/* eslint-disable camelcase */
/*
 * @Description:
 * @Autor: Wang,YunQi
 * @Date: 2021-12-07 10:54:39
 */
import { getPlatform } from "~/utilities/vendors/sensors";
let tracker = {} as any;
let isSignPrivacyTc = false;
if (process.client) {
  tracker = require('~/utilities/vendors/sensors').default;
}


// 接受来自clp主页的隐私政策签订状态，设置到这里
export function setIsSign(signFlag: boolean) {
  isSignPrivacyTc = signFlag;
}

interface clpViewParam {
  campaign_name: String;
  component_code_list: String;
  position_list: String;
  scroll_depth: Number;
  tag_list: String;
  sub_campaign_name: String;
  Nbanner_type_list: string[],
  is_has_header: boolean
  properties: {
    [name: string]: any
  }
}

export function trackClpView(
  data: clpViewParam
): void {
  try {
    const {
      campaign_name,
      component_code_list,
      position_list,
      scroll_depth,
      tag_list,
      sub_campaign_name,
      Nbanner_type_list,
      is_has_header,
      properties
    } = data;
    isSignPrivacyTc && tracker.track('CLP_View', {
      ...properties,
      campaign_name,
      component_code_list,
      position_list,
      scroll_depth,
      tag_list,
      sub_campaign_name,
      Nbanner_type_list,
      is_has_header,
      app_name: getPlatform()
    });
    if (window.self === window.top) {
      sessionStorage.setItem('previousUrl', '');
    }
    console.log('CLP_View', {
      ...properties,
      campaign_name,
      component_code_list,
      position_list,
      scroll_depth,
      tag_list,
      sub_campaign_name,
      is_has_header,
      app_name: getPlatform()
    });
  } catch (error) {
    console.warn('sensor:[CLP_View]', error);
  }
}

interface clpPdpClickParam {
  multii_item_tag?: String;
  campaign_name: String;
  text: String;
  component_code: String;
  position_code: Number;
  article_position: Number;
  tab_name: String;
  recommend_id: String;
  scroll_depth: Number;
  category_code: String;
  article_number: String;
  brand: String;
  category: String;
  gender: String;
  tag: String;
  sub_campaign_name: String;
  is_has_countdown: Boolean;
  Nbanner_type: String;
  Hotzone_Number?: String;
}

export function trackClpPdpClick(data: clpPdpClickParam): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_PDP_Click', {
      ...data,
      app_name: getPlatform()
    });
    console.log('CLP_PDP_Click', {
      ...data,
      app_name: getPlatform()
    });
  } catch (error) {
    console.warn('sensor:[CLP_PDP_Click]', error);
  }
}

interface clpPlpClickParam {
  multii_item_tag?: String;
  campaign_name: String;
  text: String;
  component_code: String;
  position_code: Number;
  card_position: Number;
  scroll_depth: Number;
  plp_url: String;
  plp_name: String;
  tab_name: String;
  vertical_tab_name: String;
  tag: String;
  sub_campaign_name: String;
  is_has_countdown: Boolean;
  Hotzone_Number?: String;
  Nbanner_type: String;
}

export function trackClpPlpClick(data: clpPlpClickParam): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_PLP_Click', {
      ...data,
      app_name: getPlatform()
    });
    console.log('CLP_PLP_Click', {
      ...data,
      app_name: getPlatform()
    });
  } catch (error) {
    console.warn('sensor:[CLP_PLP_Click]', error);
  }
}

interface clpButtonClickParam {
  multii_item_tag?: String;
  campaign_name: String;
  text: String;
  component_code: String;
  position_code: Number;
  scroll_depth: Number;
  to_url: String;
  button_name: String;
  tag: String;
  sub_campaign_name: String;
  is_has_countdown: Boolean;
  Nbanner_type: String;
  Hotzone_Number?: String;
  direct_app_project?: String;
}

export function trackClpButtonClick(data: clpButtonClickParam): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_Button_Click', {
      ...data,
      app_name: getPlatform()
    });
    console.log('CLP_Button_Click', {
      ...data,
      app_name: getPlatform()
    });
  } catch (error) {
    console.warn('sensor:[CLP_Button_Click]', error);
  }
}

interface clpVideoClickParam {
  campaign_name: String;
  text: String;
  component_code: String;
  position_code: Number;
  scroll_depth: Number;
  Video_Url: String;
  video_to_status: String;
  tag: String;
  sub_campaign_name: String;
}

export function trackClpVideoClick(this: any, data: clpVideoClickParam): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_Video_Click', {
      ...data,
      app_name: getPlatform()
    });
  } catch (error) {
    console.warn('sensor:[CLP_Video_Click]', error);
  }
}

interface clpLabelParam {
  campaign_name: String;
  text: String;
  category_code: String;
  component_code: String;
  position_code: Number;
  tab_name: String;
  vertical_tab_name: String;
  recommend_id: String;
  scroll_depth: Number;
  article_id_list: String;
  tag_list: String;
  sub_campaign_name: String;
  lookgroup_type: String;
  lookgroup_title: String;
}

interface clpTransferWindowParams {
  campaign_name: String;
  sub_campaign_name: String;
  direct_app_project: String;
}

export function trackClpLabelTabView(data: clpLabelParam): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_ProductList_View', {
      ...data,
      app_name: getPlatform()
    });
  } catch (error) {
    console.warn('sensor:[CLP_ProductList_View]', error);
  }
}

export function trackCLPTransferHalfwindowView(data: clpTransferWindowParams): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_Transfer_Halfwindow_View', {
      ...data,
      app_name: getPlatform()
    });
    console.log('CLP_Transfer_Halfwindow_View', {
      ...data,
      app_name: getPlatform()
    });
  } catch (error) {
    console.warn('sensor:[CLP_Transfer_Halfwindow_View]', error);
  }
}

export function trackCLPTransferHalfwindowClick(data: clpTransferWindowParams): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_Transfer_Halfwindow_Click', {
      ...data,
      app_name: getPlatform()
    });
    console.log('CLP_Transfer_Halfwindow_Click', {
      ...data,
      app_name: getPlatform()
    });
  } catch (error) {
    console.warn('sensor:[CLP_Transfer_Halfwindow_Click]', error);
  }
}

export function getScrollDepth(offsetTop: number): number {
  const windowHeight = window.innerHeight;

  return Math.floor(offsetTop / windowHeight) + 1;
}
