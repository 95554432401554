
  import {
    trackClpPdpClick,
    trackClpPlpClick,
    getScrollDepth
  } from '../track/index';
  import { Utils } from "./util";
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        }
      },
      position: {
        type: String
      },
      code: {
        type: String
      }
    },
    data() {
      return {
        hotWordList: [],
        index: 1,
        scrollHeight: 0,
        isMobile: false,
        utils: null
      };
    },
    computed:{
      backgroundStyle() {
        console.log("data-search",this.data);
        const style={
          backgroundColor:(this.data?.backgroundColor)?this.data.backgroundColor:"transparent",
          maxWidth: "1920px",
          margin: "0 auto"
        };

        if(this.isMobile&&this.data?.mobBackgroundImage){
          style.backgroundSize='cover';
          style.backgroundRepeat='no-repeat';
          style.backgroundPosition="top center";
          style.backgroundImage=`url(${this.data.mobBackgroundImage})`;
        }else if(!this.isMobile&&this.data?.pcBackgroundImage){
          style.backgroundSize='cover';
          style.backgroundRepeat='no-repeat';
          style.backgroundPosition="top center";
          style.backgroundImage=`url(${this.data.pcBackgroundImage})`;
        }
        return style;
      }
    },
    async mounted () {
      this.utils = new Utils(this);
      await this.getKeyWordList();
      this.$nextTick(() => {
        this.scrollHeight = this.$refs?.hotWord?.scrollHeight || 0;
      });
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.isMobile = window.innerWidth < 720;
      },
      trackData(val, res, keyword='') {
        let data;
        switch (val) {
          case 'plp':
            data = {
              campaign_name: this.code,
              text: keyword,
              component_code: 'SearchKeyword',
              position_code: Number(this.position.slice(1)),
              card_position: 1,
              scroll_depth: getScrollDepth(this.$refs.searchKeyWord.offsetTop),
              plp_url: res,
              plp_name: '',
              tag: this.data.labelInfoList.join(',')
            };
            trackClpPlpClick(data);
            break;
          case 'pdp':
            data = {
              campaign_name: this.code,
              text: keyword,
              component_code: 'SearchKeyword',
              position_code: Number(this.position.slice(1)),
              article_position: 1,
              tab_name: '',
              recommend_id: '',
              scroll_depth: getScrollDepth(this.$refs.searchKeyWord.offsetTop),
              category_code: '',
              article_number: res.content[0].articleId,
              brand: '',
              category: '',
              gender: '',
              tag: this.data.labelInfoList.join(',')
            };

            trackClpPdpClick(data);
            break;
        }
      },
      async handleItemClick(keyword) {
        try {
          const res = await this.utils.getSearchResult(keyword);
          this.handleRedirect(res, keyword);
        } catch (e) {
          if (e.data && (e.data.code === '1000' || e.data.code === '20000')) {
            this.$dialog({
              title: '当前页面访问人数过多',
              confirmButton: true,
              confirmButtonText: '重试',
              on: {
                confirm: ({ close }) => {
                  window.location.reload();
                  close();
                }
              }
            });
          } else if (e.data && e.data.code === '1002') {
            this.$dialog({
              title: e.data.message,
              confirmButton: true,
              confirmButtonText: '我知道了',
              on: {
                confirm: ({ close }) => {
                  window.location.reload();
                  close();
                }
              }
            });
          }
        }
      },
      handleRedirect(res, keyword) {
        if (this.utils.channel === 'WMS') {
          const finalUrl = res.totalElements === 1 ? `/pages/product/details?articleId=${res.content[0].articleId}` : `/pages/product/list?keyword=${keyword}`;
          console.log('finalUrl: ' + finalUrl, res);
          this.trackData(res.totalElements === 1 ? 'pdp' : 'plp', finalUrl, keyword);
          wx.miniProgram.navigateTo({
            url: finalUrl
          });
        } else if (this.utils.channel === 'APP') {
          const finalUrl = res.totalElements === 1 ? 'adidas://product/' + res.content[0].articleId : 'adidas://category?query=' + encodeURIComponent(encodeURIComponent(keyword));
          console.log('finalUrl: ' + finalUrl);
          this.trackData(res.totalElements === 1 ? 'pdp' : 'plp', finalUrl, keyword);
          window.location.href = finalUrl;
        }  else if (this.utils.channel === 'OR') {
          const shopCode = this.$route.query.shopCode;
          const finalUrl = res.totalElements === 1 ? `/orwshop/pages/productDetail/index?articleId=${res.content[0].articleId}&shopCode=${shopCode}` : `/pages/subPackage/pages/plp/index?keyword=${keyword}&shopCode=${shopCode}`;
          console.log('finalUrl: ' + finalUrl, res);
          this.trackData(res.totalElements === 1 ? 'pdp' : 'plp', finalUrl, keyword);
          wx.miniProgram.navigateTo({
            url: finalUrl
          });
        } else {
          const path = '/plp/search';
          const queryObj = {
            keyword: encodeURI(keyword),
            page: 1
          };
          // 仅有一个商品直接跳转pdp
          if (res.totalElements === 1) {
            this.$router.push({
              path: '/pdp',
              query: { articleId: res.content[0].articleId },
              params: { flag: 'home' }
            });
            this.trackData('pdp', res, keyword);
            return false;
          }

          if (res.totalElements === 0 && res.plpType !== 'deepLink') {
            queryObj.type = 'searchNoResult';
          }
          this.trackData('plp', `${path}?keyword=${encodeURI(keyword)}&page=1`, keyword);
          // 清楚筛选条件
          this.$router.push({
            path,
            query: queryObj,
            params: { flag: 'home' }
          });
        }
      },
      async getKeyWordList() {
        try {
          const hotWordTypeList = await this.utils.getSearchKeyWord();
          this.hotWordList = hotWordTypeList;
        } catch (error) {
          console.log('获取热搜关键词失败', error);
        }
      },
      handleChangeM() {
        const length = parseInt((this.$refs.hotWord.scrollHeight / 84).toFixed());
        const remainder = this.$refs.hotWord.scrollHeight % 84;
        const singleH = 46;
        const doubleH = 92;
        if (this.index < length) {
          this.$refs.hotWord.scrollTop = this.index + 1 === length && remainder > singleH ? doubleH * (this.index - 1) + remainder - this.index * 8 : doubleH * this.index;
          this.index++;
        } else {
          this.$refs.hotWord.scrollTop = 0;
          this.index = 1;
        }
      },
      handleChangeP() {
        if (this.index < Math.floor(this.$refs.hotWord.scrollHeight / 38)) {
          this.$refs.hotWord.scrollTop = 38 * this.index;
          this.index++;
        } else {
          this.$refs.hotWord.scrollTop = 0;
          this.index = 1;
        }
      }
    }
  };
