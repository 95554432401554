
import { createPopper } from '@popperjs/core';
import { createModel } from '../../../utilities/createModel';
import { createTooltipTrigger, delay } from './LibTooltip.trigger';

export default {
  name: 'LibTooltip',
  props: {
    // 双向绑定值，控制是否显示
    value: {
      type: Boolean,
    },
    className:{
      type: String,
      default: '',
    },
    // 方向：top,left,right,bottom, top-start,left-start,right-start,bottom-start,top-end,left-end,right-end,bottom-end
    placement: {
      type: String,
      default: 'top',
    },
    // 触发动作：hover，click
    trigger: {
      type: String,
      default: 'hover',
    },
    // 主题
    theme: {
      type: String,
      default: 'black',
    },
    // 禁用滚动的时候自动关闭tooltip
    disableHideOnScroll: {
      type: Boolean,
    },
    // 主题色
    themeColor: {
      type: String,
    },
    // 宽度
    width: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 传入的气泡层级
    currentIndex: {
      type: [String, Number],
      default: 900,
    },
    // 传入气泡距离元素的间距
    offset: {
      type: Number,
      default: 8,
    },
    offsetTop: {
      type: Number,
      default: 0,
    },
    isHide: {
      type: Boolean,
      default: false
    },
    // 如何是点击 打开后,第一次点击是显示,第二次是关闭
    switcher:{
      type: Boolean,
      default: false
    }
  },
  data() {
    const model = createModel({ context: this });
    return {
      model,
      popper: null,
      triggerManager: null, // 触发器
      zIndex: 900,
    };
  },
  computed: {
    // 处理中间区域样式
    contentClasses() {
      return [
        'lib-tooltip-content',
        this.themeColor ? null : `lib-tooltip-content-${this.theme}`,
        this.className
      ];
    },
    contentStyles() {
      return {
        zIndex: this.zIndex,
        background: this.themeColor,
        width: this.width ? this.width : '',
      };
    },
  },
  watch: {
    'model.value'(val) {
      if (val) {
        this.zIndex = this.$utils.nextIndex();
        if (this.currentIndex !== 900 && this.zIndex < this.currentIndex) {
          this.zIndex = this.currentIndex;
        }
      }
      this.$emit("change", val);
    },
    // 监听触发器，及时更新和销毁组件
    trigger: {
      immediate: true,
      async handler(val) {
        await delay();
        if (this.triggerManager) {
          this.triggerManager.destroy();
        }
        this.triggerManager = createTooltipTrigger[val]().initialize({
          popper: this.$refs.popper,
          reference: this.$el,
          show: () => {
            if(this.trigger==="click"&& this.switcher){
              if(this.model.value){
                this.hide();
              }else{
                this.show();
              }
            }else{
              this.show();
            }
          },
          hide: () => this.hide(),
        });
      },
    },
  },
  mounted() {
    // 组件初始化监听scroll, 当禁用滚动的时候，关闭tooltips
    const onScroll = () => {
      if (!this.disableHideOnScroll) {
        this.model.value = false;
      }
    };
    this.$on('hook:beforeDestroy', this.$utils.device.onPageScroll(onScroll));
    // 绑定组件beforeDestroy 钩子函数，销毁触发器
    this.$on('hook:beforeDestroy', () => {
      !!this.triggerManager && this.triggerManager.destroy();
      !!this.popper && this.popper.destroy();
    });
  },
  methods: {
    async show() {
      if (this.disabled) return;
      if (!this.popper) {
        document.body.appendChild(this.$refs.popper);
        this.$on('hook:beforeDestroy', () => {
          document.body.removeChild(this.$refs.popper);
        });
        this.popper = this.createPopper();
        await delay(0);
      }
      this.popper.update();
      !this.isHide ? this.model.value = true : '';

    },
    hide() {
      this.model.value = false;
    },
    createPopper() {
      // 创建tooltips内容展示组件
      return createPopper(this.$el, this.$refs.popper, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [this.offsetTop, this.offset],
            },
          },
          {
            name: 'arrow',
            options: {
              padding: 8,
            },
          },
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false, // true by default
            },
          },
        ],
      });
    },
  },
};
