import { render, staticRenderFns } from "./couponItem.vue?vue&type=template&id=550bfa58&scoped=true"
import script from "./couponItem.vue?vue&type=script&lang=js"
export * from "./couponItem.vue?vue&type=script&lang=js"
import style0 from "./couponItem.vue?vue&type=style&index=0&id=550bfa58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "550bfa58",
  null
  
)

export default component.exports