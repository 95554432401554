// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../nuxt/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../nuxt/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../nuxt/assets/common/share-hand.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lib-share-guide{text-align:right}.lib-share-guide .guid-text{position:relative;display:inline-block;color:var(--adi-color-white);text-align:left}.lib-share-guide .guid-text .share-hand{transform:matrix(0.99, 0.13, -0.14, 0.99, 0, 0);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;background-position:center bottom;height:34.56px;width:22.9px;animation:handMove 1s cubic-bezier(0, 0, 0.58, 1) 1}.lib-share-guide .guid-text .share-tip{position:absolute;color:var(--adi-color-white);width:300px;right:15px;margin-top:10px;font-size:13px;text-align:right}.lib-share-guide .guid-text .share-button{position:relative;display:inline-block;width:3px;height:3px;margin:0 10px;background-color:var(--adi-color-white);border-radius:50%}.lib-share-guide .guid-text .share-button::before,.lib-share-guide .guid-text .share-button::after{position:absolute;content:\" \";display:block;width:3px;height:3px;background-color:var(--adi-color-white);border-radius:50%}.lib-share-guide .guid-text .share-button::before{left:-6px}.lib-share-guide .guid-text .share-button::after{right:-6px}.lib-share-guide .guid-text .mini-env{padding-right:41px}.lib-share-guide .guid-text .app-env{padding-right:49px}@keyframes handMove{0%{transform:matrix(0.99, 0.13, -0.14, 0.99, 0, 0)}30%{transform:matrix(0.99, 0.13, -0.14, 0.99, 0, -4.5)}60%{transform:matrix(0.99, 0.13, -0.14, 0.99, 0, 0)}100%{transform:matrix(0.99, 0.13, -0.14, 0.99, 0, 0)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
