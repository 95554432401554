
import { trackClpPdpClick, getScrollDepth } from '../../../track/index';
import LibProductCardMobile from '../common/product-card.vue';

export default {
  name: 'ProductList',
  components: {  LibProductCardMobile },
  props: {
    info: {
      type: Object
    },
    products: {
      type: Array,
      default: () => []
    },
    articles: {
      type: Array,
      default: () => []
    },
    type: {
      type: String
    },
    isMobile: {
      type: Boolean
    },
    articleList:{
      type:Array,
      default: () => []
    },
    tabName: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    categoryCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeBanner: 0,
      swiperProgress: 0,
      slidePerView: 0,
      device: 'pc',
      benefitDescriptionHeight: '',
      swiperNavigationTop: '0px',
      productListData: []
    };
  },
  computed: {
    itemCLass(){
      if(this.list?.length === 2){
        return ['product-list-card-2'];
      }else if(this.list?.length === 1){
        return ['product-list-card-1'];
      }
      return [];
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    list() {
       console.log('this.info', this.info);
       let list;
      if(this.type === 'PLP'){
        list=this.products.slice(0, this.info.pageSize + 1);
      }else{
        list=this.articleList;
      }
      if(!list){
        return [];
      }
      if(this.isMobile){
        let size;
        if (this.info.pageSize) {
          size = this.info.pageSize < 3 ? this.info.pageSize : 3;
        } else {
          size = 3;
        }
        return list.slice(0, size);
      }
      return list;
    }
  },
  // mounted() {
  //   this.handleResize();
  //   window.addEventListener('resize', this.handleResize);
  // },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.handleResize);
  // },
  methods: {
    sensorClickTrack(item, index) {
      const labelInfoList = [];
      if (this.info.labelInfo) {
        this.info.labelInfo.forEach((labelItem) => {
          labelInfoList.push(`${labelItem.labelGroup}:${labelItem.label.join('|')}`);
        });
      }
      // 判断当前父组件做埋点
      const data = {
        campaign_name: this.$route.query.contentId || '',
        sub_campaign_name: this.code,
        text: '',
        component_code: 'LookGroup',
        position_code: Number(this.position.slice(1)),
        article_position: index + 1 + 1,
        tab_name: this.tabName,
        recommend_id: '',
        scroll_depth: getScrollDepth(this.$refs.lookGroupRef.offsetTop),
        category_code: item.category || '',
        article_number: item.articleId,
        brand: item.brandName,
        category: item.category || '',
        gender: item.genderShow,
        tag: labelInfoList.join(',')
      };

      trackClpPdpClick(data);
    }
  }
};
