/*
 * @Description: 操作本地购物车的服务类
 * @Autor: Duan,JingLiang
 * @Date: 2022-04-18 10:49:22
 */
import deepcopy from 'deepcopy';
import { times } from 'number-precision';
import { isClient } from '~/utilities/helper/SystemHelper';
interface localCartObject {
  // 当前商品加入的时间
  addTime: number;
  // 商品id
  articleId: string;
  // 商品数量
  buyCount: number;
  // 商品图片地址
  mediaUrl: string;
  // 商品数量 同 buyCount值
  quantity: number;
  // 是否为赠品，本地购物车为默认0，不需要显示赠品
  giftFlag: number;
  // 默认选中
  isSelected: number;
  // 颜色名称
  colorName: string;
  // 尺码
  sizeAlias: string;
  // 尺码描述
  sizeDescription: string;
  // 当前商品的skuId,同skuId字段
  skuChosenId: string;
  // 本地购物车不显示折扣，但必须有这个字段，默认定为原价
  discountPrice: number;
  // 当前商品总价：quantity * salePrice
  subTotalAmount: number;
  // 单价
  salePrice: number;
  // 其他字段...
  [key: string]: any;
}

class LocalCart {
  private _cartData: any;

  private addExpireTime() {
    window.localStorage.setItem(
      'localCartExpireTime',
      new Date().valueOf() - 31 * 24 * 3600 * 1000 + ''
    );
  }

  // 获取本地购物车失效时间
  get expireTime(): number {
    return +(window.localStorage.getItem('localCartExpireTime') || 0);
  }

  // 获取本地购物车数据
  get cartData() {
    let _cartData;
    try {
      _cartData = JSON.parse(
        isClient && window.self === window.top && window.localStorage.getItem('localCartData') || '[]'
      );
    } catch {
      _cartData = [];
    }
    this._cartData = _cartData;
    return this._cartData;
  }

  set cartData(value: any) {
    // value可以是数组或对象
    if (value instanceof Array) {
      this._cartData = deepcopy(value);
      window.localStorage.setItem(
        'localCartData',
        JSON.stringify(this._cartData)
      );
    } else {
      // 第一次加入
      // eslint-disable-next-line no-lonely-if
      if (this.cartData && this.cartData.length === 0) {
        this.addExpireTime();
        value.isExsit = true;
        // 记录商品加入时间
        value.addTime = new Date().valueOf();
        window.localStorage.setItem(
          'localCartData',
          JSON.stringify(this.handledCartData([value]))
        );
      } else {
        const origin = this.cartData;
        if (!value.addTime) value.addTime = new Date().valueOf();
        const newData = this.handleSameSkuData(origin, value);
        const transformData = this.handledCartData(newData);
        window.localStorage.setItem(
          'localCartData',
          JSON.stringify(transformData)
        );
      }
    }
  }

  // 加购本地购物车
  public setLocalData(value: object) {
    this.cartData = value;
  }

  // 处理购物车数据，同一个skuId商品应该合并，只改变数量
  private handleSameSkuData(
    data: localCartObject[],
    target: localCartObject
  ): [] {
    const arr = [] as any;
    const cloneData = deepcopy(data);
    for (const item of cloneData) {
      if (item.skuChosenId === target.skuChosenId) {
        item.buyCount = item.buyCount + target.buyCount;
        target.isExsit = true;
        continue;
      }
    }
    for (const item of cloneData) {
      if (item.skuChosenId !== target.skuChosenId && !target.isExsit) {
        target.isExsit = true;
        arr.unshift(target);
      }
    }
    return arr.concat(cloneData);
  }

  // 获取购物袋，处理购物车列表所需要的字段数据
  private handledCartData(arr: localCartObject[]): localCartObject[] {
    return arr.map((item: localCartObject) => {
      return {
        ...item,
        mediaUrl: item.plpImageUrl,
        quantity: item.buyCount,
        skuId: item.skuId || item.skuChosenId,
        giftFlag: 0,
        // 默认选中
        isSelected: 1,
        colorName: item.colorDisplay,
        sizeAlias: item.sizeAlias || item.size || item.sizeName,
        sizeDescription: item.size || item.sizeAlias || item.sizeName,
        skuChosenId: item.skuChosenId || item.skuId,
        // 本地购物车不显示折扣，但必须有这个字段，默认定为原价
        discountPrice: item.salePrice,
        subTotalAmount: times(item.buyCount, item.salePrice)
      };
    });
  }

  // 修改本地购物车数据（商品加减，规格切换）
  public modifyCartData(item: localCartObject) {
    const _toModifyData = deepcopy(this.cartData);
    for (const iterator of _toModifyData) {
      if (iterator.skuChosenId === item.skuChosenId) {
        iterator.quantity = item.quantity;
        iterator.buyCount = item.quantity;
      }
      iterator.subTotalAmount = times(iterator.buyCount, iterator.salePrice);
    }
    this.cartData = _toModifyData;
  }

  // 处理勾选的商品数据
  // public handleSelectedCartData(item: any) {
  //   if (item instanceof Array) {
  //     console.log(item);
  //   } else {
  //     const _originData = deepcopy(this.cartData);
  //     for (const iterator of _originData) {
  //       if (iterator.skuChosenId === item.skuChosenId) {
  //         iterator.isSelected = !item.isSelected;
  //       }
  //     }
  //     this.cartData = _originData;
  //     return _originData;
  //   }
  // }

  // 切换规格，更新某项购物车数据
  public replaceCartItem(originSkuId: string, item: localCartObject) {
    const _local = deepcopy(this.cartData);
    const index = _local.findIndex(
      (i: localCartObject) => i.skuChosenId === originSkuId
    );
    const newData = Object.assign(_local[index], item);
    _local.splice(index, 1, newData);
    this.cartData = this.handledCartData(_local);
  }

  // 删除购物车数据,支持单个，多个删除
  public deleteCartItem(skuIdList: any) {
    const _local = deepcopy(this.cartData);
    const deletedData = _local.filter(
      (item: any) => !skuIdList.includes(item.skuChosenId)
    );
    this.cartData = deletedData;
    if (deletedData.length === 0) {
      this.clearLocalCartData();
    }
    return deletedData;
  }

  // 批量加入购物车
  public async batchAddCart(ctx: any) {
    const bathData: [] = this.cartData.map((item: localCartObject) => {
      return {
        quantity: item.quantity,
        skuId: item.skuChosenId
      };
    });
    try {
      ctx.$aloading.show();
      const data = await ctx.$axios.post(
        `${ctx.$config.ECP_API_HOST}/o2cart/v1/carts/batch`,
        bathData,
        {
          'content-type': 'application/x-www-form-urlencoded'
        }
      );
      if (data) {
        ctx.$aloading.hide();
        this.clearLocalCartData();
        console.log('本地购物车数据合并成功');
      }
    } catch (error) {
      ctx.$aloading.hide();
      this.clearLocalCartData(); // 接口报错，合并失败，也需要清除本地购物车
      console.error('服务异常,本地合并购物车失败, 请稍后再试');
    } finally {
      ctx.$aloading.hide();
      this.clearLocalCartData();
    }
  }

  public getLocalCartNum() {
    return this.cartData.reduce((prev: number, curr: localCartObject) => {
      return curr.quantity + prev;
    }, 0);
  }

  // 清空本地购物车
  public clearLocalCartData() {
    window.localStorage.removeItem('localCartData');
    window.localStorage.removeItem('localCartExpireTime');
  }
}

export default LocalCart;
