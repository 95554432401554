// pag 2.0版本 1.0版本已经删除

import {types} from 'libpag';
import {PAGFile} from 'libpag/types/pag-file';
import {PAGView} from 'libpag/types/pag-view';

import { iCtx } from "./basic/utils";
export function getWasm(ctx: iCtx){
  fetch(ctx.$config.S3_COM_HOST + "/assets/points-animation/libpag.wasm");
}
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    initPagPromise:Promise< types.PAG>|undefined;
  }
}
export class Pag{
  public buffer:ArrayBuffer|undefined;
  public PAG:types.PAG|undefined;
  public pagFile:PAGFile|undefined;
  protected width:number|undefined;
  protected height:number|undefined;
  protected pagView:PAGView|undefined;
  public playtoEndPromise:Promise<void>|undefined;
  public getBufferPromise:Promise<void>|undefined;
  public initPromise:Promise<void>|undefined;
  // public readyPlayPromise:Promise<void>|undefined
  // eslint-disable-next-line no-useless-constructor
  constructor(protected ctx: iCtx,public url:string,protected canvas:HTMLCanvasElement){
    // 先把素材下好
    this.getBufferPromise= this.getPagBufferAsync(this.url);
    getWasm(ctx);
  };

  public async initAsync(){
    if(!window.initPagPromise){
      window.initPagPromise=this.initPagAsync();
    }
    this.PAG= await  window.initPagPromise;
    await this.getBufferPromise;
    console.log('图片加载完成', this.getBufferPromise);
    this.pagFile = await (this.PAG as types.PAG).PAGFile.load(this.buffer as ArrayBuffer);
    console.log("Page initAsync");
  }

  /**
   * 初始化pag
   * @returns 
   */
  private async initPagAsync(){
    const { PAGInit } = await import( 'libpag');
      const PAG=  await PAGInit({
        locateFile: (file) => this.ctx.$config.S3_COM_HOST + "/assets/points-animation/" + file
      });
      return PAG;
  }

  private async getPagBufferAsync(url:string):Promise<void>{
    const response=await fetch(url);
    this.buffer=await response.arrayBuffer();
  };

  /**
   * 设置视频的宽度和高度
   * @param size 
   */
  async setVideoSize(size:{width?:number,height?:number}){
    if(!this.initPromise){
      this.initPromise= this.initAsync();
    }
    await this.initPromise;
    const pagFile=this.pagFile as PAGFile;
    if(size.width&&!size.height){
      this.width=size.width;
      this.height=pagFile.height()* (this.width/pagFile.width());
    }else if(!size.width&&size.height){
      this.height=size.height;
      this.width=pagFile.width()* (this.height/pagFile.height());
    }else{
      this.height=size.height;
      this.width=size.width;
    }
  };

  /**
   * 准备播放
   */
  private async replay(updateContent:void|((pagFile:PAGFile)=>void)){
    if(!this.initPromise){
      this.initPromise= this.initAsync();
    }
    await this.initPromise;
    const PAG=this.PAG as types.PAG;
    if( this.pagView){
      this.pagView.destroy();
      // this.pagView.setProgress(0);
    }
    
    const pagFile=this.pagFile as PAGFile;
    updateContent&&updateContent(pagFile);
    this.canvas.width = pagFile.width();
    this.canvas.height = pagFile.height();
    if(this.width&&this.height){
      this.canvas.width=this.width;
      this.canvas.height=this.height;
    }
    
    // 停顿下 让canvas渲染下 
    await new Promise((resolve)=>{
      setTimeout(resolve, 10);
    });
    
    this.pagView = await PAG.PAGView.init(pagFile, this.canvas);
  }

  // public async readyPlay(){
  //   await this.replay();
  // }

  /**
   * play 播放动画
   * @param {number}
   */
  async play(updateContent:void|((pagFile:PAGFile)=>void)):Promise<void>{
    this.playtoEndPromise=undefined;
    // if(this.readyPlayPromise){
    //   const readyPlayPromise=this.readyPlayPromise;
    //   await readyPlayPromise;
    // }
    await this.replay(updateContent);
    const pagView=this.pagView as PAGView;
    // pagView.setProgress(0);
    // await pagView.stop();
    if(pagView){
      this.playtoEndPromise= new Promise<void>((resolve) => {
        const callBack=()=>{
          pagView.removeListener("onAnimationEnd",callBack);
          resolve();
        };
        pagView.addListener("onAnimationEnd",callBack);
      });
      await pagView.play();
    }
  }


  async playtoEnd(){
    if(this.playtoEndPromise){
      await this.playtoEndPromise;
    }
  }
}