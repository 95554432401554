
import { BasicUtils } from '~/utilities/basic/utils';
export default {
  name: 'Countdown',
  props: {
    options: {
      type: Object
    }
  },
  data() {
    let data={};
    if (!process.server) {
      const options = { ...this.options };
      data= this.updateType(options,new Date());
    }
    return {
      nowTime: new Date(),
      process: null,
      countdownTime: null,
      countdownType: '',
      ...data
    };
  },
  computed: {
    boxStyle() {
      return {
        left: this?.options?.textMarginLeft
          ? this.options.textMarginLeft
          : '0px',
        top: this?.options?.textMarginTop ? this.options.textMarginTop : '0px'
      };
    },
    textStyle() {
      if (this?.options?.textColor) {
        return {
          color: this.options.textColor
        };
      } else {
        return {
          color: '#FFFFFF'
        };
      }
    },
    timeText() {
      let txt='';
      if (
        this.countdownType === 'start' &&
        this.options?.displayStartTimeText
      ) {
        txt = this.options.displayStartTimeText;
      }
      if (this.countdownType === 'end' && this.options?.displayEndTimeText) {
        txt = this.options.displayEndTimeText;
      }
      // 控制24个字符
      let txtItem='';
      const txts=[];
      for(const item of txt.split('')){
        ;
        const encoder = new TextEncoder();
        const encodedData = encoder.encode(txtItem+item);
        if(encodedData.length<=12*3){
          txtItem=txtItem+item;
        }else{
          txts.push(txtItem);
          txtItem=item;
        }
      }
      if(txtItem){
        txts.push(txtItem);
      }
      return txts.join('<br>');
    },
    hour() {
      if (this.countdownTime) {
        const hour = Math.floor(this.countdownTime / (1 * 60 * 60 * 1000));
        return hour < 10 ? '0' + hour : hour;
      }
      return '';
    },
    minute() {
      if (this.countdownTime) {
        const minute = Math.floor(
          (this.countdownTime % (1 * 60 * 60 * 1000)) / (1 * 60 * 1000)
        );
        return minute < 10 ? '0' + minute : minute;
      }
      return '';
    },
    second() {
      if (this.countdownTime) {
        const second = Math.floor(
          (this.countdownTime % (1 * 60 * 1000)) / (1 * 1000)
        );
        return second < 10 ? '0' + second : second;
      }
      return '00';
    },
    timeClass() {
      if (this?.options?.timeStyle) {
        if (this.options.timeStyle === '1') {
          return ['white'];
        } else if (this.options.timeStyle === '2') {
          return ['black'];
        }
      }
      return ['black'];
    },
    show() {
      const show = !!this.countdownTime;
      this.$emit('update', show);
      return show;
    }
    // displayStartTime(){
    //   if(this.options?.displayStartTime){
    //     return this.options.displayStartTime.toString();
    //   }else{
    //     return 'null';
    //   }
    // },
    // displayEndTime(){
    //   if(this.options?.displayEndTime){
    //     return this.options.displayEndTime.toString();
    //   }else{
    //     return 'null';
    //   }
    // }
  },
  mounted() {
    const utils = new BasicUtils(this);
    // // 调试开始时间
    // const displayStartTime=new Date();
    // displayStartTime.setTime((this.nowTime.getTime()) + 10000);
    // options.displayStartTime=displayStartTime;
    // // 调试结束时间
    // const displayEndTime=new Date();
    // displayEndTime.setTime((this.nowTime.getTime() + 20*1000));
    // options.displayEndTime=displayEndTime.toString();
    // console.log("displayStartTime",displayStartTime);
    const nowTime=utils.getServerTime();
    if(!nowTime){
      this.nowTime = new Date();
      utils.getServerTimeAsync().then((res) => {
        this.nowTime = res;
      });
    }else{
      this.nowTime=nowTime;
    }
    const options = { ...this.options };
    const data= this.updateType(options,this.nowTime);
    this.countdownTime=data.countdownTime;
    this.countdownType=data.countdownType;
    this.process&&clearInterval(this.process);
    this.process = setInterval(async () => {
      // getServerTimeAsync当前为立刻返回
      const nowTime=utils.getServerTime();
      if(!nowTime){
        this.nowTime = await utils.getServerTimeAsync();
      }else{
        this.nowTime=nowTime;
      }
      const data= this.updateType(options,this.nowTime);
      this.countdownTime=data.countdownTime;
      this.countdownType=data.countdownType;
    }, 500);
  },
  destroyed() {
    clearInterval(this.process);
  },
  methods: {
    updateType(options,nowTime) {
      const data={
        countdownTime:0,
        countdownType:''
      };
      if (options?.displayStartTime) {
        const startTime = new Date(options.displayStartTime);
        const startTimeCheck = startTime.getTime() - nowTime.getTime();
        if (startTimeCheck >= 0 && startTimeCheck <= 72 * 60 * 60 * 1000) {
          data.countdownTime = startTimeCheck;
          data.countdownType = 'start';
          return data; 
        }
        const endTime = new Date(options.displayEndTime);
        const endTimeCheck = endTime.getTime() - nowTime.getTime();
        if (endTimeCheck >= 0 && endTimeCheck <= 72 * 60 * 60 * 1000) {
          data.countdownTime = endTimeCheck;
          data.countdownType = 'end';
          // eslint-disable-next-line no-useless-return
          return data;
        }
        // console.log("nowTime",this.nowTime,"startTimeCheck",startTimeCheck,"endTimeCheck",endTimeCheck);
        data.countdownType = '';
        data.countdownTime = null;
      }
      return data;
    }
  }
  // data() {
  //   return {

  //   };
  // }
};
