
import { LibButton } from '@adidas-com-front/ui-library';
import Footer from '../../../components/footer';
import Header from './header';
export default {
  name: 'LuckyDraw500Error',
  components: { Header, Footer, LibButton },
  data() {
    return {
      isWXBrowser:false,
      isQQBrowser:false,
      IsMINI:true,
      isAPP:true,
      IsOR: true,
      IsAdiclub:true,
      showHeader:false
    };
  },
  computed: {
    iconError() {
      return this.$config.S3_COM_HOST + '/assets/lucky-draw/icon.png';
    },
    animationUrl(){
      return (process.client)?this.$config.S3_COM_HOST+"/assets/error/404.mp4":'';
    }
  },
  mounted(){
    const userAgent = navigator.userAgent.toLowerCase();
    this.IsMINI = userAgent.includes('miniprogram');
    this.isAPP = userAgent.includes('adidasapp');
    this.IsOR = this.$route.path.includes('/OR');
    this.IsAdiclub = this.$route.path.includes('/AC');
    if (userAgent.match(/MicroMessenger/i)) {
      this.isWXBrowser = userAgent.match(/MicroMessenger/i)[0] === 'micromessenger';
    }
    if (
      userAgent.match(/tencenttraveler/) != null ||
      userAgent.match(/qqbrowse/) != null
    ) {
      this.isQQBrowser = true;
    }
    this.showHeader=true;
  },
  methods: {
    handleLeft() {
      this.$router.back();
    },
    toHomePageByEnv() {
      const ua = navigator.userAgent.toLowerCase();
      let currentEnviroment = '';
      if(this.$route.path.includes('/OR')) {
        currentEnviroment = 'OR';
      } else if (this.$route.path.includes('/AC')) {
        currentEnviroment = 'Adiclub';
      } else if (ua.includes('miniprogram')) {
        currentEnviroment = 'miniprogram';
      } else if (ua.includes('adidasapp')) {
        currentEnviroment = 'AdidasApp';
      }

      if (currentEnviroment) {
        console.log('currentEnviroment', currentEnviroment);
        if(currentEnviroment === 'OR') {
          // 如果当前环境是OR
          const finalUrl = '/orwshop/pages/home-page/index';
          wx.miniProgram.switchTab({
            url: finalUrl
          });
        } else if (currentEnviroment === 'Adiclub') {
          // 如果当前环境是Adiclub
          const finalUrl = '/pages/memberCenter/homePage/index';
          console.log('adiclub路径：', finalUrl);
          wx.miniProgram.switchTab({
            url: finalUrl
          });
        } else if (currentEnviroment === 'miniprogram') {
          // 如果当前环境是WMS
          const finalUrl = '/pages/tabs/home';
          // console.log('小程序跳转home路径：', finalUrl);
          wx.miniProgram.switchTab({
            url: finalUrl
          });
        } else if (currentEnviroment === 'AdidasApp') {
          // 如果当前链接参数环境是APP内嵌
          const finalUrl = 'adidas://shop';
          console.log('app路径：', finalUrl);
          setTimeout(() => {
            window.location.href = finalUrl;
          }, 1500);
        }
      } else {
        // 否则直接跳转系统内
        console.log('com系统内');
        this.$router.push('/');
      }
    }
  }
};
