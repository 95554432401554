
import look from './group.vue';
export default {
  name: 'FeedProduct',
  components: {
    look
  },
  props: {
    lookGroupList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    franchise: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    tabName: {
      type: String,
      default: ''
    },
    clpShow: {
      type: Boolean
    }
  },
  data() {
    return {
      // 初始化中
      initCom: true,
      // 准备好了再显示
      showSwiper: false,
      // 动画是否打开
      animation: false,
      activeIndex: 0,
      boxProgress: 0,
      // activeHeight:400,
      // leaveHeight:320,
      swiperOption: {
        // scrollbar: {
        //   el: '.swiper-scrollbar'
        // },

        // watchOverflow: true,
        // simulateTouch: false,
        // pagination: {
        //   el: '.swiper-pagination'
        // },
        spaceBetween: 0,
        loop: false,
        slidesPerView: 'auto',
        loopAdditionalSlides: 2,
        centeredSlides: true,
        paginationClickable: true,

        // slidesPerGroupAuto:true,
        // autosize: false,
        on: {
          // 回调函数，swiper从一个slide过渡到另一个slide结束时执行。
          slideChangeTransitionEnd() {
            // 在Loop模式下Swiper切换到指定slide。切换到的是slide索引是realIndex
            this.slideToLoop(this.realIndex, 0, false);
          },
          slideChange: (activeIndex) => {
            if (this.swiper) {
              this.activeIndex = this.swiper.realIndex;
              this.updateStyle(this.activeIndex);
              // this.updateStyle();
            }
          },
          progress: (progress) => {
            this.$nextTick(() => {
              const boxs = this.$refs.mySwiper.querySelectorAll(
                '.swiper-slide.loock-box'
              );
              const leprogress =
                progress * (boxs.length - 1) - this.lookGroupList.length;
              if (this.boxProgress !== Number(leprogress.toFixed(1))) {
                this.updateStyle(leprogress);
                this.boxProgress = Number(leprogress.toFixed(1));
                // console.log("progress",leprogress,this.activeIndex);
              }
            });
          }
        }
      }
    };
  },
  computed: {
    itemCLass() {
      if (this.animation) {
        return ['animation'];
      } else {
        return [];
      }
    },

    show() {
      return this.clpShow && !this.initCom && this?.lookGroupList?.length > 0;
    },
    swiper() {
      return this.$refs?.mySwiper?.swiper || null;
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.swiperOption.loop =
          this?.lookGroupList?.length && this.lookGroupList.length > 1;
        // 初始不要动画
        this.animation = false;

        this.$nextTick(async () => {
          if (this.show) {
            this.showSwiper = true;
            await this.waitTask();
          }
          if (this.show) {
            // 要等下 swiper渲染出来才能控制swiper
            this.updateStyle(0);
            await this.waitTask();
          }
          if (this.show) {
            this.animation = true;
          }
        });
      } else {
        this.showSwiper = false;
      }
    },
    activeIndex(newValue, oldValue) {
      this.$nextTick(() => {
        this.$emit('changeSwiper', newValue);
      });
    }
  },
  mounted() {
    this.initCom = false;
  },
  methods: {
    updateStyle(progress) {
      let boxProgress = progress % 1;

      if (boxProgress === 0) {
        // 已经滑动完成;
        boxProgress = 1;
      } else if (boxProgress < 0) {
        boxProgress = 1 + boxProgress;
      }
      const items = this.$refs.mySwiper.querySelectorAll(
        '.swiper-slide.loock-box'
      );
      const pags = this.$refs.pagination.querySelectorAll('.bullet');
      if (!items || items.length === 0 || !pags || pags.length === 0) {
        return;
      }
      let activeIndex = 0;
      let leaveIndex = 0;
      if (Math.floor(progress) !== progress) {
        // const moveIndex=Math.ceil( Math.abs(progress));
        // 有小数点 表示在移动过程中
        leaveIndex = this.activeIndex;
        let leaveAdd = 0;
        if (progress < leaveIndex) {
          activeIndex = Math.floor(progress);
          boxProgress = 1 - boxProgress;
          leaveAdd = 1;
        } else {
          activeIndex = Math.ceil(progress);
          leaveAdd = -1;
        }
        leaveIndex =
          (activeIndex + leaveAdd + this.lookGroupList.length) %
          this.lookGroupList.length;
      } else {
        leaveIndex = -1;
        activeIndex = progress;
      }
      // activeIndex可能大于this.lookGroupList.length 也可能小于0
      activeIndex =
        (activeIndex + this.lookGroupList.length) % this.lookGroupList.length;
      // console.log("leaveIndex",leaveIndex,"activeIndex",activeIndex,"boxProgress",boxProgress,"progress",progress);
      // const leaveHeight = Math.floor(400 - (boxProgress * 80) );
      // const activeHeight=Math.floor( 320 + (boxProgress * 80) );
      const activeX = `scale(${0.8 + 0.2 * boxProgress}) translateZ(0)`;
      const leaveX = `scale(${1 - 0.2 * boxProgress}) translateZ(0)`;
      const otherX = `scaleY(0.8) scaleX(0.88) translateZ(0)`;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const index = item.getAttribute('data-index');
        let transform = '';
        // console.log("index",index);
        if (index.toString() === leaveIndex.toString()) {
          transform = leaveX;
        } else if (index.toString() === activeIndex.toString()) {
          transform = activeX;
        } else {
          transform = otherX;
        }
        if (transform !== item.style.transform) {
          item.style.transform = transform;
        }
      }
      for (const item of pags) {
        const index = item.getAttribute('data-index');
        if (index.toString() === leaveIndex.toString()) {
          item.classList.remove('active');
        } else if (index.toString() === activeIndex.toString()) {
          item.classList.add('active');
        } else {
          item.classList.remove('active');
        }
      }
    },
    // 切换轮播图
    changeSwiper(index) {
      this.swiper.slideTo(index, 1000, false);
    },
    // 轮播图滑动
    swiperSlideChange() {
      this.swiper.slideTo(1, 1000, false);
    },
    waitTask() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    }
  }
};
