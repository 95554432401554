/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2023-01-17 12:32:29
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2024-09-03 11:23:44
 * @FilePath: /adidas-com-front/packages/nuxt/store/clp.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import deepcopy from 'deepcopy';

const state = () => ({
  componentsData: [],
  feedProductList: [],
  cachedContentId: '',
  hasPreviewPermission: false,
  seoInfo: {},
  uniquePlay: false,
  currentVideoPostion: '',
  contentCode: '',
  pplpData: [],
  tabPageProductList: [],
  allPreProductData:{},
  pplpPage: 1,
  shareData: {},
  // 通过search接口获取的productListMap
  plpListMap: [],
  // 通过articleIdList批量获取的productList
  articleList: [],
  surveyMemberOnlyConfig: [],
  // 小程序跳转方式配置
  pathConfig: {}
});

const mutations = {
  SET_SURVEY_MEMBER_ONLY_CONFIG(state, status) {
    state.surveyMemberOnlyConfig = status;
  },
  SET_PPLP_PAGE(state, status) {
    state.pplpPage = status;
  },
  SET_TABPAGE_PRODUCTLIST(state, status) {
    state.tabPageProductList = status;
  },
  SET_PRE_PRODUCTDATA(state, status) {
    state.allPreProductData = status;
  },
  SET_COMPONENTS_DATA(state, status) {
    state.componentsData = status;
  },
  SET_FEED_PRODUCT(state, status) {
    state.feedProductList = status;
  },
  SET_CACHE_PAGE_ID(state, pageId) {
    state.cachedContentId = pageId;
  },
  SET_CONTENT_CODE(state, code) {
    state.contentCode = code;
  },
  // 小程序跳转方式
  SET_PATH_CONFIG(state, pathConfig) {
    state.pathConfig = pathConfig;
  },
  // 页面有无预览权限
  SET_PREVIEW_PERMISSION(state, status) {
    state.hasPreviewPermission = status;
  },

  // seo数据
  SET_SEO_INFO(state, status) {
    state.seoInfo = status;
  },
  SET_UNIQUEPLAY(state, status) {
    state.uniquePlay = status;
  },
  SET_CURRENT_VIDEO_POSITION(state, position) {
    state.currentVideoPostion = position;
  },
  SET_PPLP_DATA(state, data) {
    state.pplpData.push(data);
  },
  SET_SHARE_DATA(state, data) {
    state.shareData = data;
  },
  SET_PLP_LIST(state, data) {
    const list = deepcopy(state.plpListMap);
    list.push(...data);
    state.plpListMap = unique(list);
  },
  SET_ARTICLE_LIST(state, data) {
    const res = new Map();
    const list = deepcopy(state.articleList);
    list.push(...data);
    state.articleList = list.filter((item) => !res.has(item.articleId) && res.set(item.articleId, 1));
  }
};

const actions = {
};
function unique (arr1) {
  const res = new Map();
  return arr1.filter((item) => !res.has(item.link) && res.set(item.link, 1));
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
