/*
 * @Author: Li.Zou
 * @Date: 2021-09-09 13:30:32
 * @LastEditTime: 2022-06-28 14:03:59
 * @Description: tdk优化
 * @FilePath: \adidas-com-front\packages\nuxt\plugins\mixin.js
 */
import Vue from 'vue';
import { installDialogService } from '@adidas-com-front/ui-library/src/components/atoms/LibDialog/LibDialog.service';
import { installGlobalUtils } from '@adidas-com-front/ui-library/src/utilities/help';
import { installLogin } from '../components/login-register/LibLogin.service';
Vue.mixin({
  methods: {
    $seoPlp(productName, payload = []) {
      return {
        title: `阿迪达斯-${productName}`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: `阿迪达斯,阿迪达斯${productName},adidas,adidas${productName}`
          },
          {
            hid: 'description',
            name: 'description',
            content: `欢迎登录adidas官网，选购${productName}系列专业运动装备产品，及时了解品牌产品最新动态，下载adidasAPP，限量尖货，等你解锁。成为adiCLUB会员，即刻去创造。`
          }
        ].concat(payload)
      };
    },
    $seoPdp(productName, description, payload = []) {
      return {
        title: `阿迪达斯-${productName} | adidas`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: `阿迪达斯,adidas,${productName}`
          },
          {
            hid: 'description',
            name: 'description',
            content: `欢迎登录adidas官网，${description}，下载adidasAPP，限量尖货，等你解锁。成为adiCLUB会员，即刻去创造。`
          }
        ].concat(payload)
      };
    },

    $seoNews(pageName, content, payload = []) {
      const first = content.indexOf('。');
      const end = first !== -1 ? content.indexOf('。', first + 1) : -1;
      const desc = end !== -1 ? content.slice(0, end) : content;
      return {
        title: `阿迪达斯-${pageName} | adidas`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: `阿迪达斯,阿迪达斯${pageName},adidas,adidas${pageName}`
          },
          {
            hid: 'description',
            name: 'description',
            content: `欢迎登录adidas官网，${desc}。下载adidasAPP，限量尖货，等你解锁。成为adiCLUB会员，即刻去创造。`
          }
        ].concat(payload)
      };
    }
  }
});
console.log('plugins mixin');
installDialogService(Vue);
installGlobalUtils(Vue);
installLogin(Vue);
