
import { LibInput, LibButton } from '@adidas-com-front/ui-library';
export default {
  name: 'ForgetPassword',
  components: {
    LibInput,
    LibButton
    // LibToast,
  },
  props: {
    preStep: {
      type: Number
    },
    isMobile:{
      type: Boolean
    }
  },
  data() {
    return {
      show: true,
      // 确认步数
      nowStep: 0,
      // 当前标签标识
      telSign: null,
      existed: false,
      verifyCodeSign: null,
      newpasswordSign: null,
      certainPasswordSign: null,
      tel: null,
      verifyCode: '',
      newpassword: null,
      certainPassword: null,
      isdisabled: true,
      currentcheck: 0,
      mobile: true,
      apiErrMessage: null,
      buttonLabel: [{ text: '前往验证' }, { text: '重置' }],
      // 检验当前的验证码校验
      nowRecheck: null,
      reset: false,
      btnLoading: false,
      certainPasswordError:"",
      forgetResetFail: false
    };
  },
  computed: {
    label(){
      return "发送至"+this.tel;
    }
  },
  watch: {
    nowStep(newVal){
      this.updateTitle();
    },
    preStep(newVal,preVal) {
      this.nowStep = newVal;
      if(preVal>newVal){
        this.isdisabled=false;
      }
      // this.$emit('changeStep', !!val);
    },
    reset(newValue) {
      if(newValue){
        this.reset=false;
      }
    },
    newpassword(){
      if(!this.apiErrMessage){
        this.checkNewpassword(this.forgetResetFail);
      }
    },
    verifyCode(){
      if(!this.apiErrMessage){
        this.checkVerifyCode(this.forgetResetFail);
      }
    },
    certainPassword(){
      if(!this.apiErrMessage){
        this.checkCertainPassword(this.forgetResetFail);
      }
    }
  },
  created() {},
  mounted() {
    // created() {}报错可能与服务器渲染顺序有关系
    window.addEventListener('resize', this.getWindowsWidthAndHeight);
    this.getWindowsWidthAndHeight();
    this.updateTitle();
    this.$nextTick(()=>{
      this.$refs.tel.focusInput();
    });
  },
  beforeDestroy() {
    this.tel = null;
    this.newpassword = null;
    this.certainPassword = null;
    this.verifyCode = '';
    window.removeEventListener('resize', this.getWindowsWidthAndHeight);
  },
  methods: {
    updateTitle(){
      if(this.nowStep===0){
        // this.$emit('handleTitle', '输入手机号');
        this.$emit('handleTitle', '重置密码');
      }else if(this.nowStep===1){
        // this.$emit('handleTitle', '验证码已发送至 '+this.tel);
        this.$emit('handleTitle', '重置密码');
      }
    },
    onPhoneNumberChange(e) {},
    checkTel(e) {
      // console.log("checkTel",this.newpasswordSign,this.verifyCodeSign,this.newpasswordSign,this.certainPasswordSign);
      if (e) {
        this.telSign = false;
        this.isdisabled = true;
      } else {
        this.telSign = true;
        this.isdisabled = false;
      }
    },
    checkVerifyCode(e) {
      // console.log("checkVerifyCode",this.newpasswordSign,this.verifyCodeSign,this.newpasswordSign,this.certainPasswordSign);
      e ? (this.nowRecheck = true) : (this.nowRecheck = false);
      if (e) {
        this.verifyCodeSign = false;
        this.isdisabled = true;
      } else {
        this.verifyCodeSign = true;
        this.verifyCodeSign === true &&
        this.newpasswordSign === true &&
        this.certainPasswordSign === true
          ? (this.isdisabled = false)
          : (this.isdisabled = true);
      }
    },
    checkNewpassword(e) {
      // console.log("checkNewpassword",this.newpasswordSign,this.verifyCodeSign,this.newpasswordSign,this.certainPasswordSign);
      if (e) {
        this.newpasswordSign = false;
        this.isdisabled = true;
      } else {
        this.newpasswordSign = true;
        this.verifyCodeSign === true &&
        this.newpasswordSign === true &&
        this.certainPasswordSign === true
          ? (this.isdisabled = false)
          : (this.isdisabled = true);
      }
    },
    checkCertainPassword(e) {
      // console.log("checkCertainPassword e",e,typeof(e),this.certainPasswordSign,this.verifyCodeSign,this.newpasswordSign,this.certainPasswordSign);
      if (e) {
        this.certainPasswordSign = false;
        this.isdisabled = true;
      } else {
        // console.log("checkCertainPassword 2",e,typeof(e),this.certainPasswordSign,this.verifyCodeSign,this.newpasswordSign,this.certainPasswordSign);
        this.certainPasswordSign = true;
        this.verifyCodeSign === true &&
        this.newpasswordSign === true &&
        this.certainPasswordSign === true
          ? (this.isdisabled = false)
          : (this.isdisabled = true);
      }
    },
    // 监听windows参数
    getWindowsWidthAndHeight() {
      this.mobile = window.innerWidth < 720;
    },
    onFocus() {
      if (this.mobile && this.nowRecheck === false) {
        const contentWidth = document.getElementById('contentPas').offsetTop;
        if (this.newpasswordSign && this.certainPasswordSign) {
          this.$refs.content.style.height = '276px';
        } else if (
          this.newpasswordSign === null &&
          this.certainPasswordSign === null
        ) {
          this.$refs.content.style.height = '276px';
        } else if (
          this.newpasswordSign === false &&
          this.certainPasswordSign === false
        ) {
          this.$refs.content.style.height = '316px';
        } else {
          this.$refs.content.style.height = '296px';
        }
        this.$refs.content.scrollTo(0, contentWidth);
      }
    },
    onBlurCertainPassword(){
      if(this.newpassword!==this.certainPassword){
        // this.certainPasswordError='请与新密码保持一致';
      }else{
        // this.certainPasswordError="";
      }
      // this.$refs.certainPassword&&this.$refs.certainPassword.handleBlur();
      this.onBlur();
    },
    onBlur() {
      this.$refs.content.style.height = 'auto';
    },
    next() {
      if (!this.isdisabled) {
        if (this.nowStep === 1) {
          this.endFind();
        } else {
          this.isdisabled = true;
          this.gotoNext();
        }
      }
    },
    // 验证接口
    async gotoNext() {
      this.btnLoading = true;
      try {
        const data = await this.$axios.get(
          `${this.$config.ANA_API_HOST}/v1/users/exist/${this.tel}`,
          {
            params: {
              type: 'phone'
            }
          }
        );
        if (data && data.data && this.btnLoading) {
          if (data.data.existed) {
            this.verifyCode = '';
            this.newpassword = null;
            this.certainPassword = null;
            this.nowRecheck = null;
            await this.sendVerifyCode();
          } else {
            this.$Toast({ message: '用户异常' ,ref:this.isMobile?null:this.$refs.content});
          }
        }
      } catch (e) {
        this.$emit('networkErr', e);
        if (e.data?.errmsg) this.$Toast({ message: e.data.errmsg ,ref:this.isMobile?null:this.$refs.content});
      }
      this.btnLoading = false;
    },
    // 验证码内部接口
    async sendVerifyCode() {
      this.btnLoading = true;
      try {
        const data = await this.$axios.get(`${this.$config.ANA_API_HOST}/v1/sms/otp/send`, {
          params: {
            phone: this.tel,
            type: '10007'
          }
        });
        if (data && data.data && this.btnLoading) {
          if (this.nowStep === 0) {
            this.$emit('changeStep');
            // this.autoFocus();
          }
          setTimeout(() => {
            this.$refs.verifyCode.startCountDown();
            this.$refs.verifyCode.focusInput();
          }, 0);
        }
      } catch (e) {
        this.$emit('networkErr', e);
        if (e.data?.errcode) {
          if (e.data.errcode === 4000024) {
            this.apiErrMessage = '验证码发送过于频繁, 请稍后尝试';
            setTimeout(() => {
              this.$refs.verifyCode.startCountDown();
            }, 0);
            if (this.nowStep === 0) {
              this.$emit('changeStep');
              this.nowStep = 1;
              // this.autoFocus();
            }
          }else if (e.data.errcode === 4000026) {
            this.$Toast({
              message: '24小时内验证码发送次数过多，请稍后尝试',
              ref: this.isMobile ? null : this.$refs.boxg
            });
            this.apiErrMessage = '24小时内验证码发送次数过多，请稍后尝试';
          } else if (e.data.errcode === 4000021) {
            this.$Toast({
              message: 'Type传入值不在枚举值中',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          }else if (e.data.errcode === 4000025) {
            this.$Toast({
              message: '短信验证码生成失败',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          }else if (e.data.errcode === 4000029) {
            this.$Toast({
              message: '短信发送内部错误',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          }else if (e.data.errcode === 4000022) {
            this.$Toast({
              message: '手机号为空',
              ref: this.isMobile ? null : this.$refs.boxg
            });
            this.apiErrMessage = '手机号为空';
          }else if (e.data.errcode === 4000023) {
            this.$Toast({
              message: '手机号格式错误',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          } else {
            this.$Toast({
              message: e.data.errmsg,
              ref:this.isMobile?null:this.$refs.content
            });
          }
        } else if (e.data?.errmsg) {
          this.$Toast({
            message: e.data.errmsg,
            ref:this.isMobile?null:this.$refs.content
          });
        }
      }
      this.btnLoading = false;
    },
    // 跳出找回
    async endFind() {
      this.reset=false;
      this.btnLoading = true;
      try {
        const data = await this.$axios.post(
          `${this.$config.ANA_API_HOST}/v1/users/password/reset`,
          this.$qs.stringify({
            phone: this.tel,
            smsOtp: this.verifyCode,
            newPassword: this.newpassword,
            confirmation: this.certainPassword
          }),
          {
            'api-key': 'YW5hLXNlcnZpY2UtYm9zLXNlY3JldC1zaXQ=',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        );
        if (data && data.data && this.btnLoading) {
          this.$emit('endReset');
        }
      } catch (e) {
        this.$emit('networkErr', e);
        if (e.data?.errcode) {
          const text = e.data.errcode === 4000056 ? '重置密码不成功，请保证新密码与确认密码保持一致' : '重置密码不成功,请重新再试';
          this.$Toast({
            message: text,
            ref:!this.isMobile ? this.$refs.content : null
          });
        } else if (e.data?.errmsg) {
          this.$Toast({
            message: e.data.errmsg,
            ref:this.isMobile?null:this.$refs.content
          });
        }
        this.verifyCode = '';
        this.newpassword = null;
        this.certainPassword = null;
        this.nowRecheck = null;
        this.isdisabled = true;
        this.reset= true;
        this.forgetResetFail = true;
      }
      this.btnLoading = false;
    }
    // autoFocus(){
    //   this.$nextTick( () =>{
    //     this.$refs.verifyCode.focusInput();
    //   });
    // },
  }
};
