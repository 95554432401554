
import deepcopy from 'deepcopy';
import { LibButton } from '@adidas-com-front/ui-library';
import { mapState } from 'vuex';
import { trackClpSurveyResultView, trackClpSurveySubmit } from './track/index';
import { colorHexToRgb } from '~/utilities/helper/colorHelper';
import {BasicUtils} from '~/utilities/basic/utils';
import { AppLogin } from "~/utilities/basic/appLogin";
import { TokenUtils } from '~/utilities';
import MemberGuide from '~/components/clp/survey/memberPopover.vue';
import {
  getDistinctIds
} from '~/utilities/vendors/sensors';
  export default {
    components: {
      LibButton,
      MemberGuide
    },
    props: {
      question: {
        type: Object,
        default: () => {
          return {};
        }
      },
      repeatFillingFlag: {
        type: Boolean,
        default: false
      },
      surveyCode: {
        type: String,
        default: ""
      },
      surveyDetail: {
        type: Object,
        default: null
      },
      surveyResult: {
        type: Object,
        default: null
      },
      wholeSurveySubmited: {
        type: Boolean,
        default: false
      },
      refreshWholeSurvey: {
        type: Boolean,
        default: false
      },
      answerInfoWholeSubmited: {
        type: Array,
        default: () => {
          return [];
        }
      },
      wholeSurveySubmitData: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        optionList: [],
        submited: false,
        selectedItemList: [],
        selectedItem: null,
        wholeResultClassName: '',
        isIos: false,
        showWholeReplyAnimation: false,
        wholeReplyAnimationEnd: true,
        showOptions: true,
        wholeReplyStyle: {top: 0, height: 0},
        showRepeatSelect: false,
        optionsHeight: null,
        wholeReplyImgHeight: null,
        isAndroidChrome: false,
        computeCompleted: false,
        questionImgHeight: 0,
        windowWidth: 0,
        isPcQQBrowser: false,
        showMemberGuide: false,
        utils: null
      };
    },
    computed: {
      submitAnswerFlag() {
        return this.surveyDetail.submitAnswerFlag;
      },
      isAdiclub() {
        return this.$route.path.includes('/AC');
      },
      noOptionAndTitle() {
        const filterList = this.optionList.filter((item) => {return item.optionText || item.optionImageUrl || item.productImage;});
        return !filterList.length && !this.questionDescDetail.title && !this.questionDescDetail.subtitle;
      },
      ...mapState('myAccount', [
        'isLogin',
        'isEmployeeLogin',
        'isNonmemberLogin'
      ]),
      questionDescDetail() {
        return this.question.questionDescriptionDetail;
      },
      maxNumInMulti() {
        return this.question.maximumNum;
      },
      minNumInMulti() {
        return this.question.atLeastNum;
      },
      dynamicReplyFlag() {
        return this.questionDescDetail.dynamicReplyFlag;
      },
      subtitleStyle() {
        return {
          color: this.questionDescDetail.subtitleColor
        };
      },
      titleStyle() {
        return {
          color: this.questionDescDetail.titleColor
        };
      },
      choiceStyle() {
        if (this.$utils.device.isMobile()) {
          return {
            backgroundImage: this.questionDescDetail.mobBackgroundImage ? `url(${this.questionDescDetail.mobBackgroundImage})` : '',
            backgroundPosition: 'top center',
            backgroundRepeatY: 'repeat',
            backgroundRepeatX: 'no-repeat',
            backgroundSize: '100%',
            backgroundColor: this.questionDescDetail.questionBackgroundColor
          };
        } else {
          return {
            backgroundColor: this.questionDescDetail.questionBackgroundColor
          };
        }
      },
      choiceBgStyle() {
        if (!this.$utils.device.isMobile()) {
          return {
            backgroundImage: this.questionDescDetail.pcBackgroundImage ? `url(${this.questionDescDetail.pcBackgroundImage})` : '',
            backgroundPosition: 'center top',
            backgroundSize: '100%',
            backgroundRepeatX: 'no-repeat',
            backgroundRepeatY: 'repeat',
            maxWidth: '1440px',
            marginLeft: 'auto',
            marginRight: 'auto'
          };
        } else {
          return {};
        }
      },
      hasProduct() {
        return this.optionList.find((item) => {
          return item.productImage;
        });
      },
      hasOptionImage() {
        return this.optionList.find((item) => {
          return item.optionImageUrl || item.productImage;
        });
      },
      hasOptionReplyImage() {
        return this.optionList.find((item) => {
          return item.replyImageUrl || item.replyProductImage;
        });
      },
      isMultiple() {
        return !!this.question.multipleChoiceFlag;
      },
      isBlock() {
        if (window.innerWidth < 720) {
          return true;
        } else {
          return false;
        }
      },
      ctaStatus() {
        const selectedList = this.optionList.filter((item) => {return item.selected;});
        if (this.isMultiple) {
          return selectedList.length < this.minNumInMulti;
        } else {
          return !(selectedList.length > 0 && selectedList.length < 2);
        }
      },
      showWholeReply() {
        const noneReplyItem = this.selectedItemList.find((item) => {return !item.replyText && !item.replyImageUrl && (!item.replyArticleList || !item.replyArticleList?.length) && !item.replyCategoryCode;});
        if (!this.submitAnswerFlag) {
          if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
            return !!(noneReplyItem && this.submited && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
          } else {
            return !!(this.selectedItem && !this.selectedItem.replyText && !this.selectedItem.replyImageUrl && (!this.selectedItem.replyArticleList || !this.selectedItem.replyArticleList?.length) && !this.selectedItem.replyCategoryCode && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
          }
        }
        else if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
          return !!(noneReplyItem && this.wholeSurveySubmited && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
        } else {
          return !!(this.wholeSurveySubmited && this.selectedItem && !this.selectedItem.replyText && !this.selectedItem.replyImageUrl && (!this.selectedItem.replyArticleList || !this.selectedItem.replyArticleList?.length) && !this.selectedItem.replyCategoryCode && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
        }
      },
      // showWholeReply() {
      //   if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
      //     const noneReplyItem = this.selectedItemList.find((item) => {return !item.replyText && !item.replyImageUrl && !item.replyProductImage;});
      //     return noneReplyItem && this.submited && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText);
      //   } else {
      //     return this.selectedItem && !this.selectedItem.replyText && !this.selectedItem.replyImageUrl && !this.selectedItem.replyProductImage && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText);
      //   }
      // },
      wholeReplyText() {
        return this.questionDescDetail.replyText || '感谢您的参与！';
      },
      wholeReplyTextStyle() {
        return {
          color: this.questionDescDetail.replyTextColor
        };
      },
      surveyBgSingle() {
        return !this.submitAnswerFlag && this.repeatFillingFlag && (!this.isMultiple && !this.questionDescDetail.submitButtonFlag) && !this.showWholeReply;
      },
      surveyBgMulti() {
        return !this.submitAnswerFlag && this.repeatFillingFlag && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.submited && !this.showWholeReply;
      },
      surveyBgMultiNoReset() {
        return !this.submitAnswerFlag && !this.repeatFillingFlag && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.submited && !this.showWholeReply;
      },
      isShowResetText() {
        return (!this.submitAnswerFlag && !this.dynamicReplyFlag && this.repeatFillingFlag && !this.isMultiple && this.selectedItem) ||
          (!this.submitAnswerFlag && !this.dynamicReplyFlag && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.submited && this.repeatFillingFlag) ||
          (!this.submitAnswerFlag && this.dynamicReplyFlag && this.computeCompleted && this.repeatFillingFlag && (((this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.submited) || (!this.isMultiple && this.selectedItem)));
      },
      buttonBgStyle() {
        const bgColor = this.questionDescDetail.submitButtonColor;
        if (bgColor && !this.ctaStatus) {
          return {
            backgroundColor: bgColor
          };
        } else {
          return {};
        }
      },
      isShowSubmitBtn() {
        if (this.isMultiple) {
          return !this.submitAnswerFlag && this.isMultiple && !this.submited;
        } else {
          return !this.submitAnswerFlag && this.questionDescDetail.submitButtonFlag && !this.submited;
        }
      },
      isStaticUnified() {
        return this.questionDescDetail.staticReplyType === 'Unified';
      }
    },
    watch: {
      surveyResult(newVal, oldVal) {
        if(newVal) {
          this.$nextTick(async () => {
            const height = this.$refs.surveyTitle?.offsetHeight;
            const buffer = this.windowWidth < 720 ? 8 : 40;
            const top = await this.loadQuestionImg(height, buffer, 20);
            this.wholeReplyImgHeight = await this.loadReplyImg();
            this.optionsHeight = this.$refs.options?.clientHeight || 0;
            this.wholeReplyStyle = { top:  top + 'px', height: this.optionsHeight + 'px' };
            if (this.showWholeReply) {
              this.animateWholeReply();
            }
            this.getLastResult();
          });
        }
      },
      showWholeReply(newValue, oldValue) {
        if (newValue) {
          this.animateWholeReply();
        }
      },
      answerInfoWholeSubmited(newValue, oldValue) {
        if (newValue.length) {
          this.rotateOption();
          this.computeDynamicData(newValue);
          this.computeCompleted = true;
        }
      },
      refreshWholeSurvey(newValue, oldValue) {
        if (newValue) {
          this.resetSurvey();
        }
      }
    },
    async mounted () {
      this.utils = new BasicUtils(this);
      this.computeCompleted = true;
      const u = navigator.userAgent.toLowerCase();
      this.isIos = !!u.match(/macintosh|mac os x/i);
      this.isVivo = !!u.match(/vivobrowser/i);
      this.isAndroidChrome = !!u.includes('android') && (!!u.includes('chrome/78.0.3904.96') || !!u.includes('chrome/81'));
      if (!this.isAdiclub) {
        await this.getProductList();
      } else {
        this.getOptionList();
      }
      // this.loadImg();
      this.$nextTick(async () => {
        const height = this.$refs.surveyTitle?.offsetHeight;
        const buffer = this.windowWidth < 720 ? 8 : 40;
        const top = await this.loadQuestionImg(height, buffer, 20);
        this.wholeReplyImgHeight = await this.loadReplyImg();
        this.optionsHeight = this.$refs.options?.clientHeight || 0;
        this.wholeReplyStyle = { top:  top + 'px', height: this.optionsHeight + 'px' };
        if (this.showWholeReply) {
          this.animateWholeReply();
        }
        this.getLastResult();
      });
      this.onResize();
      window.addEventListener('resize', this.onResize);
      this.isPcQQBrowser = this.windowWidth > 720 && !u.match(/macintosh|mac os x/i) && !!u.match(/qqbrowser/i);
    },
    methods: {
      isCurrentItemSubmited(item) {
        const itemSubmited = !this.submitAnswerFlag && this.submited &&
        (
          ((this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.selectedItemList.find((it) => { return it.optionCode === item.optionCode;})) ||
          (!this.isMultiple && this.selectedItem && this.selectedItem.optionCode === item.optionCode)
        );
        return !!itemSubmited;
      },
      isWholeSurveySubmited() {
        return this.submitAnswerFlag && this.wholeSurveySubmited;
      },
      isCurrentWholeSubmited(item) {
        const wholeSubmited = this.submitAnswerFlag && (
          (this.wholeSurveySubmited && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) &&
          this.selectedItemList.find((it) => { return it.optionCode === item.optionCode;})) ||
          (!this.isMultiple && this.selectedItem && this.selectedItem.optionCode === item.optionCode)
        );
        return !!wholeSubmited;
      },
      rotateOption() {
        const questionAnswerInfo = this.wholeSurveySubmitData.questionAnswerInfo.find((item) => {return item.questionCode === this.question.questionCode;});
        if (questionAnswerInfo) {
          const answerList = questionAnswerInfo?.answerList || [];
          this.optionList.forEach(element => {
            const answer = answerList.find((item) => { return item.optionCode === element.optionCode;});
            if (answer) {
              this.$set(element, 'selected', true);
              this.$set(element, 'className', 'rotated');
              this.$set(element, 'submited', true);
            }
          });
        }
    },
      handleGuideChange(val) {
        this.showMemberGuide = val;
      },
      onResize() {
        this.windowWidth = window.innerWidth;
      },
      loadQuestionImg(height, buffer, marginTop = 0) {
        if (this.questionDescDetail.questionImage) {
          const img = new Image();
          img.src = this.questionDescDetail.questionImage;
          return new Promise((resolve, reject) => {
            img.onload = (e) => {
              this.questionImgHeight = this.$refs.questionImg?.offsetHeight || 0;
              const top = height + this.questionImgHeight + buffer + marginTop ;
              resolve(top);
            };
          });
        } else {
          return new Promise((resolve, reject) => {
            const top = height + buffer;
            resolve(top);
          });
        }
      },
      loadReplyImg() {
        if (this.questionDescDetail.replyImage) {
          const img = new Image();
          img.src = this.questionDescDetail.replyImage;
          return new Promise((resolve) => {
            img.onload = (e) => {
              const wholeReplyImgHeight = this.$refs.wholeReplyImg?.clientHeight || 0;
              resolve(wholeReplyImgHeight);
            };
          });
        } else {
          return new Promise((resolve) => {
            const wholeReplyImgHeight = this.$refs.wholeReplyText?.clientHeight || 0;
            resolve(wholeReplyImgHeight);
          });
        }
      },
      animateWholeReply() {
        setTimeout(() => {
          this.showWholeReplyAnimation = true;
        }, 400);
        if (!this.isAndroidChrome && this.wholeReplyImgHeight && !this.isPcQQBrowser) {
          this.$refs.wholeReplyContainer && this.$refs.wholeReplyContainer.animate([{
            height: this.wholeReplyImgHeight ? `${this.wholeReplyImgHeight}px` : 'auto'
          }], {
            duration: 1200,
            easing: 'linear',
            fill: 'forwards',
            delay: 0
          });
        } else {
          this.wholeReplyStyle.height = 'auto';
        }
        setTimeout(() => {
          this.wholeReplyAnimationEnd = false;
          this.showOptions = false;
        }, 1000);
        setTimeout(() => {
          this.showRepeatSelect = true;
        }, 1500);
      },
      optionOnlyHasText(item) {
        return (item.optionText && !item.optionImageUrl) || (item.productImage && item.optionImageUrl) || (item.productImage && !item.optionText);
      },
      getLastResult() {
        if (this.surveyResult) {
          const questionAnswer = this.surveyResult.questionAnswerInfo.find((item) => { return item.questionCode === this.question.questionCode;});
          if (questionAnswer) {
            const answerList = questionAnswer?.answerList || [];
            this.optionList.forEach(element => {
              const answer = answerList.find((item) => { return item.optionCode === element.optionCode;});
              if (answer) {
                this.$set(element, 'selected', true);
                this.$set(element, 'className', 'rotated');
              }
            });
            this.submited = true;
            if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
              this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
            } else {
              this.selectedItem = this.optionList.find((item) => {return item.selected;});
            }
          }
        }
      },
      isCurrentSubmited(item) {
        return !!this.isCurrentItemSubmited(item) || !!this.isCurrentWholeSubmited(item);
      },
      getOptionList() {
        const optionList = deepcopy(this.question.questionDescriptionDetail.optionList);
        optionList.sort((a, b) => { return a.optionPosition - b.optionPosition; });
        optionList.forEach((option, idx) => {
          option.productImage = '';
          option.replyProductImage = '';
        });
        this.optionList = deepcopy(optionList);
      },
      async getProductList() {
        const productPromises = [];
        const replyProductPromises = [];
        const optionList = deepcopy(this.question.questionDescriptionDetail.optionList);
        optionList.sort((a, b) => { return a.optionPosition - b.optionPosition; });
        optionList.forEach((item) => {
          const product = {
            optionCode: item.optionCode,
            articleId: item.optionArticleList?.length ? item.optionArticleList[0] : '',
            categoryCode: item.optionCategoryCode || '',
            replyArticleId: item.replyArticleList?.length ? item.replyArticleList[0] : '',
            replyCategoryCode: item.replyCategoryCode || ''
          };
          if (product.articleId) {
            productPromises.push(this.getProductDetail(product.articleId));
          } else if (product.categoryCode) {
            productPromises.push(this.getPlp(product.categoryCode));
          } else {
            productPromises.push('');
          }

          if (product.replyArticleId) {
            replyProductPromises.push(this.getProductDetail(product.replyArticleId));
          } else if (product.replyCategoryCode) {
            replyProductPromises.push(this.getPlp(product.replyCategoryCode));
          } else {
            replyProductPromises.push('');
          }
        });
        const allProductData = await Promise.all(productPromises);
        const allReplyProductData = await Promise.all(replyProductPromises);
        optionList.forEach((option, idx) => {
          option.productImage = allProductData[idx];
          option.replyProductImage = allReplyProductData[idx];
        });
        this.optionList = deepcopy(optionList);
      },
      getProductDetail(articleId) {
        const params = {
          articleId: articleId
        };
        return new Promise((resolve, reject) => {
          this.$axios
            .get(
              `${this.$config.ECP_API_PRIVATE_HOST}/o2pcm/v1/pub/platform-products/detail`,
              {
                params: { ...params }
              }
            )
            .then( (res) => {
              resolve(res.imageUrlList.length ? res.imageUrlList[0] : '');
            })
            .catch((e) => {
              resolve('');
            });
        });
      },
      getPlp(categoryCode) {
        const params = {
          page: 1,
          pageSize: 10,
          categoryCode: categoryCode,
          abTest: 'A'
        };
        return new Promise((resolve, reject) => {
          this.$axios
            .get(
              `${this.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/search`,
              {
                params: { ...params }
              }
            )
            .then( (res) => {
              resolve(res.content.length ? res.content[0].imageUrl : '');
            })
            .catch((e) => {
              resolve('');
            });
        });
      },
      resetSurvey() {
        this.submited = false;
        this.computeCompleted = false;
        this.selectedItem = null;
        this.selectedItemList = [];
        this.optionList.forEach(element => {
          this.$set(element, 'selected', false);
          this.$set(element, 'className', '');
        });
        this.showWholeReplyAnimation = false;
        this.wholeReplyAnimationEnd = true;
        this.showRepeatSelect = false;
        this.showOptions = true;
        this.$refs.wholeReplyContainer && this.$refs.wholeReplyContainer.animate([{
          height: 'auto'
        }], {
          duration: 0,
          easing: 'linear',
          fill: 'forwards',
          delay: 0
        });
      },
      frontStyle(item) {
        if (this.dynamicReplyFlag) {
          return {
            backgroundImage: (item.optionImageUrl && !item.productImage) || (item.optionImageUrl && item.productImage && (!this.submited || !this.wholeSurveySubmited)) ? `url(${item.optionImageUrl})` : '',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          };
        } else {
          return {
            backgroundImage: item.optionImageUrl ? `url(${item.optionImageUrl})` : '',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          };
        }

      },
      backStyle(item) {
        if (item.replyImageUrl) {
          return {
            backgroundImage: item.replyImageUrl ? `url(${item.replyImageUrl})` : '',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          };
        } else {
          // return {
          //   backgroundImage: item.optionImageUrl ? `url(${item.optionImageUrl})` : '',
          //   backgroundPosition: 'center',
          //   backgroundSize: 'cover'
          // };
        }

      },
      computedStyle(item) {
        return {
          color: item.optionTextColor
        };
      },
      dynamicBarColor(item) {
        if (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item)) {
          return {
            backgroundColor: this.questionDescDetail.proportionColor ? colorHexToRgb(this.questionDescDetail.proportionColor, 1) : ''
          };
        } else {
          return {

          };
        }
      },
      dynamicPercent(item) {
        if ((this.submited || this.wholeSurveySubmited) && this.computeCompleted) {
          return {
            width: item.productImage ? `calc((100% - 72px) * ${parseInt(item.percentStr)/100})`: item.percentStr,
            left: item.productImage ? '72px' : 0,
            backgroundColor: this.questionDescDetail.proportionColor && (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item)) ? colorHexToRgb(this.questionDescDetail.proportionColor, 1) : ''
          };
        } else {
          return {
          };
        }
      },
      dynamicTheme(item) {
        if (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item)) {
          return {
            backgroundColor: this.questionDescDetail.proportionColor ? colorHexToRgb(this.questionDescDetail.proportionColor, 0.5) : 'rgba(31, 131, 248, 0.5)'
          };
        } else {
          return {

          };
        }
      },
      handleData(item) {
        if (this.submited)
          return;
        if (this.isMultiple) {
          if (this.selectedItemList.length >= this.maxNumInMulti && !item.selected) {
            this.$Toast({ message: `只能选择${this.maxNumInMulti}个选项` });
          } else {
            this.optionList.forEach(element => {
              if (element.optionCode === item.optionCode) {
                this.$set(element, 'selected', !item.selected);
              }
            });
            this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
          }
        } else if (this.questionDescDetail.submitButtonFlag) {
            this.optionList.forEach(element => {
              if (element.optionCode === item.optionCode) {
                this.$set(element, 'selected', !item.selected);
              }
            });
            this.optionList.forEach(element => {
              if (element.optionCode !== item.optionCode) {
                this.$set(element, 'selected', false);
              }
            });
          this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
        } else {
          this.optionList.forEach(element => {
            this.$set(element, 'selected', false);
            this.$set(element, 'className', '');
            if (element.optionCode === item.optionCode) {
              this.$set(element, 'selected', true);
              this.$set(element, 'className', 'rotated');
            }
          });
          this.selectedItem = this.optionList.find((item) => {return item.selected;});
          this.submited = true;
          trackClpSurveySubmit({
            campaignName: this.$route.query.contentId,
            componentCode: 'Survey',
            positionCode: this.question.questionPosition,
            questionType: this.question.questionType,
            surveyId: this.surveyCode,
            questionId: this.question.questionCode,
            submitDetail: item.optionDescribe,
            articleIdList: item.optionArticleList?.length ? item.optionArticleList[0] : '',
            categoryCodeList: item.optionCategoryCode || '',
            is_memer_only: !!this.surveyDetail.replyMemberFlag
          });
          trackClpSurveyResultView({
            campaignName: this.$route.query.contentId,
            componentCode: 'Survey',
            positionCode: this.question.questionPosition,
            questionType: this.question.questionType,
            surveyId: this.surveyCode,
            questionId: this.question.questionCode,
            submitDetail: item.optionDescribe,
            articleIdList: item.optionArticleList?.length ? item.optionArticleList[0] : '',
            categoryCodeList: item.optionCategoryCode || '',
            resultType: this.dynamicReplyFlag ? 'dynamic': (this.showWholeReply ? 'for all' : 'by choice'),
            pageTitle: 'submit',
            is_memer_only: !!this.surveyDetail.replyMemberFlag
          });
          this.submitResult();
        }
      },
      async handleMemberLogin(){
        const utils = new BasicUtils(this);
        if (utils.isMemberLogin()) {
          // this.$Toast({message: "您已登录为会员"});
          // this.handleData(item);
        } else if (utils.getEmployeeId()) {
          this.$Toast({message: "抱歉，员工无法参与"});
        } else {
          const utils=new BasicUtils(this);
          if ((utils.IsAPP && window.AdidasApp) || utils.IsWMS || utils.IsOR || utils.IsAdiclub) {
            this.showMemberGuide = true;
          } else {
            const firstLogin = await utils.loginByEnvAsync({
              redirectUrl: location.href,
              memberOnly: true,
              loginClp: true,
              title: window.innerWidth < 720 ? '会员专属内容' : '登录即可解锁互动玩法',
              desc: window.innerWidth < 720 ? '加入会员即可解锁趣味玩法/专属活动等精彩内容' : ''
            });
            if (firstLogin) {
              this.$emit('refreshLastResult');
            }
          }
        }
      },
      handleClick(item) {
        if(this.surveyDetail.replyMemberFlag) {
          this.utils.isMemberLogin() ? this.handleSubmitData(item) : this.handleMemberLogin();
        } else {
          this.handleSubmitData(item);
        }
      },
      handleSubmitData(item) {
        if (!this.submitAnswerFlag) {
          // 非整卷提交
          this.handleData(item);
        } else {
          // 整卷提交
          if (this.wholeSurveySubmited)
            return;
          this.beforeWholeSurveySubmit(item);
          console.log('整卷提交', this.selectedItemList, this.selectedItem);
          const noneReplyItem = this.selectedItemList.find((item) => {return !item.replyText && !item.replyImageUrl && (!item.replyArticleList || !item.replyArticleList?.length) && !item.replyCategoryCode;});
          let flag;
          if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
            flag = !!(noneReplyItem && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
          } else {
            flag = !!(this.selectedItem && !this.selectedItem.replyText && !this.selectedItem.replyImageUrl && (!this.selectedItem.replyArticleList || !this.selectedItem.replyArticleList?.length) && !this.selectedItem.replyCategoryCode && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
          }
          this.$emit('wholeSubmit', {
            selectedItemList: this.selectedItemList,
            selectedItem: this.selectedItem,
            optionCode: item.optionCode,
            questionCode: this.question.questionCode,
            questionType: this.question.questionType,
            surveyCode: this.surveyCode,
            showWholeReply: flag
          });
        }
      },
      // 整卷提交数据装饰
      beforeWholeSurveySubmit(item) {
        if (this.isMultiple) {
          if (this.selectedItemList.length >= this.maxNumInMulti && !item.selected) {
            this.$Toast({ message: `只能选择${this.maxNumInMulti}个选项` });
          } else {
            this.optionList.forEach(element => {
              if (element.optionCode === item.optionCode) {
                this.$set(element, 'selected', !item.selected);
                setTimeout(() => {
                  this.$set(element, 'submited', true);
                }, 500);
              }
            });
            this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
          }
        } else if (this.questionDescDetail.submitButtonFlag) {
          this.optionList.forEach(element => {
            if (element.optionCode === item.optionCode) {
              this.$set(element, 'selected', !item.selected);
            }
          });
          this.optionList.forEach(element => {
            if (element.optionCode !== item.optionCode) {
              this.$set(element, 'selected', false);
            }
          });
          this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
        } else {
          this.optionList.forEach(element => {
            this.$set(element, 'selected', false);
            this.$set(element, 'className', '');
            this.$set(element, 'submited', false);

            if (element.optionCode === item.optionCode) {
              this.$set(element, 'selected', true);
              this.$set(element, 'className', 'rotated');
              setTimeout(() => {
                this.$set(element, 'submited', true);
              }, 500);
            }
          });
          this.selectedItem = this.optionList.find((item) => {return item.selected;});
        }
      },
      handleCtaAlert() {
        const selectedList = this.optionList.filter((item) => {return item.selected;});
        if (selectedList.length < this.minNumInMulti) {
          this.$Toast({ message: `请至少选择${this.minNumInMulti}个选项` });
        }
      },
      handleNativeClick() {
        if(this.surveyDetail.replyMemberFlag) {
          this.utils.isMemberLogin() ? this.handleCtaAlert() : this.handleMemberLogin();
        } else {
          this.handleCtaAlert();
        }
      },
      handleSubmit() {
        this.submited = true;
        this.optionList.forEach(element => {
          if (element.selected) {
            this.$set(element, 'className', 'rotated');
          }
        });
        const submitDetail = this.optionList.filter((item) => {
          return item.selected && item.optionDescribe;
        }).map((item) => {
          return item.optionDescribe;
        });
        const articleIdList = this.optionList.filter((item) => {
          return item.selected && item.optionArticleList?.length;
        }).map((item) => {
          return item.optionArticleList[0];
        });
        const categoryCodeList = this.optionList.filter((item) => {
          return item.selected && item.optionCategoryCode;
        }).map((item) => {
          return item.optionCategoryCode;
        });
        trackClpSurveySubmit({
          campaignName: this.$route.query.contentId,
          componentCode: 'Survey',
          positionCode: this.question.questionPosition,
          questionType: this.question.questionType,
          surveyId: this.surveyCode,
          questionId: this.question.questionCode,
          submitDetail: submitDetail.join('|'),
          articleIdList: articleIdList.join('|'),
          categoryCodeList: categoryCodeList.join('|'),
          is_memer_only: !!this.surveyDetail.replyMemberFlag
        });
        trackClpSurveyResultView({
          campaignName: this.$route.query.contentId,
          componentCode: 'Survey',
          positionCode: this.question.questionPosition,
          questionType: this.question.questionType,
          surveyId: this.surveyCode,
          questionId: this.question.questionCode,
          submitDetail: submitDetail.join('|'),
          articleIdList: articleIdList.join('|'),
          categoryCodeList: categoryCodeList.join('|'),
          resultType: this.dynamicReplyFlag ? 'dynamic': (this.showWholeReply ? 'for all' : 'by choice'),
          pageTitle: 'submit',
          is_memer_only: !!this.surveyDetail.replyMemberFlag
        });
        this.submitResult();
      },
      formatResultData() {
        const data = {
          surveyCode: this.surveyCode,
          surveyType: this.surveyDetail.surveyType,
          version: this.surveyDetail.version
        };
        const questionAnswerInfo = [];
        const questionAnswer = {
          questionCode: this.question.questionCode
        };
        let answerList = [];
        if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
          const length = this.selectedItemList.length;
          for(let i = 0; i < length; i++) {
            answerList.push({optionCode: this.selectedItemList[i].optionCode});
          }
          questionAnswer.answerList = answerList;
          questionAnswerInfo.push(questionAnswer);
          data.questionAnswerInfo = questionAnswerInfo;
        } else {
          const answerItem = {optionCode: this.selectedItem.optionCode};
          answerList = [answerItem];
          questionAnswer.answerList = answerList;
          questionAnswerInfo.push(questionAnswer);
          data.questionAnswerInfo = questionAnswerInfo;
        }
        return data;
      },
      computeDynamicData(questionAnswerInfoList) {
        const questionAnswerInfo = questionAnswerInfoList.find((li) => {return li.questionCode === this.question.questionCode;});
        const answerStatisticsList = questionAnswerInfo?.questionAnswerStatistics?.optionStatistics || [];
        const optionList = deepcopy(this.optionList);
        const optionLength = optionList.length;
        const questionAnswerTotalNum = questionAnswerInfo?.questionAnswerStatistics?.questionAnswerTotalNum || 0;
        if (this.dynamicReplyFlag && questionAnswerTotalNum) {
          if (this.question.multipleChoiceFlag) {
            optionList.forEach((option) => {
              const answer = answerStatisticsList.find((answer) => {
                return answer.optionCode === option.optionCode;
              });
              option.percent = answer.optionAnswerNum / questionAnswerTotalNum;
              option.percentStr = Math.floor(option.percent * 100) + '%';
            });
          } else {
            let sum = 0;
            optionList.forEach((option, index) => {
              const answer = answerStatisticsList.find((answer) => {
                return answer.optionCode === option.optionCode;
              });
              if (index === optionLength - 1) {
                option.percent = 1 - sum;
                option.percentStr = Math.ceil(option.percent * 100) + '%';
              } else {
                option.percent = answer.optionAnswerNum / questionAnswerTotalNum;
                sum = option.percent + sum;
                option.percentStr = Math.floor(option.percent * 100) + '%';
              }
            });
          }
        }
        this.optionList = deepcopy(optionList);
      },
      async submitResult() {
        this.computeCompleted = false;
        const resultData = this.formatResultData();
        const utils=new BasicUtils(this);
        const isLogin = !!TokenUtils.getToken();
        const unionId = TokenUtils.getQueryValue('unionId');
        if (utils.IsAPP && window.AdidasApp) {
          const appLogin = new AppLogin(window.AdidasApp);
          const data = await appLogin.getloginInfoAsync();
          !data ? utils.config.headers['x-client-device-id'] = await getDistinctIds() : '';
        } else if((utils.IsWMS || utils.IsOR || utils.IsAdiclub) && !isLogin) {
          utils.config.headers['x-open-id'] = TokenUtils.getQueryValue('openId');
        } else {
          !isLogin && (utils.config.headers['x-client-device-id'] = await getDistinctIds());
        }

        if ((utils.IsWMS || utils.IsOR || utils.IsAdiclub) && unionId) {
          utils.config.headers['x-union-id'] = unionId;
        }

        try {
          const res = await this.$axios.post(
            `${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/surveys/answer-submit`,
            resultData,
            utils.config
          );
          res.questionAnswerInfo && this.computeDynamicData(res.questionAnswerInfo);
          this.computeCompleted = true;
          console.log('survey submit user result', res);
        } catch (error) {
          if (this.dynamicReplyFlag) {
            this.resetSurvey();
          }
          console.log('survey submit error', error);
        }
      }
    }
  };
