/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2022-08-25 12:19:13
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2022-08-25 12:21:03
 * @FilePath: /adidas-com-front/packages/nuxt/store/plp.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const state = () => ({
  selectedTabFilterItem: {}
});

const mutations = {
  SET_SELECTED_TAB_FILTER(state, params) {
    state.selectedTabFilterItem = params;
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
