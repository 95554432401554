import { render, staticRenderFns } from "./SearchPc.vue?vue&type=template&id=629d22e7&scoped=true"
import script from "./SearchPc.vue?vue&type=script&lang=js"
export * from "./SearchPc.vue?vue&type=script&lang=js"
import style0 from "./SearchPc.vue?vue&type=style&index=0&id=629d22e7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "629d22e7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBar: require('/usr/src/app/packages/nuxt/components/search/search-bar/SearchBar.vue').default})
