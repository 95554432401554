
  import LibPopover from '@adidas-com-front/ui-library/src/components/molecules/LibPopover/LibPopover.vue';
  import { copyToClipboard } from '@adidas-com-front/ui-library/src/components/molecules/LibDiscountCard/copyToClipboard';
  import {
    trackCLPTransferHalfwindowClick,
    trackCLPTransferHalfwindowView
  } from '../../../components/clp/track/index';
  export default {
    components: {
      LibPopover
    },
    props: {
      show: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: ''
      },
      appType: {
        type: String
      },
      code: {
        type: String
      }
    },
    data() {
      return {
        isMemberLogin: false,
        dir: 'bottom',
        title: `精彩内容已准备就绪`,
        titleType: 'left',
        titleFont: 'heading-3 text-black',
        icon: 'icon-cross_32',
        iconType: 'right',
        margin: 0,
        zIndex: 998,
        size: 230,
        backToClose: false,
        isMobile: false,
        wms2fsCode: this.$config.S3_COM_HOST + '/assets/clp/qr-code/wms2fs.png',
        wms2cfmCode: this.$config.S3_COM_HOST + '/assets/clp/qr-code/wms2cfm.png',
        ac2cfmCode: this.$config.S3_COM_HOST + '/assets/clp/qr-code/ac2cfm.png',
        ac2fsCode: this.$config.S3_COM_HOST + '/assets/clp/qr-code/ac2fs.png',
        ac2fsLink: `https://analytics.adidas.com.cn/sd/ccc/8Sa/NivWvf`,
        ac2cfmLink: `https://analytics.adidas.com.cn/sd/ddd/PSa/LTPUJz`,
        wms2fsLink: `https://analytics.adidas.com.cn/sd/ccc/VSa/bRaGYj`,
        wms2cfmLink: `https://analytics.adidas.com.cn/sd/ddd/gSa/vO3XfT`
      };
    },
    computed: {
      isIphoneX() {
        if(typeof window !== 'undefined' && window) {
          return /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
        }
        return false;
      },
      popoverSize() {
        return this.isIphoneX ? 245 : this.size;
      },
      isAdiclub() {
        return this.$route.path.includes('/AC');
      },
      scanCode () {
        let code;
        if (this.isAdiclub) {
          code = this.appType === 'bos' ? this.ac2fsCode : this.ac2cfmCode;
        } else {
          code = this.appType === 'bos' ? this.wms2fsCode : this.wms2cfmCode;
        }
        return code;
      },
      appDownloadLink() {
        let link;
        if (this.isAdiclub) {
          link = this.appType === 'bos' ? this.ac2fsLink : this.ac2cfmLink;
        } else {
          link = this.appType === 'bos' ? this.wms2fsLink : this.wms2cfmLink;
        }
        return link;
      }
    },
    watch: {
      show(newValue) {
        if (newValue) {
          try {
            trackCLPTransferHalfwindowView({
              campaign_name: this.$route.query.contentId,
              direct_app_project: this.appType === 'bos' ? 'FS' : 'CFD',
              sub_campaign_name: this.code
            });
          } catch (error) {
          }
        }
      }
    },
    mounted () {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.isMobile = window.innerWidth < 720;
        this.dir = this.isMobile ? 'bottom' : 'right';
      },
      updateShow() {
        this.$emit('close');
      },
      copyLink() {
        const el = this.$utils.screen.whenSmallScreen ? undefined : this.$el;
        copyToClipboard(
          this.appDownloadLink,
          () => {
            this.$Toast({
              message: '链接已复制 请粘贴到浏览器打开',
              ...(el ? { ref: el } : {})
            });
            this.$emit('close');
          },
          () => {
            this.$Toast({
              message: '链接复制失败！',
              ...(el ? { ref: el } : {})
            });
          }
        );
        try {
          trackCLPTransferHalfwindowClick({
            campaign_name: this.$route.query.contentId,
            direct_app_project: this.appType === 'bos' ? 'FS' : 'CFD',
            sub_campaign_name: this.code
          });
        } catch (error) {
        }
      }
    }
  };

