/*
 * @Description: seo 配置文件
 * @Autor: Duan,JingLiang
 * @Date: 2021-10-07 11:09:17
 */

export const setSeo = (type: string, data: any, flag?: boolean) => {
  const productName = data.articleName;
  const title = data.title;
  const keywords = data.keywords;
  // for pdp
  const description = data.description;
  // for clp
  const appId = data.appId || '';
  // for luckydraw
  const image = data.image || '';
  const metaDataClp = [
    {
      hid: 'keyword',
      name: 'keywords',
      content: `${keywords || 'adidas,阿迪达斯,阿迪达斯官方商城,运动装备'}`
    },
    {
      hid: 'description',
      name: 'description',
      content: `${description ||
        '登录adidas官网，选购跑步、训练、足球、篮球等专业运动装备系列产品。下载adidasAPP，限量尖货，等你解锁。成为adiCLUB会员，即刻去创造。'
        }`
    }
  ];
  appId
    ? metaDataClp.push({
      hid: 'apple-itunes-app',
      name: 'apple-itunes-app',
      content: `app-id=${appId}`
    })
    : '';
  switch (type) {
    case 'sizeguide':
      return {
        title: '尺码指南'
      };
    case 'plp':
      return {
        title: flag ? '产品-阿迪达斯官方旗舰店' : `阿迪达斯-${productName}-阿迪达斯官方旗舰店`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: flag
              ? '阿迪达斯,adidas,阿迪达斯官方旗舰店'
              : `阿迪达斯,阿迪达斯${productName},adidas,adidas${productName}`
          },
          {
            hid: 'description',
            name: 'description',
            content: flag
              ? '欢迎登录adidas官网，选购更多专业运动装备，下载adidasAPP，限量尖货，等你解锁。成为adiCLUB会员，即刻去创造。'
              : `欢迎登录adidas官网，选购${productName}系列专业运动装备产品，及时了解品牌产品最新动态，下载adidasAPP，限量尖货，等你解锁。成为adiCLUB会员，即刻去创造。`
          }
        ]
      };
    case 'pdp':
      return {
        title: `阿迪达斯-${productName} | adidas 阿迪达斯官方旗舰店`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: `阿迪达斯,adidas,${productName}`
          },
          {
            hid: 'description',
            name: 'description',
            content: `欢迎登录adidas官网，${description?.text?.text || ''
              } 下载adidasAPP，限量尖货，等你解锁。成为adiCLUB会员，即刻去创造。`
          }
        ]
      };
    case 'homepage':
      return {
        title: title || `阿迪达斯官方旗舰店`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: `${description || 'adidas,阿迪达斯,阿迪达斯官方商城,运动装备'
              }`
          },
          {
            hid: 'description',
            name: 'description',
            content: `${description ||
              '登录adidas官网，选购跑步、训练、足球、篮球等专业运动装备系列产品。下载adidasAPP，限量尖货，等你解锁。成为adiCLUB会员，即刻去创造。'
              }`
          }
        ]
      };
    case 'clp':
      return {
        title: `${flag ? title : (title || '阿迪达斯官方旗舰店')}`,
        meta: [...metaDataClp]
      };

    case 'd_n':
      return {
        title: `医护家庭折扣-阿迪达斯官方旗舰店`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: `${description || '医护家庭折扣'}`
          },
          {
            hid: 'description',
            name: 'description',
            content: `${description || '医护家庭折扣'}`
          }
        ]
      };

    case 'campus':
      return {
        title: `学生权益优惠-阿迪达斯官方旗舰店`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: `${description || '学生权益优惠'}`
          },
          {
            hid: 'description',
            name: 'description',
            content: `${description || '学生权益优惠'}`
          }
        ]
      };

    case 'volunteer':
      return {
        title: `志愿者权益优惠-阿迪达斯官方旗舰店`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: `${description || '志愿者权益优惠'}`
          },
          {
            hid: 'description',
            name: 'description',
            content: `${description || '志愿者权益优惠'}`
          }
        ]
      };

    case 'mtbr':
      return {
        title: `在终点重生-阿迪达斯官方旗舰店`,
        meta: [
          {
            hid: 'keyword',
            name: 'keywords',
            content: `${description || '在终点重生'}`
          },
          {
            hid: 'description',
            name: 'description',
            content: `${description || '在终点重生'}`
          }
        ]
      };
    case 'jointmembership':
      return {
        title: title || `阿迪达斯官方旗舰店`,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: `${description}`
          },
          {
            hid: 'image',
            name: 'image',
            content: `${image}`
          },
          {
            name: 'og:type',
            property: 'og:type',
            content: 'website'
          },
          {
            name: 'og:title',
            property: 'og:title',
            content: title
          },
          {
            name: 'og:description',
            property: 'og:description',
            content: description
          },
          {
            name: 'og:image',
            property: 'og:image',
            content: image
          }
        ]
      };
    case 'dailycheckin':
    case 'luckydraw':
      return {
        title: `${title}`,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: `${description}`
          },
          {
            hid: 'image',
            name: 'image',
            content: `${image}`
          },
          {
            name: 'og:type',
            property: 'og:type',
            content: 'website'
          },
          {
            name: 'og:title',
            property: 'og:title',
            content: title
          },
          {
            name: 'og:description',
            property: 'og:description',
            content: description
          },
          {
            name: 'og:image',
            property: 'og:image',
            content: image
          }
        ]
      };
    default:
      break;
  }
};
