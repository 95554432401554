const state = () => ({
  // 临时订单对象
  checkout: {},
  // 支付对象
  payment: {},
  // 支付报错
  paymentErr:{},
  // 支付宝、微信支付等参数信息
  payInfo: ''
});
const getters = {
  /**
   * 构造更新接口需要的全部数据返回
   * @param state
   * @returns {{settlementCode: *}}
   */
  updateObj(state) {
    const obj = {
      settlementCode: state.checkout.settlementCode
    };
    // 优惠劵号
    const couponCodes = state.checkout?.tempOrder?.selectedCouponInfo ?? [];
    obj.couponCodes = couponCodes.map(item => item.couponCode);
    // 优惠码
    obj.voucherCodeList=[];
    if(state.checkout?.tempOrder?.selectedVoucherCodeList){
      obj.voucherCodeList = state.checkout.tempOrder.selectedVoucherCodeList.map((item) => {
        return item.voucherCode;
      });
    }
    // obj.voucherCode = state.checkout?.tempOrder?.selectedVoucherInfo?.voucherCode ?? '';
    // 配送方式
    obj.deliveryTypeInfo = state.checkout?.tempOrder?.defaultDeliveryTypeInfo ?? undefined;
    // 支付方式
    obj.paymentTypeInfo = state.checkout?.tempOrder?.defaultPaymentTypeInfo ?? undefined;
    // 地址信息
    obj.addressInfo = state.checkout?.tempOrder?.addressInfo ?? undefined;
    // 发票信息
    obj.invoiceInfo = state.checkout?.tempOrder?.invoiceInfo ?? undefined;

    return obj;
  }
};
const mutations = {
  setCheckout(state, checkout) {
    if (checkout) {
      state.checkout = checkout;
    } else {
      state.checkout = {};
    }
  },
  setPayment(state, payment) {
    if (payment) {
      state.payment = payment;
    } else {
      state.payment = {};
    }
  },
  setPaymentErr(state, paymentErr) {
    if(paymentErr) {
      state.paymentErr = paymentErr;
    }
  },
  setPayInfo(state, payInfo) {
    if (payInfo) {
      state.payInfo = payInfo;
    } else {
      state.payInfo = '';
    }
  }
};

const actions = {
  /**
   * 根据平台订单编码查询订单详情
   */
  async queryByPlatformOrderId({ commit }, { row, ctx }) {
    try {
      // 平台订单编码
      const platformOrderId = row.platformOrderId;
      if (platformOrderId) {
        const data = await ctx.$axios.get(ctx.$config.ECP_API_HOST + '/o2ord/v1/orders/' + platformOrderId);
        return data;
      }
    } catch (e) {
      console.error('查询订单详情失败: ' + e);
      return e;
    }
  },
  /**
   * 创建临时订单
   */
  async createOrder({ commit }, { row, cartObj, ctx }) {
    try {
      const param = {
        // 结算页来源-必输-PDP/CART
        action: row?.action || cartObj?.action,
        items: cartObj?.items ||  [
          {
            // 商品数量
            quantity: row.quantity,
            // 平台SKU编码
            skuId: row.skuId,
            // 购物车行编码
            cartEntryCode: row.cartEntryCode,
            // 	定制编码
            recipeId: row.recipeId,
            // 定制信息
            embellishmentOptions: row.embellishmentOptions,
            // 团购活动编码
            groupBuyCode: row.groupBuyCode,
            // 小团编码
            eventCode: row.eventCode,
            // 预售活动编码
            preSaleCode: row.preSaleCode
          }
        ]
      };
      const data = await ctx.$axios.post(`${ctx.$config.ECP_API_HOST}/o2ckt/v1/order-settlements`, param);
      commit('setCheckout', data);
    } catch (e) {
      console.error('创建临时订单失败: ' + e);
      commit('setCheckout', {});
      return e;
    }
  },
  /**
   * 更新临时订单
   * @params row 更新的订单对象
   * @params ctx 调用此函数的页面Vue 实例
   * @params customErrTip 是否自定义错误提示
   */
  async updateOrder({ commit, getters }, { row, ctx, customErrTip }) {
    const notPayFailed=!!row?.notPayFailed;
    row?.notPayFailed && delete row.notPayFailed;
    try {
      if (row.settlementCode) {
        const checkoutTemp = JSON.parse(JSON.stringify(getters.updateObj));
        const obj = Object.assign(checkoutTemp, row);
        if(obj.voucherCodeList&&obj.voucherCodeList?.length && 'voucherCode' in obj){
          delete obj.voucherCode;
        }
        const data = await ctx.$axios.put(`${ctx.$config.ECP_API_HOST}/o2ckt/v1/order-settlements/update-order`, obj);
        commit('setCheckout', data);
      }
    } catch (e) {
      console.error('更新临时订单失败: ', e);
      if(notPayFailed){
        return e;
      }
      if (ctx.$PayFailed && e.status === 400 && !customErrTip) {
        ctx.$PayFailed(e.data, ()=> {
          ctx.$router.back();
          // ctx.$router.push('/');
          // if (ctx.$route.query.source) {
          //   ctx.$router.push(ctx.$route.query.source);
          // }
        });
      }
      return e;
    }
  },
  /**
   * 提交订单
   * @params row 提交的订单对象
   * @params ctx 调用此函数的页面Vue 实例
   * @params customErrTip 是否自定义错误提示
   */
  async submitOrder({ commit }, { row, ctx, customErrTip }) {
    try {
      const { utmSource, utmMedium, utmTerm, utmContent, utmCampaign } = row;

      const param = {
        // 结算页编码，可由创建临时订单接口获得
        settlementCode: row.settlementCode,
        // 是否朋友代付
        payForOthersFlag: row.payForOthersFlag,
        // 用户头像
        weChatPicture: row.weChatPicture,
        // 微信名
        weChatName: row.weChatName
      };

      if(utmSource || utmMedium || utmTerm || utmContent || utmCampaign) {
        param.utmInfo = {
          utmSource,
          utmMedium,
          utmTerm,
          utmContent,
          utmCampaign
        };
      }

      const data = await ctx.$axios.post(`${ctx.$config.ECP_API_HOST}/o2ckt/v1/order-settlements/submit-order`, param);
      commit('setPayment', data);
    } catch (e) {
      // const e={
      //   status:400,
      //   data: {
      //     code:30011
      //   }
      // };
      console.error('提交订单失败: ' + e);
      // commit('setPayment', {});
      // if(e.status === 400&&e?.data?.code){
      //   console.log('e.data.code',e.data.code);
      //   const errList=["60001","60284","30011"];
      //   if(errList.includes(e.data.code.toString())){
      //     return 60001;
      //   }
      // }
      const codelist=["60000","60001","60002","60003","60004","60264","60265","60266","60267","60268","60269","60284","60285",'30011'];
      if(ctx.$PayFailed && e.status === 400 &&e?.data?.code&&codelist.includes(e.data.code.toString())){
        ctx.$PayFailed({
          message:'优惠券/码发生变动，请重新下单',
          code:60285
        }, ()=> {
          ctx.$aloading&&ctx.$aloading.show();
          ctx.$router.back();
        },ctx);
        return 60285;
      }else if (ctx.$PayFailed && e.status === 400 &&e.data.code!=='error.membership_status_is_incorrect' && !customErrTip) {
        ctx.$PayFailed(e.data, ()=> {
          ctx.$router.back();
          // if (ctx.$route.query.source) {
          //   // console.log(ctx.$route.query);
          //   ctx.$router.push(ctx.$route.query.source);
          // }
        },ctx);
      } else {
        commit('setPaymentErr', e.data);
      }
      return e;
    }
  },
  /**
   * 预支付交易单下单
   */
  async payOrder({ commit }, { row, headers, ctx }) {
    try {
      const param = {
        // 平台订单号
        platformOrderId: row.platformOrderId,
        // 商户订单号
        merchantOrderNum: row.merchantOrderNum,
        // 订单标题
        description: row.description,
        // 支付平台类型
        paymentWay: row.paymentWay,
        // 本次需支付金额
        totalAmount: row.totalAmount,
        // 交易类型
        channelTrxType: row.channelTrxType,
        // 是否朋友代付
        payForOthersFlag: row.payForOthersFlag,
        // 获取微信openId的code参数
        code: row.code
      };
      const data = await ctx.$axios.post(`${ctx.$config.ECP_API_HOST}/o2ord/v1/order-trades/pay`, param, {headers});
      commit('setPayInfo', data);
    } catch (e) {
      console.error('预支付失败: ' + e);
      commit('setPayInfo', '');
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
