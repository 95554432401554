
import calcTextareaHeight from './calcTextareaHeight';
export default {
  name: 'ComTextarea',
  // model: {
  //   prop: 'value',
  //   event: 'change'
  // },
  props: {
    // v-model 绑定值
    value: [String, Number],
    // 只读
    readonly: Boolean,
    // 禁用
    memberOnly: {
      type: Boolean,
      default: false
    },
    // 必输
    required: Boolean,
    // 显示数字限制
    showWordLimit: Boolean,
    // 自适应高度
    autosize: {
      type: [Boolean, Object],
      default: false
    },
    height: { type: Number },
    label: String,
    // 必输提示文字
    requiredMsg: String,
    isMemberLogin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 组件内部计算样式
      textareaCalcStyle: {},
      hovering: false,
      focused: false,
      // 是否输入法正在输入（即敲击键盘，但不会立即显示字符到文本框）
      isComposing: false
    };
  },
  computed: {
    // 根据原生属性maxlength 以及是否展示字长限制判断是否显示limit 信息
    isWordLimitVisible() {
      return this.showWordLimit && this.$attrs.maxlength && !this.readonly;
    },
    upperLimit() {
      return this.$attrs.maxlength;
    },
    textLength() {
      if (typeof this.value === 'number') {
        return String(this.value).length;
      }
      return (this.value || '').length;
    },
    nativeInputValue() {
      return this.value === null || this.value === undefined
        ? ''
        : String(this.value);
    },
    // 根据props 属性变化动态调整样式
    textareaStyle() {
      const style = {};
      if (this.isWordLimitVisible) {
        style.paddingBottom = '32px';
      }
      if (this.height) {
        style.height = this.height + 'px';
      }
      if (this.focused) {
        style.border = "1px solid black";
      }
      return Object.assign(
        {},
        this.textareaCalcStyle,
        { resize: this.resize },
        style
      );
    }
  },
  watch: {
    value() {
      // 重新计算设置文本框高度
      this.$nextTick(this.resizeTextarea);
      // this.elemContentEditable.innerText = newVal;
    },
    // native input value is set explicitly
    // do not use v-model / :value in template
    // see: https://github.com/ElemeFE/element/issues/14521
    nativeInputValue() {
      this.setNativeInputValue();
    }
  },
  mounted() {
    this.setNativeInputValue();
    this.resizeTextarea();
  },
  methods: {
    getInput() {
      return this.$refs.textarea;
    },
    setNativeInputValue() {
      const input = this.getInput();
      if (!input) { return; }
      if (input.value === this.nativeInputValue) { return; }
      input.value = this.nativeInputValue;
    },
    /**
     * 应对输入法敲击键盘时实际可能未输入
     */
    handleCompositionStart() {
      this.isComposing = true;
    },
    handleCompositionUpdate(event) {
      const text = event.target.value;
      const lastCharacter = text[text.length - 1] || '';
      this.isComposing = !this.isKorean(lastCharacter);
    },
    handleCompositionEnd(event) {
      if (this.isComposing) {
        this.isComposing = false;
        this.handleInput(event);
      }
    },
    handleInput(event) {
      // should not emit input during composition
      // see: https://github.com/ElemeFE/element/issues/10516
      if (this.isComposing) { return; }

      // hack for https://github.com/ElemeFE/element/issues/8548
      // should remove the following line when we don't support IE
      if (event.target.value === this.nativeInputValue) { return; }

      this.$emit('input', event.target.value);

      // ensure native input value is controlled
      // see: https://github.com/ElemeFE/element/issues/12850
      this.$nextTick(this.setNativeInputValue);
    },
    handleFocus(event) {
      event.preventDefault();
      this.focused = true;
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
      // if (this.validateEvent) {
      //   this.dispatch('ElFormItem', 'el.form.blur', [this.value]);
      // }
    },
    handleChange(event) {
      this.$emit('change', event.target.value);
    },
    /** 是否韩语 */
    isKorean(text) {
      const reg = /([(\uAC00-\uD7AF)|(\u3130-\u318F)])+/gi;
      return reg.test(text);
    },
    /**
     * 重新计算文本框高度
     */
    resizeTextarea() {
      if (this.$isServer) { return; }
      if (!this.autosize) {
        this.textareaCalcStyle = {
          minHeight: calcTextareaHeight(this.$refs.textarea).minHeight
        };
        return;
      }
      const minRows = this.autosize.minRows;
      const maxRows = this.autosize.maxRows;

      this.textareaCalcStyle = calcTextareaHeight(
        this.$refs.textarea,
        minRows,
        maxRows
      );
    }
  }
};
