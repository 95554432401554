/*
 * @Description:
 * @Author: ZhangHui
 * @Date: 2021-10-06 14:04:23
 */

import Vue from 'vue';
import 'swiper/dist/css/swiper.css';
if (process.browser) {
  const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr');
  Vue.use(VueAwesomeSwiper);
}
