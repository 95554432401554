
import throttle from 'lodash.throttle';
import SearchPc from '../search/search-joint/SearchPc.vue';
import { parseURL } from '../../utilities/helper/urlHelper';
import { serviceOnlineClick } from '../homepage/track';
import Skeleton from './skeleton.vue';
import Dropdown from './Components/dropdown.vue';
import { openLive800 } from '~/utilities/helper/live800';
import { detectOS, isClient } from '~/utilities';
import { scrollElementTo } from '~/utilities/helper/catalogHelper';
let Tracker;
if (process.client) {
  Tracker = require('~/utilities/helper/live800-track.ts').default;
}
export default {
  name: 'NavigationBar',
  components: {
    SearchPc,
    Dropdown,
    Skeleton
  },
  props: {
    size: {
      type: String,
      default: 'normal'
    },
    activeTab: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      activeTabTimer: null,
      activeTabWidth: '31px',
      activeTabLeft: '50px',
      showTooltip: false,
      hideSearchBar: false,
      collapseDynamic: false,
      profileOptionVisible: false,
      employeeProfileOption: [
        {
          text: '我的账户首页',
          url: '/my-account'
        },
        {
          text: '我的订单',
          url: '/my-account/order/list'
        },
        {
          text: '我的地址簿',
          url: '/my-account/address'
        },
        {
          text: '员工信息',
          url: '/my-account/employeeInfo'
        }
      ],
      visitorProfileOption: [
        {
          text: '我的账户首页',
          url: '/my-account'
        },
        {
          text: '我的订单',
          url: '/my-account/order/list'
        }
        // {
        //   text: '我的地址簿',
        //   url: '/my-account/address'
        // }
      ],
      memberProfileOption: [
        {
          text: '我的账户首页',
          url: '/my-account'
        },
        {
          text: '我的订单',
          url: '/my-account/order/list'
        },
        {
          text: '我的地址簿',
          url: '/my-account/address'
        },
        {
          text: '我的优惠券',
          url: '/my-account/coupon'
        },
        {
          text: '我的积分',
          url: '/my-account/score'
        },
        {
          text: '个人信息',
          url: '/my-account/personalInfo'
        }
      ],
      number: 0,
      id: 0,
      val: '',
      link: '',
      hasAnimation: false,
      catalogWidth: undefined,
      scrollendY: 0,
      showHeader: true,
      firstNavItems: [],
      secondNavItems: [],
      firstNavWidth: 0,
      secondNavWidth: 0,
      navWidth: 0
    };
  },
  computed: {
    computedStyle() {
      return {width: this.navWidth + 'px'};
    },
    isNonmemberLogin() {
       // 非会员登录后有tel
      return !!this.$store.state.myAccount.isNonmemberLogin;
    },
    isNewNonmber(){
      return !!this.$store.state.myAccount.isnew;
    },
    notifications() {
      return this.$store.state.homePage.navInfo.notifications || [];
    },
    cartNum() {
      return this.$store.state.homePage.navInfo.cartNum;
    },
    navItems() {
      // if (this.collapseDynamic) {
      //   return (this.$store.state.homePage.navInfo.navItems || []).slice(0, 6);
      // }
      this.$nextTick(() => {
        if (isClient) {
          this.getNavWidthByPage();
        }
      });
      return this.$store.state.homePage.navInfo.navItems || [];
    },
    pageIndex() {
      const moreIdx = this.$store.state.homePage.navInfo.navItems.findIndex((item) => { return item.contentId === 'more'; });
      if (moreIdx === -1) {
        return this.$store.state.homePage.navInfo.navItems.length;
      }
      return moreIdx;
    },
    isLogin() {
      return this.$store.state.myAccount.isLogin;
    },
    isEmployeeLogin() {
      return this.$store.state.myAccount.isEmployeeLogin;
    },
    profileOption() {
      return this.isEmployeeLogin
        ? this.employeeProfileOption
        : this.isNonmemberLogin
        ? this.visitorProfileOption
        : this.memberProfileOption;
    },
    needHeaderAnimation() {
      const includePath = ['/', '/cart', '/my-account'];
      const currentPath = this.$route.path;
      let needs = false;
      includePath.forEach((item) => {
        if (
          currentPath === '/' ||
          currentPath.startsWith(`${item}/`) ||
          currentPath === item
        ) {
          needs = true;
        }
      });
      return needs;
    },
    isPreview() {
      const { path, query } = this.$route;
      return ['/', '/clp'].includes(path) && query.preview === '1';
    },
    hasPermission() {
      const { path } = this.$route;
      let _page;
      if (path === '/') _page = 'homePage';
      else if (path === '/clp') _page = 'clp';
      return this.$store.state[_page]?.hasPreviewPermission;
    },
    getPath() {
      return function(link) {
        if (!link) return 'javascript:void(0);';
        if (link.startsWith('http')) {
          return link;
        } else {
          const urlPara = this.$router.resolve(parseURL(link));
          return urlPara.href;
        }
      };
    }
  },
  watch: {
     // 判断当前的路由标识,由于是挂载到default下面的,理论上在任何地方都可唤起
     $route(to, from) {
      if(to.path !== '/category' && this.size !== 'small') {
        this.getNavWidthByPage();
      }
    },
    notifications() {
      if (this.notifications.length) {
        if (this.notifications.length > 1) {
          clearTimeout(this.timer);
          this.startMove();
        }
        this.id = this.number;
        if (!this.notifications[this.number]) return;
        this.val = this.notifications[this.number].text;
        if (this.notifications[this.number].link) {
          this.link = this.notifications[this.number].link;
        }
      }
      //
    },
    number() {
      this.id = this.number;
      if (!this.notifications[this.number]) return;
      this.val = this.notifications[this.number].text;
      if (this.notifications[this.number].link) {
        this.link = this.notifications[this.number].link;
      } else {
        this.link = '';
      }
      this.showTooltip = false;
      // this.showTip();
      //
    },
    activeTab(val) {
      if (val === null) {
        this.hasAnimation = false;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);

    if (!this.isPreview) {
      this.initNavigationData();
    } else {
      // 更新会员状态
      this.$store.dispatch('myAccount/updateLoginStatus');
      this.$watch('hasPermission', (val) => {
        this.initNavigationData({ hasPermission: val });
      });
    }
    // this.startMove();
    const onScroll = (() => {
      return () => {
        const { scrollTop } = this.$utils.device.getScrollTop();
        const startY = scrollTop;
        if (startY >= 124 && startY >= this.scrollendY) {
          this.showHeader = false;
          this.scrollendY = startY;
        } else {
          this.showHeader = true;
          this.scrollendY = startY;
        }
        if (scrollTop < 2) {
          this.showHeader = true;
        }
      };
    })();
    this.$on(
      'hook:beforeDestroy',
      throttle(this.$utils.device.onPageScroll(onScroll), 300)
    );
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;

    clearTimeout(this.activeTabTimer);
    this.activeTabTimer = null;
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    // 判断是否为safari浏览器
    isIosSafari() {
      const ua = navigator.userAgent.toLowerCase();
      return (
        ua.includes('applewebkit') &&
        ua.includes('safari') &&
        !ua.includes('android') &&
        !ua.includes('linux') &&
        !ua.includes('crios') &&
        !ua.includes('chrome') &&
        !ua.includes('browser') &&
        !ua.includes('ios')
      );
    },
    toHomepage() {
      window.location.href = '/';
    },
    // 是否显示toolTip
    showTip() {
      const container = document.querySelector('.swiper-box');
      const content = document.querySelector('.content-width');
      let containerWidth;

      if (content) {
        containerWidth = container.clientWidth - 8;
        if (content.clientWidth >= containerWidth) {
          this.showTooltip = true;
          return true;
        } else {
          this.showTooltip = false;
          return false;
        }
      }
    },
    showClass() {
      window.addEventListener('mousemove', this.showClass2);
    },
    showClass2(e) {
      if (
        !e.target.className.includes('show-tooltip') &&
        !e.target.className.includes('content-width') &&
        !e.target.className.includes('opacity-space')
      ) {
        this.showTooltip = false;
      } else {
        this.showTip();
      }
    },
    // 数据初始化
    initNavigationData(option = {}) {
      if (this.$store.state.homePage.navInfo.notifications === undefined)
        this.$store.dispatch('homePage/getNotification', {
          ctx: this,
          previewPermission: option.hasPermission
        });
      if (!this.$store.state.homePage.navInfo.navItems?.length)
        this.$store.dispatch('homePage/getNavItem', {
          ctx: this,
          previewPermission: option.hasPermission
        });

      // 更新登陆状态 更新会员状态
      this.$store.dispatch('myAccount/updateLoginStatus');
      // 更新员工状态
      this.$store.dispatch('myAccount/updateEmployeeLoginStatus');
      // 更新非会员状态
      this.$store.dispatch('myAccount/updateNonmemberLoginStatus');

      this.$store.dispatch('myAccount/upadteIsNew');
      // 如果未登陆状态或者有数据的话, 切换横竖屏不重复请求
      // 已登陆且没数据才会请求cart === 本地购物车无需登录可看到数据
      // if (
      //   (option.debounce && this.cartNum) ||
      //   (!this.isLogin && !this.isEmployeeLogin && !this.isNonmemberLogin)
      // )
      //   return;
      // cart 数量可能随时变化, 不做缓存
      setTimeout(() => {
        this.$store.dispatch('homePage/getCartNum', { ctx: this });
      }, 50);
    },
    // 切换tab
    selectTab: throttle(function (item, index, e) {
      if (this.activeTabTimer) {
        clearTimeout(this.activeTabTimer);
      }

      // 当前选中并不会执行滑动的动画, 下一次切换tab时才会有动画, 时间设置为跟过渡时间一致
      setTimeout(() => {
        this.hasAnimation = true;
      }, 600);
      const timeout = item.contentId === 'more' || item.contentId === 'return' ? 400 : 100;
      this.activeTabTimer = setTimeout(() => {
        // this.activeTabLeft = `${e.target.offsetLeft + 10}px`;
        // this.activeTabWidth = `${e.target.clientWidth - 20}px`;
        // if (index === 'more') {
        //   const items = (this.$store.state.homePage.navInfo.navItems || [])
        //     .slice(6)
        //     .map((el, _index) => {
        //       return { ...el, index: _index + 6 };
        //     });
        //   this.$emit('hover', items, index);
        // } else {
          // this.$emit(
          //   'hover',
          //   [
          //     {
          //       ...item
          //     }
          //   ],
          //   index
          // );
        // }

        if (item.contentId === 'more') {
          this.navWidth = this.secondNavWidth;
          this.$nextTick(() => {
            // if (this.isIosSafari()) {
              scrollElementTo(document.getElementById('pc-navigation-bar-catalog'), 1, 2000, this.firstNavWidth - 5);
            // } else {
            //   document.getElementById('pc-navigation-bar-catalog').scrollTo({left: this.firstNavWidth + 50, top: 0,  behavior: 'smooth'});
            // }
          });
          const dom = document.getElementsByClassName('catalog-item-second-page')[1];
          this.activeTabLeft = `${dom.offsetLeft + 10}px`;
          this.activeTabWidth = `${dom.clientWidth - 16}px`;
          this.$emit('more-hover', [{...this.navItems[index + 2]}], index + 2);
        } else if (item.contentId === 'return') {
          this.navWidth = this.firstNavWidth;
          this.$nextTick(() => {
            // if (this.isIosSafari()) {
              scrollElementTo(document.getElementById('pc-navigation-bar-catalog'), -1, 2000, 0);
            // } else {
              // document.getElementById('pc-navigation-bar-catalog').scrollTo({left: 0, top: 0, behavior: 'smooth'});
            // }
          });
          const dom = document.getElementsByClassName('catalog-item-first-page')[0];
          this.activeTabLeft = `${dom.offsetLeft + 10}px`;
          this.activeTabWidth = `${dom.clientWidth - 16}px`;
          this.$emit('more-hover', [{...this.navItems[0]}], 0);
        } else {
          this.activeTabLeft = `${e.target.offsetLeft + 10}px`;
          this.activeTabWidth = `${e.target.clientWidth - 16}px`;
          this.$emit(
            'hover',
            [
              {
                ...item
              }
            ],
            index
          );
        }
      }, timeout);
    }, 100),

    getNavWidthByPage() {
      const firstNavItems = document.getElementsByClassName('catalog-item-first-page') || [];
      const secondNavItems = document.getElementsByClassName('catalog-item-second-page') || [];
      const firstNavLength = firstNavItems.length;
      const secondNavlength = secondNavItems.length;
      const screenWidth = window.innerWidth;
      const scrollContainerLeft = document.getElementById('pc-navigation-bar-catalog')?.scrollLeft || 0;
      const returnIdx = this.navItems.findIndex((item) => {
        return item.contentId === 'return';
      });
      let margin;
      if (screenWidth > 799 && screenWidth < 960) {
        margin = 12;
      } else if (screenWidth > 719 && screenWidth < 800) {
        margin = 4;
      } else {
        margin = 20;
      }
      let firstNavWidth = 0;
      let secondNavWidth = 0;
      if (firstNavLength) {
        for(let i = 0; i < firstNavLength; i++) {
          firstNavWidth += firstNavItems[i].clientWidth;
        }
        firstNavWidth += margin*(firstNavLength - 1);
      }

      if (secondNavlength) {
        for(let i = 0; i < secondNavlength; i++) {
          secondNavWidth += secondNavItems[i].clientWidth;
        }
        secondNavWidth += margin*(secondNavlength - 1);
      }
      this.firstNavWidth = firstNavWidth;
      this.secondNavWidth = secondNavWidth;
      this.navWidth = returnIdx !== -1 && scrollContainerLeft > 0 ? secondNavWidth : firstNavWidth;
      scrollContainerLeft > 0 && returnIdx !== -1 ? document.getElementById('pc-navigation-bar-catalog')?.scrollTo({left: this.firstNavWidth + 50, top: 0}): '';
    },
    levelTab() {
      clearTimeout(this.activeTabTimer);
    },
    closeCatalog(e) {
      if (['Tablet', 'Mobile'].includes(detectOS())) return;
      this.$emit('close', e);
    },
    // 页面尺寸变化时, 计算的从第七个往后的导航项状态是收缩还是展开
    handleResize(e) {
      const spaceDom = document.getElementById('pc-navigation-bar-space');
      const catalogDom = document.getElementById('pc-navigation-bar-catalog');
      const navigationDom = document.getElementById('pc-navigation-bar');
      this.getNavWidthByPage();
      // const tempTab = this.activeTab;
      if (!spaceDom || !catalogDom) {
        return;
      }
      const catalogsToFahterLeft =
        window.innerWidth > 1440 ? 164 : catalogDom.offsetLeft;

      // catalog 展开后所需的宽度
      this.catalogWidth = this.catalogWidth || catalogDom.clientWidth;

      // 当前catalog 可以拥有的最大宽度
      const currentCatalogSpace =
        (navigationDom.clientWidth / 3) * 2 + 40 - catalogsToFahterLeft - 18;

      // 如果catalog展开后的宽度大于当前catalog可以拥有的距离
      // const temp = this.collapseDynamic;
      this.collapseDynamic = this.catalogWidth > currentCatalogSpace;
      // if (
      //   this.collapseDynamic !== temp &&
      //   (this.activeTab === 'more' || this.activeTab > 5)
      // ) {
      //   this.hasAnimation = false;
      // }
    },
    hoverMore() {
      const items = this.navItems.slice(5);
      this.$emit('hover', items);
    },
    routerPush(url) {
      this.$router.push(url);
    },
    handleClickAccount(e) {
      e.stopPropagation();
      if (!this.isLogin && !this.isEmployeeLogin && !this.isNonmemberLogin) {
        this.login();
      }
    },
    handleHoverAccount(e) {
      if (!this.isLogin && !this.isEmployeeLogin && !this.isNonmemberLogin) {
        e.stopPropagation();
      }
    },
    handleClickCartIcon() {
      // 购物车界面未登陆的时候再次点击购物车图标再次唤出登陆弹窗
      const _login =
        this.isNonmemberLogin || this.isLogin || this.isEmployeeLogin;
      if (this.$route.path === '/cart' && !_login) {
        this.$login({ show: true });
      }

      this.$router.push('/cart');
    },
    login() {
      if (this.isLogin) {
        this.$router.push('/my-account');
      } else {
        this.$login({
          show: true,
          on: {
            login: () => {
              if (this.$route.path === '/cart') {
                // 通知cart 页面合并本地购物车并更新购物车列表
                this.$store.commit('cart/SET_MERGECART_FLAG', true);
              }
              this.initNavigationData();
            }
          }
        });
      }
    },
    callLive800() {
      // @params: pageName, pageType
      Tracker.trackLive800('HomePage', 'HomePage');
      serviceOnlineClick({page_title:'homepage'});
      openLive800(this,10111);
    },
    // 开始滚动
    startMove() {
      const length = this.notifications.length;
      this.timer = setTimeout(() => {
        if (this.number === length - 1) {
          this.number = 0;
        } else {
          this.number += 1;
        }
        this.startMove();
      }, 5000);
    }
  }
};
