/**
 * desc 签到模块存储
 *
 * @author libo
 * @date   2023/10/24
 */
const state = () => ({
  nowDateGap: 0
});

const mutations = {
  SET_NOW_DATE_GAP(state, params) {
    state.nowDateGap = params;
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
