/*
 * @Description: PC端打开不显示brafinder，重定向到首页
 * @Autor: Duan,JingLiang
 * @Date: 2022-02-22 16:29:24
 */

// 在服务端渲染过程中无window,通过req.header判断当前ua
const isMobile = (ua) => {
  return !!ua.match(/AppleWebKit.*Mobile.*/);
};

export default function ({ req, route, redirect }) {
  const targetPath = '/brafinder';
  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent || '';
  if (!isMobile(userAgent) && route.fullPath.includes(targetPath)) {
    redirect({ path: '/' });
  }
}
