
import { LibPopover, LibButton, LibDialog } from '@adidas-com-front/ui-library';
import { TokenUtils } from '../../utilities';
import { trackSpecialLogin } from '../login-register/track/index';
import { sensorsLogin } from '~/utilities/vendors/sensors';
const jwt = require('jsonwebtoken');

export default {
  components: {
    LibPopover,
    LibButton,
    LibDialog
  },
  data() {
    return {
      // 重新修改密码(pc端)
      newPassword: false,
      // 重新修改密码(mobile端)
      showDialog: false,
      // 组件展示
      show: false,
       // 是否为pc
      isPcClient: false,
      // 员工号
      employeeId: '',
       // 密码
      employeePwd: '',
      // 使用该组件的实例对象
      root: null,
      // 员工号是否有错
      isErr: false,
       // 员工号校验
      idFlag: false,
      // 密码校验
      pwdFlag: false,
      // footer登录
      isFooter: false,
      resetPwd:'',
      // 接口请求时的按钮loading态
      btnLoading: false
    };
  },
  computed: {
    // 按钮启禁用
    loginDisabled() {
      return !(this.idFlag && this.pwdFlag);
    }
  },
  watch: {
    // 关闭弹窗后清除状态
    show(val) {
      if (!val) {
        this.employeeId = '';
        this.employeePwd = '';
        this.isErr = false;
        this.idFlag = false;
        this.pwdFlag = false;
      }
    }
  },
  mounted() {
    this.isPc();
    window.addEventListener('resize', this.isPc, true);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.isPc, true);
  },
  methods: {
    // footer登录相关操作
    footerLogin() {
      const currentUrl = this.root.$route.path;
      const myAccount = currentUrl.startsWith('/my-account');
      if (myAccount) {
        this.root.$router.push('/my-account');
        setTimeout(() => {
          this.$utils.device.setScrollTop(0);
        }, 0);
      }
    },
    // 校验员工号
    checkEmployeeId(e) {
      const reg = /^[0-9]+$/;
      if (!e.target.value || !reg.test(e.target.value)) {
        this.isErr = true;
        this.idFlag = false;
      } else {
        this.isErr = false;
        this.idFlag = true;
      }
    },
    // 校验密码
    checkEmployeePwd(e) {
      e.target.value ? (this.pwdFlag = true) : (this.pwdFlag = false);
    },
    // 唤起弹出事件
    showPop(that, isFooter = false) {
      this.root = that;
      this.show = true;
      this.isFooter = isFooter;
      this.resetPwd = that.$config.RESET_PWD + '/pwd-reset/index';
    },
    // 是否pc端
    isPc() {
      this.isPcClient = window.innerWidth > 719;
    },
    // 保存token和id
    setRecord(data, employeeId) {
      // 先删除之前的token等信息
      TokenUtils.logOut();
      this.root.$trackLogout();
      TokenUtils.setToken({ ...data, isKeep: true }, this.$axios);
    },
    // 是否微信浏览器
    isWeiXin() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)) {
        const webkit = ua.match(/MicroMessenger/i);
        return webkit[0] === 'micromessenger';
      } else {
        return false;
      }
    },
    // 重置密码
    resetPassword() {
      this.showDialog = false;
      this.newPassword = false;
      const url = this.root.$config.RESET_PWD + '/pwd-reset/index';
      const parameters = 'directories=no, status=no, copyhistory=yes';
      if (this.isWeiXin()) {
        window.location.href = url;
      } else {
        const winref = window.open('', parameters);
        winref.location = url;
      }
    },
    // 登录请求
    async handleLogin() {
      if (this.employeePwd === '') {
        this.$Toast({ message: '请输入密码', ref: this.$refs.loginBox });
        return;
      }
      if (this.loginDisabled) {
        return;
      }
      if (this.root) {
        this.btnLoading = true;
        try {
          const res = await this.root.$axios.post(
            `${this.root.$config.ANA_API_HOST}/v1/users/employee/login`,
            this.root.$qs.stringify({
              employeeId: this.employeeId,
              password: this.employeePwd
            })
          );
          if (res && res.data && this.btnLoading) {
            const employeeId = this.employeeId;
            const tokenInfo = jwt.decode(res.data.access_token || '');

            const firstLogin = tokenInfo.need_password_update;
            if (firstLogin) {
              if (this.isPcClient) {
                this.newPassword = true;
              } else {
                this.showDialog = true;
              }
              this.show = false;
              this.btnLoading = false;
              return;
            }
            this.setRecord(res.data, this.employeeId);
            this.root.$store.commit('myAccount/LOGIN_COUNT');
            this.root.$store.dispatch('myAccount/afterEmployeeLogin', {
              ctx: this.root
            });
            this.root.$store.dispatch('myAccount/updateLoginStatus');
            this.root.$store.dispatch('myAccount/updateNonmemberLoginStatus');
            if (!this.isFooter) {
              this.root.$router.push('/my-account');
              setTimeout(() => {
                this.$utils.device.setScrollTop(0);
              }, 0);
            } else {
              this.footerLogin();
            }
            this.show = false;
            const sensorSpecialData = ['Employee', employeeId];
            trackSpecialLogin(...sensorSpecialData);
            this.root.$trackLogin();

            sensorsLogin();
            // this.$Toast({ message: '登录成功' });
          }
        } catch (e) {
          this.handleNetworkErr(e);
          console.error('员工登录出错了！！！', e);
          if(e.data?.errcode) {
            this.$Toast({
              message: e.data.errcode===4000065?'密码输入错误频繁，请于明日再试':'员工号或密码不正确',
              ref: this.$refs.loginBox
            });
          }
        }
        this.btnLoading = false;
      }
    },
    handleNetworkErr(e) {
      if(this.$nuxt.isOffline) {
        window.location.reload();
      } else if(!e.data) {
        this.$Toast({
          message: '系统繁忙，请稍后再试',
          ref: this.isMobile ? null : this.$refs.boxg
        });
      }
    }
  }
};
