
import { LibLoading} from '@adidas-com-front/ui-library';
// import { LibButton,LibLoading} from '@adidas-com-front/ui-library';
// import clp, { getClpData } from '~/components/clp/index';
export default {
  name: 'Clp',
  // components: { LibButton ,LibLoading,clp},
  components: { LibLoading},
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clpData: null,
      clpUrl: "about:blank",
      clpHeight:824,
      loadingIframe:true
    };
  },
  computed:{
    showCLp(){
      return this.clpUrl!== "about:blank";
    },
    style(){
      if(this.isMobile){
        return {
          height:(this.isShow)?this.clpHeight+'px':"1px"
        };
      }else{
        return {};
      }
    }
  },
  mounted() {
    window.addEventListener('message',this.iframeheight);
    const urlPara = new URL(location.href);
    this.clpUrl=urlPara.origin + '/clp/login';
    // this.getClpData();
  },
  beforeDestroy() {
    window.removeEventListener('message', this.iframeheight);
  },
  methods:{
    // async getClpData(){
    //   const response = await this.$axios.get(
    //     `${this.$config.S3_COM_HOST}/static/loginConfig.json`
    //   );
    //   const clpCode = response.clp.toString();
    //   const waitTime=new Promise((resolve)=>{
    //     setTimeout(() => {
    //       resolve();
    //     }, 2000);
    //   });
    //   const clpDataJson = await getClpData(this, clpCode, 'server');
    //   await waitTime;
    //  if ('data' in clpDataJson) {
    //     this.clpData = {
    //       contentId: clpCode,
    //       hasState: false,
    //       data: clpDataJson.data
    //     };
    //    this.loadingClp=false;
    //  }
    // },
    iframeheight(event){
      console.log("iframeheight",event);
      try{
        if(event.data==="hiddenClp"){
          this.$emit('hiddenClp');
          return;
        }
        const obj=JSON.parse(event.data);
        if(obj&&'clp-height' in obj){
          if(obj['clp-height']!==0){
            this.loading=false;
            this.clpHeight=obj['clp-height'];
            console.log('iframeheight',obj['clp-height']);
            this.loadingIframe=false;
          }
        }
      }catch{};

    }
  }
};
