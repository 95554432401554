
import { LibButton } from '@adidas-com-front/ui-library';
import deepcopy from 'deepcopy';
import { getCurrentLink } from '../util';
import { getImgUrl, parseURL } from '../../../utilities/helper/urlHelper';
import Countdown from'../common/countdown';
import { getScrollDepth, trackClpButtonClick, trackClpPdpClick, trackClpPlpClick } from '../track/index';
import specialLinkMixin from '../common/specialLink.mixins';

export default {
  name: 'ClpSingleImage',
  components: {
    LibButton,
    Countdown
  },
  mixins: [specialLinkMixin],
  inject:['doShare'],
  props: {
    // 组件数据对象
    options: {
      type: Object
    },
    // 神策code
    code: {
      type: String,
      default: ''
    },
    position: {
      type: String
    },
    franchise: {
      type: String
    },
    isChild:{
      type: Boolean,
      default: false
    },
    tabName: {
      type: String,
      default: ''
    },
    categoryCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 是否为移动端
      isMobile: '',
      // 是否加载完毕
      isLoad: false,
      imgRatio: {
        pc: {},
        mob: {}
      },
      countdownIsShow:false
    };
  },
  computed: {
    hotZoneList() {
      if (!process.client) return [];
      const mobImageHotspotList = deepcopy(this.options.mobImageHotspotList) || [];
      const pcImageHotspotList = deepcopy(this.options.pcImageHotspotList) || [];
      if (this.isMobile) {
        mobImageHotspotList.forEach((item) => {
          item.mobTop = item.upperLeftPercentageY;
          item.mobLeft = item.upperLeftPercentageX;
          item.mobHeight = item.lowerRightPercentageY - item.upperLeftPercentageY;
          item.mobWidth = item.lowerRightPercentageX - item.upperLeftPercentageX;
          item.style = {
            height: `${item.mobHeight}%`,
            width: `${item.mobWidth}%`,
            top: `${item.mobTop}%`,
            left: `${item.mobLeft}%`
          };
        });
        return mobImageHotspotList;
      } else {
        pcImageHotspotList.forEach((item) => {
          item.pcTop = item.upperLeftPercentageY;
          item.pcLeft = item.upperLeftPercentageX;
          item.pcHeight = item.lowerRightPercentageY - item.upperLeftPercentageY;
          item.pcWidth = item.lowerRightPercentageX - item.upperLeftPercentageX;
          item.style = {
            height: `${item.pcHeight}%`,
            width: `${item.pcWidth}%`,
            top: `${item.pcTop}%`,
            left: `${item.pcLeft}%`
          };
        });
        return pcImageHotspotList;
      }
    },
    backgroundStyle() {
      return {
        backgroundColor: (this.options?.backgroundColor) ? this.options.backgroundColor : "transparent",
        maxWidth: "1920px",
        margin: "0 auto"
      };
    },
    feedType() {
      if (!process.client) return;
      const imgSize = this.isMobile
        ? this.options.mobImgSize
        : this.options.pcImgSize;
      const tempArray = imgSize?.split('*');
      return tempArray && tempArray.length > 1 && tempArray[1] !== 'X';
    },
    // pc端、移动端分别展示不同的图片
    imagePath() {
      return this.isMobile ? getImgUrl(this.options.mobImgUrl, 1000) : this.options.pcImgUrl;
    },

    // 根据返回数据设置图片比例尺
    setSize() {
      // if (!this.options.mobImgSize) return;
      const imgSize = this.isMobile
        ? this.options.mobImgSize
        : this.options.pcImgSize;
      const sizeArray = imgSize.split('*');

      if (sizeArray.includes('X')) {
        return;
      }

      return {
        height: `calc(100vw / ${sizeArray[0]} * ${sizeArray[1]}) !important`
      };
    },

    // 标签样式
    labelStyles() {
      const { label } = this.options;
      return {
        color: label.textColor || 'var(--adi-color-white)',
        fontSize: label.size,
        fontFamily: label.bold
          ? 'AdihausDIN-Bold, PingFang SC, sans-serif, 微软雅黑'
          : 'AdihausDIN-Regular, PingFang SC, sans-serif, 微软雅黑',
        backgroundColor: label.color || 'var(--adi-color-black)'
      };
    },

    // 是否只有一个副标题
    isSingleSubtitle() {
      return this.options.subtitle.text && this.options.subtitle2.text
        ? ''
        : 'cutText';
    },

    // 动态设置主标题样式
    titleStyles() {
      return function (data) {
        return {
          fontSize: data.size,
          color: data.color
        };
      };
    },

    // 动态设置副标题样式
    subtitleStyles() {
      return function (data) {
        return {
          fontSize: data.size,
          color: data.color,
          fontFamily: data.bold
            ? 'AdihausDIN-Bold, PingFang SC, sans-serif, 微软雅黑'
            : 'AdihausDIN-Regular, PingFang SC, sans-serif, 微软雅黑'
        };
      };
    },

    // 动态设置按钮样式
    btnStyles() {
      const { button } = this.options;
      return {
        color: button.textColor,
        backgroundColor: button.color,
        // width: this.isMobile ? 'calc(100vw - 60px)' : '200px',
        width: this.isMobile ? 'calc(100% - 20px)' : 'fit-content',
        minWidth: this.isMobile ? '' : '200px',
        maxWidth: this.isMobile ? '' : '100%',
        fontFamily: button.bold
          ? 'AdihausDIN-Bold, PingFang SC, sans-serif, 微软雅黑'
          : 'AdihausDIN-Regular, PingFang SC, sans-serif, 微软雅黑'
      };
    },

    // 在移动端且图片尺寸为16：9时展示向下图标
    showUpIcon() {
      if (!this.options.mobImgSize || this.options.button.text) return false;
      const sizeArray = this.options.mobImgSize.split('*');
      return this.isMobile ? sizeArray[0] / sizeArray[1] === 9 / 16 : false;
    },

    // 鼠标样式设置
    setCursor() {
      if (this.options.button.text || !this.options.link) {
        return '';
      } else {
        return 'cursor-pointer';
      }
    },
    countdown(){
      if( this.options&&!!this.options.displayStartTime){
        return {
          displayEndTime:this.options.displayEndTime,
          displayEndTimeText:this.options.displayEndTimeText,
          displayStartTime:this.options.displayStartTime,
          displayStartTimeText:this.options.displayStartTimeText,
          textColor:this.options.textColor,
          textMarginLeft:this.options.textMarginLeft,
          textMarginTop:this.options.textMarginTop,
          timeStyle:this.options.timeStyle
        };
      }else{
        return null;
      }
    }
  },

  mounted() {
    this.getImgSize();
    this.identifyDevice();
    window.addEventListener('resize', this.identifyDevice);
  },

  destroyed() {
    window.removeEventListener('resize', this.identifyDevice);
  },

  methods: {
    handleHotZoneClick(item, key) {
      const options = {
        link: item.redirectLink,
        allLink: item.allLink
      };
      const curTerminalLink = this.options.allLink ? getCurrentLink(options.allLink) : options.link || '';
      if (!curTerminalLink) {
        return;
      }

      try {
        if (
          curTerminalLink.includes('/pages/product/list') ||
          curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
          curTerminalLink.includes('/pages/product/rich-list') ||
          curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
          curTerminalLink.includes('/pages/plp/index') ||
          curTerminalLink.includes('adidas://category') ||
          parseURL(curTerminalLink).path.startsWith('/plp') ||
          parseURL(curTerminalLink).path.startsWith('/splp')
        ) {
          this.trackData('plp', key, curTerminalLink);
        } else if (
          curTerminalLink.includes('/pages/product/details') ||
          curTerminalLink.includes('adidas://product') ||
          curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
          parseURL(curTerminalLink).path === '/pdp'
        ) {
          this.trackData('pdp', key, curTerminalLink);
        } else {
          this.trackData('button', key, curTerminalLink);
        }
      } catch (error) {
        console.log('sensors track报错', error);
      }
      this.handleSpecialLinkClick(curTerminalLink, options);
    },
    // true: 定高，false: 不定高
    switchType() {
      const imgSize = this.isMobile
        ? this.options.mobImgSize
        : this.options.pcImgSize;
      const tempArray = imgSize?.split('*');
      return tempArray && tempArray.length > 1 && tempArray[1] !== 'X';
    },
    handleImgLoad() {
      this.isLoad = true;
    },
    identifyDevice() {

      this.isMobile = window.innerWidth < 720;
      this.setContainerHieght();
    },

    setContainerHieght() {
      if (this.switchType()) return;
      // 除去滚轮屏幕的宽度
      const screenWidth = this.isMobile ? window.innerWidth : window.innerWidth - 15;
      let height;
      if (window.innerWidth <= 1440) {
        const rate =
          window.innerWidth < 720
            ? this.imgRatio.mob.rate
            : this.imgRatio.pc.rate;
        height = Math.floor( screenWidth / rate );
      } else {
        height = Math.floor( screenWidth / this.imgRatio.pc.rate );
      }
      if (window.innerWidth < 720) {
        this.$refs.singleImage
          ? (this.$refs.singleImage.style.height = height + 'px')
          : '';
        this.$refs.imgContainer
          ? (this.$refs.imgContainer.style.height = height + 'px')
          : '';
      } else {
        this.$refs.singleImage
          ? (this.$refs.singleImage.style.height = 'inherit')
          : '';
        this.$refs.imgContainer
          ? (this.$refs.imgContainer.style.height = 'inherit')
          : '';
      }
      // 当计算号图片容器高度后, 图片可能高度可能会超出容器, 需要隐藏超出部分
      if(this.$refs.imgContainer){
        this.$refs.imgContainer.style.overflow="hidden";
      }
    },

    getImgSize() {
      const self = this;
      const pcImg = new Image();
      const mobImg = new Image();
      pcImg.src = self.options.pcImgUrl;
      mobImg.src = self.options.mobImgUrl;
      pcImg.onload = () => {
        this.imgRatio.pc = {
          height: pcImg.height,
          width: pcImg.width,
          rate: pcImg.width / pcImg.height
        };
        this.setContainerHieght();
      };
      mobImg.onload = () => {
        this.imgRatio.mob = {
          height: mobImg.height,
          width: mobImg.width,
          rate: mobImg.width / mobImg.height
        };
        this.setContainerHieght();
      };
    },

    // 图片点击跳转逻辑
    handleImgClick() {
      const _options = this.options;
      this.franchise ? _options.franchise = this.franchise : '';
      const curTerminalLink = this.options.allLink ? getCurrentLink(this.options.allLink) : this.options.link || '';
      if (!curTerminalLink) {
        return;
      }
      // or wms app com (plp,splp)
      try {
        if (
          curTerminalLink.includes('/pages/product/list') ||
          curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
          curTerminalLink.includes('/pages/product/rich-list') ||
          curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
          curTerminalLink.includes('/pages/plp/index') ||
          curTerminalLink.includes('adidas://category') ||
          parseURL(curTerminalLink).path.startsWith('/plp') ||
          parseURL(curTerminalLink).path.startsWith('/splp')
        )
        {
          this.trackData('plp', -1, curTerminalLink);
        } else if (
          curTerminalLink.includes('/pages/product/details') ||
          curTerminalLink.includes('adidas://product') ||
          curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
          parseURL(curTerminalLink).path === '/pdp'
        ) {
          this.trackData('pdp', -1, curTerminalLink);
        } else {
          this.trackData('button', -1, curTerminalLink);
        }
      } catch (error) {
        console.log('sensors track报错', error);
      }
      this.handleSpecialLinkClick(curTerminalLink, _options);
    },

    // 埋点
    trackData(val, key = -1, link) {
      let data = [];
      const url = parseURL(link);
      const text = [this.options.title.text, this.options.title2.text, this.options.subtitle.text, this.options.subtitle2.text, this.options.label.text].filter(item => {
        return !!item;
      });
      const isContainHotZone = this.hotZoneList.length;
      const directAppProject = link.includes('BOS') && 'FS' || link.includes('CFM') && 'CFD' || '';
      switch (val) {
        case 'plp':
          data = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'ClpSingleImage',
            position_code: Number(this.position.slice(1)),
            card_position: 1,
            tab_name: this.tabName,
            scroll_depth: getScrollDepth(this.$refs.imgContainer.offsetTop),
            plp_url: link || '',
            plp_name: '',
            tag: this.options.labelInfoList.join(','),
            is_has_countdown:!!this.countdown,
            Hotzone_Number: isContainHotZone ? (key + 1) : 'no hotzone'
          };
          trackClpPlpClick(data);
          break;
        case 'pdp':
          data = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'ClpSingleImage',
            position_code: Number(this.position.slice(1)),
            article_position: 1,
            tab_name: this.tabName,
            recommend_id: '',
            scroll_depth: getScrollDepth(this.$refs.imgContainer.offsetTop),
            category_code: this.categoryCode,
            article_number: url?.query?.articleId || '',
            brand: '',
            category: this.categoryCode,
            gender: '',
            tag: this.options.labelInfoList.join(','),
            is_has_countdown:!!this.countdown,
            Hotzone_Number: isContainHotZone ? (key + 1) : 'no hotzone'
          };

          trackClpPdpClick(data);
          break;
        case 'button':
          data = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'ClpSingleImage',
            position_code: Number(this.position.slice(1)),
            scroll_depth: getScrollDepth(this.$refs.imgContainer.offsetTop),
            to_url: link || '',
            button_name: link === '领券' ? '领券' : this.options?.button?.text || '',
            tab_name: this.tabName,
            tag: this.options.labelInfoList.join(','),
            is_has_countdown:!!this.countdown,
            Hotzone_Number: isContainHotZone ? (key + 1) : 'no hotzone'
          };
           directAppProject ? data.direct_app_project = directAppProject : '';
          trackClpButtonClick(data);
          break;
      }
    },
    getClpView(data){
      if(!data?.is_has_countdown===true){
        data.is_has_countdown=this.countdownIsShow;
      }
    },
    updateCountdown(value){
      this.countdownIsShow=value;
    }
  }
};
