export const actions = {
  /**
   * 每个页面都会调用的初始化方法
   * @param dispatch
   * @param ctx
   * @return {Promise<void>}
   */
  async nuxtServerInit({ dispatch }, ctx) {
    // const promiseList = [];
    // promiseList.push(dispatch('cart/GET_COUNT', ctx));
    // promiseList.push(dispatch('address/GET_ADDRESS', ctx));
    // await Promise.all(promiseList);
    // await dispatch('user/setUser');
  }
};
