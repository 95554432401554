/*
 * @Description: loading插件
 * @Autor: Duan,JingLiang
 * @Date: 2021-09-17 22:15:31
 */

import Vue from 'vue';
import ALoading from '@adidas-com-front/ui-library/src/components/atoms/LibLoading/LibLoading.service';

Vue.use(ALoading);
