
import { LibButton } from '@adidas-com-front/ui-library';
import {toHomePageByEnv} from './utills';
export default {
  name: 'LuckyDraw500Error',
  components: { LibButton },
  data() {
    return {
      isWXBrowser:false,
      isQQBrowser:false,
      IsWMS:true,
      isAPP:true,
      IsOR: true,
      showHeader:false
    };
  },
  computed: {
    iconError() {
      return this.$config.S3_COM_HOST + '/assets/lucky-draw/icon.png';
    },
    animationUrl(){
      return (process.client)?this.$config.S3_COM_HOST+"/assets/error/404.mp4":'';
    }
  },
  mounted(){
    const userAgent = navigator.userAgent.toLowerCase();
    this.IsWMS = userAgent.includes('miniprogram');
    this.IsOR = this.$route.path.includes('/OR');
    this.isAPP = userAgent.includes('adidasapp');
    if (userAgent.match(/MicroMessenger/i)) {
      this.isWXBrowser = userAgent.match(/MicroMessenger/i)[0] === 'micromessenger';
    }
    if (
      userAgent.match(/tencenttraveler/) != null ||
      userAgent.match(/qqbrowse/) != null
    ) {
      this.isQQBrowser = true;
    }
    this.showHeader=true;
  },
  methods: {
    toHomePage() {
      this.$router.push('/');
    },
    handleLeft() {
      this.$router.back();
    },
    toHomePageByEnv() {
      toHomePageByEnv(this);
    }
  }
};
