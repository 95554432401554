/*
 * @Description: luckydraw 相关神策埋点
 * @Autor: wendy.zhou
 * @Date: 2022-04-18 10:01:55
 */
let tracker = {} as any;
const { setGlobalParams } = require('~/components/login-register/track');
if (process.client) {
  tracker = require('~/utilities/vendors/sensors').default;
}
export function trackPartnerCert(
  type: String,
  partner: String,
  step: String
): void {
  try {
    tracker.track('partner_project_certification', {
      certificate_type: type,
      partner,
      step
    });
  } catch (error) {
    console.warn('sensor:[partner_project_certification]', error);
  }
}

export async function trackPartnerLoginOrLogout(
  type: String
): Promise<void> {
  await setGlobalParams();
  try {
    tracker.track('Partner_Login_Logout', {
      type
    });
  } catch (error) {
    console.warn('sensor:[Partner_Login_Logout]', error);
  }
}

export function trackPartnerProfileView(
  partner: String,
  balanceMonth: Number,
  fixedMonthlyAmount: Number
): void {
  try {
    tracker.track('Partner_Profile_View', {
      partner,
      balance_month: balanceMonth,
      fixed_monthly_amount: fixedMonthlyAmount
    });
  } catch (error) {
    console.warn('sensor:[Partner_Profile_View]', error);
  }
}

export function trackPartnerView(
  viewType: String
): void {
  try {
    tracker.track('Partner_View', {
      view_type: viewType
    });
  } catch (error) {
    console.warn('sensor:[Partner_View]', error);
  }
}

export function trackPartnerClick(
  buttonName: String,
  viewType: String
): void {
  try {
    tracker.track('Partner_Click', {
      button_name: buttonName,
      view_type: viewType
    });
  } catch (error) {
    console.warn('sensor:[Partner_Click]', error);
  }
}
