
import { trackCatalogClick } from '../../track/index';
import { parseURL, getImgUrl } from '../../../../utilities/helper/urlHelper';
import { setBreadCrumbs, setPdpBreadCrumbs } from '../../utils';
import { handleHref } from '../../../clp/util';
import { searchClickTerm } from '../../../../pages/search/track';

export default {
  name: 'Sudoku',
  props: {
    // 九宫格组件数据源
    sudokuData: {
      type: Object
    },
    // 当前九宫格组件对应二级标题数据
    tagline: {
      type: Array
    },
    // 当前九宫格组件对应一级标题数据
    content: {
      type: Array
    },
    // 二级标题index
    taglineIndex: {
      type: Number,
      default: 0
    },
    nowNaItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 图片是否加载完成
      isLoad: false,
      // 是否第一次打开当前分类页
      firstIn: true,
      // 当前分类页长度
      maxLen: 0
    };
  },
  computed: {
    compData() {
      return this.$store.state.homePage.sortInfo.compData;
    },
    // 字符长度超过10进行截断处理
    cutOffItem() {
      return function (item) {
        if (item) {
          let text = item.imgDescription;
          if (text && text.length > 10) {
            text = text.slice(0, 9) + '...';
          }
          return text;
        } else {
          return '';
        }
      };
    },
    // 在图片加载完成后去掉加载动画
    imgLoad() {
      if (!this.firstIn) return 'showImg';
      return this.isLoad && 'fadein';
    },
    // 配置每个a标签的href
    setHref() {
      return function (item) {
        if (!item || !item.link) return 'javascript:void(0);';
        if (item && item.link) {
          const href = handleHref(item.link) || '';
          return href;
        }
      };
    },
    getResizeImgUrl() {
      return function (item) {
        if (item && item.imgUrl) {
          return getImgUrl(item.imgUrl, 200);
        } else {
          return '';
        }
      };
    }
  },
  methods: {
    // 类别点击事件
    handleClick(item) {
      if (item) {
        if (!item.link.startsWith('/')) {
          window.location = item.link;
          this.trackData(item, item.link, encodeURIComponent(item.link.split('?')[1]));
          return;
        }
        const noNavComp = this.compData[this.content[0].index];
        const idx = noNavComp.findIndex((it) => {return it.component[0].componentCode === this.sudokuData.componentCode && it.component[0].layoutPositionCode === this.sudokuData.layoutPositionCode; });
        let currentTagLine;
        for(let i = idx; i >= 0; i--) {
          if(noNavComp[i].component[0].componentCode === 'TAGLINE') {
            currentTagLine = noNavComp[i].component[0];
            break;
          }
        }
        console.log('currentTagLine', currentTagLine);
        // 面包屑处理
        let breadStr = '';
        breadStr += this.content[0].contentName + ':' + ',';
        breadStr +=
        currentTagLine?.options.headDescription + ':' + ',';
        breadStr += item.imgDescription + ':';

        const queryObj = parseURL(item.link);
        queryObj.query = {
          ...queryObj.query
        };
        if (queryObj.path.startsWith('/plp')) {
          queryObj.query.page = 1;
        }
        let url = queryObj.path + '?';
        for (const key in queryObj.query) {
          url = url + `${key}=${queryObj.query[key]}` + '&';
        }
        if (queryObj.path === '/pdp') {
          setPdpBreadCrumbs(breadStr, queryObj.query.articleId);
        } else {
          const key = queryObj.query.keyword
            ? decodeURI(queryObj.query.keyword)
            : queryObj.path.split('/')[queryObj.path.split('/').length - 1];
          setBreadCrumbs(key, breadStr);
          if (queryObj.query.keyword) {
            const urlPara={
              path:queryObj.path,
              query:{...queryObj.query,keyword:key}
            };
           this.trackSearchClickTerm(key, urlPara);
            }
        }

        window.location = url.slice(0, -1);
        const query = item.link.split('?')[1];
        this.trackData(item, url.slice(0, -1), encodeURIComponent(query));
        this.$emit('jump', item);
      }
    },
    async trackSearchClickTerm(keyword,urlPara) {
      const res = await this.$axios.get(
        this.$config.ECP_API_HOST + '/o2srh/v1/pub/platform-products/search',
        {
          params: {
            page: 1,
            pageSize: 24,
            abTest: 'A',
            keyword: keyword
          }
        }
      );
      const searchClickTermData = {
        // eslint-disable-next-line camelcase
        search_type: "Catalog Preset",
        // eslint-disable-next-line camelcase
        search_term: keyword,
        // eslint-disable-next-line camelcase
        search_result_type: 'PLP',
        // eslint-disable-next-line camelcase
        search_result_detail: '',
        // eslint-disable-next-line camelcase
        search_results: res.totalElements
      };
      if (res.totalElements === 0 && res.plpType !== 'deepLink') {
        searchClickTermData.search_result_type = 'No result';
      }
      searchClickTermData.search_result_detail= decodeURI(this.$router.resolve(urlPara).href);
      searchClickTerm(searchClickTermData);
    },
    // 埋点数据格式化
    trackData(item, linkTo, query) {
      const data = {
        catalog_title: this.nowNaItem.contentName,
        catalog_section: this.tagline[this.taglineIndex]?.options.headDescription,
        position_code: this.sudokuData.layoutPositionCode,
        component_code: this.sudokuData.componentCode,
        button_name: item.imgDescription,
        to_page_path: linkTo,
        to_page_query: query,
        to_page_title: this.tagline[this.taglineIndex]?.options.headDescription
      };
      console.log('pc 九宫格组件 click track data', data);
      trackCatalogClick(data);
    },

    // 图片加载完成事件
    onLoad() {
      this.maxLen = this.maxLen + 1;
      if (this.maxLen === this.sudokuData.options.itemList.length) {
        this.firstIn = false;
      }
      this.isLoad = true;
    }
  }
};
