/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2024-03-08 12:22:47
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2024-03-20 16:35:22
 * @FilePath: /new-com/adidas-com-front/packages/nuxt/components/clp/survey/utils.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { BasicUtils, iCtx } from '~/utilities/basic/utils';
import { AppLogin } from "~/utilities/basic/appLogin";
import { /* TokenUtils , */isClient } from '~/utilities/helper/SystemHelper';
declare let wx: any;
export class Utils extends BasicUtils {
  private appLogin: AppLogin | void;
  /**
   *
   * @param ctx
   * @param activityCode
   * @param activityData
   */
  constructor(protected ctx: iCtx) {
    super(ctx);
    if (isClient && this.IsAPP && window.AdidasApp) {
      this.appLogin = new AppLogin(window.AdidasApp);
    }
  }

  /**
   * 弹登录窗口
   * @returns  true 登录成功 false 登录取消
   */
  async loginAsync(): Promise<boolean> {
    if (this.IsAPP && this.appLogin) {
      return await this.appLogin.appLoginAsync();
    } else if (this.IsWMS || this.IsOR || this.IsAdiclub) {
      const urlPrar = new URL(location.href);
      let shopCode;
      let contentId;
      urlPrar.searchParams.forEach((value, key) => {
        if (key !== "shopCode" && key !== "contentId" && key !== "activityCode") {
          urlPrar.searchParams.delete(key);
        }
        if (key === 'shopCode') {
          shopCode = value;
        }
        if (key === 'contentId') {
          contentId = value;
        }
      });
      const path = urlPrar.origin + urlPrar.pathname + '?contentId=' + contentId;
      let WMSLoginPath: string;
      if (this.getToken() && !this.isMemberLogin()) {
        WMSLoginPath = `/pages/redirect/login-redirect?memberLoginOnly=true&redirectUrl=${encodeURIComponent(path)}`;
      } else {
        WMSLoginPath = `/pages/redirect/login-redirect?redirectUrl=${encodeURIComponent(path)}`;
      }
      const ACLoginPath = `/pages/crmPackage/pages/redirect/index?actionType=login&redirectUrl=${encodeURIComponent(path)}`;
      const ORLoginPath = `/pages/otherPackage/pages/redirect/login-redirect?shopCode=${shopCode}&redirectUrl=${encodeURIComponent(path)}`;
      console.log('sfjsldjflks', path, urlPrar);
      const LoginPath = this.IsWMS && WMSLoginPath || this.IsOR && ORLoginPath || this.IsAdiclub && ACLoginPath;

      wx.miniProgram.redirectTo({
        url: LoginPath
      });
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
      return false;
    } else {
      return new Promise<boolean>((resolve) => {
        // eslint-disable-next-line no-undef
        let loginComP: NodeJS.Timeout;

        this.ctx.$login({
          show: true,
          firstStepProp: true,
          joinAdiclubProp: true,
          on: {
            login() {
              if (loginComP) {
                clearTimeout(loginComP);
              }
              resolve(true);
            },
            close() {
              resolve(false);

              // 这个坑爹的方法会在 login前先运行
              loginComP = setTimeout(() => {
                resolve(false);
              }, 100);
            }
          }
        });
      });
    }
  }
}


