import {iCtx} from "~/utilities/basic/utils";
declare let wx: any;
export function toHomePageByEnv(ctx:iCtx) {
  const ua = navigator.userAgent.toLowerCase();
  let currentEnviroment = '';
  if(ctx.$route.path.includes('/OR')) {
    currentEnviroment = 'OR';
  } else if (ctx.$route.path.includes('/AC')) {
    currentEnviroment = 'Adiclub';
  }else if (ua.includes('miniprogram')) {
    currentEnviroment = 'miniprogram';
  } else if (ua.includes('adidasapp')) {
    currentEnviroment = 'AdidasApp';
  } 
  if (currentEnviroment) {
    if(currentEnviroment === 'OR') {
      // 如果当前环境是OR
      const finalUrl = '/orwshop/pages/home-page/index';
      wx.miniProgram.switchTab({
        url: finalUrl
      });
    } else if (currentEnviroment === 'Adiclub') {
      // 如果当前环境是Adiclub
      const finalUrl = '/pages/memberCenter/homePage/index';
      wx.miniProgram.switchTab({
        url: finalUrl
      });
    } else if (currentEnviroment === 'miniprogram') {
      // 如果当前环境是WMS
      const finalUrl = '/pages/tabs/home';
      // console.log('小程序跳转home路径：', finalUrl);
      wx.miniProgram.switchTab({
        url: finalUrl
      });
    } else if (currentEnviroment === 'AdidasApp') {
      // 如果当前链接参数环境是APP内嵌
      const finalUrl = 'adidas://shop';
      // console.log('app路径：', finalUrl);
      setTimeout(() => {
        window.location.href = finalUrl;
      }, 1500);
    }
  } else {
    // 否则直接跳转系统内
    // console.log('com系统内');
    ctx.$router.push('/');
  }
}