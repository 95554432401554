
import { LibButton } from '@adidas-com-front/ui-library';
import SearchBarRest from '../search/search-bar/SearchBarRest.vue';
export default {
  components: { LibButton, SearchBarRest },
  props: {
    errorCode: String
  },
  data() {
    return {
      left: 'icon-a-32_icon_performance',
      errorInfo: [
        {
          errorNum: 'server_busy',
          errorIcon: 'icon-cloud_disabled_32',
          errorTitle: '当前页面访问人数过多',
          errorInfo: '请稍后重试'
        },
        {
          errorNum: 'too_many_visitors',
          errorIcon: 'icon-no_connection_32',
          errorTitle: '暂无网络连接',
          errorInfo: ''
        },
        {
          errorNum: 'server_strays',
          errorIcon: 'icon-no_connection_32',
          errorTitle: '服务器开小差了',
          errorInfo: '请稍后重试'
        }
      ],
      errorItem: 0
    };
  },
  computed: {
    // 购物车数量
    cartNum() {
      return this.$store.state.homePage.navInfo.cartNum;
    }
  },
  mounted() {
    /**
     * @description: 根据返回的errorCode获取数组errorInfo中对应下标值
     * @param {*}
     * @return {*}
     */
    const errorItem = this.errorInfo.findIndex((item) => {
      return item.errorNum === this.errorCode;
    });
    this.errorItem = errorItem;
  },
  methods: {
    handleClick() {
      this.$emit('handleClick');
    },
    handleSearch() {
      this.$router.push({
        query: {
          VisibleFlag: 'replace'
        }
      });
    },
    gotoCatalog() {
      this.$router.push({
        path: `/catalog/catalog-mobile`
      });
    },
    gotoCart() {
      this.$router.push({
        path: `/cart`
      });
    },
    handleLeft() {
      this.$router.push({
        path: `/`
      });
    }
  }
};
