import Cookies from 'js-cookie';
import { DayJs } from '@adidas-com-front/ui-library/src/components/atoms/LibFormat/LibFormat.utils';
import jwt from 'jsonwebtoken';
import { isClient, TokenUtils , getPhone, getNonmemberTel ,getEmployeeId} from '~/utilities';

const originalState = {
  // 登录半窗展示状态
  loginPopover: false,
  updateLoginCount: 0,
  // 会员信息
  accountInfo: {},
  // 员工信息
  employeeInfo: {},
  // 积分商城信息
  pointShopInfo: {},
  // 员工Id
  employeeId: '',
  // 会员标识颜色码
  levelColor: ['#8b99a4', '#CA8A6A', '#B0B0B6', '#D6C27E'],
  // 当前会员等级信息
  currentLevel: {},
  orderInfo: {
    // 各订单数量
    toBePay: 0,
    toBeDelivered: 0,
    toBeReceived: 0,
    toBeRate: 0
  },
  isLogin: false,
  // 是否从myAccount页面退出登陆, 因为要在退出到主页后再显示退出成功的提示
  loggingOut: false,
  // 是否从myAccount页面退出登陆员工账户, 因为要在退出到主页后再显示退出成功的提示
  employeeLoggingOut: false,
  // 优惠券列表
  couponList: [],
  // 优惠码列表
  couponCodeList: [],
  couponInfo: {
    // 优惠券信息, 数量, 即将过期的数量
    num: undefined,
    aboutExpireNum: undefined
  },
  couponCodeInfo: {
    // 优惠码信息, 数量, 即将过期的数量
    num: undefined,
    aboutExpireNum: 0
  },
  // 员工登录
  isEmployeeLogin: false,
  // myAccount骨架屏
  showSkeleton: true,
  // 是否医护
  isDoctorOrNurse: false,
  // 是否学生
  isCampus: false,
  // 是否是合作伙伴且不是学生,深圳志愿者
  isPartnerShip: false,
  // 是否是深圳志愿者
  isVolunteer: false,
  // 非会员电话号码
  nonmemberTel: '',
  // 非会员登录
  isNonmemberLogin: false,
  // 是否新雍和宫
  isnew:false,
  needLoginList: [
    '/my-account',
    '/my-account/score',
    '/my-account/coupon',
    '/my-account/order/list',
    '/my-account/return/list',
    '/my-account/personalInfo',
    '/my-account/address',
    '/my-account/contact-us',
    '/my-account/account'
  ],
  isKeep: true,
  partnerInfo: {
    leftQuotaAmount: 0,
    memberEndTime: '',
    memberStartTime: '',
    memberStatusCode: '',
    partnerShipStatusCode: '',
    partnershipCode: '',
    partnershipEndTime: '',
    partnershipName: '',
    partnershipStartTime: '',
    partnershipTypeCode: '',
    subscribeFlag: 0,
    totalQuotaAmount: 0,
    totalQuotaDiscount: 0
  },
  partnerTC: {}
  // nonmember:{
  //   tel:undefined,
  // }
};

const state = JSON.parse(JSON.stringify(originalState));

const mutations = {
  SET_LOGIN_POPOVER(state, status) {
    state.loginPopover = status;
  },
  SET_ACCOUNT_INFO(state, accountInfo) {
    state.accountInfo = accountInfo || {};
  },
  SET_EMPLOYEE_INFO(state, employeeInfo) {
    state.employeeInfo = employeeInfo || {};
    state.employeeId = employeeInfo?.employeeNumber;
  },
  SET_POINT_SHOP_INFO(state, employeeInfo) {
    state.pointShopInfo = employeeInfo || {};
  },
  SET_CURRENT_LEVEL(state, currentLevel) {
    state.currentLevel = currentLevel || {};
  },
  SET_COUPON(state, { num, aboutExpireNum, couponList }) {
    const _num = num !== undefined ? num : state.couponInfo.num;
    const _aboutExpireNum =
      aboutExpireNum !== undefined
        ? aboutExpireNum
        : state.couponInfo.aboutExpireNum;

    state.couponInfo = Object.assign(
      {},
      { ...state.couponInfo, num: _num, aboutExpireNum: _aboutExpireNum }
    );
    state.couponList = couponList || [];
  },
  SET_COUPON_CODE(state, { num, aboutExpireNum, couponCodeList }) {
    const _num = num !== undefined ? num : state.couponInfo.num;
    const _aboutExpireNum =
      aboutExpireNum !== undefined
        ? aboutExpireNum
        : state.couponInfo.aboutExpireNum;

    state.couponCodeInfo = Object.assign(
      {},
      { ...state.couponCodeInfo, num: _num, aboutExpireNum: _aboutExpireNum }
    );
    state.couponCodeList = couponCodeList || [];
  },
  SET_ORDER_INFO(state, orderInfo) {
    state.orderInfo = orderInfo || {};
  },
  SET_LOGIN_STATUS(state, isLogin) {
    // 此处有个登录后会被调用两次的问题，导致触发相关watch 两次，待有时间研究
    state.isLogin = isLogin || false;
    if (isLogin) {
      // 登录后判断是否医护
      const token = TokenUtils.getOriginToken();
      const info = jwt.decode(token);
      getAccoutType(state, info);
    }
  },
  SET_SPECIAL_IDENTITY(state, identity) {
    if (identity) {
      state.isDoctorOrNurse = false;
      state.isCampus = false;
      state.isPartnerShip = false;
      state.isVolunteer = false;
    } else {
      state.isEmployeeLogin = false;
      state.employeeInfo = {};
      state.employeeId = '';
    }
  },
  SET_LOGOUT_STATUS(state, loggingOutFlag) {
    state.loggingOut = loggingOutFlag || false;
  },
  SET_EMPLOYEE_LOGOUT_STATUS(state, employeeLoggingOutFlag) {
    state.employeeLoggingOut = employeeLoggingOutFlag || false;
  },
  SET_EMPLOYEE_LOGIN_STATUS(state, isEmployeeLogin) {
    state.updateLoginStateCount++;
    state.isEmployeeLogin = isEmployeeLogin || false;
  },
  SET_NONMEMBER_LOGIN_STATUS(state, isNonmemberLogin) {
    state.updateLoginStateCount++;
    state.isNonmemberLogin = isNonmemberLogin || false;
  },
  SET_IS_NEW(state,isnew){
    state.isnew=isnew;
  },
  SET_SKELETON_STATUS(state, showSkeleton) {
    state.showSkeleton = showSkeleton || false;
  },
  /**
   * 退出登录，登录失效所有状态应该清空
   * @param state
   * @constructor
   */
  SET_LOGOUT(state) {
    // const backup = JSON.parse(JSON.stringify(originalState));
    // Object.keys(backup).forEach(key => {
    //   state[key] = backup[key];
    // });
    state.isEmployeeLogin = false;
    state.isLogin = false;
    state.isDoctorOrNurse = false;
    state.isCampus = false;
    state.isPartnerShip = false;
    state.isVolunteer = false;
    state.isNonmemberLogin = false;
    state.nonmemberTel = '';
    state.orderInfo = {
      // 各订单数量
      toBePay: 0,
      toBeDelivered: 0,
      toBeReceived: 0,
      toBeRate: 0
    };
    state.partnerInfo = {
      leftQuotaAmount: 0,
      memberEndTime: '',
      memberStartTime: '',
      memberStatusCode: '',
      partnerShipStatusCode: '',
      partnershipCode: '',
      partnershipEndTime: '',
      partnershipName: '',
      partnershipStartTime: '',
      partnershipTypeCode: '',
      subscribeFlag: 0,
      totalQuotaAmount: 0,
      totalQuotaDiscount: 0
    };
  },
  LOGIN_COUNT(state) {
    state.updateLoginCount++;
  },
  SET_NONMEMBER(state, tel) {
    if (!state.nonmemberTel) {
      if (tel) {
        state.nonmemberTel = tel;
      } else {
        const newTel = getNonmemberTel();
        state.nonmemberTel = newTel || '';
      }
    } else {
      const newTel = getNonmemberTel();
      if (!newTel) {
        state.nonmemberTel = '';
      }
    }
  },
  SET_KEEP_STATUS(state, val) {
    state.isKeep = val;
  },
  SET_PARTNER_INFO(state, val) {
    state.partnerInfo = val || {};
  },
  SET_PARTNER_TC(state, val) {
    state.partnerTC = val;
  }
};


// 是否会员账号登录
const isMemberLogin = function () {
  return !!TokenUtils.getToken() && !!getPhone();
};

// 是否员工账号登录
const isEmpLogin = function () {
  return !!TokenUtils.getToken() && !!getEmployeeId();
};

// 是否非会员账号登录
const isNonMemLogin = function () {
  return !!TokenUtils.getToken() && !!getNonmemberTel();
};

const getAccoutType = function (state, info) {
  state.isCampus = false;
  state.isPartnerShip = false;
  state.isDoctorOrNurse = false;
  state.isVolunteer = false;
  if (info?.role?.includes('MEDICAL')) {
    state.isDoctorOrNurse = true;
  } else if (info?.role?.includes('PARTNERSHIP-CAMPUS')) {
    state.isCampus = true;
  } else if (info?.role?.includes('PARTNERSHIP-SHENZVOLUNTEER')) {
    state.isVolunteer = true;
  } else if (
    info?.role?.find((it) => {
      return it.includes('PARTNERSHIP');
    })
  ) {
    state.isPartnerShip = true;
  }
};


const actions = {
  async getAccountInfo({ commit }, { ctx, cb }) {
    try {
      // const params = {
      //   mobilephone: getPhoneNumber(),
      // };
      const data = await ctx.$axios.get(
        `${ctx.$config.CRM_API_HOST}/membership/adidas/cn/member/query/ECOM`
      );
      window.localStorage.setItem('memberId', data.memberId);
      cb && cb();
      commit('SET_ACCOUNT_INFO', data);

      // 当前会员等级
      let level;
      let text;
      let benefitNum;
      switch (data?.memberTier) {
        case '铜牌会员':
          level = 1;
          benefitNum = 8;
          text = '铜牌会员';
          break;
        case '银牌会员':
          level = 2;
          benefitNum = 12;
          text = '银座会员';
          break;
        case '金牌会员':
          level = 3;
          benefitNum = 16;
          text = '金尊会员';
          break;
        default:
          text = '新标会员';
          level = 0;
          benefitNum = 5;
          break;
      }
      if (!data?.memberTier) {
        text = '会员';
        level = undefined;
        benefitNum = undefined;
      }
      commit('SET_CURRENT_LEVEL', { level, text, benefitNum });
    } catch (e) {
      return false;
    }
  },

  async getEmployeeInfo({ commit }, { ctx }) {
    try {
      const data = await ctx.$axios.get(
        `${ctx.$config.ECP_API_HOST}/o2vip/v1/user-quota/info`,
        {}
      );
      commit('SET_EMPLOYEE_INFO', data);
    } catch (e) {
      return false;
    }
  },

  async getPointShopInfo({ commit }, { ctx }) {
    try {
      const data = await ctx.$axios.get(
        `${this.$config.S3_COM_HOST}/static/membership/jumpmembership.json?20230221`
      );
      commit('SET_POINT_SHOP_INFO', data);
    } catch (e) {
      return false;
    }
  },

  async getCoupon({ commit }, { ctx, options }) {
    try {
      let memberId = '';
      if (isClient && window.localStorage) {
        memberId = window.localStorage.getItem('memberId');
      } else {
        memberId = Cookies.get('memberId');
      }

      if (!memberId) {
        const data = await ctx.$axios.get(
          `${ctx.$config.CRM_API_HOST}/membership/adidas/cn/member/query/ECOM`
        );
        memberId = data.memberId;
        window.localStorage.setItem('memberId', memberId);
      }
      const params = {
        source: 'ECOM',
        state: ['100000001'],
        pageSize: 99999,
        memberId
        // mobilephone: getPhoneNumber(),
      };
      const data = await ctx.$axios.post(
        `${ctx.$config.CRM_API_HOST}/membership/adidas/cn/coupon/query`,
        params,
        options
      );
      if (data && data.couponList?.length) {
        let aboutExpireNum = 0;
        data.couponList.forEach((el) => {
          if (DayJs(el.endTime).isBefore(DayJs().add(15, 'day'))) {
            aboutExpireNum += 1;
          }
        });

        commit(
          'SET_COUPON',
          JSON.parse(
            JSON.stringify({
              num: data.couponList.length,
              aboutExpireNum,
              couponList: data.couponList
            })
          )
        );
      } else {
        commit('SET_COUPON', { num: 0, aboutExpireNum: 0, couponList: [] });
      }
    } catch (e) {
      return false;
    }
  },

  async getCouponCode({ commit }, { ctx, options }) {
    try {
      const data = await ctx.$axios.get(
        `${ctx.$config.ECP_API_HOST}/o2pmt/v1/promocode-self/available`,
        options
      );
      if (data && data.length) {
        let aboutExpireNum = 0;
        data.forEach((el) => {
          if (DayJs(el.endTime).isBefore(DayJs().add(15, 'day'))) {
            aboutExpireNum += 1;
          }
        });
        commit('SET_COUPON_CODE', {
          num: data.length,
          aboutExpireNum,
          couponCodeList: data
        });
        return data;
      } else {
        commit('SET_COUPON_CODE', {
          num: 0,
          aboutExpireNum: 0,
          couponCodeList: []
        });
      }
    } catch (e) {
      return false;
    }
  },
  async getOrderNum({ commit }, { ctx }) {
    try {
      const data = await ctx.$axios.get(
        ctx.$config.ECP_API_HOST + '/o2ord/v1/multistate-orderQuantity',
        { noForceLogin: true }
      );
      commit('SET_ORDER_INFO', data);
    } catch (e) {
      return false;
    }
  },
  async getPartnerInfo({ commit, state }, { ctx, cb }) {
    if (state.isPartnerShip || state.isCampus || state.isVolunteer) {
      try {
        const data = await ctx.$axios.get(
          `${ctx.$config.ECP_API_HOST}/o2vip/v1/0/partnerships/accountDetail`
        );
        commit('SET_PARTNER_INFO', data);
        cb && cb();
      } catch (e) {
        return false;
      }
    }
  },
  async getPartnerTC({ commit, state }, { ctx, cb }) {
    if (state.isPartnerShip || state.isCampus || state.isVolunteer) {
      try {
        const data = await ctx.$axios.get(
          `${ctx.$config.ECP_API_HOST}/o2vip/v1/0/partnerships`,
          {
            params: {
              partnershipCode: state.partnerInfo.partnershipCode
            }
          }
        );
        cb && cb();
        commit('SET_PARTNER_TC', data);
      } catch (e) {
        return false;
      }
    }
  },
  updateLoginStatus({ commit }, loginFlag) {
    const _isLogin = loginFlag || isMemberLogin();
    commit('SET_LOGIN_STATUS', _isLogin);
    // commit('SET_NONMEMBER','');
  },
  updateEmployeeLoginStatus({ commit }, loginFlag) {
    const isEmployeeLogin = loginFlag || isEmpLogin();
    commit('SET_EMPLOYEE_LOGIN_STATUS', isEmployeeLogin);
  },
  updateNonmemberLoginStatus({ commit }, loginFlag) {
    const isNonmemberLogin = loginFlag || isNonMemLogin();
    commit('SET_NONMEMBER_LOGIN_STATUS', isNonmemberLogin);
  },
  upadteIsNew({ commit }){
    commit('SET_IS_NEW', Cookies.get('isNew')==="1");
  },
  // 登陆后需要的数据
  afterLogin({ dispatch }, { ctx }) {
    dispatch('updateLoginStatus');
    dispatch('getAccountInfo', {
      ctx,
      cb: () => {
        dispatch('getCoupon', { ctx, options: { noForceLogin: true } });
        dispatch('getCouponCode', { ctx, options: { noForceLogin: true } });
      }
    });
    dispatch('getPartnerInfo', {
      ctx,
      cb: () => {
        dispatch('getPartnerTC', { ctx });
      }
    });
    dispatch('homePage/getCartNum', { ctx }, { root: true });
    dispatch('getOrderNum', { ctx });
  },
  // 非会员登陆后需要的数据
  afterNonmemberLogin({ dispatch }, { ctx }) {
    dispatch('updateNonmemberLoginStatus');
    dispatch('homePage/getCartNum', { ctx }, { root: true });
    dispatch('getOrderNum', { ctx });
  },
  // 员工登陆后需要的数据
  afterEmployeeLogin({ dispatch }, { ctx }) {
    dispatch('updateEmployeeLoginStatus');
    dispatch('getEmployeeInfo', { ctx });
    dispatch('homePage/getCartNum', { ctx }, { root: true });
    dispatch('getOrderNum', { ctx });
  },
  // 更新非会员电话信息
  updateNonmember({ commit }, tel) {
    commit('SET_NONMEMBER', tel);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
