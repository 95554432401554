
import { LibButton } from '@adidas-com-front/ui-library';
import { trackClpPdpClick, getScrollDepth } from '../../../track/index';
import LibProductCardMobile from '../common/product-card.vue';
import ProductCardPc from '../common/product-card-pc.vue';

export default {
  name: 'ProductList',
  components: { ProductCardPc, LibButton, LibProductCardMobile },
  props: {
    info: {
      type: Object
    },
    products: {
      type: Array,
      default: () => []
    },
    articles: {
      type: Array,
      default: () => []
    },
    type: {
      type: String
    },
    isMobile: {
      type: Boolean
    },
    articleList:{
      type:Array,
      default: () => []
    },
    tabName: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    categoryCode: {
      type: String,
      default: ''
    },
    clpShow: {
      type: Boolean
    }
  },
  data() {
    return {
      activeBanner: 0,
      swiperProgress: 0,
      slidePerView: 0,
      swiperOption: {
        watchOverflow: true,
        simulateTouch: false,
        freeMode: false,
        spaceBetween: 2,
        slidesPerView: 3,
        slidesPerGroup: 3,
        breakpoints: {
          719: {
            freeMode: true,
            spaceBetween: 2,
            slidesPerView: 3,
            slidesPerGroup: 1
          }
        },
        navigation: {
          nextEl: '.navigation-right',
          prevEl: '.navigation-left'
        },
        on: {
          slideChange: () => {
            this.swiperProgress = this.swiper.progress;
            this.activeBanner = this.swiper.activeIndex;
          }
        }
      },
      device: 'pc',
      benefitDescriptionHeight: '',
      swiperNavigationTop: '0px',
      productListData: []
    };
  },
  computed: {
    LibProductCardCLass(){
      return(index)=>{
        return [{'sm:mb-2': index !== this.list.length - 1}, {'product-list-card-2': this.list.length === 2}, {'product-list-card-1': this.list.length === 1}];
      };
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    list() {
      let list;
      if(this.type === 'PLP'){
        list=this.products.slice(0, this.info.pageSize);
      }else{
        list=this.articleList;
      }
      if(!list){
        return [];
      }
      if(this.isMobile){
        let size;
        if (this.info.pageSize) {
          size = this.info.pageSize < 3 ? this.info.pageSize : 3;
        } else {
          size = 3;
        }
        return list.slice(0, size);
      }
      return list;
    }
  },
  watch: {
    clpShow(newValue, oldValue) {
      this.$nextTick(()=>{
        this.swiper.update();
      });
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    sensorClickTrack(item, index) {
      const labelInfoList = [];
      if (this.info.labelInfo) {
        this.info.labelInfo.forEach((labelItem) => {
          labelInfoList.push(`${labelItem.labelGroup}:${labelItem.label.join('|')}`);
        });
      }
      // 判断当前父组件做埋点
      // const parent = this.$parent.transmit();
      const data = {
        campaign_name: this.$route.query.contentId || '',
        sub_campaign_name: this.code,
        text: '',
        component_code: 'LookGroup',
        position_code: Number(this.position.slice(1)),
        article_position: index + 1 + 1,
        tab_name: this.tabName,
        recommend_id: '',
        scroll_depth: getScrollDepth(this.$refs.lookGroupRef.offsetTop),
        category_code: item.category || '',
        article_number: item.articleId,
        brand: item.brandName,
        category: item.category || '',
        gender: item.genderShow,
        tag: labelInfoList.join(',')
      };

      trackClpPdpClick(data);
    },
    handleResize() {
      let slidePerView;
      this.slidePerView = slidePerView;

      if (!document.getElementsByClassName('product-list')[0]) return;
      // 设置完轮播图属性 动态获取的图片高度确定导航的绝对位置
      setTimeout(() => {
        const imgDom = document.getElementsByClassName('product-list')[0];
        if (!imgDom) return;
        this.swiperNavigationTop =
          ((imgDom.clientWidth - 4) / 3 + 72) / 2 - 16 + 'px';
      }, 0);
    }
  }
};
