
export default {
  props:{
    isMobile:{
      type: Boolean,
      default: false
    },
    joinAdiclubProp:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconGif() {
      return this.$config.S3_COM_HOST + '/assets/login/icon-animate.gif';
    }
  }
};
