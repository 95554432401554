
import { mapState } from 'vuex';
import { trackClpVideoClick, getScrollDepth } from '../track/index';
export default {
  name: 'ClpVideo',
  components: {},
  props: {
    backgroundColor: {
      type: String,
      default: '#FFFFFF'
    },
    pcVideoUrl: {
      type: String,
      default: ''
    },
    pcImgUrl: {
      type: String,
      default: ''
    },
    mobVideoUrl: {
      type: String,
      default: ''
    },
    mobImgUrl: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    isChild: {
      type:Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      // 点击播放
      clickFlag: false,
      // 移动端标识
      isMobile: false,
      // ios系统标识
      isIos: false,
      // 微信客户端标识
      isWeixin: false,
      // 是否已加载第一帧
      loaded: false
    };
  },
  computed: {
    ...mapState('clp', [
      'uniquePlay',
      'currentVideoPostion'
    ]),
    backgroundStyle() {
      const style={
        backgroundColor:(this.options?.backgroundColor)?this.options.backgroundColor:"transparent",
        maxWidth: "1920px",
        margin: "0 auto"
      };

      if(this.isMobile&&this.options?.mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.options.mobBackgroundImage})`;
      }else if(!this.isMobile&&this.options?.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.options.pcBackgroundImage})`;
      }
      return style;
    }
  },
  watch: {
    pcVideoUrl(val) {
      this.clickFlag = false;
      this.loaded = false;
    },
    mobVideoUrl(val) {
      this.clickFlag = false;
      this.loaded = false;
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 720;
    const u = navigator.userAgent.toLowerCase();
    this.isIos = !!u.match(/macintosh|mac os x/i);
    this.isWeixin = u.includes('micromessenger');
    this.$store.commit('clp/SET_CURRENT_VIDEO_POSITION', this.position);
    const windowHeight = this.isWeixin ? (window.innerHeight || document.documentElement.clientHeight) - 20 : window.innerHeight || document.documentElement.clientHeight;
    // 播放上边界
    this.downVideoThreshold = parseInt(windowHeight * 0.2);
    // 播放下边界
    this.upVideoThreshold = windowHeight - this.downVideoThreshold;
    // 判断PC（中大屏）、移动端、ios系统、微信
    if (this.isMobile && !this.mobImgUrl) {
      if (this.isWeixin) {
        // 微信浏览器
        // 使用微信jssdk来实现自动播放
        const video = this.$refs.video;
        const _this = this;
        if (typeof WeixinJSBridge === 'undefined') {
          document.addEventListener(
            'WeixinJSBridgeReady',
            function () {
              try {
                video.play();
                video.ontimeupdate = () => {
                  if (!_this.clickFlag && video.currentTime > 0.1) {
                    video.pause();
                    video.currentTime = 0.1;
                    _this.$nextTick(() => {
                      _this.loaded = true;
                    });
                  }
                };
              } catch (error) {
                console.log('clp video播放错误', error);
              }
            },
            false
          );
        } else {
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
            try {
              video.play();
              video.ontimeupdate = () => {
                if (!_this.clickFlag && video.currentTime > 0.1) {
                  video.pause();
                  video.currentTime = 0.1;
                  _this.$nextTick(() => {
                    _this.loaded = true;
                  });
                }
              };
            } catch (error) {
              console.log('clp video播放错误', error);
            }
          });
        }
      } else {
        try {
          // 非微信浏览器
          const video = this.$refs.video;
          video.muted = true;
          video.autoplay = true;
          video.play();
          video.ontimeupdate = () => {
            if (!this.clickFlag && video.currentTime > 0.1) {
              video.pause();
              // video.currentTime = 0.1;
              this.$nextTick(() => {
                this.loaded = true;
              });
            }
          };
        } catch (error) {
          console.log('clp video播放错误', error);
        }
      }
    } else if (!this.isMobile && !this.pcImgUrl) {
      // PC(中大屏)，且没有传入封面
      // 展示视频第一帧
      const video = this.$refs.video;
      if (!this.isIos) {
        // 非mac
        video.oncanplay = () => {
          this.loaded = true;
        };
      } else {
        try {
          // mac
          // 部分浏览器视频不能自动显示第一帧；自动播放视频，并暂停在0.1s处，以代替视频封面图
          video.muted = true;
          video.autoplay = true;
          video.play();
          video.ontimeupdate = () => {
            if (!this.clickFlag && video.currentTime > 0.1) {
              video.pause();
              // video.currentTime = 0.1;
              this.$nextTick(() => {
                this.loaded = true;
              });
            }
          };
        } catch (error) {
          console.log('clp video播放错误', error);
        }
      }
    }
    const elevideo = document.getElementById('video');
    elevideo&&elevideo.addEventListener('playing', this.playing);
    elevideo&&elevideo.addEventListener('pause', this.pause);
    this.checkFirstVideoPlay();
    window.addEventListener('scroll', this.isElementVisible, true);
  },
  updated() {
    // 展示视频第一帧
    if ((this.pcImgUrl || this.mobImgUrl) && !this.clickFlag && !this.loaded) {
      // 有封面 且 不是ios wechat 浏览器 展示封面
    } else if (
      (!this.pcImgUrl &&
      !this.mobImgUrl &&
      !this.clickFlag &&
      !this.loaded)
    ) {
      // 没有传入封面
      // 部分浏览器视频不能自动显示第一帧；自动播放视频，并暂停在0.1s处，以代替视频封面图
      if (!this.isMobile) {
        // PC(中大屏)
        const video = this.$refs.video;
        if (!this.isIos) {
          // 非mac
          video.oncanplay = () => {
            this.loaded = true;
          };
        } else {
          try {
            // mac
            video.muted = true;
            video.autoplay = true;
            video.play();
            video.ontimeupdate = () => {
              if (!this.clickFlag && video.currentTime > 0.1) {
                video.pause();
                // video.currentTime = 0.1;
                this.$nextTick(() => {
                  this.loaded = true;
                });
              }
            };
          } catch (error) {
            console.log('clp video播放错误', error);
          }
        }
      // } else if (this.isIos) {
      } else if (this.isWeixin) {
        // 微信浏览器
        // 使用微信jssdk来实现自动播放
        const video = this.$refs.video;
        const _this = this;
        if (typeof WeixinJSBridge === 'undefined') {
          document.addEventListener(
            'WeixinJSBridgeReady',
            function () {
              try {
                video.play();
                video.ontimeupdate = () => {
                  if (!_this.clickFlag && video.currentTime > 0.1) {
                    video.pause();
                    // video.currentTime = 0.1;
                    _this.$nextTick(() => {
                      _this.loaded = true;
                    });
                  }
                };
              } catch (error) {
                console.log('clp video播放错误', error);
              }
            },
            false
          );
        } else {
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
            try {
              video.play();
              video.ontimeupdate = () => {
                if (!_this.clickFlag && video.currentTime > 0.1) {
                  video.pause();
                  // video.currentTime = 0.1;
                  _this.$nextTick(() => {
                    _this.loaded = true;
                  });
                }
              };
            } catch (error) {
              console.log('clp video播放错误', error);
            }
          });
        }
      } else {
        try {
          // 非微信浏览器
          const video = this.$refs.video;
          video.muted = true;
          video.autoplay = true;
          video.play();
          video.ontimeupdate = () => {
            if (!this.clickFlag && video.currentTime > 0.1) {
              video.pause();
              // video.currentTime = 0.1;
              this.$nextTick(() => {
                this.loaded = true;
              });
            }
          };
        } catch (error) {
          console.log('clp video播放错误', error);
        }
      }
    }
  },
  beforeDestroy() {
    const elevideo = document.getElementById('video');
    elevideo&& elevideo.removeEventListener('playing', this.playing);
    elevideo&&elevideo.removeEventListener('pause', this.pause);
    window.removeEventListener('scroll', this.isElementVisible);
  },
  destroyed () {
    window.removeEventListener('scroll', this.isElementVisible);
  },
  methods: {
    // 播放视频
    playVideo() {
      if (!this.clickFlag) {
        this.clickFlag = true;
        const video = this.$refs.video;
        video.play();
      }
    },
    playing() {
      // 播放中
      if (this.clickFlag) {
        this.trackData('play');
      }
    },
    pause() {
      // 暂停
      if (this.clickFlag) {
        this.trackData('pause');
      }
    },
    trackData(val) {
      // 第一个参数null为第几屏
      const data = {
        campaign_name: this.$route.query.contentId || '',
        sub_campaign_name: this.code,
        text: '',
        component_code: 'Video',
        position_code: Number(this.position.slice(1)),
        scroll_depth: getScrollDepth(this.$refs.videoWrapRef.offsetTop),
        Video_Url: !this.isMobile ? this.pcVideoUrl : this.mobVideoUrl,
        video_to_status: val,
        tag: this.options.labelInfoList.join(',')
      };
      trackClpVideoClick(data);
    },
    isElementVisible () {
      this.debounceScroll(this.checkPlay(), 1000);
    },
    checkFirstVideoPlay() {
      if (this.$refs.video) {
        const video = this.$refs.video;
        const rect = video.getBoundingClientRect();
        const vHeight = window.innerHeight || document.documentElement.clientHeight;
        const firstCanPlay = rect.bottom < vHeight && rect.top < vHeight && !this.uniquePlay;
        if (firstCanPlay) {
          video.autoplay = true;
          this.clickFlag = true;
          this.handleVideoPlay();
          this.$store.commit('clp/SET_UNIQUEPLAY', true);
        }
      }
    },
    checkPlay() {
      if (this.$refs.video) {
        const rect = this.$refs.video.getBoundingClientRect();
        if (rect.height/2 + rect.top > this.downVideoThreshold && rect.height/2 + rect.top < this.upVideoThreshold && !this.uniquePlay) {
          this.$refs.video.autoplay = true;
          this.clickFlag = true;
          this.handleVideoPlay();
          this.currentVideoPostion !== this.position ? this.$store.commit('clp/SET_UNIQUEPLAY', true) : '';
        } else {
          this.$refs.video.autoplay = false;
          this.clickFlag = false;
          this.handleVideoPause();
          this.$store.commit('clp/SET_UNIQUEPLAY', false);
        }
      }

    },
    debounceScroll(func, wait=1000) {
      let timer; let startTimeStamp = 0;
      let context, args;
      const run = (timerInterval)=>{
      // 周期内有新事件触发时，重置定时器开始时间撮，定时器执行时，判断开始时间撮，若开始时间撮被推后，重新设定延时定时器
        timer= setTimeout(()=>{
          const now = (new Date()).getTime();
          const interval= now - startTimeStamp;
          if(interval < timerInterval) {
            startTimeStamp=now;
            run(wait - interval);
          } else {
            func.apply(context,args);
            clearTimeout(timer);
            timer = null;
          }
        },timerInterval);
      };
      return function(){
        context=this;
        args=arguments;
        startTimeStamp=new Date().getTime();

        if(!timer){
          run(wait);
        }
      };
    },
    handleVideoPlay() {
      const video = this.$refs.video;
      if (this.isWeixin) {
        // 微信浏览器
        if (typeof WeixinJSBridge === 'undefined') {
          document.addEventListener(
            'WeixinJSBridgeReady',
            function () {
              try {
                video.play();
              } catch (error) {
                console.log('clp video播放错误', error);
              }
            },
            false
          );
        } else {
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
            try {
              video.play();
            } catch (error) {
              console.log('clp video播放错误', error);
            }
          });
        }
      } else {
        // 非微信浏览器
        try {
          video.play();
        } catch (error) {
          console.log('clp video播放错误', error);
        }
      }
    },
    handleVideoPause() {
       const video = this.$refs.video;
      if (this.isWeixin) {
        // 微信浏览器
        if (typeof WeixinJSBridge === 'undefined') {
          document.addEventListener(
            'WeixinJSBridgeReady',
            function () {
              video.pause();
            },
            false
          );
        } else {
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
            video.pause();
          });
        }
      } else {
        // 非微信浏览器
        video.pause();
      }
    }
  }
};
