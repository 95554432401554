interface TriggerInitializeConfig {
  reference: HTMLElement, // 元素引用
  popper: HTMLElement,
  show: () => void,
  hide: () => void,
  beforeShow: () => void, // 元素显示之前触发
  hideWhenScroll: Boolean, // 当滚动元素时，自动隐藏tooltips
  hideWhenScrollSelector: String  // dom选择器中参数
}
// 触发器组件初始化
interface TriggerInitialize {(config: TriggerInitializeConfig): void}

let activeConfig: null | TriggerInitializeConfig;
// 创建一个触发器
function createTrigger (newTrigger: () => {
  initialize: TriggerInitialize,
  destroy: () => void,
  name: string,
}) {
  return () => newTrigger();
}

export const delay = (timer = 0) => new Promise(resolve => setTimeout(resolve, timer));

export const createTooltipTrigger = {
  hover: createTrigger(() => { // 鼠标hover新建触发器

    let config: TriggerInitializeConfig;
    let closeTimer: number;


    const destroy = () => {
      const {
        reference,
        popper
      } = config;
      if (!reference) {return;}
      reference.removeEventListener('mouseenter', onEnter);
      reference.removeEventListener('mouseleave', onLeave);
      popper.removeEventListener('mouseenter', onEnter);
      popper.removeEventListener('mouseleave', onLeave);
    };

    // 组件显示
    const onEnter = () => {
      if (closeTimer) {
        clearTimeout(closeTimer);
      }
      if (!!activeConfig && activeConfig.reference !== config.reference) {
        activeConfig.hide();
      }
      config.show();
      activeConfig = config;
    };
    const onLeave = () => {
      if (closeTimer) {
        clearTimeout(closeTimer);
      }
      closeTimer = setTimeout(() => {
        config.hide();
      }, 50) as any as number;
    };

    const initialize: TriggerInitialize = (initialConfig) => {
      config = initialConfig;
      const {
        reference,
        popper
      } = initialConfig;
      // 组件初始化，绑定mouse相关监听器
      if (!reference) {return;}
      !!reference && reference.addEventListener('mouseenter', onEnter);
      !!reference && reference.addEventListener('mouseleave', onLeave);
      !!popper && popper.addEventListener('mouseenter', onEnter);
      !!popper && popper.addEventListener('mouseleave', onLeave);
    };

    return {
      name: 'hover',
      initialize,
      destroy
    };
  }),
  click: createTrigger(() => { // 移动端点击新建触发器
    let config: TriggerInitializeConfig;

    // 组件销毁移除监听器
    const destroy = () => {
      const { reference } = config;
      if (!reference) {return;}
      reference.removeEventListener('click', onClickReference);
      document.body.removeEventListener('click', onClickWindow);
      document.body.removeEventListener('click', onScrollWindow);
      document.body.removeEventListener('touchstart', onClickWindow);
    };

    const onClickReference = () => {
      config.show();
    };
    const onClickWindow = (e: MouseEvent|TouchEvent) => {
      const {
        reference,
        popper,
        hide
      } = config;
      if (reference?.contains(e.target as HTMLElement) || popper?.contains(e.target as HTMLElement)) {
        
      } else {
        hide();
      }
    };
    const onScrollWindow = () => {
      const {
        hideWhenScroll,
        hide,
      } = config;
      if (!hideWhenScroll) {
        
      } else {
        hide();
      }
    };

    const initialize: TriggerInitialize = (initialConfig) => {
      config = initialConfig;
      const { reference } = initialConfig;
      if (!reference) {return;}
      const {
        hideWhenScroll,
        hideWhenScrollSelector
      } = config;
      reference.addEventListener('click', onClickReference);
      document.body.addEventListener('click', onClickWindow);
      document.body.addEventListener('touchstart', onClickWindow);
      if (hideWhenScrollSelector && hideWhenScrollSelector) {
        // @ts-ignore
        const el = document.querySelector(hideWhenScrollSelector);
        el && el.addEventListener('scroll', onScrollWindow);
      }
    };

    return {
      name: 'click',
      initialize,
      destroy
    };
  })
};
