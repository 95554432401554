/*
 * @Description:
 * @Author: ZhangHui
 * @Date: 2021-09-10 17:30:14
 */
import Vue from 'vue';
import { defer } from '@adidas-com-front/ui-library/src/utilities/help/defer';
import LibLogin from './loginPopover.vue';
import { delay } from '~/utilities/helper/delay';
import {isClient} from '~/utilities/ssr.ts';
/**
 * 登录服务单例优化，要求LibLogin的登录逻辑不能有大的变化，要求登录取消要派发close事件，登录成功要派发login以及close事件；
 * @author  韦胜健
 * @date    2021/9/22 14:23
 */
const state = {
  dfd:null,
  instance:null
};
// 新用户登录成功积分动画promise 表示动画是否接受
let pointsAnimation=null;
const init = (context) => {
  if(!state.instance){
    pointsAnimation=null;
    const loginEle = document.createElement('div');
    document.body.appendChild(loginEle);
    state.instance = new Vue({
      parent: context,
      data: {
        show: false,
        option: {}
      },
      methods: {
        close() {
          this.show = false;
        },
        service(option) {
          this.option = option;
          this.show = option.show;
        }
      },
      render() {
        let { on } = this.option;
        const {login, close } = on || {};
        // this.option.joinAdiclubProp=true;
        const isJointMember= !!this.option?.isJointMember;
        const joinType= (this.option?.joinType)?this.option?.joinType:'';
        on = {
          ...on,
          login: () => {
            !!login && login();
            state.dfd.resolve();
          },
          close:async (isEvent=true)=>{
            if(isEvent){
              !!close && close();
            }
            this.close();
            /**
             * 关闭登录弹框之后，为了确保 login 处理函数在close处理函数之前处理，这里等待0ms，确保清除state.dfd在login处理之后执行；
             * @author  韦胜健
             * @date    2021/9/22 11:55
             */
            await delay(0);
            state.dfd = null;
          },
          pointsClose:(promise)=>{
            pointsAnimation=promise;
          }
        };
        return <LibLogin
          show={this.show}
          allEvents={on}
          firstStepProp={this.option.firstStepProp}
          isJointMember={isJointMember}
          joinType={joinType}
          joinAdiclubProp={this.option.joinAdiclubProp}
          loginClp={this.option.loginClp}
          titleImport={this.option.titleImport}
          descImport={this.option.descImport}
        />;
      }
    });
    state.instance.$mount(loginEle);
  }
  return state.instance;
};

function createLoginService(context) {
  return (option) => {
    if(!state.dfd){
      state.dfd = defer();
      init(context).service(option);
    }
    return state.dfd.promise;
  };
}

export function installLogin(Vue) {
  isClient&&console.log('plugins mixin installLogin');
  isClient&&Vue.mixin({
    computed: {
      $login() {
        return createLoginService(this);
      },
      $loginPointsAnimation(){
        // 新用户登录成功如果有积分动画 返回promise 表示动画是否接受
        // 用户登录没有积分动画动画 返回 null;
        return pointsAnimation;
      }
    }
  });
}
