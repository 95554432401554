
  import { LibButton } from '@adidas-com-front/ui-library';
  export default {
    components: { LibButton },
    props: {
      error: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
      };
    }
  };
