/*
 * @Description:
 * @Author: ZhangHui
 * @Date: 2021-11-30 14:11:58
 */
let tracker = {} as any;
if (process.client) {
  tracker = require('~/utilities/vendors/sensors').default;
}
// 首页优惠券弹窗显示神策
export function couponPopupView(
  couponCodeList: String,
  couponNumber: Number
): void {
  try {
    tracker.track('Coupon_PopUp_View', {
      coupon_code_list: couponCodeList,
      coupon_number: couponNumber
    });
  } catch (error) {
    console.warn('sensor:[Coupon_PopUp_View]', error);
  }
}

// 首页优惠券弹窗点击事件神策
export function couponPopupClick(
  couponCodeList: String,
  couponNumber: Number,
  buttonName: String
): void {
  try {
    tracker.track('Coupon_PopUp_Click', {
      coupon_code_list: couponCodeList,
      coupon_number: couponNumber,
      button_name: buttonName
    });
  } catch (error) {
    console.warn('sensor:[Coupon_PopUp_Click]', error);
  }
}
// 首页优惠券领取后toast事件神策
export function couponToastView(
  pageTitle: String,
  toastName: Number
): void {
  try {
    tracker.track('Coupon_toast_view', {
      page_title: pageTitle,
      toast_name: toastName
    });
  } catch (error) {
    console.warn('sensor:[Coupon_toast_view]', error);
  }
}
// 首页优惠券PC端收拾放在右下角悬浮气泡上时触发
export function couponBubbleHover(
  couponCodeList: String,
  couponNumber: Number
): void {
  try {
    tracker.track('Coupon_Bubble_Hover', {
      coupon_code_list: couponCodeList,
      coupon_number: couponNumber
    });
  } catch (error) {
    console.warn('sensor:[Coupon_Bubble_Hover]', error);
  }
}
export function bannerView(
  bannerId: String,
  bannerName: String,
  bannerPosition: String,
  bannerToUrl: String
): void {
  try {
    tracker.track('Banner_View', {
      banner_id: bannerId,
      banner_name: bannerName,
      banner_position: bannerPosition,
      banner_to_url: bannerToUrl,
      page_name: "HOME",
      page_type: "HOME"
    });
  } catch (error) {
    console.warn('sensor:[get_in_homepage]', error);
  }
}

interface bannerClickParam {
  articleNumber: String,
  bannerClickType: String,
  bannerId: String,
  bannerName: String,
  bannerPosition: String,
  bannerToPageType: String,
  bannerToUrl: String,
  brand: String,
  category: String,
  gender: String,
  modelName: String,
  productName: String,
  roundBannerPosition: String
}

export function bannerClick(param: bannerClickParam): void {
  try {
    tracker.track('banner_click', {
      article_number: param.articleNumber,
      banner_click_type: param.bannerClickType,
      banner_id: param.bannerId,
      banner_name: param.bannerName,
      banner_position: param.bannerPosition,
      banner_to_page_type: param.bannerToPageType,
      banner_to_url: param.bannerToUrl,
      brand: param.brand,
      category: param.category,
      gender: param.gender,
      model_name: param.modelName,
      product_name: param.productName,
      round_banner_position: param.roundBannerPosition,
      page_name: "HOME",
      page_type: "HOME"
    });
  } catch (error) {
    console.warn('sensor:[click_homepage_banner]', error);
  }
}

export function getLinkType(url: String): String {
  if (!url) return '';
  const pageType = url.split('?')[0].slice(1);
  if (['plp', 'pdp', 'clp', 'splp'].includes(pageType?.toLowerCase())) {
    return pageType.toUpperCase();
  } else {
    return 'H5';
  }
}

interface nBannerClickParam {
  type: string,
  component_code: String,
  subject: String,
  position: Number,
  name?: String,
  to_url?: String,
  image_url?: String,
  img_description?: String
}

// 首页 Nbanner 组件click事件集合
export function nBannerClick(param: nBannerClickParam): void {
  const eventMap: { [key: string]: string } = {
    ordinary: 'ordinary_Nbanner_Click',
    franchise: 'Key_Franchise_Click',
    brand: 'Brand_Click'
  };
  const eventName = eventMap[param.type];
  try {
    tracker.track(eventName, param);
  } catch (error) {
    console.warn('sensor:[homepage nBannerClick]', error);
  }
}

interface nBannerViewParam {
  type: string,
  component_code: String,
  subject: String,
  name_list?: [string]
}

// 首页 Nbanner 组件view事件集合
export function nBannerView(param: nBannerViewParam): void {
  const eventMap: { [key: string]: string } = {
    ordinary: 'ordinary_Nbanner_View',
    franchise: 'Key_Franchise_View',
    brand: 'Brand_View'
  };
  const eventName = eventMap[param.type];

  try {
    tracker.track(eventName, param);
  } catch (error) {
    console.warn('sensor:[homepage nBannerView]', error);
  }
}

interface feedClickParam {
  component_code: String,
  subject: String,
  series_name: String,
  click_type: String,
  to_plp_url?: String,
  article_number?: String,
  position: number,
  to_url?: String
}

// 首页 Feed 组件click事件集合
export function feedProductClick(param: feedClickParam): void {
  try {
    tracker.track('FeedProduct_Click', param);
  } catch (error) {
    console.warn('sensor:[homepage FeedProduct_Click]', error);
  }
}

interface feedViewParam {
  component_code: String,
  subject: String,
  series_name: String,
  article_number_list: String
}

export function feedProductView(param: feedViewParam): void {
  try {
    tracker.track('FeedProduct_View', param);
  } catch (error) {
    console.warn('sensor:[homepage FeedProduct_View]', error);
  }
}

interface serviceObject {
  page_title: String,
  order_status: String
}
export function serviceOnlineClick(param: serviceObject): void {
  try {
    tracker.track('Service_Online_Click', param);
  } catch (error) {
    console.warn('sensor:[Service_Online_Click]', error);
  }
}
export function serviceTelClick(param: serviceObject): void {
  try {
    tracker.track('Service_Tel_Click', param);
  } catch (error) {
    console.warn('sensor:[Service_Tel_Click]', error);
  }
}
