import { render, staticRenderFns } from "./shuffle-item.vue?vue&type=template&id=6faec232&scoped=true"
import script from "./shuffle-item.vue?vue&type=script&lang=js"
export * from "./shuffle-item.vue?vue&type=script&lang=js"
import style0 from "./shuffle-item.vue?vue&type=style&index=0&id=6faec232&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6faec232",
  null
  
)

export default component.exports