/*
 * @Author: your name
 * @Date: 2022-04-21 15:12:51
 * @LastEditTime: 2022-04-21 15:54:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /adidas-com-front/packages/nuxt/plugins/shareGuide.js
 */
import Vue from 'vue';
import ShareGuide from '@adidas-com-front/ui-library/src/components/organisms/LibShareGuide/LibShareGuide.service';

Vue.use(ShareGuide);
