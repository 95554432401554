/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2024-07-24 10:27:30
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2024-08-27 14:11:46
 * @FilePath: /new-com/adidas-com-front/packages/nuxt/components/clp/look-group/components/common/scroll.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export class Scroll {
  protected timout: any = null;
  public isVisible: boolean = false;
  // eslint-disable-next-line no-useless-constructor
  constructor(protected containerEle: HTMLVideoElement) {
    this.init();
  };

  init() {
    const layout = document.querySelector("#layout-wrapper");
    if (layout) {
      layout.addEventListener('scroll', (e: Event) => {
        if (this.timout) {
          clearTimeout(this.timout);
        }
        this.timout = setTimeout(() => {
          this.scroll();
        }, 500);
      });
    }

  }


  protected scroll(): void {
    this.checkAction();
  }

  checkAction(): void {
    this.isVisible = this.scrollVisible(this.containerEle);
    if (this.isVisible) {
      this.play();
    } else {
      this.pause();
    }
  }

  pause(): void {
    this.containerEle.pause();
  }

  play(): void {
    this.videoPlay();
  }

  videoPlay(): void {
    const u = navigator.userAgent.toLowerCase();
    const isWeixin = u.includes('micromessenger');
    if (isWeixin) {
      this.wechatVideoPlay();
    } else {
      try {
        // 活跃视频播放
        this.containerEle.play();
      } catch (error) {
      }
    }
  }

  wechatVideoPlay() {
    // 判断微信环境 单独处理
    // 使用微信jssdk来实现自动播放
    if (typeof WeixinJSBridge === 'undefined') {
      document.addEventListener(
        'WeixinJSBridgeReady',
        () => {
          try {
            this.containerEle.play();
          } catch (error) {
            console.log('video播放错误', error);
          }
        },
        false
      );
    } else {
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke('getNetworkType', {}, (e: Event) => {
        try {
          this.containerEle.play();
        } catch (error) {
          console.log('video播放错误', error);
        }
      });
    }
  }

  scrollVisible(ele: HTMLElement): boolean {
    const curTop = ele.getBoundingClientRect().top;
    const curBottom = curTop + ele.offsetHeight;
    const windowHeight = window.innerHeight;
    if ((curTop > 0 && curTop < windowHeight) || (curBottom < windowHeight && curBottom > 0)) {
      return true;
    } else {
      return false;
    }
  }
}
