import { getServerTime, isClient } from '~/utilities/helper/SystemHelper';
/**
 * desc 服务器时间
 *
 * @author milo
 * @date   2024/02/22
 */
const state = () => ({
  nowDateGap: null,
  getTimePromise: null
});

const mutations = {
  SET_NOW_DATE_GAP(state, params) {
    state.nowDateGap = params;
  },
  SET_GET_TIME_PROMISE(state, params) {
    state.getTimePromise=params;
  }
};
async function getTime( ctx,commit,state) {
  let requestTime = 0;
  let nowDateGap = state.nowDateGap;
  if (nowDateGap===null) {
    nowDateGap=0;
    for (let i = 0;i<3;i++) {
      try{
        const startTime = new Date().getTime();
        const resp = await getServerTime(ctx.$axios);
        // const resp=(new Date("2023/11/5 23:59:00")).getTime();
        const endTime = new Date().getTime();
        // 这里是服务器时间接口在后端生产时到返回给前端所用的时间
        requestTime = (endTime - startTime) / 2;
        nowDateGap = (resp + requestTime) - endTime;
        // 如果接口花费时间大于1秒，证明请求时间异常，此时重新请求
        if (requestTime < 2000) break;
        
      }catch(error){
        console.log("请求服务器时间接口失败",error);
      };
    }
  }
  const nowTime = new Date().getTime();
  console.log("当前时间与服务器时间差",nowDateGap);
  const now = new Date(nowTime + nowDateGap);
  nowDateGap&&commit('SET_NOW_DATE_GAP', nowDateGap);
  return now;
}
const actions = {
  async getNowTime({ commit,state }, { ctx }) {
    if(!isClient){
      return new Date();
    }
    if(!state.getTimePromise){
      const getPromise= getTime(ctx,commit,state);
      commit("SET_GET_TIME_PROMISE",getPromise);
    }
    await state.getTimePromise;
    const nowlocalTime = new Date().getTime();
    const nowDateGap=state.nowDateGap;
    const now = new Date(nowlocalTime + nowDateGap);
    return now;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
