
export default {
  name: 'SearchInput',
  props: {
    // 传入的placeholder
    placeholder: {
      type: [String],
      default: ''
    },
    // 绑定的inputvalue值
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      // 展示placeholder
      showPlaceHolder: true, 
      // 展示清除按钮
      showClear: false, 
      // 聚焦
      focus: false 
    };
  },
  methods: {
    noop() {},
    // 点击清除按钮
    searchBack() {
      this.$emit('input', null);
      this.showClear = false;
    },
    onInputChange(e) {
      if (e.target.value) {
        this.showClear = true;
        this.showPlaceHolder = false;
      } else {
        this.showClear = false;
      }
      this.$emit('input', e.target.value);
    },
    onFocus() {
      this.focus = true;
      this.$emit('focus');
      // this.$refs.inputNode.focus(); // 手动唤起
    },
    handleBlur() {
      this.showPlaceHolder = true;
      this.showClear = false;
      this.focus = false;
    },
    stopBlur(e) {
      if (this.focus) {
        e.preventDefault();
      }
    },
    // 回车搜索事件
    enterSearch(e) {
      this.$emit('input', e.target.value);
      if (e.target.value) {
        this.$emit('showFlag', true, e.target.value);
        this.historyWords = this.$store.state.searchHistory.words;
        this.$store.commit('searchHistory/setWords', e.target.value);
      } else {
        this.$emit('showFlag', false);
      }
      this.$refs.inputNode.blur();
    },
    focusInput(e) {
      this.$refs.inputNode.focus();
      this.focus = true;
      this.$emit('in', {});
      if (this.value) {
        this.showClear = true;
      }
    },
    mvBlur() {
      this.$refs.inputNode.blur();
    }
  }
};
