
  import LibPopover from '@adidas-com-front/ui-library/src/components/molecules/LibPopover/LibPopover.vue';
  import CouponItem from './couponItem.vue';
  import { mapState } from 'vuex';
  import {BasicUtils} from '~/utilities/basic/utils';

  export default {
    components: {
      LibPopover,
      CouponItem
    },
    props: {
      couponData: {
        type: Array,
        default: () => {
          return [];
        }
      },
      show: {
        type: Boolean,
        default: false
      },
      source: {
        type: String
      }
    },
    computed: {
      ...mapState('myAccount', [
        'isNonmemberLogin',
        'isLogin'
      ]),
    },
    data() {
      return {
        isMemberLogin: false,
        dir: 'bottom',
        title: `优惠促销`,
        titleType: 'left',
        titleFont: 'heading-3 text-black',
        icon: 'icon-cross_32',
        iconType: 'right',
        margin: 0,
        zIndex: 999,
        size: 0,
        backToClose: false,
        popover_bg_color: '#ffffff',
        isMobile: false,
        desc: '登录/注册ADICLUB会员即可领取以下优惠',
        subTitleFont: 'label-6 mt-4'
        // popover_bg_color: ''
      };
    },
    watch: {
      show(val) {
        if (val) {
          this.$nextTick(() => {
            const utils = new BasicUtils(this);
            this.isMemberLogin = utils.isMemberLogin();
            if (this.isMobile) {
              if (this.couponData.length < 6) {
                this.size = this.couponData.length * (76 + 16) + 64;
              } else {
                this.size = 5 * (76 + 16) + 64;
              }
            } else {
              this.size = undefined;
            }

          });
        }
      }
    },
    mounted () {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.isMobile = window.innerWidth < 720;
        this.dir = this.isMobile ? 'bottom' : 'right';
      },
      updateShow() {
        this.$emit('closePanel');
      }
    }
  };

