
import { LibMask } from '@adidas-com-front/ui-library';

export default {
  name: 'LibShareGuide',
  components: {
    LibMask,
  },
  props: {
    // 组件显示隐藏
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      env: 'browser',
      visible: true,
    };
  },
  mounted() {
    const ua = window.navigator.userAgent;
    if (/miniProgram/i.test(ua)) {
      this.env = 'miniProgram';
    } else if (/MicroMessenger/i.test(ua)) {
      this.env = 'weChat';
    } else if (/adidasapp/i.test(ua)) {
      this.env = 'app';
    }
  },
  methods: {
    handleClick() {
      this.show = false;
    },
  },
};
