
import deepcopy from 'deepcopy';
import { LibButton } from '@adidas-com-front/ui-library';
import { handleClickLink, decorateSurveyAllLink } from '../util';
import questionMixin from './question.mixins';
import { trackClpSurveyPDPClick } from './track/index';
import { colorHexToRgb } from '~/utilities/helper/colorHelper';
import MemberGuide from '~/components/clp/survey/memberPopover.vue';

export default {
  components: {
    LibButton,
    MemberGuide
  },
  mixins: [questionMixin],
  data() {
    return {
      optionList: []
    };
  },
  computed: {
    noOptionAndTitle() {
      const filterList = this.optionList.filter((item) => {return (item.optionImageUrl || item.productImage) &&
                !(item.optionImageUrl && item.productImage);});
      return !filterList.length && !this.questionDescDetail.title && !this.questionDescDetail.subtitle;
    },
    optionsStyle() {
      const len = this.optionList.length;
      if (this.windowWidth > 719 && this.windowWidth < 766) {
        return {
          width: len < 3 ? len * 180 + (len - 1) * 2 + 'px' : '544px'
        };
      } else if (this.windowWidth > 765 && this.windowWidth < 950) {
        return {
          width: len < 4 ? len * 180 + (len - 1) * 2 + 'px' : '726px'
        };
      } else if (this.windowWidth > 949 && this.windowWidth < 1130) {
        return {
          width: len < 5 ? len * 180 + (len - 1) * 2 + 'px' : '908px'
        };
      } else if (this.windowWidth > 1129) {
        return {
          width: len < 6 ? len * 180 + (len - 1) * 2 + 'px' : '1090px'
        };
      } else {
        return {};
      }
    },
    showDynamicReply() {
      return (this.submited || this.wholeSurveySubmited) && this.dynamicReplyFlag && this.computeCompleted;
    }
    // showReplyBtn(){
    //   if(this.dynamicReplyFlag){
    //     return this.showDynamicReply;
    //   }else{
    //     return (this.repeatFillingFlag && !this.isMultiple && this.selectedItem) ||
    //       (this.isMultiple && this.submited && this.repeatFillingFlag);
    //   }
    // }
  },
  watch: {
    // surveyResult(newValue, oldValue) {
    //   console.log('surveyResult>>>>>>>>');
    //   if (newValue) {
    //     this.$nextTick(async() => {
    //       const height = this.$refs.surveyTitle?.offsetHeight;
    //       const buffer = this.windowWidth < 720 ? 8 : 40;
    //       const top = await this.loadQuestionImg(height, buffer, 20);
    //       this.wholeReplyImgHeight = await this.loadReplyImg();
    //       const optionsHeight = this.$refs.options?.clientHeight || 0;
    //       this.wholeReplyStyle = { top:  top + 'px', height: optionsHeight + 'px' };
    //       if (this.showWholeReply) {
    //         this.animateWholeReply();
    //       }
    //       this.getLastResult();
    //     });
    //   }
    // }
    surveyResult: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.$nextTick(async() => {
            const height = this.$refs.surveyTitle?.offsetHeight;
            const buffer = this.windowWidth < 720 ? 8 : 40;
            const top = await this.loadQuestionImg(height, buffer, 20);
            this.wholeReplyImgHeight = await this.loadReplyImg();
            const optionsHeight = this.$refs.options?.clientHeight || 0;
            this.wholeReplyStyle = { top:  top + 'px', height: optionsHeight + 'px' };
            if (this.showWholeReply) {
              this.animateWholeReply();
            }
            this.getLastResult();
          });
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (!this.isAdiclub) {
      this.getProductList();
    } else {
      this.getOptionList();
    }
  },
  methods: {
    frontTextStyle(item) {
      return {
        color: item.optionTextColor
      };
    },
    getOptionList() {
      const optionList = deepcopy(
        this.question.questionDescriptionDetail.optionList
      );
      optionList.sort((a, b) => {
        return a.optionPosition - b.optionPosition;
      });
      optionList.forEach((option, idx) => {
        option.productImage = '';
        option.replyProductImage = '';
      });
      const filterOptionList = optionList.filter((item) => {
        return (
          (item.optionImageUrl || item.productImage) &&
          !(item.optionImageUrl && item.productImage)
        );
      });
      this.optionList = deepcopy(filterOptionList);

      this.$nextTick(async() => {
        const height = this.$refs.surveyTitle?.offsetHeight;
        const buffer = this.windowWidth < 720 ? 8 : 40;
        const top = await this.loadQuestionImg(height, buffer, 20);
        this.wholeReplyImgHeight = await this.loadReplyImg();
        const optionsHeight = this.$refs.options?.clientHeight || 0;
        this.wholeReplyStyle = { top:  top + 'px', height: optionsHeight + 'px' };
        if (this.showWholeReply) {
          this.animateWholeReply();
        }
        this.getLastResult();
      });
    },
    async getProductList() {
      const productPromises = [];
      const replyProductPromises = [];
      const optionList = deepcopy(
        this.question.questionDescriptionDetail.optionList
      );
      optionList.sort((a, b) => {
        return a.optionPosition - b.optionPosition;
      });
      optionList.forEach((item) => {
        const product = {
          optionCode: item.optionCode,
          articleId: item.optionArticleList?.length
            ? item.optionArticleList[0]
            : '',
          categoryCode: item.optionCategoryCode || '',
          replyArticleId: item.replyArticleList?.length
            ? item.replyArticleList[0]
            : '',
          replyCategoryCode: item.replyCategoryCode || ''
        };
        if (product.articleId) {
          productPromises.push(this.getProductDetail(product.articleId));
        } else if (product.categoryCode) {
          productPromises.push(this.getPlp(product.categoryCode));
        } else {
          productPromises.push('');
        }

        if (product.replyArticleId) {
          replyProductPromises.push(
            this.getProductDetail(product.replyArticleId)
          );
        } else if (product.replyCategoryCode) {
          replyProductPromises.push(this.getPlp(product.replyCategoryCode));
        } else {
          replyProductPromises.push('');
        }
      });
      const allProductData = await Promise.all(productPromises);
      const allReplyProductData = await Promise.all(replyProductPromises);
      optionList.forEach((option, idx) => {
        option.productImage = allProductData[idx];
        option.replyProductImage = allReplyProductData[idx];
      });
      const filterOptionList = optionList.filter((item) => {
        return (
          (item.optionImageUrl || item.productImage) &&
          !(item.optionImageUrl && item.productImage)
        );
      });
      this.optionList = deepcopy(filterOptionList);

      this.$nextTick(async() => {
        const height = this.$refs.surveyTitle?.offsetHeight;
        const buffer = this.windowWidth < 720 ? 8 : 40;
        const top = await this.loadQuestionImg(height, buffer, 20);
        this.wholeReplyImgHeight = await this.loadReplyImg();
        const optionsHeight = this.$refs.options?.clientHeight || 0;
        this.wholeReplyStyle = { top:  top + 'px', height: optionsHeight + 'px' };
        if (this.showWholeReply) {
          this.animateWholeReply();
        }
        this.getLastResult();
      });
    },
    getProductDetail(articleId) {
      const params = {
        articleId: articleId
      };
      return new Promise((resolve, reject) => {
        this.$axios
          .get(
            `${this.$config.ECP_API_PRIVATE_HOST}/o2pcm/v1/pub/platform-products/detail`,
            {
              params: { ...params }
            }
          )
          .then((res) => {
            resolve(res.imageUrlList.length ? res.imageUrlList[0] : '');
          })
          .catch((e) => {
            resolve('');
          });
      });
    },
    getPlp(categoryCode) {
      const params = {
        page: 1,
        pageSize: 10,
        categoryCode: categoryCode,
        abTest: 'A'
      };
      return new Promise((resolve, reject) => {
        this.$axios
          .get(
            `${this.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/search`,
            {
              params: { ...params }
            }
          )
          .then((res) => {
            resolve(res.content.length ? res.content[0].imageUrl : '');
          })
          .catch((e) => {
            resolve('');
          });
      });
    },
    handleToPDP(e, item) {
      // sensors
      trackClpSurveyPDPClick({
        campaignName: this.$route.query.contentId,
        componentCode: 'Survey',
        positionCode: this.question.questionPosition,
        questionType: this.question.questionType,
        surveyId: this.surveyCode,
        questionId: this.question.questionCode,
        optionDetail: item.optionDescribe,
        articleId: item.optionArticleList?.length
          ? item.optionArticleList[0]
          : '',
        categoryCode: item.optionCategoryCode || '',
        is_memer_only: !!this.surveyDetail.replyMemberFlag
      });
      e = e || window.event;
      if (e.stopPropagation) {
        // W3C阻止冒泡方法
        e.stopPropagation();
      } else {
        // IE阻止冒泡方法
        e.cancelBubble = true;
      }
      item.allLink = decorateSurveyAllLink(item.link);
      handleClickLink.call(this, item);
    },
    handleCheckLogin(e, item) {
      if(this.surveyDetail.replyMemberFlag) {
        this.utils.isMemberLogin() ? this.handleToPDP(e, item) : this.handleMemberLogin();
      } else {
        this.handleToPDP(e, item);
      }
    },
    dynamicStripStyle(item) {
      const color =
        this.questionDescDetail.proportionColor && (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item))
          ? colorHexToRgb(this.questionDescDetail.proportionColor, 1)
          : '';
      return {
        'border-color': color,
        'background-color': color,
        width: this.showDynamicReply ? item.percentStr : '0%'
      };
    },
    dynamicBottomStripStyle(item) {
      const color =
        this.questionDescDetail.proportionColor && (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item))
          ? colorHexToRgb(this.questionDescDetail.proportionColor, 1)
          : '';
      return {
        'border-color': color,
        'background-color': color,
        width: this.showDynamicReply ? item.percentStr : '0%'
      };
    },
    dynamicTextStyle(item) {
      return {
        color:
          this.questionDescDetail.proportionColor &&
          (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item))
            ? colorHexToRgb(this.questionDescDetail.proportionColor, 1)
            : ''
      };
    },
    dynamicTextClass(item) {
      const className = [];
      if (item.selected && this.showDynamicReply) {
        className.push('label-3');
      } else if (this.showDynamicReply) {
        className.push('label-4');
      }
      return className;
    },
    dynamicBackground(item) {
      const color =
        this.questionDescDetail.proportionColor && (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item))
          ? colorHexToRgb(this.questionDescDetail.proportionColor, 0.5)
          : '';
      return { 'background-color': item.optionText ? color : '' };
    }
  }
};
