
export default {
  name: 'LibImage',
  props: {
    imageUrl: String,
    imageAlt: String,
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { show: false };
  },
  // 由于onload事件在keep-alive下无非稳定触发，通过监听item数据方式实现
  watch: {
    item: {
      handler(cur, prev) {
        if (cur) {
          this.$nextTick(() => {
            this.showAnimation();
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    showAnimation() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 100);
    },
    onLoad() {
      this.$emit('load');
    },
    onError(e) {
      if (!this.imageUrl || this.imageUrl?.startsWith('undefined')) {
        // 直接写hidden某些地方权重不够, 这里自定义一个类
        e.target.classList.add('hidden-img');
      }
    },
  },
};
