
import debounce from 'lodash.debounce';
import SearchInput from './search-input/index.vue';
import { trackSearchAutocomplete } from '~/pages/search/track';
export default {
  name: 'MobileSearch',
  components: {
    SearchInput
  },
  props: {
    // 传入的标识值,这样方便操作
    click: {
      type: Boolean
    }
  },
  data() {
    return {
       // 点击分组标签
      isactive: 0,
      chatMore: false,
      // 是否超出两行
      hisHasMoreBtn: false,
      // 是否超出三行
      hotHasMoreBtn: false,
      // 历史搜索数组
      historyList: [],
      // 历史搜索备用数组
      historyListFb: [],
      // 热门搜索数组
      hotList: [],
      // 热门搜索备用数组
      hotListFb: [],
      // 联想词接口返回数组
      searchList: [],
      // 联想词接口返回备用数组
      searchListFb: [],
      // 绑定输入的input值
      result: null,
      // 显示历史&&显示热门
      showGroups: false,
       // 显示历史搜索
      showHistory: false,
       // 显示热门搜索
      showHot: false,
      // 显示联想词
      showRelation: false,
       // 联想词
      placeholder: null,
      // 保存之前的联想词
      placeholderFb: null
    };
  },
  watch: {
    // 监听输入值变化
    result(val) {
      if (val === '' || val === null) {
        this.showRelation = false;
      }
      if (val !== null && val !== '') {
        this.showGroups = false;
        this.showHistory = false;
        this.showHot = false;
        this.getRelation(val);
      } else {
        this.showGroups = true;
        if (this.historyList && this.historyList.length) {
          this.showHistory = true;
          this.init('group');
        }
        if (this.hotList && this.hotList.length) {
          this.showHot = true;
          this.init('hotGroup');
        }
      }
    },
    // 传入的标识值变化去监听操作,同样需要初始化一下组件的状态
    click(val) {
      this.result = null;
      this.showRelation = false;
      if (this.$route.fullPath === this.$store.state.searchHistory.nowRoute) {
        this.result = this.$store.state.searchHistory.inputValue;
      } else {
        this.result = null;
      }
      this.getHistoryRecords();
      // 初始化历史搜索
      this.init('group');
       // 初始化热门搜索
      this.init('hotGroup');
      this.placeholder = this.$store.state.searchHistory.valueFlag;
      if (this.$store.state.searchHistory.inputValue) {
        // todo store并没有设计这个字段plpSearchBar，所以这里永远会被执行
        if (this.$store.state.searchHistory.plpSearchBar !== 'plpSearchBar') {
          this.result = this.$store.state.searchHistory.inputValue;
          this.showGroups = false;
          this.showHot = false;
        }
      }
      // 唤起预站位的键盘
      if (val) {
        this.$nextTick(() => {
          this.$refs.searchInput?.focusInput();
        });
      }
    }
  },
  async mounted() {
    // 获取值 挂载的时候对组件初始化
    window.addEventListener('scroll', this.onScroll, true);
    this.placeholder = this.$store.state.searchHistory.valueFlag;
    if (this.$store.state.searchHistory.inputValue) {
      this.result = this.$store.state.searchHistory.inputValue;
      this.showGroups = false;
      this.showHot = false;
    }
    this.getHistoryRecords();
    // 请求热门搜索接口数据
    const hotWordList = await this.$axios.get(
      this.$config.ECP_API_HOST + '/o2srh/v1/pub/hot-word'
    );
    if (hotWordList && hotWordList.hotWordTypeList.length) {
      this.hotList = hotWordList.hotWordTypeList;
       // 备用
      this.hotListFb = hotWordList.hotWordTypeList;
      if (this.$store.state.searchHistory.inputValue) {
        this.showGroups = false;
        this.showHot = false;
      } else {
        this.showGroups = true;
        this.showHot = true;
      }
    }
    // 初始化历史搜索
    this.init('group');
    // 初始化热门搜索
    this.init('hotGroup');
    this.$nextTick(() => {
      this.$refs.searchInput?.focusInput();
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll(e) {
      // 滚动距离顶部高度
      const top = e.target.scrollTop;
      if (top !== 0) {
        if (this.$refs.searchInput && this.$refs.searchInput.focus) {
          // 滑动的时候收起键盘
          this.$refs.searchInput.mvBlur();
        }
      }
    },
    getHistoryRecords() {
      // 从cookies读取搜索历史记录
      this.$store.commit('searchHistory/initializeWord');
      const historyWords = this.$store.state.searchHistory.words;
      if (historyWords && historyWords.length) {
        this.historyList = historyWords;
        // 备用
        this.historyListFb = historyWords;
        if (this.result) {
          this.showGroups = false;
          this.showHistory = false;
        } else {
          this.showGroups = true;
          this.showHistory = true;
        }
      }
    },
    init(val) {
      if (val === 'group') {
        let idx = 0;
        let idlx = 0;
        let num = 0;
        if (!this.showHistory) {
          return;
        }
        this.$nextTick(() => {
          if (!document.getElementById(val)) {
            return;
          }
          // 获取父容器所有子节点
          const ulChid = document.getElementById(val).childNodes;
          ulChid.forEach((i, index) => {
            if (i.offsetLeft === 20) {
              num++;
              if (num === 3) {
                idx = index - 1;
              }
              if (num === 5) {
                idlx = index - 1;
              }
            }
          });
          // 超过2行截断数据
          if (idx > 0) {
            this.historyList = this.historyList.slice(0, idx);
            this.hisHasMoreBtn = true;
          }
          // 不超过4行数据
          if (idlx > 0) {
            this.historyListFb = this.historyListFb.slice(0, idlx);
            this.hisHasMoreBtn = true;
          }
        });
      } else if (val === 'hotGroup') {
        let idx = 0;
        let num = 0;
        if (!this.showHot) {
          return;
        }
        this.$nextTick(() => {
          if (!document.getElementById(val)) {
            return;
          }
           // 获取父容器所有子节点
          const ulChid = document.getElementById(val).childNodes;
          ulChid.forEach((i, index) => {
            if (i.offsetLeft === 20) {
              num++;
              if (num === 4) {
                idx = index - 1;
              }
            }
          });
          // 超过3行截断数据
          if (idx > 0) {
            this.hotList = this.hotList.slice(0, idx);
            this.hotHasMoreBtn = true;
          }
        });
      }
    },
    componentBack() {
      // 组件的回退
      this.result = null;
      this.$router.back();
    },
    // 组件点击的展示更多
    clickShowMore(val, index) {
      if (val === 'history') {
        this.hisHasMoreBtn = false;
        this.historyList = this.historyListFb;
      } else if (val === 'hot') {
        this.hotHasMoreBtn = false;
        this.hotList = this.hotListFb;
      }
      this.stopPropagation();
    },
    // 阻止冒泡事件
    stopPropagation(e) {
      e = e || window.event;
      if (e.stopPropagation) {
        // W3C阻止冒泡方法
        e.stopPropagation();
      } else {
        // IE阻止冒泡方法
        e.cancelBubble = true;
      }
    },
    // 点击时的跳转动作/类型
    getL(index, val, type) {
      // 第二个参数为搜索类型，KEYWORDS RECOMMENDATION HISTORY HOT（热门搜索）
      this.$emit('jump', val, type, 'jump', this.result, this.searchList);
      this.$store.commit('searchHistory/setTypeValue', 'jump');
    },
    // 清除记录
    clear() {
      // this.init() // 清除之后需要把数据初始化 unF
      this.historyList = [];
      this.historyListFb = [];
      this.showHistory = false;
      this.$store.commit('searchHistory/clearHistoryWords');
    },
    // 筛选/记录组件内的信息
    showOrNot(flag, val) {
      if (flag) {
         // 第二个参数为搜索类型，KEYWORDS RECOMMENDATION HISTORY HOT（热门搜索）
        this.$emit('getValue', val, 'keywords', this.searchList);
        this.$store.commit('searchHistory/setTypeValue', null);
        // 设置InputValue
        this.$store.commit('searchHistory/setInputValue', val);
        this.$store.commit('searchHistory/setNowRoute', this.$route.fullPath);
      } else {
        // 第二个参数为搜索类型，KEYWORDS RECOMMENDATION HISTORY HOT（热门搜索）
        this.$emit('jump', this.placeholder, 'history', 'jump', this.searchList);
        // 添加跳转类型
        this.$store.commit('searchHistory/setTypeValue', 'jump');
        this.$store.commit('searchHistory/setInputValue', null);
      }
    },
    // 联想词
    getRelation: debounce(function (val) {
      this.$axios
        .get(
          this.$config.ECP_API_HOST + '/o2srh/v2/pub/keyword-recommendation',
          {
            params: {
              keyword: val
            }
          }
        )
        .then((res) => {
          if (res.recommendationWords && res.recommendationWords.length > 0) {
            if (res.recommendationWords.length > 40) {
              this.searchList = res.recommendationWords.slice(0, 40);
              this.showRelation = true;
            } else if (this.result !== '') {
              this.searchList = res.recommendationWords;
              this.showRelation = true;
              trackSearchAutocomplete(
                JSON.stringify(res.recommendationWords),
                this.result,
                this.searchList.map((item)=> { return item.word; })
              );
            }
          } else {
            this.searchList = [];
            this.showRelation = false;
          }
        });
    }, 500)
  }
};
