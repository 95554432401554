import { render, staticRenderFns } from "./ErrorPageNormalSingle.vue?vue&type=template&id=97b195ea&scoped=true"
import script from "./ErrorPageNormalSingle.vue?vue&type=script&lang=js"
export * from "./ErrorPageNormalSingle.vue?vue&type=script&lang=js"
import style0 from "./ErrorPageNormalSingle.vue?vue&type=style&index=0&id=97b195ea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97b195ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBarRest: require('/usr/src/app/packages/nuxt/components/search/search-bar/SearchBarRest.vue').default})
