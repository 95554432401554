import { render, staticRenderFns } from "./list.vue?vue&type=template&id=4e0832cd&scoped=true"
import script from "./list.vue?vue&type=script&lang=js"
export * from "./list.vue?vue&type=script&lang=js"
import style0 from "./list.vue?vue&type=style&index=0&id=4e0832cd&prod&lang=scss&scoped=true"
import style1 from "./list.vue?vue&type=style&index=1&id=4e0832cd&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e0832cd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/usr/src/app/packages/nuxt/components/footer/index.vue').default})
