import deepcopy from 'deepcopy';
import { mapState } from 'vuex';
import { trackClpSurveyResultView, trackClpSurveySubmit } from './track/index';
import {BasicUtils} from '~/utilities/basic/utils';
import { AppLogin } from "~/utilities/basic/appLogin";
import { TokenUtils } from '~/utilities';
import { colorHexToRgb } from '~/utilities/helper/colorHelper';
import {
  getDistinctIds
} from '~/utilities/vendors/sensors';


export default {
  name: 'questionMixins',
  beforeRouteLeave(to, from, next) {
  },
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    },
    repeatFillingFlag: {
      type: Boolean,
      default: false
    },
    surveyCode: {
      type: String,
      default: ""
    },
    surveyDetail: {
      type: Object,
      default: null
    },
    surveyResult: {
      type: Object,
      default: null
    },
    wholeSurveySubmited: {
      type: Boolean,
      default: false
    },
    refreshWholeSurvey: {
      type: Boolean,
      default: false
    },
    answerInfoWholeSubmited: {
      type: Array,
      default: () => {
        return [];
      }
    },
    wholeSurveySubmitData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    surveyResult(newValue, oldValue) {
      if (newValue && newValue.questionAnswerInfo && newValue.questionAnswerInfo.length) {
        this.rotateOption();
        this.computeDynamicData(newValue.questionAnswerInfo);
        this.computeCompleted = true;
      }
    },
    showWholeReply(newValue, oldValue) {
      if (newValue) {
        this.animateWholeReply();
      }
    },
    answerInfoWholeSubmited(newValue, oldValue) {
      if (newValue.length) {
        this.rotateOption();
        this.computeDynamicData(newValue);
        this.computeCompleted = true;
      }
    },
    refreshWholeSurvey(newValue, oldValue) {
      if (newValue) {
        this.question.questionType === 'TextInput' ? this.resetTextSurvey() : this.resetSurvey();
      }
    }
  },
  data() {
    return {
      submited: false,
      selectedItemList: [],
      selectedItem: null,
      isIos: false,
      isVivo: false,
      windowWidth: 0,
      showWholeReplyAnimation: false,
      wholeReplyAnimationEnd: true,
      showOptions: true,
      wholeReplyStyle: {top: 0, height: 0},
      isMac: false,
      showRepeatSelect: false,
      optionsHeight: null,
      wholeReplyImgHeight: null,
      isAndroidChrome: false,
      computeCompleted: false,
      questionImgHeight: 0,
      isPcQQBrowser: false,
      showMemberGuide: false,
      utils: null
    };
  },
  computed: {
    ...mapState('myAccount', [
      'isLogin',
      'isEmployeeLogin',
      'isNonmemberLogin'
    ]),
    submitAnswerFlag() {
      return this.surveyDetail.submitAnswerFlag;
    },
    showWholeReply() {
      const noneReplyItem = this.selectedItemList.find((item) => {return !item.replyText && !item.replyImageUrl && (!item.replyArticleList || !item.replyArticleList?.length) && !item.replyCategoryCode;});
      if (!this.submitAnswerFlag) {
        if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
          return !!(noneReplyItem && this.submited && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
        } else {
          return !!(this.selectedItem && !this.selectedItem.replyText && !this.selectedItem.replyImageUrl && (!this.selectedItem.replyArticleList || !this.selectedItem.replyArticleList?.length) && !this.selectedItem.replyCategoryCode && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
        }
      }
      else if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
        return !!(noneReplyItem && this.wholeSurveySubmited && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
      } else {
        return !!(this.wholeSurveySubmited && this.selectedItem && !this.selectedItem.replyText && !this.selectedItem.replyImageUrl && (!this.selectedItem.replyArticleList || !this.selectedItem.replyArticleList?.length) && !this.selectedItem.replyCategoryCode && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
      }
    },
    isMultiple() {
      return !!this.question.multipleChoiceFlag;
    },
    maxNumInMulti() {
      return this.question.maximumNum;
    },
    minNumInMulti() {
      return this.question.atLeastNum;
    },
    questionDescDetail() {
      return this.question.questionDescriptionDetail;
    },
    wrapperStyle() {
      if (this.windowWidth < 720) {
        return {
          backgroundImage: this.questionDescDetail.mobBackgroundImage ? `url(${this.questionDescDetail.mobBackgroundImage})` : '',
          backgroundPosition: 'top center',
          backgroundRepeatY: 'repeat',
          backgroundRepeatX: 'no-repeat',
          backgroundSize: '100%',
          backgroundColor: this.questionDescDetail.questionBackgroundColor
        };
      } else {
        return {
          backgroundColor: this.questionDescDetail.questionBackgroundColor
        };
      }
    },
    wrapperBgStyle() {
      if (this.windowWidth > 720) {
        return {
          backgroundImage: this.questionDescDetail.pcBackgroundImage ? `url(${this.questionDescDetail.pcBackgroundImage})` : '',
          backgroundPosition: 'center top',
          backgroundSize: '100%',
          backgroundRepeatX: 'no-repeat',
          backgroundRepeatY: 'repeat',
          maxWidth: '1440px',
          marginLeft: 'auto',
          marginRight: 'auto'
        };
      }
    },
    subtitleStyle() {
      return {
        color: this.questionDescDetail.subtitleColor
      };
    },
    titleStyle() {
      return {
        color: this.questionDescDetail.titleColor
      };
    },
    isBlock() {
      if (window.innerWidth < 720) {
        return true;
      } else {
        return false;
      }
    },
    ctaStatus() {
      const selectedList = this.optionList.filter((item) => {return item.selected;});
      if (this.isMultiple) {
        return selectedList.length < this.minNumInMulti;
      } else {
        return !(selectedList.length > 0 && selectedList.length < 2);
      }
    },
    wholeReplyText() {
      return this.questionDescDetail.replyText || '感谢您的参与！';
    },
    wholeReplyTextStyle() {
      return {
        color: this.questionDescDetail.replyTextColor
      };
    },
    dynamicReplyFlag() {
      return this.questionDescDetail.dynamicReplyFlag;
    },
    buttonBgStyle() {
      const bgColor = this.questionDescDetail.submitButtonColor;
      if (bgColor && !this.ctaStatus) {
        return {
          backgroundColor: bgColor
        };
      } else {
        return {};
      }
    },
    surveyBgSingle() {
      return !this.submitAnswerFlag && this.repeatFillingFlag && (!this.isMultiple && !this.questionDescDetail.submitButtonFlag) && !this.showWholeReply;
    },
    surveyBgMulti() {
      return !this.submitAnswerFlag && this.repeatFillingFlag && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.submited && !this.showWholeReply;
    },
    surveyBgMultiNoReset() {
      return !this.submitAnswerFlag && !this.repeatFillingFlag && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.submited && !this.showWholeReply;
    },
    isShowResetText() {
      return (!this.submitAnswerFlag && !this.dynamicReplyFlag && this.repeatFillingFlag && !this.isMultiple && this.selectedItem) ||
        (!this.submitAnswerFlag && !this.dynamicReplyFlag && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.submited && this.repeatFillingFlag) ||
        (!this.submitAnswerFlag && this.dynamicReplyFlag && this.computeCompleted && this.repeatFillingFlag && (((this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.submited) || (!this.isMultiple && this.selectedItem)));
    },
    isShowSubmitBtn() {
      if (this.isMultiple) {
        return !this.submitAnswerFlag && this.isMultiple && !this.submited;
      } else {
        return !this.submitAnswerFlag && this.questionDescDetail.submitButtonFlag && !this.submited;
      }
    },
    isAdiclub() {
      return this.$route.path.includes('/AC');
    }
  },
  mounted() {
    this.utils = new BasicUtils(this);
    this.computeCompleted = true;
    const u = navigator.userAgent.toLowerCase();
    this.isIos = !!u.match(/ipad|iphone|ipod/i);
    this.isAndroidChrome = !!u.includes('android') && (!!u.includes('chrome/78.0.3904.96') || !!u.includes('chrome/81'));
    this.isMac = !!u.match(/macintosh|mac os x/i);
    this.isVivo = !!u.match(/vivobrowser/i);
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.isPcQQBrowser = this.windowWidth > 720 && !this.isMac && !!u.match(/qqbrowser/i);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  updated() {
  },
  destroyed() {
  },
  methods: {
    rotateOption() {
        const questionAnswerInfo = this.wholeSurveySubmitData.questionAnswerInfo.find((item) => {return item.questionCode === this.question.questionCode;});
        if (questionAnswerInfo) {
          const answerList = questionAnswerInfo?.answerList || [];
          this.optionList.forEach(element => {
            const answer = answerList.find((item) => { return item.optionCode === element.optionCode;});
            if (answer) {
              this.$set(element, 'selected', true);
              this.$set(element, 'className', 'rotated');
              this.$set(element, 'submited', true);
            }
          });
        }
    },
    handleGuideChange(val) {
      this.showMemberGuide = val;
    },
    loadQuestionImg(height, buffer, marginTop = 0) {
      if (this.questionDescDetail.questionImage) {
        const img = new Image();
        img.src = this.questionDescDetail.questionImage;
        return new Promise((resolve, reject) => {
          img.onload = (e) => {
            this.questionImgHeight = this.$refs.questionImg?.offsetHeight || 0;
            const top = height + this.questionImgHeight + buffer + marginTop;
            resolve(top);
          };
        });
      } else {
        return new Promise((resolve, reject) => {
          const top = height + buffer;
          resolve(top);
        });
      }
    },
    loadReplyImg() {
      if (this.questionDescDetail.replyImage) {
        const img = new Image();
        img.src = this.questionDescDetail.replyImage;
        return new Promise((resolve) => {
          img.onload = (e) => {
            const wholeReplyImgHeight = this.$refs.wholeReplyImg?.clientHeight || 0;
            resolve(wholeReplyImgHeight);
          };
        });
      } else {
        return new Promise((resolve) => {
          const wholeReplyImgHeight = this.$refs.wholeReplyText?.clientHeight || 0;
          resolve(wholeReplyImgHeight);
        });
      }
    },
    animateWholeReply() {
      setTimeout(() => {
        this.showWholeReplyAnimation = true;
      }, 400);
      if (!this.isAndroidChrome && !this.isPcQQBrowser) {
        this.$refs.wholeReplyContainer && this.$refs.wholeReplyContainer.animate([{
          height: `${this.wholeReplyImgHeight}px`
        }], {
          duration: 1200,
          easing: 'linear',
          fill: 'forwards',
          delay: 0
        });
      } else {
        this.wholeReplyStyle.height = 'auto';
      }
      setTimeout(() => {
        this.wholeReplyAnimationEnd = false;
        this.showOptions = false;
      }, 1000);
      setTimeout(() => {
        this.showRepeatSelect = true;
      }, 1500);
    },
    dynamicPercent(item) {
      if (this.submited && this.computeCompleted) {
        return {
          width: item.percentStr,
          backgroundColor: this.questionDescDetail.proportionColor && this.isCurrentSubmited(item) ? colorHexToRgb(this.questionDescDetail.proportionColor, 1) : 'rgba(0, 0, 0, 0.1)'
        };
      } else {
        return {};
      }
    },
    dynamicTheme(item) {
      if (this.isCurrentSubmited(item) && this.computeCompleted) {
        return {
          backgroundColor: this.questionDescDetail.proportionColor ? colorHexToRgb(this.questionDescDetail.proportionColor, 0.5) : 'inherit'
        };
      } else {
        return {

        };
      }

    },
    getLastResult() {
      if (this.surveyResult) {
        const questionAnswer = this.surveyResult.questionAnswerInfo.find((item) => { return item.questionCode === this.question.questionCode;});
        if (questionAnswer) {
          const answerList = questionAnswer?.answerList || [];
          this.optionList.forEach(element => {
            const answer = answerList.find((item) => { return item.optionCode === element.optionCode;});
            if (answer) {
              this.$set(element, 'selected', true);
              this.$set(element, 'className', 'rotated');
            }
          });
          this.submited = true;

          if (this.question.questionType === 'TextInput') {
            this.selectedItem = {text:answerList&&answerList[0]?.answerValue?answerList&&answerList[0]?.answerValue:""};
          } else if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
            this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
          } else {
            this.selectedItem = this.optionList.find((item) => {return item.selected;});
          }
        }
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    backStyle(item) {
      if (item.replyImageUrl) {
        return {
          backgroundImage: item.replyImageUrl ? `url(${item.replyImageUrl})` : '',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        };
      } else {
        return {
          backgroundImage: item.optionImageUrl ? `url(${item.optionImageUrl})` : '',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        };
      }

    },
    isCurrentItemSubmited(item) {
      const itemSubmited = !this.submitAnswerFlag && this.submited &&
      (
        ((this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && this.selectedItemList.find((it) => { return it.optionCode === item.optionCode;})) ||
        (!this.isMultiple && this.selectedItem && this.selectedItem.optionCode === item.optionCode)
      );
      return !!itemSubmited;
    },
    isWholeSurveySubmited() {
      return this.submitAnswerFlag && this.wholeSurveySubmited;
    },
    isCurrentWholeSubmited(item) {
      const wholeSubmited = this.submitAnswerFlag && (
        (this.wholeSurveySubmited && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) &&
        this.selectedItemList.find((it) => { return it.optionCode === item.optionCode;})) ||
        (!this.isMultiple && this.selectedItem && this.selectedItem.optionCode === item.optionCode)
      );
      return !!wholeSubmited;
    },
    isCurrentSubmited(item) {
      // console.log('>>>>>>>>>>><<<<<<<<<', this.isCurrentItemSubmited(item), this.isCurrentWholeSubmited(item));
      return !!this.isCurrentItemSubmited(item) || !!this.isCurrentWholeSubmited(item);
    },
    optionTextColor(item) {
      return {
        color: item.optionTextColor
      };
    },
    handleCtaAlert() {
      const selectedList = this.optionList.filter((item) => {return item.selected;});
      if (selectedList.length < this.minNumInMulti) {
        this.$Toast({ message: `请至少选择${this.minNumInMulti}个选项` });
      }
    },
    handleNativeClick() {
      if(this.surveyDetail.replyMemberFlag) {
        this.utils.isMemberLogin() ? this.handleCtaAlert() : this.handleMemberLogin();
      } else {
        this.handleCtaAlert();
      }
    },
    handleData(item) {
      if (this.submited)
        return;
      if (this.isMultiple) {
        if (this.selectedItemList.length >= this.maxNumInMulti && !item.selected) {
          this.$Toast({ message: `只能选择${this.maxNumInMulti}个选项` });
        } else {
          this.optionList.forEach(element => {
            if (element.optionCode === item.optionCode) {
              this.$set(element, 'selected', !item.selected);
              setTimeout(() => {
                this.$set(element, 'submited', true);
              }, 500);
            }
          });
          this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
        }
      } else if (this.questionDescDetail.submitButtonFlag) {
        this.optionList.forEach(element => {
          if (element.optionCode === item.optionCode) {
            this.$set(element, 'selected', !item.selected);
          }
        });
        this.optionList.forEach(element => {
          if (element.optionCode !== item.optionCode) {
            this.$set(element, 'selected', false);
          }
        });
        this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
      } else {
        this.optionList.forEach(element => {
          this.$set(element, 'selected', false);
          this.$set(element, 'className', '');
          this.$set(element, 'submited', false);

          if (element.optionCode === item.optionCode) {
            this.$set(element, 'selected', true);
            this.$set(element, 'className', 'rotated');
            setTimeout(() => {
              this.$set(element, 'submited', true);
            }, 500);
          }
        });
        this.selectedItem = this.optionList.find((item) => {return item.selected;});
        this.submited = true;
        this.handleSingleTrack(item);
        this.submitResult();
      }
    },
    async handleMemberLogin(){
      const utils = new BasicUtils(this);
      if (utils.isMemberLogin()) {
        // this.$Toast({message: "您已登录为会员"});
        // this.handleData(item);
      } else if (utils.getEmployeeId()) {
        this.$Toast({message: "抱歉，员工无法参与"});
      } else {
        const utils=new BasicUtils(this);
        if ((utils.IsAPP && window.AdidasApp) || utils.IsWMS || utils.IsOR || utils.IsAdiclub) {
          this.showMemberGuide = true;
        } else {
          const firstLogin = await utils.loginByEnvAsync({
            redirectUrl: location.href,
            memberOnly: true,
            loginClp: true,
            title: window.innerWidth < 720 ? '会员专属内容' : '登录即可解锁互动玩法',
            desc: window.innerWidth < 720 ? '加入会员即可解锁趣味玩法/专属活动等精彩内容' : ''
          });
          if (firstLogin) {
            this.$emit('refreshLastResult');
          }
        }
      }
    },
    handleClick(item) {
      if(this.surveyDetail.replyMemberFlag) {
        this.utils.isMemberLogin() ? this.handleSubmitData(item) : this.handleMemberLogin();
      } else {
        this.handleSubmitData(item);
      }
    },
    handleSubmitData(item) {
      if (!this.submitAnswerFlag) {
        // 非整卷提交
        this.handleData(item);
      } else {
        // 整卷提交
        if (this.wholeSurveySubmited)
          return;
        this.beforeWholeSurveySubmit(item);
        console.log('整卷提交', this.selectedItemList, this.selectedItem);
        const noneReplyItem = this.selectedItemList.find((item) => {return !item.replyText && !item.replyImageUrl && (!item.replyArticleList || !item.replyArticleList?.length) && !item.replyCategoryCode;});
          let flag;
          if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
            flag = !!(noneReplyItem && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
          } else {
            flag = !!(this.selectedItem && !this.selectedItem.replyText && !this.selectedItem.replyImageUrl && (!this.selectedItem.replyArticleList || !this.selectedItem.replyArticleList?.length) && !this.selectedItem.replyCategoryCode && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
          }
        this.$emit('wholeSubmit', {
          selectedItemList: this.selectedItemList,
          selectedItem: this.selectedItem,
          optionCode: item.optionCode,
          questionCode: this.question.questionCode,
          surveyCode: this.surveyCode,
          questionType: this.question.questionType,
          showWholeReply: flag
        });
      }
    },
    // 整卷提交数据装饰
    beforeWholeSurveySubmit(item) {
      if (this.isMultiple) {
        if (this.selectedItemList.length >= this.maxNumInMulti && !item.selected) {
          this.$Toast({ message: `只能选择${this.maxNumInMulti}个选项` });
        } else {
          this.optionList.forEach(element => {
            if (element.optionCode === item.optionCode) {
              this.$set(element, 'selected', !item.selected);
              setTimeout(() => {
                this.$set(element, 'submited', true);
              }, 500);
            }
          });
          this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
        }
      } else if (this.questionDescDetail.submitButtonFlag) {
        this.optionList.forEach(element => {
          if (element.optionCode === item.optionCode) {
            this.$set(element, 'selected', !item.selected);
          }
        });
        this.optionList.forEach(element => {
          if (element.optionCode !== item.optionCode) {
            this.$set(element, 'selected', false);
          }
        });
        this.selectedItemList = this.optionList.filter((item) => {return item.selected;});
      } else {
        this.optionList.forEach(element => {
          this.$set(element, 'selected', false);
          this.$set(element, 'className', '');
          this.$set(element, 'submited', false);

          if (element.optionCode === item.optionCode) {
            this.$set(element, 'selected', true);
            this.$set(element, 'className', 'rotated');
            setTimeout(() => {
              this.$set(element, 'submited', true);
            }, 500);
          }
        });
        this.selectedItem = this.optionList.find((item) => {return item.selected;});
      }
    },
    handleSingleTrack(item) {
      trackClpSurveySubmit({
        campaignName: this.$route.query.contentId,
        componentCode: 'Survey',
        positionCode: this.question.questionPosition,
        // 文字问题类型由于在获取组件时转化为驼峰法写法，此处需要再转化回来，下同
        questionType: this.question.questionType === "TextInput"? "Text_essay" : this.question.questionType,
        surveyId: this.surveyCode,
        questionId: this.question.questionCode,
        submitDetail: item.optionDescribe,
        articleIdList: item.optionArticleList?.length ? item.optionArticleList[0] : '',
        categoryCodeList: item.optionCategoryCode || '',
        is_memer_only: !!this.surveyDetail.replyMemberFlag
      });
      trackClpSurveyResultView({
        campaignName: this.$route.query.contentId,
        componentCode: 'Survey',
        positionCode: this.question.questionPosition,
        questionType: this.question.questionType === "TextInput"? "Text_essay" : this.question.questionType,
        surveyId: this.surveyCode,
        questionId: this.question.questionCode,
        submitDetail: item.optionDescribe,
        articleIdList: item.optionArticleList?.length ? item.optionArticleList[0] : '',
        categoryCodeList: item.optionCategoryCode || '',
        resultType: this.dynamicReplyFlag ? 'dynamic': (this.showWholeReply ? 'for all' : 'by choice'),
        pageTitle: 'submit',
        is_memer_only: !!this.surveyDetail.replyMemberFlag
      });
    },
    computeDynamicData(questionAnswerInfoList) {
      const questionAnswerInfo = questionAnswerInfoList.find((li) => {return li.questionCode === this.question.questionCode;});
      const answerStatisticsList = questionAnswerInfo?.questionAnswerStatistics?.optionStatistics || [];
      const optionList = deepcopy(this.optionList);
      const optionLength = optionList.length;
      const questionAnswerTotalNum = questionAnswerInfo?.questionAnswerStatistics?.questionAnswerTotalNum || 0;
      if (this.dynamicReplyFlag && questionAnswerTotalNum) {
        if (this.question.multipleChoiceFlag) {
          optionList.forEach((option) => {
            const answer = answerStatisticsList.find((answer) => {
              return answer.optionCode === option.optionCode;
            });
            option.percent = answer.optionAnswerNum / questionAnswerTotalNum;
            option.percentStr = Math.floor(option.percent * 100) + '%';
          });
        } else {
          let sum = 0;
          optionList.forEach((option, index) => {
            const answer = answerStatisticsList.find((answer) => {
              return answer.optionCode === option.optionCode;
            });
            if (index === optionLength - 1) {
              option.percent = 1 - sum;
              option.percentStr = Math.ceil(option.percent * 100) + '%';
            } else {
              option.percent = answer.optionAnswerNum / questionAnswerTotalNum;
              sum = option.percent + sum;
              option.percentStr = Math.floor(option.percent * 100) + '%';
            }
          });
        }
      }
      this.optionList = deepcopy(optionList);
    },
    handleSubmit() {
      this.submited = true;
      this.optionList.forEach(element => {
        if (element.selected) {
          this.$set(element, 'className', 'rotated');
        }
      });
      const submitDetail = this.optionList.filter((item) => {
        return item.selected && item.optionDescribe;
      }).map((item) => {
        return item.optionDescribe;
      });
      const articleIdList = this.optionList.filter((item) => {
        return item.selected && item.optionArticleList?.length;
      }).map((item) => {
        return item.optionArticleList[0];
      });
      const categoryCodeList = this.optionList.filter((item) => {
        return item.selected && item.optionCategoryCode;
      }).map((item) => {
        return item.optionCategoryCode;
      });
      trackClpSurveySubmit({
        campaignName: this.$route.query.contentId,
        componentCode: 'Survey',
        positionCode: this.question.questionPosition,
        questionType: this.question.questionType,
        surveyId: this.surveyCode,
        questionId: this.question.questionCode,
        submitDetail: submitDetail.join('|'),
        articleIdList: articleIdList.join('|'),
        categoryCodeList: categoryCodeList.join('|'),
        is_memer_only: !!this.surveyDetail.replyMemberFlag
      });
      trackClpSurveyResultView({
        campaignName: this.$route.query.contentId,
        componentCode: 'Survey',
        positionCode: this.question.questionPosition,
        questionType: this.question.questionType,
        surveyId: this.surveyCode,
        questionId: this.question.questionCode,
        submitDetail: submitDetail.join('|'),
        articleIdList: articleIdList.join('|'),
        categoryCodeList: categoryCodeList.join('|'),
        resultType: this.dynamicReplyFlag ? 'dynamic': (this.showWholeReply ? 'for all' : 'by choice'),
        pageTitle: 'submit',
        is_memer_only: !!this.surveyDetail.replyMemberFlag
      });
      this.submitResult();
    },
    resetSurvey() {
      this.submited = false;
      this.computeCompleted = false;
      this.selectedItem = null;
      this.selectedItemList = [];
      this.optionList.forEach(element => {
        this.$set(element, 'submited', false);
        this.$set(element, 'selected', false);
        this.$set(element, 'className', '');
      });
      this.showWholeReplyAnimation = false;
      this.wholeReplyAnimationEnd = true;
      this.showOptions = true;
      this.showRepeatSelect = false;
      if (!this.isAndroidChrome && !this.isPcQQBrowser) {
        this.$refs.wholeReplyContainer && this.$refs.wholeReplyContainer.animate([{
          height: 'auto'
        }], {
          duration: 0,
          easing: 'linear',
          fill: 'forwards',
          delay: 0
        });
      }
    },
    formatResultData() {
      const data = {
        surveyCode: this.surveyCode,
        surveyType: this.surveyDetail.surveyType,
        version: this.surveyDetail.version
      };
      const questionAnswerInfo = [];
      const questionAnswer = {
        questionCode: this.question.questionCode
      };
      let answerList = [];
      // 这里对于Text组件特殊处理，因为其为非选项提交
      if (this.question.questionType === 'TextInput') {
        answerList.push({answerValue: this.selectedItem.text});
        questionAnswer.answerList = answerList;
        questionAnswerInfo.push(questionAnswer);
        data.questionAnswerInfo = questionAnswerInfo;
      } else if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag) || this.question.questionType === 'Shuffle') {
        const length = this.selectedItemList.length;
        for(let i = 0; i < length; i++) {
          if (this.question.questionType === 'Shuffle') {
            answerList.push({optionCode: this.selectedItemList[i].optionCode,submitType: this.selectedItemList[i].submitType || this.selectedItemList[i].liked? 'second':'first'});
          } else{
            answerList.push({optionCode: this.selectedItemList[i].optionCode});
          }
        }
        questionAnswer.answerList = answerList;
        questionAnswerInfo.push(questionAnswer);
        data.questionAnswerInfo = questionAnswerInfo;
      } else {
        const answerItem = {optionCode: this.selectedItem.optionCode};
        answerList = [answerItem];
        questionAnswer.answerList = answerList;
        questionAnswerInfo.push(questionAnswer);
        data.questionAnswerInfo = questionAnswerInfo;
      }
      return data;
    },
    async submitResult() {
      this.computeCompleted = false;
      const resultData = this.formatResultData();
      const utils=new BasicUtils(this);
      const isLogin = !!TokenUtils.getToken();
      const unionId = TokenUtils.getQueryValue('unionId');
      if (utils.IsAPP && window.AdidasApp) {
        const appLogin = new AppLogin(window.AdidasApp);
        const data = await appLogin.getloginInfoAsync();
        !data ? utils.config.headers['x-client-device-id'] = await getDistinctIds() : '';
      } else if((utils.IsWMS || utils.IsOR || utils.IsAdiclub) && !isLogin) {
        utils.config.headers['x-open-id'] = TokenUtils.getQueryValue('openId');
      } else {
        !isLogin && (utils.config.headers['x-client-device-id'] = await getDistinctIds());
      }

      if ((utils.IsWMS || utils.IsOR || utils.IsAdiclub) && unionId) {
        utils.config.headers['x-union-id'] = unionId;
      }

      try {
        const res = await this.$axios.post(
          `${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/surveys/answer-submit`,
          resultData,
          utils.config
        );
        res.questionAnswerInfo && this.computeDynamicData(res.questionAnswerInfo);
        this.computeCompleted = true;
        console.log('survey submit user result', res);
      } catch (error) {
        if (this.dynamicReplyFlag && this.question.questionType !== 'Shuffle') {
          this.resetSurvey();
        }
        console.log('survey submit error', error);
      }
    }
  }
};
