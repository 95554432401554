
import {
  LibPopover,
  LibDialog
} from '@adidas-com-front/ui-library';
import  LibLogin from './LibLogin.vue';
import {BasicUtils} from "~/utilities/basic/utils";
import points from '~/components/pointsAnimation/index.vue';

export default {
  name: 'Login',

  components: {
    LibPopover,
    LibDialog,
    LibLogin,
    points
  },
  props: {
    allEvents:{
      type:Object,
      default:()=>{return {};}
    },
    show: {
      required: true,
      type: Boolean,
      default: false
    },
    // 展示会员登录界面
    firstStepProp: {
      type: Boolean,
      default: false
    },
    // 点击加入阿迪俱乐部
    joinAdiclubProp: {
      type: Boolean,
      default: false
    },
    loginClp:{
      type: Boolean,
      default: true
    },
    isJointMember:{
      type: Boolean,
      default: true
    },
    joinType:{
      type: String,
      default: ''
    },
    titleImport: {
      type: String,
      default: ''
    },
    descImport: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      init:true,
      isMobile:false,
      titleContent:'',
      titleFont:'heading-2',
      iconType:'',
      icon:'',
      showMobileErrMsgDialog:false,
      isHide:false,
      // 向上滑动打开clp标识
      scrollShowClp: false,
      utils:null,
      showPointsClose:()=>{},
      showPoints:false,
      descContent: ''
    };
  },
  computed:{
    classList(){
      return ['login-popover',this.isHide?'hide':'',this.isIosSafari?"safari":"",!this.isMobile?"pc":""];
    },
    isIosSafari(){
      if(this?.utils){
        return this?.utils?.isIosSafari;
      }else{
        return false;
      }
    }
  },
  watch:{
    show(value){
      if(value){
        this.isHide=false;
        this.$nextTick(()=>{
          for(const name in this.allEvents){
            if(name==='login'){
              // 老版本
              this.$refs.login.$on("login",(data)=>{
                this.showPointsAnimation(data);
                this.updateShow();
                this.allEvents.close();
                this.allEvents.login();
              });
            }else if(name==='newLoginEnd'){
              // 新版本
              this.$refs.login.$on("login",(data)=>{
                this.showPointsAnimation(data);
                this.updateShow();
                this.allEvents.close(false);
                this.allEvents.newLoginEnd();
              });
            }else if(name!=='pointsClose'){
              this.$refs.login.$on(name,this.allEvents[name]);
            }
          }
        });
      }
    }
  },
  mounted() {
    this.isHide=false;
    this.init=true;
    this.utils=new BasicUtils(this);
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.$nextTick(()=>{
      // this.initBody();
      this.init=false;
      const loginPopContainer = document.querySelector(".login-popover").querySelector(".pop-container");
      loginPopContainer?.addEventListener('touchstart',this.popoverTouchStart);
      loginPopContainer?.addEventListener('touchend',this.popoverTouchEnd);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('touchstart', this.popoverTouchStart);
    window.removeEventListener('touchend', this.popoverTouchEnd);
  },
  methods: {
    showPointsAnimation(data){
      if(data?.actionType==='4'||data?.actionType==='5'){
        this.showPoints=true;
        this.allEvents.pointsClose(new Promise((resolve)=>{
          this.showPointsClose=()=>{
            this.showPoints=false;
            this.showPointsClose=()=>{};
            resolve();
          };
        }));

      }
    },
    getBody(){
      let box=this.$refs.login.$el;
      if(!box){
        return;
      }
      for(let i=0;i<100;i++){
        if(box.parentElement){

          if([...box.parentElement.classList].includes('pop-body')){
            return box.parentElement;
          }else{
            box=box.parentElement;
            continue;
          }
        }
        break;
      }
    },
    scroll(scrollTop){
      const box=this.getBody();
      if(box){
        console.log('scrollTop',scrollTop);
        box.scrollTop=scrollTop;
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 720;
      this.screenHeight = document.documentElement.clientHeight;
    },
    setTitle(val){
      if('titleContent' in val){
        this.titleContent=val.titleContent;
      }
      if('titleFont' in val){
        this.titleFont=val.titleFont;
      }
      if('descContent' in val) {
        this.descContent = val.descContent;
      }
    },
    setIcon(val){
      if(val.iconType){
        this.iconType=val.iconType;
      }
      if(val.icon){
        this.icon=val.icon;
      }
    },
    showErrMsgDialog(val){
      this.showMobileErrMsgDialog=val;
    },
    handleCancel(){
      this.$refs.login&&this.$refs.login.handleCancel();
    },
    handleConfirm() {
      this.$refs.login&&this.$refs.login.handleConfirm();
    },
    popoverTouchStart(e){
      this.vueTouches = {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY
      };
    },
    popoverTouchEnd(e){
      const disY = e.changedTouches[0].pageY - this.vueTouches.y;
      if (disY < -20) {
        this.scrollShowClp = true;
      }
    },
    popGoBack(){
      console.log('popGoBack');
      this.$refs.login&&this.$refs.login.popGoBack();
    },
    updateShow(){
      console.log('updateShow');
      this.$refs.login&&this.$refs.login.updateShow();
      this.scrollShowClp = false;
    },
    // 点击开启会员福利即关闭clp后，更新滑动展开属性
    clpBoxUpdate(val){
      this.scrollShowClp = val;
    }
  }
};
