import { BasicUtils,iCtx } from '~/utilities/basic/utils';
import { isClient } from '~/utilities/helper/SystemHelper';
type contentName = 'profile' | 'order' | 'cs';

export enum contentType{
  // eslint-disable-next-line no-unused-vars
  NPS='NPS',
  // eslint-disable-next-line no-unused-vars
  CES="CES"
}

export enum QuestionType {
  // eslint-disable-next-line no-unused-vars
  CesScore= 'CesScore',
  // eslint-disable-next-line no-unused-vars
  NpsScore = 'NpsScore',
  // eslint-disable-next-line no-unused-vars
  Options = 'Options',
  // eslint-disable-next-line no-unused-vars
  Text = 'Text',
}
interface IQuestion {
  questionId: number;
  questionType: QuestionType;
  surveyPosition: number;
}
interface IQCesScoreItem{
  // 0,6 7,8
  score: string;
  nextQuestionId: 2;
  text: string;
  img:string;
  activeImg:string;
  difficult:string
}
interface IQNpsScoreItem{
  // 0,6 7,8
  score: string;
  nextQuestionId: 2;
  text: string;
}
interface IQNpsScore extends IQuestion {
  questionType: QuestionType.NpsScore;
  questionDescriptionDetail: {
    scoreArea: string;
    description: string;
    optionList: IQNpsScoreItem[];
  };
}
interface IQCesScore extends IQuestion {
  questionType: QuestionType.CesScore;
  questionDescriptionDetail: {
    scoreArea: string;
    description: string;
    optionList: IQCesScoreItem[];
  };
}
interface IQOptions extends IQuestion {
  nextQuestionId?: number;
  questionType: QuestionType.Options;
  questionDescriptionDetail: {
    optionList: string[];
    description: string;
  };
}
interface IQText extends IQuestion {
  nextQuestionId?: number;
  questionType: QuestionType.Text;
  questionDescriptionDetail: {
    description: '111';
  };
}

interface IContent {
  surveyCode: string,
  surveySource: string,
  surveyType: "NPS",
  publishDate: string;
  questionList: (IQNpsScore | IQOptions | IQText |IQCesScore )[];
}

interface IShow {
  showFlag: number;
  showNumber: number;
  submitNumber: number;
}
interface ISurveyEntries{
  "answer":string,
  "surveyPosition":number,
  "questionType":string
}

interface iLastVisitTime{
  memberId: string;
  page:string,
  time:number
}

const difficult:{
  [key:number]:{
    text:string,
    img:string,
    activeImg:string,
  }
}={
  1:{
    text:"非常困难",
    img:require('~/assets/nsp-ces/very-difficult.png'),
    activeImg:require('~/assets/nsp-ces/very-difficult-sel.png') 
  },
  2:{
    text:"困难",
    img:require('~/assets/nsp-ces/difficult.png') ,
    activeImg:require('~/assets/nsp-ces/difficult-sel.png') 
  },
  3:{
    text:"一般",
    img:require('~/assets/nsp-ces/normalDiffcult.png') ,
    activeImg:require('~/assets/nsp-ces/normalDiffcult-sel.png') 
  },
  4:{
    text:"轻松",
    img:require('~/assets/nsp-ces/easy.png'),
    activeImg:require('~/assets/nsp-ces/easy-sel.png') 
  },
  5:{
    text:"非常轻松",
    img:require('~/assets/nsp-ces/very-easy.png'),
    activeImg:require('~/assets/nsp-ces/very-easy-sel.png')
  }
};

export class Utils extends BasicUtils {
  protected type: contentType=contentType.NPS;
  protected name: contentName='profile';
  init(
    type: contentType,
    name: contentName
  ){
    this.type=type;
    this.name=name;
  }

  /**
   * 获取问卷
   * @param type
   * @returns
   */
  async initContentAsync(
    type: contentType,
    name: contentName
  ): Promise<{ content: IContent; entrance: IQNpsScore| IQCesScore | null }> {
    this.type=type;
    this.name=name;
    const getP = this.axios.get(
      `${this.S3Host}/f04/page_conf/COM/${type}/zh_CN/0/${name}.json`
    ) as Promise<IContent>;
    const data = await getP;
    let npsScore: IQNpsScore |IQCesScore| null = null;
    if (data?.questionList && data.questionList instanceof Array) {
      for (const item of data.questionList) {
        if (
          (item.questionType === QuestionType.NpsScore&&type===contentType.NPS)||
          (item.questionType === QuestionType.CesScore&&type===contentType.CES)
        ) {
          if (!npsScore) {
            npsScore = item;
          }
          item.questionDescriptionDetail.optionList = this.updateQScoreList(item.questionDescriptionDetail.optionList);
        }
      }
      if (npsScore) {
        const index = data.questionList.indexOf(npsScore);
        data.questionList.splice(index, 1);
      }
      data.questionList.sort((aItme, bItem) => aItme.surveyPosition - bItem.surveyPosition );
    }
    return { content: data, entrance: npsScore };
  }

  /**
   * 获取 nps是否显示
   */
  async getNPSShowAsync(): Promise<boolean> {
    if(isClient&&window===window.top){
      let nps:iLastVisitTime| null =null;
      try{
        const npsStr=localStorage.getItem('nps');
        if(npsStr){
          nps=JSON.parse( npsStr) as iLastVisitTime;
        }
      }catch{
        return false;
      }
      let memberId=this.getMemberId();
      if(!memberId){
        memberId=this.getUUID();
      }
      // 不是当前用户的记录 删除
      if(nps&&nps?.memberId&&nps?.memberId!==memberId){
        localStorage.removeItem('nps');
      }
      const nowTime= (new Date()).getTime();
      if(
          nps&&
          nps?.memberId===memberId&&
          nowTime-nps.time<24*60*60*1000
        ){
        const showp=this.axios.get(
          `${this.host}/o2vip/v1/pub/0/surveys/show?surveyType=NPS`,
          {
            ...this.getConfig()
          }
        ) as Promise<IShow>;
        const {showFlag}=await showp;
        if(showFlag){
          return true;
        }
      }
    }
    return false;
  }

  /**
   * 提交评分
   */
   submitAnswerAsync(params:{
    content: IContent,
    score:string,
    items:null|ISurveyEntries[],
    orderId?:string,
    refundCode?:string
   }): Promise<void> {
    const data:{
      surveyCode:string,
      score:string,
      surveyEntries:null|ISurveyEntries[],
      platformOrderId?: string
      platformRefundCode?: string
    }={
        "surveyCode":params.content.surveyCode,
        score:params.score,
        "surveyEntries":params.items
    };
    if(params.orderId){
      data.platformOrderId=params.orderId;
    }
    if(params.refundCode){
      data.platformRefundCode=params.refundCode;
    }
    return this.axios.put(
      `${this.host}/o2vip/v1/pub/0/surveys/submit-answer`,data,
      {
        ...this.getConfig()
      }
    );
  }

  /**
   * 刷新疲劳度
   */
  updateTimeRecordAsync(surveyType:string):Promise<void>{
    return this.axios.put(`${this.host}/o2vip/v1/pub/0/surveys/time-record`,{
      surveyType
    },
    {
      ...this.getConfig()
    });
  }

  /** 获取请求header */
  getConfig(){
    const headers = {...this.config.headers};
    if (!this.getToken()) {
      headers['x-com-unlogged-id'] = this.getUUID();
    }
    return {
      ...this.config,
      headers
    };
  }

  /**
   * 生产
   * @returns
   */
  getUUID(): string {
    const name = 'com-unlogged-uuid';
    let uuid = localStorage.getItem(name);
    if (!uuid) {
      uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0;
          const v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
      localStorage.setItem(name, uuid);
    }
    return uuid;
  }

  /**
   * 更新 本地使用的
   * @param list
   * @returns
   */
  updateQScoreList(
    list: IQNpsScoreItem[]|IQCesScoreItem[]
  ): (IQNpsScoreItem|IQCesScoreItem)[] {
    const newList: (IQNpsScoreItem|IQCesScoreItem)[]= [];
    for (const item of list) {
      let min,max;
      if(item.score.includes(',')){
      const [minText, maxText] = item.score.split(',');
        min = parseInt(minText);
        max = parseInt(maxText);
      }else{
        min=max=parseInt(item.score);
      }
      if (isNaN(min) || isNaN(max)) {
        continue;
      }
      for (let i = min; i <= max; i++) {
        if(this.type===contentType.CES&& i in difficult){
          const cesScoreItem={
            ...item,
            difficult:i.toString(),
            text:difficult[i].text,
            img:difficult[i].img,
            activeImg:difficult[i].activeImg
          };
          const img=new Image();
          img.src=cesScoreItem.img;
          const img2=new Image();
          img2.src=cesScoreItem.activeImg;
          newList.push(cesScoreItem);
        }else{
          const scoreItem:IQNpsScoreItem={
            ...item,
            text:i.toString()
          };
          newList.push(scoreItem);
        }
      }
    }
    return newList;
  }


  /**
   * 根据nextQuestionID 更新questionList
   * @param nextQuestionID
   */
  updateQuestionList(
    questionList: IContent['questionList'],
    nextQuestionID: number | null
  ): IContent['questionList'] {
    let nextQID: number | null = nextQuestionID;
    const newQuestionList: IContent['questionList'] = [];
    while (nextQID) {
      let nextItem: IQNpsScore | IQOptions | IQText|IQCesScore|null=null;
      for (const item of questionList) {
        if (item.questionId === nextQID) {
          nextItem=item;
          if ('nextQuestionId' in item && item.nextQuestionId) {
            nextQID = item.nextQuestionId;
          } else {
            nextQID = null;
          }
          break;
        }
      }
      if(nextItem&&!newQuestionList.includes(nextItem)){
        newQuestionList.push(nextItem);
      }else{
        break;
      }
    }
    return newQuestionList.sort((aItme, bItem) => {
      return aItme.surveyPosition - bItem.surveyPosition;
    });
  }

  /**
   * 更新plp pdp 访问时间
   * @param page 
   * @param ctx 
   */
  updateLastVisitTime(page:string,ctx:iCtx){
    if(isClient&&window===window.top){
      let memberId = this.getMemberId();
      if(!memberId){
        memberId=this.getUUID();
      }
      const values=JSON.stringify({
        memberId,
        page,
        time:(new Date).getTime()
      } as iLastVisitTime);
      localStorage.setItem('nps',values);
    }
  }
  
}