
import Vue from 'vue';
import throttle from 'lodash.throttle';
import Dayjs from '../../ui-library/src/components/atoms/LibFormat/dayjs.min';
import Header from '../components/navigation';
import { getServerTime, isClient, syncAdTrack, TokenUtils } from '../utilities';
import SearchPopover from '../components/search/mobile-search-component/index.vue';
// import { getUrlQuery } from '~/utilities/helper/urlHelper';
import Compatibility from '../components/compatibility';
import { trackSmartBannerView, trackSmartBannerClick } from '~/components/smart-banner/track/index';
import { initCS } from '~/utilities/helper/live800';
export default {
  name: 'NuxtDefaultLayout',
  components: {
    Header,
    SearchPopover,
    Compatibility
  },
  data() {
    let hideHeader = false;
    if (this.$route.path === '/clp') {
      if (this.$route.query && 'header' in this.$route.query) {
        hideHeader = !this.$route.query.header;
        if (this.$route.query.header.toLowerCase() === 'false') {
          hideHeader = true;
        }
      }
    } else if (this.$route.path === '/operation') {
      if(this.$route?.query?.memberflagship==='true'){
        hideHeader =false;
      }else{
        hideHeader = true;
      }
    }else if(this.$route.path.includes('/daily-checkin')){
      let userAgent='';
      if(this?.$ssrContext?.req&&this.$ssrContext.req.headers['user-agent']){
        userAgent=this.$ssrContext.req.headers['user-agent'].toLowerCase();
      }else{
        try{
          userAgent=navigator.userAgent.toLowerCase();
        }catch{};
      }
      if(userAgent.includes('miniprogram')){
        hideHeader=true;
      }
    }
    return {
      hideHeader,
      keepAliveProps: {
        include:(isClient)?['Plp', 'HomeIndex', 'CatelogMobile']:[]
      },
      size: 'normal',
      detailHeader: false,
      // needAnimationStyle: {
      //   position: 'relative',
      //   top: '124px',
      // },
      isMobile: true,
      onscroll: false,
      subtitleByType: this.$route.query?.appType === 'bos'
        ? '潮流装备购物平台'
        : 'adidas 官方潮流尖货社区',
      titleByType: this.$route.query?.appType === 'bos'
        ? 'adidas APP'
        : 'CONFIRMED',
      iconType: this.$route.query?.appType === 'confirmed'
        ? 'icon-confirmed'
        : 'icon-a-32_icon_performance',
      fsDeeplink: this.$route.query?.appType === 'bos',
      cfmDeeplink: this.$route.query?.appType === 'confirmed',
      showDeeplinkBar: false,
      barDeeplink:""
    };
  },
  computed: {
    isLuckyDraw() {
      return this.$route.path.toLowerCase().includes('/lucky-draw');
    },
    showInCategory() {
      if (
        this.$route.path === '/category' ||
        this.$route.path === '/catalog/catalog-mobile' ||
        this.$route.path.includes('/OR/')
      ) {
        return true;
      } else {
        return false;
      }
    },
    pcShowFooterPageList() {
      const returnObject = {
        showFlag: false,
        size: 'normal',
        showInMobile: false,
        headerIsCeiling: false
      };
      // 如果需要增加精简header的路径, 需要同步在libMask里增加
      const pcShowFooterPageList = [
        { title: '支付完成页', path: '/checkout/pay-success' },
        { title: '结算页', path: '/checkout', size: 'small' },
        { title: '退换货详情', path: '/return/detail', headerIsCeiling: true },
        {
          title: '退换货详情',
          path: '/exchange/detail',
          headerIsCeiling: true
        },
        { title: '订单', path: '/order/detail', headerIsCeiling: true },
        {
          title: '仅退款详情',
          path: '/refund-only/detail',
          headerIsCeiling: true
        }
      ];
      const currentPath = this.$route.path;

      // 匹配到之后就不要再运行后面的代码了
      let matched = false;
      pcShowFooterPageList.forEach((item) => {
        if (
          !matched &&
          (currentPath === item.path ||
            currentPath.startsWith(`${item.path}/`) ||
            currentPath === '/')
        ) {
          matched = true;
          if (item.path !== '/order/detail') {
            returnObject.showFlag = true;
            if (item.size === 'small' && currentPath !== '/') {
              returnObject.size = 'small';
            }
          }
          if (currentPath !== '/') {
            if (item.headerIsCeiling) {
              returnObject.headerIsCeiling = true;
            }
          }
          return {};
        }
      });
      return returnObject;
    },
    needHeaderAnimation() {
      const includePath = ['/', '/cart', '/my-account'];
      const currentPath = this.$route.path;
      let needs = false;
      includePath.forEach((item) => {
        if (
          currentPath === '/' ||
          currentPath.startsWith(`${item}/`) ||
          currentPath === item
        ) {
          needs = true;
        }
      });
      return process.client && needs;
    }
  },
  watch: {
    $route: {
      handler() {
        if (
          this.$route.path === '/category' ||
          this.$route.path.startsWith('/plp') ||
          this.$route.path === '/pdp'
        ) {
          this.keepAliveProps = {
            include: ['Plp', 'HomeIndex', 'CatelogMobile']
          };
        } else {
          this.keepAliveProps = {
            include: ['Plp', 'CatelogMobile']
          };
        }
      }
    },
    showInCategory(val) {
      console.log('showInCategory', val);
    },
    showDeeplinkBar(val) {
      val&&trackSmartBannerView(window.location.href);
    }
  },
  mounted() {
    this.initDeeplinkBar();
    this.isMobile = window.innerWidth < 720;
    window.addEventListener('resize', this.handleResize);
    // eslint-disable-next-line no-console
    const urlpara=new URL(location.href);
    // console.log("setAdTrack",urlpara.searchParams.get('irclickid'));
    if (urlpara.searchParams.get('irclickid')) {
      this.setAdTrack();
    }
    if (this.isMobile && this.$route.query.VisibleFlag) {
      // console.log('检测到了刷新的时候当前路由是否包含有特殊标识');
      const query = JSON.parse(JSON.stringify(this.$route.query));
      Vue.delete(query, 'VisibleFlag');
      // 刷新/跳转路由的重定向
      this.$router.push({
        query
      });
    }
    this.$store.dispatch('login/getLoginImg',{ctx: this});
    this.$store.dispatch('serverTime/getNowTime',{ctx: this});
    initCS(this);
    if (this.$route?.query?.schemeLink) {
      const onScroll = (() => {
        return () => {
          const { scrollTop } = this.$utils.device.getScrollTop();
          const startY = scrollTop;
          if(startY===0){
            if(this.onscroll){
              typeof(this.onscroll)!=='boolean'&&window.clearTimeout(this.scroll);
              this.onscroll = false;
              return;
            }
          }
          // const scrollEmitter = 64 + 88;
          // if (startY >= scrollEmitter) {
          //   this.toggleDeeplinkBar = true;
          // } else {
          //   this.toggleDeeplinkBar = false;
          // }
          // if (scrollTop < 2) {
          //   this.toggleDeeplinkBar = false;
          // }
          this.onscroll = true;
          window.clearTimeout(this.scroll);
          this.scroll = setTimeout(() => {
            this.onscroll = false;
          }, 3000);
        };
      })();
      this.$on(
        'hook:beforeDestroy',
        throttle(this.$utils.device.onPageScroll(onScroll), 300)
      );
    }

  },
  methods: {
    initDeeplinkBar() {
      this.iconType = this.$route.query?.appType === 'confirmed'
        ? 'icon-confirmed'
        : 'icon-a-32_icon_performance';
      this.fsDeeplink = this.$route.query?.appType === 'bos';
      this.cfmDeeplink = this.$route.query?.appType === 'confirmed';
      this.subtitleByType = this.$route.query?.appType === 'bos'
          ? '潮流装备购物平台'
          : 'adidas 官方潮流尖货社区';
      this.titleByType = this.$route.query?.appType === 'bos'
          ? 'adidas APP'
          : 'CONFIRMED';
      this.showDeeplinkBar = this.$route.query?.schemeLink;
      this.barDeeplink=this.$route.query?.schemeLink;
    },
    handleResize() {
      this.isMobile = window.innerWidth < 720;
    },
    // affiliate-设置广告点击进来的埋点，同步信息至ECP
    async setAdTrack() {
      if (isClient && window.localStorage) {
        let serverTime = null;
        // // 如果未登录获取服务器时间
        // 如果登录了请将  adTrackDate 设置为 null, click-id上传ecp时adTrackDate为null ecp会去取系统时间
        if (!TokenUtils.getToken()) {
          try{
            await getServerTime(this.$axios).then((resp) => {
              serverTime = resp;
            });
            // 这里转化为北京时间，时区取东八区，
            const timeZone = 8;
            // 计算偏移UTC的时间
            const offsetTime = new Date().getTimezoneOffset() * 60000;
            serverTime =
              serverTime &&
              Dayjs(
                new Date(serverTime + 3600000 * timeZone + offsetTime)
              ).format();
            // 这里手动调整末尾时区为东八区
            serverTime = serverTime
              .substr(0, serverTime.lastIndexOf('+'))
              .concat('+08:00');
          }catch(error){
            console.log("getServerTime error",error);
          }
        }
         // 设置广告进入的链接地址
        window.localStorage.setItem('adTrackUrl', window.location.href);
        // 设置广告链接中中clickId参数
        const urlpara=new URL(location.href);
        const irclickid=urlpara.searchParams.get('irclickid');
        irclickid&&window.localStorage.setItem(
          'adTrackClickId',
          // this.$route.query.irclickid
          encodeURIComponent(irclickid)
        );
        console.log('adTrackDate', serverTime);
        // 设置点击广告链接进入的时间
        window.localStorage.setItem('adTrackDate', serverTime);
        // window.localStorage.setItem("adTrackAlreadySynced", "0"); // 设置同步状态为未同步
      }
      if (TokenUtils.getToken()) {
        syncAdTrack(this.$axios);
      }
    },
    handleClickDeeplink() {
      // const { schemeLink } = getUrlQuery(location.href);
      if(this.barDeeplink){
        location.href = decodeURIComponent(this.barDeeplink);
        trackSmartBannerClick(window.location.href, this.barDeeplink);
      }
    }
  }
};
