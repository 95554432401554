import { Context } from '@nuxt/types/app/index';
import { AxiosStatic, AxiosRequestConfig } from 'axios';
import * as jwt from 'jsonwebtoken';
import Vue from 'vue';
import qs from 'qs';
import Cookies from 'js-cookie';
import { AppLogin } from "./appLogin";
import { isClient, TokenUtils,detectOS } from '~/utilities/helper/SystemHelper';
import {sensorsSetPublicProps,getLocatDistinctIds} from '~/utilities/vendors/sensors';
import {decodeURIComponentSafe} from '~/utilities/helper/UIHelper';

declare let wx: any;
// utils 使用说明
export interface IUtils {
  // 当前环境渠道
  channel: string;
  // 是否小程序(or环境也是true)
  IsWMS: boolean
  // 是否or环境
  IsOR: boolean;
  // 是否adiclub环境
  IsAdiclub: boolean;
  // 是否ios
  isIos: boolean;
  // 是否Android
  isAndroid: boolean;
  // 是否BOSAPP
  IsAPP: boolean;
  // 是否手机ios的Safari浏览器
  isIosSafari: boolean;
  // 是否是微信浏览器
  isWeixin: boolean;
  // 当前环境是否无法登录
  unablelogin: boolean;
  // 是否客户端
  isClient: boolean;

  // com的s3 config
  // staticConfig: {
  //   "backgroundColor": {
  //     "id": number,
  //     "mainBackgroundColor": string,
  //     "endBackgroundColor": string
  //   }[],
  //   "backgroundImage": {
  //     "id": number,
  //     "mainBackgroundImage": string,
  //     "popupBackgroundImage": string,
  //     "ticketEmptyContinuous": string,
  //     "ticketActiveContinuous": string,
  //     "ticketEmptyTotal": string,
  //     "ticketActiveTotal": string
  //   }[]
  // };

  /**
   * 获取当前 token
   * 自动根据当前环境获取 Token
   */
  getToken(): string | null;
  /**
   * 是否会员登录
   * @returns true 是会员
   */
  isMemberLogin(): boolean
  /*
   * 是否员工
   */
  getEmployeeId(): boolean
  /**
   * 是否非会员登录
   */
  isNonMemberLogin(): boolean
  /**
   * 获取特殊身份
   * @returns
   *  isMedical: 是否医护
   *  isCampus: 是否学生
   *  isPartnerShip:是否合作伙伴
   *  isVolunteer:是否志愿者
   */
  getIdentity(): {
    isMedical: boolean;
    isCampus: boolean;
    isPartnerShip: boolean;
    isVolunteer: boolean;
  }
  /**
   * 更新最新token
   * @returns
   */
  updataTokenAsync(): Promise<boolean | void>

  /**
   * 获取.com s3的common config json配置
   * @returns
   */
  getS3CommonConfig(): Promise<any>
  /**
   * 初始app
   */
  initApp(): Promise<void>
  /**
   * 调用环境的登录窗口
   * 特别注意!!!!!!!!!!:
   * 这里 返回true 只代表登录操作成功了, 用户可能登录了其他身份 如切换为非会员登录并且登录成功了 这里也会返回TRue
   * 所以登录成功后 一定要通过 isMemberLogin isNonMemberLogin 等方法判断登录身份是否符合你的要求
   * @returns
   */
  loginByEnvAsync(param: { redirectUrl: string, memberOnly: boolean }): Promise<Boolean>
  /**
   * 退出登录,app wms 去对应界面
   */
  loginOutByEnvAsync(): Promise<void>
  /**
   * 跳到当前环境的对应界面
   */
  jumpByEnvAsync(path: 'profile'): Promise<void>
  /**
   * 获取服务器时间
  */
  getServerTimeAsync(): Promise<Date>

  /**
   * 立即获取服务器时间
   */
  getServerTime(): Date | void;

  /**
   * 更新sensors全局登录属性 
   * */
  updateSensors():void;
}
export interface iVue extends Vue {
  [key: string]: any;
}
export interface iCtx extends Context {
  $config: {
    ECP_API_PRIVATE_HOST: string;
    ECP_API_HOST: string;
    ANA_API_HOST: string;
    S3_HOST: string;
    S3_COM_HOST: string;
    CRM_API_HOST: string,
    OR_CHECKIN_TEMPLATE_ID: string,
    WMS_CHECKIN_TEMPLATE_ID: string,
    [key: string]: any;
    ABTEST_URL: string
  };
  $route: Vue['$route'];
  $router: Vue['$router'];
  $store?: Vue['$store'];
  store: Vue['$store'];
  $login: (v: any) => void;
  $dialog: (v: any) => void;
  $Toast: (v: any) => void;

  $aloading: { show: () => void, hide: () => void };
}
interface isAxiosRequestConfig extends AxiosRequestConfig {
  noForceLogin: boolean;
  sourceExclusion: boolean;
  sourceToken: boolean;
}

interface IRefreshToken {
  "access_token": string
  "expires_in": number
  "not-before-policy": number
  "refresh_expires_in": number
  "refresh_token": string
  "scope": string
  "session_state": string
  "token_type": string
}
type iMiniProgramTerminal='WMS'|"OR"|"ADICLUB"|"COM"

export class BasicUtils implements IUtils {
  protected axios: AxiosStatic;
  // 小写userAgent
  protected userAgent: string = '';
  // 是否微信
  public IsWMS: boolean = false;
  public IsOR: boolean = false;
  public IsAdiclub: boolean = false;
  public shopCode: string | null = null;
  // 是否ios
  public isIos: boolean = false;
  public isWeixin: boolean = false;
  public isAndroid: boolean = false;
  public IsAPP: boolean = false;
  public isIosSafari: boolean = false;
  public isWeiXinBrowser: boolean = false;
  public unablelogin: boolean = false;
  public isClient: boolean = isClient as boolean;
  public channel = "COM";
  protected extranetHost: string;
  protected intranetHost: string;
  protected S3Host: string;
  protected S3_COM_HOST: string;
  protected ECP_API_HOST: string;
  protected ABTEST_URL: string;
  protected ANAHost: string;
  protected CRMHost: string;
  public basicAppLogin: AppLogin | undefined;
  public config: isAxiosRequestConfig = {
    noForceLogin: true,
    sourceExclusion: true,
    sourceToken: false,
    headers: {
      'x-source': 'COM'
    }
  } as isAxiosRequestConfig;

  /**
   * 请求域名
   */
  get host() {
    if (isClient || process.env.RUNTIME_ENV === 'dev') {
      return this.extranetHost;
    } else {
      return this.intranetHost;
    }
  }

  constructor(protected ctx: iCtx) {
    this.extranetHost = ctx.$config.ECP_API_HOST;
    this.intranetHost = ctx.$config.ECP_API_PRIVATE_HOST;

    this.S3Host = ctx.$config.S3_HOST;
    this.ANAHost = ctx.$config.ANA_API_HOST;
    this.axios = ctx.$axios;
    this.CRMHost = ctx.$config.CRM_API_HOST;
    this.S3_COM_HOST = ctx.$config.S3_COM_HOST;
    this.ECP_API_HOST = ctx.$config.ECP_API_HOST;
    this.ABTEST_URL = ctx.$config.ABTEST_URL;
    if (this.ctx.req && 'user-agent' in this.ctx.req.headers) {
      this.userAgent = (this.ctx.req.headers['user-agent'] as string).toLowerCase();
    } else if (isClient) {
      this.userAgent = navigator.userAgent.toLowerCase();
    }
    // 调试 改userAgent
    // this.userAgent="";

    this.isIos = !!this.userAgent.match(/macintosh|mac os x/i);
    this.isWeixin = !!this.userAgent.match(/micromessenger/i);
    this.isAndroid = this.userAgent.includes('android') || this.userAgent.includes('adr');
    //  默认是COM
    this.config.headers['x-source'] = this.channel = 'COM';
    if (this.userAgent.includes('adidasapp')) {
      this.IsAPP = true;
      this.config.headers['x-source'] = this.channel = 'APP';
    }else{
      const terminal = this.getTerminal();
      if(terminal==="WMS"){
        this.IsWMS = true;
        this.config.headers['x-source'] = this.channel = 'WMS';
        this.updateSensors();
      }else if(terminal === "OR"){
        let shopCode:string|null =( ctx.$route||ctx.route)?.query?.shopCode as string|null;
        shopCode = typeof (shopCode) === "string" ? shopCode : null;
        if(isClient && !shopCode){
          shopCode=sessionStorage.getItem('shopCode');
        }
        if (shopCode) {
          this.IsOR = true;
          this.shopCode=shopCode as string;
          this.config.headers['x-source'] = this.channel = 'OR';
          this.config.headers['x-shop-code'] = shopCode;
          isClient && sessionStorage.setItem('shopCode',this.shopCode);
          this.updateSensors();
        } else {
          console.error('OR环境缺失shopCode参数');
        }
      }else if(terminal==="ADICLUB"){
        this.IsAdiclub = true;
        this.config.headers['x-source'] = this.channel = 'ADICLUB';
        this.updateSensors();
      }
    }
    if(isClient){
      console.log('channel',this.channel);
    };
    this.isWeiXinBrowser = this.userAgent.includes('micromessenger');
    if (this.IsAPP) {
      if (this.userAgent.includes('iphone')) {
        const matchs = /adidasapp\/([\d\\.]+)/g.exec(this.userAgent);
        if (matchs && matchs.length >= 2) {
          const version = matchs[1];
          this.unablelogin = version === '1.0';
        }
      }
    }
    const ua = this.userAgent;
    this.isIosSafari =
      ua.includes('applewebkit') &&
      ua.includes('safari') &&
      !ua.includes('android') &&
      !ua.includes('linux') &&
      !ua.includes('crios') &&
      !ua.includes('chrome') &&
      !ua.includes('browser') &&
      !ua.includes('ios');
  }

  protected getTerminal():iMiniProgramTerminal{
    const ctx=this.ctx;
    if(
      (ctx?.$route?.path && ctx.$route.path.toLowerCase().indexOf('/ac/') === 0) || (ctx?.route?.fullPath && ctx?.route?.fullPath.toLowerCase().indexOf('/ac/') === 0)
    ) {
      return "ADICLUB";
    }
    if(
        (ctx?.$route?.path && ctx.$route.path.toLowerCase().indexOf('/or/') === 0) || 
        (ctx?.route?.fullPath && ctx?.route?.fullPath.toLowerCase().indexOf('/or/') === 0)
      ){
        return 'OR';
    }
    if(this.userAgent.includes('miniprogram')){
      const terminalName="mpTerminal";
      const terminal= ( ctx.$route||ctx.route)?.query?.terminal as (string|null);
      let terminalValue:iMiniProgramTerminal|null=null;

      if(!terminal){
        const miniProgramTerminal=isClient && sessionStorage.getItem(terminalName);
        if(miniProgramTerminal && ['OR',"ADICLUB","WMS"].includes(miniProgramTerminal)){
          return miniProgramTerminal as iMiniProgramTerminal;
        }
        // 么提供任何terminal信息 默认是WMS
        terminalValue = "WMS";
      }else if(terminal.toLowerCase()==='or'){
        terminalValue= 'OR';
      }else if(terminal.toLowerCase()==='adiclub'){
        terminalValue = "ADICLUB";
      }else if(terminal.toLowerCase()==='wms'){
        terminalValue = "WMS";
      }
      if(terminalValue){
        isClient&&sessionStorage.setItem(terminalName,terminalValue);
        return terminalValue;
      }
    }
    return "COM";
  }

  getToken(): string | null {
    let token: string | null = null;
    if (this.IsWMS || this.IsOR || this.IsAdiclub) {
      return TokenUtils.getToken(false, '', true);
    } else if (this.IsAPP) {
      token = TokenUtils.getToken(false, "", false, true);
    } else {
      token = TokenUtils.getToken(false, "", false, true);
    }
    if (token) {
      return token.replace('bearer ', '');
    } else {
      return null;
    }
  }

  getUserID(): string | null {
    const token=this.getToken();
    if (!token) {
      return null;
    }
    // eslint-disable-next-line camelcase
    const memberInfo = jwt.decode(token) as { user_id: string };
    return memberInfo.user_id;
  }

  /**
   * 是否会员登录
   * @returns
   */
  isMemberLogin(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    // eslint-disable-next-line camelcase
    const memberInfo = jwt.decode(token) as { member_group: string };
    return memberInfo?.member_group === '11';
  }
  
  /**
   * 是否非会员
   */
  isNonMemberLogin(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    // eslint-disable-next-line camelcase
    const memberInfo = jwt.decode(token) as { member_group: string };
    return memberInfo?.member_group === '91';
  }

  /*
   * 是否员工
   */
  getEmployeeId(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    // eslint-disable-next-line camelcase
    const memberInfo = jwt.decode(token) as { member_group: string };
    return memberInfo?.member_group === '31';
  }

  /*
   * 登录的MemberId
   */
  getMemberId(): string | void {
    const token = this.getToken();
    if (!token) {
      return;
    }
    // eslint-disable-next-line camelcase
    const memberInfo = jwt.decode(token) as { member_id?: string };
    return memberInfo?.member_id;
  }

  /**
   * 获取特殊身份
   * @returns
   *  isMedical: 是否医护
   *  isCampus: 是否学生
   *  isPartnerShip:是否合作伙伴
   *  isVolunteer: 是否志愿者
   */
  getIdentity(): {
    isMedical: boolean;
    isCampus: boolean;
    isPartnerShip: boolean;
    isVolunteer: boolean;
  } {
    const token = this.getToken();
    const identity = {
      isMedical: false,
      isCampus: false,
      isPartnerShip: false,
      isVolunteer: false
    };
    if (!token) {
      return identity;
    }
    const memberInfo = jwt.decode(token) as { role?: string[] };
    console.log('memberInfo', memberInfo);
    if (memberInfo?.role && memberInfo?.role instanceof Array) {

      // eslint-disable-next-line no-unreachable-loop
      for (const type of memberInfo.role) {
        if (type === 'MEDICAL') {
          identity.isMedical = true;
        } else if (type === 'PARTNERSHIP-CAMPUS') {
          identity.isCampus = true;
        } else if (type.includes('PARTNERSHIP-SHENZVOLUNTEER')) {
          console.log('identity', identity);
          identity.isVolunteer = true;
        } else if (type.includes('PARTNERSHIP')) {
          identity.isPartnerShip = true;
        }
        if (type) {
          return identity;
        }
      }
    }
    return identity;
  }

  /**
   * 更新最新token
   * @returns
   */
  async updataTokenAsync(): Promise<boolean> {
    if (!this.isClient || this.IsWMS || this.IsOR || this.IsAdiclub || this.IsAPP) {
      // 当前环境无法更新token
      return false;
    }
    const refreshToken = TokenUtils.getRefreshToken();
    if (!refreshToken) {
      return false;
    };
    let response: {
      data: IRefreshToken;
    };
    try {
      response = await this.axios.post<any, { data: IRefreshToken }>(
        `${this.ANAHost}/v1/users/refreshToken`,
        qs.stringify({ refreshToken })
      );
    } catch {
      return false;
    }

    if (response && response.data) {
      const isKeep = !!Number(Cookies.get('isKeep') || '0');
      TokenUtils.setToken(
        {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
          isKeep
        },
        this.axios,
        true
      );
      return true;
    }
    return false;
  }

  /**
   * 获取.com s3的common config json配置
   * @returns
   */
  async getS3CommonConfig(): Promise<any> {
    let response: any = {};
    try {
      response = await this.axios.get(
        `${this.S3_COM_HOST}/static/commonConfig.json`
      );
    } catch {
      return {};
    }

    return response;
  }

  /**
   * 获取.com s3的daily checkin 皮肤config json配置
   * @returns
   */
  async getDailyCheckinThemeConfig(): Promise<any> {
    let response: any = {};
    try {
      response = await this.axios.get(
        `${this.S3_COM_HOST}/static/dailyCheckinThemeConfig.json`
      );
    } catch {
      return {};
    }

    return response;
  }

  protected sensorsUserid: string|null = null;
  updateSensors(){
    
    if(this.getUserID()!==this.sensorsUserid){
      let URLDecoded=window.location.href;
      try{
        URLDecoded= decodeURI(decodeURIComponentSafe(window.location.href));
      }catch{
        URLDecoded=decodeURI(window.location.href);
      };
      const isLogin=!!this.getToken();
      const memberId=this.getMemberId();
      const isMember=this.isMemberLogin();
      const distinctId=getLocatDistinctIds();
      const publicParams = {
        URL_decoded: URLDecoded,
        com_cookie_id:isMember? memberId : distinctId,
        language: 'ZH',
        logged_in: isLogin ? 'TRUE' : 'FALSE',
        memberID: memberId || distinctId,
        page: window.location.pathname,
        user_agent: navigator.userAgent || '',
        os_type: detectOS(),
        referrer: document.referrer || '',
        is_login: isLogin ? 'TRUE' : 'FALSE'
      };
      sensorsSetPublicProps(publicParams);
      this.sensorsUserid=this.getUserID();
    }
  }

  /**
   * 初始app
   */
  public async initApp() {
    if (isClient && this.IsAPP && window.AdidasApp) {
      this.basicAppLogin = new AppLogin(window.AdidasApp);
      await this.basicAppLogin.getloginInfoAsync();
      this.updateSensors();
    } else {
      throw new Error("当前App环境 初始失败");
    }
  }

  /**
   * * 特别注意!!!!!!!!!!
   * 这里 返回true 只代表登录操作成功了, 用户可能登录了其他身份 如切换为非会员登录并且登录成功了 这里也会返回TRue
   * 所以登录成功后 一定要通过 isMemberLogin isNonMemberLogin 等方法判断登录身份是否符合你的要求
  * */
  async loginByEnvAsync(param: { redirectUrl: string, memberOnly: boolean, loginClp?: boolean, isJointMember?: boolean, joinType?: string, title?: string, desc?: string }): Promise<boolean> {
    if (this.IsAPP) {
      await this.initApp();
      if (this.basicAppLogin) {
        return await this.basicAppLogin.forceAppLoginAsync();
      } else {
        return false;
      }
    } else if (this.IsWMS || this.IsOR || this.IsAdiclub) {
      const urlPrar = new URL(param.redirectUrl);
      const shopCode = urlPrar.searchParams.get('shopCode');

      urlPrar.searchParams.delete('openId');
      urlPrar.searchParams.delete('idToken');
      urlPrar.searchParams.delete('unionId');
      urlPrar.searchParams.delete('anaToken');


      let WMSLoginPath: string;
      if (this.getToken() && !this.isMemberLogin()) {
        WMSLoginPath = `/pages/redirect/login-redirect?memberLoginOnly=true&redirectUrl=${encodeURIComponent(urlPrar.href)}`;
      } else {
        WMSLoginPath = `/pages/redirect/login-redirect?redirectUrl=${encodeURIComponent(urlPrar.href)}`;
      }
      const ACLoginPath = `/pages/crmPackage/pages/redirect/index?actionType=login&redirectUrl=${encodeURIComponent(urlPrar.href)}`;
      const ORLoginPath = `/pages/otherPackage/pages/redirect/login-redirect?shopCode=${shopCode}&redirectUrl=${encodeURIComponent(urlPrar.href)}`;
      const LoginPath = this.IsWMS && WMSLoginPath || this.IsOR && ORLoginPath || this.IsAdiclub && ACLoginPath;
      wx.miniProgram.redirectTo({
        url: LoginPath
      });
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
      return false;
    } else {
      const showClp = !((param?.loginClp === false));
      return new Promise<boolean>((resolve) => {
        // eslint-disable-next-line no-undef
        this.ctx.$login({
          show: true,
          firstStepProp: true,
          joinAdiclubProp: param.memberOnly,
          loginClp: showClp,
          isJointMember: param.isJointMember,
          joinType: param.joinType,
          titleImport: param.title,
          descImport: param.desc,
          on: {
            newLoginEnd:()=> {
              this.updateSensors();
              resolve(true);
            },
            close() {
              // 这个坑爹的方法会在 login前先运行
              resolve(false);
            }
          }
        });
      });
    }
  }

  async jumpByEnvAsync(path: 'profile'): Promise<void> {
    if (path !== 'profile') {
      throw new Error('未发现你选择的页面还未支持');
    }
    if (this.IsOR) {
      wx.miniProgram.switchTab({
        url: "/orwcrm/pages/my/index"
      });
    } else if (this.IsAdiclub) {
      console.log('占位符，等待接入adiclub my-account');
    } else if (this.IsWMS) {
      wx.miniProgram.switchTab({
        url: "/pages/tabs/profile"
      });
    } else if (this.IsAPP) {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
      window.location.href = "adidas://profile";
    } else {
      this.ctx.$router.push('/my-account');
    }
  }

  /**
   * 退出登录
   */
  async loginOutByEnvAsync(): Promise<void> {
    if (this.IsAPP || this.IsOR || this.IsWMS || this.IsAdiclub) {
      await this.jumpByEnvAsync('profile');
    } else {
      TokenUtils.logOut();
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    }
  }

  /**
   * 立刻获取服务器时间
   */
  getServerTime(): Date | void {
    return getServerTime(this.ctx);
  };

  /**
   * 获取服务器时间
   */
  getServerTimeAsync(): Promise<Date> {
    return getServerTimeAsync(this.ctx);
  }
}

export class PopoverUrl {
  protected initstate: boolean | null = null;
  /**
   *
   * @param vue vue 对象
   * @param name vue下对应属性 boolean类型
   * @param queryName url中对应的query 参数名
   * @param initstate 可选 vue下对应属性的初始化值,不传 按vue下对应属性属性值
   */
  constructor(
    protected vue: iVue,
    protected name: string,
    protected queryName: string
  ) {
    if (!isClient) {
      throw new Error('服务端无法运行');
    }
    if (!(name in vue) || typeof vue[name] !== 'boolean') {
      throw new Error('vue 属性不存在或者不为boolean类型');
    }
  }

  /**
   * @param initstate 初始值 可选
   */
  init(initstate?: boolean) {
    const urlPara = new URL(location.href);
    if (typeof initstate === 'boolean') {
      this.initstate = initstate;
      this.vue[this.name] = initstate;
    } else if (urlPara.searchParams.get(this.queryName) === 'true') {
      this.vue[this.name] = true;
    }
    this.seturl(this.vue[this.name]);
    window.addEventListener('popstate', this.popstate, false);
    this.vue.$watch(this.name, (value: boolean) => {
      console.log('popoverUrl', value);
      this.vue.$nextTick(() => {
        this.seturl(this.vue[this.name]);
      });
    });
  }

  protected popstate = () => {
    if (this.vue._isDestroyed) {
      window.removeEventListener('popstate', this.popstate, false);
      return;
    }
    const urlPara = new URL(location.href);
    if (!urlPara.searchParams.get(this.queryName)) {
      this.vue[this.name] = false;
    } else if (urlPara.searchParams.get(this.queryName)) {
      // 浏览器前进后退导致的 queryName=true, 询问是否可以打开
      if (typeof this.initstate === 'boolean') {
        if (!this.vue[this.name]) {
          const urlPara = new URL(location.href);
          urlPara.searchParams.delete(this.queryName);
          history.replaceState(null, document.title, urlPara.href);
        }
      } else {
        this.vue[this.name] = true;
        this.seturl(true);
      }
    }
  };

  /** 根据传入值设置url参数 */
  protected seturl(value: boolean) {
    const urlP = new URL(location.href);
    if (value) {
      if (urlP.searchParams.get(this.queryName) !== 'true') {
        urlP.searchParams.append(this.queryName, 'true');
        history.pushState(null, document.title, urlP.href);
      }
    } else if (urlP.searchParams.get(this.queryName) === 'true') {
      urlP.searchParams.delete(this.queryName);
      // 使用replace 取保关闭后不能通过前进 进入
      history.replaceState(null, document.title, urlP.href);
    }
  }
}
/**
* 是否为手机尺寸
* @returns
*/
export function isMobileWidth(): Boolean {
  return window.innerWidth < 720;
}

export function redirectTo(ctx:iCtx,url:string,win:Window=window):void {


  // 只允许 HTTPS 协议，并过滤危险字符
  // 使用正则表达式或其他方法来过滤不安全的字符或协议
  const sURL=sanitizedURL(ctx,url);
  win.location&&win.location.assign(sURL);
}
export function redirectToDeepLink(ctx:iCtx,url:string){
  const deepLink=sanitizedURLDepplink(ctx,url);
  if(deepLink){
    location.assign(deepLink);
  }
}
export function sanitizedURLDepplink(ctx:iCtx,url:string):string {
  let sURL = url.trim();
  if(/^adidas:\/\/[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=%]+$/i.test(sURL)){
    return sURL;
  }else if(sURL.startsWith('https://')){
    const urlPara=new URL(sURL);
    if(ctx.$config.SENSORS_URL.startsWith(urlPara.origin)){
      try{
        sURL=sanitizedURL(ctx,sURL);
      }catch(e){
        return '';
      }
      return sURL;
    }
  }
  return '';
}
export function sanitizedURL(ctx:iCtx,url:string):string{
  let sURL = url.trim();
  if(sURL.substring(0,1)==='/'){
    let hostName=ctx.$config.COM_HOST as string;
    if(hostName.endsWith('/')) {
      hostName=hostName.slice(0, -1);
    }
    sURL=hostName+sURL;
  }
  if( process.env.RUNTIME_ENV === 'dev'){
      if (!/^http:\/\/[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=%]+$/i.test(sURL)) {
        throw new Error('Invalid URL');
      }
    }else if (!/^https:\/\/[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=%]+$/i.test(sURL)) {
      throw new Error('Invalid URL');
    
  }
  return sURL;
}

export function getTime(ctx:iCtx):Date{
  const serverTime=getServerTime(ctx);
  return (serverTime) || new Date();
}

export function getServerTime(ctx:iCtx): Date | void {
  const store = (ctx.$store || ctx.store);
  const state = store.state as {
    dispatch(type: string, data: any): void,
    serverTime: {
      getTimePromise: Promise<void>,
      nowDateGap: null | number
    }
  };
  const nowDateGap = state.serverTime.nowDateGap as number;
  if (nowDateGap) {
    const nowTime = new Date().getTime();
    const now = new Date();
    now.setTime( nowTime + nowDateGap );
    return now;
  }
};

export async function getServerTimeAsync(ctx:iCtx): Promise<Date> {
  const store = (ctx.$store || ctx.store);
  const state = store.state as {
    dispatch(type: string, data: any): void,
    serverTime: {
      getTimePromise: Promise<void>,
      nowDateGap: null | number
    }
  };
  store.dispatch('serverTime/getNowTime', { ctx: ctx });
  await state.serverTime.getTimePromise;
  const nowDateGap = state.serverTime.nowDateGap as number;
  const nowTime = new Date().getTime();
  const now = new Date();
  now.setTime(nowTime + nowDateGap);
  return now;
}
