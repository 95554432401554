
  import deepcopy from 'deepcopy';
  import { getCurrentLink } from '../util';
  import { parseURL } from '../../../utilities/helper/urlHelper';
  import specialLinkMixin from './specialLink.mixins';

  export default {
    name: 'Hotzone',
    mixins: [specialLinkMixin],
    props: {
      options: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        // 是否为移动端
        isMobile: ''
      };
    },
    computed: {
      hotZoneList() {
        if (!process.client) return [];
        const mobImageHotspotList = deepcopy(this.options.mobImageHotspotList) || [];
        const pcImageHotspotList = deepcopy(this.options.pcImageHotspotList) || [];
        if (this.isMobile) {
          mobImageHotspotList.forEach((item) => {
            item.mobTop = item.upperLeftPercentageY;
            item.mobLeft = item.upperLeftPercentageX;
            item.mobHeight = item.lowerRightPercentageY - item.upperLeftPercentageY;
            item.mobWidth = item.lowerRightPercentageX - item.upperLeftPercentageX;
            item.style = {
              height: `${item.mobHeight}%`,
              width: `${item.mobWidth}%`,
              top: `${item.mobTop}%`,
              left: `${item.mobLeft}%`
            };
          });
          return mobImageHotspotList;
        } else {
          pcImageHotspotList.forEach((item) => {
            item.pcTop = item.upperLeftPercentageY;
            item.pcLeft = item.upperLeftPercentageX;
            item.pcHeight = item.lowerRightPercentageY - item.upperLeftPercentageY;
            item.pcWidth = item.lowerRightPercentageX - item.upperLeftPercentageX;
            item.style = {
              height: `${item.pcHeight}%`,
              width: `${item.pcWidth}%`,
              top: `${item.pcTop}%`,
              left: `${item.pcLeft}%`
            };
          });
          return pcImageHotspotList;
        }
      }
    },
    mounted() {
      this.identifyDevice();
      window.addEventListener('resize', this.identifyDevice);
    },
    destroyed() {
      window.removeEventListener('resize', this.identifyDevice);
    },
    methods: {
      handleHotZoneClick(item, key) {
        const options = {
          link: item.redirectLink,
          allLink: item.allLink,
          isContainHotZone: this.hotZoneList.length > 0,
          key
        };
        const curTerminalLink = this.options.allLink ? getCurrentLink(options.allLink) : options.link || '';
        if (!curTerminalLink) {
          return;
        }

        try {
          if (
            curTerminalLink.includes('/pages/product/list') ||
            curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
            curTerminalLink.includes('/pages/product/rich-list') ||
            curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
            curTerminalLink.includes('/pages/plp/index') ||
            curTerminalLink.includes('adidas://category') ||
            parseURL(curTerminalLink).path.startsWith('/plp') ||
            parseURL(curTerminalLink).path.startsWith('/splp')
          ) {
            this.$emit('trackHotZoneClick', {type: 'plp', item: options});

          } else if (
            curTerminalLink.includes('/pages/product/details') ||
            curTerminalLink.includes('adidas://product') ||
            curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
            parseURL(curTerminalLink).path === '/pdp'
          ) {
            this.$emit('trackHotZoneClick', {type: 'pdp', item: options});

          } else {
            this.$emit('trackHotZoneClick', {type: 'button', item: options});
          }
        } catch (error) {
          console.log('sensors track报错', error);
        }
        this.handleSpecialLinkClick(curTerminalLink, options);
      },
      identifyDevice() {
        this.isMobile = window.innerWidth < 720;
      }
    }
  };
