import { sensorsLogout } from '~/utilities/vendors/sensors';
import { clearCopher } from "~/utilities/helper/live800";
declare let AdidasApp: any;
let appToken: any;
let hasReq: boolean;
let noticeNum: number;

/*
 * @Description: 系统类相关函数
 * @Autor: Duan,JingLiang
 * @Date: 2021-09-06 14:17:26
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 */
const Cookies = require('js-cookie');
const jwt = require('jsonwebtoken');

/**
 * 判断是否处于客户端
 */
export const isClient: Boolean = (() =>
  typeof window !== 'undefined' || typeof document !== 'undefined')();

export function isLogin(reset: boolean = false): Boolean {
  // const Cookies = process.browser ? require('js-cookie') : undefined;
  // console.log(Cookies.get('token'))
  // return typeof Cookies.get('token') !== 'undefined';
  // 注意，目前不再需要手动判断是否已登录，报刘此函数只是为了防止报错，结果并不一定准确
  return !!TokenUtils.getToken(reset);
}

export function appToWebPage(action: string, data: string) {
  console.warn('执行appToWebPage');
  if (action === 'resp_login_info') {
    if (!data) {
      AdidasApp.webPageToApp("action_login", "");
      console.warn('调用AdidasApp方法唤起登录');
    } else {
      const obj = JSON.parse(data);
      const info = jwt.decode(obj?.token);
      appToken = info?.ana_token;
      isTokenInvalid(appToken);
      if (appToken) {
        // const memberInfo = jwt.decode(appToken);
        // 会员
        // if (memberInfo?.member_group === '11') {
        //   if (isClient && window.localStorage) {
        //     window.localStorage.setItem('accountPhone', memberInfo?.mobile);
        //   } else {
        //     Cookies.set('accountPhone', memberInfo?.mobile);
        //   }
        // }
        // 员工
        // if (memberInfo?.member_group === '31') {
        //   if (isClient && window.localStorage) {
        //     window.localStorage.setItem('employeeId', memberInfo?.employee_id);
        //   } else {
        //     Cookies.set('employeeId', memberInfo?.employee_id);
        //   }
        // }
        // 非会员
        // if (memberInfo?.member_group === '91') {
        //   if (isClient && window.localStorage) {
        //     window.localStorage.setItem('nonmemberTel', memberInfo?.mobile);
        //   } else {
        //     Cookies.set('nonmemberTel', memberInfo?.mobile);
        //   }
        // }
      } else {
        if (noticeNum > 0) return;
        (window as any).dialog({
          title: '当前adidas app版本过低，请更新至最新版本',
          confirmButton: true,
          confirmButtonText: '我知道了',
          on: {
            confirm: ({ close }: any) => {
              close();
            }
          }
        });
        noticeNum = 1;
      }
    }
  }
}

export const logOut = () => {
  // const Cookies = process.browser ? require('js-cookie') : undefined;
  TokenUtils.logOut();
};

// eslint-disable-next-line no-unused-vars
enum channel{
  // eslint-disable-next-line no-unused-vars
  OR='OR',
  // eslint-disable-next-line no-unused-vars
  AC='AC',
  // eslint-disable-next-line no-unused-vars
  WMS='WMS',
  // eslint-disable-next-line no-unused-vars
  APP='APP',
  // eslint-disable-next-line no-unused-vars
  COM='COM'
}
function getChannel():channel{
  const href = isClient ? window.location.href : '';
  const userAgent = isClient ? navigator.userAgent.toLowerCase() : '';
  if(href.toLowerCase().includes('/or/')){
    return channel.OR;
  }else if(href.toLowerCase().includes('/ac/')){
    return channel.AC;
  }else if(userAgent.includes('miniprogram')){
    return channel.WMS;
  }else if(userAgent.includes('adidasapp')){
    return channel.APP;
  }else{
    return channel.COM;
  }
}
/**
 * Token相关操作对象
 */
export const TokenUtils = {
  tokenName: 'token',
  refreshTokenName: 'refreshToken',

  /**
   * @Description: 设置token
   * @Autor: 姚润发
   * @Date: 2021-09-14
   * @param paramsObj 参数对象，包含 当前token和 refreshToken, 是否保持登陆态isKeep
   * @param ctx  当前环境（this.$axios或 $axios
   * @param notSynFlag affiliate不同步标识
   */
  // eslint-disable-next-line camelcase
  setToken(paramsObj: { access_token: string, refresh_token: string, isKeep: boolean, isNew?: boolean }, ctx: { post: (arg0: string, arg1: { clickId: string | null; createTime: string | null; url: string | null; }) => Promise<{ failed: any; }>; } | undefined, notSynFlag: boolean | undefined): void {
    // CRFC-6187 隔离token 防止其他页面使用
    const href = isClient ? window.location.href : '';
    const atChannel=getChannel();
    if(atChannel!==channel.COM && (href.includes('/right2copy')||href.includes('/nonmember-right2copy'))){
      sessionStorage.setItem(`${atChannel}-${this.tokenName}`,paramsObj.access_token);
      return;
    }
    
    const userAgent = isClient ? navigator.userAgent.toLowerCase() : '';
    if (userAgent.includes('miniprogram')) {
      sessionStorage.setItem(this.tokenName,paramsObj.access_token);
    }else if (paramsObj.isKeep) {
      Cookies.set(this.tokenName, paramsObj.access_token, { expires: 365 });
      Cookies.set(this.refreshTokenName, paramsObj.refresh_token, { expires: 365 });
      Cookies.set('isKeep', Number(paramsObj.isKeep).toString(), { expires: 365 });
    } else {
      
      Cookies.set(this.tokenName, paramsObj.access_token);
      Cookies.set(this.refreshTokenName, paramsObj.refresh_token);
      Cookies.set('isKeep', Number(paramsObj.isKeep).toString());
    }
    Cookies.set("isNew", (paramsObj.isNew) ? "1" : "0", { expires: 1 });
    clearCopher();
    // affiliate-设置广告点击进来的埋点，同步信息至ECP
    if (window.localStorage && window.localStorage.getItem("adTrackClickId") && !notSynFlag)
      syncAdTrack(ctx);
  },

  /**
   * 获取url参数值
   * @Description: 获取url参数值
   * @Autor: 胡阳
   * @Date: 2021-09-14
   */
  getQueryValue(queryName: string) {
    if (!isClient) return null;
    const href = window.location.href;
    const query = href.split("?")[1];
    if (query) {
      const querys = query.split("&");
      for (let i = 0; i < querys.length; i++) {
        const pair = querys[i].split("=");
        if (pair[0] === queryName) { return pair[1]; }
      }
      return null;
    } else {
      return null;
    }
  },

  /**
   * 获取App token
   * @Description: 获取token
   * @Autor: milo
   * @Date: 2022-06-01
   */
  getAppToken() {
    if (appToken) {
      return `bearer ${appToken}`;
    } else {
      return null;
    }
  },
  getIsolatedToken(pure: boolean = false){
    const href = isClient ? window.location.href : '';
    const prefix = pure ? '' : 'bearer ';
    const atChannel=getChannel();
    // 会员与非会员导出隔离
    if(atChannel!==channel.COM&&(href.includes('/right2copy')||href.includes('/nonmember-right2copy'))){
      const token=sessionStorage.getItem(`${atChannel}-${this.tokenName}`);
      if(token){
        return `${prefix}${token}`;
      }else{
        return null;
      }
    }
    return null;
  },
  /**
   * 获取token
   * @Description: 获取token
   * @Autor: 姚润发
   * @Date: 2021-09-14
   */
  getToken(reset: boolean = false, url: string = '', pure: boolean = false, isBasicUtils: boolean = false): string | null {
    const userAgent = isClient ? navigator.userAgent.toLowerCase() : '';
    const href = isClient ? window.location.href : '';
    const prefix = pure ? '' : 'bearer ';
    
    if (userAgent.includes('miniprogram')) {
      const anaToken = this.getQueryValue('anaToken');
      if (anaToken) {
        return `${prefix}${anaToken}`;
      } else if (sessionStorage.getItem(this.tokenName)) {
        return `${prefix}${sessionStorage.getItem(this.tokenName)}`;
      } else {
        return null;
      }
    } else if (
      userAgent.includes('adidasapp') &&
      (isBasicUtils ||
        href.includes('d_n') ||
        href.toLowerCase().includes('identity') ||
        href.toLowerCase().includes('lucky-draw') ||
        href.toLowerCase().includes('loop') ||
        href.toLowerCase().includes('daily-checkin') ||
        href.toLowerCase().includes('joint-membership') ||
        href.toLowerCase().includes('/clp') ||
        href.toLowerCase().includes('/right2copy') ||
        href.toLowerCase().includes('/nonmember-right2copy') ||
        url.includes('recommend-products') || url.includes('personalized-product-list') || url.includes('tc/sign-info'))) {
      hasReq = !reset;
      if (!hasReq) {
        hasReq = true;
        noticeNum = 0;
        // h5页面调用AdidasApp请求token
        AdidasApp.webPageToApp("req_login_info", "");
        console.warn('调用AdidasApp请求token');
      }
      if (appToken) {
        return `${prefix}${appToken}`;
      } else {
        return null;
      }
    } else {
      const token = Cookies.get(this.tokenName);
      if (token) {
        return `${prefix}${token}`;
      } else {
        return null;
      }
    }
  },

  /**
   * @Description: 获取refreshToken
   * @Autor: 李博
   * @Date: 2021-09-22
   */
  getRefreshToken() {
    const href = isClient ? window.location.href : '';
    const atChannel=getChannel();
    if(atChannel===channel.COM||( (href.includes('/right2copy')||href.includes('/nonmember-right2copy')))){
      const token = Cookies.get(this.refreshTokenName);
      if (token) {
        return token;
      } else {
        return null;
      }
    }
    return null;
  },

  /**
   * 退出登录
   * @Description: 退出登录
   * @Autor: 姚润发
   * @Date: 2021-09-14
   */
  logOut(): void {
    // const Cookies = process.browser ? require('js-cookie') : undefined;
    Cookies.remove(this.tokenName);
    Cookies.remove(this.refreshTokenName);
    Cookies.remove('isKeep');
    sensorsLogout();
    if (isClient && window.localStorage) {
      // 删除员工ID
      window.localStorage.removeItem('employeeId');
      // 删除非会员电话(nonmemberTel 已经不存在 但是需老用户仍然有~需要帮助删除)
      window.localStorage.removeItem('nonmemberTel');
      // 删除会员电话
      window.localStorage.removeItem('accountPhone');
      window.localStorage.removeItem('memberId');
    } else {
      // 删除员工ID
      Cookies.remove('employeeId');
      // 删除非会员电话(nonmemberTel 已经不存在 但是需老用户仍然有~需要帮助删除)
      Cookies.remove('nonmemberTel');
      // 删除会员电话
      Cookies.remove('accountPhone');
      Cookies.remove('memberId');
    }
    clearCopher();
  },

  getOriginToken(): string | null {
    let token = Cookies.get(this.tokenName);

    if (isClient && isWechatMini()) {
      token = this.getQueryValue('anaToken');
    }
    if (token) {
      return token;
    } else {
      return null;
    }
  },

  getWxOpenid(): string | null {
    return this.getQueryValue('openId');
  }
};
export const getMemberPhone = function ():string|null {
  let mobilephone;
  const token = TokenUtils.getToken(false,'',true);
  if(token){
    const info = jwt.decode(token);
    // 会员
    if(info?.member_group === '11'){
      mobilephone= info?.mobile;
    }
  }
  if (mobilephone) {
    return mobilephone;
  } else {
    return null;
  }
};
// 是否含有非会员手机号
export const getNonmemberTel = function () {
  let nonmemberTel;
  const token = TokenUtils.getToken(false,"",true);
  if(token){
    const info = jwt.decode(token);
    if(info?.member_group === '91'){
      nonmemberTel= info?.mobile;
    }
  }
  if (nonmemberTel) {
    return nonmemberTel;
  } else {
    return false;
  }
};
export const getEmployeeId= function ():string|null {
  const token = TokenUtils.getToken(false,'',true);
  if(token){
    const info = jwt.decode(token);
    // 会员
    if(info?.member_group === '31'){
      return  info?.employee_id;
    }
  }
  return null;
};

/*
 * 是否含有手机号
 */
export const getPhone = function () {
  const mobilephone=getMemberPhone()||getNonmemberTel();
  if (mobilephone) {
    return mobilephone;
  } else {
    return false;
  }
};
/**
 *
 * 判断是否是移动端
 */
export function isMobile(): boolean {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
}

/**
 *  判断当前系统
 */
export function detectOS(): string {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  if (isMobile()) {
    if (isTablet) {
      return 'Tablet';
    } else {
      return 'Mobile';
    }
  } else {
    // ipadOs 的userAgent 现在显示为mac, 所以还要通过设备的最大触摸点数来判断到底是pad还是pc
    if (/mac/.test(userAgent) && navigator.maxTouchPoints > 0) {
      return 'Tablet';
    }
    return 'PC';
  }
}

/**
 *  设置广告埋点同步至ECP
 */
export function syncAdTrack(this: any, ctx: { post: (arg0: string, arg1: { clickId: string | null; createTime: string | null; url: string | null; }) => Promise<{ failed: any; }>; } | undefined): Object {
  const param = {
    clickId: window.localStorage.getItem("adTrackClickId"),
    createTime: window.localStorage.getItem("adTrackDate"),
    url: window.localStorage.getItem("adTrackUrl")
  };
  // 当是已登录则不需传时间
  if (!param.createTime || param.createTime === "null" || param.createTime === "+08:00") {
    // @ts-ignore
    delete param.createTime;
    // 当不传createTime时 ecp会在click-id接口被调用时自动加上系统时间
  }
  let result = {};
  ctx && ctx.post('/o2vip/v1/customer/click-id', param).then(res => {
    result = res;
  });
  return result;
}

/**
 *  获取服务器时间
 */
export async function getServerTime(ctx: { get: (arg0: string) => any; }) {
  const result = await ctx.get('/system/time');
  if (result) {
    return result;
  }
}

/**
 *  验证token是否过期 true: token未失效 false：token失效
 */
export function isTokenInvalid(token: string) {
  const tokenInfo = jwt.decode(token || '');
  const nowTime = Number(String(new Date().getTime()).slice(0, 10));
  return tokenInfo && tokenInfo.exp > nowTime;
}

export function isWechatBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /MicroMessenger/i.test(userAgent);
}

export function isWechatMini() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /miniprogram/i.test(userAgent);
}

export function isBos() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /adidasapp/i.test(userAgent);
}

export function isConfirmed() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /confirmed/i.test(userAgent);
}

export function isOR() {
  return location.pathname.includes('/OR');
}

export function isAdiclub() {
  return location.pathname.includes('/AC');
}

const ua = isClient && (navigator.userAgent || navigator.vendor);
export const isAndroidSystem = /android/i.test(ua);
export const isIosSystem = /iPad|iPhone|iPod/.test(ua) && !(window as any).MSStream;

export function checkEmoji(content: string | [string]) {
  const reg = /^[\u4E00-\u9FA5a-zA-Z0-9\s`~!@#$%^&*()_\-+=·！￥…（）—<>?:"{}|,./;'\\\[\]《》？：“”，。、；‘’【】「」¥～｜]+$/;

  if (typeof content === 'string') {
    if (!content || content.length === 0) {
      return true;
    }

    return reg.test(content);
  } else if (Array.isArray(content) && content.length > 0) {

    return content.every((item) => {
      return !item || reg.test(item);
    });
  }
}
