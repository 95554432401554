import { isMobile } from './is-mobile';
import {
  disablePageScroll,
  enablePageScroll,
  getScrollTop,
  isHarmonyBrowser,
  onPageScroll,
  setScrollTop,
} from './onPageScroll';

export const DeviceService = {
  isMobile,
  onPageScroll,
  isHarmonyBrowser,
  getScrollTop,
  setScrollTop,
  enablePageScroll,
  disablePageScroll,
};
