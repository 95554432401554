/*
 * @Author: 胡阳
 * @Date: 2021-11-10 17:33:04
 * @Description: 退出员工账户插件入口文件
 */
import Logout from './Logout.vue';

export default {
  install(Vue) {
    const LogoutConstructor = Vue.extend(Logout);
    const logout = new LogoutConstructor();
    document.body.appendChild(logout.$mount().$el);
    Vue.prototype.$EmployeeLogout = logout;
  }
};
