/*
 * @Author: 胡阳
 * @Date: 2021-10-28 10:11:49
 * @Description: Search 埋点
 */
let tracker = {} as any;
if (process.client) {
  tracker = require('~/utilities/vendors/sensors').default;
}

function getPageTitle(path: string): string {
  if (path === '/') {
    return 'Homepage';
  } else {
    return path.split('/')[1].toUpperCase();
  }
}

// Search
export function trackSearchViewWeb(
  // 根据点击客服的页面取值 "ACCOUNT|LOGIN"/" SHOPPING CART|EMPTY CART"
  pageName: string,
  // 根据点击客服的页面取值 "ACCOUNT”/“SHOPPING CART”
  pageType: string,
  // 从哪个页面进行的搜素 PDP/Homepage/PLP….
  searchPosition: string,
  // 搜索关键词
  searchTerm: string,
  // 搜素类型 KEYWORDS RECOMMENDATION HISTORY
  searchType: string,
  // 结果产品数量
  totalResults: string,
  // 是否使用默认关键词进行搜索
  isDefaultSearch: boolean,
  // 搜索结果类型 CLP，PLP，No Result，H5
  searchResultType: string,
  // 是否为搜索关键词自动补全时的搜索结果
  isAutoCompleteUsed: boolean,
  // 用户输入的词
  beforeWord: string,
  // 点击的联想词
  autoComplateItem: string,
  suggestedSource: string
): void {
  try {
    tracker.track('Search_view_web', {
      // 根据点击客服的页面取值
      page_name: pageName,
      // 根据点击客服的页面取值
      page_type: pageType,
      // 从哪个页面进行的搜素
      search_position: searchPosition,
       // 搜索关键词
      search_term: searchTerm,
       // 搜素类型
      search_type: searchType,
       // 结果产品数量
      total_results: totalResults,
      // 是否使用默认关键词进行搜索
      is_default_search: isDefaultSearch,
      // 搜索结果类型
      search_result_type: searchResultType,
      // 是否为搜索关键词自动补全时的搜索结果
      is_auto_complete_used: isAutoCompleteUsed,
      before_auto_complete_keyword: beforeWord,
      auto_complete_category: autoComplateItem,
      suggestedSource
    });
  } catch (error) {
    console.warn('sensor:[Search_view_web]', error);
  }
}

// Search 弹框点击事件
export function trackSearchClick(
  // 当前在哪个页面点击的search
  pagePath: string
): void {
  try {
    tracker.track('Search_Box_Click', {
      // PDP/PLP/HOMEPAGE/CLP
      page_title: getPageTitle(pagePath)
    });
  } catch (error) {
    console.warn('sensor:[Search_Box_Click]', error);
  }
}
export function searchClickTerm(value:{
  [x: string]: string|number;
  // eslint-disable-next-line camelcase
  search_type: string,
  // eslint-disable-next-line camelcase
  search_term: string,
  // eslint-disable-next-line camelcase
  search_result_detail: string,
  // eslint-disable-next-line camelcase
  search_results:number
}){
  const href = window.location.pathname;
  let screenType='';
  if(href.includes('/plp')){
    screenType='PLP';
  }else if(href.includes('/pdp')){
    screenType='PDP';
  }else if(href.includes('/splp')){
    screenType='PLP';
  }else if(href.includes('/my-account')){
    screenType='Profile';
  }else if(href==='/'){
    screenType='homePage';
  };

  if(value.search_type==='hot'){
    value.search_type="Poupular Search Terms";
  }else if(value.search_type==='recommendation'){
    value.search_type="auto";
  }else if(value.search_type==="history"){
    value.search_type="History";
  }else if(value.search_type==="keywords"){
    value.search_type="Owned";
  }
  if(value.search_results===0){
    value.search_result_type='No result';
  }else if(value.search_result_detail.includes('/plp')){
    value.search_result_type='PLP';
  }else if(value.search_result_detail.includes('/splp')){
    value.search_result_type='PLP';
  }else if(value.search_result_detail.includes('/pdp')){
    value.search_result_type='PDP';
  }else{
    value.search_result_type='CLP';
  }

  // eslint-disable-next-line camelcase
  const screen_name= window.location.href;
  const prarm={
    ...value,
    screen_type:screenType,
    screen_name
  };
  try {
    tracker.track('Search_Click_Term', prarm);
  } catch (error) {
    console.warn('sensor:[Search_Click_Term]', error);
  }
}

// Search 联想词搜索时埋点事件
export function trackSearchAutocomplete(
  // 联想词string集合
  complateKeywordList: string,
  // 输入框输入的搜索词
  keyword: string,
  suggestedSource: string
): void {
  try {
    tracker.track('Auto_Complete_Search', {
      auto_complete_keyword: complateKeywordList,
      key_word: keyword,
      suggestedSource
    });
  } catch (error) {
    console.warn('sensor:[Auto_Complete_Search]', error);
  }
}
