/*
 * @Description: 路由守卫
 * @Autor: Duan,JingLiang
 * @Date: 2021-09-15 19:53:57
 */
import jwt from 'jsonwebtoken';
import {
  sensorsSetPublicProps,
  getDistinctIds
} from '~/utilities/vendors/sensors';
import {
  decodeURIComponentSafe,
  detectOS,
  isLogin,
  TokenUtils
} from '~/utilities/index';
import { BasicUtils } from '~/utilities/basic/utils';
import {Utils} from '~/components/nps-ces/utils';
import LocalCart from '~/components/cart/util/localCartService';
const localCartService = new LocalCart();
let utils;

export default ({ app }) => {
  app.router.afterEach(async (to, from) => {
    try{
      if(to?.path&&to.path.startsWith('/pdp')||to.path.startsWith('/plp')){
        if(!utils){
          utils=new Utils(app);
        }
        utils.updateLastVisitTime();
      }
      app.store.dispatch('homePage/isUnixMode', { from,to ,ctx: app});
    }catch {}
    // 跳转页面时更新购物车数量
    // 跳转页面为医护时不更新购物车数量
    if (
      isLogin() &&
      !app.store.state.myAccount.loggingOut &&
      to.path !== '/d_n'
    )
      app.store.dispatch('homePage/getCartNum', { ctx: app });

    const distinctId = await getDistinctIds();
    const memberInfo = jwt.decode(TokenUtils.getOriginToken() || '');
    const memberId = memberInfo?.member_id || '';
    const isNonmemberLogin = app.store.state.myAccount.isNonmemberLogin;
    let referrer;
    if (window.self === window.top) {
      referrer =
        sessionStorage.getItem('previousUrl') ||
        `${window.location.protocol}${window.location.host}${from.fullPath}`;
    } else {
      referrer = `${window.location.protocol}${window.location.host}${from.fullPath}`;
    }

    // 判断当前用户身份类型
    const getIdentityType = ()=>{
      let identity = "Guest";
      if (app.store.state.myAccount.isDoctorOrNurse) {
        identity = "Doctor";
      } else if (app.store.state.myAccount.isCampus) {
        identity = "Student";
      } else if (app.store.state.myAccount.isPartnerShip) {
        identity = "Partner";
      } else if (app.store.state.myAccount.isVolunteer) {
        identity = "Volunteer";
      } else if (app.store.state.myAccount.isEmployeeLogin) {
        identity = "Employee";
      } else if (app.store.state.myAccount.isNonmemberLogin) {
        identity = "Non_Member";
      } else if (new BasicUtils(app).isMemberLogin()) {
        identity = "Member";
      }
      return identity;
    };
    let URLDecoded=window.location.href;
    try{
      URLDecoded= decodeURI(decodeURIComponentSafe(window.location.href));
    }catch{
      URLDecoded=decodeURI(window.location.href);
    };
    const publicParams = {
      URL_decoded: URLDecoded,
      com_cookie_id:
        isLogin() && memberId && !isNonmemberLogin ? memberId : distinctId,
      language: 'ZH',
      logged_in: isLogin() ? 'TRUE' : 'FALSE',
      memberID: isLogin() && !isNonmemberLogin ? memberId : '',
      page: to.path,
      user_agent: navigator.userAgent || '',
      os_type: detectOS(),
      referrer,
      is_login: isLogin() ? 'TRUE' : 'FALSE',
      identity_type: getIdentityType()
    };
    sensorsSetPublicProps(publicParams);

    // 一个月后清除本地购物车
    if (localCartService.cartData.length > 0) {
      const currentTime = new Date().valueOf();
      if (currentTime > localCartService.expireTimecurrentTime) {
        localCartService.clearLocalCartData();
      }
    }
  });
};
