
import Block from './Block/Block.vue';
import Indicator from './Indicator/indicator.vue';
export default {
  name: 'LibLoading',
  components: {
    Block, // 三条杠loading组件
    Indicator, // 横向线型移动loading组件
  },
  props: {
    // 组件显示隐藏
    show: {
      type: Boolean,
      default: false,
    },
    // 是否需要遮罩
    withMask: {
      type: Boolean,
      default: false,
    },
    // 组件类型
    type: {
      type: String,
      default: 'default',
    },
    // loading是否带文字内容
    withContent: {
      type: Object,
      default: () => {
        return { content: '' };
      },
    },
    /**
     * 局部使用，当设置为true时，应该在使用处引入loading组件
     */
    partial: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      withContent: this.withContent,
    };
  },
};
