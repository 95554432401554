
// import Cookies from 'js-cookie';
import { LibInput, LibCheckbox, LibButton } from '@adidas-com-front/ui-library';
import { trackSpecialLogin, trackLogin } from './track/index';
import { trackPartnerLoginOrLogout } from '~/components/partnership/track/index';
export default {
  name: 'NonmemberLogin',
  components: {
    LibInput,
    LibCheckbox,
    LibButton
  },
  props: {
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 当前非会员步骤
    nonmemberStep: {
      type: Number,
      default: 1
    },
    // 是否会员登录
    isMemberLogin: {
      type: String,
      default: ''
    },
    // 是否同意条款
    isAgree:{
      type: Boolean,
      default: false
    },
    isJointMember:{
      type: Boolean,
      default: false
    },
    joinType:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // showCheckClauseNotice: false,
      // 当前步骤数
      step: 1,
      // 电话号码
      tel: undefined,
      // button的文本显示
      buttonText: ['获取验证码', '登录', '登录'],
      loadingLabel: ['获取验证码', '登录中', '登录中'],
      // 是否保持登录状态
      // isKeep:false,
      // 验证码
      verifyCode: undefined,
      // 输入电话号码格式是否正确
      isRight: false,
      // 是否同意会员条款
      memberIsAgree: false,
      // 标题数组
      titles: ['输入手机号', '输入验证码',()=>{
        return this.isMobile?"请您阅读并同意相关条款":"";
      }],
      icons:[null,null,{iconType:'right',icon:'icon-cross_32'}],
      // 输入验证码格式是否正确
      rightCode: false,
      // 错误提示信息
      apiErrMessage: '',
      nonTimer: null,
      timeout: false,
      // 接口请求时的按钮loading态
      btnLoading: false,
      clauseWin:false,
      closeClauseWin:()=>{}
    };
  },
  computed: {
    proUserIconImg() {
      return this.$config.S3_COM_HOST + '/assets/login/pro_user_icon.png';
    },
    rememberPwd: {
      get() {
        return this.$store.state.myAccount.isKeep;
      },
      set(val) {
        this.$store.commit('myAccount/SET_KEEP_STATUS', val);
      }
    },
    isDoctorOrNurse() {
      return this.$store.state.myAccount.isDoctorOrNurse;
    },
    isCampus() {
      return this.$store.state.myAccount.isCampus;
    },
    isPartnerShip() {
      return this.$store.state.myAccount.isPartnerShip;
    },
    isVolunteer() {
      return this.$store.state.myAccount.isVolunteer;
    },
    disabled() {
      let disabled = true;
      if (this.step === 1) {
        if(this.tel){
          this.checkTel();
        }
        // console.log("!this.isRight",!this.isRight);
        disabled = !this.isRight;
      } else if (this.step === 2) {
        disabled = !this.rightCode || !!this.apiErrMessage;
      } else if (this.step === 3) {
        disabled = !!this.apiErrMessage;
      }
      return disabled;
    }
  },
  watch: {
    // isAgree(val, preVal) {
    //   if (!preVal && val) {
    //     this.showCheckClauseNotice = false;
    //   }
    // },
    clauseWin(newValue){
      if(newValue){
        this.$emit('handleTitle', this.isMobile?"请您阅读并同意相关条款":'',{iconType:'right',icon:'icon-cross_32'});
      }else{
       this.updataTitle(this.nonmemberStep-1);
      }
    },
    nonmemberStep(newValue) {
      if(this.clauseWin){
        this.closeClauseWin(false);
        return;
      }
      if (newValue < this.step) {
        this.clearData(false);
        // if (newValue === 1) {
        //   this.tel = undefined;
        // }
        this.step = newValue;

        const prop = this.step === 1 ? 'tel' : 'verifyCode';
        this.onFocus(prop);
        // let title= '';
        // if(typeof(this.titles[newValue - 1])==='string'){
        //   title= this.titles[newValue - 1];
        // }else{
        //   title=this.titles[newValue - 1]();
        // }
        // if (this.isMobile) {
          this.updataTitle(newValue - 1);
          // this.$emit('handleTitle',title,this.icons[newValue - 1]);
        // }
        // this.handleDisabled();
      }
    },
    isMemberLogin: {
      immediate: true,
      handler(newValue) {
        if (newValue === 'N') {
          this.onFocus('tel');
        }
      }
    }
    // memberIsAgree(newValue) {
    //   this.showCheckClauseNotice = !newValue;
    // }
  },
  beforeMount() {
    // if (this.isMobile) {
      // this.$emit('handleTitle', this.titles[this.step - 1],this.icons[this.step - 1]);
      this.updataTitle(this.step - 1);
    // }
  },
  methods: {
    updataTitle(index){
      let title= '';
       if(typeof(this.titles[index])==='string'){
        title= this.titles[index];
       }else{
        title=this.titles[index]();
       }
      this.$emit('handleTitle', title, this.icons[index]);
    },
    // 根据电话号码输入是否正确变更是否能进行下一步状态
    checkTel(e) {
      if (e) {
        this.isRight = false;
      } else if (this.tel.length === 11) {
        this.isRight = true;
      }
      // this.handleDisabled();
    },
    onFocus(val) {
      if (this.isMobile) return;
      if (val === 'tel') {
        this.$nextTick(() => {
          this.$refs?.tel&&this.$refs.tel.focusInput();
        });
      }else if(val==='verifyCode'){
        this.$nextTick(() => {
          this.$refs?.verifyCode&&this.$refs.verifyCode.focusInput();
        });
      }
    },
    checkVerifyCode(e) {
      if (this.apiErrMessage) {
        this.apiErrMessage = '';
      }
      if (e) {
        this.rightCode = false;
      } else {
        this.rightCode = true;
      }
    },
    // 进行到下一步骤
    async goNext() {
      console.log("goNext",this.step);
      if (this.step < 3) {
        if (this.step === 1) {
          await this.sendCode();
          this.onFocus('verifyCode');
        }
        else if (this.step === 2) {
          // if (this.isAgree) {
            this.onFocus('verifyCode');
            await this.loginNon();
          // } else {
          //   this.showCheckClauseNotice = true;
          // }
        }
      } else if (this.memberIsAgree) {
        this.loginMember();
      } else {
        // this.showCheckClauseNotice = true;
      }
    },
    // 初始化
    clearData(value = true) {
      if (value) {
        this.tel = undefined;
      }
      this.isKeep = false;
      // this.isAgree = false;
      this.verifyCode = undefined;
      this.isRight = false;
      this.memberIsAgree = false;
      this.rightCode = false;
      this.apiErrMessage = '';
      this.nonTimer = null;
      this.timeout = false;
    },
    // 扩大PC端勾选热区范围
    handlePcClick(value) {
      if (!this.isMobile) {
        this[value] = !this[value];
      }
    },
    // 非会员登录
    async loginNon() {
      console.log("loginNon");
      this.btnLoading = true;
      try {
        const data = await this.$axios.post(
          this.$config.ANA_API_HOST + '/v2/users/no-member/login/sms',
          this.$qs.stringify({
            phone: this.tel,
            smsOtp: this.verifyCode
          })
        );
        if (data && data.data && this.btnLoading) {
          const actionType = data.data.actionType;
          const login= await this.handleClickPrivate();
          if(!login){
            this.btnLoading = false;
            return;
          }
          const paramsData = {
            ...data.data,
            isKeep: this.isMobile || this.rememberPwd
          };
           // 神策埋点-手机号登录
           const sensorData = [
            actionType === '3' ? 'Register' : 'Login',
            'SMS',
            'ACCOUNT|LOGIN',
            'ACCOUNT',
            false,
            this.isJointMember,
            this.joinType
          ];
          trackLogin(...sensorData);

          this.signNonRecord();
          this.$emit('setNonmemberToken', paramsData, this.tel, true);
          this.$emit('emitLogin');
          this.clearData();
          this.$emit('switchPopover');
        }
      } catch (e) {
        this.$emit('networkErr', e);
        if (e.data?.errcode === 4000142) {
          this.step = 3;
          this.$Toast({
            message: '您已是adiClub会员',
            ref:this.isMobile?null:this.$refs.content
          });
          this.showCheckClauseNotice = false;
          // if (this.isMobile) {
            this.updataTitle(this.step - 1);
          // }
          this.$emit('handleNonmember', this.step);
          this.validationId = e.data.errmsg;
          if(this.nonTimer){
            clearTimeout(this.nonTimer);
          }
          this.nonTimer = setTimeout(() => {
            this.timeout = true;
          }, 300000);
        } else if (e.data?.errcode === 4000036) {
          this.apiErrMessage = '验证码不正确, 请检查并重新输入';
        } else {
          const errCodeArr = [
            4000035, 4000045, 4000046, 4000047, 4000061, 4000068, 4000143
          ];
          if (e.data?.errcode in errCodeArr) {
            this.$Toast({
              message: '系统繁忙，请稍后再试',
              ref: this.isMobile ? null : this.$refs.content
            });
          } else if (e.data?.errmsg) {
            this.$Toast({
              message: e.data.errmsg,
              ref: this.isMobile ? null : this.$refs.content
            });
          }
        }
      }
      this.btnLoading = false;
    },
    // 会员登录
    async loginMember() {
      if (!this.timeout) {
        clearTimeout(this.nonTimer);
      } else {
        this.$emit('timeoutToast');
        this.clearData();
        // setTimeout(() => {
        //   this.$emit('switchPopover');
        // }, 2000);
        return;
      }
      this.btnLoading = true;
      try {
        const data = await this.$axios.post(
          this.$config.ANA_API_HOST + '/v1/users/login/sms',
          this.$qs.stringify({
            phone: this.tel,
            subscribeFlag: 0,
            validationId: this.validationId
          })
        );
        if (data && data.data && this.btnLoading) {
          const tel = this.tel;
          const paramsData = {
            ...data.data,
            isKeep: this.isMobile || this.rememberPwd
          };
          this.signRecord();
          this.$emit('setNonmemberToken', paramsData, this.tel);
          this.$emit('emitLogin');
          this.clearData();
          const sensorData = [
            'Login',
            'Account',
            'ACCOUNT|LOGIN',
            'ACCOUNT',
            false
          ];
          trackLogin(...sensorData);
          // 医护埋点
          setTimeout(() => {
            if (this.isDoctorOrNurse) {
              const sensorSpecialData = ['Doctor', tel];
              trackSpecialLogin(...sensorSpecialData);
            } else if (this.isCampus) {
              const sensorSpecialData = ['Student', tel];
              trackSpecialLogin(...sensorSpecialData);
            } else if (this.isVolunteer) {
              const sensorSpecialData = ['Volunteer', tel];
              trackSpecialLogin(...sensorSpecialData);
            } else if (this.isPartnerShip) {
              const sensorSpecialData = ['Partner', tel];
              trackSpecialLogin(...sensorSpecialData);
              trackPartnerLoginOrLogout('Login');
            }
          }, 500);
          this.$emit('switchPopover');
        }
      } catch (e) {
        this.$emit('networkErr', e);
        if (e.data?.errcode === 4000036) {
          this.$emit('timeoutToast');
          setTimeout(() => {
            this.clearData();
            this.$emit('switchPopover');
          }, 2000);
        } else if (e.data?.errcode === 4000066) {
          this.$emit('unRegister');
        } else if (e.data?.errmsg) {
          this.$Toast({
            message: e.data.errmsg || e.errmsg,
            ref: this.isMobile ? null : this.$refs.content
          });
        }
      }
      this.btnLoading = false;
    },
    // 发送验证码
    async sendCode() {
      this.btnLoading = true;
      this.verifyCode = '';
      this.apiErrMessage='';
      try {
        const data = await this.$axios.get(
          `${this.$config.ANA_API_HOST}/v1/sms/otp/send`,
          {
            params: {
              phone: this.tel,
              type: '10008'
            }
          }
        );
        if (data && data.data && this.btnLoading) {
          await new Promise((resolve)=>{
            setTimeout(() => {
              this.step = 2;
              // if (this.isMobile) {
                this.titles[this.step - 1]='验证码已发送至 '+this.tel;
                this.updataTitle(this.step - 1);
              // }
              this.$emit('handleNonmember', this.step);

              setTimeout(() => {
                this.$refs.verifyCode.startCountDown();
                resolve();
              }, 0);
            }, 100);
          });

        }
      } catch (e) {
        this.$emit('networkErr', e);
        if (e.data?.errcode) {
          if (e.data.errcode === 4000024) {
            this.apiErrMessage = '验证码发送过于频繁, 请稍后尝试';
            setTimeout(() => {
              this.step = 2;
              // if (this.isMobile) {
                this.updataTitle(this.step - 1);
                // this.$emit('handleTitle', this.titles[this.step - 1],this.icons[this.step - 1]);
              // }
              this.$emit('handleNonmember', this.step);

              setTimeout(() => {
                this.$refs.verifyCode.startCountDown();
              }, 0);
            }, 100);
          } else if (e.data.errcode === 4000026) {
            this.$Toast({
              message: '24小时内验证码发送次数过多，请稍后尝试',
              ref: this.isMobile ? null : this.$refs.boxg
            });
            this.apiErrMessage = '24小时内验证码发送次数过多，请稍后尝试';
          } else if (e.data.errcode === 4000021) {
            this.$Toast({
              message: 'Type传入值不在枚举值中',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          } else if (e.data.errcode === 4000025) {
            this.$Toast({
              message: '短信验证码生成失败',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          } else if (e.data.errcode === 4000029) {
            this.$Toast({
              message: '短信发送内部错误',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          } else if (e.data.errcode === 4000022) {
            this.$Toast({
              message: '手机号为空',
              ref: this.isMobile ? null : this.$refs.boxg
            });
            this.apiErrMessage = '手机号为空';
          } else if (e.data.errcode === 4000023) {
            this.$Toast({
              message: '手机号格式错误',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          } else {
            this.$Toast({
              message: e.data.errmsg,
              ref: this.isMobile ? null : this.$refs.content
            });
          }
        } else if (e.data?.errmsg) {
          this.$Toast({
            message: e.data.errmsg,
            ref: this.isMobile ? null : this.$refs.content
          });
        }
      }
      this.btnLoading = false;
    },
    linkto(type){
      this.$emit('linkto',type);
    },
    AgreeMember(){
        this.memberIsAgree=true;
        this.goNext();
    },
    handleClickPrivate(){
      return new Promise((resolve)=>{
        if(!this.isAgree){
          this.clauseWin=true;
        this.closeClauseWin=(value)=>{
            this.closeClauseWin=()=>{};
            this.clauseWin=false;
            if(value){
              this.$emit('agree');
              this.$nextTick(()=>{
                resolve(true);
              });
            }else{
              resolve(false);
            }
          };
        }else{
          resolve(true);
        }
      });
    },
    signRecord() {
      this.$axios.post(`${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/tc/sign-record`,{
        signFlag: this.memberIsAgree? 1 : 0,
        tcCode: 'TAC_PRIVACY'
      });
      this.$axios.post(`${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/tc/sign-record`,{
        signFlag: this.memberIsAgree ? 1 : 0,
        tcCode: 'TAC_MEMBER'
      });
      this.$axios.post(`${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/tc/sign-record`,{
        signFlag: this.memberIsAgree ? 1 : 0,
        tcCode: 'TAC_4IN1'
      });
    },
    signNonRecord() {
      this.$axios.post(`${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/tc/sign-record`,{
        signFlag: this.isAgree ? 1 : 0,
        tcCode: 'TAC_4IN1'
      });
    },

    closeClause(){
      if(this.clauseWin){
        this.closeClauseWin(false);
        return true;
      }

    }
  }
};
