/*
 * @Author: 胡阳
 * @Date: 2021-10-28 10:11:49
 * @Description: Search 埋点
 */
let tracker = {} as any;
if (process.client) {
  tracker = require('~/utilities/vendors/sensors').default;
}

// Search
export function trackSearchViewWeb(
  // 根据点击客服的页面取值 "ACCOUNT|LOGIN"/" SHOPPING CART|EMPTY CART"
  pageName: string,
  // 根据点击客服的页面取值 "ACCOUNT”/“SHOPPING CART”
  pageType: string,
  // 从哪个页面进行的搜素 PDP/Homepage/PLP….
  searchPosition: string,
   // 搜索关键词
  searchTerm: string,
  // 搜素类型 KEYWORDS RECOMMENDATION HISTORY
  searchType: string,
  // 结果产品数量
  totalResults: string,
  // 是否使用默认关键词进行搜索
  isDefaultSearch: boolean,
   // 搜索结果类型 CLP，PLP，No Result，H5
  searchResultType: string,
  // 是否为搜索关键词自动补全时的搜索结果
  isAutoCompleteUsed: boolean,
   // 用户输入的词
  beforeWord: string,
  // 点击的联想词
  autoComplateItem: string,
  suggestedSource: string
): void {
  try {
    tracker.track('Search_view_web', {
      // 根据点击客服的页面取值
      page_name: pageName,
      // 根据点击客服的页面取值
      page_type: pageType,
       // 从哪个页面进行的搜素
      search_position: searchPosition,
       // 搜索关键词
      search_term: searchTerm,
      // 搜素类型
      search_type: searchType,
      // 结果产品数量
      total_results: totalResults,
      // 是否使用默认关键词进行搜索
      is_default_search: isDefaultSearch,
       // 搜索结果类型
      search_result_type: searchResultType,
      // 是否为搜索关键词自动补全时的搜索结果
      is_auto_complete_used: isAutoCompleteUsed,
      before_auto_complete_keyword: beforeWord,
      auto_complete_category: autoComplateItem,
      suggestedSource
    });
  } catch (error) {
    console.warn('sensor:[Search_view_web]', error);
  }
}
