
import { LibButton } from '@adidas-com-front/ui-library';
import deepcopy from 'deepcopy';
import questionMixin from './question.mixins';
import { colorHexToRgb } from '~/utilities/helper/colorHelper';
import MemberGuide from '~/components/clp/survey/memberPopover.vue';

export default {
  components: {
    LibButton,
    MemberGuide
  },
  mixins: [questionMixin],
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    },
    repeatFillingFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionList: []
    };
  },
  computed: {
    showResetSurvey() {
      if(this.dynamicReplyFlag){
        return this.showDynamicReply;
      }else if (
        // 可以重复填写
        this.repeatFillingFlag &&
        // 不是多选
        !this.isMultiple &&
        // 单选并有已选
        this.selectedItem
      ) {
        return true;
      }
      if (
        // 多选
        this.isMultiple &&
        // 已经提交结果
        this.submited &&
        // 可以重复填写
        this.repeatFillingFlag
      ) {
        return true;
      }
      return false;
    },
    optionsStyle() {
      if (window.innerWidth > 719) {
        const len = this.optionList.length;
        return {
          width:
            len < 3
              ? len * 167 + (len - 1) * 2 + 'px'
              : len === 3
              ? len * 110 + (len - 1) * 2 + 'px'
              : len * 80 + (len - 1) * 2 + 'px'
        };
      } else {
        return {};
      }
    },
    showDynamicReply() {
      return (this.submited || this.wholeSurveySubmited) && this.dynamicReplyFlag && this.computeCompleted;
    }
  },
  watch: {
    surveyResult(newVal, oldVal) {
      if(newVal) {
        this.$nextTick(async () => {
          const height = this.$refs.surveyTitle?.offsetHeight;
          const buffer = this.windowWidth < 720 ? 8 : 40;
          const top = await this.loadQuestionImg(height, buffer, 20);
          this.wholeReplyImgHeight = await this.loadReplyImg();
          const optionsHeight = this.$refs.options?.clientHeight || 0;
          this.wholeReplyStyle = { top:  top + 'px', height: optionsHeight + 'px' };
          if (this.showWholeReply) {
            this.animateWholeReply();
          }
          this.getLastResult();
        });
      }
    }
  },
  mounted() {
    const optionList = deepcopy(
      this.question.questionDescriptionDetail.optionList
    );
    optionList.sort((a, b) => {
      return a.optionPosition - b.optionPosition;
    });
    this.optionList = deepcopy(optionList);
    this.$nextTick(async () => {
      const height = this.$refs.surveyTitle?.offsetHeight;
      const buffer = this.windowWidth < 720 ? 8 : 40;
      const top = await this.loadQuestionImg(height, buffer, 20);
      this.wholeReplyImgHeight = await this.loadReplyImg();
      const optionsHeight = this.$refs.options?.clientHeight || 0;
      this.wholeReplyStyle = { top:  top + 'px', height: optionsHeight + 'px' };
      if (this.showWholeReply) {
        this.animateWholeReply();
      }
      this.getLastResult();
    });
  },
  methods: {
    frontTextStyle(item) {
      return {
        color: item.optionTextColor
      };
    },
    dynamicItemStyle(item) {
      // if(!item.selected){
      //   item.percentStr="0%";
      // }
      return {
        'background-color':
          this.questionDescDetail.proportionColor &&
          (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item))
            ? colorHexToRgb(this.questionDescDetail.proportionColor, 0.6)
            : '',
        height: this.showDynamicReply ? item.percentStr : '0%'
      };
    },
    dynamicItemTextStyle(item) {
      // if(!item.selected){
      //   item.percentStr="0%";
      // }
      return {
        'background-color':
          this.questionDescDetail.proportionColor &&
          (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item))
            ? colorHexToRgb(this.questionDescDetail.proportionColor, 1)
            : '',
        height: this.showDynamicReply ? item.percentStr : '0%'
      };
    },
    isCurrentReplySubmited(item) {
      return !this.dynamicReplyFlag && (this.isCurrentItemSubmited(item) || this.isCurrentWholeSubmited(item));
    },
    optionItemClassNames(item) {
      const optionHasText =
        // 有文字选项
        item.optionText ||
        // 有反馈文字并且有反馈图片
        (item.replyText &&
          this.isCurrentReplySubmited(item) &&
          item.replyImageUrl);

      let optionNoText =
        // 没有文字选项并且没有提交
        (!item.optionText && !this.isCurrentReplySubmited(item)) ||
        // 没有文字选项并且反馈文字且没有提交
        (!item.replyText &&
          !item.optionText &&
          this.isCurrentReplySubmited(item));
      // 有反馈图片
      optionNoText = optionNoText && item.replyImageUrl;
      return {
        "rotated": item.className === 'rotated' && item.replyImageUrl,
        'option-ios': this.isIos && !item.submited,
        'option-rotated': item.replyImageUrl,
        'option-multi-selected':
          (!this.dynamicReplyFlag && item.selected && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && ((!this.submitAnswerFlag && !this.submited) || (this.submitAnswerFlag && !this.wholeSurveySubmited))) ||
          (this.dynamicReplyFlag && item.selected && (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) && (!this.computeCompleted || ((!this.submitAnswerFlag && !this.submited) || (this.submitAnswerFlag && !this.wholeSurveySubmited)))),
        'option-has-text': optionHasText,
        'option-no-text': optionNoText,
        'option-mobile':
          item.replyImageUrl && this.windowWidth < 720 && !item.optionText,
        'option-one option-two': this.optionList.length < 3,
        'option-three': this.optionList.length === 3,
        'option-other': this.optionList.length > 3
      };
    }
  }
};
