import { getPlatform } from '~/utilities/vendors/sensors';
let tracker = {} as any;
if (process.client) {
  tracker = require('~/utilities/vendors/sensors').default;
}
interface iOption {
  optionCode: string,
  optionDescribe?: string,
  optionCategoryCode?: string,
  optionArticleList?: string[]
}
interface iQuestion {
  questionCode: string,
  questionType: string,
  multipleChoiceFlag?: boolean,
  questionDescriptionDetail: {
    optionList: iOption[],
    staticReplyType?: string,
    dynamicReplyFlag?: number
  }
}

interface iSurvey {
  surveyCode: string,
  surveyType: string,
  surveySource: string,
  questionList: iQuestion[],
  replyMemberFlag?: number
}

interface iQuestionAnswer {
  answerList: iOption[],
  questionAnswerStatistics: Object,
  questionCode: String
}

interface iSurveyResult {
  surveyCode: string,
  surveyType: string,
  questionAnswerInfo: iQuestionAnswer[]
}


interface iCLPSurveyView {
  campaignName: string,
  surveCodeList: string[],
  surveyIdList: string[],
  questionTypeList: string[],
  questionIdList: string[],
  articleIdList: string[],
  categoryCodeList: string[],
  appName: string,
  subCampaignName?: String,
  // eslint-disable-next-line camelcase
  is_memer_only_list?: String
}
interface iCLPSurveySubmit {
  campaignName: string,
  componentCode: string,
  positionCode: string,
  questionType: string,
  surveyId: string,
  questionId: string,
  submitDetail: string,
  submitType?: string,
  articleIdList: string[],
  categoryCodeList: string[],
  appName: string,
  subCampaignName?: String,
  // eslint-disable-next-line camelcase
  is_memer_only?: Boolean
}
interface iCLPSurveyPDPClick {
  campaignName: string,
  componentCode: string,
  positionCode: string,
  questionType: string,
  surveyId: string,
  questionId: string,
  optionDetail: string,
  articleId: string,
  categoryCode: string,
  appName: string,
  subCampaignName?: String,
  // eslint-disable-next-line camelcase
  is_memer_only?: Boolean
}
interface iCLPSurveyResultView {
  campaignName: string,
  componentCode: string,
  positionCode: string,
  questionType: string,
  surveyId: string,
  questionId: string,
  submitDetail: string,
  articleIdList: string,
  categoryCodeList: string,
  resultType: string,
  appName: string,
  subCampaignName?: String,
  pageTitle?: String,
  // eslint-disable-next-line camelcase
  is_memer_only?: Boolean
}
// 接受来自clp主页的隐私政策签订状态，设置到这里
let isSignPrivacyTc = false;
export function surveySetIsSign(signFlag: boolean) {
  isSignPrivacyTc = signFlag;
}

export function trackClpSurveyView(data: iCLPSurveyView): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_Survey_View', {
      campaign_name: data.campaignName,
      survey_component_code_list: data.surveCodeList,
      question_type_list: data.questionTypeList.join(','),
      survey_id_list: data.surveyIdList.join(','),
      question_id_list: data.questionIdList.join(','),
      article_id_list: data.articleIdList.join(','),
      category_code_list: data.categoryCodeList.join(','),
      app_name: data.appName,
      // survey 目前不支持子clp，未来支持再补充逻辑
      sub_campaign_name: '',
      is_memer_only_list: data.is_memer_only_list
    });
    console.log('sensor:[CLP_Survey_View]', {
      campaign_name: data.campaignName,
      survey_component_code_list: data.surveCodeList,
      question_type_list: data.questionTypeList.join(','),
      survey_id_list: data.surveyIdList.join(','),
      question_id_list: data.questionIdList.join(','),
      article_id_list: data.articleIdList.join(','),
      category_code_list: data.categoryCodeList.join(','),
      app_name: data.appName,
      // survey 目前不支持子clp，未来支持再补充逻辑
      sub_campaign_name: '',
      is_memer_only_list: data.is_memer_only_list
    });
  } catch (error) {
    console.warn('sensor:[CLP_Survey_View]', error);
  }
}

export function trackClpSurveySubmit(data: iCLPSurveySubmit): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_Survey_Submit', {
      campaign_name: data.campaignName,
      component_code: data.componentCode,
      position_code: data.positionCode,
      question_type: data.questionType,
      survey_id: data.surveyId,
      question_id: data.questionId,
      submit_detail: data.submitDetail,
      submit_type: data.submitType,
      article_id_list: data.articleIdList,
      category_code_list: data.categoryCodeList,
      app_name: getPlatform(),
      // survey 目前不支持子clp，未来支持再补充逻辑
      sub_campaign_name: '',
      is_memer_only: data.is_memer_only
    });
    console.log('sensor:[CLP_Survey_Submit]', {
      campaign_name: data.campaignName,
      component_code: data.componentCode,
      position_code: data.positionCode,
      question_type: data.questionType,
      survey_id: data.surveyId,
      question_id: data.questionId,
      submit_detail: data.submitDetail,
      submit_type: data.submitType,
      article_id_list: data.articleIdList,
      category_code_list: data.categoryCodeList,
      app_name: getPlatform(),
      // survey 目前不支持子clp，未来支持再补充逻辑
      sub_campaign_name: '',
      is_memer_only: data.is_memer_only
    });
  } catch (error) {
    console.warn('sensor:[CLP_Survey_Submit]', error);
  }
}

export function trackClpSurveyPDPClick(data: iCLPSurveyPDPClick): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_Survey_PDP_Click', {
      campaign_name: data.campaignName,
      component_code: data.componentCode,
      position_code: data.positionCode,
      question_type: data.questionType,
      survey_id: data.surveyId,
      question_id: data.questionId,
      option_detail: data.optionDetail,
      article_id: data.articleId,
      category_code: data.categoryCode,
      app_name: getPlatform(),
      // survey 目前不支持子clp，未来支持再补充逻辑
      sub_campaign_name: '',
      is_memer_only: data.is_memer_only
    });
  } catch (error) {
    console.warn('sensor:[CLP_Survey_PDP_Click]', error);
  }
}

export function trackClpSurveyResultView(data: iCLPSurveyResultView): void {
  try {
    isSignPrivacyTc && tracker.track('CLP_Survey_Result_View', {
      campaign_name: data.campaignName,
      component_code: data.componentCode,
      position_code: data.positionCode,
      question_type: data.questionType,
      survey_id: data.surveyId,
      question_id: data.questionId,
      submit_detail: data.submitDetail,
      article_id_list: data.articleIdList,
      category_code_list: data.categoryCodeList,
      result_type: data.resultType,
      app_name: getPlatform(),
      // survey 目前不支持子clp，未来支持再补充逻辑
      sub_campaign_name: '',
      page_title: data.pageTitle,
      is_memer_only: data.is_memer_only
    });
    console.log('sensor:[CLP_Survey_Result_View]', {
      campaign_name: data.campaignName,
      component_code: data.componentCode,
      position_code: data.positionCode,
      question_type: data.questionType,
      survey_id: data.surveyId,
      question_id: data.questionId,
      submit_detail: data.submitDetail,
      article_id_list: data.articleIdList,
      category_code_list: data.categoryCodeList,
      result_type: data.resultType,
      app_name: getPlatform(),
      // survey 目前不支持子clp，未来支持再补充逻辑
      sub_campaign_name: '',
      page_title: data.pageTitle,
      is_memer_only: data.is_memer_only
    });
  } catch (error) {
    console.warn('sensor:[CLP_Survey_Result_View]', error);
  }
}

export class Scroll {
  protected surveyList: iSurvey[] = [];
  protected surveyResultList: iSurveyResult[] = [];
  protected timout: any = null;
  // eslint-disable-next-line no-useless-constructor
  constructor(protected containerEle: HTMLElement | void, protected contentId: string) {
    this.init();
  };

  init() {
    const layout = document.querySelector("#layout-wrapper");
    if (layout) {
      layout.addEventListener('scroll', (e: Event) => {
        if (this.timout) {
          clearTimeout(this.timout);
        }
        this.timout = setTimeout(() => {
          this.scroll();
        }, 500);
      });
    }

  }

  addData(data: iSurvey) {
    this.surveyList.push(data);
  }

  addLastResult(data: iSurveyResult) {
    this.surveyResultList.push(data);
  }

  protected getOption(containerEle: HTMLElement, data: iQuestion): { categoryCodeList: string | null, articleIdList: string | null } {
    const articleIdList: string[] = [];
    const categoryCodeList: string[] = [];
    let hasOption = false;
    const optionEles = containerEle.querySelectorAll('.survey-option');
    for (let i = 0; i < optionEles.length; i++) {
      const optionEle = optionEles[i];
      const optionCode = optionEle.getAttribute('optioncode');
      if (!optionCode) {
        continue;
      }
      let optionData: iOption | null = null;
      for (const option of data.questionDescriptionDetail.optionList) {
        if (option.optionCode === optionCode) {
          optionData = option;
          break;
        }
      }
      if (!optionData) {
        continue;
      }
      if (optionData.optionCategoryCode) {
        hasOption = true;
        categoryCodeList.push(optionData.optionCategoryCode);
      } else {
        categoryCodeList.push('null');
      }
      if (optionData.optionArticleList?.length) {
        hasOption = true;
        if (optionData.optionArticleList.length > 1) {
          articleIdList.push(`[${optionData.optionArticleList.join(',')}]`);
        } else {
          articleIdList.push(optionData.optionArticleList.join(''));
        }
      } else {
        articleIdList.push('null');
      }
    }
    return {
      categoryCodeList: hasOption ? categoryCodeList.join(':') : null,
      articleIdList: hasOption ? articleIdList.join(':') : null
    };
  }

  protected getQuestion(containerEle: HTMLElement, data: iSurvey): {
    questionTypeList: string,
    questionCodeList: string,
    questionArticleIdList: string,
    questionCategoryCodeList: string
  } {
    const questionsEles = containerEle.querySelectorAll<HTMLElement>('.survey-question-sensors');
    const questionCodeList = [];
    const questionTypeList = [];
    const questionArticleIdList: string[] = [];
    const questionCategoryCodeList: string[] = [];
    for (let i = 0; i < questionsEles.length; i++) {
      const questionEle = questionsEles[i];
      const questionCode = questionEle.getAttribute('questioncode');
      if (!questionCode) {
        continue;
      }
      let questingData = null;
      for (const questing of data.questionList) {
        if (questing.questionCode === questionCode) {
          questingData = questing;
        }
      }
      if (!questingData) {
        continue;
      }
      const { categoryCodeList, articleIdList } = this.getOption(questionEle, questingData);
      if (articleIdList) {
        questionArticleIdList.push(articleIdList);
      } else {
        questionArticleIdList.push('null');
      }
      if (categoryCodeList) {
        questionCategoryCodeList.push(categoryCodeList);
      } else {
        questionCategoryCodeList.push('null');
      }
      questionCodeList.push(questionCode);
      questionTypeList.push(questingData.questionType);
    }
    return {
      questionTypeList: questionTypeList.join("|"),
      questionCodeList: questionCodeList.join("|"),
      questionArticleIdList: questionArticleIdList.join("|"),
      questionCategoryCodeList: questionArticleIdList.join("|")
    };
  }

  protected scroll(): void {
    if (!this.containerEle) {
      return;
    }
    const surveyEles = this.containerEle.querySelectorAll<HTMLElement>('.survey-container');
    const surveyQuestionCodeList = [];
    const surveyQuestionTypeList = [];
    const surveyArticleIdList: string[] = [];
    const surveyCategoryCodeList: string[] = [];
    const surveyIdList: string[] = [];
    const surveCodeList: string[] = [];
    const memberOnlyFlagList: boolean[] = [];
    for (let i = 0; i < surveyEles.length; i++) {
      const surveyEle = surveyEles[i];
      const surveyCode = surveyEle.getAttribute("surveycode");
      const surveyMemberOnlyFlag = !!surveyEle.getAttribute("memberonly");
      memberOnlyFlagList.push(surveyMemberOnlyFlag);
      if (!this.scrollVisible(surveyEle)) {
        continue;
      }
      if (!surveyCode) {
        continue;
      }
      for (const data of this.surveyList) {
        if (data.surveyCode === surveyCode) {
          this.trackResultView(surveyEle, data);
          // const lastResult = this.surveyResultList.find((item) => { return item.surveyCode === data.surveyCode; }) || null;
          // const questionAnswerInfoList = lastResult?.questionAnswerInfo || [];
          // const questionList = data.questionList;
          // for (let i = 0; i < questionList.length; i++) {
          //   const question = questionList[i];
          //   const optionList = question.questionDescriptionDetail.optionList;
          //   const answerListInfo = questionAnswerInfoList.find((item) => { return item.questionCode === question.questionCode; }) || null;
          //   const answerList = answerListInfo?.answerList;
          //   const position = surveyEle.getAttribute("position") || 'a1';
          //   const positionCode = position.slice(1);
          //   const submitDetail = optionList.filter((item) => {
          //     const selectedItem = answerList?.find((it) => { return item.optionCode === it.optionCode; });
          //     if (selectedItem) {
          //       return item;
          //     } else {
          //       return false;
          //     }
          //   }).map((item) => {
          //     return item.optionDescribe;
          //   });
          //   const articleIdList = optionList.filter((item) => {
          //     const selectedItem = answerList?.find((it) => { return item.optionCode === it.optionCode; });
          //     if (selectedItem) {
          //       return item;
          //     } else {
          //       return false;
          //     }
          //   }).map((item) => {
          //     return item.optionArticleList && item.optionArticleList[0];
          //   }) || [];
          //   const categoryCodeList = optionList.filter((item) => {
          //     const selectedItem = answerList?.find((it) => { return item.optionCode === it.optionCode; });
          //     if (selectedItem) {
          //       return item;
          //     } else {
          //       return false;
          //     }
          //   }).map((item) => {
          //     return item.optionCategoryCode;
          //   });
          //   const resultData = {
          //     campaignName: this.contentId,
          //     componentCode: 'Survey',
          //     positionCode: positionCode,
          //     questionType: question.questionType,
          //     surveyId: data.surveyCode,
          //     questionId: question.questionCode,
          //     submitDetail: submitDetail.join('|'),
          //     articleIdList: articleIdList.join('|'),
          //     categoryCodeList: categoryCodeList.join('|'),
          //     resultType: question.questionDescriptionDetail.staticReplyType === "Unified" ? 'for all' : 'by choice',
          //     appName: getPlatform(),
          //     pageTitle: 'directly'
          //   };
          //   trackClpSurveyResultView(resultData);
          // }
          const { questionTypeList, questionCodeList, questionArticleIdList, questionCategoryCodeList } = this.getQuestion(surveyEle, data);
          surveyQuestionCodeList.push(questionCodeList);
          surveyQuestionTypeList.push(questionTypeList);
          surveyArticleIdList.push(questionArticleIdList);
          surveyCategoryCodeList.push(questionCategoryCodeList);
          surveCodeList.push(data.surveyType);
          surveyIdList.push(surveyCode);
          break;
        }
      }
    }
    if (surveyEles.length > 0) {
      const data: iCLPSurveyView = {
        campaignName: this.contentId,
        surveCodeList,
        surveyIdList,
        questionTypeList: surveyQuestionTypeList,
        questionIdList: surveyQuestionCodeList,
        articleIdList: surveyArticleIdList,
        categoryCodeList: surveyCategoryCodeList,
        appName: getPlatform(),
        is_memer_only_list: memberOnlyFlagList.join(',')
      };
      trackClpSurveyView(data);
    }
  }

  protected trackResultView(surveyEle: HTMLElement, data: iSurvey): void {
    const lastResult = this.surveyResultList.find((item) => { return item.surveyCode === data.surveyCode; }) || null;
    const questionAnswerInfoList = lastResult?.questionAnswerInfo || [];
    const questionList = data.questionList;
    for (let i = 0; i < questionList.length; i++) {
      const question = questionList[i];
      const optionList = question.questionDescriptionDetail.optionList;
      const answerListInfo = questionAnswerInfoList.find((item) => { return item.questionCode === question.questionCode; }) || null;
      const answerList = answerListInfo?.answerList;
      const position = surveyEle.getAttribute("position") || 'a1';
      const positionCode = position.slice(1);
      const submitDetail = optionList.filter((item) => {
        const selectedItem = answerList?.find((it) => { return item.optionCode === it.optionCode; });
        if (selectedItem) {
          return item && item.optionDescribe;
        } else {
          return false;
        }
      }).map((item) => {
        return item.optionDescribe;
      });
      const articleIdList = optionList.filter((item) => {
        const selectedItem = answerList?.find((it) => { return item.optionCode === it.optionCode; });
        if (selectedItem) {
          return item && item.optionArticleList && item.optionArticleList.length > 0;
        } else {
          return false;
        }
      }).map((item) => {
        return item.optionArticleList && item.optionArticleList[0];
      }) || [];
      const categoryCodeList = optionList.filter((item) => {
        const selectedItem = answerList?.find((it) => { return item.optionCode === it.optionCode; });
        if (selectedItem) {
          return item && item.optionCategoryCode;
        } else {
          return false;
        }
      }).map((item) => {
        return item.optionCategoryCode;
      });
      const resultData = {
        campaignName: this.contentId,
        componentCode: 'Survey',
        positionCode: positionCode,
        questionType: question.questionType,
        surveyId: data.surveyCode,
        questionId: question.questionCode,
        submitDetail: submitDetail.join('|'),
        articleIdList: articleIdList.join('|'),
        categoryCodeList: categoryCodeList.join('|'),
        resultType: question.questionDescriptionDetail.dynamicReplyFlag ? 'dynamic' : (question.questionDescriptionDetail.staticReplyType === "Unified" ? 'for all' : 'by choice'),
        appName: getPlatform(),
        pageTitle: 'directly',
        is_memer_only: !!data.replyMemberFlag
      };
      trackClpSurveyResultView(resultData);
    }
  }

  scrollVisible(ele: HTMLElement): boolean {
    const curTop = ele.getBoundingClientRect().top;
    const curBottom = curTop + ele.offsetHeight;
    const windowHeight = window.innerHeight;
    if ((curTop > 0 && curTop < windowHeight) || (curBottom < windowHeight && curBottom > 0)) {
      return true;
    } else {
      return false;
    }
  }
}
