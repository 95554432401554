import Vue from 'vue';
// @ts-ignore
import Dayjs from './dayjs.min';

const ONE_DAY = 24 * 60 * 60 * 1000;

export const DayJs = Dayjs;

const state = Vue.observable({
  now: new Date()
});
const isClient: Boolean = (() =>
  typeof window !== 'undefined' || typeof document !== 'undefined')();
if(isClient){
  setInterval(() => {
    // console.log("warn: Interval p15");
    state.now = new Date();
  }, 1000);
}else{
  state.now = new Date();
}

const formatZero = (num: string, len: number) => {
  if(String(num).length > len) return num;
  return (Array(len).join('0') + num).slice(-len);
};

/**
 * 计算两个时间戳之间相差的天数，小时、分钟以及秒钟；
 * @author  韦胜健
 * @date    2021/9/8 16:22
 */
const calcInterval = (startTime: number, endTime: number) => {

  // 两个时间戳相差的毫秒数
  let usedTime = endTime - startTime;
  if (usedTime < 0) {
    usedTime = -usedTime;
  }
  const daysUseTime = 24 * 60 * 60 * 1000;
  const days = Math.floor(usedTime / daysUseTime);
  usedTime = usedTime - days * daysUseTime;

  const hoursUseTime = 60 * 60 * 1000;
  const hours = Math.floor(usedTime / hoursUseTime);
  usedTime = usedTime - hours * hoursUseTime;

  const minutesUseTime = 60 * 1000;
  const minutes = Math.floor(usedTime / minutesUseTime);
  usedTime = usedTime - minutes * minutesUseTime;

  const secondsUseTime = 1000;
  const seconds = Math.floor(usedTime / secondsUseTime);

  return {
    days,
    hours,
    minutes,
    seconds,
    time: endTime - startTime
  };
};

export const LibFormatter = {
  /**
   * 价格数字
   * @author  韦胜健
   * @date    2021/9/7 17:27
   */
  price(value: string | number) {
    if (value == null) {
      return null;
    }
    return `¥ ${
      Number.isInteger(value)
        ? Number(value).toFixed(0)
        : (() => {
          const [integerNum, floatNum] = String(value).split('.');
          return [integerNum, ((floatNum || '') + '00').slice(0, 2)].join(
            '.'
          );
        })()
    }`;
    `¥ ${Number.isInteger(value) ? Number(value).toFixed(0) : value}`;
  },
  /**
   * 优惠券数字，与价格一致
   * @author  韦胜健
   * @date    2021/9/7 17:27
   */
  coupon(value: string | number) {
    if (value == null) {
      return null;
    }
    return LibFormatter.price(value);
  },
  /**
   * 会员中心-优惠券金额数字
   * @author  李博
   * @date    2021/10/21
   */
  accountCoupon(value: string | number) {
    if (value == null) {
      return null;
    }
    const [integerNum, floatNum] = String(value).split('.');
    return `¥ ${
      Number.isInteger(value) || Number(floatNum) === 0
        ? Number(value).toFixed(0)
        :
           [integerNum, ((floatNum || '') + '00').slice(0, 2)].join('.')

    }`;
  },

  /**
   * 额度数字（两位小数）
   * @author  胡阳
   * @date    2021/11/16 17:28
   */
  quota(value: string | number) {
    if (value == null) {
      return null;
    }
    const [integerNum, floatNum] = String(value).split('.');
    return `¥ ${
        [integerNum, ((floatNum || '') + '00').slice(0, 2)].join('.')
    }`;
  },

  /**
   * 折扣数字
   * @author  韦胜健
   * @date    2021/9/7 17:28
   */
  discount(value?: string | number) {
    if (value == null) {
      return null;
    }
    const [, val] = String(value).split('.');
    return `${val}折`;
  },
  /**
   * 积分
   * @author  韦胜健
   * @date    2021/9/7 17:28
   */
  integral(value?: string | number) {
    if (value == null) {
      return null;
    }
    value = Number(value);
    if (value < 10000) {
      return value;
    }
    return `${Math.floor(value / 1000) / 10}万`;
  },
  /**
   * 倒计时
   * @author  韦胜健
   * @date    2021/9/7 17:28
   * @param   value         一个未来日期；
   * @param   valueFormat   用来将未来日期解析成dayjs对象的格式化字符串，比如未来日期为"2021-09-07 22:00:00"，那么 valueFormat应该为 "YYYY-MM-DD HH:mm:ss"
   *                        如果 value未来日期为 "2021/09/07  22:00:00"，那么valueFormat就应该是"YYYY/MM/DD  HH:mm:ss"，默认情况下，valueFormat为 "YYYY-MM-DD HH:mm:ss"
   * @return  countdown 函数会计算未来日期与当前日期之间的时间间隔，如果小于48小时，则返回的格式为 "23:40:57"，如果大于48个小时，返回的格式为："2天4小时"。如果当前时间大于未来时间，则返回"已结束"；
   */
  countdown(value?: string, { valueFormat }: { valueFormat?: string } = {}) {
    if (value == null) {
      return null;
    }
    valueFormat = valueFormat || 'YYYY-MM-DD HH:mm:ss';
    const now = Dayjs(state.now, valueFormat);
    const next = Dayjs(value, valueFormat);
    const { days, hours, minutes, seconds, time } = calcInterval(now.toDate().getTime(), next.toDate().getTime());
    if (time < 0) {
      return '已结束';
    }
    if (days >= 2) {
      return `${days}天${hours}时`;
    } else {
      const hoursFixed = formatZero(`${hours + days * 24}`, 2);
      const minutesFixed = formatZero(`${minutes}`, 2);
      const secondsFixed = formatZero(`${seconds}`, 2);
      return `${hoursFixed}:${minutesFixed}:${secondsFixed}`;
    }
  },

  /**
   * 计算两个时间戳之间相差的天数，小时、分钟以及秒钟；
   * @author  李博
   * @date    2023/3/13
   */
   calcInterval(startTime: number, endTime: number) {
    // 两个时间戳相差的毫秒数
    let usedTime = endTime - startTime;
    if (usedTime < 0) {
      usedTime = -usedTime;
    }
    const daysUseTime = 24 * 60 * 60 * 1000;
    const days = Math.floor(usedTime / daysUseTime);
    usedTime = usedTime - days * daysUseTime;

    const hoursUseTime = 60 * 60 * 1000;
    const hours = Math.floor(usedTime / hoursUseTime);
    usedTime = usedTime - hours * hoursUseTime;

    const minutesUseTime = 60 * 1000;
    const minutes = Math.floor(usedTime / minutesUseTime);
    usedTime = usedTime - minutes * minutesUseTime;

    const secondsUseTime = 1000;
    const seconds = Math.floor(usedTime / secondsUseTime);

    return {
      days,
      hours,
      minutes,
      seconds,
      time: endTime - startTime
    };
  },
  /**
   * 日期值格式化
   * @author  韦胜健
   * @date    2021/9/8 16:19
   */
  date: (() => {

    const valueRegList: [RegExp, string][] = [
      [/\d{4}-\d{2}-\d{2}/, 'YYYY-MM-DD'],
      [/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/, 'YYYY-MM-DD HH:mm:ss'],
      [/\d{4}\/\d{2}\/\d{2}/, 'YYYY/MM/DD'],
      [/\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}/, 'YYYY/MM/DD HH:mm:ss']
    ];

    const getValueFormat = (value: string) => {
      for (const i of valueRegList) {
        const [test, format] = i;
        if (test.test(value)) {
          return format;
        }
      }
      return undefined;
    };

    const displayRegList: [RegExp, string][] = [
      [/\d{4}-\d{2}-\d{2}/, 'YYYY年MM月DD日'],
      [/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/, 'YYYY年MM月DD日 HH时mm分ss秒'],
      [/\d{4}\/\d{2}\/\d{2}/, 'YYYY年MM月DD日'],
      [/\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}/, 'YYYY年MM月DD日 HH时mm分ss秒']
    ];

    const getDisplayFormat = (value: string) => {
      for (const i of displayRegList) {
        const [test, format] = i;
        if (test.test(value)) {
          return format;
        }
      }
      return undefined;
    };

    return (value?: string | Date, displayFormat?: string, valueFormat?: string) => {
      if (value == null) {
        return null;
      }
      if (value instanceof Date) {
        return Dayjs(value).format(displayFormat || 'YYYY年MM月DD日 HH时mm分ss秒');
      } else {
        if (!valueFormat) {
          valueFormat = getValueFormat(value);
          if (valueFormat == null) {
            console.error(`无法自动识别日期字符串:${value}, 请手动配置该日期的valueFormat参数！`);
            return 'Invalid Date!';
          }
        }
        const dayjs = Dayjs(value, valueFormat!);
        return dayjs.format(displayFormat || getDisplayFormat(value) || displayRegList[0][1]);
      }
    };
  })(),
  /**
   * 时间距离
   * @author  韦胜健
   * @date    2021/9/8 16:25
   * @param   value         格式化的值
   * @param   interval      一个以毫秒为单位的时间戳，当value与当前时间的间隔超过这个毫秒数的时候，就会返回这个日期的原始值
   * @param   valueFormat   用来将value格式化为Date对象，默认为：'YYYY-MM-DD HH:mm:ss'
   */
  interval: (value?: string, interval: number = ONE_DAY * 2, valueFormat = 'YYYY-MM-DD HH:mm:ss') => {
    if (value == null) {return;}
    const now = Dayjs(new Date(), valueFormat);
    const dayjs = Dayjs(value, valueFormat);
    const { days, hours, minutes, time } = calcInterval(dayjs.toDate().getTime(), now.toDate().getTime());
    if (Math.abs(time) > interval) {
      return value;
    } else {
      return [
        days == 0 ? null : `${days}天`,
        hours == 0 ? null : `${hours}时`,
        minutes == 0 ? null : `${minutes}分`,
        time > 0 ? '前' : '后'
      ].filter(Boolean).join('');
    }
  }
};

export function installLibFormatterFilters(Vue: any) {
  Object.entries(LibFormatter).forEach(([key, filter]) => {
    Vue.filter(key, filter);
  });
}
