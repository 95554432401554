
  import { LibButton, LibPopover } from '@adidas-com-front/ui-library';
  import { Utils } from "./utils";

  export default {
    components: {
      LibButton, LibPopover
    },
    props: {
      showPopover: {
        type: Boolean,
        default:false
      },
      surveyCode: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isApp: false
      };
    },
    computed: {
      isIphoneX() {
        if(typeof window !== 'undefined' && window) {
          return /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
        }
        return false;
      },
      config() {
        return this.$store.state.clp.surveyMemberOnlyConfig;
      },
      currentSurveyConfig() {
        const configArray = this.config.filter((item) => {
          return item.surveyCode && item.surveyCode === this.surveyCode;
        });
        const noneSurveyCodeConfigArray = this.config.filter((item) => {
          return !item.surveyCode;
        });
        const currentTime = new Date().getTime();

        if (configArray && configArray.length) {
          configArray.sort((a, b) => {
            return new Date(b.startTime).getTime() - new Date(a.startTime).getTime();
          });
          const defaultConfig = configArray.find((item) => {
            return !item.startTime;
          });
          const config = configArray.find((item) => {
            return currentTime > new Date(item.startTime).getTime();
          }) || defaultConfig;
          return config;
        } else {
          noneSurveyCodeConfigArray.sort((a, b) => {
            return new Date(b.startTime).getTime() - new Date(a.startTime).getTime();
          });
          const defaultConfig = noneSurveyCodeConfigArray.find((item) => {
            return !item.startTime;
          });
          const config = noneSurveyCodeConfigArray.find((item) => {
            return currentTime > new Date(item.startTime).getTime();
          }) || defaultConfig;
          return config;
        }
      },
      title() {
        return this.currentSurveyConfig?.title || '会员专属内容';
      },
      desc() {
        return this.currentSurveyConfig?.subtitle || "加入会员即可解锁趣味玩法/专属活动等精彩内容";
      },
      popoverBg() {
        return this.currentSurveyConfig?.bannerPath || '';
      },
      ctaText() {
        return this.currentSurveyConfig?.ctaText || '立即解锁';
      }
    },
    mounted () {
      const utils = new Utils(this);
      this.isApp = utils.IsAPP;
    },
    methods: {
      handlePopChange(){
        this.$emit('updateShow', !this.showPopover);
      },
      async handleClick() {
        this.$emit('updateShow', !this.showPopover);
        const utils = new Utils(this);
        const isMemberLogin = await utils.loginAsync();
        if (isMemberLogin) {
          this.$emit('refreshLastResult');
        }
      }
    }
  };
