
export default {
  data() {
    return {
      title: '  ',
      itemList: [],
      scrollBottom:false,
      maxLength:3
      // // 图片格式为全尺寸请注意
      // adidasQR: `${this.$config.S3_HOST}/p05/right/tiktokadidas.png`,
      // womenQR: `${this.$config.S3_HOST}/p05/right/tiktokwomen.png`,
      // kidsQR: `${this.$config.S3_HOST}/p05/right/tiktokkids.png`
    };
  },
  async mounted() {
    const configObj = await this.$axios.get(
      `${this.$config.S3_COM_HOST}/static/douyin/douyin.json`
    );
    if (configObj?.douyinFloatingWindowContentConfig) {
      this.title=configObj.douyinFloatingWindowContentConfig.title;
      const itemList = configObj.douyinFloatingWindowContentConfig.itemList;
      itemList.sort((a, b) => {
        return (
          new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
        );
      });
      const currentTime = new Date().getTime();
      let no=0;
      for (const item of itemList) {
        if (currentTime > new Date(item.startTime).getTime() && currentTime<  new Date(item.endTime).getTime()) {
          no+=1;
          this.itemList.push({
            ...item,
            key:`item-${no}`
          });
        }
      }
      this.title = configObj.douyinFloatingWindowContentConfig.title;
    }
    if(this.$refs.content){
      const content=this.$refs.content;
      content.addEventListener('scroll', this.onScroll);
    }
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    if(this.$refs.content){
      const content=this.$refs.content;
      content.removeEventListener('scroll', this.onScroll);
    }
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onScroll(e){
        // 设置滑动块高度
        const {scrollTop, clientHeight, scrollHeight} = e.target;
        this.scrollBottom=(scrollTop + clientHeight === scrollHeight);
        console.log("scrollBottom",(scrollTop + clientHeight === scrollHeight));
        // console.log(scrollTop, clientHeight, scrollHeight)
    },
    onResize() {
      if( window.innerWidth < 1040){
        this.maxLength=2;
      }else{
        this.maxLength=3;
      }
      
    }
  }
};
