
  import Dayjs from '@adidas-com-front/ui-library/src/components/atoms/LibFormat/dayjs.min';
  import { mapState } from 'vuex';
  import * as myAccountTrack from '../../../pages/index/track';

  export default {
    props: {
      item: {
        type: Object,
        default: () => {
          return {};
        }
      },
      show: {
        type: Boolean,
        default: false
      },
      source: {
        type: String
      }
    },
    data() {
      return {
        isSuccess: false
      };
    },
    watch: {
      show(newValue, oldValue) {
        if (newValue) {
          this.isSuccess = false;
        }
      },
      'couponData.length': {
        handler(val) {
          this.isSuccess = false;
        }
      }
    },
    filters: {
      discountFilter: function(value) {
        const y = String(value).indexOf(".") + 1;
        if (y > 0) {
          return value*10;
        } else {
          return value;
        }
      }
    },
    computed: {
      ...mapState('cart', [
        'couponData'
      ]),
      ...mapState('myAccount', [
        'isLogin',
        'isEmployeeLogin',
        'isNonmemberLogin'
      ]),
      endTime() {
        return Dayjs(this.item.endTime).format('YYYY-MM-DD HH:mm') + "前可领";
      },
      btnName() {
        if (!this.isSuccess) {
          return '立即领取';
        } else {
          return '已领取';
        }
      }
    },
    methods: {
      async receiveCoupon(item) {
        try {
            this.$aloading.show();
            const promoList = [];
            promoList.push(item.promoCodeId);
            const data = await this.$axios.post(`${this.$config.ECP_API_HOST}/o2pmt/v1/promocode/acquire/batch`,
              promoList
            );
            this.$aloading.hide();
            if (data[0].success) {
              this.$Toast({ message: '领取成功',  ref: this.$refs.containers});
              this.isSuccess = true;
            } else {
              setTimeout(() => {
                this.$Toast({ message: '领取失败，请再试一次', ref: this.show ? this.$refs.containers : '' });
              }, 500);
              this.$emit('refreshCouponData');
              this.isSuccess = false;
            }
          this.receiveCouponTrack(this.isSuccess);
          } catch (e) {
            this.$aloading.hide();
            this.$Toast({ message: '领取失败，请再试一次', ref: this.show ? this.$refs.containers : '' });
            console.error('领取pdp优惠码失败: ' + e);
            this.isSuccess = false;
            return '';
          }
      },
      // 领取优惠券的埋点
      receiveCouponTrack(flag) {
        const obj = {
          couponamount: this.item.discount || this.item.reduction,
          couponid: this.item.promoCodeId,
          couponname: this.item.promoCodeName,
          coupontype: '优惠码',
          pageSource: this.source,
          isValid: flag
        };
        // console.log("埋点couponList",couponList,obj);
        try {
          myAccountTrack.CouponRecieveClick(obj);
        } catch (e) {
          console.warn('CouponRecieveClick', e);
        }
      },
      handleClick() {
        if (this.isSuccess) {
          return false;
        }
        if (this.isNonmemberLogin) {
          console.log('非会员登陆，引导入会');
          this.$emit('updateShow');
          this.$emit('loginMember');
        } else if (this.isLogin) {
          console.log('已经登陆直接领券');
          this.receiveCoupon(this.item);
        } else {
          console.log('未登录，请登录，弹出没有非会员入口的登陆半窗');
          this.$emit('updateShow');
          this.$emit('loginMember');
        }
      }
    }
  };
