
import Vue from 'vue';
import toast from './index.vue';

// 将vue组件变为构造函数
const ConfirmConstructor = Vue.extend(toast);

let newInstance = null;
let notFirst = true;
const init = (options)=>{
  const defaultConfig = {
    htmlMsg: '',
    // 传入的message信息,默认message,可传入信息均可不填
    message: '',
    // 显示时间
    delay: 2000,
    // 显示位置,可不传
    position: '',
    // left距离
    left: null,
    // top距离
    top: null,
    // 传入元素ref 这样可以控制显示的位置
    ref: null,
    // 计算显示元素的宽度
    msgWidth: null,
    // 计算显示元素的高度
    msgHeight: null,
    width: 'auto',
    // 给了几个直接可控位置
    composition: '',
    timer: null,
    resolveList: []
  };
  notFirst = !!newInstance;
  // 实例化组件
  newInstance = newInstance || new ConfirmConstructor();
  // 如果不是首次实例化，则需要将配置初始化，否则会遗留上次的脏配置
  if (notFirst) {
    Object.assign(newInstance,defaultConfig);
  }
  // 合并配置选项
  Object.assign(newInstance,options);
  // 使用$mount()后  可以理解为创建虚拟的dom
  document.body.appendChild(newInstance.$mount().$el);
};
const caller = (options)=>{
  // options 为调用组件方法时传入的配置选项
  if(!options.ref){
    options.ref =null;
  }
  init(options);

  // if(!newInstance){
  //     init(options);
  // }
  return newInstance.show();
};
export default {
  install(vue){
    vue.prototype.$Toast = caller;
  }
};
