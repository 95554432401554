
import SearchBarRest from '~/components/search/search-bar/SearchBarRest.vue';
import { trackSearchClick } from '~/pages/search/track';

export default {
  name: 'ErrorHeader',
  components: { SearchBarRest },
  data() {
    return {
      left: 'icon-a-32_icon_performance',
      width: null,
      isWXBrowser: true,
      isQQBrowser: true,
      isSticky: false
    };
  },
  computed: {
    // 购物车数量
    cartNum() {
      return this.$store.state.homePage.navInfo.cartNum;
    }
  },
  mounted() {
    this.isWXBrowser = this.isWeiXin();
    this.isQQBrowser = this.isQQ();
  },
  updated() {
    try {
      if (!this.isWXBrowser && !this.isQQBrowser) {
        const video = document.querySelector('video');
        video.muted = true;
        video.autoplay = true;
        video.play();
      }
    } catch (error) {
      console.log('luckydraw video播放报错', error);
    }

  },
  methods: {
    handleClick() {
      this.$emit('handleClick');
    },
    handleSearch() {
      this.$router.push({
        query: {
          VisibleFlag: 'replace'
        }
      });
      trackSearchClick(this.$route.path);
    },
    gotoCatalog() {
      this.$router.push({
        path: `/catalog/catalog-mobile`
      });
    },
    gotoCart() {
      this.$router.push({
        path: `/cart`
      });
    },
    handleLeft() {
      this.$router.push({
        path: `/`
      });
    },
    getValue(value) {
      //
    },
    // 判断是否是微信内置浏览器
    isWeiXin() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)) {
        return ua.match(/MicroMessenger/i)[0] === 'micromessenger';
      } else {
        return false;
      }
    },
    isQQ() {
      const ua = navigator.userAgent.toLocaleLowerCase();
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return true;
      } else {
        return false;
      }
    }
  }
};
