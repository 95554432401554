/**
 * 创建一个双向绑定的管理对象
 * @author  韦胜健
 * @date    2021/9/6 16:40
 * @param propName        双向绑定的属性名称，可选参数，默认值为value;
 * @param context         组件上下文对象
 */
import Vue from 'vue';

export function createModel({ propName, context }) {
  propName = propName || 'value';

  const state = Vue.observable({ val: context[propName] });

  const model = {
    get value() {
      return state.val;
    },
    set value(val) {
      if (state.val !== val) {
        state.val = val;
        if (propName === 'value') {
          context.$emit('input', val);
          context.$emit('change', val);
        } else {
          context.$emit(`update:${propName}`, val);
          context.$emit(`${propName}Change`, val);
        }
      }
    },
  };

  context.$watch(
    () => context[propName],
    (val) => {
      if (state.val !== val) {
        state.val = val;
      }
    }
  );

  return model;
}
