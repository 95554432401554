/*
 * @Description: login 相关神策埋点
 * @Autor: Duan,JingLiang
 * @Date: 2021-10-13 14:25:13
 */
const jwt = require('jsonwebtoken');
const { decodeURIComponentSafe, detectOS, isLogin, TokenUtils } = require('~/utilities/index');

// eslint-disable-next-line camelcase
let tracker = {} as any;
let sensorsSetPublicProps: (params: any) => any;
let getDistinctIds: () => any;
if (process.client) {
  const sensors = require('~/utilities/vendors/sensors');
  sensorsSetPublicProps = sensors.sensorsSetPublicProps;
  getDistinctIds = sensors.getDistinctIds;
  tracker = sensors.default;
}
export function trackLogin(
  loginFrom: String,
  loginType: String,
  pageName: String,
  pageType: String,
  acceptSubscription: Boolean,
  isJointMember:Boolean,
  joinType:string
): void {
  try {
    console.log('click_login', {
      login_from: loginFrom,
      login_type: loginType,
      page_name: pageName,
      page_type: pageType,
      accept_subscription: acceptSubscription,
      is_joint_member:isJointMember,
      joint_type:joinType
    });
    tracker.track('click_login', {
      login_from: loginFrom,
      login_type: loginType,
      page_name: pageName,
      page_type: pageType,
      accept_subscription: acceptSubscription,
      is_joint_member:isJointMember,
      joint_type:joinType
    });
  } catch (error) {
    console.warn('sensor:[click_login]', error);
  }
}

export function trackVerificode(pageName: String, pageType: String,isJointMember:Boolean,joinType:string): void {
  try {
    console.log('click_send_vrfct_code', {
      page_name: pageName,
      page_type: pageType,
      is_joint_member:isJointMember,
      joint_type:joinType
    });
    tracker.track('click_send_vrfct_code', {
      page_name: pageName,
      page_type: pageType,
      is_joint_member:isJointMember,
      joint_type:joinType
    });
  } catch (error) {
    console.warn('sensor:[click_send_vrfct_code]', error);
  }
}

export async function trackSpecialLogin(identityType: String, account: String): Promise<void> {
  await setGlobalParams();
  try {
    tracker.track('Special_Login', {
      identity_type: identityType,
      account
    });
  } catch (error) {
    console.warn('[Special_Login]', error);
  }
}

export function trackSpecialLogout(
  identityType: String,
  account: String
): void {
  try {
    tracker.track('Special_Logout', {
      identity_type: identityType,
      account
    });
  } catch (error) {
    console.warn('[Special_Logout]', error);
  }
}

export async function setGlobalParams() {
  const distinctId = await
  getDistinctIds();
  const memberInfo = jwt.decode(TokenUtils.getOriginToken() || '');
  const memberId = memberInfo?.member_id;
  let  URLDdecoded=window.location.href;
  try{
    URLDdecoded=decodeURI(decodeURIComponentSafe(window.location.href));
  }catch{}
  const publicParams = {
    URL_decoded: URLDdecoded,
    com_cookie_id: memberId || distinctId,
    language: 'ZH',
    logged_in: isLogin() ? 'TRUE' : 'FALSE',
    memberID: memberId || distinctId,
    page: window.location.pathname,
    user_agent: navigator.userAgent || '',
    os_type: detectOS(),
    referrer: document.referrer || '',
    is_login: isLogin() ? 'TRUE' : 'FALSE'
  };

  sensorsSetPublicProps(publicParams);
}

export function newuserSubscribeNotificationView(pageName: String,isJointMember:Boolean,joinType:string){
  try {
    console.log('Newuser_Subscribe_Notification_View', {
      page_name: pageName,
      is_joint_member:isJointMember,
      joint_type:joinType,
      page_type:"ACCOUNT"
    });
    tracker.track('Newuser_Subscribe_Notification_View', {
      page_name: pageName,
      is_joint_member:isJointMember,
      joint_type:joinType,
      page_type:"ACCOUNT"
    });
  } catch (error) {
    console.warn('sensor:[Newuser_Subscribe_Notification_View]', error);
  }
}
export function newuserSubscribeNotificationClick(pageName: String,isJointMember:Boolean,joinType:string,interactionType:"Agree"|"Close"){
  try {
    console.log('Newuser_Subscribe_Notification_Click', {
      page_name: pageName,
      is_joint_member:isJointMember,
      joint_type:joinType,
      interaction_type:interactionType,
      page_type:"ACCOUNT"
    });
    tracker.track('Newuser_Subscribe_Notification_Click', {
      page_name: pageName,
      is_joint_member:isJointMember,
      joint_type:joinType,
      interaction_type:interactionType,
      page_type:"ACCOUNT"
    });
  } catch (error) {
    console.warn('sensor:[Newuser_Subscribe_Notification_Click]', error);
  }
}