import { render, staticRenderFns } from "./index.vue?vue&type=template&id=496aa30c&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=496aa30c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496aa30c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationBar: require('/usr/src/app/packages/nuxt/components/navigation/NavigationBar.vue').default})
