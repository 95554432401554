
  import {
    trackClpPdpClick,
    trackClpPlpClick,
    trackClpButtonClick,
    getScrollDepth
  } from '../track/index';
  import { getCurrentLink } from '../util';
  import minibanner from './minibanner.vue';
  import minibannerV2 from './minibannerV2.vue';
  import sideslip from './sideslip.vue';
  import tiled from './tiled';
  import { parseURL } from '~/utilities/helper/urlHelper';

  export default {
    components:{minibanner,tiled,sideslip,minibannerV2},
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        }
      },
      position: {
        type: String
      },
      tabName: {
        type: String,
        default: ''
      },
      code: {
        type: String
      },
      clpShow:{
        type: Boolean,
        default: true
      }
    },
    data() {
      return {isMobile:false,countdown:false};
    },
    mounted() {
      this.onResize();
      window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
    methods: {
      onResize() {
        this.isMobile = window.innerWidth < 720;
      },
      // 埋点
      trackData(val) {
        const itemLabelInfoList = [];
        val.item.labelInfo?.forEach((labelItem) => {
          itemLabelInfoList.push(`${labelItem.labelGroup}:${labelItem.label.join('|')}`);
        });
        let data = [];
        const curTerminalLink = val.item.allLink ? getCurrentLink(val.item.allLink) : val.item.link || '';
        const url = parseURL(curTerminalLink);
        const directAppProject = curTerminalLink.includes('BOS') && 'FS' || curTerminalLink.includes('CFM') && 'CFD' || '';
        switch (val.type) {
          case 'plp':
            data = {
              sub_campaign_name: this.code,
              campaign_name: this.$route.query.contentId || '',
              text: '',
              component_code: 'NBanner',
              position_code: Number(this.position.slice(1)),
              card_position: 1,
              scroll_depth: getScrollDepth(this.$refs.nbannerContainer.offsetTop),
              plp_url: url.path,
              plp_name: '',
              tab_name: this.tabName,
              tag: this.data.labelInfoList.join(','),
              Nbanner_type: this.data.mulPhotoType,
              is_has_countdown:!!val.item.displayStartTime,
              multii_item_tag: itemLabelInfoList?.join(','),
              Hotzone_Number: val.item.isContainHotZone ? (val.item.key + 1) : 'no hotzone'
            };
            trackClpPlpClick(data);
            break;
          case 'pdp':
            data = {
              sub_campaign_name: this.code,
              campaign_name: this.$route.query.contentId || '',
              text: '',
              component_code: 'NBanner',
              position_code: Number(this.position.slice(1)),
              article_position: 1,
              tab_name: this.tabName,
              recommend_id: '',
              scroll_depth: getScrollDepth(this.$refs.nbannerContainer.offsetTop),
              category_code: '',
              article_number: url?.query?.articleId || '',
              brand: '',
              category: '',
              gender: '',
              tag: this.data.labelInfoList.join(','),
              Nbanner_type: this.data.mulPhotoType,
              is_has_countdown:!!val.item.displayStartTime,
              multii_item_tag: itemLabelInfoList?.join(','),
              Hotzone_Number: val.item.isContainHotZone ? (val.item.key + 1) : 'no hotzone'
            };

            trackClpPdpClick(data);
            break;
          case 'button':
            data = {
              campaign_name: this.$route.query.contentId || '',
              sub_campaign_name: this.code,
              text: '',
              component_code: 'NBanner',
              position_code: Number(this.position.slice(1)),
              scroll_depth: getScrollDepth(this.$refs.nbannerContainer.offsetTop),
              to_url: curTerminalLink || '',
              button_name: curTerminalLink === '领券' ? '领券' : '',
              tab_name: this.tabName,
              tag: this.data.labelInfoList.join(','),
              is_has_countdown:!!val.item.displayStartTime,
              Nbanner_type:this.data.mulPhotoType,
              multii_item_tag: itemLabelInfoList?.join(','),
              Hotzone_Number: val.item.isContainHotZone ? (val.item.key + 1) : 'no hotzone'
            };
            directAppProject ? data.direct_app_project = directAppProject : '';
            trackClpButtonClick(data);
            break;
        }
      },
      handleClickSpecialLink(link) {
        this.$emit('clickSpecialLink',link);
      },
      getClpView(data){
        if(!data?.is_has_countdown===true){
          data.is_has_countdown=this.countdownIsShow;
        }
      }
    }
  };
