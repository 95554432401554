/*
 * @Description: 界面相关工具函数
 * @Autor: Duan,JingLiang
 * @Date: 2021-09-06 13:37:36
 * @LastEditors: Duan,JingLiang
 * @LastEditTime: 2021-09-06 18:15:52
 */

const reduceDatas =
  (query: { [x: string]: any }) => (prev: any, curr: string | number) => {
    const makeArray = Array.isArray(query[curr]);

    return {
      ...prev,
      [curr]: makeArray ? query[curr] : [query[curr]],
    };
  };

/**
 * 获取URL参数
 * @param {*} context eg: this
 * @returns
 */
export const getParamsFromUrl = (context: any) => {
  const { query } = context.$router.history.current;

  return Object.keys(query).reduce(reduceDatas(query), {});
};

/**
 * 多选参数转换
 * [color]红||蓝 => { color: ['红', '蓝'] }
 */
export const parseBracketStrAsObject = (data: string) => {
  const matches = data.match(/\[([^\]]+)\]/);
  if (matches && matches.length > 1) {
    const key = matches[1];
    const kMasked = matches[0];
    const valStr = data.replace(kMasked, ''); // 红||蓝
    const valArr = valStr.split('||'); // For multiple values
    return { [key]: valArr };
  }
  return data;
};

/**
 * 手机号转换
 * input: 189****9709
 * output: 189*******9
 * @returns {string}
 */
export const transformPhoneNumber = (numberRaw: string | undefined) => {
  if (!numberRaw) return '';
  return ''.concat(numberRaw.slice(0, 3), '*******', numberRaw.slice(-1));
};

/**
 * 函数扁平
 * @param arr
 * @returns
 */
export const flatten: any = (arr: Array<Array<string | number>>) => {
  return arr.reduce((flat: string | any[], toFlatten: any) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
};

/**
 * URL参数解码
 * @param encoded
 * @returns
 */
export function decodeURIComponentSafe(encoded: string) {
  try {
    return decodeURIComponent(encoded || '');
  } catch (e) {
    return '';
  }
}
