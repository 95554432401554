
  import deepcopy from 'deepcopy';
  import { mapState } from 'vuex';
  import { trackClpPdpClick ,trackClpButtonClick,trackClpPlpClick,getScrollDepth} from '../track';
  import { handleClickLink, getCurrentLink } from '../util';
  import pc from "./components/pc";
  import mobileVerticalTabPage from "./components/mobile.vue";
  import { parseURL } from '~/utilities/helper/urlHelper';
  import { filterProducts } from "~/components/clp/common/productUtil";
  export default {
    name: 'VerticalTabPage',
    components: {
      mobileVerticalTabPage,
      pc
    },
    props: {
      code:{
        type: String,
        default: ''
      },
      data: {
        type: Object,
        default: () => {
          return {};
        }
      },
      position: {
        type: String
      },
      tabName: {
        type: String
      }
    },
    data() {
      return {
        isMobile: false,
        viewWidth: 0,
        active:0
      };
    },
    computed: {
      ...mapState('clp', [
        'plpListMap',
        'articleList'
      ]),
      computedBackgroundStyle() {
        if (this.data.backgroundColor || this.data.mobBackgroundImage || this.data.pcBackgroundImage) {
          const style={
            backgroundColor: this.data.backgroundColor || "transparent",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
            backgroundImage: `url(${this.isMobile ? this.data.mobBackgroundImage : this.data.pcBackgroundImage})`,
            maxWidth: "1920px",
            margin: "0 auto"
          };
          return style;
        } else {
          return {};
        }
      },
      backgroundStyle() {
        const style={
          backgroundColor:(this.data?.contentBackgroundColor)?this.data.contentBackgroundColor:"transparent",
          maxWidth: "1440px",
          margin: "0 auto"
        };

        if(this.isMobile&&this.data?.contentMobBackgroundImage){
          style.backgroundSize='cover';
          style.backgroundRepeat='no-repeat';
          style.backgroundPosition="top center";
          style.backgroundImage=`url(${this.data.contentMobBackgroundImage})`;
        }else if(!this.isMobile&&this.data?.contentPcBackgroundImage){
          style.backgroundSize='cover';
          style.backgroundRepeat='no-repeat';
          style.backgroundPosition="top center";
          style.backgroundImage=`url(${this.data.contentPcBackgroundImage})`;
        }
        return style;
      },
      miniCatalogData() {
        const list = deepcopy(this.data.itemList);
        if (list && list.length) {
          list.forEach((vi) => {
            const isTransparent=vi?.transparentFlag?.toString()==="1";
            vi.isTransparent=isTransparent;
            vi.articleDetailList = [];
            if (vi.maintainType === 'article') {
              const articleDetailList = this.articleList.filter((article) => {
                return vi.articleList.includes(article.articleId);
              });
              const list =filterProducts(isTransparent,articleDetailList,vi);
              if (list.length)
                vi.articleDetailList.push(...list);
            } else {
              const dLink = vi.searchTerm === 'categoryCode' ? `/plp?categoryCode=${vi.categoryCode}` : `/plp?keyword=${vi.keyword}`;
              const articleDetailObj = this.plpListMap.find((plp) => {
                return dLink === plp.link;
              });
              if (articleDetailObj){
                const list=filterProducts(isTransparent,[...articleDetailObj.content],vi);
                vi.articleDetailList.push(...list);
              }
            }
            vi.link = vi.tabRedirectLink;
          });
          const obj = {
            ...this.data
          };
          delete obj.itemList;
          obj.itemList = list;
          return obj;
        } else {
          return this.data;
        }
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleWidth);
      this.handleWidth();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleWidth);
    },
    methods: {
      handlePdpClick(tab,banner,index){
        let categoryCode='';
        if(tab.maintainType==='PLP'&&tab.searchTerm==="categoryCode"){
          categoryCode=tab.categoryCode;
        }
        const text = [banner.title?.text, banner.subtitle?.text].filter(item => {
        return !!item;
      });
        const data={
          campaign_name: this.$route.query.contentId || '',
          sub_campaign_name: this.code,
          text: text,
          component_code: 'VerticalTabPage',
          position_code: Number(this.position.slice(1)),
          article_position: index+1,
          tab_name: this.tabName,
          vertical_tab_name: tab.tabTitle,
          recommend_id: '',
          scroll_depth: getScrollDepth(this.$refs.verticalTabPage.offsetTop),
          category_code: categoryCode,
          article_number:banner.articleId,
          brand: '',
          category:banner.plpType,
          gender: banner.genderShow,
          tag: this.data?.labelInfoList?.join(',')
        };
        console.log("handlePdpClick",data);
        trackClpPdpClick(data);
      },
      handleClickTab(idx,tab){
        this.active=idx;
        const data={
          text:'',
          component_code: 'VerticalTabPage',
          tag: this.data?.labelInfoList?.join(','),
          position_code: Number(this.position.slice(1)),
          scroll_depth: getScrollDepth(this.$refs.verticalTabPage.offsetTop),
          campaign_name: this.$route.query.contentId || '',
          sub_campaign_name: this.code,
          tab_name: this.tabName,
          vertical_tab_name: tab.tabTitle,
          button_name:`Tab-${tab.tabTitle}`
        };
        console.log("trackClpButtonClick",data);
        this.$emit("switchTab",tab.tabTitle);
        trackClpButtonClick(data);
      },
      handleWidth() {
        this.isMobile = window.innerWidth < 720;
        this.viewWidth = window.innerWidth;
      },
      handleRedirect(val,tab, allLink) {
        const _options = {link: val, allLink: allLink};
        handleClickLink.call(this, _options);
        const curTerminalLink = _options.allLink ? getCurrentLink(_options.allLink) : _options.link || '';
       if(
        curTerminalLink.includes('/pages/product/list') ||
        curTerminalLink.includes('/pages/plp/index') ||
        curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/rich-list') ||
        curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
        curTerminalLink.includes('adidas://category') ||
        parseURL(curTerminalLink).path.startsWith('/plp') ||
        parseURL(curTerminalLink).path.startsWith('/splp')){
        const index=this.data.itemList.indexOf(tab);
        const data={
          campaign_name: this.$route.query.contentId || '',
          sub_campaign_name: this.code,
          tag: this.data?.labelInfoList?.join(','),
          text:'',
          component_code:'VerticalTabPage',
          position_code:Number(this.position.slice(1)),
          card_position:index+1,
          tab_name: this.tabName,
          vertical_tab_name: tab.tabTitle,
          scroll_depth: getScrollDepth(this.$refs.verticalTabPage.offsetTop),
          plp_url: curTerminalLink,
          plp_name:""
        };
        console.log('trackClpPlpClick',data);
        trackClpPlpClick(data);
       }else{
        const data={
          text:'',
          component_code: 'VerticalTabPage',
          tag: this.data?.labelInfoList?.join(','),
          position_code: Number(this.position.slice(1)),
          scroll_depth: getScrollDepth(this.$refs.verticalTabPage.offsetTop),
          campaign_name: this.$route.query.contentId || '',
          sub_campaign_name: this.code,
          button_name:`Link-${tab.tabRedirectText}`,
          vertical_tab_name: tab.tabTitle,
          tab_name: this.tabName,
          to_url: curTerminalLink
        };
        console.log("trackClpButtonClick",data);
        trackClpButtonClick(data);
       }
      },
      // 暴露给父组件调用获取Product
      getProduct(){
        if(this.active < this.data.itemList.length){
          const tab=this.data.itemList[this.active];
          let categoryCode='';
          if(tab.maintainType==='PLP'&&tab.searchTerm==="categoryCode"){
            categoryCode=tab.categoryCode;
          }
          // eslint-disable-next-line camelcase
          const articleIdList=[];
          for(const item of  tab.articleDetailList?.slice(0, 4)){
            articleIdList.push(item.articleId);
          }
          return {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            component_code: 'VerticalTabPage',
            tag_list: this.data?.labelInfoList?.join(','),
            category_code:categoryCode,
            position_code: Number(this.position.slice(1)),
            tab_name:tab.tabTitle,
            vertical_tab_name:tab.tabTitle,
            article_id_list:articleIdList?.join("|"),
            scroll_depth: getScrollDepth(this.$refs.verticalTabPage.offsetTop)
          };
        }
      }
    }
  };
