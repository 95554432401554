
  import { parseURL } from '../../../../utilities/helper/urlHelper';
  import { setBreadCrumbs, setPdpBreadCrumbs } from '../../utils';
  import { trackCatalogClick } from '../../track/index';
  export default {
    props: {
      comp: {
        type: Array
      },
      // 当前nbanner组件对应二级标题数据
      tagline: {
        type: Array
      },
      // 当前nbanner组件对应一级标题数据
      content: {
        type: Array
      },
      // 二级标题index
      taglineIndex: {
        type: Number,
        default: 0
      },
      nowNaItem: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        screenWidth: 0
      };
    },
    computed: {
      compData() {
        return this.$store.state.homePage.sortInfo.compData;
      },
      list() {
        const acc = [];
        this.comp.forEach((item) => {
          item.options.itemList.forEach((it) => {
            it.layoutType = item.options.layoutType;
            it.mulPhotoType = item.options.mulPhotoType;
            it.ratio = item.options.ratio;
            it.className = item.className;
            it.layoutPositionCode = item.layoutPositionCode;
            it.componentCode = item.componentCode;
          });
          acc.push(...item.options.itemList);
        });

        return acc;
      }
    },
    watch: {
      $route: {
        handler: function (route) {
          const offset = route.path === '/' || route.path.includes('my-account') ? 0 : 15;
          this.screenWidth = document.getElementById('__layout').clientWidth - offset;
        }
      }
    },
    mounted () {
      const offset = this.$route.path === '/' || this.$route.path.includes('my-account') ? 0 : 15;
      this.screenWidth = document.getElementById('__layout').clientWidth - offset;
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize',this.handleResize);
    },
    methods: {
      getComputedStyle(item) {
        if (this.screenWidth >= 1440){
          if (item.layoutType === 1 || (!item.layoutType && item.ratio === '3:2')) {
            return {width: `${(1120)/3}px`};
          } else if (item.layoutType === 2 || (!item.layoutType && item.ratio === '3:4')) {
            return {width: `${(1120)/6}px`};
          }
        } else if (this.screenWidth > 959) {
          if (item.layoutType === 1 || (!item.layoutType && item.ratio === '3:2')) {
            return {width: `${(this.screenWidth - 240 - 80)/3}px`};
          } else if (item.layoutType === 2 || (!item.layoutType && item.ratio === '3:4')) {
            return {width: `${(this.screenWidth - 240 - 80)/6}px`};
          }
        } else if (this.screenWidth > 719) {
          if (item.layoutType === 1 || (!item.layoutType && item.ratio === '3:2')) {
            return {width: `${(this.screenWidth - 240 - 80)/2}px`};
          } else if (item.layoutType === 2 || (!item.layoutType && item.ratio === '3:4')) {
            return {width: `${( this.screenWidth - 240 - 80)/4}px`};
          }
        }
      },
      handleResize() {
        this.screenWidth = document.getElementById('__layout').clientWidth;
      },
      handleRedirect(item) {
        const link = item.link;
        if(link) {
          let queryObj;
          let title = '';
          if (link.startsWith('https') || link.startsWith('http')) {
            window.open(link, '_blank');
          } else if(link.includes('pdp')) {
            const path = link;
            queryObj = parseURL(path);
            const breadStr = this.tagline[this.taglineIndex]?.options.headDescription + ':';
            setPdpBreadCrumbs(breadStr, item.articleId);
            title = item.articleName;
            queryObj.query = {
              ...queryObj.query
            };
            this.$router.push(queryObj);
          } else if (link.includes('plp')) {
            const noNavComp = this.compData[this.content[0].index];
            const idx = noNavComp.findIndex((it) => {return it.component[0].componentCode === item.componentCode && it.component[0].layoutPositionCode === item.layoutPositionCode; });
            let currentTagLine;
            for(let i = idx; i >= 0; i--) {
              if(noNavComp[i].component[0].componentCode === 'TAGLINE') {
                currentTagLine = noNavComp[i].component[0];
                break;
              }
            }
            console.log('currentTagLine', currentTagLine);
            const path = link;
            const breadStr = currentTagLine?.options.headDescription + ':';
            queryObj = parseURL(path);
            const key = queryObj.query.keyword
              ? decodeURI(queryObj.query.keyword)
              : queryObj.path.split('/')[queryObj.path.split('/').length - 1];
              setBreadCrumbs(key, breadStr);
            title = currentTagLine?.options.headDescription;
            queryObj.query = {
              ...queryObj.query,
              page: 1
            };
            this.$router.push(queryObj);
          }
          this.trackData(item, link, title);
          this.$emit('watchMore');
        }
      },
      trackData(item, linkTo, title) {
        const arr = linkTo.split('?');
        const len = arr.length;
        const query = len > 1 ? encodeURIComponent(arr[len - 1]) : '';
        const data = {
          catalog_title: this.nowNaItem.contentName,
          catalog_section: this.tagline[this.taglineIndex]?.options.headDescription,
          position_code: item.layoutPositionCode,
          component_code: item.componentCode,
          button_name: item.imgDescription,
          to_page_path: linkTo,
          to_page_query: query,
          to_page_title: title
        };
        console.log('pc nbanner click track Data', data);
        trackCatalogClick(data);
      }
    }
  };
