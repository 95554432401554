
import { createModel } from '../../../utilities/createModel';
import { LibButtonType, LibButtonItem } from './LibButton.utils.js';

export default {
  name: 'LibButton',
  props: {
    label: { type: String }, // 按钮文本
    type: {
      type: String,
      default: LibButtonType.primary,
    }, // 按钮主要类型：primary, secondary, in-line
    items: { type: String }, // 按钮次级类型：filled, unfilled, regular bold
    disabled: { type: Boolean }, // 按钮是否禁用
    block: { type: Boolean }, // 块级按钮，占满宽度
    width: { type: [Number, String] }, // 设置按钮宽度
    arrow: {
      type: Boolean,
      default: null,
    }, // 显示箭头图标
    icon: { type: String }, // 放在箭头位置的图标
    loading: { type: Boolean }, // 按钮是否处于加载状态
    loadingLabel: { type: String, default: '加载中' },
    asyncHandler: { type: Function }, // 异步处理函数，代替@click监听点击事件，会自动开启以及关闭加载状态
  },
  data() {
    const loadingModel = createModel({
      propName: 'loading',
      context: this,
    });

    return {
      LibButtonType,
      loadingModel,
      loadingState: {
        dot: '.',
        timer: null,
      },
    };
  },
  computed: {
    propsItems() {
      if (this.items != null) {
        return this.items;
      }
      if (this.type === 'primary' || this.type === 'secondary') {
        return 'filled';
      }
      if (this.type === 'in-line') {
        return 'regular';
      }
      return null;
    },
    labelCls() {
      switch (this.type) {
        case LibButtonType.primary:
          return 'button-1';
        case LibButtonType.secondary:
          return 'button-2';
        case LibButtonType['in-line']:
          switch (this.propsItems) {
            case LibButtonItem.regular:
              return 'button-3';
            case LibButtonItem.bold:
              return 'button-2';
          }
      }
      return null;
    },
    classes() {
      return [
        this.block ? 'flex' : 'inline-flex',
        this.labelCls,
        'items-center',
        'justify-between',
        'select-none',

        'lib-button',
        `lib-button-type-${this.type}`,
        `lib-button-items-${this.propsItems}`,
        {
          'lib-button-disabled': this.disabled,
          'lib-button-block': this.block,
        },
      ];
    },
    styles() {
      const styles = {};
      if (this.width != null) {
        styles.width = `${this.width}px`;
      } else if (!this.block) {
        switch (this.type) {
          case LibButtonType.primary:
            styles.width = `295px`;
            break;
          case LibButtonType.secondary:
            styles.width = `180px`;
            break;
        }
      } else {
        styles.width = '100%';
      }
      return styles;
    },
  },
  watch: {
    'loadingModel.value': {
      immediate: true,
      handler(val) {
        if (this.loadingState.timer != null) {
          clearInterval(this.loadingState.timer);
          this.loadingState.timer = null;
        }

        if (val) {
          this.loadingState.dot = '.';
          this.loadingState.timer = setInterval(() => {
            // console.log("warn: Interval p14");
            this.loadingState.dot = this.loadingState.dot + '.';
            if (this.loadingState.dot.length > 6) {
              this.loadingState.dot = '.';
            }
          }, 500);
        }
      },
    },
  },
  methods: {
    async handleClick(e) {
      if (this.loadingModel.value || this.disabled) {
        return;
      }
      if (!this.asyncHandler) {
        this.$emit('click', e);
      } else {
        try {
          this.loadingModel.value = true;
          await this.asyncHandler(e);
        } catch (e) {
          console.error(e);
        } finally {
          this.loadingModel.value = false;
        }
      }
    },
  },
};
