
import { auto } from '@popperjs/core';
export default {
  name: 'LibMask',
  props: {
    // 白色背景的mask
    whiteMask: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    // 是否可点击
    maskClick: {
      type: Boolean,
      default: true,
    },
    // 层级
    zIndex: {
      type: [Number, String],
      default: 1,
    },

    scrollIn: {
      type: Boolean,
      default: true,
    },
    // 蒙层黑色透明度
    opacity: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      shortNavigation: false,
      top: 0,
    };
  },
  computed:{
    computedStyle() {
      return {
        zIndex:this.zIndex,
        backgroundColor: this.whiteMask ? '#ffffff' : this.opacity !== null ? `rgba(0, 0, 0, ${this.opacity})` : '',
      };
    }
  },
  watch: {
    /**
     * @description: 遮罩显示情况下禁止页面滑动
     * @param {*} val true/false
     * @return {*}
     */
    visible(val) {
      if (val) {
        document.getElementById('layout-wrapper').style.height = '100%';
        document.getElementById('layout-wrapper').style.overflow = 'hidden';
        // document.getElementById('__layout').style.overflowY = 'scroll';
        const obj = document.getElementById('layout-wrapper');
        if (
          obj.scrollHeight > obj.clientHeight ||
          obj.offsetHeight > obj.clientHeight
        ) {
          document.getElementById('__layout').style.overflowY = 'scroll';
        }
        if (this.scrollIn === false) {
          window.addEventListener('touchmove', this.stopScroll, {
            passive: false,
          });
        }
      } else {
        // document.getElementsByTagName('body')[0].style.height = '';
        // document.getElementsByTagName('body')[0].style.overflow = '';
        document.getElementById('layout-wrapper').style.height = '';
        document.getElementById('layout-wrapper').style.overflow = '';
        document.getElementById('__layout').style.overflowY = '';

        if (this.scrollIn === false) {
          window.removeEventListener('touchmove', this.stopScroll);
        }
      }
    },
  },
  methods: {
    handleClick() {
      if (!this.maskClick) return;
      this.$emit('click');
    },

    stopScroll(event) {
      const e = event || window.event;
      e.preventDefault();
      e.stopPropagation();
    },
  },
};
