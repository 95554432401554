/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2022-10-31 16:12:51
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2022-10-31 17:05:50
 * @FilePath: /adidas-com-front/packages/nuxt/components/clp/searchKeyword/util.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { BasicUtils } from "../../../utilities/basic/utils";
interface iSearchKeyWord {
  hotWord: string;
  type: "red" | "default";
  icon?: string;
};
interface iHotWord {
  hotWordList: string[];
  hotWordTypeList: iSearchKeyWord[];
}
interface iProduct {
  aggregateArticleNum?: number;
  articleId: string;
  articleName?: string;
  brandName?: string;
  category?: string;
  colorImageUrl?: string;
  genderShow?: string;
  hypeFlag?: number;
  imageUrl?: string;
  labelList?: any[];
  modelNo?: string;
  purchaseFlag?: number;
  salePrice?: string;
  source?: string;
  subTitle?: string;
}
interface iSearchResult {
  content?: iProduct[];
  plpType?: string;
  sortingDowngradeFlag?: number;
  totalElements?: number;
  totalPages?: number;
}

export class Utils extends BasicUtils {
  async getSearchKeyWord(): Promise<iSearchKeyWord[]> {
    const getReq = this.axios.get(`${this.host}/o2srh/v1/pub/hot-word`, { ...this.config }) as Promise<iHotWord>;
    const data = await getReq;
    const searchKeyWordList: iSearchKeyWord[] = data.hotWordTypeList;
    return searchKeyWordList;
  }
  async getSearchResult(keyword: string): Promise<iSearchResult> {
    const getReq = this.axios.get(`${this.host}/o2srh/v1/pub/platform-products/search`,
      {
        ...this.config,
        params: {
          page: 1,
          pageSize: 24,
          abTest: 'A',
          keyword
        }
      }) as Promise<iSearchResult>;
    const data = await getReq;
    return data;
  }
}
