import deepcopy from 'deepcopy';
import { isClient, isLogin, isMobile } from '~/utilities';
import LocalCart from '~/components/cart/util/localCartService.ts';
import {BasicUtils} from '~/utilities/basic/utils';
const localCartService = new LocalCart();

const state = () => ({
  // 导航栏信息
  navInfo: {
    notifications: undefined
  },
  mobileNavItems: [],
  // 主页数据
  componentsDetail: [],
  componentsDetailPC: [],
  componentsDetailMobile: [],
  componentsData: [],
  feedProductList: {},
  // 分类页数据
  sortInfo: {
    // patch: [],
    // patchData: [],
    // plp: [],
    // plpData: [],
    // 商品列表数据
    productList: [],
    all: [],
    compData: [],
    taglineData: [],
    decorateCompData: []
  },
  hasPreviewPermission: false,
  seoInfo: {},
  closeCompatibilityTips: false,
  waitCheck:null
});

const mutations = {
  SET_NAV_INFO(state, status) {
    state.navInfo = { ...state.navInfo, ...status };
  },
  SET_COMPONENTS_DATA(state, status) {
    state.componentsData = status;
  },
  SET_COMPONENTS_DETAIL(state, status) {
    state.componentsDetail = status;
  },
  SET_COMPONENTS_DETAIL_MOBILE(state, status) {
    state.componentsDetailMobile = status;
  },
  SET_COMPONENTS_DETAIL_PC(state, status) {
    state.componentsDetailPC = status;
  },
  SET_FEED_PRODUCT(state, status) {
    state.feedProductList = status;
  },
  SET_PRODUCT_LIST(state, status) {
    state.sortInfo.productList = status;
  },
  // 页面有无预览权限
  SET_PREVIEW_PERMISSION(state, status) {
    state.hasPreviewPermission = status;
  },
  // seo数据
  SET_SEO_INFO(state, status) {
    state.seoInfo = status;
  },
  // 所有catalog数据
  SET_ALL_CATALOG_DATA(state, status) {
    state.sortInfo.all = status;
  },
  SET_ALL_COMP_DATA(state, status) {
    state.sortInfo.compData = status;
  },
  SET_TAGLINE_DATA(state, status) {
    state.sortInfo.taglineData = status;
  },
  SET_ALL_DECORATE_COMP_DATA(state, status) {
    state.sortInfo.decorateCompData = status;
  },
  SET_MOBILE_NAV_INFO(state, status) {
    state.mobileNavItems = status;
  },
  SET_COMPATIBILITY_STATUS(state, status) {
    state.closeCompatibilityTips = status;
  },
  SET_WAIT_CHECK(state, status){
    state.waitCheck = status;
  },
  SET_UNIX(state, status){
    state.isUnix = status;
  }
};
const hasCheckPermission={};
// eslint-disable-next-line no-unused-vars
const checkPermission=async (ctx,commit)=>{
  const waitCheckPermission=ctx.$store.state.homePage.waitCheck;
  if(waitCheckPermission){
    await waitCheckPermission;
  }
  const waitCheck= _checkPermission_(ctx);
  commit('SET_WAIT_CHECK', waitCheck);
  return await waitCheckPermission;
};
const _checkPermission_=async (ctx)=> {
  let isPreview=false;
  if(process.client){
    const utils=new BasicUtils(ctx);
    if((ctx?.$route?.path==="/"||ctx?.$route?.path==="/category")&&ctx?.$route?.query?.preview === '1'){
      let userID=utils.getUserID();
      
      if(!userID){
        await utils.loginByEnvAsync({
          redirectUrl: location.href,
          memberOnly: true,
          loginClp: false,
          isJointMember: true
        });
        userID=utils.getUserID();
        if(userID){
          await new Promise((resolve)=>{
            setTimeout(() => {
              resolve();
            }, 1000);
          });
        }
      }
      if(userID){
        if(userID in hasCheckPermission){
          return hasCheckPermission[userID];
        }
        isPreview=true;
      }else{
        return false;
      }
    }
    if(isPreview){
        const hasPermission = await ctx.$axios.get(
          `${ctx.$config.ECP_API_PRIVATE_HOST}/o2cms/v1/cms/preview/permission-check`
        );
        if (hasPermission?.prePermissionFlag) {
          const userID=utils.getUserID();
          hasCheckPermission[userID]=true;
          return true;
        }else{
          ctx.$router.push('error');
        }
    }
  }
  return false;
};

const actions = {
  async getNotification({ commit }, { ctx }) {
    const hasPermission = await checkPermission(ctx,commit);
    try {
      const data = await ctx.$axios.get(
        ctx.$config.ECP_API_HOST + '/o2cms/v1/pub/texts',
        {
          params: {
            pageType: 'homepage'
          },
          headers: {
            'x-preview-flag': hasPermission?1:0
          }
        }
      );
      commit('SET_NAV_INFO', { notifications: data.textGroup || [] });
    } catch (e) {}
  },
  async getNavItem({ commit, dispatch }, { ctx, previewPermission }) {
    const hasPermission = await checkPermission(ctx,commit);
    try {
      // const navParams = {};
      // if (previewPermission) {navParams.preview = '0';}
      const data = await ctx.$axios.get(
        ctx.$config.ECP_API_HOST + '/o2cms/v2/pub/navigation',
        {
          params: {},
          headers: {
            'x-preview-flag': hasPermission?1:0
          }
        }
      );
      const newNavItems = handleNavData(deepcopy(data.content));
      const filterNav = filterNavByTerminal(data.content);
      commit('SET_NAV_INFO', { navItems: newNavItems || [] });
      commit('SET_MOBILE_NAV_INFO', filterNav);
      const promiseParams = [];
      let currentSortItem = [];

      // const catalogParams = {};
      // if (previewPermission) { catalogParams.preview = '0'; }

      for (const i in filterNav) {
        if (filterNav[i].contentId) {
          promiseParams.push(
            new Promise((resolve, reject) => {
              const res = ctx.$axios.get(
                `${ctx.$config.S3_HOST}/f04/page_conf/COM/zh_CN/${hasPermission?"stage":"online"}/0/${filterNav[i].contentId}.json`,
                {
                  params: {}
                }
              );
              resolve(res);
            })
          );
        }
      }
      Promise.all(
        promiseParams.map((promise) =>
          promise.catch((e) => console.error('catalog数据请求出错！', e))
        )
      )
        .then((res) => {
          currentSortItem = res;
          currentSortItem.forEach((item, index) => {
            if (item && item.layout) {
              const firstComp = item.layout[0].component[0];
              const navItem = newNavItems.find((nv) => {
                return nv.contentId === item.contentId;
              });
              const firstTagLine = {
                className: '',
                componentCode: 'TAGLINE',
                options: JSON.stringify({
                  terminal: 'PC,MOBILE',
                  headDescription: navItem?.contentName
                })
              };
              let tag = -1;
              let idx = -1;
              item.taglineArr = [];
              item.componentData = [];
              if (firstComp.componentCode !== 'TAGLINE') {
                item.layout.unshift({
                  layoutCode: "basics",
                  layoutPositionCode: "1",
                  component: [firstTagLine]
                });
              }
              for (const i in item.layout) {
                item.layout[i].component[0].layoutPositionCode = item.layout[i].layoutPositionCode;
                if (item.layout[i].component[0].componentCode === 'TAGLINE') {
                  item.taglineArr.push(item.layout[i].component[0]);
                  tag++;
                  item.componentData[tag] = [];
                  item.componentData[tag].push([item.layout[i].component[0]]);
                  idx = 0;
                  continue;
                }
                if (tag === -1) continue;
                if (idx === 0) {
                  item.layout[i].component[0].layoutPositionCode = item.layout[i].layoutPositionCode;
                  item.componentData[tag].push([item.layout[i].component[0]]);
                  idx++;
                  continue;
                }
                if (item.layout[i].component[0].componentCode === item.componentData[tag][idx][0].componentCode) {
                  item.componentData[tag][idx].push(item.layout[i].component[0]);
                  continue;
                }
                item.componentData[tag].push([item.layout[i].component[0]]);
                idx++;
                if (
                  item.layout[i].component[0].componentCode !== 'PATCH' &&
                  item.layout[i].component[0].componentCode !== 'TAGLINE' &&
                  item.layout[i].component[0].componentCode !== 'PlpCom' &&
                  item.layout[i].component[0].componentCode !== 'NBanner'
                ) {
                  item.layout[i] = null;
                }
              }
              item.layout = item.layout.filter((it) => {
                return it;
              });
              for (const i in item.layout) {
                item.layout[i].component[0].options = JSON.parse(
                  item.layout[i].component[0].options
                );
                const {terminal, viewport, className} = decorateAllcomp(item.layout[i].component[0].options);
                item.layout[i].component[0].terminal = terminal;
                item.layout[i].component[0].viewport = viewport;
                item.layout[i].component[0].className = className;
              }
            }
          });
          console.log('currentSortItem', currentSortItem, handleComponentData(currentSortItem));
          commit('SET_ALL_CATALOG_DATA', currentSortItem);
          commit('SET_ALL_COMP_DATA', handleComponentData(currentSortItem));
          commit('SET_ALL_DECORATE_COMP_DATA', handleDecorateData(currentSortItem));
          // dispatch('getPlpDeatilData', { ctx, previewPermission });
        })
        .catch((e) => {
          console.error('catalog数据处理出错！', e);
        });
    } catch (e) {
      console.log('getNavItem', e);
    }
  },

  isUnixMode({ commit }, { to,from,ctx }) {
    if(to?.path&&to.path.startsWith('/pdp')||to.path.startsWith('/plp')||to.path.startsWith('/clp')){
      
      // const  unixOption=from.query?.unix_option;
      const  toUnixOption=to.query?.unix_option;
      // if(unixOption==="true"){
      //   // to 或 from是同样页面,并且只要有headerOption 就会保留headerOption
      //   if(to.path=== from.path &&  unixOption!==toUnixOption){
      //     const query=to.query;
      //     query.unix_option=unixOption;
      //     window.history.replaceState(null,null,ctx.router.resolve({ path: to.path, query: query }).href);
      //     commit('SET_UNIX',true);
      //     return;
      //   }
      // }
      if(toUnixOption==="true"){
        commit('SET_UNIX',true);
      }else{
        commit('SET_UNIX',false);
      }
    }else{
      commit('SET_UNIX',false);
    }
    
  },
  async getCartNum({ commit, rootState }, { ctx }) {
    if (
      !rootState.myAccount.isLogin &&
      !rootState.myAccount.isEmployeeLogin &&
      !rootState.myAccount.isNonmemberLogin
    ) {
      commit('SET_NAV_INFO', { cartNum: localCartService.getLocalCartNum() });
      return;
    }
    try {
      if (
        localCartService.cartData.length > 0 &&
        !rootState.cart.checkoutFlag &&
        !rootState.cart.triggerMergeFromCart
      ) {
        await localCartService.batchAddCart(ctx);
      }
      const data = await ctx.$axios.get(
        ctx.$config.ECP_API_HOST + '/o2cart/v1/carts/quantity',
        {
          noForceLogin: true
        }
      );
      let cartNum = 0;
      if (data.quantity) {
        cartNum = data.quantity > 99 ? 99 : data.quantity;
      }
      if (isLogin()) { commit('SET_NAV_INFO', { cartNum }); }
    } catch (e) {
      console.error('getCartNum 数据出错', e);
      commit('SET_NAV_INFO', { cartNum: 0 });
      if (
        isClient &&
        e.data &&
        (e.data.code === '1000' || e.data.code === '9999')
      ) {
        ctx.$dialog && ctx.$dialog({
          title: '当前页面访问人数过多',
          confirmButton: true,
          confirmButtonText: '重试',
          on: {
            confirm: ({ close }) => {
              window.location.reload();
              close();
            }
          }
        });
      } else if (
        isClient &&
        e.data &&
        (e.data.code === '30001' ||
          e.data.code === '30003' ||
          e.data.code === '30006')
      ) {
        ctx.$dialog({
          title: e.data.message,
          confirmButton: true,
          confirmButtonText: '我知道了',
          on: {
            confirm: ({ close }) => {
              window.location.reload();
              close();
            }
          }
        });
      }
    }
  },

  getPlpDeatilData({ commit, state }, { ctx, previewPermission }) {
    try {
      // const plpData = [...state.sortInfo.plpData];
      const catalogData = [...state.sortInfo.compData];
      const promiseParams = [];
      const idxArray = [];
      catalogData.forEach((item, index) => {
        const plpData = item.filter((it) => {
          return it.component[0].componentCode === 'PlpCom';
        });
        if (plpData.length) {
          idxArray.push(index);
        }
        item.plpCompData = plpData;
        plpData.forEach((plp) => {
          promiseParams.push(
            new Promise((resolve, reject) => {
              const res = ctx.$axios.get(
                ctx.$config.ECP_API_HOST +
                  '/o2srh/v1/pub/platform-products/search?' +
                  'page=1&pageSize=6&abTest=A&' +
                  plp.component[0].options.link.split('?')[1] +
                  `${previewPermission ? '&preview=0' : ''}`
              );
              resolve(res);
            })
          );
        });
        // if (item && item.length && item[0].component[0].componentCode === 'PlpCom'){
        //   console.log('itemSSS',item)
        //   promiseParams.push(
        //     new Promise((resolve, reject) => {
        //       const res = ctx.$axios.get(
        //         ctx.$config.ECP_API_HOST +
        //           '/o2srh/v1/pub/platform-products/search?' +
        //           'page=1&pageSize=6&abTest=A&' +
        //           item[0].component[0].options.link.split('?')[1] +
        //           `${previewPermission ? '&preview=0' : ''}`
        //       );
        //       resolve(res);
        //     })
        //   );
        // }
      });

      Promise.all(
        promiseParams.map((promise) =>
          promise.catch((e) => console.error('plp组件数据请求出错！', e))
        )
      ).then((res) => {
        res.forEach((item, index) => {
          idxArray.forEach((idx) => {
          });
          // if (
          //   catalogData[index] && catalogData[index].length && catalogData[index][0]?.component[0].options.pageSize <
          //   res[index]?.content.length
          // ) {
          //   res[index].content.length =
          //   catalogData[index][0].component[0].options.pageSize;
          // }
          // if (catalogData[index] && catalogData[index].length) {
          //   catalogData[index][0].component[0].options.productList = res[index];
          // }
        });
        // commit('SET_PLPDATA', plpData);
        // commit('SET_ALL_CATALOG_DATA', catalogData);
        commit('SET_PRODUCT_LIST', res);
      });
    } catch (e) {
      console.error('plp组件数据请求出错！', e);
      if (
        isClient &&
        e.data &&
        (e.data.code === '1000' || e.data.code === '20000')
      ) {
        ctx.$dialog({
          title: '当前页面访问人数过多',
          confirmButton: true,
          confirmButtonText: '重试',
          on: {
            confirm: ({ close }) => {
              window.location.reload();
              close();
            }
          }
        });
      } else if (isClient && e.data && e.data.code === '1002') {
        ctx.$dialog({
          title: e.data.message,
          confirmButton: true,
          confirmButtonText: '我知道了',
          on: {
            confirm: ({ close }) => {
              window.location.reload();
              close();
            }
          }
        });
      }
    }
  }
};
function handleComponentData(data) {
  const componentData = [];
  data.forEach((item, index) => {
    if (item && item.layout) {
      item.layout.forEach((list, j) => {
        if (!componentData[index]) {
          componentData[index] = [];
        }
        componentData[index].push(list);
      });
    }
  });
  return componentData;
}
function handleDecorateData(data) {
  const componentData = [];
  data.forEach((item, index) => {
    if (item && item.componentData) {
      item.componentData.forEach((list) => {
        if (!componentData[index]) {
          componentData[index] = [];
        }
        componentData[index].push(list);
      });
    }
  });
  return componentData;
}
function decorateAllcomp(options) {
  const terminal = options.terminal || 'pc,mobile';
  let viewport = terminal.toLowerCase();
  viewport = viewport.split(',').filter((it) => {
    return it === 'pc' || it === 'mobile';
  });
  const className =
    viewport.includes('mobile') && viewport.includes('pc')
      ? ''
      : viewport.includes('mobile')
      ? 'mobile-only'
      : viewport.includes('pc')
      ? 'desktop-only'
      : '';
  return { viewport, className, terminal };
}

function filterNavByTerminal(data) {
  const currentTerminal = isMobile() ? 'mobile' : 'pc';
  return data.filter(item => {
    // terminal 未设置或者terminal等于当前环境的时候 才展示
    return item.terminal === undefined || item.terminal?.toLowerCase().includes(currentTerminal);
  });
}

function handleNavData(data) {
  const length = data.length || 0;
  let textNum = 0;
  let textNumTwoPage = 0;
  const maxNum = 15;
  const maxNumTwo = 15;
  const lenArray = [];
  const lenArrayTwo = [];
  let maxIdx = 6;
  const _data = filterNavByTerminal(data);
  _data.forEach((item, idx) => {
    item.index = idx;
    const splitIdx = getSplitIdx(item.contentName);
    item.contentName = item.contentName.substring(0, splitIdx);
    const textLen = getTextLength(item.contentName);
    textNum += textLen;
    lenArray.push(textNum);
    // console.log('splitASS', splitIdx, item.contentName.length);
  });
  // console.log('lenArray', lenArray);
  let idx = lenArray.findIndex((it) => {
    return it > maxNum;
  });
  idx = idx === -1 ? 6 : idx;
  maxIdx = Math.min(idx, maxIdx);
  for (let i = maxIdx; i < length; i++ ) {
    if (_data[i]) {
      textNumTwoPage += getTextLength(_data[i]?.contentName);
      lenArrayTwo.push({ index: _data[i].index, num: textNumTwoPage });
    }
  }
  // console.log('>>lenArrayTwo<<', lenArrayTwo);
  const idxTwoObj = lenArrayTwo.find((item) => {
    return item.num > maxNumTwo;
  }) || {};
  // console.log('idxTwoObj', idxTwoObj);
  const idxTwo = _data.findIndex((item) => {
    return item.index === idxTwoObj.index;
  });
  // console.log('maxIdx', maxIdx, idxTwo, idxTwoObj, lenArrayTwo);
  const moreNavItem = {
    contentCode: 'more',
    contentId: 'more',
    contentName: '更多精彩',
    contentNameSecond: '返回',
    index: -1
  };
  const returnNavItem = {
    contentCode: 'return',
    contentId: 'return',
    contentName: '返回',
    index: -1
  };
  // console.log('length, maxIdx', length,maxIdx);
  if (length < 8 && maxIdx === 6) {
    return _data;
  } else {
    idxTwo !== -1 ? _data.splice(idxTwo) : '';
    _data.splice(maxIdx, 0, moreNavItem, returnNavItem);
  }
  return _data;
}
function getSplitIdx(contentName) {
  const textArray = contentName.split('');
  let num = 0;
  // eslint-disable-next-line no-control-regex
  const pattern = /[^\x00-\xFF]/g;
  let splitIdx;
  for(let i = 0; i < textArray.length; i++) {
    if (pattern.test(textArray[i])) {
      num = num + 1;
    } else {
      num = num + .5;
    }
    if (num === 5) {
      splitIdx = i;
      break;
    }
    if (num > 5) {
      splitIdx = i - 1;
      break;
    }
  }
  return splitIdx || 5;
}
function getTextLength(contentName) {
  const textArray = contentName.split('');
  let num = 0;
  // eslint-disable-next-line no-control-regex
  const pattern = /[^\x00-\xFF]/;
  for(let i = 0; i < textArray.length; i++) {
    if (pattern.test(textArray[i])) {
      num = num + 1;
    } else {
      num = num + .5;
    }
  }
  return num;
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
