/**
 * cs 存储模块
 *
 * @author milo
 * @date   2024/04/01
 */
const state = () => ({
  cipher:null,
  waitUpdate:null
});

const mutations = {
  SET_CIPHER(state, params) {
    state.cipher = {...params};
  },
  SET_WAIT_UPDATE(state, updatePromise) {
    state.waitUpdate = updatePromise;
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
