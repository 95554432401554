
export default {
  props: {
    // 左边Icon显示类名
    left: {
      type: String
    },
    // header头部title
    title: {
      type: String,
      default: ''
    },
    // 是否需要内边距
    showPadding: {
      type: Boolean,
      default: true
    },
    // 头部是否吸顶
    isSticky: {
      type: Boolean,
      default: true
    },
    // 是否为popover的header
    isPopHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      i: 0
    };
  },
  methods: {
    /**
     * @description: 点击左边图标
     * @param {*}
     * @return {*}
     */
    handleLeft() {
      this.$emit('handleLeft');
    },
    /**
     * @description: 点击search图标
     * @param {*}
     * @return {*}
     */
    handleRight() {
      this.$emit('handleRight');
    }
  }
};
