
export default {
  name: 'TabAnchorItem',
  inject: ['tabAnchorGroup'],
  props: {
    anchor: {
      type: String
    },
    data:{
      type: Object
    }
  },
  computed: {
    classes() {
      return [
        'tab-anchor-item flex-1 h-full cursor-pointer label-3 flex-grow-1'
        // this.tabAnchorGroup.activeAnchorRef() === this.anchor
        //   ? 'label-1'
        //   : 'label-3',
      ];
    }
  },
  created() {
    const refFunc = () => this;
    this.tabAnchorGroup.addItem(refFunc);
    this.$on('hook:beforeDestroy', () => {
      this.tabAnchorGroup.removeItem(refFunc);
    });
  }
};
