 // 数据处理
 export function dataProcess(originData:string,priceDigits:boolean) {
  const floatData = parseFloat(originData);
  if ((floatData < 10000||priceDigits)&& !Number.isInteger(floatData)) {
    const finalData = Math.floor(floatData * 100) / 100;
    let stringData = finalData.toString();
    const dataIndex = stringData.indexOf('.');
    if (stringData.length <= dataIndex + 2) {
      stringData += '0';
    }
    return stringData;
  } else if (floatData >= 10000&&!priceDigits) {
    const rowData = Math.floor(floatData / 1000) / 10;
    const finalData = rowData + '万';
    return finalData;
  } else {
    return floatData;
  }
}
