
import { LibButton, LibDialog, LibPopover } from '@adidas-com-front/ui-library';
import { TokenUtils } from '~/utilities';
import { sensorsLogout } from '~/utilities/vendors/sensors';

export default {
  name: 'Logout',
  components: {
    LibButton,
    LibDialog,
    LibPopover
  },
  data() {
    return {
      // 使用该组件的实例对象
      root: null,
      // 退出员工账户提示弹窗
      employeeLogoutDlg: false
    };
  },
  methods: {
    // 唤起弹出事件
    showPop(that) {
      this.root = that;
      this.employeeLogoutDlg = true;
    },

    // 退出员工账户
    employeeLogout() {
      const flag = TokenUtils.logOut();
      sensorsLogout();
      this.root.$trackLogout();
      if (flag === undefined) {
        this.employeeLogoutDlg = false;
        if (window.innerWidth > 719) {
          // PC 端
          this.root.$router.push('/');
          this.$aloading.show();
          this.root.$store.commit('myAccount/SET_EMPLOYEE_LOGOUT_STATUS', true);
        } else {
          // 移动端

          // 更新会员状态
          this.root.$store.dispatch('myAccount/updateLoginStatus');
          // 更新员工状态
          this.root.$store.dispatch(
            'myAccount/updateEmployeeLoginStatus',
            false
          );
          // 更新非会员状态
          this.root.$store.dispatch(
            'myAccount/updateNonmemberLoginStatus',
            false
          );
          this.root.$store.commit('myAccount/SET_ACCOUNT_INFO', {});
          this.root.$store.commit('myAccount/SET_EMPLOYEE_INFO', {});
          this.root.$store.commit('myAccount/SET_ORDER_INFO', {
            toBePay: 0,
            toBeDelivered: 0,
            toBeReceived: 0,
            toBeRate: 0
          });
          this.root.$store.commit('homePage/SET_NAV_INFO', {
            cartNum: undefined
          });
          this.root.$Toast({ message: '退出成功' });
        }
      }
    }
  }
};
