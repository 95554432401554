/*
 * @Author: your name
 * @Date: 2022-01-04 15:17:20
 * @LastEditTime: 2022-06-28 14:03:01
 * @LastEditors: Kevin.Wang Kevin.Wang2@adidas.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /projects/adidas-com-front/packages/nuxt/plugins/abtest2.js
 */
import abtest from '../static/js/abtest.min.js';
const sensors = require('sa-sdk-javascript');

export default ({ app, $config }) => {
  const router = app.router;
  router.afterEach((to, from) => {
    const query = to.query.key;
    const param = to.query.name;
    if (to.path === '/redirect') {
      sensors.use('SensorsABTest', {
        url: `${$config.ABTEST_URL}?project-key=${query}`
      });
      abtest.fastFetchABTest({
        param_name: param,
        default_value: '',
        value_type: 'String',
        callback(result) {
          // 获取用户命中的试验结果，针对用户可能命中的试验进行处理
          if (result) {
            const url = result.substring(2);
            window.location.replace(url);
          }
        }
      });
    }
  });
};
