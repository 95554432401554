
export default {
  name: 'pplp-loading',

  props: {
    // 是否显示本组件
    isShow: {
      type: Boolean,
      default: false
    }
  }
};
