/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2024-07-16 10:09:33
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2024-07-16 16:57:55
 * @FilePath: /new-com/adidas-com-front/packages/nuxt/components/clp/common/specialLink.mixins.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { handleClickLink } from '../util';
export default {
  name: 'popupMixins',
  data() {
    return {
      specialLink: ['领券', '会员登录', 'action://TOBOS', 'action://TOCFM']
    };
  },
  methods: {
    handleSpecialLinkClick(url, _options, isMobile = false) {
      if(url === '分享引导') {
        const ua = window.navigator.userAgent;
        if(/miniProgram/i.test(ua) || /adidasapp/i.test(ua)) {
          this.doShare();
        }
      } else if (this.specialLink.includes(url)) {
        this.$emit('clickSpecialLink', url);
      } else {
        handleClickLink.call(this, _options, isMobile);
      }
    }
  }
};
