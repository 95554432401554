
import { mapState } from 'vuex';
import { getScrollDepth } from '../track';
import lookGroupLeftRight from './components/leftRight/index.vue';
import lookGroupupDown from './components/up_down/index.vue';
import look from './components/leftRight/group.vue';
import { getUrlQuery } from '~/utilities/helper/urlHelper';

export default {
  name: 'LookGroup',
  components: {
    lookGroupLeftRight,
    lookGroupupDown,
    look
  },
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    franchise: {
      type: String,
      default:""
    },
    position: {
      type: String,
      default: ''
    },
    tabName: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    clpShow: {
      type: Boolean,
      default: true
    },
    isChildClp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mountedShow: false,
      isMobile: false,
      videoMapWidthArray: [],
      imageMapWidthArray: [],
      lookGroupList: [],
      // mobile端lookGroup当前的活跃的
      activeIdx: 0
    };
  },
  computed: {
    ...mapState('clp', [
      'plpListMap',
      'articleList'
    ]),
    imgProductStyle() {
      // lookGroup 展示形式： 左右 上下
      return this.options.imageProductStyle;
    },
    details() {
      const params = { ...this.options };
      delete params.detail;
      const detail = this.options.detail;
      const details = [];
      for (const detailItem of detail) {
        const item = {
          ...params,
          ...detailItem,
          key: 'item-' + (details.length + 1)
        };
        // if (!this.isChildClp) {
          // 不是子clp的用新的方法获取商品，后面会统一改成新方法，此处只是过渡
          item.products = [];
          item.articleListData = [];
          if (item.maintainType === 'PLP') {
            const articleDetailObj = this.plpListMap.find((plp) => {
              return item.link === plp.link;
            });
            item.products = articleDetailObj?.content || [];
          } else {
            const articleDetailList = this.articleList.filter((article) => {
              return item.articleList.includes(article.articleId);
            });
            item.articleListData = articleDetailList || [];
          }
        // }

        if( (
          item.maintainType === 'PLP' &&
          item.products &&
          item.products.length
        ) ||(
          item.maintainType === 'article' &&
          item.articleListData &&
          item.articleListData.length
        ) ){
          details.push(item);
        }
      }
      return details;
    },
    show(){
      return this.details.length > 0 && this.mountedShow;
    },
    backgroundStyle() {
      const style={
        backgroundColor:(this.options?.backgroundColor)?this.options.backgroundColor:"transparent",
        maxWidth: "1920px",
        margin: "0 auto"
      };

      if(this.isMobile&&this.options?.mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.options.mobBackgroundImage})`;
      }else if(!this.isMobile&&this.options?.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.options.pcBackgroundImage})`;
      }
      return style;
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.mountedShow=true;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleChangeSwiper(idx) {
      this.activeIdx = idx;
      this.$emit('lookGroupSwiperChange', this.position);
    },
    getProduct() {
      const isMobile = window.innerWidth < 720;
      if (isMobile) {
        const activeDetail = this.options.detail[this.activeIdx];
        const maintainType = activeDetail.maintainType;

        let articleIdList=[];
        let categoryCode = '';
        if (maintainType === 'PLP') {
          articleIdList = [];
          const paramsObj = getUrlQuery(activeDetail.link);
          categoryCode =  paramsObj?.categoryCode || '';
        } else {
          articleIdList = this.details[this.activeIdx].articleList;
        }
        return {
          campaign_name: this.$route.query.contentId || '',
          sub_campaign_name: this.code,
          component_code: 'LookGroup',
          tag_list: this.options.labelInfoList.join(','),
          category_code: categoryCode,
          position_code: Number(this.position.slice(1)),
          tab_name: this.tabName,
          article_id_list:articleIdList.join("|"),
          lookgroup_type: this.imgProductStyle,
          lookgroup_title: activeDetail?.title?.text || '',
          scroll_depth: getScrollDepth(this.$refs.lookGroupRef.offsetTop)
        };
      } else {
        const details = this.options.detail;
        const detailsLen = details.length;
        const list = [];
        for(let i = 0; i < detailsLen; i++) {
          const currentDetail = this.options.detail[i];
          const maintainType = currentDetail.maintainType;
          let articleIdList=[];
          let categoryCode = '';
          if (maintainType === 'PLP') {
            articleIdList = [];
            const paramsObj = getUrlQuery(currentDetail.link);
            categoryCode =  paramsObj?.categoryCode || '';
          } else {
            articleIdList = this.details[this.activeIdx].articleList;
          }
          const item = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            component_code: 'LookGroup',
            tag_list: this.options.labelInfoList.join(','),
            category_code: categoryCode,
            position_code: Number(this.position.slice(1)),
            tab_name: this.tabName,
            lookgroup_type: this.imgProductStyle,
            lookgroup_title: currentDetail?.title?.text || '',
            article_id_list: articleIdList.join("|"),
            scroll_depth: getScrollDepth(this.$refs.lookGroupRef.offsetTop)
          };
          list.push(item);
        }
        return list;
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth < 720;
    }
  }
};
