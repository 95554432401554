
export default {
  name: 'LibInput',
  props: {
    // input 类型
    type: {
      type: String,
      default: 'text',
    },
    verifyLabelText: {
      type: Boolean,
      default:false
    },
    // input label: 描述文本
    label: String,
    // 无文本时显示占位
    placeholder: String,
    // 获得焦点时，是否隐藏placeholder
    focusedNoPlaceholder: {
      type: Boolean,
      default: false,
    },
    // 是否为验证码输入框
    isVerify: {
      type: Boolean,
      default: false,
    },
    // 是否必须
    required: {
      type: Boolean,
      default: true,
    },
    // 聚焦时仅展示label
    onlyShowLabelOnFocus: {
      type: Boolean,
      default: false,
    },
    // 是否展示清除按钮
    clear: {
      type: Boolean,
      default: false,
    },
    usage:{
      type: Boolean,
      default: false,
    },
    // input边框的border仅显示bottom-border
    bottomOnly: {
      type: Boolean,
      default: false,
    },
    // input 值
    value: {
      type: [String, Number],
      default: '',
    },
    // 接口报错信息
    apiErrMessage: {
      type: String,
      default: null,
    },
    // 当前input得格式校验方式
    formatAs: {
      type: String,
    },
    needed: {
      type: Boolean,
      default: true,
    },
    // 自动填充
    autocomplete: {
      type: String,
      default: null,
    },
    // 是否验证码字段
    isVerifyTel: {
      type: String,
      default: '',
    },
    disableResend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowText: false, // 当前input type为password时, 切换密码的明文秘文显示
      countdown: null, // 计时器, 供清楚定时器使用
      tillNextVerifySec: 60, // 倒计时时间
      // 验证码的验证阶段文字描述
      verifyStatus: ['重新发送', '已发送', '重新发送'],
      currentVerifyStatus: 0, // 当前的验证阶段
      inputFormat: {
        verifyCode: /^[0-9]{6}$/,
        phoneNumber: /^(1[3|4|5|6|7|8|9])\d{9}$/,
        password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^]{8,20}$/,
      }, // input的格式校验
      inputValue: null, // input
      formatErrMessage: null, // 格式错误信息
      focused: false, // input是否聚焦
    };
  },
  computed: {

    // 是否显示清楚内容的按钮
    showClear() {
      if(this.usage&&this.clear){
        return !!this.value && !this.focuse;
      }
      if (this.clear) {
        if (this.value) {
          return true;
        } else if (this.onlyShowLabelOnFocus && !this.focused) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    // 显示使用按钮
    showUsage(){
      if(this.usage){
        return this.focused;
      }else{
        return false;
      }
    },
    // 是否显示input描述
    showLabel() {
      if (!this.onlyShowLabelOnFocus) {
        return true;
      } else {
        return this.focused || this.value;
      }
    },
  },
  watch: {
    // 当focus状态改变时, 更改input边框的颜色状态
    focused() {
      if (this.$refs.outline.classList.contains('active-border')) {
        this.$refs.outline.classList.remove('active-border');
      } else {
        this.$refs.outline.classList.add('active-border');
      }
    },
  },
  methods: {
    // 清除内容按钮, 将value赋值为空字符串
    handleClear(e) {
      this.$emit('input', '');
    },
    // 点击input外面的框时, 手动使input聚焦
    focusInput(e) {
      this.$emit('in', {});
      this.$refs.inputNode.focus();
      setTimeout(() => {
        this.focused = true;
      }, 50);
    },
    // input 失焦事件
    handleBlur(e) {
      this.focused = false;
      this.$emit('out', {});
      this.checkInput(e, 'blur');
    },
    // 改变input边框状态
    changeOutlineColor() {
      if (
        this.formatErrMessage &&
        !this.$refs.outline.classList.contains('error-border')
      ) {
        this.$refs.outline.classList.add('error-border');
      } else if (
        !this.formatErrMessage &&
        this.$refs.outline.classList.contains('error-border')
      ) {
        this.$refs.outline.classList.remove('error-border');
      }
    },
    // 切换密码的秘文明文显示
    showTextSwitch() {
      this.isShowText = !this.isShowText;
      const type = this.$refs.inputNode.type;
      this.$refs.inputNode.type = type === 'text' ? 'password' : 'text';
    },
    // 获取验证码
    getVerifyCode() {
      // 获取code
      if (this.currentVerifyStatus === 1) return;
      this.$emit('sendCode');
      this.$nextTick(() => {
        if (this.currentVerifyStatus !== 1 && this.disableResend) return;
        this.startCountDown();
      });
    },
    // 发送验证码后倒计时
    startCountDown() {
      // 倒计时时间
      this.tillNextVerifySec = 60;
      // 改变获取验证码的按钮状态
      if ([0, 2].includes(this.currentVerifyStatus)) {
        this.currentVerifyStatus = 1;
        this.countdown = setInterval(() => {
          // console.log("warn: Interval p16");
          // 倒计时结束清除定时器, 将现在的验证码状态设置为可再次发送的状态
          if (this.tillNextVerifySec === 0) {
            this.currentVerifyStatus = 2;
            clearInterval(this.countdown);
          } else {
            this.tillNextVerifySec = this.tillNextVerifySec - 1;
          }
        }, 1000);
      }
    },
    // 主要是输入长度校验
    onInputChange(e) {
      // 如果是验证码的话, 检查每次的输入必须为0-9纯数字, 如果输入不是纯数字的话赋值为上一次的value
      if (
        !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.data) &&
        this.isVerify &&
        e.data
      )
        e.target.value = this.value;
      // 输入长度限制, 验证码的长度为6位
      if (this.isVerify && e.target.value && e.target.value.length > 6) {
        e.target.value = e.target.value.slice(0, 6);
      }
      // 输入长度限制, 密码上限20位
      if (
        (this.type === 'password' || this.formatAs === 'password') &&
        e.target.value.length > 20
      ) {
        e.target.value = e.target.value.slice(0, 20);
      }
      this.$emit('input', e.target.value);
      this.$emit('clearErr');
      this.checkInput(e);
    },
    // 主要是正则校验, 以及报错信息的设置
    checkInput(e, value = 'input') {
      const content = e.target.value;
      const length = this.type === 'password' ? 1 : this.isVerify ? 6 : 11;
      // 验证输入格式, 如果验证不通过, 设置报错信息
      if (
        !content ||
        this.type==='email'||
        (this.type === 'tel' && !this.inputFormat.phoneNumber.test(content)) ||
        (this.isVerify && !this.inputFormat.verifyCode.test(content)) ||
        ((this.type === 'password' || this.formatAs === 'password') &&
          !this.inputFormat.password.test(content))
      ) {
        // 非必填不校验
        if (this.required) {
          if (this.type === 'password' || this.formatAs === 'password') {
            if (!content) {
              this.formatErrMessage = '请输入密码';
              // this.label + '不能为空';
            } else if (this.needed === false) {
              this.formatErrMessage = null;
            } else {
              this.formatErrMessage = '密码必须包含数字、大小写字母，至少8位';
            }
          } else if (content.length > length || value === 'blur') {
            this.formatErrMessage =
              (this.isVerify
                ? '验证码'
                : this.label === '账号'
                ? '手机号'
                : this.label) +
              `${content ? '格式不正确, 请检查后重新输入' : '不能为空'}`;
          }
        } else {
          this.formatErrMessage = null;
        }

        this.$emit(
          'checkErr',
          !!(
            this.formatErrMessage ||
            this.apiErrMessage ||
            content.length < length
          )
        );
      } else if (
        (this.type === 'tel' && this.inputFormat.phoneNumber.test(content)) ||
        (this.isVerify && this.inputFormat.verifyCode.test(content))
      ) {
        this.formatErrMessage = null;
        this.$emit('checkErr', !!(this.formatErrMessage || this.apiErrMessage));
      
      } else {
        // 当前没有格式问题的话, 清除之前的报错信息(如果有的话)
        this.formatErrMessage = null;
        this.$emit('checkErr', !!(this.formatErrMessage || this.apiErrMessage));
      }
      this.changeOutlineColor();
      // 调用父组件方法, 检查输入错误
    },
    onFocus() {
      this.$emit('focus');
      this.changeOutlineColor();
    },
  },
};
