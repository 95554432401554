
export default {
  props: {
    hotWords: {
      type: Array,
      default: () => {
        return [];
      }
    },
    left: {
      type: String,
      default: ''
    },
    right: {
      type: String,
      default: ''
    },
    keyWord: {
      type: String
    },
    cutWidth: {
      type: String
    }
  },
  data() {
    return {
      number: 0,
      timer: null,
      hotWordList: '',
      isMobile: false
    };
  },
  computed: {
    text() {
      return {
        id: this.number,
        val: this.hotWordList[this.number]
      };
    }
  },
  updated() {
    if (this.isMobile) {
      const queryObj = this.$route.query;
      if ('keyword' in queryObj) {
        clearTimeout(this.timer);
      } else if (this.hotWordList[0] !== '查找商品') {
        clearTimeout(this.timer);
        this.startMove();
      }
    }
  },
  mounted() {
    this.$axios
      .get(this.$config.ECP_API_HOST + '/o2srh/v1/pub/hot-word')
      .then((res) => {
        if (res.hotWordList.length > 0) {
          this.hotWordList = res.hotWordList;
        } else {
          this.hotWordList = ['查找商品'];
          if (window.self === window.top) {
            window.sessionStorage.setItem('hotWordsSession', '查找商品');
          }
        }
        setTimeout(() => {
          if (this.hotWordList[0] !== '查找商品' && !this.keyWord) {
            clearTimeout(this.timer);
            this.startMove();
          }
        });
      });
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 720;
      if (this.isMobile) {
        const queryObj = this.$route.query;
        if ('keyword' in queryObj) {
          clearTimeout(this.timer);
        } else if (this.hotWordList[0] !== '查找商品') {
          clearTimeout(this.timer);
          this.startMove();
        }
      } else {
        clearTimeout(this.timer);
        this.startMove();
      }
    },
    /**
     * @description: 每隔5s改变热词数组（hotWordList）下标
     * @param {*}
     * @return {*}
     */
    startMove() {
      const length = this.hotWordList.length;
      this.timer = setTimeout(() => {
        if (this.number === length - 1) {
          this.number = 0;
        } else {
          this.number += 1;
        }
        if (window.self === window.top) {
          window.sessionStorage.setItem(
            'hotWordsSession',
            this.hotWordList[this.number]
          );
        }
        this.startMove();
      }, 5000);
    },
    /**
     * @description: 获取当前搜索框内容
     * @param {*} index
     * @return {*}
     */
    getContent(index) {
      this.$emit('getContent', this.hotWordList[index], this.hotWordList);
    },
    /**
     * @description: 决定当前搜索框显示内容
     * @param {*}
     * @return {*}
     */
    showHotWords() {
      if (
        this.keyWord !== 'undefined' &&
        typeof this.keyWord === 'string' &&
        !!this.keyWord
      ) {
        return this.keyWord;
      } else if (this.hotWordList[0] !== '查找商品') {
        return this.text.val;
      } else {
        return this.hotWordList[0];
      }
    },
    /**
     * @description: 点击左边图标
     * @param {*}
     * @return {*}
     */
    handleLeft() {
      this.$emit('handleLeft');
    },
    /**
     * @description: 点击右边图标
     * @param {*}
     * @return {*}
     */
    handleRight() {
      this.$emit('handleRight');
    }
  }
};
