/*
 * @Author: wang,shuang
 * @Date: 2021-10-22 10:52:05
 * @Description: filte description
 */
interface HttpRequestHeadersInterfaceMock {
  [id: string]: string | string[] | undefined;
}

interface HttpRequestInterfaceMock {
  headers: HttpRequestHeadersInterfaceMock;

  [id: string]: any;
}

export interface IsMobileOptions {
  ua?: string | HttpRequestInterfaceMock;
  tablet?: boolean;
  featureDetect?: boolean;
}

const mobileRE =
  /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;

const tabletRE = /android|ipad|playbook|silk/i;

/**
 * 判断是否为移动端，默认情况下视平板为移动端，如果需要识别平板为非移动端，opts.tablet请设置为false；
 * @author  韦胜健
 * @date    2021/9/13 17:54
 */
export function isMobile(opts?: IsMobileOptions) {
  if (!opts) {
    opts = {};
  }
  if (opts.tablet == null) {
    opts.tablet = true;
  }
  let ua = opts.ua;
  if (!ua && typeof navigator !== 'undefined') {
    ua = navigator.userAgent;
  }
  if (
    ua &&
    typeof ua === 'object' &&
    ua.headers &&
    typeof ua.headers['user-agent'] === 'string'
  ) {
    ua = ua.headers['user-agent'];
  }
  if (typeof ua !== 'string') {
    return false;
  }

  let result = mobileRE.test(ua) || (!!opts.tablet && tabletRE.test(ua));

  if (
    !result &&
    opts.tablet &&
    opts.featureDetect &&
    navigator &&
    navigator.maxTouchPoints > 1 &&
    ua.includes('Macintosh') &&
    ua.includes('Safari')
  ) {
    result = true;
  }

  return result;
}

/**
 *  判断当前系统
 */
export function detectOS(): string {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  if (isMobile()) {
    if (isTablet) {
      return 'Tablet';
    } else {
      return 'Mobile';
    }
  } else {
    // ipadOs 的userAgent 现在显示为mac, 所以还要通过设备的最大触摸点数来判断到底是pad还是pc
    if (/mac/.test(userAgent) && navigator.maxTouchPoints > 0) {
      return 'Tablet';
    }
    return 'PC';
  }
}
