
import ImageCard from '@adidas-com-front/ui-library/src/components/atoms/LibImage/LibImage.vue';
import {getImgUrl} from '@adidas-com-front/ui-library/src/utilities/help/index';
export default {
  name: 'ProductCard',
  components: {
    ImageCard
  },
  props: {
    // 商品信息
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    urlParam: {
      type: String
    },
    size: {
      type: Number,
      default: 1000
    },
    // 展示图片的类型
    imageType: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    // 过万不显示 1万 而是 10000
    priceDigits:{
      type:Boolean,
      default:false
    },
    // 当显示价格字符数量超过autoHideSalePrice,自动隐藏划线价格
    autoHideSalePrice:{
      type:Number,
      default:0
    },
    isTransparent:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      // 传递给Image组件的图片Url
      imageUrl: '',
      // 传递给Image组件的图片Alt
      imageAlt: '',
      // 是否显示推荐信息
      isShowRecommend: false,
      // 显示立减标签（labelist）
      newLabel: {},
      labelFirst: {},
      active: true,
      currentEnviroment: ''
    };
  },
  computed: {
    // 一排三个商品布局特殊处理，仅移动端
    isProduct3List(){
      return true;
    },
    // 判断是否显示推荐百分比而动态更改标题样式
    changeLineClass() {
      const classList = [];
      if (this.handleShowRecommend(this.item.recommendedPercentage)) {
        classList.push('single');
      } else {
        classList.push('double');
      }
      console.log("this.type",this.type);
      if (this.isProduct3List) {
        classList.push('label-5');
        classList.push('mt-2 pr-8');
        classList.push('article-name');
      } else {
        classList.push('label-3');
        classList.push('mt-4');
      }
      return classList;
    },

    formatImgUrl() {
      return function(item) {
        if(this.isTransparent){
          return getImgUrl(item.transFrontImageUrl||item.transColorImageUrl, this.size, true);
        }else{
          const url = this.imageType ? item[this.imageType] || item.imageUrl : item.imageUrl || '';
          return getImgUrl(url, this.size);
        }
      };
    },
    articleName(){
      return (item)=>{
        if(item?.articleInfo?.articleSpName){
          return item.articleInfo.articleSpName;
        }else{
          return item.articleName;
        }
      };
    },
    bgClass(){
      const classList=[];
      if(this.isTransparent){
        classList.push( 'bg-transparent');
      }else{
        classList.push( 'fill-bg-light-gray');
      }
      if(!this.item.articleId){
        classList.push('replay');
      }
      return classList;
    }
  },

  mounted() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes('miniprogram')) {
      if (location.pathname.toLocaleLowerCase().includes('/or/')) {
        this.currentEnviroment = 'or';
      } else {
        this.currentEnviroment = 'miniprogram';
      }
    } else if (ua.includes('adidasapp')) {
      this.currentEnviroment = 'AdidasApp';
    }
    setTimeout(() => {
      this.active = false;
    }, 50);
  },
  methods: {
    // 这里判断一排三个商品时，价格是否超过999，则使用特殊样式
    largeNumber(item){
      return item.activityPrice? this.isProduct3List && item.activityPrice && item.salePrice && Number(item.activityPrice) >999 && Number(item.salePrice)>999:this.isProduct3List && item.discountPrice && item.salePrice && Number(item.discountPrice) >999 && Number(item.salePrice)>999;
    },
    mediumNumber(item){
      return item.activityPrice? this.isProduct3List && item.activityPrice && item.salePrice && Number(item.activityPrice) >99 && Number(item.salePrice)>99:this.isProduct3List && item.discountPrice && item.salePrice && Number(item.discountPrice) >99 && Number(item.salePrice)>99;
    },
    smallNumber(item){
      return item.activityPrice? this.isProduct3List && item.activityPrice && item.salePrice && Number(item.activityPrice) >9 && Number(item.salePrice)>9:this.isProduct3List && item.discountPrice && item.salePrice && Number(item.discountPrice) >9 && Number(item.salePrice)>9;
    },
    // 判断需要跳转的系统，如果是其他系统则需要禁止跳转官网，使跳转到其他系统
    goLink() {
      console.log('goLink',this.currentEnviroment,`/orwshop/pages/productDetail/index?articleId=${this.item.articleId}`);
      if(this.currentEnviroment==='or'){
        const finalUrl =`/orwshop/pages/productDetail/index?articleId=${this.item.articleId}`;
        wx.miniProgram.navigateTo({
          url: finalUrl
        });
      }else if (this.currentEnviroment === 'miniprogram') {
        const finalUrl =
          '/pages/product/details?articleId=' + this.item.articleId;
        wx.miniProgram.navigateTo({
          url: finalUrl
        });
      } else if (this.currentEnviroment === 'AdidasApp') {
        // 如果当前链接参数环境是APP内嵌
        const finalUrl = 'adidas://product/' + this.item.articleId;
        window.location.href = finalUrl;
      }
    },
    // 保存浏览记录
    setBreadCrumbsData() {
      setTimeout(() => {
        localStorage.setItem(
          'pdpBreadCrumbs',
          JSON.stringify({
            ...JSON.parse(localStorage.getItem('pdpBreadCrumbs')),
            [this.item.articleId]: this.urlParam
          })
        );
      });
    },

    /**
     * @description:是否显示推荐百分比
     * @param {*}
     * @return {*}
     */
    handleShowRecommend(recommend) {
      if (recommend) {
        const recommendNumber = parseFloat(recommend);
        if (recommendNumber > 80) {
          return recommend;
        }
      }
    },
    /**
     * @description: 立减/折扣标签
     * @param {*}
     * @return {*}
     */
    countLabel(labelList) {
      const res = new Array(labelList.length);
      let resIndex = 0;
      const labelSet = ['CUSTOM', 'PROMOTION', 'FLEXIBLE'];
      for (let i = 0; i < labelList.length; i++) {
        const type = labelList[i].labelType;
        const text = labelList[i].labelText;
        const typeOrder = labelSet.indexOf(type);
        if (
          typeOrder !== -1 &&
          /(^\d{1,2}折$)|(立减)|((^([0]{1})(.\d{0,3}折)?$))/.test(text)
        ) {
          res[i] = typeOrder;
        } else {
          res[i] = 99;
        }
        // console.log(type, res[i], res[resIndex]);
        if (res[i] < res[resIndex]) resIndex = i;
      }
      return res[resIndex] === 99 ? {} : labelList[resIndex] || {};
    },
    /**
     * @description: 标签优先级处理
     * @param {*} labelList
     * @return {*}
     */
    labelPriority(labelList) {
      const res = new Array(labelList.length);
      let resIndex = 0;
      const labelSet = ['FIXED', 'AUTOMATIC', 'ACTIVITY'];
      for (let i = 0; i < labelList.length; i++) {
        const type = labelList[i].labelType;
        const text = labelList[i].labelText;
        const typeOrder = labelSet.indexOf(type);
        // console.log(type, text, typeOrder);
        if (text && text === 'APP专享') {
          res[i] = -2;
        } else if (!text || type === 'PROMOTION') {
          res[i] = 999;
        } else if (typeOrder !== -1) {
          res[i] = typeOrder;
          if (text === '售罄' || text === '即将发售') res[i] += 1;
        } else {
          res[i] = 9999;
        }
        if (res[i] < res[resIndex]) resIndex = i;
      }
      return !labelSet.includes(labelList[resIndex]?.labelType) &&
        labelList[resIndex]?.labelText !== 'APP专享'
        ? {}
        : labelList[resIndex] || {};
    },

    // labelPriority(labelList) {
    //   const res = new Array(labelList.length);
    //   let resIndex = 0;
    //   const labelSet = ['EXCLUSIVE', 'FIXED', '', 'AUTOMATIC', 'ACTIVITY'];
    //   for (let i = 0; i < labelList.length; i++) {
    //     const type = labelList[i].labelType;
    //     const text = labelList[i].labelText;
    //     const typeOrder = labelSet.indexOf(type);
    //     // console.log(type, text, typeOrder);
    //     if (!text || type === 'PROMOTION') {
    //       res[i] = 999;
    //     } else if (typeOrder !== -1) {
    //       res[i] = typeOrder;
    //       if (text === '售罄' || text === '即将发售') res[i] += 1;
    //     } else {
    //       res[i] = 9999;
    //     }
    //     if (res[i] < res[resIndex]) resIndex = i;
    //   }
    //   return !labelSet.includes(labelList[resIndex].labelType)
    //     ? {}
    //     : labelList[resIndex];
    // },
    // 数据处理
    dataProcess(originData) {
      const floatData = parseFloat(originData);
      if ((floatData < 10000||this.priceDigits) && !Number.isInteger(floatData)) {
        const finalData = Math.floor(floatData * 100) / 100;
        let stringData = finalData.toString();
        const dataIndex = stringData.indexOf('.');
        if (stringData.length <= dataIndex + 2) {
          stringData += '0';
        }
        return stringData;
      } else if (floatData >= 10000&&!this.priceDigits) {
        const rowData = parseInt(floatData / 1000) / 10;
        const finalData = rowData + '万';
        return finalData;
      } else {
        return floatData;
      }
    }
  }
};
