import { isHarmonyBrowser } from './device/onPageScroll';

export const getScreenInfo = () => {
  let isSmall = false;
  let isMedium = false;
  let isLarge = false;

  if (typeof document === 'undefined') {
    return {
      whenSmallScreen: false,
      whenNotSmallScreen: true,
      whenMediumScreen: false,
      whenNotMediumScreen: false,
      whenLargeScreen: true,
      whenNotLargeScreen: true,
      clientWidth: 1920,
      clientHeight: 1080,
    };
  }

  let { clientWidth, clientHeight } = document.documentElement;

  if (isHarmonyBrowser()) {
    /* 鸿蒙浏览器下，有个奇怪的问题。浏览器高度比预估的要少56个px */
    clientHeight += 56;
  }

  if (clientWidth < 719) {
    isSmall = true;
  } else if (clientWidth < 1439) {
    isMedium = true;
  } else {
    isLarge = true;
  }

  return {
    whenSmallScreen: isSmall,
    whenNotSmallScreen: !isSmall,
    whenMediumScreen: isMedium,
    whenNotMediumScreen: !isMedium,
    whenLargeScreen: isLarge,
    whenNotLargeScreen: !isLarge,

    clientWidth,
    clientHeight,
  };
};
