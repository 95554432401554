import {isClient} from "~/utilities";

const state = () => ({
  // 购物车商品 [{prod: {}, count:1}]
  list: [], 
  // count: 0, // 商品类型个数
  // num: 0, // 商品个数
  // price: 0 // 商品总价格
  // 结算合并本地购物车标识
  checkoutFlag: false, 
  // 在cart页面合并本地购物车标识
  triggerMergeFromCart: false, 
  // 优惠码
  couponData: [], 
  outterCoupon: [],
  couponDataSelected: [],
  cartCouponPannelFlag: false
});

const mutations = {
  /**
   * 初始化设置购物车
   * @param state
   * @param list
   */
  init(state, { list, count, num, price }) {
    if (list) {
      state.list = list;
      state.count = count;
      state.num = num;
      state.price = price;
    } else {
      state.count = -1;
    }
  },
  /**
   * 加购物车
   * @param state
   * @param obj
   */
  add(state, obj) {
    const existItem = state.list.find((item) => {
      return item.prod.id === obj.prod.id;
    });
    if (existItem) {
      existItem.count += obj.count;
      state.count += obj.count;
    } else {
      state.list.unshift(obj);
      state.count += obj.count;
    }
  },
  /**
   * 从购物车中移除
   * @param state
   * @param obj
   */
  remove(state, obj) {
    const existItem = state.list.find((item) => {
      return item.prod.id === obj.prod.id;
    });
    if (existItem) {
      if (existItem.count <= obj.count) {
        state.list.splice(state.list.indexOf(existItem), 1);
        state.count -= existItem.count;
      } else {
        existItem.count -= obj.count;
        state.count -= obj.count;
      }
    }
  },
  SET_CHECKOUT_FLAG(state, status) {
    state.checkoutFlag = status;
  },
  SET_MERGECART_FLAG(state, status) {
    state.triggerMergeFromCart = status;
  },
  SET_COUPON_INFO(state, status) {
    state.couponData = status;
  },
  SET_COUPON_INFO_OUTTER(state, status) {
    state.outterCoupon = status;
  },
  SET_COUPON_INFO_SELECTED(state, status) {
    state.couponDataSelected = status;
  },
  SET_COUPON_PANEL_FLAG(state, status) {
    state.cartCouponPannelFlag = status;
  }
};

const actions = {
  async getCount({ commit }, ctx) {
    // const udid = ctx.app.$cookies.get('udid');
    // if (udid) {
    //   const { data } = await ctx.$axios.post('/api/**/omsCartItem/queryNumber', { udid });
    //   if (data) {
    //     commit('init', { count: data, list: [] });
    //   }
    // }
  },
  /**
   * 获取购物车列表
   * @param state
   * @param obj
   */
  async getList({ commit }, ctx) {
    // 用户购物车
    // const addr = ctx.$cookies.get('address');
    // const addrCode = addr.streetCode || addr.districtCode || addr.cityCode || addr.provinceCode;
    // const cart = ctx.$axios.post('/portal/api/web/v1/omsCartItem/query', {
    //   deliveryAddrCode: addrCode
    // });
    // let resultList = [];
    // try {
    //   const promiseList = [cart];
    //   resultList = await Promise.all(promiseList);
    // } catch (e) {
    //   console.log('页面数据加载错误：' + e);
    // }
    // const cartArr = resultList[0].data;
    // let arr = [];
    // if (cartArr.length > 0) {
    //   cartArr.forEach((item) => {
    //     arr = arr.concat(item.omsCartItemList);
    //   });
    // }
    // let cartAllPrice = 0;
    // let cartAllNum = 0;
    // if (arr.length > 0) {
    //   arr.forEach((item) => {
    //     cartAllPrice = cartAllPrice + item.promotionPrice * item.productQuantity;
    //     cartAllNum = cartAllNum + item.productQuantity;
    //   });
    // }
    // commit('init', { count: arr.length, list: arr, num: cartAllNum, price: cartAllPrice });
  },
  /**
   * 将商品加入购物车
   * @param dispatch
   * @param ids
   * @param ctx
   * @return {Promise<void>}
   * @constructor
   */
  async add({ dispatch }, { row, ctx }) {
    // try {
    //   const deliveryType = row.deliveryCode;
    //   const subDeliveryType = row.freightPrice.list.filter(type => type.isDefalut === 'Y')[0].subDeliverType;
    //   const param = {
    //     productId: row.id,
    //     productSkuId: row.skuId,
    //     quantity: 1,
    //     deliveryType,
    //     subDeliveryType
    //   };
    //   await ctx.$axios.post(`/portal/api/web/v1/omsCartItem/batchAdd`, param);
    //   dispatch('GET_LIST', ctx);
    // } catch (e) {
    //   console.error('从购物车中移除失败');
    // }
  },
  /**
   * 从购物车中删除商品
   * @param dispatch
   * @param ids
   * @param ctx
   * @return {Promise<void>}
   * @constructor
   */
  async remove({ dispatch }, { ids, ctx }) {
    // try {
    //   await ctx.$axios.post('/portal/api/web/v1/omsCartItem/delete', { ids });
    //   dispatch('GET_LIST', ctx);
    // } catch (e) {
    //   console.error('从购物车中移除失败');
    // }
  },
  async checkAccount({ commit, dispatch, rootState }, { ctx, selectedData,errorCallback }) {
    try {
      let action="CART";
      const items = selectedData.map((el) => {
        // 新增类型允许为PDP
        if(el.action==="PDP"){
          action=el.action;
          return{
            quantity: el.quantity,
            skuId: el.skuId
          };
        }else{
          return {
            cartEntryCode: el.cartEntryCode,
            quantity: el.quantity,
            skuId: el.skuId
          };
        }
      });
      const params = {
        action: action,
        items
      };
      // const data = await ctx.$axios.post(`${ctx.$config.ECP_API_HOST}/o2ckt/v1/order-settlements`, params);
      const e = await dispatch('checkout/createOrder', {
        cartObj: params,
        ctx: this
      }, {root: true});
      const data = rootState.checkout.checkout;
      if (data.settlementCode) {
        ctx.$router.push(
          `/checkout?id=${data.settlementCode}&payCode=${data?.tempOrder?.defaultPaymentTypeInfo?.paymentTypeCode}`
        );
      } else if (isClient && e?.data?.failed) {
        if(!errorCallback){
          ctx.$dialog({
            title: e.data.message,
            confirmButton: true,
            confirmButtonText: '我知道了',
            on: {
              confirm: ({ close }) => {
                window.location.reload();
                close();
              }
            }
          });
        }else{
          errorCallback(e.data.message);
        }
      }
      return data;
    } catch (e) {
      return false;
    }
  },
  async getCoupon({ commit }, { ctx, ids, flag=0 }) {
    try {
      const scene = {
        promoSceneCode: 'Campaign Page',
        cieFlag: 0,
        limitFlag: 0
      };
      const promoSceneList = [];
      promoSceneList.push(scene);
      const data = await ctx.$axios.post(
        `${ctx.$config.ECP_API_HOST}/o2pmt/v1/pub/promocode/recommend-enhance`,{
          articleNoList: ids,
          promoSceneList
        }
      );
      const couponData = sortCouponData(data.promoSceneList[0].promoCodeList) || [];
      const outterAllCouponData = sortOutterCouponData(data.promoSceneList[0].promoCodeList) || [];
      if (flag) {
        commit('SET_COUPON_INFO_OUTTER', outterAllCouponData.slice(0, 2));
      }
      console.log("couponData111",data.promoSceneList[0].promoCodeList, couponData);
      commit('SET_COUPON_INFO', couponData);
    } catch (e) {
      console.error('获取pdp优惠码失败: ' + e);
      commit('SET_COUPON_INFO', []);
    }
  },
  async getCouponSelected({ commit }, { ctx, ids }) {
    try {
      const scene = {
        promoSceneCode: 'Campaign Page',
        cieFlag: 0,
        limitFlag: 0
      };
      const promoSceneList = [];
      promoSceneList.push(scene);
      const data = await ctx.$axios.post(
        `${ctx.$config.ECP_API_HOST}/o2pmt/v1/pub/promocode/recommend-enhance`,{
          articleNoList: ids,
          promoSceneList
        }
      );
      const couponData = sortCouponData(data.promoSceneList[0].promoCodeList) || [];
      commit('SET_COUPON_INFO_SELECTED', couponData);
    } catch (e) {
      console.error('获取cart selected优惠码失败: ' + e);
      commit('SET_COUPON_INFO_SELECTED', []);
    }
  }
};

function sortCouponData(couponData) {
  couponData = couponData.filter((item) => {
    return item.statusCode === 'TO_BE_RECEIVED';
  });

  couponData.forEach((item) => {
    if (item.reduction) {
      item.sort = 1;
    } else {
      item.sort = 2;
    }
  });
  couponData.sort((a, b) => {
    return a.sort - b.sort;
  });
  couponData.sort((a, b) => {
    return b.reduction - a.reduction || a.discount - b.discount;
  });
  return couponData;
}

function sortOutterCouponData(couponData) {
  couponData = couponData.filter((item) => {
    return item.statusCode === 'TO_BE_RECEIVED' || item.statusCode === 'UNUSED';
  });

  couponData.forEach((item) => {
    if (item.reduction) {
      item.sort = 1;
    } else {
      item.sort = 2;
    }
  });
  couponData.sort((a, b) => {
    return a.sort - b.sort;
  });
  couponData.sort((a, b) => {
    return b.reduction - a.reduction || a.discount - b.discount;
  });
  return couponData;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
