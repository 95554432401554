
import LibNoMore from '@adidas-com-front/ui-library/src/components/atoms/LibNoMore/LibNoMore.vue';
export default {
  name: 'NoMore',
  components: {
    LibNoMore
  },
  props: {
    // 是否显示本组件
    isShow: {
      type: Boolean,
      default: false
    }
  }
};
