import { DeviceService } from '@adidas-com-front/ui-library/src/utilities/device';
import Vue from 'vue';
import GlobalScrollCache from './GlobalScrollCache';
import { isClient } from"~/utilities/ssr.ts";
const scrollCacheNamePattern = /(plp|test-page)/;
const scrollCache = {};
console.log("warn: new GlobalScrollCache");
export const globalScrollCache = new GlobalScrollCache();
export const ScrollCachePlugin = ({ app }) => {
  console.log("warn: scroll.cache app.router.beforeEach");
  app.router.beforeEach((to, from, next) => {
    const { name } = from;
    if (scrollCacheNamePattern.test(name)) {
      const { scrollTop } = DeviceService.getScrollTop();
      console.log("warn: scrollCache[name]");
      scrollCache[name] = scrollTop;
    }
    next();
  });
  globalScrollCache.init(app.router); // 让globalScrollCache 参与进来
};
if(isClient){
  console.log("warn: mixin scroll.cache");
  Vue.mixin({
    beforeRouteLeave(to, from, next) {
      console.log("warn: mixin scroll.cache beforeRouteLeave");
      if (this.$parent.$options.name !== 'Nuxt') {
        return next();
      }
      if (!this.$router._scrollTopCache) {
        this.$router._scrollTopCache = {};
      }
      const { scrollTop } = this.$utils.device.getScrollTop();
      let name = from.name.toLowerCase();
      if (name.startsWith('plp')) {
        name = 'plp'; // plp调整后name为'plp-category',所以无法匹配到plp的缓存scrolltop
      }
      if (from.name.startsWith('plp') && to.name !== 'pdp') {
        this.$router._scrollTopCache.plp = 0;
      } else {
        this.$router._scrollTopCache[name] = scrollTop;
      }
      next();
    },
    mounted() {
      if (
        globalScrollCache.useScrollCache &&
        globalScrollCache.useScrollCache(
          globalScrollCache.triggerType.mounted,
          this.$options.name
        )
      ) {
        return; // useScrollCache接管 （鼠标hover查看注释）
      }
      if (!this.$parent || this.$parent.$options.name !== 'Nuxt') {
        return;
      }
      this.handleScroll();
    },
    activated() {
      if (this.$parent.$options.name !== 'Nuxt') {
        return;
      }
      if (
        globalScrollCache.useScrollCache &&
        globalScrollCache.useScrollCache(
          globalScrollCache.triggerType.activated,
          this.$options.name
        )
      ) {
        return; // useScrollCache接管 （鼠标hover查看注释）
      }
      this.handleScroll();
    },
    methods: {
      handleScroll() {
        let cacheName = this.$options.name?.toLowerCase();
        if (cacheName && cacheName.startsWith('plp')) {
          cacheName = 'plp'; // plp调整后name为'plp-category',所以无法匹配到plp的缓存scrolltop
        }
        if (
          !!this.$router._scrollTopCache &&
          this.$router._scrollTopCache[cacheName] !== undefined &&
          globalScrollCache.isPopstate
        ) {
          setTimeout(() => {
            this.$utils.device.setScrollTop(
              this.$router._scrollTopCache[cacheName]
            );
          });
        } else if (!globalScrollCache.isPopstate) {
          this.$utils.device.setScrollTop(0);
        }
        globalScrollCache.isPopstate = false;
      },
      toJSON() {}
    }
  });
}

/**
 * plp页面路由缓存工具类
 * 在plp页面中使用mixin无效，改为call的方式调用；
 * @author  韦胜健
 * @date    2021/11/5 17:06
 */
export const PlpCacheMixin = (() => {
  let unwatch; // onMounted会执行两遍，不知道什么鬼，这里做记录，如果已经初始化了watch，就不再初始化；
  let prevPath = ''; // 当路由离开或者当前要刷新数据的时候，记下当前的path以及fullPath
  let prevFulPath = ''; // 当路由离开或者当前要刷新数据的时候，记下当前的path以及fullPath

  return {
    beforeRouteLeave(to, from, next) {
      // 当路由离开或者当前要刷新数据的时候，记下当前的path以及fullPath
      prevPath = from.path;
      prevFulPath = from.fullPath;
      next();
    },
    onMounted() {
      if (unwatch) {
        return;
      }
      /**
       * 监听路由变化，刷新页面数据，并且滚动回顶部；
       * 不能使用watchQuery来监听路由变化刷新数据，缓存路由之后，watchQuery没法使得页面刷新，即使asyncData已经重新执行；
       * 不能在watchQuery中调用 $nuxt.refresh()，这样会导致asyncData执行两遍；
       * @author  韦胜健
       * @date    2021/9/17 20:33
       */
      prevPath = this.$route.path;
      prevFulPath = this.$route.fullPath;

      unwatch = this.$watch(
        () => this.$route.fullPath,
        () => {
          const { path, fullPath } = this.$route;
          // console.log({ path,fullPath,prevPath,prevFulPath });
          if (this.$route.path !== prevPath) {
            // console.log('当前在其他页面，不刷新');
            return;
          }
          if (this.$route.fullPath === prevFulPath) {
            // console.log('当前参数没变，不刷新');
            return;
          }
          // console.log('刷新',fullPath,prevPath);
          prevFulPath = fullPath;
          prevPath = path;
          // 当前需要刷新plp页面数据，需要滚动回顶部，这里直接设置缓存中的scrollTop值为0，在scroll.cache.plugin中，在onActivated中会自动设置为0
          if (!this.$router._scrollTopCache) {
            this.$router._scrollTopCache = {};
          }
          let name = this.$route.name?.toLowerCase();
          if (name.startsWith('plp')) {
            name = 'plp'; // plp调整后name为'plp-category',所以无法匹配到plp的缓存scrolltop
          }
          this.$router._scrollTopCache[name] = 0;
          // 重新执行asyncData刷新数据
          this.$nuxt.refresh();
        },
        {
          deep: true // 不用deep对象内部的变化监测不到
        }
      );
    }
  };
})();
