import { DeviceService } from '../device/index';

interface iOnPageScrollParameter {
  scrollTop: number; // 当前滚动高度
  el: HTMLElement; // 滚动的元素，鸿蒙浏览器下是 document.body，其他浏览器下是 document.documentElement
  toBottom: number,// 距离底部的距离
}

/**
 * 判断是否为鸿蒙浏览器
 * @author  韦胜健
 * @date    2021/9/14 13:11
 */
export function isHarmonyBrowser () {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('harmony');
}

/**
 * 获取页面滚动高度
 * @author  韦胜健
 * @date    2021/9/14 13:12
 */
export function getScrollTop (): iOnPageScrollParameter {
  const el = document.querySelector('#layout-wrapper') as HTMLElement;
  const {
    scrollTop,
    scrollHeight,
    offsetHeight
  } = el;
  return {
    el,
    scrollTop,
    toBottom: scrollHeight - 1 - (scrollTop + offsetHeight)
  };
}

/**
 * 设置页面高度（无过渡动画）
 * @author  韦胜健
 * @date    2021/9/16 17:27
 */
export function setScrollTop (scrollTop: number) {
  const { el } = getScrollTop();
  el.scrollTop = scrollTop;
}

/**
 * 监听页面滚动事件
 * @author  韦胜健
 * @date    2021/9/16 17:27
 */
export function onPageScroll (
  listener: (param: iOnPageScrollParameter) => any
): () => void {
  const handler = () => listener(getScrollTop());
  const { el } = getScrollTop();
  el.addEventListener('scroll', handler);
  return () => el.removeEventListener('scroll', handler);
}

export function disablePageScroll () {
  [document.documentElement, document.body].forEach((el) => {
    el.style.overflow = 'hidden';
    el.style.touchAction = 'none';
  });
}

export function enablePageScroll () {
  [document.documentElement, document.body].forEach((el) => {
    el.style.overflow = '';
    el.style.touchAction = '';
  });
}
