
  import { Scroll } from './scroll';
  export default {
    props: {
      videoSrc: {
        type: String,
        default: ''
      },
      index: {
        type: Number,
        default: 0
      },
      activeIndex: {
        type: Number,
        default: 0
      },
      itemStyle: {
        type: Object,
        default: () => {
          return {};
        }
      },
      poster: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        clickFlag: false,
        eventFun: null,
        scroll: null
      };
    },
    mounted () {
      const u = navigator.userAgent.toLowerCase();
      this.isWeixin = u.includes('micromessenger');
      const name = 'video' + this.index;
      const video = this.$refs[name];
      const activeVideoName = 'video' + this.activeIndex;
      const activeVideo = this.$refs[activeVideoName];
      this.$nextTick(() => {
        // 监听竖向滚动 区分pc端和mobile端
        if (window.innerWidth < 720) {
          if (activeVideo) {
            this.scroll = new Scroll(activeVideo);
            this.scroll.scroll();
          }
        } else {
          this.scroll = new Scroll(video);
          this.scroll.scroll();
        }
      });
      // 监听video自动播放等事件报错
      window.addEventListener("unhandledrejection", (event) => {
        console.log('监听到video Error', event);
      });
      // 监听元数据加载完成
      video.addEventListener('loadedmetadata', () => {
        this.$emit('loadEnd', true);
      });
      // 监听是否播放
      video.addEventListener('timeupdate', () => {
        this.clickFlag = true;
      });
    },
    beforeDestroy() {
      clearTimeout(this.timer);
      document.removeEventListener("visibilitychange",this.eventFun);
    },
    methods: {
      visibilitychangeAsync(){
        return new Promise((resolve)=>{
            this.eventFun=()=>{
              if(document.visibilityState==="visible"){
                resolve();
              }
            };
            document.addEventListener("visibilitychange", this.eventFun);
        });
      },
      // 播放视频
      playVideo() {
        const name = 'video' + this.index;
        const video = this.$refs[name];
        if (!this.clickFlag) {
          video ? video.play() : '';
          this.clickFlag = true;
        }
      },
      handleClickVideo() {
        const name = 'video' + this.index;
        const video = this.$refs[name];
        this.visibilitychangeAsync().then(()=>{
          if (this.isWeixin) {
            this.curPlayVideo ? this.curPlayVideo.play() : this.wechatVideoPlay(video);
          }
        });
        this.$emit('linkRoute');
      },
      wechatVideoPlay(firstVideo) {
        this.curPlayVideo = firstVideo;
        // 判断微信环境 单独处理
        // 使用微信jssdk来实现自动播放
        if (typeof WeixinJSBridge === 'undefined') {
          document.addEventListener(
            'WeixinJSBridgeReady',
            () => {
              try {
                firstVideo.play();
                firstVideo.ontimeupdate = () => {
                  this.clickFlag = true;
                };
              } catch (error) {
                console.log('微信lookBook video播放错误', error);
              }
            },
            false
          );
        } else {
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke('getNetworkType', {}, (e) => {
            try {
              firstVideo.play();
              firstVideo.ontimeupdate = () => {
                this.clickFlag = true;
              };
            } catch (error) {
              console.log('微信lookBook video播放错误', error);
            }
          });
        }
      }
    }
  };
