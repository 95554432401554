/*
 * @Description: URL path 多个斜杠删除处理
 * @Autor: Duan,JingLiang
 * @Date: 2021-12-16 15:45:46
 */

export default function ({ route, redirect }) {
  const regTest = /\/\/+/g;
  if (regTest.test(route.fullPath)) {
    redirect({ path: route.fullPath.replace(/\/\/+/g, "/") });
  }

}
