/*
 * @Description:
 * @Author: ZhangHui
 * @Date: 2021-12-08 14:57:34
 */
import { removeBreadCrumbs } from '../catalog/utils';
import { parseURL, getUrlQuery, getUrlRelativePath, isContainInnerPath, isAbsoluteURL } from '~/utilities/helper/urlHelper';
import { TokenUtils, isOR, isAdiclub } from '~/utilities/helper/SystemHelper';
function isLinkTargetBlank() {
  const target = TokenUtils.getQueryValue('target');
  if (target && target === '_blank') {
    return true;
  } else if (window.self !== window.top) {
    return true;
  } else {
    return false;
  }
}
function isDownloadPage(url = '') {
  const path = '/app-download';
  // 应用宝链接
  const yybPath = 'a.app.qq.com';

  return url.includes(path) || url.includes(yybPath);
}

function getQueryVariable(link, variable)
{
  const query = link.split('?');
  const vars = query.length > 1 ? query[1].split("&") : [];
  for (let i=0;i<vars.length;i++) {
    const pair = vars[i].split("=");
    if(pair[0] === variable){return pair[1];}
  }
  return('');
}

export const handleClickLink = function (option = {}, isMobile) {
  const { link, anchor, franchise, allLink } = option;
  if (allLink) {
    goNativePathByTerminal(this, allLink);
  } else if (link) {
      if (link.startsWith('/') && !link.startsWith('/pages/') && !link.startsWith('/orwshop') && !link.startsWith('/orwcrm')) {
        const url = parseURL(link);
        goDetail(this, url, franchise, link);
      } else {
        const ua = window.navigator.userAgent.toLowerCase();

        if (isWXminiUrl(link)) {
          // 跳转小程序页面，区分普通page和tab 兼容 /pages 和 pages 两种配置
          if (/miniprogram/i.test(ua)) {
            const finalUrl = formatWXminiUrl(link);
            if (isOR()) {
              // OR 环境下的跳转 接口返回的path包含完整的path+query
              if (isORTabPage(link)) {
                wx.miniProgram.switchTab({
                  url: finalUrl
                });
              } else {
                wx.miniProgram.navigateTo({
                  url: finalUrl
                });
              }
            } else if (link.startsWith('/pages/tabs/')) {
              // wms 跳转
              wx.miniProgram.switchTab({
                url: finalUrl
              });
            } else {
              wx.miniProgram.navigateTo({
                url: finalUrl
              });
            }

          } else {
            return false;
          }
        } else if (isDownloadPage(link)) {
          if(/miniprogram/i.test(ua)) {
            // 小程序
            copy2clipboard(link);
            this.$Toast({ message: '链接已复制', delay: 1500 });

            setTimeout(() => {
              this.$dialog({
                title: '下一步',
                confirmButton: true,
                confirmButtonText: '我知道了',
                on: {
                  confirm: ({ close }) => {
                    close();
                  }
                },
                render: () => '打开浏览器粘贴地址，下载 ADIDAS APP'
              });
            }, 1500);
          } else if (/micromessenger/i.test(ua)) {
            // 微信内置浏览器
            this.$shareGuide.show();
          } else {
            window.open(decodeURIComponent(link), '_self');
          }
        } else {
          // 处理clp跳转链接维护的是一个完整的url情况下，记录上一个页面完整地址
          if (window.self === window.top) {
            sessionStorage.setItem('previousUrl', location.href);
          }
          if (isContainInnerPath(link)) {
            const params = getUrlQuery(link);
            params.keyword ? params.keyword = decodeURIComponent(params.keyword) : '';
            const relPath = getUrlRelativePath(link);
            this.$router.push({path: relPath, query: params});
          } else {
            const _target = isLinkTargetBlank() ? '_blank' : '_self';
            window.open(decodeURIComponent(link), _target);
          }
          // if (location.pathname === '/clp' && isLinkTargetBlank()) {
          //   window.open(decodeURIComponent(link), '_blank');
          // } else {
          //   window.open(decodeURIComponent(link), '_self');
          // }
        }
      }
  } else if (anchor) {
    const tabTop = document.querySelector(
      `div[tab-anchor="a${anchor}"]`
    ).offsetTop;
    let container = '';
    if (isMobile) {
      container = document.querySelector('.CLP');
    } else {
      container = document.querySelector('#layout-wrapper');
    }
    this.$utils.device.setScrollTop(tabTop);
    container.scrollTop = tabTop;
  }

};

const goNativePathByTerminal = (_this, allLink) => {
  const ua = navigator.userAgent.toLowerCase();
  let currentEnviroment = '';
  const pathConfig = _this.$store.state.clp.pathConfig.switchTabPathConfig;
  const orPathConfig = pathConfig.OR;
  const wmsPathConfig = pathConfig.WMS;
  const adiclubPathConfig = pathConfig.ADICLUB;
  let finalUrl = '';
  if (ua.includes('miniprogram')) {
    currentEnviroment = 'miniprogram';
  } else if (ua.includes('adidasapp')) {
    currentEnviroment = 'AdidasApp';
  }
  if (currentEnviroment === 'miniprogram') {
    if (isOR()) {
      finalUrl = allLink.orPath;
      console.log('or端finalUrl', finalUrl);
      if (finalUrl) {
        const isNeedSwitchTabFun = !!orPathConfig.find((it) => {return it === finalUrl;});
        if (isNeedSwitchTabFun) {
          wx.miniProgram.switchTab({
            url: finalUrl
          });
        } else {
          wx.miniProgram.navigateTo({
            url: finalUrl
          });
        }
      }
    } else if (isAdiclub()) {
      finalUrl = allLink.adiclubLink;
      console.log('adiclub端finalUrl', finalUrl);
      if (finalUrl) {
        const isNeedSwitchTabFun = !!adiclubPathConfig.find((it) => {return it === finalUrl;});
        if (isNeedSwitchTabFun) {
          wx.miniProgram.switchTab({
            url: finalUrl
          });
        } else {
          wx.miniProgram.navigateTo({
            url: finalUrl
          });
        }
      }
    } else {
      finalUrl = allLink.wmsPath;
      console.log('wms端finalUrl', finalUrl);
      if (finalUrl) {
        const isNeedSwitchTabFun = !!wmsPathConfig.find((it) => {return it === finalUrl;});
        if (isNeedSwitchTabFun) {
          wx.miniProgram.switchTab({
            url: finalUrl
          });
        } else {
          wx.miniProgram.navigateTo({
            url: finalUrl
          });
        }
      }
    }
  } else if (currentEnviroment === 'AdidasApp') {
    finalUrl = allLink.appDeeplink;
    console.log('app端finalUrl', finalUrl);
    if (finalUrl) {
      redirectToApp(finalUrl);
    }
  } else {
    // 否则直接跳转系统内
    finalUrl = parseURL(allLink.webLink);
    const link = allLink.webLink;
    console.log('com端finalUrl', link);
    removeBreadCrumbs(false);
    if (link) {
      if (isAbsoluteURL(link)) {
        window.open(link, '_blank');
      } else {
        _this.$router.push(finalUrl);
      }
    }
  }
};

/*
 * @Author: Li Bo
 * @Date: 2021-12-10
 * @Description: Homepage & CLP 页面跳转官网、小程序、APP的plp、pdp页面链接统一处理
 */

/**
 * @param ctx
 * @param url:跳转连接地址对象，包含query和path
 * @return push对象
 */
export function goDetail(ctx, url, franchise, link) {
  const ua = navigator.userAgent.toLowerCase();
  let currentEnviroment = '';
  if (ua.includes('miniprogram')) {
    currentEnviroment = 'miniprogram';
  } else if (ua.includes('adidasapp')) {
    currentEnviroment = 'AdidasApp';
  }

  if (currentEnviroment) {
    // 如果当前链接参数环境是小程序
    if (currentEnviroment === 'miniprogram') {
      console.log('这是小程序', url);
      const wmsPathMap = {
        plpCategory: '/pages/product/list?categoryCode=/',
        plpKeyword: '/pages/product/list?keyword=',
        pdp: '/pages/product/details?articleId=',
        splp: '/pages/product/rich-list?contentId='
      };

      const orPathMap = {
        plpCategory: '/pages/subPackage/pages/plp/index?link=',
        plpKeyword: '/pages/subPackage/pages/plp/index?link=',
        pdp: '/orwshop/pages/productDetail/index?articleId=',
        splp: ''
      };

      let finalUrl = '';
      if (url.path.startsWith('/plp') && url.query.keyword) {
        if (isOR()) {
          finalUrl = orPathMap.plpKeyword + encodeURIComponent(`keyword=${decodeURIComponent(url.query.keyword)}`);
        } else {
          finalUrl = wmsPathMap.plpKeyword + decodeURIComponent(url.query.keyword);
        }
      } else if (url.path.startsWith('/plp') && url.path.substr(5)) {
        // 这里截取
        if (isOR()) {
          const categoryCode = link ? getQueryVariable(link, 'categoryCode') : '';
          finalUrl = orPathMap.plpCategory + encodeURIComponent(`categoryCode=${categoryCode}`);
        } else {
          finalUrl = wmsPathMap.plpCategory + url.path.substr(5);
        }
      } else if (url.path === "/pdp") {
        const param = franchise ? `&frsParam=${franchise}` : '';
        if (isOR()) {
          finalUrl = orPathMap.pdp + url.query.articleId;
        } else {
          finalUrl = `${wmsPathMap.pdp}${url.query.articleId}${param}`;
        }
      } else if (url.path === '/splp') {
        if (isOR()) {
          finalUrl = orPathMap.splp + url.query.contentId;
        } else {
          finalUrl = `${wmsPathMap.splp}${url.query.contentId}`;
        }
      }
      wx.miniProgram.navigateTo({
        url: finalUrl
      });
    } else if (currentEnviroment === 'AdidasApp') {
      console.log('当前环境为app');
      // 如果当前链接参数环境是APP内嵌
      let finalUrl = '';
      if (url.path.startsWith('/plp') && url.query.keyword) {
        // 临时方案，encode两次 解决fs下的跳转问题
        finalUrl =
          'adidas://category?query=' + encodeURIComponent(url.query.keyword);
      } else if (url.path.startsWith('/plp') && url.path.substr(5)) {
        finalUrl = 'adidas://category?url=/' + url.path.substr(5);
        // ctx.$Toast({message: 'app路径：'+ finalUrl, delay: 2000});
      } else if (url.path === '/pdp') {
        finalUrl = 'adidas://product/' + url.query.articleId;
      }
      // window.location.href = finalUrl;
      redirectToApp(finalUrl);
    }
  } else {
    // 否则直接跳转系统内
    removeBreadCrumbs(false);
    if (location.pathname === '/clp' && isLinkTargetBlank()) {
      const urlPara = ctx.$router.resolve(url);
      window.open(urlPara.href, '_blank');
    } else {
      ctx.$router.push(url);
    }
  }
}
export function lookHandleHref(link){
  if(!link){
    return;
  }
  let href=link;
  if (typeof href === 'string') {
    if (href.startsWith('/')) {
       const [path, query] = href.split('?');
       const querys=[];
       if(query){
        for(const keyValue of query.split("&")){
          const [key,value]=keyValue.split('=');
          if(key&&value){
            querys.push(`${key}=${value}`);
          }
        }
       }
       if(querys.length){
        href=path+'?'+querys.join('&');
       }else{
        return '';
       }
    }
  }
  return handleHref(href);
}
/*
 * @Author: Wang, Shuang
 * @Date: 2022-01-10
 * @Description: 返回图片跳转href
 */

export function handleHref(link) {
  let href = '';
  if (typeof link === 'string') {
    if (link.startsWith('/')) {
      const obj = parseURL(link);
      href = parseURL(link).path;
      if (Object.keys(obj.query).length) {
        href += '?';
      }
      for (const key in obj.query) {
        href += key + '=' + encodeURI(obj.query[key]);
      }
    } else {
      href = decodeURIComponent(link);
    }
  }
  return href;
}

function copy2clipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  textArea.style.top = '-999px';
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

function formatWXminiUrl(url) {
  if (url.startsWith('pages/') || url.startsWith('orwshop/') || url.startsWith('orwcrm/')) {
    return '/' + url;
  } else {
    return url;
  }
}

// wms + or path 规则
function isWXminiUrl(url) {
  return url && (url.startsWith('pages/') || url.startsWith('/pages/') || url.startsWith('orwshop/') || url.startsWith('/orwshop/') || url.startsWith('orwcrm/') || url.startsWith('/orwcrm/'));
}

function isORTabPage(url) {
  const ORTabPages = [
    'orwshop/pages/home-page/index',
    'orwshop/pages/catalog/index',
    'orwcrm/pages/my/index'
  ];

  return ORTabPages.findIndex(item => {
    return url.includes(item);
  }) > -1;
}

function redirectToApp(url) {
  if(window?.AdidasApp?.webPageToApp){
    console.log("action_deep_link");
    window.AdidasApp.webPageToApp("action_deep_link",
    JSON.stringify({
        "deep_link":  url,
        "hold_web_page": true
    }));
  }else{
    console.log("location.href");
    window.location.href = url;
  }
}

// 判断当前渠道的path是否是['领券', '会员登录', '分享引导']
export function getCurrentLink(allLink) {
  const ua = navigator.userAgent.toLowerCase();
  let currentEnviroment = '';
  if (ua.includes('miniprogram')) {
    currentEnviroment = 'miniprogram';
  } else if (ua.includes('adidasapp')) {
    currentEnviroment = 'AdidasApp';
  }

  if (currentEnviroment === 'miniprogram') {
    if (isOR()) {
      return allLink.orPath;
    } else if (isAdiclub()) {
      return allLink.adiclubLink;
    } else {
      return allLink.wmsPath;
    }
  } else if (currentEnviroment === 'AdidasApp') {
    return allLink.appDeeplink;
  } else {
    return allLink.webLink;
  }
}

// 包装survey跳转链接为allLink
export function decorateSurveyAllLink(link) {
  const ua = navigator.userAgent.toLowerCase();
  let currentEnviroment = '';
  if (ua.includes('miniprogram')) {
    currentEnviroment = 'miniprogram';
  } else if (ua.includes('adidasapp')) {
    currentEnviroment = 'AdidasApp';
  }
  const allLink = {
    wmsPath: '',
    orPath: '',
    webLink: '',
    adiclubLink: '',
    appDeeplink: ''
  };
  if (currentEnviroment === 'miniprogram') {
    if (isOR()) {
      allLink.orPath = link;
    } else if (isAdiclub()) {
      allLink.adiclubLink = link;
    } else {
      allLink.wmsPath = link;
    }
  } else if (currentEnviroment === 'AdidasApp') {
    allLink.appDeeplink = link;
  } else {
    allLink.webLink = link;
  }
  return allLink;
}
