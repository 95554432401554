/*
 * @Description: loading组件，可全局调用
 * @Autor: Duan,JingLiang
 * @Date: 2021-09-17 15:52:01
 */

import Vue from 'vue';
import LibLoading from './LibLoading.vue';

interface optionsProps {
  withMask?: boolean; // 是否需要遮罩
  type?: string; // 'default' and 'indicator'
  withContent?: string; // 加载文字
}
export default {
  install(vue: any) {
    const UseLoading = Vue.extend(LibLoading);
    const Profile: any = new UseLoading({
      el: document.createElement('div'),
    });

    document.body.appendChild(Profile.$el);
    const loadingMethod = {
      show(options: optionsProps = {}) {
        Profile.show = true;
        Profile.withMask = options.withMask;
        Profile.type = options.type || 'default';
        Profile.withContent.content = options.withContent;
      },
      hide() {
        Profile.show = false;
      },
    };
    vue.prototype.$aloading = loadingMethod;
  },
};
