
import { createModel } from '../../../utilities/createModel';
import LibButton from '../LibButton/LibButton';
import { delay } from '../LibTooltip/LibTooltip.trigger';

export default {
  name: 'LibDialog',
  components: {
    LibButton,
  },
  props: {
    className:{
      type:String,
      default:''
    },
    // 双向绑定值，控制弹框是否显示
    value: { type: Boolean },
    // 弹框标题
    title: { type: String },
    // 是否展示关闭按钮，默认不展示关闭按钮
    closeIcon: { type: Boolean },
    // 是否可以关闭，设置为false之后，点击遮罩不会关闭，ESC按键不会关闭，关闭按钮会消失
    closeable: {
      type: Boolean,
      default: true,
    },
    // 禁用点击遮罩的时候关闭弹框
    disabledCloseOnClickMask: { type: Boolean },
    // 弹框的样式
    dialogClass: { type: String },

    // 显示确认按钮
    confirmButton: { type: Boolean },
    // 禁用确认按钮
    confirmButtonDisabled: { type: Boolean },
    // 显示取消按钮
    cancelButton: { type: Boolean },
    // 确认按钮的文本
    confirmButtonText: {
      type: String,
      default: '确定',
    },
    // 取消按钮的文本
    cancelButtonText: {
      type: String,
      default: '取消',
    },
    // 点击确认按钮的时候关闭
    closeOnClickConfirm: { type: Boolean },
    // 点击取消按钮的时候关闭
    closeOnClickCancel: { type: Boolean },
    // 位置，top上，bottom下，left左，right右以及center居中，可以是位置字符串，也可以是一个函数返回位置字符串；函数参数是一个对象，格式为 {whenSmallScreen,whenNotSmallScreen,whenMediumScreen,whenNotMediumScreen,whenLargeScreen,whenNotLargeScreen}，当对应的屏幕尺寸匹配时，会值为true，否则为false
    position: {
      type: [String, Function],
      default: 'center',
    },
    // 全屏
    full: {
      type: Boolean,
    },
    // container节点高度
    height: {
      type: String,
    },
    hideHeader: {
      type: Boolean,
    },
    hideFooter: {
      type: Boolean,
    },
    hideBorder: {
      type: Boolean,
    },
    noPadding: {
      type: Boolean,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    useAnimation: {
      type: Boolean,
      default: true,
    },
    noMounted: {
      type: Boolean,
      default: false
    },
    zIndexSize: {
      type: Number,
      default: 0
    }
  },
  data() {
    const model = createModel({ context: this });
    return {
      model,

      /* 判断当前组件是否mounted，以避免当value为true的时候，页面直接显示弹框 */
      isMounted: false,

      zIndex: 10000000,

      // 弹窗关闭动画，默认为false
      isAnimation: false,
    };
  },
  computed: {
    targetPosition() {
      if (typeof this.position === 'string') {
        return this.position;
      } else {
        return this.position(this.$utils.screen);
      }
    },
    classes() {
      return [
        `lib-dialog flex justify-center items-center`,
        this.dialogClass,
        {
          'lib-dialog-full': this.full,
          'lib-dialog-no-padding': this.noPadding,
        },
        this.className
      ];
    },
    containerStyles() {
      const styles = {};
      if (this.height) {
        styles.height = this.height;
      }
      if (this.hideBorder) styles.border = 'none';
      return styles;
    },
    dialogStyles() {
      return {
        zIndex: this.zIndexSize || this.zIndex,
      };
    },
  },
  watch: {
    'model.value'(val) {
      if (val) {
        this.$utils.device.disablePageScroll();
        this.zIndex = this.$utils.nextIndex();
        this.isAnimation = false;
      } else {
        this.$utils.device.enablePageScroll();
        if (this.useAnimation) {
          this.isAnimation = true;
        }
      }
    },
    $route(to, from) {
      this.isMounted = false;
    }
  },
  async mounted() {
    const onEsc = (e) => {
      if (e.keyCode === '27' && this.closeable) {
        this.hide();
      }
    };
    window.addEventListener('keydown', onEsc);
    this.$on('hook:beforeDestroy', () => {
      window.removeEventListener('keydown', onEsc);
    });

    if (this.appendToBody) {
      document.body.appendChild(this.$refs.el);
      this.$on('hook:beforeDestroy', () => {
        document.body.removeChild(this.$refs.el);
      });
    }
    await delay(100);
    this.isMounted = true;
  },
  methods: {
    /**
     * 打开弹框
     * @author  韦胜健
     * @date    2021/9/10 11:16
     */
    show() {
      this.model.value = true;
    },
    /**
     * 关闭弹框
     * @author  韦胜健
     * @date    2021/9/10 11:16
     */
    hide() {
      this.model.value = false;
    },

    /**
     * 处理点击确定按钮
     * @author  韦胜健
     * @date    2021/9/10 11:16
     */
    onClickConfirm() {
      !!this.closeOnClickConfirm && this.hide();
      this.$emit('confirm', { close: () => this.hide() });
    },
    /**
     * 处理点击遮罩
     * @author  韦胜健
     * @date    2021/9/10 11:16
     */
    onClickMask() {
      if (this.closeable && !this.disabledCloseOnClickMask) {
        this.hide();
        this.$emit('cancel', { close: () => this.hide() });
        if (this.useAnimation) {
          this.isAnimation = !this.isAnimation;
        }
      }
    },
    /**
     * 处理点击关闭按钮
     * @author  韦胜健
     * @date    2021/9/10 11:16
     */
    onClickClose() {
      if (this.closeable) {
        this.hide();
        this.$emit('cancel');
        if (this.useAnimation) {
          !this.isAnimation;
        }
      }
    },
    /**
     * 处理点击取消按钮
     * @author  韦胜健
     * @date    2021/9/10 11:16
     */
    onClickCancel() {
      if (this.closeOnClickCancel) {
        this.hide();
        this.$emit('cancel');
        this.$emit('cancelBtn');
        if (this.useAnimation) {
          !this.isAnimation;
        }
      }
    },
  },
};
