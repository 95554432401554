
import { mapState } from 'vuex';
import { LibButton } from '@adidas-com-front/ui-library';
// import { bannerView, bannerClick, getLinkType } from '../track';
import deepcopy from 'deepcopy';
import { trackClpPdpClick, trackClpPlpClick, trackClpButtonClick, getScrollDepth } from '../track';
import productList from './components/list.vue';
import LookVideo from './components/video.vue';
import { handleClickLink, getCurrentLink } from '~/components/clp/util';
import { getImgUrl, parseURL } from '~/utilities/helper/urlHelper';
export default {
  name: 'FeedProduct',
  components: {
    LibButton,
    productList,
    LookVideo
  },
  props: {
    detail: {
      type: [Object, Array],
      default: () => {}
    },
    sensorInfo: {
      type: Object,
      default: () => ({})
    },
    franchise: {
      type: String
    },
    tabName: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    clpShow: {
      type: Boolean,
      default: false
    },
    isChildClp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMobile: true,
      isLoad: false
    };
  },
  computed: {
    ...mapState('clp', [
      'plpListMap',
      'articleList'
    ]),
    backgroundStyle() {
      return item=>{
        const style={
          backgroundColor:(item.backgroundColor)?item.backgroundColor:"transparent"
        };
        if(this.isMobile&&item.mobBackgroundImage){
          style.backgroundSize='cover';
          style.backgroundRepeat='no-repeat';
          style.backgroundPosition="top center";
          style.backgroundImage=`url(${item.mobBackgroundImage})`;
        }else if(!this.isMobile&&item.pcBackgroundImage){
          style.backgroundSize='cover';
          style.backgroundRepeat='no-repeat';
          style.backgroundPosition="top center";
          style.backgroundImage=`url(${item.pcBackgroundImage})`;
        }
        return style;
      };
    },
    imgInfo() {
      return function (item) {
        return this.isMobile
          ? {
              img: getImgUrl(item.mobImgUrl, 1000),
              tag: item.mobSeoTag
            }
          : {
              img: getImgUrl(item.pcImgUrl, 500),
              tag: item.pcSeoTag
            };
      };
    },
    optLink() {
      return function (item, type) {
        if (type === 'Image') {
          return item.photoLink || item.link;
        } else if (type === 'Button') {
          return item.buttonLink || item.link;
        } else {
          return '';
        }
      };
    },

    handleUrl() {
      return function (link) {
        return 'javascript:void(0);';
      };
    },
    details() {
      const detail = deepcopy(this.detail);
      const detailItem = detail.length && detail[0];
      // if (!this.isChildClp && detailItem) {
        const maintainType = detailItem.maintainType;
        if (maintainType === 'PLP') {
          const pageSize = detailItem.pageSize;
          const articleDetailObj = this.plpListMap.find((plp) => {
            return detailItem.link === plp.link;
          });
          const content  = articleDetailObj?.content.map((item) => {
            delete item.recommendedPercentage;
            delete item.subTitle;
            delete item.labelList;
            return item;
          }) || [];
          detailItem.products = content?.slice(0, pageSize) || [];
          return [detailItem];
        } else {
          const articleDetailList = this.articleList.filter((article) => {
            return detailItem.articleList.includes(article.articleId);
          });
          const content  = articleDetailList.map((item) => {
            delete item.recommendedPercentage;
            delete item.subTitle;
            delete item.labelList;
            return item;
          }) || [];
          detailItem.products = content;
          return [detailItem];
        }
      // } else {
      //   return this.detail;
      // }
    },
    videoUrl(){
      return (item)=>{
        return this.isMobile ? item.mobileVideoUrl : item.pcVideoUrl;
      };
    },
    itemStyle() {
      return (item) => {
        const ratio = item.mobileActualH / item.mobileActualW;
        const width = window.innerWidth;
        return {
          height: item.mobileActualW && item.mobileActualH ? ratio * width + 'px' : ''
        };
      };
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    console.log("this.detail-look",this.detail);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    // sensorViewTrack() {
    //   const { bannerId, bannerName, bannerPosition } = this.sensorInfo;
    //   bannerView(bannerId, bannerName, bannerPosition);
    // },
    // sensorClickTrack(type, data) {
    //   const { bannerId, bannerName, bannerPosition } = this.sensorInfo;
    //   const { photoLink, buttonLink } = data;
    //   const linkType = getLinkType(type === 'Image' ? photoLink : buttonLink);
    //   bannerClick({
    //     bannerClickType: type,
    //     bannerId,
    //     bannerName,
    //     bannerPosition,
    //     bannerToPageType: linkType,
    //     bannerToUrl: type === 'Image' ? photoLink : buttonLink,
    //     // 下面是pdp需要的
    //     articleNumber: data.articleId,
    //     brand: data.brandName,
    //     category: data.category,
    //     gender: data.genderShow,
    //     modelName: data.modelNo,
    //     productName: data.articleName
    //   });
    // },
    sensorClickTrack(type, data) {
      console.log('sensorClickTrack======', type, data);
      const curTerminalLink = data.imageRedirectAllLink ? getCurrentLink(data.imageRedirectAllLink) : data.imageRedirectLink || '';
      if (!curTerminalLink) {
        return;
      }
      if (
        curTerminalLink.includes('/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/list') ||
        curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/rich-list') ||
        curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
        curTerminalLink.includes('adidas://category') ||
        parseURL(curTerminalLink).path.startsWith('/plp') ||
        parseURL(curTerminalLink).path.startsWith('/splp')
      ) {
        this.trackData('plp', data, curTerminalLink);
      } else if (
        curTerminalLink.includes('/pages/product/details') ||
        curTerminalLink.includes('adidas://product') ||
        curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
        parseURL(curTerminalLink).path === '/pdp'
      ) {
        this.trackData('pdp', data, curTerminalLink);
      } else {
        this.trackData('button', data, curTerminalLink);
      }
    },
    trackData(val, data, link) {
      const text = [data.title?.text, data.subtitle?.text].filter(item => {
        return !!item;
      });
      let param;
      switch (val) {
        case 'plp':
          param = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'Look',
            position_code: Number(this.position.slice(1)),
            card_position: 1,
            scroll_depth: getScrollDepth(this.$refs.lookRef.offsetTop),
            plp_url: link || '',
            plp_name: '',
            tab_name: this.tabName,
            tag: data.labelInfoList.join(',')
          };
          trackClpPlpClick(param);
          break;
        case 'pdp':
          param = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'Look',
            position_code: Number(this.position.slice(1)),
            article_position: 1,
            tab_name: this.tabName,
            recommend_id: '',
            scroll_depth: getScrollDepth(this.$refs.lookRef.offsetTop),
            category_code: this.categoryCode,
            article_number: parseURL(link).query.articleId,
            brand: '',
            category: '',
            gender: '',
            tag: data.labelInfoList.join(',')
          };

          trackClpPdpClick(param);
          break;
        case 'button':
          param = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'Look',
            position_code: Number(this.position.slice(1)),
            scroll_depth: getScrollDepth(this.$refs.lookRef.offsetTop),
            to_url: link || '',
            button_name: '',
            tab_name: this.tabName,
            tag: data.labelInfoList.join(',')
          };
          trackClpButtonClick(param);
          break;
      }
    },
    linkRoute(item, type) {
      const curTerminalLink = item.imageRedirectAllLink ? getCurrentLink(item.imageRedirectAllLink) : item.imageRedirectLink || '';
      if (!curTerminalLink) {
        return;
      }
      const _item = { allLink: item.imageRedirectAllLink, link: item.imageRedirectLink };
      this.franchise ? _item.franchise = this.franchise : '';
      handleClickLink.call(this, _item);
      this.sensorClickTrack(type, item);
    },
    handleResize() {
      this.isMobile = window.innerWidth < 720;
    },
    onLoad() {
      this.isLoad = true;
    },
    transmit() {
      return this.$parent.$options.name;
    },
    handleLoadEnd(loadEnd) {
      this.isLoad = loadEnd;
    }
  }
};
