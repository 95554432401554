
export default {
  name: 'LibPageIndicator',
  props: {
    // “回到顶部” 箭头的显隐
    arrowShow: {
      type: Boolean,
      default: false,
    },
    // 当前滚动的高度
    currentTop: {
      type: Number,
      default: 0
    },
    // product card 高度
    cardHeight: {
      type: Number,
      default: 303
    }
  },
  watch: {
    // 监听当前页数，控制组件渲染时机
    currentTop(newValue) {
      if(window.innerWidth < 720) {
        // mobile端>=24 显示
        this.hidden = newValue > -(this.cardHeight * 10 + this.cardHeight/2);
      } else if ( window.innerWidth < 1440 && window.innerWidth > 719) {
        // 中屏>=24 显示
        this.hidden = newValue > -(this.cardHeight * 6 + this.cardHeight/2);
      } else {
        // 大屏>= 48 显示
        this.hidden = newValue > -(this.cardHeight * 9  + this.cardHeight/2);
      }
      if (!this.initialized && newValue < 0) {
        this.initialized = true;
      }
    },
  },
  data() {
    return {
      initialized: false,
      hidden: true,
    };
  },
  methods: {
    // 回到顶部
    backToTop() {
      document.documentElement.scrollTop = 0;
      this.initialized = false;
      this.hidden = true;
      this.$emit('backToTop');
    },
  },
};
