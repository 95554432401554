
import jwt from 'jsonwebtoken';
import deepcopy from 'deepcopy';
import { CLPUtils } from './common/productUtil';
import look from '~/components/clp/look';
import LookGroup from '~/components/clp/look-group';
import textLink from '~/components/clp/text-link/TextLink.vue';
import cta from '~/components/clp/cta/Cta.vue';
import clpSingleImage from '~/components/clp/clpSingleImage';
import multiImage from '~/components/clp/multi-image';
import clpVideo from '~/components/clp/video';
import productList from '~/components/clp/product-list';
import imageSlideProduct from '~/components/clp/image-slide-product';
import NBanner from '~/components/clp/n-banner';
import VerticalTabPage from '~/components/clp/verticalTabPage';
import {BasicUtils} from '~/utilities/basic/utils';
import { AppLogin } from "~/utilities/basic/appLogin";
import {
  getDistinctIds
} from '~/utilities/vendors/sensors';

const getClpLayer = (ctx, contentId, renderSide) => {
  try {
    const url = `${ctx.$config.S3_HOST}/f04/page_conf/COM/zh_CN/${
      renderSide === 'server' ? 'online' : 'stage'
    }/0/${contentId}.json`;
    return ctx.$axios.get(url);
  } catch (e) {
    console.log('getClpLayer error', e);
  }
};

const getSurveyLayer = (ctx, address, code) => {
  return new Promise((resolve, reject) => {
    ctx.$axios
    .get(address).then((res) => {
      resolve(res);
    }).catch(() => {
      resolve({});
    });
  });
};

const searchPlpByPre = async (options, ctx, renderSide) => {
  try {
    const params = {
      page: 1,
      abTest: 'A',
      pageSize: options.pageSize,
      categoryCode: options.preCategoryCode
    };

    const data = await ctx.$axios.get(
      `${ctx.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/search`,
      { params: { ...params, preview: renderSide === 'client' ? 0 : null } }
    );
    console.log('推荐商品列表为空，使用preCategoryCode调用search接口进行兜底', data, params);
    if (!data.content) data.content = [];
    return data;
  } catch (e) {
    return {content: [], totalPages: 0, totalElements: 0};
  }
};
const getRecommendedPlp = async (options, contentCode, ctx, tabContentCode, renderSide) => {
  const utils=new BasicUtils(ctx);
  if (utils.IsAPP && window.AdidasApp) {
    const appLogin = new AppLogin(window.AdidasApp);
    const data = await appLogin.getloginInfoAsync();
    console.log('app 下面 tabPage', data);
    utils.config.headers['x-client-device-id'] = data ? jwt.decode(JSON.parse(data).token).crm_member_id : await getDistinctIds();
  } else {
    utils.config.headers['x-client-device-id'] = await getDistinctIds();
  }
  const params = {
    page: 1,
    abTest: 'A',
    pageSize: options.pageSize,
    recommendUrl: 'recommendation/getPersonalizedCampaignListPage',
    categoryCode: options.preCategoryCode,
    clpContentCode: contentCode,
    tabContentCode: tabContentCode
  };
  options.articleList.join(',') ? params.articleList = options.articleList.join(',') : '';
  options.fixCategoryCode ? params.fixCategoryCode = options.fixCategoryCode : '';
  console.log('推荐商品列表params', params);
  return new Promise((resolve, reject) => {
    ctx.$axios
      .get(
        `${ctx.$config.ECP_API_PRIVATE_HOST}/o2pcm/v1/pub/platform-products/recommend-products`,
        {
          params: { ...params },
          ...utils.config
        }
      )
      .then(async (res) => {
        let normalPlpList;
        if (!res.recommendList || !res.recommendList.length) res.recommendList = [];
        if (!res.recommendList.length) {
          const preRes = await searchPlpByPre(options, ctx, renderSide);
          normalPlpList = preRes.content || [];
        }
        resolve(res.recommendList.length ? {list: res.recommendList, id: res.recommendId, flag: true} : {list: normalPlpList, id: res.recommendId, flag: false});
      })
      .catch(async(e) => {
        console.log('获取推荐商品列表报错，去调search接口', e);
        const preRes = await searchPlpByPre(options, ctx, renderSide);
        const normalPlpList = preRes.content || [];
        resolve(normalPlpList);
      });
  });
};

export const searchArticleList = (params, ctx, renderSide) => {
  const utils=new BasicUtils(ctx);
  if (!params.articleIdList) return [];
  return new Promise((resolve, reject) => {
    ctx.$axios
      .get(
        `${ctx.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/batch-search`,
        {
          params: { ...params, preview: renderSide === 'client' ? 0 : null },
          ...utils.config
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        resolve([]);
      });
  });
};

export const getClpChildData = async (ctx, contentId, renderSide,isChild,dataValue, tabContentCode = '', flag = '') => {
  try {
    // 获取组件
    let data;
    if(dataValue){
      data=dataValue;
    }else{
      data=await getClpLayer(ctx, contentId, renderSide);
    }
    const tabPageChildClpList = {};
    // 存储seo信息
    const componentsData = [];
    const surveys= [];
    const surveyPromises = [];
    const plpData=[];

    data.layout.forEach((el, index) => {
      const _key = el.component[0].componentCode;
      if(isChild){
        if(!["ImageSlideProduct","Look","LookGroup","TextLink","CTA","ClpSingleImage","ClpMultiImage","Video","ProductList","NBanner","VerticalTabPage"].includes(_key)){
          return;
        }
      }
      const _value = JSON.parse(el.component[0].options || '{}');
      _value.labelInfoList = [];
      if (_value.labelInfo) {
        _value.labelInfo.forEach((labelItem) => {
          _value.labelInfoList.push(`${labelItem.labelGroup}:${labelItem.label.join('|')}`);
        });
      }
      const _position = el.layoutPositionCode;
      let _viewport = _value.terminal || 'pc,mobile';
      _viewport = _viewport.toLowerCase();
      _viewport = _viewport.split(',').filter((it) => {
        return it === 'pc' || it === 'mobile';
      });
      const _class =
        _viewport.includes('mobile') && _viewport.includes('pc')
          ? ''
          : _viewport.includes('mobile')
          ? 'mobile-only'
          : _viewport.includes('pc') ? 'desktop-only' : '';
      if (['TabPage'].includes(_key) && _value.content === 'clp') {
        tabPageChildClpList[index] = { ..._value, code: _key, position: `a${_position}`};
      }
      if (_key === 'Survey') {
        surveys.push({..._value, code: _key, position: `a${_position}`});
      }

      // 将数据整理称componentsData
      componentsData.push({
        code: _key,
        options: _value,
        viewport: _viewport,
        class: _class,
        position: 'a' + _position,
        key: 'key'+  contentId + "-" + _position,
        tabContentCode: el.component[0].tabContentCode
      });
    });
    const clputils = new CLPUtils(ctx,componentsData);
    await clputils.getAllPlpList(ctx);
    await clputils.getAllArticles(ctx);
    // 获取survey的json
    for (const el of Object.keys(surveys)) {
      const surveyS3Info = surveys[el].surveyS3Info;
      let address = '';
      if (surveyS3Info) {
        const hasACPath = ctx.app?.router?.history.current.path.toLowerCase().includes('/ac/') || ctx.$route?.path.toLowerCase().includes('/ac/');
        const hasOrPath = ctx.app?.router?.history.current.path.toLowerCase().includes('/or/') || ctx.$route?.path.toLowerCase().includes('/or/');
        const shopCode = ctx.app?.router?.history.current.query.shopCode || ctx.$route?.query.shopCode;
        const ua = ctx.req?.headers['user-agent'].toLowerCase() || window?.navigator.userAgent.toLowerCase();
        const isCom = !ua.includes('miniprogram') && !ua.includes('adidasapp') && !hasOrPath && !shopCode;

        if (hasOrPath && shopCode) {
          address = surveyS3Info?.OR?.address || '';
        } else if (hasACPath) {
          address = surveyS3Info?.ADICLUB?.address || '';
        } else if (ua.includes('miniprogram') && !hasOrPath && !shopCode && !hasACPath) {
          address = surveyS3Info?.WMS?.address || '';
        } else if (ua.includes('adidasapp')) {
          address = surveyS3Info?.APP?.address || '';
        } else if (isCom)  {
          address = surveyS3Info?.COM?.address || '';
        }
      } else {
        address = surveys[el].surveyS3Address;
      }

      surveyPromises.push(getSurveyLayer(ctx, address, surveys[el].surveyCode));
    }
    const surveyData = await Promise.all(surveyPromises);
    for (const component of componentsData) {
      if (component.code === 'Survey') {
        const detail = surveyData.find((data) => { return data.surveyCode === component.options.surveyCode; });
          const searchProduct = [];
        // 当时survey组件的 shuffle问卷类型时，这里一起批量根据articleId查询产品列表
          detail?.questionList?.forEach((item,index)=>{
            if (item.questionType === "Shuffle") {
              if (item?.questionDescriptionDetail?.optionList){
                item?.questionDescriptionDetail?.optionList?.forEach((optionItem,optionIndex)=>{
                  optionItem?.optionArticleList?.forEach(listItem=>{
                    searchProduct.push(listItem);
                  });
                });
              }

            }

          });
          const res= await searchArticleList(
            { articleIdList: [...searchProduct].join(',') },
            ctx,
            renderSide
          );
          if (res) {
            detail?.questionList?.forEach((item,index)=>{
              if (item.questionType === "Shuffle") {
                // 这里开始根据articleId来匹配选项商品列表，并取前四个商品
                if (item?.questionDescriptionDetail?.optionList) {
                  item?.questionDescriptionDetail?.optionList?.forEach((optionItem, optionIndex) => {
                    const productListResult = [];
                    optionItem?.optionArticleList?.forEach(listItem => {
                      const tmpProduct = res.find(resItem => resItem.articleId === listItem);
                      if(tmpProduct){
                        productListResult.push(tmpProduct);
                      }
                    });
                    optionItem.productList = productListResult.slice(0,4);
                  });
                }
              }
            });
          }
        component.detail = detail;
      }
    }
    // tabpage 子clp逻辑
    for (const el of Object.values(tabPageChildClpList)) {
      if (el.code === 'TabPage'&& el.content==='clp') {
        for(const item of el.itemList){
          item.clpData=null;
        }
        const clpCode=parseInt(el.itemList[0].clpFileName);
        const currentTabContentCode = el.itemList[0].tabContentCode;
        const clpData= el.itemList[0].clpData={
          contentId:clpCode.toString(),
          hasState:false,
          getData: getClpChildData(ctx,clpCode, 'server',true,null, currentTabContentCode)
        };
        plpData.push(clpData);
      }
    }

    for(const item of plpData){
      if(item.getData&&!item.data){
        const data=await item.getData;
        item.data=data.data;
        item.componentsData=data.componentsData;
        item.feedProductList=data.feedProductList;
        delete item.getData;
      }
    }

    return {
      data:data,
      componentsData:componentsData
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};
export const changeTabPageData = async (ctx, contentId, renderSide,isChild,dataValue, tabContentCode = '', clpContentCode) => {
  try {
    // 获取组件
    let data;
    if(dataValue){
      data=dataValue;
    }else{
      data=await getClpLayer(ctx, contentId, renderSide);
    }
    data = filterClpDataByTerminal(data, ctx);
    const tabPageChildClpList = {};
    // 存储seo信息
    const plpData=[];
    const componentsData = [];
    const recommendProdList = {};
    const prePromises = [];
    const posData = [];
    const allPreProductDataObj = {};
    data.layout.forEach((el, index) => {
      const _key = el.component[0].componentCode;
      if(isChild){
        if(!["Look","ImageSlideProduct","LookGroup","TextLink","CTA","ClpSingleImage","ClpMultiImage","Video","ProductList","NBanner", "VerticalTabPage"].includes(_key)){
          return;
        }
      }

      const _value = JSON.parse(el.component[0].options || '{}');
      _value.labelInfoList = [];
      if (_value.labelInfo) {
        _value.labelInfo.forEach((labelItem) => {
          _value.labelInfoList.push(`${labelItem.labelGroup}:${labelItem.label.join('|')}`);
        });
      }
      const _position = el.layoutPositionCode;
      let _viewport = _value.terminal || 'pc,mobile';
      _viewport = _viewport.toLowerCase();
      _viewport = _viewport.split(',').filter((it) => {
        return it === 'pc' || it === 'mobile';
      });
      const _class =
        _viewport.includes('mobile') && _viewport.includes('pc')
          ? ''
          : _viewport.includes('mobile')
          ? 'mobile-only'
          : _viewport.includes('pc') ? 'desktop-only' : '';

      if (['TabPage'].includes(_key) && _value.content === 'clp') {
        tabPageChildClpList[index] = { ..._value, code: _key, position: `a${_position}`};
      }

      if (['ProductList'].includes(_key) && _value.preCategoryCode) {
        recommendProdList[_position] = {..._value, code: _key, _position};
      }

      // 将数据整理称componentsData
      componentsData.push({
        code: _key,
        options: _value,
        viewport: _viewport,
        class: _class,
        key: 'key'+  contentId + "-" + _position,
        position: 'a' + _position
      });
    });

    const clputils = new CLPUtils(ctx,componentsData);
    await clputils.getAllPlpList(ctx);
    await clputils.getAllArticles(ctx);

    for (const el of Object.values(recommendProdList)) {
      prePromises.push(getRecommendedPlp(el, clpContentCode, ctx, tabContentCode, renderSide));
    }
    // tabpage 子clp逻辑
    for (const el of Object.values(tabPageChildClpList)) {
      if (el.code === 'TabPage'&& el.content==='clp') {
        for(const item of el.itemList){
          item.clpData=null;
        }
        const clpCode=parseInt(el.itemList[0].clpFileName);
        const currentTabContentCode = el.itemList[0].tabContentCode;
        const clpData= el.itemList[0].clpData={
          contentId:clpCode.toString(),
          hasState:false,
          getData: getClpChildData(ctx,clpCode, 'server',true,null, currentTabContentCode)
        };
        plpData.push(clpData);
      }
    }
    for(const item of plpData){
      if(item.getData&&!item.data){
        const data=await item.getData;
        item.data=data.data;
        item.componentsData=data.componentsData;
        item.feedProductList=data.feedProductList;
        delete item.getData;
      }
    }

    // 处理子clp的pre数据
    const allPreProductData = await Promise.all(prePromises);
    for (const el of Object.keys(recommendProdList)) {
      posData.push(el);
    }
    for (let i = 0; i < allPreProductData.length; i++) {
      allPreProductDataObj[posData[i]] = allPreProductData[i];
    }
    componentsData.forEach((comp) => {
      const componentDataItem = comp;
      if (componentDataItem.code === 'ProductList') {
        const preCategoryCode = componentDataItem.options.preCategoryCode;
        const pos = +(componentDataItem.position.replace(/[^0-9]/ig, ''));
        const preContent = allPreProductDataObj[pos];
        if (preCategoryCode) {
            componentDataItem.options.products = preContent.list;
            componentDataItem.options.recommendId = preContent.id;
            componentDataItem.options.flag = preContent.flag;
        }
      }
    });
    return {
      data:data,
      componentsData:componentsData
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};
const filterClpDataByTerminal = (clpData, ctx) => {
  const layout = clpData.layout;
  const hasOrPath = ctx.app?.router?.history.current.path.toLowerCase().includes('/or/') || ctx.$route?.path.toLowerCase().includes('/or/');
  const shopCode = ctx.app?.router?.history.current.query.shopCode || ctx.$route?.query.shopCode;
  const ua = ctx.req?.headers['user-agent'].toLowerCase() || window?.navigator.userAgent.toLowerCase();
  const isCom = !ua.includes('miniprogram') && !ua.includes('adidasapp') && !hasOrPath && !shopCode;
  console.log('>>ua<<', ua);
  // eslint-disable-next-line array-callback-return
  const newLayout = layout.filter((item) => {
    const options = JSON.parse(item.component[0].options);
    const terminal = options.terminal.toLowerCase().split(',');
    if (terminal.includes('or') && hasOrPath && shopCode) {
      return item;
    }
    if (ua.includes('miniprogram') && terminal.includes('wms') && !hasOrPath && !shopCode) {
      return item;
    }
    if (ua.includes('adidasapp') && terminal.includes('bosapp')) {
      return item;
    }
    if ((terminal.includes('mobile') || terminal.includes('pc')) && isCom)  {
      return item;
    }
  });
  clpData.layout = newLayout;
  return clpData;
};


export default {
  name: 'CLP',
  components: {
    textLink,
    cta,
    look,
    LookGroup,
    clpSingleImage,
    multiImage,
    clpVideo,
    imageSlideProduct,
    productList,
    NBanner,
    VerticalTabPage
  },
  props:{
    tabIndex:{
      type:Number,
      default:-1
    },
    clpData:{
      type: Object
      /**
       * data: clp数据经过getClpData 处理
       * contentId 活动id
       * preview url中 preview参数
       * appType url中 appType参数
       * hasState 是否有缓存
       */
    },
    franchise: {
      type: String
    },
    tabName: {
      type: String,
      default: ''
    },
    categoryCode: {
      type: String,
      default: ''
    },
    tabAnchor: {
      type: String,
      default: ''
    },
    clpShow:{
      type: Boolean,
      default: true
    }
  },
  data() {
    const dataPara={
        ua: '',
        pageScrollTop: 0,
        preview:this.clpData.preview,
        contentId:this.clpData.contentId,
        componentsData:this.clpData.componentsData,
        feedProductList:this.clpData.feedProductList
    };
    if(this.clpData.hasState){
        return dataPara;
      }
    try {
      // 有缓存时不请求

      let data ;
      if (this.clpData.preview !== '1') {
        data = this.clpData.data;
      } else {
        return dataPara;
      }
      let appId="";
      if(this.clpData.appType=== '1'){
        appId = '1413676670';
      }else if( this.appType=== '2'){
        appId='1525190009';
      }

      return {
        ...dataPara,
        seoData: {
          keyword: data?.seoKeyword,
          description: data?.seoDescription,
          title: data?.seoTitle,
          appId
        }
      };
    } catch (e) {
      console.log("data catch",e);
    }
  },
  computed: {
    // ...mapState('clp', [
    //   'componentsData',
    // ]),
    // ...mapState('myAccount', ['isLogin']),
    // isNonmemberLogin() {
    //   return !!this.$store.state.myAccount.isNonmemberLogin;
    // }
  },
  watch: {
    'clpData.contentId': {
      handler(nVal, oVal) {
        this.componentsData = deepcopy(this.filterCompDataByTerminal(this.clpData.componentsData));
      }
    }
  },
  mounted() {
    // const data = [null, this.contentId || null];
    // trackClpView(...data);
    // 判断是否为小程序或者app打开
    // this.handleJump();
    // 有缓存的clp contentId
    this.ua = window?.navigator.userAgent.toLowerCase();
    this.componentsData = deepcopy(this.filterCompDataByTerminal(this.componentsData));
  },
  methods: {
    getDetail(detail){
      return [detail];
    },
    // by 渠道filter组件
    filterCompDataByTerminal(componentsData) {
      const copyComponentsData = deepcopy(componentsData);
      const hasOrPath = this.$route?.path.toLowerCase().includes('/or/');
      const hasACPath = this.$route?.path.toLowerCase().includes('/ac/');
      const shopCode = this.$route?.query.shopCode;
      // const ua = window?.navigator.userAgent.toLowerCase();
      const isCom = !this.ua.includes('miniprogram') && !this.ua.includes('adidasapp') && !hasOrPath && !shopCode && !hasACPath;
      // eslint-disable-next-line array-callback-return
      const newComponentsData = copyComponentsData.filter((item) => {
        const options = item.options;
        const terminal = options.terminal.toLowerCase().split(',');
        if (terminal.includes('or') && hasOrPath && shopCode) {
          return item;
        }
        if (terminal.includes('adiclub') && hasACPath) {
          return item;
        }
        if (this.ua.includes('miniprogram') && terminal.includes('wms') && !hasOrPath && !shopCode && !hasACPath) {
          return item;
        }
        if (this.ua.includes('adidasapp') && terminal.includes('bosapp')) {
          return item;
        }
        if ((terminal.includes('mobile') || terminal.includes('pc')) && isCom)  {
          return item;
        }
      });
      return newComponentsData;
    },
    // 暴露给父组件调用获取ProductList
    getProductList(){
      let productList;
      if(this.$refs.components){
        const components=(this.$refs.components?.length)?this.$refs.components:[this.$refs.components];
        for(const component of components){
          if(component.getProduct){
            try{
              const product= component.getProduct();
              if(product && product instanceof Array){
                productList=productList||[];
                productList.push(...product);
              }else if(product){
                productList=productList||[];
                productList.push(product);
              }
            }catch{}
          }
        }
      }
      return productList;
    }
  }
};
