import { createPopper } from '@popperjs/core';
import { addClass } from '../../../utilities/dom/addClass';
import { createTooltipTrigger } from './LibTooltip.trigger';

// 处理tooltips dom 变化的指令
function createTooltipService(el, option) {
  if (option == null) {
    option = {};
  }
  if (typeof option === 'string') {
    option = { content: option };
  }
  option.reference = el;
  option.el = {
    reference: el,
    ...(() => {
      const popperEl = document.createElement('div');
      addClass(popperEl, 'lib-tooltip-content');
      addClass(popperEl, `lib-tooltip-content-${option.theme || 'black'}`);

      const arrowEl = document.createElement('div');
      addClass(arrowEl, 'lib-tooltip-arrow');
      arrowEl.setAttribute('data-popper-arrow', '');

      const contentEl = document.createElement('div');
      contentEl.innerHTML = option.content;

      popperEl.appendChild(contentEl);
      popperEl.appendChild(arrowEl);

      return {
        popper: popperEl,
        content: contentEl,
        arrow: arrowEl,
      };
    })(),
  };

  const show = () => {
    if (!option.popper) {
      document.body.appendChild(option.el.popper);
      option.popper = createPopper(option.el.reference, option.el.popper, {
        placement: option.placement || 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: option.offset || [0, 8],
            },
          },
          {
            name: 'arrow',
            options: {
              padding: 8,
            },
          },
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false, // true by default
            },
          },
        ],
      });
    }
    option.popper.update();
    option.el.popper.setAttribute('show', '');
  };

  const hide = () => {
    option.el.popper.removeAttribute('show');
  };

  option.triggerManager = createTooltipTrigger[
    option.trigger || 'hover'
  ]().initialize({
    popper: option.el.popper,
    reference: option.el.reference,
    show,
    hide,
    hideWhenScroll: option.hideWhenScroll, // 当滚动元素时，自动隐藏tooltips
    hideWhenScrollSelector: option.hideWhenScrollSelector, // dom选择器中参数
  });

  return {
    update: (el, value) => {
      const content = typeof value === 'string' ? value : value.content;
      option.el.content.innerHTML = content;
      !!option.popper && option.popper.update();
    },
    unbind: () => {
      !!option.popper && option.popper.destroy();
      !!option.triggerManager && option.triggerManager.destroy();
      !!option.el.popper.parentElement &&
        option.el.popper.parentElement.removeChild(option.el.popper);
    },
  };
}

export function installTooltipDirective(Vue) {

  const map = new WeakMap();
  console.log('warn:plugins installTooltipDirective');
  Vue.directive('tooltip', {
    inserted(el, { value }) {
      console.log('plugins installTooltipDirective inserted');
      if (value) {
        const service = createTooltipService(el, value);
        map.set(el, service);
        service.update(el, value);
      }
    },
    update(el, { value }) {
      console.log('warn:plugins installTooltipDirective update');
      if (value) {
        let service = map.get(el);
        if (!service) {
          service = createTooltipService(el, value);
          map.set(el, service);
        }
        service.update(el, value);
      } else {
        let service = map.get(el);
        if (service) {
          service.unbind();
          service = null;
          map.set(el, service);
        }
      }
    },
    unbind(el) {
      console.log('warn:plugins installTooltipDirective unbind');
      const service = map.get(el);
      if (service) {
        service.unbind();
      }
    },
  });
}
