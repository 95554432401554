
import { getCurrentLink } from '../util';
import specialLinkMixin from '../common/specialLink.mixins';
import { parseURL } from '../../../utilities/helper/urlHelper';
import {
  trackClpPlpClick,
  trackClpButtonClick,
  trackClpPdpClick,
  getScrollDepth
} from '../track/index';
export default {
  mixins: [specialLinkMixin],
  props: {
    textLinkObj: {
      type: Object
    },
    code: {
      type: String,
      default: ''
    },
    franchise: {
      type: String
    },
    isChild: {
      type:Boolean,
      default: false
    },
    position: {
      type: String
    },
    tabName: {
      type: String,
      default: ''
    },
    categoryCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isMobile: true,
      showTextLink: true,
      justifyMap: {
        center: 'center',
        alignright: 'flex-end',
        alignleft: 'flex-start'
      },
      alignMap: {
        center: 'center',
        alignright: 'right',
        alignleft: 'left'
      }
      // textLinkObj: {
      //   backgroundColor: '#fff',
      //   text: {
      //     text: '<span>正文内容示例。</span>正文内容示例。正文内容示例。二级标题样式展示，中英混排英文用 adineue PRO',
      //     color: '#000',
      //     size: '15px',
      //     bold: false,
      //     textlinkColor: '#000',
      //   },
      //   link: 'xxxx',
      //   anchor: '01',
      // },
    };
  },
  computed: {
    // 文本样式
    handleStyle() {
      const { color, size, bold, position } = this.textLinkObj?.text || {};
      const textPos = position?.toLowerCase() || 'alignleft';
      let family = '';
      if (parseFloat(size) >= 18) {
        family = 'adineuePRO-Bold,PingFang SC,sans-serif, 微软雅黑';
      } else if (bold) {
        family = 'AdihausDIN-Bold,PingFang SC,sans-serif, 微软雅黑';
      } else {
        family = 'AdihausDIN-Regular,PingFang SC,sans-serif, 微软雅黑';
      }
      return {
        color: color || '#000',
        fontSize: size || '14px',
        fontWeight: bold ? '700' : '400',
        justifyContent: this.justifyMap[textPos] || 'flex-start',
        fontFamily: family
      };
    },
    backgroundStyle() {
      const style={
        backgroundColor:"transparent",
        maxWidth: "1920px",
        margin: "0 auto"
      };
      if(this.isMobile&&this.textLinkObj.mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="center";
        style.backgroundImage=`url(${this.textLinkObj.mobBackgroundImage})`;
      }else if(!this.isMobile&&this.textLinkObj.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="center";
        style.backgroundImage=`url(${this.textLinkObj.pcBackgroundImage})`;
      }else if(this.textLinkObj.backgroundColor){
        style.backgroundColor=this.textLinkObj.backgroundColor;
      }
      return style;
    },
    // 链接文字颜色
    handleColor() {
      const { textlinkColor } = this.textLinkObj?.text || {};
      return {
        color: textlinkColor || '#000'
      };
    },
    // 文字链文字内容处理
    handleText() {
      const { text } = this.textLinkObj?.text || {};
      const textArr = [];
      if (text) {
        if (text.includes('<span>') && text.includes('</span>')) {
          const firstText = text.split('<span>')[0];
          const textLink = text.split('<span>')[1].split('</span>')[0];
          const lastText = text.split('<span>');
          let totalText = '';
          for (let i = 1; i < lastText.length; i++) {
            if (lastText[i].includes('</span>')) {
              const lastTextArr = lastText[i].split('</span>');
              if (i === 1) {
                for (let j = 1; j < lastTextArr.length; j++) {
                  totalText += lastTextArr[j];
                }
              } else {
                for (let j = 0; j < lastTextArr.length; j++) {
                  totalText += lastTextArr[j];
                }
              }
            }
          }
          if (textLink === totalText) {
            textArr.push(firstText, textLink, '');
            return textArr;
          } else {
            textArr.push(firstText, textLink, totalText);
            return textArr;
          }
        } else {
          textArr.push(text, '', '');
          return textArr;
        }
      } else {
        textArr.push(text, '', '');
        return textArr;
      }
    },
    /* 处理link样式 */
    handleLinkStyle() {
      const { textlinkColor, size, bold } = this.textLinkObj?.text || {};
      // const textPos = position?.toLowerCase() || 'alignleft';
      // const textPos='alignright';
      let family = '';
      if (parseFloat(size) >= 18) {
        family = 'adineuePRO-Bold,PingFang SC,sans-serif, 微软雅黑';
      } else if (bold) {
        family = 'AdihausDIN-Bold,PingFang SC,sans-serif, 微软雅黑';
      } else {
        family = 'AdihausDIN-Regular,PingFang SC,sans-serif, 微软雅黑';
      }
      return {
        color: textlinkColor || '#000',
        fontSize: size || '14px',
        fontWeight: bold ? '700' : '400',
        // textAlign: this.alignMap[textPos] || 'left',
        fontFamily: family
      };
    },
    isQuote(){
      if(this?.textLinkObj?.style){
        return this.textLinkObj.style==='QUOTES';
      }else{
        return false;
      }
    },
    quoteStyle(){
      const { color, size, bold } = this.textLinkObj?.quotes || {};
      return {
        color: color || '#000',
        fontSize: size || '24px',
        fontWeight: bold ? '700' : '400',
        lineHeight: size || '24px'
      };
    },
    authorStyle(){
      const { color, size, bold } = this.textLinkObj?.quoter || {};
      let family = '';
       if (parseFloat(size) >= 18) {
        family = 'adineuePRO-Bold,PingFang SC,sans-serif, 微软雅黑';
      } else if (bold) {
        family = 'AdihausDIN-Bold,PingFang SC,sans-serif, 微软雅黑';
      } else {
        family = 'AdihausDIN-Regular,PingFang SC,sans-serif, 微软雅黑';
      }
      return {
        fontFamily: family,
        color: color || '#000',
        fontSize: size || '13px',
        fontWeight: bold ? '700' : '400'
      };
    },
    author(){
      if(this.textLinkObj?.quoter?.details){
        return this.textLinkObj.quoter.details;
      }else{
        return null;
      }
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 720;
    },
    // 链接、锚点跳转
    skip() {
      const _textLinkObj = this.textLinkObj;
      this.franchise ? _textLinkObj.franchise = this.franchise : '';
      const curTerminalLink = _textLinkObj.allLink ? getCurrentLink(_textLinkObj.allLink) : _textLinkObj.link || '';
      try {
        if (curTerminalLink) {
          // plp和splp 都算plp event
          if (
            curTerminalLink.includes('/pages/product/list') ||
            curTerminalLink.includes('/pages/plp/index') ||
            curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
            curTerminalLink.includes('/pages/product/rich-list') ||
            curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
            curTerminalLink.includes('adidas://category') ||
            parseURL(curTerminalLink).path.startsWith('/plp') ||
            parseURL(curTerminalLink).path.startsWith('/splp')
          ) {
            this.trackData('plp', curTerminalLink);
          } else if (
            curTerminalLink.includes('/pages/product/details') ||
            curTerminalLink.includes('adidas://product') ||
            curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
            parseURL(curTerminalLink).path === '/pdp'
          ) {
            this.trackData('pdp', curTerminalLink);
          } else {
            this.trackData('button', curTerminalLink);
          }
        } else {
          // 没有link直接是button类型埋点
          this.trackData('button', curTerminalLink);
        }
      } catch (error) {
      }
      this.handleSpecialLinkClick(curTerminalLink, _textLinkObj);
    },
    trackData(val, link) {
      let data = [];
      const text1 = this.handleText[1];
      const directAppProject = link.includes('BOS') && 'FS' || link.includes('CFM') && 'CFD' || '';
      switch (val) {
        case 'plp':
          data = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text1,
            component_code: 'TextLink',
            position_code: Number(this.position.slice(1)),
            card_position: 1,
            scroll_depth: getScrollDepth(this.$refs.textLinkRef.offsetTop),
            plp_url: link || '',
            plp_name: '',
            tab_name: this.tabName,
            tag: this.textLinkObj.labelInfoList.join(',')
          };

          trackClpPlpClick(data);
          break;
        case 'pdp':
          data = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text1,
            component_code: 'TextLink',
            position_code: Number(this.position.slice(1)),
            article_position: 1,
            tab_name: this.tabName,
            recommend_id: '',
            scroll_depth: getScrollDepth(this.$refs.textLinkRef.offsetTop),
            category_code: this.categoryCode,
            article_number: parseURL(link).query.articleId,
            brand: '',
            category: this.categoryCode,
            gender: '',
            tag: this.textLinkObj.labelInfoList.join(',')
          };
          trackClpPdpClick(data);
          break;
        case 'button':
          data = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text1,
            component_code: 'TextLink',
            position_code: Number(this.position.slice(1)),
            scroll_depth: getScrollDepth(this.$refs.textLinkRef.offsetTop),
            to_url: link || '',
            button_name: link === '领券' ? '领券' : text1 || '',
            tab_name: this.tabName,
            tag: this.textLinkObj.labelInfoList.join(',')
          };
          directAppProject ? data.direct_app_project = directAppProject : '';
          trackClpButtonClick(data);
          break;
      }
    }
  }
};
