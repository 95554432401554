
import { LibTooltip } from '@adidas-com-front/ui-library';
import { serviceOnlineClick, serviceTelClick } from '../homepage/track';
import wxPopper from './wx-popper';
import dyPopper from './dy-popper';
import wbPopper from './wb-popper';
import { trackIntroView, trackIntroClick } from './track';
import { openLive800 } from '~/utilities/helper/live800';


export default {
  name: 'Footer',
  components: {
    LibTooltip,
    dyPopper,
    wxPopper,
    wbPopper
  },
  props: {
    size: {
      // 页脚的尺寸：分为一般和简版。其中简版只包含隐私申明、版权申明等最底部信息
      type: String,
      default: 'normal'
    },
    showInMobile: {
      // 移动端是否显示页脚标识，默认不在移动端显示
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mobile: false,
      // 职业发展中心二维码图片地址
      jobQrCode: `${this.$config.S3_HOST}/ecp-member/cdn-image/right/career_development.png?im=Resize=(200,200)`,
      // adidas微信会员中心二维码图片地址
      adiClubQrCode: `https://adiclub.static.adidas.com.cn/member/20240514/311ff24e-833f-458e-a8f5-f135b333bcf2.jpg`,
      // 微信小程序二维码图片地址
      weChatAppQrCode: `${this.$config.S3_HOST}/ecp-member/cdn-image/right/WmsHomeM_footer_2.jpg?im=Resize=(200,200)`,
      placement: 'right-start'
    };
  },
  mounted() {
    window.addEventListener('resize', this.getWindowsWidthAndHeight);
    this.getWindowsWidthAndHeight();
    trackIntroView();
  },
  methods: {
    callLive800(showInMobile) {
      serviceOnlineClick({page_title:'homepage'});
      openLive800(this,showInMobile?10212:10112);
    },
    trackServiceTel() {
      serviceTelClick({page_title:'homepage'});
    },
    getWindowsWidthAndHeight() {
      this.mobile = window.innerWidth < 720;
      this.placement = window.innerWidth < 960 ? 'left-start' : 'right-start';
      const windowInnerWidth = window.innerWidth;
      let timeoutWindowInnerWidth = 0;
      setTimeout(() => {
        timeoutWindowInnerWidth = window.innerWidth;
        // 当判断为微信内置浏览器时，则刷新页面防止布局错乱，使兼容
        if (
          windowInnerWidth !== timeoutWindowInnerWidth &&
          window.navigator.userAgent.includes('MicroMessenger')
        ) {
          // 如果是医护页面 不重新加载
          if (this.$route.path === '/d_n') {
            // 不执行任何逻辑
          } else {
            window.location.reload();
          }
        }
      }, 100);
    },

    // 跳转到地区切换页面
    goLocationSelector() {
      this.$router.push(`/location-selector?locale=zh_CN`);
    },
    // 会员其他页面跳转
    goPage(path) {
      window.open(window.location.origin + '/my-account/' + path, '_blank');
    },
    // 打开员工登录弹框
    employeeLogin() {
      this.$EmployeeLogin.showPop(this, true);
    },
    handleToClp(contentId, appType) {
      if (this.$config.RUNTIME_ENV === 'prod') {
        this.$router.push({
          path: '/clp',
          query: { contentId, appType }
        });
      } else {
        const url = `https://www.adidas.com.cn/clp?contentId=${contentId}&appType=${appType}`;
        location.href = url;
      }
    },
    goHelpCenter() {
      this.$router.push({
        path: '/clp',
        query: { contentId: 476 }
      });
    },
    handleGoToAbout() {
      trackIntroClick();

      const newWin = window.open();
      newWin.location = 'https://www.adidas.com.cn/aboutAdidas';
    }
  }
};
