
import { getCurrentLink } from '../util';
import specialLinkMixin from '../common/specialLink.mixins';
import { parseURL } from '~/utilities/helper/urlHelper';
import Hotzone from '~/components/clp/common/hotzone.vue';
export default {
  components: {
    Hotzone
  },
  mixins: [specialLinkMixin],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isMobile:{
      type:Boolean
    }
  },
  data() {
    return {
      itemStyleMoblie:{
        height:"0px"
      },
      itemStylePC:{
        height:"0px"
      },
      offsetWidth:0,
      conf:{
        '16:9':{
           child:[1,2],
           scale:0.5625
        },
        "1:1":{
           child:[1,2,3],
           scale:1
        },
        "3:4":{
           child:[1,2,3,4],
           scale:1.33333
        },
        "null":{
           child:[1,2,3,4],
           scale:null
        }
      },
      activeBanner: 0,
      swiperProgress: 0,
      swiperNavigationTop: '0px',
      swiperOptionLoop: {
        // simulateTouch: false,
        // slidesPerView: 4,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction : false,
        // },
        spaceBetween: 4,
        loopAdditionalSlides: 2,
        loop: true,
        speed: 500,
        on: {
          slideChange: () => {},
          init:()=>{
            this.onResize();
          },
          resize:()=>{
            this.onResize();
          }
        }
      },
      swiperOption: {
        spaceBetween: 2,
        slidesPerView: "auto",
        autoplay: {
          enabled: false,
          delay: 3000,
          disableOnInteraction: false
        },
        // speed: 500,
        on: {
          slideChange: () => {}
        }
      },
      swiperOptionPC: {
        watchOverflow: true,
        simulateTouch: false,
        freeMode: false,
        spaceBetween: 2,
        slidesPerView: 4,
        slidesPerGroup: 4,
        breakpoints: {
          719: {
            freeMode: true,
            spaceBetween: 2,
            slidesPerView: 3,
            slidesPerGroup: 1
          }
        },
        navigation: {
          nextEl: '.navigation-right',
          prevEl: '.navigation-left'
        },
        on: {
          slideChange: () => {
            if (this.swiperPC) {
              this.swiperProgress = this.swiperPC.progress;
              this.activeBanner = this.swiperPC.activeIndex;
            }
          },
          init:()=>{
            this.$nextTick(() => {
              this.onResize();
            });;
          },
          resize:()=>{
            this.$nextTick(() => {
              this.onResize();
            });
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.nBannerSwiper?.swiper;
    },
    swiperPC() {
      return this.$refs.nBannerSwiperPC?.swiper;
    },
    itemList(){
             return this.data.itemList;
    },
    backgroundStyle() {
      const style={
        backgroundColor:(this.data?.backgroundColor)?this.data.backgroundColor:"transparent"
      };

      if(this.isMobile&&this.data?.mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.data.mobBackgroundImage})`;
      }else if(!this.isMobile&&this.data?.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.data.pcBackgroundImage})`;
      }
      return style;
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.$nextTick(() => {
      // if (this.itemList.length > 2 && this.swiper) {
      //   this.swiper.params.autoplay.delay = 3000;
      //   this.swiper.params.autoplay.enabled = false;
      //   this.swiper.params.autoplay.disableOnInteraction = false;
      //   this.swiper.autoplay.start();
      // }
      this.onResize();
    });
  },
  beforeDestroy() {
    this.swiper?.destroy(false, false);
    this.swiperPC?.destroy(false, false);
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    handleClickSpecialLink(url) {
      this.$emit('clickSpecialLink', url);
    },
    handleTrackHotZoneClick(obj) {
      this.$emit('trackClick', obj);
    },
    // mini banner点击事件
    handleBannerClick(item) {
      const _options = item;
      const curTerminalLink = item.allLink ? getCurrentLink(item.allLink) : item.link || '';
      try {
        if (curTerminalLink) {
          if (
            curTerminalLink.includes('/pages/product/list') ||
            curTerminalLink.includes('/pages/plp/index') ||
            curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
            curTerminalLink.includes('/pages/product/rich-list') ||
            curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
            curTerminalLink.includes('adidas://category') ||
            parseURL(curTerminalLink).path.startsWith('/plp') ||
            parseURL(curTerminalLink).path.startsWith('/splp')
          ) {
            this.$emit('trackClick', {type: 'plp', item: item});
          } else if (
            curTerminalLink.includes('/pages/product/details') ||
            curTerminalLink.includes('adidas://product') ||
            curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
            parseURL(curTerminalLink).path === '/pdp') {
            this.$emit('trackClick', {type: 'pdp', item: item});
          } else {
            this.$emit('trackClick', {type: 'button', item: item});
          }
        }
      } catch (error) {
      }
      this.handleSpecialLinkClick(curTerminalLink, _options);
    },
    onResize(){
      let offsetWidthMoblie=0;
      let offsetWidthPC=0;
      const {ratio}=this.data;

      const child=this.conf[ratio];
      if(this.swiper?.$el?.length){
        const $el=this.swiper?.$el[0];
        const item= $el.querySelector('.swiper-slide');
        if(item){
          offsetWidthMoblie= item.offsetWidth;
        }
      }
      this.itemStyleMoblie= {
        height: child ? (offsetWidthMoblie*child.scale)+'px' : 'auto'
      };
      if(this.$refs?.itemPC?.length){
        offsetWidthPC= this.$refs.itemPC[0].offsetWidth;
      }
      if(!offsetWidthPC&&this.swiperPC?.$el?.length){
        const $el=this.swiperPC?.$el[0];
        const item= $el.querySelector('.swiper-slide');
        if(item){
          offsetWidthPC= item.offsetWidth;
        }
      }
      this.itemStylePC= {
        height: child ? offsetWidthPC+'px' : 'auto'
      };
    }
  }
};
