
import LibMask from '@adidas-com-front/ui-library/src/components/atoms/LibMask/LibMask.vue';
import debounce from 'lodash.debounce';
import SearchPcSkeleton from '../search-pc-skeleton/SearchPcSkeleton.vue';
import { detectOS } from '../../../utilities/helper/SystemHelper';
import { parseURL } from '../../../utilities/helper/urlHelper';
import { trackSearchViewWeb } from './track';
import { trackSearchAutocomplete,searchClickTerm } from '~/pages/search/track';
export default {
  name: 'SearchOverlay',
  components: {
    SearchPcSkeleton,
    LibMask
  },
  props: {
    // overlay 显隐的控制
    showOverLay: {
      type: Boolean,
      default: true
    },
    // 预置词文本
    presetWord: {
      type: String
    }
  },
  data() {
    return {
      visible: false,
      // 是否显示搜索历史
      showHistory: false,
      // 是否显示热词/搜索历史
      showContent: true,
       // 搜索历史容器
      historyWords: [],
      // 搜索热词容器
      hotWordList: [],
      // 用户输入的值
      inputValue: '',
      // 是否显示搜索联想词
      showAssociate: false,
      // 联想词容器
      recommendation: [],
      // 是否第一次打开overlay
      firstIn: true,
       // 是否显示骨架屏
      showSkeleton: true,
       // 是否显示热词
      showHoyWord: false,
      // 用于清除定时器
      focusTimer: null
    };
  },

  computed: {
    // overlay打开关闭时添加动效
    overlayClass() {
      const cls = ['search-overlay'];
      if (!this.visible) {
        cls.push('hide-overlay');
      }
      return cls;
    },
    // 热词、搜索历史区域动效
    boxClass() {
      const cls = [];
      if (this.visible) {
        cls.push('hide-box');
      }
      return cls;
    },

    //  搜索框区域动效
    inputClass() {
      const cls = [];
      if (this.visible) {
        cls.push('input-box-in');
      }
      return cls;
    },

    // 是否显示删除所有按钮
    showClearIcon(e) {
      return !!this.inputValue.trim();
    }
  },

  watch: {
    // 用户输入的值 双向绑定
    inputValue(val) {
      if (val === '') {
        this.showAssociate = false;
      }
      if (val.trim()) {
        this.showContent = false;
        this.showHistory = false;
        this.getRecommendation(val);
      } else if (this.historyWords.length) {
        this.showHistory = true;
        this.showContent = true;
      }
    },

    // overlay打开时的初始化操作
    // pc端打开时inout自动聚焦
    showOverLay(val) {
      this.visible = val;
      if (this.firstIn) {
        this.firstIn = false;
        this.init();
      }
      this.getHistoryData();
      if (detectOS() !== 'PC') { return; }
      if (val) {
        this.focusTimer = setTimeout(() => {
          this.$refs.search_input.focus();
        }, 300);
      } else {
        this.$refs.search_input.blur();
        clearTimeout(this.focusTimer);
      }
    },

    // 在页面切换时关闭overlay
    $route(oldRoute, newRoute) {
      if (oldRoute.query.keyword !== newRoute.query.keyword) {
        setTimeout(() => {
          this.showAssociate = false;
          this.inputValue = '';
          this.recommendation = [];
        });
      }
    }
  },

  mounted() {
    this.getHistoryData();
  },
  methods: {
    async init() {
      // 请求热门搜索接口数据
      const hotWordList = await this.$axios.get(
        this.$config.ECP_API_HOST + '/o2srh/v1/pub/hot-word'
      );
      if (hotWordList.hotWordList.length) {
        this.showHoyWord = true;
      } else {
        this.showHoyWord = false;
      }
      this.hotWordList = hotWordList.hotWordTypeList;
      this.showSkeleton = false;
    },

    // 从cookies读取搜索历史记录
    getHistoryData() {
      this.$store.commit('searchHistory/initializeWord');
      this.historyWords = this.$store.state.searchHistory.words;
      if (this.historyWords.length) { this.showHistory = true; }
    },

    // settimeout
    setTimer(item, time = 200) {
      let timer = setTimeout(() => {
        this.$store.commit('searchHistory/setWords', item);
      }, time);
      this.$once('hook:beforeDestroy', () => {
        clearTimeout(timer);
        timer = null;
      });
    },

    async jumpWhere(keyword, type, item) {
      try {
        // 若搜索关键词相同则直接关闭
        if (keyword === decodeURI(this.$route.query.keyword)) {
          this.$emit('closeOverlay');
          this.showAssociate = false;
          this.inputValue = '';
          this.recommendation = [];
        }
        const res = await this.$axios.get(
          this.$config.ECP_API_HOST + '/o2srh/v1/pub/platform-products/search',
          {
            params: {
              page: 1,
              pageSize: 24,
              abTest: 'A',
              keyword
            }
          }
        );
        const href = window.location.href;
        const pageName =
          href.indexOf('plp') > 0
            ? 'PLP'
            : href.indexOf('pdp') > 0
            ? 'PDP'
            : href.indexOf('my-account') > 0
            ? 'my-account'
            : 'Homepage';
        // search 埋点
        trackSearchViewWeb(
           // ？根据点击客服的页面取值 "ACCOUNT|LOGIN"/" SHOPPING CART|EMPTY CART"
          pageName,
          // ？根据点击客服的页面取值 "ACCOUNT”/“SHOPPING CART”
          pageName,
          // 从哪个页面进行的搜素 PDP/Homepage/PLP….
          pageName,
          // 搜索关键词
          keyword,
          // 搜素类型 KEYWORDS RECOMMENDATION HISTORY
          type === 'hot' ? 'recommendation' : type,
          // 结果产品数量
          res.totalElements,
          // 是否使用默认关键词进行搜索（KEYWORDS，键入回车）
          type !== 'keywords',
          // 搜索结果类型 CLP活动页，PLP，No Result，H5目前没有
          res.totalElements > 0 ? 'PLP' : 'No Result',
          // 是否为搜索关键词自动补全时的搜索结果（猜你想搜）
          type === 'recommendation',
          this.inputValue,
          JSON.stringify(item),
          this.recommendation.map((item)=> { return item.word; })
        );
        const searchClickTermData={
           // eslint-disable-next-line camelcase
            search_type: type,
            // eslint-disable-next-line camelcase
            search_term: keyword,
            // eslint-disable-next-line camelcase
            search_result_type: '',
            // eslint-disable-next-line camelcase
            search_result_detail: '',
            // eslint-disable-next-line camelcase
            search_results:res.totalElements
        };

        const path = '/plp/search';
        const queryObj = {
          keyword: encodeURI(keyword),
          page: 1
        };
        if (res.plpType === 'deepLink') {
          if (res.deepLink.startsWith('http')) {
            if (res.deepLink.includes("adidas.com.cn/splp?")) {
              this.$store.commit('searchHistory/setSplpFlag', keyword);
            }
            searchClickTermData.search_result_detail=res.deepLink;
            searchClickTerm(searchClickTermData);
            window.location.href = res.deepLink;
          } else {
            const routerObj = parseURL(res.deepLink);
            const temp = routerObj.path.split('/');
            if (
              temp[temp.length - 1] === decodeURI(this.$route.query.keyword) ||
              temp[temp.length - 1] === decodeURI(this.$route.params.category)
            ) {
              this.$emit('closeOverlay');
              this.showAssociate = false;
              this.inputValue = '';
              this.recommendation = [];
            }
            if (routerObj.path.startsWith('/plp')) {
              routerObj.query = {
                page: 1,
                type: 'deepLink'
              };
            }
            const urlPara=this.$router.resolve(routerObj);
            searchClickTermData.search_result_detail=urlPara.href;
            searchClickTerm(searchClickTermData);
            this.$router.push(routerObj);
          }
          return;
        }
        this.$emit('jump');

        // 仅有一个商品直接跳转pdp
        if (res.totalElements === 1) {
          const urlPara={
            path: '/pdp',
            query: { articleId: res.content[0].articleId },
            params: { flag: 'home' }
          };
          searchClickTermData.search_result_detail=this.$router.resolve(urlPara).href;
          searchClickTerm(searchClickTermData);
          this.$router.push(urlPara);
          return false;
        }

        if (res.totalElements === 0 && res.plpType !== 'deepLink') {
          queryObj.type = 'searchNoResult';
          searchClickTermData.search_result_type="No result";
        }
        const urlPara={
          path,
          query: queryObj,
          params: { flag: 'home' }
        };
        searchClickTermData.search_result_detail=this.$router.resolve(urlPara).href;
        searchClickTerm(searchClickTermData);
        // 清楚筛选条件
        this.$router.push(urlPara);
      } catch (e) {
        if (e.data && (e.data.code === '1000' || e.data.code === '20000')) {
          this.$dialog({
            title: '当前页面访问人数过多',
            confirmButton: true,
            confirmButtonText: '重试',
            on: {
              confirm: ({ close }) => {
                window.location.reload();
                close();
              }
            }
          });
        } else if (e.data && e.data.code === '1002') {
          this.$dialog({
            title: e.data.message,
            confirmButton: true,
            confirmButtonText: '我知道了',
            on: {
              confirm: ({ close }) => {
                window.location.reload();
                close();
              }
            }
          });
        }
      }
    },

    // 点击回车执行搜索
    addWords(e) {
      const item = e.target.value ? e.target.value : this.presetWord;
      this.$store.commit('searchHistory/setWords', item);
      this.jumpWhere(item, 'keywords');
    },

    jump(e) {
      const item = e.target.value ? e.target.value : this.presetWord;
      this.$store.commit('searchHistory/setWords', item);
      this.jumpWhere(item, 'keywords');
    },

    // 清空搜索记录
    clearHistoryWords() {
      this.historyWords = [];
      this.showHistory = false;
      this.$store.commit('searchHistory/clearHistoryWords');
    },

    // 清空输入框内容
    cleanInputValue() {
      this.inputValue = '';
    },

    // 热词或搜索记录被点击时执行搜索
    wordClick(item, type) {
      this.jumpWhere(item, type);
      setTimeout(() => {
        this.$store.commit('searchHistory/setWords', item);
      }, 200);
    },

    // 联想词点击时执行搜索
    associateClick(item, type) {
      const keyword = item.word.split('>')[1];
      this.$store.commit('searchHistory/setWords', keyword);
      this.jumpWhere(keyword, type, item);
    },

    // 联想词
    getRecommendation: debounce(function (val) {
      if (val === this.inputValue) {
        this.$axios
          .get(
            this.$config.ECP_API_HOST + '/o2srh/v2/pub/keyword-recommendation',
            {
              params: {
                keyword: val
              }
            }
          )
          .then((res) => {
            if (res.recommendationWords && res.recommendationWords.length > 0) {
              this.inputValue === val && (this.showAssociate = true);

              if (res.recommendationWords.length > 40) {
                res.recommendationWords.splice(40);
              }
              this.recommendation = res.recommendationWords || [];
              trackSearchAutocomplete(
                JSON.stringify(this.recommendation),
                this.inputValue,
                this.recommendation.map((item)=> { return item.word; })
              );
            } else {
              this.showAssociate = false;
            }
          });
      }
    }, 500),

    // 点击遮罩事件
    clickMask() {
      this.showContent = true;
      this.visible = false;
      this.inputValue = '';
      this.$emit('clickMask');
    }
  }
};
