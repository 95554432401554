
// import { bannerView, bannerClick, getLinkType } from '../track';
import deepcopy from 'deepcopy';
import { mapState } from 'vuex';
import { trackClpPdpClick, trackClpPlpClick, trackClpButtonClick, getScrollDepth } from '../track';
import pcProductList from './components/pc-list.vue';
import mobileProductList from './components/mobile-list.vue';
import { handleClickLink, lookHandleHref, getCurrentLink } from '~/components/clp/util';
import { getImgUrl, parseURL } from '~/utilities/helper/urlHelper';

export default {
  name: 'FeedProduct',
  components: {
    pcProductList,mobileProductList
  },
  props: {
    itemList: {
      type: [Object, Array],
      default: () => {}
    },
    options: {
      type: [Object, Array],
      default: () => {}
    },
    sensorInfo: {
      type: Object,
      default: () => ({})
    },
    franchise: {
      type: String
    },
    tabName: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    clpShow:{
      type:Boolean,
      default:true
    },
    isUnderTabPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMobile: true,
      isLoad: false
    };
  },
  computed: {
    ...mapState('clp', [
      'plpListMap',
      'articleList'
    ]),
    kvSliderList() {
      // if (this.isUnderTabPage) {
      //   console.log('sfjsjdfksdf', this.itemList);
      //   return this.itemList;
      // } else {
        const itemList = deepcopy(this.itemList);
        const length = itemList.length;
        for (let i = 0; i < length; i++) {
          const curItem = itemList[i];
          const maintainType = curItem.maintainType;
          curItem.articleListData = [];
          curItem.products = [];
          const dLink = curItem.searchTerm === 'categoryCode' ? `/plp?categoryCode=${curItem.categoryCode}` : `/plp?keyword=${curItem.keyword}`;
          if (maintainType === 'PLP') {
            const articleDetailObj = this.plpListMap.find((plp) => {
              return dLink.includes(plp.link);
            });
            if (articleDetailObj)
              curItem.products.push(...articleDetailObj.content);
          } else {
            const articleDetailList = this.articleList.filter((article) => {
              return curItem.articleList.includes(article.articleId);
            });

            if (articleDetailList.length){
              curItem.articleListData.push(...articleDetailList);
            }
          }
          curItem.link = curItem.imageRedirectLink;
        }
        return itemList;
      // }
    },
    imgInfo() {
      return function (item) {
        return this.isMobile
          ? {
              img: getImgUrl(item.mainImageMobile, 1000),
              tag: item.mobSeoTag
            }
          : {
              img: getImgUrl(item.mainImagePc, 500),
              tag: item.pcSeoTag
            };
      };
    },
    imgInfoURL(){
      return function (item) {
        return this.isMobile? getImgUrl(item.mainImageMobile, 1000) : getImgUrl(item.mainImagePc, 500);
      };
    },
    optLink() {
      return function (item, type) {
        if (type === 'Image') {
          return item.photoLink || item.link;
        } else if (type === 'Button') {
          return item.buttonLink || item.link;
        } else {
          return '';
        }
      };
    },

    handleUrl() {
      return function (link) {
        if (!link) {return 'javascript:void(0);';}
        const url= lookHandleHref(link);
        if(!url){
          return 'javascript:void(0);';
        }
        return url;
      };
    },
    categorycode(){
      if(this.itemList.length>0){
        const tab=this.itemList[0];
        if(tab.maintainType==='PLP'&&tab.searchTerm==="categoryCode"){
          return tab.categoryCode;
        }
      }
      return '';
    },
    computedBackgroundStyle() {
      if (this.options.backgroundColor || this.options.mobBackgroundImage) {
        const style={
          backgroundColor: this.options.backgroundColor || "transparent",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
          backgroundImage: `url(${this.options.mobBackgroundImage})`
        };
        return this.isMobile ? style : {};
      } else {
        return {};
      }
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    backgroundStyle(item) {
        const style={
          backgroundColor:(this.options.contentBackgroundColor || item.contentBackgroundColor) ? this.options.contentBackgroundColor || item.contentBackgroundColor : "transparent"
        };
        if(this.isMobile&&(this.options.contentMobBackgroundImage || item.contentMobBackgroundImage)){
          style.backgroundSize='cover';
          style.backgroundRepeat='no-repeat';
          style.backgroundPosition="top center";
          style.backgroundImage=`url(${this.options.contentMobBackgroundImage || item.contentMobBackgroundImage})`;
        }else if(!this.isMobile&&(this.options.contentPcBackgroundImage || item.contentPcBackgroundImage)){
          style.backgroundSize='cover';
          style.backgroundRepeat='no-repeat';
          style.backgroundPosition="top center";
          style.backgroundImage=`url(${this.options.contentPcBackgroundImage || item.contentPcBackgroundImage})`;
        }
        return !this.isMobile?style:{};
    },
    trackMore(val){
      const curTerminalLink = val.allLink ? getCurrentLink(val.allLink) : val.link || '';
      if (
        curTerminalLink.includes('/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/list') ||
        curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/rich-list') ||
        curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
        curTerminalLink.includes('adidas://category') ||
        parseURL(curTerminalLink).path.startsWith('/plp') ||
        parseURL(curTerminalLink).path.startsWith('/splp')
      ){
        const data={
          campaign_name: this.$route.query.contentId || '',
          sub_campaign_name: this.code,
          tag: this.options.labelInfoList.join(','),
          text:'',
          component_code:'ImageSlideProduct',
          position_code:Number(this.position.slice(1)),
          scroll_depth: getScrollDepth(this.$refs.lookRef.offsetTop),
          plp_url:curTerminalLink,
          tab_name: this.tabName,
          plp_name:"",
          multii_item_tag: val?.itemLabelInfoList?.join(',')

        };
        console.log('trackClpPlpClick',data);
        trackClpPlpClick(data);
       }else{
        const data={
          text:'',
          component_code: 'ImageSlideProduct',
          tag: this.options.labelInfoList.join(','),
          position_code: Number(this.position.slice(1)),
          scroll_depth: getScrollDepth(this.$refs.lookRef.offsetTop),
          campaign_name: this.$route.query.contentId || '',
          sub_campaign_name: this.code,
          button_name:`查看更多`,
          tab_name: this.tabName,
          to_url: curTerminalLink,
          multii_item_tag: val?.itemLabelInfoList?.join(',')
        };
        console.log("trackClpButtonClick",data);
        trackClpButtonClick(data);
       }
    },
    // sensorViewTrack() {
    //   const { bannerId, bannerName, bannerPosition } = this.sensorInfo;
    //   bannerView(bannerId, bannerName, bannerPosition);
    // },
    // sensorClickTrack(type, data) {
    //   const { bannerId, bannerName, bannerPosition } = this.sensorInfo;
    //   const { photoLink, buttonLink } = data;
    //   const linkType = getLinkType(type === 'Image' ? photoLink : buttonLink);
    //   bannerClick({
    //     bannerClickType: type,
    //     bannerId,
    //     bannerName,
    //     bannerPosition,
    //     bannerToPageType: linkType,
    //     bannerToUrl: type === 'Image' ? photoLink : buttonLink,
    //     // 下面是pdp需要的
    //     articleNumber: data.articleId,
    //     brand: data.brandName,
    //     category: data.category,
    //     gender: data.genderShow,
    //     modelName: data.modelNo,
    //     productName: data.articleName
    //   });
    // },
    sensorClickTrack(type, data) {
      console.log('sensorClickTrack======', type, data);
      const curTerminalLink = data.allLink ? getCurrentLink(data.allLink) : data.link || '';
      if (!curTerminalLink) {
        return;
      }
      if (
        curTerminalLink.includes('/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/list') ||
        curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/rich-list') ||
        curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
        curTerminalLink.includes('adidas://category') ||
        parseURL(curTerminalLink).path.startsWith('/plp') ||
        parseURL(curTerminalLink).path.startsWith('/splp')
      ) {
        this.trackData('plp', data, curTerminalLink);
      } else if (
        curTerminalLink.includes('/pages/product/details') ||
        curTerminalLink.includes('adidas://product') ||
        curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
        parseURL(curTerminalLink).path === '/pdp') {
        this.trackData('pdp', data, curTerminalLink);
      } else {
        this.trackData('button', data, curTerminalLink);
      }
    },
    trackData(val, data, link) {
      const text = [data.title?.text, data.subtitle?.text].filter(item => {
        return !!item;
      });
      let param;
      switch (val) {
        case 'plp':
          param = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'ImageSlideProduct',
            position_code: Number(this.position.slice(1)),
            card_position: 1,
            scroll_depth: getScrollDepth(this.$refs.lookRef.offsetTop),
            plp_url: link || '',
            plp_name: '',
            tag: data.labelInfoList.join(','),
            multii_item_tag: data?.itemLabelInfoList?.join(',')
          };
          trackClpPlpClick(param);
          break;
        case 'pdp':
          param = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'ImageSlideProduct',
            position_code: Number(this.position.slice(1)),
            article_position: 1,
            tab_name: this.tabName,
            recommend_id: '',
            scroll_depth: getScrollDepth(this.$refs.lookRef.offsetTop),
            category_code: this.categoryCode,
            article_number: parseURL(link).query.articleId,
            brand: '',
            category: '',
            gender: '',
            tag: data.labelInfoList.join(','),
            multii_item_tag: data?.itemLabelInfoList?.join(',')
          };

          trackClpPdpClick(param);
          break;
        case 'button':
          param = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'ImageSlideProduct',
            position_code: Number(this.position.slice(1)),
            scroll_depth: getScrollDepth(this.$refs.lookRef.offsetTop),
            to_url: link || '',
            button_name: '',
            tab_name: this.tabName,
            tag: data.labelInfoList.join(','),
            multii_item_tag: data?.itemLabelInfoList?.join(',')
          };
          trackClpButtonClick(param);
          break;
      }
    },
    linkRoute(item, type) {
      if (!this.optLink(item, type)) {return;}
      const url=lookHandleHref(item.link);
      if(!url){
        return;
      }
      const _item = { ...item, link: this.optLink(item, type) };
      this.franchise ? _item.franchise = this.franchise : '';
      handleClickLink.call(this, _item);
      this.sensorClickTrack(type, item);
    },
    handleResize() {
      this.isMobile = window.innerWidth < 720;
    },
    onLoad() {
      this.isLoad = true;
    },
    transmit() {
      return this.$parent.$options.name;
    },
    getProduct(){
      const articleIdList=[];
      for(const item of this.itemList){
        articleIdList.push(item.article_id);
      }
      return {
        campaign_name: this.$route.query.contentId || '',
        sub_campaign_name: this.code,
        component_code: 'ImageSlideProduct',
        tag_list: this.options.labelInfoList.join(','),
        category_code:this.categorycod,
        position_code: Number(this.position.slice(1)),
        tab_name:"",
        article_id_list:articleIdList.join("|"),
        scroll_depth: getScrollDepth(this.$refs.lookRef.offsetTop)
      };
    }
  }
};
