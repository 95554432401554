export const LibButtonType = {
  primary: 'primary',
  secondary: 'secondary',
  'in-line': 'in-line',
};

export const LibButtonItem = {
  filled: 'filled',
  unfilled: 'unfilled',
  regular: 'regular',
  bold: 'bold',
};
