
  import throttle from 'lodash.throttle';
  import { mapState } from 'vuex';
  import deepcopy from 'deepcopy';
  import ProductCardSkeleton from './components/skeleton.vue';
  import LibPageIndicator from './components/indicator.vue';
  import ProductCard from './components/list.vue';
  import NoMore from './components/noMoreList.vue';
  import LoadMore from './components/loading.vue';

  export default {
    components: {
      ProductCardSkeleton,
      LibPageIndicator,
      ProductCard,
      NoMore,
      LoadMore
    },
    props: {
      options: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    watch: {
      options: {
        handler(n, o) {
          if (n.listData && n.listData?.length) {
            setTimeout(() => {
              this.showSkeleton = false;
            }, 1000);
          }
        },
        deep: true,
        immediate: true
      }
    },
    computed: {
      ...mapState('clp', [
        'pplpPage'
      ]),
      isTenPage() {
        let dividend;
        if(this.isMediumScreen) {
          dividend = 5;
        } else if (this.isLargeScreen) {
          dividend = 10;
        }
        if (this.pplpPage%dividend === 0) {
          const item = this.pplpDataByPage.find((item) => {
            return item.page === this.pplpPage;
          });
          const data = item?.data || [];
          if (data.length) {
            return true;
          }
        } else {
          return false;
        }
      },
      pplpDataByPage() {
        return this.$store.state.clp.pplpData;
      },
      judgeNoMore() {
        const len = this.pplpDataByPage.length;
        return !this.pplpDataByPage[len - 1].data.length;
      },
      isLoadMore() {
        const pplpDataCurrentPage = this.pplpDataByPage.find((pplpItem) => {
          return pplpItem.page === this.pplpPage;
        });
        const pplpListCurrentPage = pplpDataCurrentPage?.data || [];
        return !!pplpListCurrentPage.length;
      }
    },
    data() {
      return {
        showSkeleton: true,
        page: 1,
        currentTop: 0,
        cardHeight: 0,
        arrowShow: false,
        isMobile: false,
        isMediumScreen: false,
        isLargeScreen: false
      };
    },
    mounted () {
      const onScroll = (() => {
      const getMoreList = throttle(this.getMoreList, 500);
        return () => {
          this.changePage();
          getMoreList();
        };
      })();
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      this.$on('hook:beforeDestroy', this.$utils.device.onPageScroll(onScroll));
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.isMobile = window.innerWidth < 720;
        this.isMediumScreen = window.innerWidth < 1440 && window.innerWidth > 719;
        this.isLargeScreen = window.innerWidth > 1439;
      },
      handleShowMore() {
        let page = deepcopy(this.pplpPage);
        page += 1;
        this.$store.commit('clp/SET_PPLP_PAGE', page);
        this.$emit('getMoreList', { page: this.page });
      },
      // 监听滚轮更新页数 || 控制排序栏消失以及隐藏
      changePage(e) {
        // 每页高度
        if (!this.$refs.productCard?.$children[0]) return;
        this.cardHeight =
          this.$refs.productCard.$children[0].$el?.offsetHeight || 0;
        this.currentTop = this.$refs.pplpCom.getBoundingClientRect().top;
        // console.log('currentPage', this.$refs.pplpCom.getBoundingClientRect().top);
        // this.showArrowAfterScrollEnd();
      },
      // 过1.5s 后显示回到顶部按钮
      // showArrowAfterScrollEnd() {
      //   if (this.timer) clearTimeout(this.timer);
      //   this.timer = setTimeout(() => {
      //     this.arrowShow = true;
      //   }, 1500);
      // },
      handleClickCard() {

      },
      // 回到顶部
      handleToTop() {
        this.$utils.device.setScrollTop(0);
      },
      // 拉到底部获取更多数据
      async getMoreList() {
        const { toBottom } = this.$utils.device.getScrollTop();
        if (
          toBottom <= 360 && this.isLoadMore && (!this.isTenPage || this.isMobile)) {
          // this.page += 1;
          let page = deepcopy(this.pplpPage);
          page += 1;
          this.$store.commit('clp/SET_PPLP_PAGE', page);
          this.$emit('getMoreList', { page: this.page });
        }
      }
    }
  };
