import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_06b268c0 from 'nuxt_plugin_plugin_06b268c0' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_pluginclient_2f51d43c from 'nuxt_plugin_pluginclient_2f51d43c' // Source: ./content/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_d5e97478 from 'nuxt_plugin_pluginserver_d5e97478' // Source: ./content/plugin.server.js (mode: 'server')
import nuxt_plugin_axios_14c89854 from 'nuxt_plugin_axios_14c89854' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginserver_1d803bee from 'nuxt_plugin_pluginserver_1d803bee' // Source: ./plugin.server.js (mode: 'server')
import nuxt_plugin_axios_2228ef02 from 'nuxt_plugin_axios_2228ef02' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_mixin_22cb6f0d from 'nuxt_plugin_mixin_22cb6f0d' // Source: ../plugins/mixin (mode: 'client')
import nuxt_plugin_filter_2aab3a6c from 'nuxt_plugin_filter_2aab3a6c' // Source: ../plugins/filter (mode: 'all')
import nuxt_plugin_vuetoast_b78f18a8 from 'nuxt_plugin_vuetoast_b78f18a8' // Source: ../plugins/vue-toast (mode: 'client')
import nuxt_plugin_directives_7e8ad6f8 from 'nuxt_plugin_directives_7e8ad6f8' // Source: ../plugins/directives (mode: 'all')
import nuxt_plugin_loading_71bc50c8 from 'nuxt_plugin_loading_71bc50c8' // Source: ../plugins/loading (mode: 'client')
import nuxt_plugin_router_3f7e063d from 'nuxt_plugin_router_3f7e063d' // Source: ../plugins/router (mode: 'client')
import nuxt_plugin_sensors_5894d8f6 from 'nuxt_plugin_sensors_5894d8f6' // Source: ../plugins/sensors (mode: 'client')
import nuxt_plugin_scrollcache_019573a8 from 'nuxt_plugin_scrollcache_019573a8' // Source: ../plugins/scroll.cache.plugin (mode: 'client')
import nuxt_plugin_vueawesomeswiper_0f4ed586 from 'nuxt_plugin_vueawesomeswiper_0f4ed586' // Source: ../plugins/vue-awesome-swiper (mode: 'client')
import nuxt_plugin_vconsole_0d98d997 from 'nuxt_plugin_vconsole_0d98d997' // Source: ../plugins/vconsole (mode: 'client')
import nuxt_plugin_employeelogin_7c398480 from 'nuxt_plugin_employeelogin_7c398480' // Source: ../plugins/employee-login (mode: 'client')
import nuxt_plugin_employeelogout_0af72353 from 'nuxt_plugin_employeelogout_0af72353' // Source: ../plugins/employee-logout (mode: 'client')
import nuxt_plugin_imglazyload_6a1fdd18 from 'nuxt_plugin_imglazyload_6a1fdd18' // Source: ../plugins/img-lazyload (mode: 'client')
import nuxt_plugin_abtest_21c3c727 from 'nuxt_plugin_abtest_21c3c727' // Source: ../plugins/abtest (mode: 'client')
import nuxt_plugin_shareGuide_0545e351 from 'nuxt_plugin_shareGuide_0545e351' // Source: ../plugins/shareGuide (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"css":false,"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"阿迪达斯官方旗舰店","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0,maximum-scale=1.0, user-scalable=no"},{"name":"x5-fullscreen","content":"true"},{"hid":"description","name":"description","content":""},{"http-equiv":"X-UA-Compatible","content":"IE=edge,chrome=1"},{"name":"apple-mobile-web-app-capable","content":"yes"}],"link":[{"rel":"icon","type":"image\u002Fpng","href":"\u002Ffavicon.png?version=1.0"},{"rel":"stylesheet","href":"\u002Ficons\u002Ficonfont.css"},{"rel":"stylesheet","href":"\u002Fcss\u002Ftailwind.css"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Fapple-touch-icon.png"}],"script":[{"src":"https:\u002F\u002Fstatic-page-sit.adidas.com.cn\u002Fassets\u002Fweixin\u002Fjweixin-1.6.0.js"},{"src":"\u002Fjs\u002FdeleteCookies.js","defer":true,"async":false}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      errPageReady: false,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        nuxt.errPageReady = false
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_06b268c0 === 'function') {
    await nuxt_plugin_plugin_06b268c0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_2f51d43c === 'function') {
    await nuxt_plugin_pluginclient_2f51d43c(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_d5e97478 === 'function') {
    await nuxt_plugin_pluginserver_d5e97478(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_14c89854 === 'function') {
    await nuxt_plugin_axios_14c89854(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_1d803bee === 'function') {
    await nuxt_plugin_pluginserver_1d803bee(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2228ef02 === 'function') {
    await nuxt_plugin_axios_2228ef02(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mixin_22cb6f0d === 'function') {
    await nuxt_plugin_mixin_22cb6f0d(app.context, inject)
  }

  if (typeof nuxt_plugin_filter_2aab3a6c === 'function') {
    await nuxt_plugin_filter_2aab3a6c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetoast_b78f18a8 === 'function') {
    await nuxt_plugin_vuetoast_b78f18a8(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_7e8ad6f8 === 'function') {
    await nuxt_plugin_directives_7e8ad6f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_loading_71bc50c8 === 'function') {
    await nuxt_plugin_loading_71bc50c8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_router_3f7e063d === 'function') {
    await nuxt_plugin_router_3f7e063d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sensors_5894d8f6 === 'function') {
    await nuxt_plugin_sensors_5894d8f6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrollcache_019573a8 === 'function') {
    await nuxt_plugin_scrollcache_019573a8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueawesomeswiper_0f4ed586 === 'function') {
    await nuxt_plugin_vueawesomeswiper_0f4ed586(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vconsole_0d98d997 === 'function') {
    await nuxt_plugin_vconsole_0d98d997(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_employeelogin_7c398480 === 'function') {
    await nuxt_plugin_employeelogin_7c398480(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_employeelogout_0af72353 === 'function') {
    await nuxt_plugin_employeelogout_0af72353(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_imglazyload_6a1fdd18 === 'function') {
    await nuxt_plugin_imglazyload_6a1fdd18(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_abtest_21c3c727 === 'function') {
    await nuxt_plugin_abtest_21c3c727(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_shareGuide_0545e351 === 'function') {
    await nuxt_plugin_shareGuide_0545e351(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
