/*
 * @Author: wang,shuang
 * @Date: 2021-10-07 10:40:28
 * @Description: 搜索历史
 */

const state = () => ({
  // 搜索历史记录
  words: [],
  // 记录 Coupon_View 神策埋点事件的 search_keyword跳转splp的搜索关键字
  splpFlag: "",
  // 跳转带回参数值
  valueFlag: null,
  // 跳转类型
  typeFlag: null,
  // 输入值
  inputValue: null,
  // 跳转的环境状态(searchBar)
  stepFlag:null,
  // 是否有搜索结果
  haveSearchResult: false,
  // 是否挂载(important,默认需要挂载)
  isNeedMounted: true,
  // 来源页面名称
  fromName: null,
  // 搜索时的页面路由
  nowRoute:null
});

const mutations = {
  // 页面挂载时读取cookies初始化搜索历史记录
  initializeWord(state) {
    const words = getCookie('history_words');
    if (words !== '') {
      state.words = words.split(',');
    }
  },

  // 记录用户搜索并存入cookie
  setWords(state, word) {
    const tempArray = [...state.words];
    if (state.words.includes(word)) {
      tempArray.splice(tempArray.indexOf(word), 1);
    }
    tempArray.unshift(word);
    document.cookie = `history_words = ${encodeURI(tempArray.join())}`;
  },

  // 清空搜索记录
  clearHistoryWords(state) {
    state.words = [];
    document.cookie = 'history_words=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  },

  setSplpFlag(state,val) {
    state.splpFlag = val;
  },

  // 设置input的Value值
  setInputValue(state, value) {
    state.inputValue = value;
  },

  // 存储当前的route值
  setNowRoute(state, value) {
    state.nowRoute = value;
  },

  // 保存placeholder
  getValue(state, value) {
    state.valueFlag = value;
  },

  // 设置type类型
  setTypeValue(state, value) {
    state.typeFlag = value;
  },

  // 设置跳转当前环境的(searchBar)
  setStepFlag(state, value) {
    state.stepFlag = value;
  },

  // 初始化当前状态
  init(state){
    // 跳转带回参数值
    state.valueFlag = null;
    // 跳转类型
    state.typeFlag=null;
    // 输入值
    state.inputValue=null;
    // 跳转的环境状态(searchBar)
    state.stepFlag=null;
    state.nowRoute=null;
  },

  // 是否需要挂载
  setIsNeedMounted(state, value) {
    state.isNeedMounted = value;
  },

  setHaveSearchResult(state, value) {
    state.haveSearchResult = value;
  },

  // 设置fromName
  setFromName(state, value) {
    state.fromName = value;
  }
};

/**
 * @description: 从cookie取值
 * @param {*} cname: 要取值的键
 * @return {*} 对应cookie的值
 */
function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) { return decodeURI(c.substring(name.length, c.length)); }
  }
  return '';
}

export { state, mutations };
