
import deepcopy from 'deepcopy';
import { mapState } from 'vuex';
import clpChild ,{changeTabPageData}from '../child.vue';
import clpPlp from '../product-list';
import { handleClickLink } from '../util';
import { isClient } from '~/utilities/helper/SystemHelper';
import { getImgUrl, getUrlQuery } from '~/utilities/helper/urlHelper';
import {BasicUtils} from '~/utilities/basic/utils';
import VerticalTabPage from '~/components/clp/verticalTabPage';
import imageSlideProduct from '~/components/clp/image-slide-product';

export default {
  name: 'TabPage',
  components: {
    clpPlp,
    clpChild,
    VerticalTabPage,
    imageSlideProduct
  },
  props: {
    // 绑定的数据源
    data: {},
    franchise: {
      type: String
    },
    position: {
      type: String,
      default: ''
    },
    clpData: {
      type: Object
    }
  },
  data() {
    return {
      // 当前选中tab索引
      currentTab: 0,
      // 当前选中按钮样式class索引
      currentClass: 0,
      // 当前选中tab的元素距离最左边的距离
      currentTabElOffLeft: 0,
      // 当前选中tab的元素宽度
      currentTabElOffWidth: 0,
      // 是否移动端
      isMobile: true,
      // 绑定的数据源
      dataOptions: deepcopy(this.data),
      style: {},
      // 图片是否加载完成
      isLoad: false,
      isAppleDevice: false,
      active: 0
    };
  },
  computed: {
    ...mapState('clp', [
      "contentCode",
      'plpListMap',
      'articleList'
    ]),
    tabDisplayType() {
      return this.dataOptions?.tabDisplayType || '';
    },
    backgroundStyle() {
      const style={
        backgroundColor:(this.dataOptions?.backgroundColor)?this.dataOptions.backgroundColor:"white",
        maxWidth: "1920px",
        margin: "0 auto",
        backgroundSize: 'cover'
      };

      if(this.isMobile&&this.dataOptions?.mobBackgroundImage){
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.dataOptions.mobBackgroundImage})`;
        style.backgroundColor = "white";
      }else if(!this.isMobile&&this.dataOptions?.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.dataOptions.pcBackgroundImage})`;
        style.backgroundColor = "white";
      }
      return style;
    },
    imgLoad() {
      return this.isLoad && 'fadein';
    },
    // 活跃状态的tab下划线样式计算
    bottomStyles() {
      return {
        transform: `translateX(${this.currentTabElOffLeft + 'px'})`,
        width: this.currentTabElOffWidth + 'px',
        backgroundColor:
          this.data.itemList && this.data.itemList[this.currentTab].textColor
            ? this.data.itemList[this.currentTab].textColor
            : 'black'
      };
    },
    imgUrl() {
      return function (item) {
        return isClient? (window.innerWidth < 720 ? getImgUrl(item.mobImgUrl, 1000) : item.pcImgUrl) : getImgUrl(item.mobImgUrl, 1000);
      };
    },
    handleUrl() {
      return function (item) {
        return 'javascript:void(0);';
      };
    },
    categoryCode() {
      return function (item) {
        const { categoryCode = '' } = getUrlQuery(item.link);
        return categoryCode;
      };
    },
    selectedTabItem() {
      return this.dataOptions.itemList[this.active];
    },
    tabPageStyle(){
      return (item,index)=>{
        // tabDisplayType==="highlight" 情况下 ecp 可能不返回 tabUnselectedTextColor、tabSelectedTextColor
        // tabDisplayType!=="highlight" 情况下 ecp 可能不返回 textColor
        // 这些ecp 看似bug 但是合情合理需求,一定要注意兜住了,别写出bug!!!
        const isHighlight=this?.data?.tabDisplayType==="highlight";
        let color='#ffffff';
        if(item.textColor){
          color = item.textColor;
        }
        if(isHighlight){
          if(this.currentClass === index && item.tabSelectedTextColor){
            color = item.tabSelectedTextColor;
          }else if(this.currentClass !== index && item?.tabUnselectedTextColor){
            color = item?.tabUnselectedTextColor;
          }
        }
        return {
          color
        };
      };
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      this.setImageSize();
    });
  },
  mounted() {
    this.setImageSize();
    this.bottomStylesResize();
    window.addEventListener('resize', this.bottomStylesResize);
    this.$nextTick(() => {
      this.getSelectElement();
      this.computeSize();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.bottomStylesResize);
  },
  methods: {
    decorateProductList(item) {
      return {
        ...item,
        maintainType: this.data.maintainType
      };
    },
    decorateKvSlider(item) {
      // item.imageSlideItemList.forEach((vi) => {
      //   vi.articleListData = [];
      //   vi.products = [];
      //   const dLink = vi.categoryCode ? `/plp?categoryCode=${vi.categoryCode}` : `/plp?keyword=${vi.keyword}`;
      //   if (vi.maintainType === 'article') {
      //     const articleDetailList = this.articleList.filter((article) => {
      //       return vi.articleList.includes(article.articleId);
      //     });

      //     if (articleDetailList.length)
      //       vi.articleListData.push(...articleDetailList);
      //   } else {
      //     const articleDetailObj = this.plpListMap.find((plp) => {
      //       return dLink === plp.link;
      //     });
      //     if (articleDetailObj)
      //       vi.products.push(...articleDetailObj.content);
      //   }
      //   vi.link = vi.imageRedirectLink;
      // });
      const options = {
        itemList: item.imageSlideItemList,
        labelInfoList: this.data.labelInfoList
      };
      item.options = options;
      return item;
    },
    decorateMiniCatalog(item) {
      // item.verticalItemList.forEach((vi) => {
      //   vi.articleDetailList = [];
      //   const dLink = vi.categoryCode ? `/plp?categoryCode=${vi.categoryCode}` : `/plp?keyword=${vi.keyword}`;
      //   if (vi.maintainType === 'article') {
      //     const articleDetailList = this.articleList.filter((article) => {
      //       return vi.articleList.includes(article.articleId);
      //     });
      //     if (articleDetailList.length)
      //       vi.articleDetailList.push(...articleDetailList);
      //   } else {
      //     const articleDetailObj = this.plpListMap.find((plp) => {
      //       return dLink === plp.link;
      //     });
      //     if (articleDetailObj)
      //       vi.articleDetailList.push(...articleDetailObj.content);
      //   }
      //   vi.link = vi.tabRedirectLink;
      // });
      const options = {
        contentBackgroundColor: item.contentBackgroundColor,
        contentMobBackgroundImage: item.contentMobBackgroundImage,
        contentPcBackgroundImage: item.contentPcBackgroundImage,
        itemList: item.verticalItemList,
        labelInfoList: this.data.labelInfoList

      };
      item.options = options;
      return item;
    },
    itemStyle(item, index) {
      item.tabSelectedBgColor = item.tabSelectedBgColor || 'black';
      return this.currentClass === index ? `backgroundColor: ${item.tabSelectedBgColor}` : `backgroundColor: ${item.tabUnselectedBgColor}`;
    },
    onLoad() {
      this.isLoad = true;
    },
    goLink(item) {
      this.franchise ? item.franchise = this.franchise : '';
      handleClickLink.call(this, item);
    },
    // 计算当前图片样式： 大屏直接展示原始高度，小于1440则按公式计算图片高度： 图片容器高 = 图片容器宽 / 上传图片的宽高比
    computeSize() {
      this.dataOptions.itemList?.forEach((item, index) => {
        item.style = item.size && item.size[0] && item.size[1] &&
          this.isMobile && {
            height: `calc(100vw / (${item.size[0] / item.size[1]})) !important`
          };
      });
      this.dataOptions = { ...this.dataOptions };
    },
    // 切换tab页
    async toggleTab(item, currentClass) {
      // let renderSide = 'server';
      // if (this.$route.query?.preview === '1') {
      //   renderSide = 'client';
      // }

      // 当点击的tab不是第一个需要组件内去每次请求数据，第一个是clp汇总页面查询
      // if (currentClass > 0 && !item.clpData) {
        // if (
        //   this.data.content === 'product_list' &&
        //   this.data.maintainType === 'PLP' &&
        //   !this.dataOptions.itemList[currentClass].products
        // ) {
        //   const _data = await this.searchPlp(
        //     {
        //       ...this.dataOptions.itemList[currentClass]
        //     },
        //     renderSide
        //   );
        //   const currentOptions = deepcopy(this.dataOptions);
        //   currentOptions.itemList.forEach((item, index) => {
        //     if (index === currentClass) {
        //       item.products = _data.content;
        //     }
        //   });
        //   this.dataOptions = currentOptions;
        // } else if (
        //   this.data.content === 'product_list' &&
        //   this.data.maintainType === 'article' &&
        //   !this.dataOptions.itemList[currentClass].products
        // ) {
        //   const _data = await this.searchArticleList(
        //     {
        //       articleIdList: this.dataOptions.itemList[currentClass].articleList
        //     },
        //     renderSide
        //   );
        //   const currentOptions = deepcopy(this.dataOptions);
        //   currentOptions.itemList.forEach((item, index) => {
        //     if (index === currentClass) {
        //       item.products = _data;
        //     }
        //   });
        //   this.dataOptions = currentOptions;
        // }else
        if (this.data.content==='clp'){

          if (!item.clpData) {
            
            this.$aloading.show();
            const currentOptions = deepcopy(this.dataOptions);
            const curItem = currentOptions.itemList && currentOptions.itemList[currentClass];
            const clpCode=parseInt(curItem.clpFileName);
            const data= await changeTabPageData(this,clpCode, 'server', false, null, curItem.tabContentCode, this.clpData?.data?.contentCode || this.contentCode);
            this.$aloading.hide();

            const tempItem={
              contentId:clpCode.toString(),
              hasState:false,
              data:data.data,
              componentsData:data.componentsData,
              feedProductList:data.feedProductList
            };
            currentOptions.itemList[currentClass].clpData = tempItem;
            this.dataOptions = deepcopy(currentOptions);
          }
          this.active = currentClass;
        // }
      }
      if(this.$refs.tabContent&&this.$refs.tabContent.offsetHeight){
          const tabContentElement = this.$refs.tabContent;
          const tabContentHeight = tabContentElement.offsetHeight;
          tabContentElement.style.minHeight = `${tabContentHeight}px`;
        }
      setTimeout(
        () => {
          // 选中tab内容块展示
          this.currentTab = currentClass;
          // 选中tab样式
          this.currentClass = currentClass;
          this.$nextTick(()=>{
            if(this.$refs.tabContent&&this.$refs.tabContent.offsetHeight){
              const tabContentElement = this.$refs.tabContent;
              tabContentElement.style.minHeight = 'auto';
            }
          });
          this.getSelectElement();
        },
        // 这里当还没点击也就是没有查询没有数据的时候，给100ms延迟，避免界面跳动
        this.dataOptions.itemList[currentClass].products ||
          this.data.content === 'image'
          ? 0
          : 100
      );
      this.$emit('trackClpView', this.dataOptions.itemList[currentClass].tabText);
    },
    async searchPlp(options, renderSide) {
      try {
        const utils=new BasicUtils(this);
        const { keyword, categoryCode } = getUrlQuery(options.link);
        const params = {
          page: 1,
          abTest: 'A',
          pageSize: options.pageSize,
          categoryCode,
          keyword: decodeURI(keyword)
        };
        if (!keyword && keyword !== '0') {
          delete params.keyword;
        }
        if (categoryCode === '/search') {
          delete params.categoryCode;
        }
        const data = await this.$axios.get(
          `${this.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/search`,
          {
            params: { ...params, preview: renderSide === 'client' ? 0 : null },
            ...utils.config
          }
        );
        if (!data.content) data.content = [];
        return data;
      } catch (e) {
        if (e.data?.failed) {
          return {
            content: [{}, {}, {}, {}]
          };
        }
      }
    },

    /**
     * @Description: article批量搜索查询商品接口
     * @Author: 李博
     * @Date: 2022/1/7 2:49 下午
     * @param params 请求参数
     * @param renderSide 当前渲染模式：客户端或者服务端
     */
    async searchArticleList(params, renderSide) {
      if (!params.articleIdList) return [];
      try {
        const utils=new BasicUtils(this);
        return await this.$axios.get(
          `${this.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/batch-search`,
          {
            params: { ...params, preview: renderSide === 'client' ? 0 : null },
            ...utils.config
          }
        );
      } catch (e) {
        if (e.data?.failed) {
          return undefined;
        }
      }
    },
    // 设置当前选中元素的下划线位置
    getSelectElement() {
      try{
        const selector = document.querySelector(`.tab-button-list-${this.position}`)?.querySelector(`.tab-${this.currentTab}`);
        this.currentTabElOffLeft = selector?.offsetLeft;
        this.currentTabElOffWidth = selector?.offsetWidth;
      }catch(e){
        console.log('当前选中元素的下划线位置报错', e);
      }
    },
    // 选中样式计算
    spanClasses(index) {
      const classes = [`tab-${index}`];
      if (this.currentClass === index) {
        classes.push('button-chosen');
      }
      return classes;
    },
    spanRef(index) {
      const ref = [`tab-${index}`];
      return ref;
    },

    contentRef(index) {
      const ref = `content-${index}`;
      return ref;
    },
    // 设置图片原始size
    setImageSize() {
      this.dataOptions.itemList?.forEach((item, index) => {
        const ref = `content-${index}`;
        const itemImage = this.$refs[ref] && this.$refs[ref][0];
        item.size =
          itemImage &&
          this.getImgNaturalDimensions(itemImage, index, this.getIeWidth);
      });
    },

    // 设置图片尺寸
    getIeWidth(index, width, height) {
      this.dataOptions.itemList[index].size = [width, height];
      this.computeSize();
    },

    /**
     * @Description: 获取图片原始大小
     * @Author: 李博
     * @Date: 2022/1/7 2:33 下午
     * @param {Object} img 图片对象
     * @param {Number} index 索引
     * @param {function} callback 回调函数，这里就是调用getIeWidth设置图片大小
     */
    getImgNaturalDimensions(img, index, callback) {
      let nWidth, nHeight;
      if (img && !!img.naturalWidth) {
        //  现代浏览器
        nWidth = img.naturalWidth;
        nHeight = img.naturalHeight;
        callback(index, nWidth, nHeight);
      } else {
        // IE6/7/8
        const image = new Image();
        image.src = img.src;
        image.onload = function () {
          callback(index, image.width, image.height);
        };
      }
      return [nWidth, nHeight];
    },
    // 当浏览器窗口大小变化，重新计算样式
    bottomStylesResize() {
      this.isMobile = window.innerWidth < 720;
      const u = navigator.userAgent.toLowerCase();
      this.isAppleDevice = !!u.match(/macintosh|mac os x/i);
      this.$nextTick(() => {
        this.getSelectElement();
        this.computeSize();
      });
    },
    // 暴露给父组件调用获取ProductList
    getProductList(){
      if(this.$refs.clpChild){
        let productList;
        const clpChild= (this.$refs.clpChild.length)?this.$refs.clpChild:[this.$refs.clpChild];
        for(const clp of clpChild){
          if(clp.tabIndex===this.currentTab){
            const products=clp.getProductList();
            if(products){
              productList=productList||[];
              productList.push(...products);
            }
          }
        }
        if(productList){
          for(const product of productList){
            product.tab_name=this.dataOptions.itemList[this.currentTab].tabText;
          }
          return productList;
        }

      }
    }
  }
};
