
import { mapState } from 'vuex';
import { isClient } from '../../../utilities';
import { handleClickLink, decorateSurveyAllLink } from '../util';
import { Pag } from '~/utilities/pag';
import {BasicUtils} from '~/utilities/basic/utils';

let Hammer = null;
if (isClient) {
  Hammer = require('~/static/js/hammer.min.js');
}
export default {
  name: 'ShuffleItem',
  props: {
    datas: {},
    code: {
      type: String,
      default: ''
    },
    showText: {
      type: Boolean
    },
    resultImg: {
      type: String
    },
    repeatFillingFlag: {
      type: Boolean
    },
    surveyDetail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    for (const item of this.datas) {
      if (item.productList) {
        item.productList = item.productList.filter(
          (item) => item && item.articleId
        );
      }
    }
    return {
      optionList: [...this.datas],
      pag: null,
      showLikeAnimation: false,
      /* 防止点击穿透后面的卡片 */
      lock: true,
      moving: false,
      isVivo: false,
      isAndroidChrome: false,
      isQQBrowser: false,
      loading: true,
      utils: null
    };
  },
  computed: {
    ...mapState('myAccount', [
      'isEmployeeLogin',
      'isNonmemberLogin'
    ]),
    isShowCount() {
      return (
        this.optionList.filter((item) => item.removed).length !==
        this.optionList.length - 1
      );
    },
    numberShow() {
      const finalList = this.optionList.filter(
        (item) => !item.questionImage && !item.resultPage
      );
      const length = finalList.length;
      const isQuestionImage = this.optionList.filter(
        (item) => item.questionImage && !item.removed
      ).length;
      const index =
        finalList.filter((item) => item.removed).length !== length
          ? finalList.filter((item) => item.removed).length + 1
          : length;
      return `${isQuestionImage ? 0 : index}/${length}`;
    },
    cardsStyle() {
      return {
        height: this.showText ? '455px' : '401px'
      };
    },
    tinderClass() {
      return `tinder-${this.code}`;
    },
    tinderCardClass() {
      return `tinder-card-${this.code}`;
    },
    exceptResultCardClass() {
      return `tinder-card-${this.code}`;
    },
    productListClass() {
      return (item) => {
        let finalClass = '';
        if (!item.optionImageUrl) {
          finalClass = `product-list-${item.productList?.length}`;
        }
        return finalClass;
      };
    },
    newCardsClass() {
      return `tinder-card-${this.code}:not(.removed)`;
    },
    imgContainerClass() {
      return (product) => {
        return `img-container-${product.productList?.length || 1}`;
      };
    },
    imgClass() {
      return (index) => {
        return `img-shadow-${index + 1}`;
      };
    },
    nopeId() {
      return (index) => `nope-${this.code}${index || 0}`;
    },
    loveId() {
      return (index) => `love-${this.code}${index || 0}`;
    }
  },
  watch: {
    datas: {
      handler(val) {
        if (val) {
          this.optionList = val;
          if (val &&
            val.filter((item) => item.removed).length === 0) {
            this.initCards(true);
          } else {
            this.refreshCard();
          }
        }
      },
      // immediate: true,
      deep: true
    }
  },
  async mounted() {
    this.utils = new BasicUtils(this);
    const u = navigator.userAgent.toLowerCase();
    this.isVivo = !!u.match(/vivobrowser/i);
    this.isAndroidChrome = !!u.includes('android') && (!!u.includes('chrome/78.0.3904.96') || !!u.includes('chrome/81'));
    this.isQQBrowser = !!u.match(/qqbrowser/i);
    this.pag = new Pag(
      this,
      `${this.$config.S3_COM_HOST}/assets/pag/survery-center-smile-122.pag`,
      this.$refs.likeAnimation
    );
    await this.pag.initAsync();
    this.loading = false;
    setTimeout(() => {
      this.loadLastReply();
      this.initCards();
      this.addListen();
    }, 0);
  },
  methods: {
    refreshCard() {
      this.loading = false;
      setTimeout(() => {
        this.loadLastReply();
        this.initCards();
        this.addListen();
      }, 0);
    },
    loadLastReply() {
      const allCards = this.$refs.box.querySelectorAll(
        `.${this.tinderCardClass}`
      );
      const moveOutWidth = document.body.clientWidth * 2;

      allCards.forEach((card) => {
        const optionSelected = this.optionList.find((item) => {
          return item.optionCode === card.dataset.option;
        });
        if (optionSelected.removed) {
          card.classList.add('removed');
          if (optionSelected.liked) {
            card.style.transform =
              'translate(' + moveOutWidth + 'px, -100px) rotate(30deg)';
          } else {
            card.style.transform =
              'translate(-' + moveOutWidth + 'px, -100px) rotate(-30deg)';
          }
        }
      });
    },
    resetSurvey() {
      this.$emit('reset');
    },
    goPdp(articleId) {
      if((this.utils.isMemberLogin() && this.surveyDetail.replyMemberFlag) || !this.surveyDetail.replyMemberFlag) {
        this.$emit('trackPdpClick', articleId);
        const item = {
          link: `/pdp?articleId=${articleId}`
        };
        this.goLink(item);
      } else {
        this.handleMemberLogin();
      }
    },
    goLink(item) {
      if((this.utils.isMemberLogin() && this.surveyDetail.replyMemberFlag) || !this.surveyDetail.replyMemberFlag) {
        console.log('配置图片加link', item);
        item.allLink = decorateSurveyAllLink(item.link);
        handleClickLink.call(this, item);
      } else {
        this.handleMemberLogin();
      }
    },
    clickProduct(code, id, selected) {
      if((this.utils.isMemberLogin() && this.surveyDetail.replyMemberFlag) || !this.surveyDetail.replyMemberFlag) {
        this.$emit('view', { code, id, selected });
      } else {
        this.handleMemberLogin();
      }
    },
    clickPhoto(item) {
      if((this.utils.isMemberLogin() && this.surveyDetail.replyMemberFlag) || !this.surveyDetail.replyMemberFlag) {
        if (this.lock) {
          this.$emit('viewLink', item);
        }
      } else {
        this.handleMemberLogin();
      }
    },
    initCards(refresh) {
      if (this.moving) {
        return;
      }
      if (!this.$refs.box) {
        return;
      }
      const tinderContainer = this.$refs.box.querySelector(
        `.${this.tinderClass}`
      );
      const newCards = this.$refs.box.querySelectorAll(
        `.${this.newCardsClass}`
      );
      const allCards = this.$refs.box.querySelectorAll(
        `.${this.tinderCardClass}`
      );

      (refresh ? allCards : newCards).forEach((card, index) => {
        // 这里只显示前三张，后面的先隐藏
        if (index > 2) {
          card.style.opacity = 0;
          card.style.zIndex = -1;
          card.style.transform = 'translateY(0px)';
        } else {
          card.style.zIndex = allCards.length - index;
          let translateY = 0;
          const more = (20 - index) / 20;
          if (window.innerWidth < 375) {
            translateY =
              (window.innerWidth - 40 + 40 + 54) * (1 - more) * 0.5 +
              8 * index +
              (index === 2 ? 2 : 0);
          } else {
            translateY =
              (375 - 40 + 40 + 54) * (1 - more) * 0.5 +
              8 * index +
              (index === 2 ? 2 : 0);
          }
          card.style.transform =
            'scale(' + more + ') translateY(-' + translateY + 'px)';
          card.style.opacity = 1;
          if (index === 0) {
            card.firstChild.style.background = 'transparent';
          }
        }
        refresh && card.classList.remove('removed');
      });

      tinderContainer?.classList.add('loaded');
    },
    async handleMemberLogin(){
      const utils = new BasicUtils(this);
      if (utils.isMemberLogin()) {
        // this.$Toast({message: "您已登录为会员"});
        // this.handleData(item);
      } else if (utils.getEmployeeId()) {
        this.$Toast({message: "抱歉，员工无法参与"});
      } else {
        const utils=new BasicUtils(this);
        if ((utils.IsAPP && window.AdidasApp) || utils.IsWMS || utils.IsOR || utils.IsAdiclub) {
          this.$emit('callMemberGuide');
        } else {
          const firstLogin = await utils.loginByEnvAsync({
            redirectUrl: location.href,
            memberOnly: true,
            loginClp: true,
            title: window.innerWidth < 720 ? '会员专属内容' : '登录即可解锁互动玩法',
            desc: window.innerWidth < 720 ? '加入会员即可解锁趣味玩法/专属活动等精彩内容' : ''
          });
          if (firstLogin) {
            this.$emit('refreshLastResult');
          }
        }
      }
    },
    beforeBtnListener(love, item) {
      if(this.surveyDetail.replyMemberFlag) {
        this.utils.isMemberLogin() ? this.createButtonListener(love, item) : this.handleMemberLogin();
      } else {
        this.createButtonListener(love, item);
      }
    },
    async createButtonListener(love, item) {
      const _this = this;
      const cards = this.$refs.box.querySelectorAll(`.${this.newCardsClass}`);
      const moveOutWidth = document.body.clientWidth * 2;

      if (!cards.length) return false;

      const card = cards[0];
      const tinderContainer = this.$refs.box.querySelector(
        `.${this.tinderClass}`
      );
      // this.optionList = [];
      tinderContainer.classList.toggle('tinder_love', love);
      tinderContainer.classList.toggle('tinder_nope', !love);

      // 表情放大动画
      setTimeout(() => {
        tinderContainer.classList.add('tinder_removed');
        setTimeout(() => {
          tinderContainer.classList.remove('tinder_love');
          tinderContainer.classList.remove('tinder_nope');
        }, 100);
      }, 500);

      setTimeout(() => {
        tinderContainer.classList.remove('tinder_removed');
      }, 800);

      card.classList.add('removed');
      if (love) {
        card.style.transform =
          'translate(' + moveOutWidth + 'px, -100px) rotate(30deg)';
      } else {
        card.style.transform =
          'translate(-' + moveOutWidth + 'px, -100px) rotate(-30deg)';
      }
      this.$emit(love ? 'like' : 'next', true);
      setTimeout(() => {
        _this.initCards();
      }, 400);
      // 气泡动画
      if (love) {
        this.showLikeAnimation = true;
        if (this.pag) {
          await this.pag.play();
          await this.pag.playtoEnd();
        }
        this.showLikeAnimation = false;
      }
    },
    addListen() {
      const tinderContainer = this.$refs.box.querySelector(
        `.${this.tinderClass}`
      );
      const allCards = this.$refs.box.querySelectorAll(
        `.${this.exceptResultCardClass}`
      );
      const moveEnd = 100;
      const moveStart = 50;
      // 寻找控制滑动的元素
      let layout = null;
      const urlPara = new URL(location.href);
      if (urlPara.pathname.toLowerCase() === '/clp') {
        layout = this.$refs.box;
        for (let i = 0; i < 20; i++) {
          //  寻找layout-wrapper元素
          if (layout.id === 'layout-wrapper') {
            break;
          } else {
            layout = layout.parentElement;
          }
        }
        if (layout === this.$refs.box) {
          layout = null;
        }
        const elem = this.$refs.box;
        let startY = 0;
        let startX = 0;
        let scrollTop = 0;
        elem.addEventListener('touchstart', function (e) {
          startY = e.touches[0].clientY;
          startX = e.touches[0].clientX;
          scrollTop = layout ? layout.scrollTop : 0;
        });
        elem.addEventListener(
          'touchmove',
          function (e) {
            const currentY = e.touches[0].clientY - startY;
            const currentX = e.touches[0].clientX - startX;
            if (Math.abs(currentY) > 50 && Math.abs(currentX) < moveStart ) {
              if (layout) {
                const toScrollTop = scrollTop + currentY * -1;
                layout.scrollTop = toScrollTop;
              }
            }
            e.preventDefault();
          },
          { passive: false }
        );
      }
      // 寻找控制滑动的元素 End
      const _this = this;
      allCards.forEach((el) => {
        const nope = el.lastChild.firstChild;
        const love = el.lastChild.lastChild;
        const hammertime = new Hammer(el, {
          touchAction: 'pan-y'
        });
        hammertime.get('pan').set({ direction: Hammer.DIRECTION_ALL });
        const canNextPage = (event) => {
          return Math.abs(event.deltaX) >= moveEnd && event.center.x !== 0;
        };
        const isFirst = (el) => {
          const allCards = this.$refs.box.querySelectorAll(
            `.${this.exceptResultCardClass}:not(.result-card):not(.removed)`
          );
          return allCards.length >= 0 && allCards[0] === el;
        };

        const panend = async (event) => {
          if ((this.utils.isMemberLogin() && this.surveyDetail.replyMemberFlag) || !this.surveyDetail.replyMemberFlag) {
            if (!isFirst(el)) {
              return;
            }
            el.classList.remove('moving');
            this.moving = false;
            const clientWidth = document.body.clientWidth;
            const keep = !canNextPage(event);
            el.classList.toggle('removed', !keep);
            if (keep) {
              el.style.transform = '';
            } else {
              tinderContainer.classList.add('tinder_removed');
              setTimeout(() => {
                tinderContainer.classList.remove('tinder_removed');
              }, 300);
              const i = event.deltaX > 0 ? 1 : -1;
              const toX = 450 * i;
              const deltaX = Math.abs(event.deltaX);
              const xMulti =
                (i * (deltaX > moveEnd ? moveEnd : deltaX)) / moveEnd;
              const yMulti = 5;
              const rotate = xMulti * yMulti;
              el.style.transform =
                'translate(' + toX + 'px, ' + 0 + 'px) rotate(' + rotate + 'deg)';
              if (clientWidth > 720) {
                el.style.opacity = 0;
              }
              _this.$emit(event.deltaX > 0 ? 'like' : 'next');
              _this.initCards();
              // isEnd=true;
            }
            setTimeout(() => {
              tinderContainer.classList.remove('tinder_love');
              tinderContainer.classList.remove('tinder_nope');
            }, 100);
            setTimeout(() => {
              love.style.width = `50%`;
              love.style.paddingRight = '';
              love.children[0].style.opacity = 1;
              love.children[1].style.opacity = 1;
              nope.style.width = `50%`;
              nope.style.paddingLeft = '';
              nope.children[0].style.opacity = 1;
              nope.children[1].style.opacity = 1;
              _this.lock = true;
            }, 300);
            if (this.pag && event.deltaX > 0 && !keep) {
              this.showLikeAnimation = true;
              await this.pag.play();
              console.log('play');
              await this.pag.playtoEnd();
              console.log('playtoEnd');
              this.showLikeAnimation = false;
            }
          } else {
            this.handleMemberLogin();
          }
        };

        hammertime.on('pan', (event) => {
          if ((this.utils.isMemberLogin() && this.surveyDetail.replyMemberFlag) || !this.surveyDetail.replyMemberFlag) {
            if (!isFirst(el)) {
              return;
            }

            // 防止默认的滚动行为
            _this.lock = false;
            this.moving = true;
            el.classList.add('moving');
            if (event.center.x === 0 && event.deltaX !== 0) {
              panend(event);
              return;
            }
            const i = event.deltaX > 0 ? 1 : -1;
            let deltaX = Math.abs(event.deltaX);
            if (deltaX < moveStart) return;
            if(deltaX<Math.abs(event.deltaY)){
              return;
            }

            deltaX = deltaX - moveStart;
            if (event.center.x === 0 && event.center.y === 0) return;
            tinderContainer.classList.toggle('tinder_love', i > 0);
            tinderContainer.classList.toggle('tinder_nope', i < 0);
            const xMulti = (i * (deltaX > moveEnd ? moveEnd : deltaX)) / moveEnd;
            const widthPercent = 50 + 0.5 * Math.abs(100 * xMulti);
            const yMulti = 5;

            const rotate = xMulti * yMulti;
            if (deltaX > moveEnd) {
              deltaX = moveEnd + (deltaX - moveEnd) * (moveEnd / deltaX);
            }
            deltaX = deltaX * i;
            if (i > 0) {
              love.style.width = `${widthPercent}%`;
              nope.style.width = `${100 - widthPercent}%`;
              love.style.paddingRight = `calc(${0.5 * 100 * xMulti}%)`;
              nope.children[0].style.opacity = 1 - Math.abs(xMulti);
              nope.children[1].style.opacity = 1 - Math.abs(xMulti);
            } else if (i < 0) {
              nope.style.width = `${widthPercent}%`;
              love.style.width = `${100 - widthPercent}%`;
              nope.style.paddingLeft = `calc(${100 * xMulti * -0.5}%)`;
              love.children[0].style.opacity = 1 - Math.abs(xMulti);
              love.children[1].style.opacity = 1 - Math.abs(xMulti);
          }
            el.style.transform =
              'translate(' +
              deltaX +
              'px, ' +
              0 +
              'px) rotate(' +
              rotate +
              'deg)';
            _this.$emit('view', {}, true);
            _this.$emit('viewLink', {}, true);
          } else {
            this.handleMemberLogin();
          }
        });

        hammertime.on('panend', panend);
      });

      // const nopeListener = this.createButtonListener(false);
      // const loveListener = this.createButtonListener(true);
      //
      // nope.addEventListener('click', nopeListener);
      // love.addEventListener('click', loveListener);
    }
  }
};
