
import NotFoundError from '../../components/404-error/404Error';
import Footer from '../../components/footer';
export default {
  components:{ Footer, NotFoundError}
  // head(){
  //   return {meta:[
  //     {name:'apple-mobile-web-app-capable',content: 'yes'},
  //     {name:'viewport',content: 'width=device-width,initial-scale=1,maximum-scale=1.0,user-scalable=no,minimum-scale=0.5'}
  //   ],
  //   };
  // },
};
