const state = () => ({
  firstLoad: true
});

const mutations = {
  SET_FIRST_LOAD(state, status) {
    state.firstLoad = status;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
