/*
 * @Description:
 * @Author: ZhangHui
 * @Date: 2021-12-02 15:54:50
 */
export function installImgLoadDirective(Vue) {
  console.log('warn:plugins installImgLoadDirective');
  Vue.directive('imgLoad', {
    bind(el, { value }) {
      console.log('warn:plugins installImgLoadDirective bind');
      const cb = value ? value.cb : () => {};
      el.classList.add('opacity-0');
      el.handleLoad = () => {
        el.classList.add('fadein');
        cb();

        // 删除父元素replay属性
        setTimeout(()=>{
          if(el.parentNode && el.parentNode.classList.contains('replay')) {
            el.parentNode.classList.remove('replay');
          }
        }, 300);

      };

      el.addEventListener('load', el.handleLoad);
    },

    unbind(el) {
      console.log('warn:plugins installImgLoadDirective unbind');
      el.removeEventListener('load', el.handleLoad);
    }
  });
}
