import { BasicUtils, IUtils as IBasicUtils } from '~/utilities/basic/utils';
interface iConfig{
  [key: string]:{
    "points": number,
    "startTime": string,
    "enable": 0|1
  }[]
}
export class Utils extends BasicUtils implements IBasicUtils{
  async getConfig(type: string):Promise<number>{
    const response=await fetch(this.S3_COM_HOST + "/static/pointsConfig.json");
    const data: iConfig=await response.json();
    let min=0;
    let points=-1;
    if(type in data){
      const config=data[type];
      const now= new Date();
      for(const item of config){
        const startTime=new Date(item.startTime);
        if(now.getTime()>=startTime.getTime()){
          if(min===0||(now.getTime() - startTime.getTime())<min){
            // eslint-disable-next-line no-extra-boolean-cast
            if(!!item.enable){
              points=(item.points)?item.points:0;
            }else{
              points=-1;
            }
            min=(now.getTime() - startTime.getTime());
          }
        }
      }
    }
    return points;
  };
}

