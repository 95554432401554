const middleware = {}

middleware['brafinderRedirect'] = require('../middleware/brafinderRedirect.js')
middleware['brafinderRedirect'] = middleware['brafinderRedirect'].default || middleware['brafinderRedirect']

middleware['loopRedirect'] = require('../middleware/loopRedirect.js')
middleware['loopRedirect'] = middleware['loopRedirect'].default || middleware['loopRedirect']

middleware['pathTransform'] = require('../middleware/pathTransform.js')
middleware['pathTransform'] = middleware['pathTransform'].default || middleware['pathTransform']

export default middleware
