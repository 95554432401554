/*
 * @Description: app 下载 工具类
 * @Autor: Duan,JingLiang
 * @Date: 2022-05-04 19:00:22
 */
import {
  bosIosLink,
  bosAndroidLink,
  confirmedIosLink,
  confirmedAndroidLink,
} from './downloadLink';
import {
  isAndroidSystem,
  isIosSystem,
} from './SystemHelper';

export const DownloadTokenUtils = {
  bosDownload: () => {
    if (isAndroidSystem) {
      DownloadTokenUtils.handleBosAndroidDownload();
      return;
    }
    if (isIosSystem) {
      DownloadTokenUtils.handleBosIosDownload();
    }
  },
  confirmedDownload: () => {
    if (isAndroidSystem) {
      DownloadTokenUtils.handleConfirmedAndroidDownload();
      return;
    }
    if (isIosSystem) {
      DownloadTokenUtils.handleConfirmedIosDownload();
    }
  },
  handleBosIosDownload: () => {
    location.href = bosIosLink;
  },
  handleBosAndroidDownload: () => {
    location.href = bosAndroidLink;
  },
  handleConfirmedIosDownload: () => {
    location.href = confirmedIosLink;
  },
  handleConfirmedAndroidDownload: () => {
    location.href = confirmedAndroidLink;
  },
};
