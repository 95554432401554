
export default {
  name: 'LibNoMore',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
};
