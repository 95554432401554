/*
 * @Author: your name
 * @Date: 2022-04-21 14:49:54
 * @LastEditTime: 2022-04-21 16:31:31
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /adidas-com-front/packages/ui-library/src/components/organisms/LibShareGuide/LibShareGuide.service.tsx
 */
import Vue from 'vue';
import LibShareGuide from './LibShareGuide.vue';

export default {
  install(vue: any) {
    const UseShareGuide = Vue.extend(LibShareGuide);
    const Profile: any = new UseShareGuide({
      el: document.createElement('div'),
    });
    document.body.appendChild(Profile.$el);
    const shareGuideMethod = {
      show() {
        Profile.show = true;
      },
      hide() {
        Profile.show = false;
      },
    };
    vue.prototype.$shareGuide = shareGuideMethod;
  },
};
