
import throttle from 'lodash.throttle';
import NavigationBar from './NavigationBar.vue';
import CatalogPc from '~/components/catalog/catalog-pc';
import { detectOS } from '~/utilities';
import { BasicUtils } from '~/utilities/basic/utils';

export default {
  name: 'Navigation',
  components: {
    NavigationBar,
    CatalogPc
  },
  props: {
    size: {
      type: String
    }
  },
  data() {
    const utils=new BasicUtils(this.$nuxt.context);
    return {
      activeTab: null,
      visible: false,
      item: [],
      showHeader: true,
      defaultFirst: false,
      isLoad:!(utils.IsWMS||utils.IsAPP)
    };
  },
  computed: {
    needHeaderAnimation() {
      const includePath = ['/', '/cart', '/my-account'];
      const currentPath = this.$route.path;
      let needs = false;
      includePath.forEach((item) => {
        if (
          currentPath === '/' ||
          currentPath.startsWith(`${item}/`) ||
          currentPath === item
        ) {
          needs = true;
        }
      });
      return needs;
    }
  },
  watch: {
    visible(val) {
      if (val === false) {
        this.activeTab = null;
      }
      if (val) {
        document.getElementById('layout-wrapper').style.height = '100%';
        document.getElementById('layout-wrapper').style.overflow = 'hidden';
        const obj = document.getElementById('layout-wrapper');
        if (
          obj.scrollHeight > obj.clientHeight ||
          obj.offsetHeight > obj.clientHeight
        ) {
          document.getElementById('__layout').style.overflowY = 'scroll';
        }
      } else {
        document.getElementById('layout-wrapper').style.height = '%';
        document.getElementById('layout-wrapper').style.overflow = '';
        document.getElementById('__layout').style.overflowY = '';
      }
    }
  },
  mounted() {
    this.$watch(
      () => this.$route.fullPath,
      () => {
        this.visible=false;
      }
    );
    ['Tablet', 'Mobile'].includes(detectOS())
      ? window.addEventListener('touchstart', this.handleTouch, true)
      : "";
    const onScroll = (() => {
      return () => {
        const { scrollTop } = this.$utils.device.getScrollTop();
        const startY = scrollTop;
        if (startY >= this.scrollendY) {
          this.showHeader = false;
          this.scrollendY = startY;
        } else {
          this.showHeader = true;
          this.scrollendY = startY;
        }
        if (scrollTop < 2) {
          this.showHeader = true;
        }
      };
    })();
    this.$on(
      'hook:beforeDestroy',
      throttle(this.$utils.device.onPageScroll(onScroll), 300)
    );
  },
  methods: {
    handleHover(item, activeTab) {
      this.activeTab = activeTab;
      // 点击事件切换显隐, hover事件必为visible: true
      this.visible = true;
      this.item = item;
      this.defaultFirst = false;
      console.log('hoverItem', item);
    },

    // 点击除catalog及导航栏item区域关闭overlay
    // handleClick(e) {
    //   let flag = true;
    //   e.path.forEach((i) => {
    //     if (i.className) {
    //       if (
    //         i.className.includes('catelogs') ||
    //         i.className.includes('catalog-container')
    //       ) {
    //         flag = false;
    //       }
    //     }
    //   });

    //   flag && (this.visible = false);
    // },

    handleTouch(e) {
      if (e.target.className === 'lib-mask') return;
      let flag = true;
      let p = e.target;
      while (p && p.parentNode) {
        if (
          p.className.includes('catalog-container') ||
          p.className.includes('catalogs')
        ) {
          flag = false;
        }
        p = p.parentNode;
      }
      // console.log('handleTouch');
      flag && (this.visible = false);
    },

    handleMouseLeave(e) {
      const event = e.toElement || e.relatedTarget;
      if (
        event &&
        event.parentNode &&
        !event.parentNode.className.includes('catalogs') &&
        !event.parentNode.className.includes('hotArea')
      ) {
        this.visible = false;
      }
    },
    levelNavigation(e) {
      let p = e.toElement || e.relatedTarget;
      if (detectOS() !== 'PC' || !p) return;
      while (p.parentNode && p?.className !== 'catalog-bar') {
        p = p.parentNode;
      }
      if (p && p.className !== 'catalog-bar' && !this.defaultFirst) {
        // console.log('levelNavigation')
        this.visible = false;
      }
    },
    handleMoreHover(item, activeTab) {
      this.activeTab = activeTab;
      // 点击事件切换显隐, hover事件必为visible: true
      this.visible = true;
      this.item = item;
      this.defaultFirst = true;
      console.log('morehoverItem', item);
    }
  }
};
