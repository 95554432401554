
  import ProductCard from './productCard.vue';
  
  export default {
    components: {
      ProductCard
    },
    props: {
      backgroundStyle: {
        type: Object,
        default: () => {
          return {};
        }
      },
      viewWidth: {
        type: Number,
        default: 0
      },
      data: {
        type: Object,
        default: () => {
          return {};
        }
      },
      active:{
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        allLoad: false
      };
    },
    computed: {
      emptyImg() {
        return this.$config.S3_COM_HOST + '/assets/clp/mincatalog/empty.png';
      },
      productCardWidth() {
        return (this.viewWidth - 20*2 - 12*3 - 4*2 - 1 - 68) / 2;
      },
      productCardHeight() {
        return this.productCardWidth + 46;
      },
      moduleList() {
        return this.data.itemList || [];
      },
      verticalCompHeight() {
        return this.productCardHeight*2 + 24 + 4*2;
      },
      tabHeight() {
        const len = this.moduleList.length;
        return (this.verticalCompHeight) / len;
      },
      tabStyle() {
        return {
          height: this.tabHeight + 'px'
        };
      },
      activeItemList() {
        if (this.moduleList.length) {
          const newList = this.moduleList[this.active].articleDetailList?.slice(0, 4) || [];
          return newList;
        } else {
          return [];
        }
      },
      rightContentStyle() {
        const styleData={
          width: `${4*2 + this.productCardWidth*2 + 1}px`,
          height: this.verticalCompHeight + 6 + 'px'
        };
        if(this.isTransparent){
          styleData.backgroundColor='transparent';
        }
        return styleData;
      },
      productCardStyle() {
        return {
          width: this.productCardWidth + 'px',
          height: this.productCardHeight + 'px'
        };
      },
      activeRedirectText() {
        return this.moduleList[this.active].tabRedirectText;
      },
      activeRedirectLink() {
        return this.moduleList[this.active].tabRedirectLink;
      },
      activeRedirectAllLink() {
        return this.moduleList[this.active].tabRedirectAllLink;
      },
      moduleImgList() {
        return this.moduleList.map((item) => { return item.tabMainImage;});
      },
      containerStyle() {
        return this.allLoad ? this.backgroundStyle : {backgroundColor: 'rgba(236, 239, 241, 0.50)'};
      },
      leftSkeletonStyle() {
        return {
          height: this.verticalCompHeight + 6 + 'px'
        };
      },
      rightProductSkeletonStyle() {
        return {
          width: this.productCardWidth + 'px',
          height: this.productCardWidth + 'px'
        };
      },
      isTransparent(){
        return this.moduleList[this.active].isTransparent;
      }
    },
    mounted () {
      this.loadMultiImg();
    },
    methods: {
      handlePdpClick(banner,index){
        this.$emit('pdpClick',this.moduleList[this.active],banner,index);
      },
      handleRedirect() {
        this.$emit('redirect', this.activeRedirectLink, this.moduleList[this.active], this.activeRedirectAllLink);
      },
      handleClickTab(idx) {
        if(this.moduleList?.length&&idx< this.moduleList.length){
          this.$emit('switchTab', idx,this.moduleList[idx],this.moduleList[this.active]);
        }

        // this.active = idx;
      },
      loadMultiImg() {
        const promiseAll = [];
        const img = [];
        const imgTotal = this.moduleImgList.length;
        for(let i = 0; i <  imgTotal; i++) {
          promiseAll[i] = new Promise((resolve, reject) => {
            img[i] = new Image();
            img[i].src = this.moduleImgList[i];
            if(img[i].complete){
              // 图片走缓存逻辑
              resolve(img[i]);
            }else{
              img[i].onload = () => {
                resolve(img[i]);
              };
            }
          });
        }
        Promise.all(promiseAll).then((img) => {
          // 全部加载完成
          this.allLoad = true;
        });
      }
    }
  };

