import { track } from '../vendors/sensors';

/**
 * @description: live 800 神策埋点
 */
 export function trackLive800(
  pageName: string,
  pageType: string,
): void {
  track('customer_service', {
    page_name: pageName,
    page_type: pageType,
  });
}

export default {
  trackLive800,
};
