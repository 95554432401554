
import { getCurrentLink } from '../util';
import specialLinkMixin from '../common/specialLink.mixins';

import {
  trackClpPdpClick,
  trackClpPlpClick,
  trackClpButtonClick,
  getScrollDepth
} from '../track/index';
import { parseURL, getImgUrl } from '~/utilities/helper/urlHelper';

export default {
  name: 'MultiImage',
  components: {},
  mixins: [specialLinkMixin],
  props: {
    list: {
      type: Object,
      default: () => {}
    },
    code: {
      type: String,
      default: ''
    },
    franchise: {
      type: String
    },
    tabName: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    categoryCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 4图片的高度
      fourPicHeight: 0,
      // 图片高度
      picHeight: 0,
      // 插件参数
      swiperProgress: -1,
      // 活跃标识
      activeBanner: 0,
      // 插件的各项参数
      swiperOption: {
        watchOverflow: true,
        slidesPerView: 4,
        spaceBetween: 2,
        breakpoints: {
          1439: {
            slidesPerView: 4
          },
          719: {
            slidesPerView: 1
          }
        },
        on: {
          slideChange: () => {
            this.swiperProgress = this.swiper.progress;
            this.activeBanner = this.swiper.activeIndex;
          }
        }
      },
      // mobile or pc
      device: 'mobile',
      // 计算样式
      benefitDescriptionHeight: '',
      // 计算高度
      swiperNavigationTop: '0px',
      isLoad: false
    };
  },
  computed: {
    imgUrl() {
      return function (item) {
        const pcSize = item.pcImgSize === '339*339' ? 500 : 1000;
        return this.device === 'mobile' ? (item.mobImgSize === '540*720' ? item.mobImgUrl : getImgUrl(item.mobImgUrl, 1000)) : getImgUrl(item.pcImgUrl, pcSize);
      };
    },
    imgList() {
      // 数据过滤
      let data = [];
      if (this.device === 'mobile') {
        data = this.list.itemList.filter((item) => {
          return item.mobImgUrl;
        });
      } else {
        data = this.list.itemList.filter((item) => {
          return item.pcImgUrl;
        });
      }
      // 四图并不需要多余四图
      return data;
    },
    // imgUrl不存在则不显示整个卡片
    // 这里做了修改++++++++++++++++++++++++++++++
    showItem() {
      const newList = [...this.list.itemList];
      for (let i = 0; i < newList.length; i++) {
        if (
          !(this.device === 'mobile'
            ? newList[i].mobImgUrl
            : newList[i].pcImgUrl)
        ) {
          newList.splice(i, 1);
        }
      }
      return newList;
    },
    swiper() {
      return this.$refs.mySwiper?.swiper;
    },
    // 显示右边可点击
    showRightArrow() {
      if (this.showItem?.length > 4) {
        return false;
      } else {
        return true;
      }
    },
    // 这里是判断组件的类型 clp多图组件一共有三种形式/也可以说两类 (有两种形式(可左右滑动)为一类,另一种形式为4图组件为一类)
    // 从传入的图片的移动端尺寸大小区分 注意传入的两大类只会出现一种 不会出现同时都匹配两类的情况
    // 判断条件 移动端图片尺寸960*960时为一类 其他为一类
    picSize() {
      return (this.list.itemList[0].mobImgSize === '960*960' && this.list.terminal.toLowerCase().includes('mobile')) || (this.list.itemList[0].pcImgSize === '338*338' && !this.list.terminal.toLowerCase().includes('mobile') && this.list.terminal.toLowerCase().includes('pc'));
    },
    type() {
      const filterList = this.imgList.filter((item) => {
        return !item?.title.text && !item?.subtitle.text && !item?.button.text;
      });
      return !(this.imgList.length === filterList.length);
    },
    // 背景颜色默认为白色or接口颜色
    backgroundStyle() {
      const style={
        backgroundColor:(this.list?.backgroundColor)?this.list.backgroundColor:"transparent"
        // maxWidth: "1920px",
        // margin: "0 auto"
      };

      if(this.device === "mobile" && this.list?.mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.list.mobBackgroundImage})`;
      }else if(this.device !== "mobile" &&this.list?.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.list.pcBackgroundImage})`;
      }
      return style;
    },
    // 对3种不同的类别设置padding
    paddindStyle() {
      if (this.picSize) {
        if (this.type) {
          return 'multiImage-nol-lgType';
        }
        return 'multiImage-nol-smType';
      } else {
        return 'multiImage-four';
      }
    },

    handleUrl() {
      return function (item) {
        return 'javascript:void(0);';
      };
    },

    getImageItemClass() {
      // 图片尺寸!=960的情况
      if(!this.picSize && this.list.layoutType) {
        const limit = this.list.layoutType > 4 ? 4 :this.list.layoutType;
        return 'multiImage-' + limit;
      };
      return this.imgList.length % 2 === 0 ? 'multiImage-2' : 'multiImage-3';
    },

    getImgStyle() {
      return function(item) {
        if(this.list.layoutType === 1 && !this.picSize && this.device === 'pc') {
          return {
            display: 'block',
            height: item.pcImgSize.split('*')[1] + 'px',
            margin: '0 auto',
            'max-width': item.pcImgSize.split('*')[0] + 'px'
          };
        } else {
          return {
          };
        }
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.handleResize);
      if (this.$refs.mySwiper) {
        this.swiper.destroy(false, false);
      }
    });
  },
  methods: {
    showFlag(item) {
      let show = false;
      if (
        (this.device === 'mobile' && item.mobImgUrl) ||
        (!this.device === 'mobile' && item.pcImgUrl)
      ) {
        show = true;
      }
      return show;
    },
    handleImgLoad(index) {
      index < 2 && this.handleResize();
      this.isLoad = true;
    },
    handleResize() {
      // 这里是动态计算两大类的高度是多少
      const windowSize = window.innerWidth;
      let device;
      if (windowSize < 720) {
        device = 'mobile';
      } else {
        device = 'pc';
      }
      this.device = device;
      if (document.getElementsByClassName('fourPicture-contener')[0]) {
        const currentImgWidth = this.getCurrentImgWidth();
        const imgItem = this.imgList[0];

        const width = this.device === 'mobile' ? imgItem?.mobImgSize.split('*')[0] : imgItem.pcImgSize.split('*')[0];
        const height = this.device === 'mobile' ? imgItem?.mobImgSize.split('*')[1] : imgItem.pcImgSize.split('*')[1];

        if(this.list.layoutType === 1 && this.device === 'pc') {
          this.fourPicHeight = 'auto';
        } else {
          this.fourPicHeight = (currentImgWidth * height) / width + 'px';
        }
      }
      // 设置完轮播图属性 动态获取的图片高度确定导航的绝对位置
      if (document.getElementsByClassName('benefit-banner')[0]) {
        const imgDom = document.getElementsByClassName('benefit-banner')[0];
        this.picHeight = imgDom.clientWidth;
        this.swiperNavigationTop = imgDom.clientHeight / 2 - 16 + 'px';
      }
    },
    // 根据n和图片数量获取图片宽度
    getCurrentImgWidth() {
      const containerWidth =
          this.device === 'mobile'
            ? document.body.clientWidth
            : document.getElementsByClassName('fourPicture')[0].clientWidth;

      if(this.list.layoutType) {
        const limit = this.list.layoutType > 4 ? 4 : this.list.layoutType;
        // 每个item右侧都有margin = 2，每行最末一个没有margin
        return (containerWidth - (limit - 1) * 2) / limit;
      }

      return this.imgList.length % 2 === 0
            ? (containerWidth - 2) / 2
            : (containerWidth - 4) / 3;
    },
    clickImg(item, index, e) {
      e.preventDefault();
      if (item.title?.text || item.subtitle?.text || item.button?.text) {
        return;
      }
      const curTerminalLink = item.allLink ? getCurrentLink(item.allLink) : item.link || '';
      if (!curTerminalLink) return;
      this.franchise ? item.franchise = this.franchise : '';
      if (
        curTerminalLink.includes('/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/list') ||
        curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/rich-list') ||
        curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
        curTerminalLink.includes('adidas://category') ||
        parseURL(curTerminalLink).path.startsWith('/plp') ||
        parseURL(curTerminalLink).path.startsWith('/splp')
      ) {
        this.trackData('plp', item, index);
      } else if (
        curTerminalLink.includes('/pages/product/details') ||
        curTerminalLink.includes('adidas://product') ||
        curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
        parseURL(curTerminalLink).path === '/pdp') {
        this.trackData('pdp', item, index);
      } else {
        this.trackData('button', item);
      }
      this.handleSpecialLinkClick(curTerminalLink, item);
    },
    gotoMore(item, index) {
      this.franchise ? item.franchise = this.franchise : '';
      const curTerminalLink = item.allLink ? getCurrentLink(item.allLink) : item.link || '';
      if (!curTerminalLink) return;
      if (curTerminalLink.includes('/pages/product/list') ||
        curTerminalLink.includes('/pages/plp/index') ||
        curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/rich-list') ||
        curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
        curTerminalLink.includes('adidas://category') ||
        parseURL(curTerminalLink).path.startsWith('/plp') ||
        parseURL(curTerminalLink).path.startsWith('/splp')) {
        this.trackData('plp', item, index);
      } else if (curTerminalLink.includes('/pages/product/details') ||
        curTerminalLink.includes('adidas://product') ||
        curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
        parseURL(curTerminalLink).path === '/pdp') {
        this.trackData('pdp', item, index);
      } else {
        this.trackData('button', item);
      }
      this.handleSpecialLinkClick(curTerminalLink, item);
    },
    // 配合插件配置
    guid(step) {
      this.swiper.slideTo(step, 500, false);
    },
    flipSwiper(direction) {
      let pagination;
      switch (this.device) {
        case 'mobile':
          pagination = 1;
          break;
        default:
          pagination = 4;
      }
      this.guid(
        this.activeBanner + (direction === 'prev' ? -pagination : pagination)
      );
    },
    goDetail(item) {
      this.franchise ? item.franchise = this.franchise : '';
      const curTerminalLink = item.allLink ? getCurrentLink(item.allLink) : item.link || '';
      if (!curTerminalLink) return;
      if (
        curTerminalLink.includes('/pages/product/list') ||
        curTerminalLink.includes('/pages/plp/index') ||
        curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/rich-list') ||
        curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
        curTerminalLink.includes('adidas://category') ||
        parseURL(curTerminalLink).path.startsWith('/plp') ||
        parseURL(curTerminalLink).path.startsWith('/splp')) {
        this.trackData('plp', item);
      } else if (curTerminalLink.includes('/pages/product/details') ||
        curTerminalLink.includes('adidas://product') ||
        curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
        parseURL(curTerminalLink).path === '/pdp') {
        this.trackData('pdp', item);
      } else {
        this.trackData('button', item);
      }
      this.handleSpecialLinkClick(curTerminalLink, item);
    },
    // 添加埋点
    trackData(val, item, index) {
      try{
        const curTerminalLink = item.allLink ? getCurrentLink(item.allLink) : item.link || '';
        const text = [item.title?.text, item.subtitle?.text].filter(txt => {
          return !!txt;
        });
        const itemLabelInfoList = [];
        if(item?.labelInfo?.length){
          item.labelInfo?.forEach((labelItem) => {
            itemLabelInfoList.push(`${labelItem.labelGroup}:${labelItem.label.join('|')}`);
          });
        }
        let data = {};
        const directAppProject = curTerminalLink.includes('BOS') && 'FS' || curTerminalLink.includes('CFM') && 'CFD' || '';
        switch (val) {
          case 'plp':
            data = {
              campaign_name: this.$route.query.contentId || '',
              sub_campaign_name: this.code,
              text: text.join('|'),
              component_code: 'ClpMultiImage',
              position_code: Number(this.position.slice(1)),
              card_position: index + 1,
              scroll_depth: getScrollDepth(this.$refs.multiImageRef.offsetTop),
              plp_url: curTerminalLink || '',
              plp_name: '',
              tab_name: this.tabName,
              tag: this.list.labelInfoList.join(','),
              multii_item_tag: itemLabelInfoList?.join(',')
            };
            trackClpPlpClick(data);
            break;
          case 'pdp':
            // data = [this.code, null, null, null, null, null];
            data = {
              campaign_name: this.$route.query.contentId || '',
              sub_campaign_name: this.code,
              text: text.join('|'),
              component_code: 'ClpMultiImage',
              position_code: Number(this.position.slice(1)),
              article_position: index + 1,
              tab_name: this.tabName,
              recommend_id: '',
              scroll_depth: getScrollDepth(this.$refs.multiImageRef.offsetTop),
              category_code: this.categoryCode,
              article_number: parseURL(curTerminalLink).query.articleId,
              brand: '',
              category: this.categoryCode,
              gender: '',
              tag: this.list.labelInfoList.join(','),
              multii_item_tag: itemLabelInfoList?.join(',')
            };
            trackClpPdpClick(data);
            break;
          case 'button':
            data = {
              campaign_name: this.$route.query.contentId || '',
              sub_campaign_name: this.code,
              text: text.join('|'),
              component_code: 'ClpMultiImage',
              position_code: Number(this.position.slice(1)),
              scroll_depth: getScrollDepth(this.$refs.multiImageRef.offsetTop),
              to_url: curTerminalLink || '',
              button_name: curTerminalLink === '领券' ? '领券' : item.button.text || '',
              tab_name: this.tabName,
              tag: this.list.labelInfoList.join(','),
              multii_item_tag: itemLabelInfoList?.join(',')
            };
            directAppProject ? data.direct_app_project = directAppProject : '';
            trackClpButtonClick(data);
            break;
        }
      }catch{}
    }
  }
};
