import { DeviceService } from '../device';
import { getScreenInfo } from '../screen';
import { delay } from './delay';
import { nextIndex } from './zIndex';

const $utils = {
  delay,
  nextIndex,

  device: DeviceService,
  _screen: null as any as ReturnType<typeof getScreenInfo>,
  get screen () {
    if (!this._screen) {
      this._screen = getScreenInfo();
      if (typeof window !== 'undefined') {
        window.addEventListener(
          'resize',
          () => (this._screen = getScreenInfo())
        );
      }
    }
    return this._screen;
  }
};

export function installGlobalUtils (Vue: any) {
  console.log('plugins mixin installGlobalUtils');
  Vue.mixin({
    computed: {
      $utils () {
        return $utils;
      }
    }
  });
}

// aws图片url添加尺寸后缀
export function getImgUrl(url: string, size: number): string {
  let _url = url;
  if (!url || url.length === 0) {
    return '';
  }
  if (url.includes('im=Resize')) {
    return _url;
  }
  if (url.includes('?')) {
    _url += `&im=Resize=(${size},${size})`;
  } else {
    _url += `?im=Resize=(${size},${size})`;
  }
  return _url;
}
