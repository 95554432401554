export const addClass = (el: HTMLElement, addCLs: string | string[]): void => {
  if (!el || !addCLs) return;
  let addClasses;
  if (Array.isArray(addCLs)) {
    addClasses = addCLs;
  } else {
    addClasses = (addCLs as string).split(' ');
  }
  if (!!el.classList && !!el.classList.value) {
    addClasses.forEach(item => el.classList.add(item));
  } else {
    const curClasses = (el.className || '').split(' ');
    addClasses.forEach(item => {
      if (!curClasses.includes(item)) curClasses.push(item);
    });
    el.className = curClasses.join(' ');
  }
};
