
import SearchBar from '../search-bar/SearchBar.vue';
import SearchOverlay from '../search-overlay/index.vue';
import { trackSearchClick } from '~/pages/search/track';

export default {
  components: {
    SearchBar,
    SearchOverlay
  },
  props: {
    // searchbar 宽度
    cutWidth: {
      type: String
    }
  },
  data() {
    return {
       // 控制overlay显隐
      showOverLay: false,
      // 预置词
      presetWord: '', 
      show: false
    };
  },
  watch: {
    // 路由发生变化应关闭overlay
    $route() {
      this.showOverLay = false;
      setTimeout(() => {
        this.show = false;
      });
    }
  },
  methods: {
    // 控制pc搜索overlay显隐
    changeOverLay() {
      this.show = true;
      trackSearchClick(this.$route.path);
      setTimeout(() => {
        this.showOverLay = !this.showOverLay;
      });
    },

    // 将预置词语带入搜索页
    getContent(item) {
      this.presetWord = item;
    },

    ChangeOverLayAndContent() {
      let hotWord;
      if (window.self === window.top) {
        hotWord = window.sessionStorage.getItem('hotWordsSession');
      } else {
        hotWord = '查找商品';
      }
      this.changeOverLay();
      this.getContent(hotWord);
    },
    closeOverlay() {
      this.show = false;
      this.showOverLay = false;
    }
  }
};
