
import { isClient } from '~/utilities/helper/SystemHelper';
export default {
  data() {
    let isAPP = false;
    let isWMS = false;
    if (isClient) {
      const userAgent = navigator.userAgent.toLowerCase();
      isAPP = userAgent.includes('adidasapp');
      isWMS = userAgent.includes('miniprogram');
    }
    return {
      target: isAPP || isWMS ? '_self' : '_blank',
      // 图片格式为全尺寸请注意
      adidasQRLogo: `${this.$config.S3_HOST}/p05/right/weibo_adidas.png`,
      adidasOriginalsLogo: `${this.$config.S3_COM_HOST}/assets/logo/weibo-adidasOriginals-black.png`
    };
  }
};
