
import {
  LibPopover
} from '@adidas-com-front/ui-library';
import { BrowserUtils } from './utils.ts';
import {isClient} from "~/utilities";
export default {
  components: {
    LibPopover
  },
  data() {
    return {
      showHalfWin: false,
      showBroswerPopup: false,
      isMobile: false,
      direction: 'right',
      list: [
        {title: 'adidas 小程序', desc: '畅享轻松便捷购物', btn: '去体验', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/wechat-logo.png`, urlLink: 'https://static1.adidas.com.cn/ecp-static-pages/index.html#/pagesC/weixin/urlScheme?code=URLS2023110300669'},
        {title: 'adidas App', desc: '选购必备单品及更多潮流产品', btn: '去体验', icon:  `${this.$config.S3_COM_HOST}/assets/compatibility/app-logo.png`, urlLink: 'https://analytics.adidas.com.cn/sd/ccc/Nha/2rECkt'}
      ],
      pcQRCodeList: [
        {icon: `${this.$config.S3_COM_HOST}/assets/compatibility/wechat-pc-banner.png`},
        {icon: `${this.$config.S3_COM_HOST}/assets/compatibility/app-pc-banner.png`}
      ],
      mobileBroswerList: [
        {name: 'Chrome', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/chrome.png`},
        {name: 'Safari', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/Safari.png`},
        {name: '华为浏览器', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/huawei.png`},
        {name: 'QQ浏览器', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/qq.png`},
        {name: '微信', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/wechat.png`},
        {name: '百度App', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/baidu.png`}
      ],
      pcBroswerList: [
        {name: 'Chrome', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/chrome.png`},
        {name: 'Edge', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/edge.png`},
        {name: 'Safari', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/Safari.png`},
        {name: 'QQ浏览器', icon: `${this.$config.S3_COM_HOST}/assets/compatibility/qq.png`}
      ],
      firstWinSize: 0,
      firstWinTitle: '',
      sizeDir: '',
      mobileTipsIn: false,
      pcTipsIn: false,
      pcTipsShow: true,
      isFirstShowCompatibility: true,
      compatibilitySwitch: false,
      routeCanShowCompatibility: ['index', 'pdp', 'plp-category'].includes(this.$route.name),
      viewDetailText: '详情',
      browserAdvice: 'adidas官网目前在网页端对以下浏览器进行技术支持，为避免可能存在的安全隐患，建议您使用以下浏览器：',
      hideHeader: false,
      option_2: '',
      showAnimation: false,
      show: false,
      showPCTips: false,
      activeBrowserList:[]
    };
  },
  computed: {
    broswerList() {
      return this.isMobile ? this.mobileBroswerList : this.pcBroswerList;
    },
    isCurrentBrowserShowCompatibility() {
      if (this.compatibilitySwitch) {
        return this.browserUtils.checkIsShowCompatibilityTips();
      } else {
        return false;
      }
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        // console.log("$route.handler",route,location.href);
        this.routeCanShowCompatibility = ['index', 'pdp', 'plp-category'].includes(route.name);
        const isShowTips =this.show = this.browserUtils?.checkIsShowCompatibilityTips()&&!this.browserUtils?.inWhitePage();
        this.show = this.checkIsShow(isShowTips)&&!this.browserUtils.inWhitePage();

        if(!isShowTips){
          return;
        }
        if(route.name === 'index') {
          this.getPromoStatus(isShowTips);
        }

        this.$nextTick(() => {
          this.isFirstShowCompatibility = this.getCompatibilityFlag();
          this.showPCTips = !this.getPCTipsStatus();
          if (this.isFirstShowCompatibility && this.routeCanShowCompatibility) {
            this.showHalfWin = true;
            this.showAnimation = false;
          } else {
            this.mobileTipsIn = true;
            this.pcTipsIn = true;
            this.pcTipsShow = true;
            this.showAnimation = true;
          }
        });
      }
    },
    showHalfWin(newValue) {
      if (newValue) {
        this.setCompatibilityFlag();
      } else {
        this.$store.commit('homePage/SET_COMPATIBILITY_STATUS', true);
      }
    }
  },
  async mounted () {
    this.config = await this.getConfig();
    const browserList = this.config.browserList || [];
    this.activeBrowserList = [];
    for (const k in browserList) {
      if (browserList[k]) {
        this.activeBrowserList.push(k.toLowerCase());
      }
    }
    this.browserUtils = new BrowserUtils(this.activeBrowserList,this.config.whitePageList||[]);
    this.compatibilitySwitch = this.config.compatibilityNotificationSwitch;
    const isShowTips = this.browserUtils?.checkIsShowCompatibilityTips();
    this.show = this.checkIsShow(isShowTips)&&!this.browserUtils.inWhitePage();
    this.getPromoStatus(isShowTips);
    this.$nextTick(() => {
      this.isFirstShowCompatibility = this.getCompatibilityFlag();
      this.showPCTips = !this.getPCTipsStatus();
      if (this.isFirstShowCompatibility && this.routeCanShowCompatibility) {
        this.showHalfWin = true;
        this.showAnimation = false;
      } else {
        this.mobileTipsIn = true;
        this.pcTipsIn = true;
        this.pcTipsShow = true;
        this.showAnimation = true;
      }
    });
    this.handleWidth();
    window.addEventListener('resize', this.handleWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWidth);
  },
  methods: {
    getPromoStatus(isShowTips) {
      // 未打开兼容开关、打开开关且是首页且在白名单内、打开开关且首页且不在白名单内且不是第一次弹兼容半窗
      // 以上三种，首页promoremdeption弹框展示
      const isHomepageOpenInWhite = this.compatibilitySwitch && ['index'].includes(this.$route.name) && !isShowTips;
      const isHomepageOpenNotInWhiteNotFirst = this.compatibilitySwitch && ['index'].includes(this.$route.name) && isShowTips && !this.getCompatibilityFlag();
      if (!this.compatibilitySwitch || isHomepageOpenInWhite || isHomepageOpenNotInWhiteNotFirst) {
        this.$store.commit('homePage/SET_COMPATIBILITY_STATUS', true);
      }
    },
    checkIsShow(isShowTips) {
      const hasOrPath = this.$route?.path.toLowerCase().includes('/or/');
      const shopCode = this.$route?.query.shopCode;
      const ua = window?.navigator.userAgent.toLowerCase();
      const isCom = !ua.includes('miniprogram') && !ua.includes('adidasapp') && !hasOrPath && !shopCode;
      const isShow = isCom && this.compatibilitySwitch && isShowTips;
      return isShow;
    },
    async getConfig() {
      const configObj = await this.$axios.get(
        `${this.$config.S3_COM_HOST}/static/compatibility/compatibility-config.json`
      );
      configObj.sort((a, b) => {
        return new Date(b.startTime).getTime() - new Date(a.startTime).getTime();
      });
      const currentTime = new Date().getTime();
      const defaultConfig = configObj.find((item) => {
        return !item.startTime;
      });
      const config = configObj.find((item) => {
        return currentTime > new Date(item.startTime).getTime();
      }) || defaultConfig;
      return config;
    },
    getCompatibilityFlag() {
      if(isClient && sessionStorage){
        return !sessionStorage.getItem('showCompatibility');
      } else {
        return true;
      }
    },
    setCompatibilityFlag() {
      if(sessionStorage && this.isFirstShowCompatibility && this.routeCanShowCompatibility){
        sessionStorage.setItem('showCompatibility', true);
      }
    },
    getPCTipsStatus() {
      if (isClient && sessionStorage) {
        return sessionStorage.getItem('closePCCompatibilityTips');
      } else {
        return false;
      }
    },
    setPCTipsStatus() {
      if(sessionStorage){
        sessionStorage.setItem('closePCCompatibilityTips', true);
      }
    },
    handleHalfWinAction() {
      this.showHalfWin = !this.showHalfWin;
      this.pcTipsIn = this.mobileTipsIn = !this.showHalfWin;
      this.showAnimation = true;
    },
    handleWidth() {
      this.isMobile = window.innerWidth < 720;
      this.firstWinSize = this.isMobile ? 382 : 423;
      this.direction = this.isMobile ? 'bottom': 'right';
      this.sizeDir = this.isMobile ? 'height' : '';
      this.firstWinTitle = this.isMobile ? '浏览器版本不兼容' : ' ';
      this.viewDetailText = this.isMobile ? '详情' : '查看详情';
      this.hideHeader = !this.isMobile;
      this.option_2 = this.isMobile ? '尝试使用支持的浏览器' : '尝试升级浏览器或使用支持的浏览器';
      this.browserAdvice = this.isMobile ? 'adidas官网目前在移动端对以下浏览器或App进行技术支持，为避免可能存在的安全隐患，建议您使用以下浏览器或App：' : 'adidas官网目前在网页端对以下浏览器进行技术支持，为避免可能存在的安全隐患，建议您使用以下浏览器：';
    },
    handleOpenBroswer() {
      this.showBroswerPopup = !this.showBroswerPopup;
    },
    handleCloseAll() {
      this.showBroswerPopup = !this.showBroswerPopup;
      this.showHalfWin = !this.showHalfWin;
      this.mobileTipsIn = !this.showHalfWin;
      this.pcTipsIn = !this.showHalfWin;
      this.showAnimation = true;
    },
    openUrlScheme(item) {
      window.location.href = item.urlLink;
    },
    handleClosePCTips() {
      this.pcTipsShow = false;
      this.setPCTipsStatus();
    }
  }
};
