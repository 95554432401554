
import deepcopy from 'deepcopy';
import { trackClpSurveyResultView, trackClpSurveySubmit, trackClpSurveyPDPClick } from './track/index';
import ShuffleItem from './shuffle-item';
import questionMixin from './question.mixins';
import MemberGuide from '~/components/clp/survey/memberPopover.vue';

export default {
  components: {
    ShuffleItem,
    MemberGuide
  },
  mixins: [questionMixin],
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    },
    repeatFillingFlag: {
      type: Boolean,
      default: false
    },
    surveyCode: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      optionList: this.question.questionDescriptionDetail.optionList,
      tmpOptionList: this.question.questionDescriptionDetail.optionList,
      originalList:[],
      resetFlag: false,
      // 重新选择时保存的一版答案
      oriSelectedItemList: [],
      submited: false,
      showQuestion: false,
      selectedItemList: [],
      selectedItem: null
    };
  },
  computed: {
    resultImg(){
      const defaultImage = this.$config.S3_COM_HOST + "/assets/clp/survey/shuffle-default-result.png";
      return this.questionDescDetail.replyImage || defaultImage;
    },
    showText() {
      let flag = true;
      this.optionList.forEach(
        item=>{
          if (!item.questionImage&& !item.resultPage && (item.optionText !== 0 && !item.optionText || !item.productList)) {
            flag = false;
          }
        }
      );
      return flag;
    },
    code(){
      return this.surveyCode + this.question.questionCode;
    },
    questionDescDetail() {
      return this.question.questionDescriptionDetail;
    },
    subtitleStyle() {
      return {
        color: this.questionDescDetail.subtitleColor
      };
    },
    titleStyle() {
      return {
        color: this.questionDescDetail.titleColor
      };
    },
    showWholeReply() {
      return this.tmpOptionList.filter(item=>!item.removed && !item.resultPage).length === 0;
    }
  },
  watch: {
    showWholeReply(newValue, oldValue) {
      if (newValue) {
        this.trackResultView();
      }
    },
    surveyResult(newVal) {
      if(newVal) {
        this.tmpOptionList = newVal ? deepcopy(this.getLastResultShuffle()) : [...this.addQuestionImage(this.optionList)];
      }
    }
  },
  mounted () {
    this.getProductList();
  },
  methods: {
    handleCallMemberGuide() {
      this.showMemberGuide = true;
    },
    trackResultView() {
      trackClpSurveyResultView({
        campaignName: this.$route.query.contentId,
        componentCode: 'Survey',
        positionCode: this.question.questionPosition,
        questionType: this.question.questionType,
        surveyId: this.surveyCode,
        questionId: this.question.questionCode,
        submitDetail: '',
        articleIdList: '',
        categoryCodeList: '',
        resultType: this.dynamicReplyFlag ? 'dynamic': 'for all',
        pageTitle: 'submit',
        is_memer_only: !!this.surveyDetail.replyMemberFlag
      });

    },
    handleTrackPdpClick(articleId) {
      const optionList = [...this.optionList];
      const unRemovedList = optionList.filter(optionItem=>!optionItem.removed);
      const item = unRemovedList && unRemovedList[0];
      // sensors
      trackClpSurveyPDPClick({
        campaignName: this.$route.query.contentId,
        componentCode: 'Survey',
        positionCode: this.question.questionPosition,
        questionType: this.question.questionType,
        surveyId: this.surveyCode,
        questionId: this.question.questionCode,
        optionDetail: item.optionDescribe,
        articleId: articleId,
        categoryCode: '',
        is_memer_only: !!this.surveyDetail.replyMemberFlag
      });
    },
    viewProduct({code,id,selected},resetFlag){
      const unRemovedList = this.tmpOptionList.filter(optionItem=>!optionItem.removed);
      const exceptQuestionImageList = this.tmpOptionList.filter(item=>!item.questionImage);
      const item = unRemovedList[0];
      const tmpOptionList = [...this.tmpOptionList];
        if (item.optionCode === unRemovedList[0].optionCode) {
          for (let pIndex = 0; pIndex< item.productList?.length; pIndex++) {
            let index = 0;
            if (exceptQuestionImageList.length) {
              index = tmpOptionList.findIndex(tmpItem=>tmpItem.optionCode === item.optionCode);
            }
            if (!resetFlag) {
              const product = item.productList[pIndex];
              if (product.articleId === id) {
                tmpOptionList[index].productList = tmpOptionList[index].productList.map(item=>{
                  return {
                    ...item,
                    selected: false
                  };
                });
                tmpOptionList[index].productList[pIndex].selected = !selected;
                this.tmpOptionList = [...tmpOptionList];
                break;
              }
            } else {
              tmpOptionList[index].productList = tmpOptionList[index].productList?.map(item=>{
                return {
                  ...item,
                  selected: false
                };
              });
            }
            }
        }
      // this.tmpOptionList = [...this.tmpOptionList];
    },
    viewLink({optionCode,selected},resetFlag){
      const unRemovedList = this.tmpOptionList.filter(optionItem=>!optionItem.removed);
      const exceptQuestionImageList = this.tmpOptionList.filter(item=>!item.questionImage);
      const item = unRemovedList[0];
      let tmpOptionList = [...this.tmpOptionList];
      if (item.optionCode === unRemovedList[0].optionCode) {
        let index = 0;
        if (exceptQuestionImageList.length) {
          index = tmpOptionList.findIndex(tmpItem=>tmpItem.optionCode === item.optionCode);
        }
        if (!resetFlag) {
          tmpOptionList[index].selected = !selected;
        } else {
          tmpOptionList = tmpOptionList.map(item=>{
            return {
              ...item,
              selected: false
            };
          });
        }
        this.tmpOptionList = [...tmpOptionList];
      }
      // this.tmpOptionList = [...this.tmpOptionList];
    },
    handleLike(clickFlag){
      this.handleRemove(true);
      const optionList = [...this.optionList];
      const removedList = optionList.filter(optionItem=>optionItem.removed);
      const item = removedList && removedList.pop();
      const selectList = this.tmpOptionList.filter(item=>!item.questionImage && item.removed);
      this.selectedItemList = [...selectList];
      const index = this.oriSelectedItemList.findIndex(oriItem=>oriItem.optionCode === item.optionCode);
      // 这里是重新选择的时候需要带上之前的答案，即替换最新选项即可，下同
      if (this.resetFlag && index > -1) {
        this.selectedItemList = [...this.oriSelectedItemList.slice(0,index),item,...this.oriSelectedItemList.slice(index+1)];
        this.oriSelectedItemList = [...this.selectedItemList];
      }

      if (this.surveyResult) {
        const questionAnswer = this.surveyResult.questionAnswerInfo.find((item) => { return item.questionCode === this.question.questionCode;});
        const answerList = questionAnswer?.answerList || [];
        this.selectedItemList.forEach((si) => {
          if (si.liked === undefined) {
            const answer = answerList.find((it) => {
              return it.optionCode === si.optionCode;
            });
            if (answer) {
              si.liked = answer.submitType === 'second';
            }
          }
        });
      }

      !item.questionImage && trackClpSurveySubmit({
        campaignName: this.$route.query.contentId,
        componentCode: 'Survey',
        positionCode: this.question.questionPosition,
        questionType: this.question.questionType,
        surveyId: this.surveyCode,
        questionId: this.question.questionCode,
        submitDetail: item?.optionDescribe || '',
        submitType: clickFlag ? 'Button-Right' : 'Slide-Right',
        articleIdList: item?.optionArticleList?.toString() || '',
        categoryCodeList: item?.optionCategoryCode || '',
        is_memer_only: !!this.surveyDetail.replyMemberFlag
      });
      // 封面滑动不提交
      if (!item.questionImage) {
        console.log('selectedItemList Like', this.selectedItemList);
        // 整卷提交
        if (this.submitAnswerFlag) {
          this.$emit('updateTextAnswer', {showWholeReply: false, questionType: this.question.questionType, questionCode: this.question.questionCode, selectedItemList: this.selectedItemList, selectedItem: {}});
          return;
        }
        this.submitResult();
      }
    },
    handleRemove(likeFlag){
      let optionList = [...this.optionList];
      const unRemovedList = optionList.filter(optionItem=>!optionItem.removed);
      const item = unRemovedList && unRemovedList[0];
      this.viewProduct(item.optionCode,true);
      optionList=optionList.map((option)=>{
        return {
          ...option,
          removed : option.removed || option.optionCode === item.optionCode,
          liked: option.liked || option.optionCode === item.optionCode && likeFlag
        };
      });
      this.optionList = [...optionList];
      this.tmpOptionList = [...optionList];
    },
    handleNext(clickFlag){
      this.handleRemove(false);
      const optionList = [...this.optionList];
      const removedList = optionList.filter(optionItem=>optionItem.removed);
      const item = removedList && removedList.pop();
      const selectList = this.tmpOptionList.filter(item=>!item.questionImage && item.removed);
      this.selectedItemList = [...selectList];
      const index = this.oriSelectedItemList.findIndex(oriItem=>oriItem.optionCode === item.optionCode);
      if (this.resetFlag && index > -1) {
        this.selectedItemList = [...this.oriSelectedItemList.slice(0,index),item,...this.oriSelectedItemList.slice(index+1)];
        this.oriSelectedItemList = [...this.selectedItemList];
      }
      if (this.surveyResult) {
        const questionAnswer = this.surveyResult.questionAnswerInfo.find((item) => { return item.questionCode === this.question.questionCode;});
        const answerList = questionAnswer?.answerList || [];
        this.selectedItemList.forEach((si) => {
          if (si.liked === undefined) {
            const answer = answerList.find((it) => {
              return it.optionCode === si.optionCode;
            });
            if (answer) {
              si.liked = answer.submitType === 'second';
            }
          }
        });
      }

      !item.questionImage && trackClpSurveySubmit({
        campaignName: this.$route.query.contentId,
        componentCode: 'Survey',
        positionCode: this.question.questionPosition,
        questionType: this.question.questionType,
        surveyId: this.surveyCode,
        questionId: this.question.questionCode,
        submitDetail: item?.optionDescribe || '',
        submitType: clickFlag ? 'Button-Left' : 'Slide-Left',
        articleIdList: item?.optionArticleList?.toString() || '',
        categoryCodeList: item?.optionCategoryCode || '',
        is_memer_only: !!this.surveyDetail.replyMemberFlag
      });
      // 封面滑动不提交
      if (!item.questionImage) {
        console.log('selectedItemList Next', this.selectedItemList);
        // 整卷提交
        if (this.submitAnswerFlag) {
          this.$emit('updateTextAnswer', {showWholeReply: false, questionType: this.question.questionType, questionCode: this.question.questionCode, selectedItemList: this.selectedItemList, selectedItem: {}});
          return;
        }
        this.submitResult();
      }
    },
    async getProductList() {
      const productPromises = [];
      const optionList = deepcopy(this.question.questionDescriptionDetail.optionList);
      optionList.sort((a, b) => { return a.optionPosition - b.optionPosition; });
      optionList.forEach((item) => {
        const product = {
          optionCode: item.optionCode,
          articleIdList: item.optionArticleList?.length ? item.optionArticleList.join(',') : '',
          categoryCode: item.optionCategoryCode || ''
        };
        if (product.articleIdList?.length ===0 && product.categoryCode) {
          productPromises.push(this.getPlp(product.categoryCode));
        } else {
          productPromises.push([]);
        }
      });
      const allProductData = await Promise.all(productPromises);
      optionList.forEach((option, idx) => {
        if (!option.optionArticleList?.length) {
          option.productList = allProductData[idx];
        }
      });
      this.optionList = deepcopy(this.addQuestionImage(optionList));
      this.tmpOptionList = this.surveyResult ? deepcopy(this.getLastResultShuffle()) : [...this.addQuestionImage(optionList)];
      this.originalList = deepcopy(this.addQuestionImage(optionList));
    },
    getLastResultShuffle() {
      const optionList = [...this.optionList];
      const questionAnswer = this.surveyResult.questionAnswerInfo.find((item) => { return item.questionCode === this.question.questionCode;});
      if(questionAnswer) {
        const answerList = questionAnswer.answerList;
        optionList.forEach((element) => {
          const answer = answerList.find((item) => { return item.optionCode === element.optionCode;});
          if(answer || element.questionImage) {
            element.removed = true;
            element.liked = answer?.submitType === 'second' || '';
          }
        });
      }
      return optionList;
    },
    addQuestionImage(list){
      let originalList = list;
      originalList = originalList.filter(item => {
        let flag = true;
        if (!item.optionImageUrl && !item.productList?.length) {
          flag = false;
        }
        return flag;
      });
      if(this.questionDescDetail.questionImage) {
        const tmpId = Math.random().toString().substr(3, 10) + Date.now().toString();
        const item = {
          questionImage:this.questionDescDetail.questionImage,
          optionCode: tmpId
        };
        originalList = [item,...originalList];
      }
      const resultId = Math.random().toString().substr(3, 10) + Date.now().toString();
      const resultItem= {
        resultPage:true,
        optionCode: resultId
      };
      originalList = [...originalList,resultItem];
      this.showQuestion = Boolean(originalList.filter(item=>!item.questionImage && !item.resultPage).length);
      return originalList;
    },
    getPlp(categoryCode) {
      const params = {
        page: 1,
        pageSize: 10,
        categoryCode: categoryCode,
        abTest: 'A'
      };
      return new Promise((resolve, reject) => {
        this.$axios
          .get(
            `${this.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/search`,
            {
              params: { ...params }
            }
          )
          .then( (res) => {
            resolve(res.content.length ? res.content.slice(0,4) : []);
          })
          .catch((e) => {
            resolve('');
          });
      });
    },
    resetSurvey() {
      this.submited = false;
      this.resetFlag = true;
      this.tmpOptionList = [...this.originalList];
      this.optionList = [...this.originalList];
      this.selectedItem = null;
      this.oriSelectedItemList = this.selectedItemList.length ? this.selectedItemList : deepcopy(this.optionList.filter((item) => {return !item.questionImage;}).filter((item) => { return !item.resultPage;}));
    }
  }
};
