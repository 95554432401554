/*
 * @Author: wang,shuang
 * @Date: 2021-12-09 14:44:17
 * @Description: 面包屑数据缓存至localStorage
 */

// set items
function setBreadCrumbs(key, str) {
  localStorage.setItem(
    'breadCrumbs',
    JSON.stringify({
      ...getBreadCrumbs(),
      [key]: str
    })
  );
}

// get items
function getBreadCrumbs(isPdp) {
  if (!process.client) { return {}; }
  return JSON.parse(
    localStorage.getItem(isPdp ? 'pdpBreadCrumbs' : 'breadCrumbs')
  );
}

// remove items
function removeBreadCrumbs(isPdp) {
  localStorage.removeItem(isPdp ? 'pdpBreadCrumbs' : 'breadCrumbs');
}

// set pdp items
function setPdpBreadCrumbs(str, articleId) {
  localStorage.setItem(
    'pdpBreadCrumbs',
    JSON.stringify({
      ...getBreadCrumbs(true),
      [articleId]: str
    })
  );
}

export { setBreadCrumbs, getBreadCrumbs, removeBreadCrumbs, setPdpBreadCrumbs };
