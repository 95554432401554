
import {Utils} from './utils';
import {Pag} from '~/utilities/pag.ts';
export default {
  name: "PointsAnimation",
  props:{
    show:Boolean,
    type:String,
    points:{
      type:Number,
      default:0
    },
    pointsTypeText:{
      type:String
    },
    animWidth:{
      type:Number,
      // -1 表示不限制
      default:-1
    }
  },
  data(){
    return {
      height:0,
      pagPlay:null,
      utils:null,
      animationClass:'',
      pointsText:''
    };
  },
  computed:{
    boxStyle(){
      if(this.height){
        return {height:this.height};
      }else{
        return {};
      }
      
    }
  },
  watch:{
    show(value){
      if(value){
        this.play();
      }
    }
  },
  async mounted(){
    if(!this.$refs.pointsbox||!this.$refs.pointsCanvas){
      throw  new Error("pointsAnimation 失败");
    }
    this.utils=new Utils(this);
    let points = await this.utils.getConfig(this.type);
    if(points===-1){
      return;
    }
    points=points||this.points;
    if(points>0){
      this.pointsText='+'+points.toString();
      this.pagPlay=new Pag(this,this.$config.S3_COM_HOST + "/assets/points-animation/points-animation.pag",this.$refs.pointsCanvas);
      await this.pagPlay.initAsync();
      if(this.show){
        this.play();
      }
    }
    // window.play=()=>{
    //   this.play();
    // };
  },
  methods:{
    async play(){
      if(!this.$refs.pointsbox||!this.$refs.pointsCanvas){
        throw  new Error("pointsAnimation 失败");
      }
      if(this.utils.isMemberLogin()){
        const { isMedical,isCampus,isPartnerShip,isVolunteer}= this.utils.getIdentity();
        if(isMedical||isCampus||isPartnerShip||isVolunteer){
          return;
        }
        if(this.utils.getEmployeeId()){
          return;
        }
        if(this.utils.channel!=='COM'){
          return;
        }
    
        if(this.pagPlay){
          await this.pagPlay.initPromise;
          const pagFile=this.pagPlay.pagFile;
          await new Promise((resolve)=>{
            setTimeout(resolve, 500);
          });
          let width=0;
          if(this.animWidth>0){
            width=this.animWidth;
          }else{
            width=pagFile.width()<this.$refs.pointsbox.offsetWidth?pagFile.width():this.$refs.pointsbox.offsetWidth;
          }
          this.pagPlay.setVideoSize({
            width
          });
          // 增加积分
          this.height=pagFile.height();
          await this.pagPlay.play((openPagFile)=>{
            const textDoc=openPagFile.getTextData(2);
            textDoc.text = this.pointsText;
            openPagFile.replaceText(2, textDoc);
            // 增加积分的原因
            const pointsTypeTextDoc=openPagFile.getTextData(0);
            pointsTypeTextDoc.text=this.pointsTypeText;
            openPagFile.replaceText(0, pointsTypeTextDoc);
          });
          this.animationClass='animation-enter';
          setTimeout(() => {
            this.animationClass='animation-out';
          }, 3700);
          await this.pagPlay.playtoEnd();
          this.$emit('close');
        }
      }
    }
  }
};

