
  import look from './group.vue';
  export default {
    components: {
      look
    },
    props: {
      lookGroupList: {
        type: Array,
        default: () => {
          return [];
        }
      },
      franchise: {
        type: String,
        default:""
      },
      isMobile: {
        type: Boolean,
        default: false
      },
      position: {
        type: String,
        default: ''
      },
      code: {
        type: String,
        default: ''
      },
      tabName: {
        type: String,
        default: ''
      },
      clpShow: {
        type: Boolean
      }
    },
    data() {
      return {
        activeIndex: 0,
        sliderWidth: 90/this.lookGroupList.length,
        swiperOption: {
          freeMode: true,
          spaceBetween: 20,
          slidesPerView: 'auto',
          on: {
            slideChange: () => {
              console.log("realIndex",this.swiper.realIndex);
              this.activeIndex = this.swiper.realIndex;
            },
            progress: (progress) => {
              this.swiperLeft = progress;
              this.cssText = this.$refs.swiperWrapper.style.cssText;
              const snapGridLen = this.swiper.snapGrid.length;
              const lastSnapLeft = Math.abs(this.swiper.snapGrid[snapGridLen - 1]);
              this.swiperScrollWidth = lastSnapLeft;
            }
          }
        },
        swiperLeft: 0,
        cssText: '',
        swiperScrollWidth: 0
      };
    },
    computed: {
      computedSliderStyle() {
        if (this.swiperScrollWidth) {
          // 以下这坨就是为了拿swiper-wrapper上的style 然后取transform: translate3d(x,y,z)中的x值作为滑动的距离，只有这种办法才可以滑动到最后有回弹效果
          const transformFirstCss = this.cssText.split(';')[0];
          const transformFirstCssValArray = transformFirstCss.split('translate3d(');
          const transformFirstCssValArrayLen = transformFirstCssValArray.length;
          const transformFirstCssVal = transformFirstCssValArray[transformFirstCssValArrayLen - 1];
          const XDistance = transformFirstCssVal.split(',')[0] || '0px';
          const progress = Math.abs(parseFloat(XDistance) / this.swiperScrollWidth);
          const sliderWidth = 90/this.lookGroupList.length;
          const disLeft = (90 - sliderWidth) * progress;
          return {
            width: sliderWidth + 'px',
            transform: `translateX(${disLeft}px)`
          };
        } else {
          return {
            width: this.sliderWidth + 'px',
            transform: `translateX(${0}px)`
          };
        }
      },
      swiper() {
        return this.$refs?.mySwiper?.swiper || null;
      }
    },
    watch: {
      lookGroupList(newValue, oldValue) {
        this.$nextTick(()=>{
          this.swiper.params.freeMode = true;
          this.swiper.params.slidesPerView = 'auto';
          this.swiper.update();
        });
      },
      activeIndex(newValue, oldValue) {
        this.$nextTick(() => {
          this.$emit('changeSwiper', newValue);
        });
      },
      clpShow(newValue, oldValue) {
        this.$nextTick(()=>{
          this.swiper.params.freeMode = true;
          this.swiper.params.slidesPerView = 'auto';
          this.swiper.update();
        });
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.swiper.params.freeMode = true;
        this.swiper.params.slidesPerView = 'auto';
        this.swiper.params.scrollbar.el = this.$refs.scrollbar;
        this.swiper.scrollbar.init();
        this.swiper.update();
      });
    }
  };
