
import { LibProductCard, LibButton } from '@adidas-com-front/ui-library';
import { trackClpPdpClick, getScrollDepth } from '../../../clp/track/index';

export default {
  name: 'ProductList',
  components: { LibProductCard, LibButton },
  props: {
    info: {
      type: Object
    },
    products: {
      type: Array,
      default: () => []
    },
    articles: {
      type: Array,
      default: () => []
    },
    type: {
      type: String
    },
    tabName: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeBanner: 0,
      swiperProgress: 0,
      slidePerView: 0,
      swiperOption: {
        watchOverflow: true,
        simulateTouch: false,
        spaceBetween: 2,
        slidesPerView: 3,
        breakpoints: {
          719: {
            freeMode: true,
            spaceBetween: 2,
            slidesPerView: 3,
            slidesPerGroup: 1
          }
        },
        navigation: {
          nextEl: '.navigation-right',
          prevEl: '.navigation-left'
        },
        on: {
          slideChange: () => {
            this.swiperProgress = this.swiper.progress;
            this.activeBanner = this.swiper.activeIndex;
          }
        }
      },
      device: 'pc',
      benefitDescriptionHeight: '',
      swiperNavigationTop: '0px',
      productListData: [],
      articleList: [],
      isMobile: false
    };
  },
  computed: {
    autoLineSalePrice(){
      if(this.isMobile){
        return 7;
      }else{
        return 0;
      }
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    list() {
      // const articleList = this.articleList.map((item) => {
      //   delete item.subTitle;
      //   return item;
      // });
      return this.type === 'PLP'
        ? this.products.slice(0, this.info.pageSize + 1)
        :  this.products.slice(0, 11);
    }
  },
  mounted() {
    // this.getArticles();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    // async getArticles() {
    //   const _data = await this.searchArticleList({
    //     articleIdList: this.articles.join(',')
    //   });
    //   this.articleList = _data;
    // },
    // async searchArticleList(params) {
    //   if (!params.articleIdList) return [];
    //   try {
    //     return await this.$axios.get(
    //       `${this.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/batch-search`,
    //       { params: { ...params } }
    //     );
    //   } catch (e) {
    //     if (e.data?.failed) {
    //       return undefined;
    //     }
    //   }
    // },
    sensorClickTrack(item,index) {
      // 判断当前父组件做埋点
      const parent = this.$parent.transmit();
      if (parent === 'CLP') {
        const data = {
          campaign_name: this.$route.query.contentId || '',
          sub_campaign_name: this.code,
          text: '',
          component_code: 'Look',
          position_code: Number(this.position.slice(1)),
          article_position: index + 1 + 1,
          tab_name: this.tabName,
          recommend_id: '',
          scroll_depth: getScrollDepth(this.$refs.lookListRef.offsetTop),
          category_code: this.categoryCode || '',
          article_number: item.articleId,
          brand: item.brandName,
          category: item.plpType || '',
          gender: item.genderShow,
          tag: this.info.labelInfoList.join(',')
        };

        trackClpPdpClick(data);
      } else {
        const link = `/pdp?articleId=${item.articleId}`;
        this.$emit('sensorTrack', 'Image', { ...item, photoLink: link });
      }
    },
    handleResize() {
      let slidePerView;
      this.slidePerView = slidePerView;
      this.isMobile = window.innerWidth < 720;
      if (!document.getElementsByClassName('product-list')[0]) return;
      // 设置完轮播图属性 动态获取的图片高度确定导航的绝对位置
      setTimeout(() => {
        const imgDom = document.getElementsByClassName('product-list')[0];
        if (!imgDom) return;
        this.swiperNavigationTop =
          ((imgDom.clientWidth - 4) / 3 + 72) / 2 - 16 + 'px';
      }, 0);
    }
  }
};
