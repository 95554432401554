
import { DayJs, LibFormatter } from '@adidas-com-front/ui-library/src/components/atoms/LibFormat/LibFormat.utils';
import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import deepcopy from 'deepcopy';
import jwt from 'jsonwebtoken';
import { parseURL } from '../../utilities/helper/urlHelper';
import {getClpChildData } from './child';
import { Scroll, surveySetIsSign } from './survey/track';
import look from '~/components/clp/look';
import LookGroup from '~/components/clp/look-group';
import textLink from '~/components/clp/text-link/TextLink.vue';
import cta from '~/components/clp/cta/Cta.vue';
import clpSingleImage from '~/components/clp/clpSingleImage';
import multiImage from '~/components/clp/multi-image';
import clpVideo from '~/components/clp/video';
import productList from '~/components/clp/product-list';
import tabPage from '~/components/clp/tab-page';
import tabAnchor from '~/components/clp/tab-anchor';
import searchKeyword from '~/components/clp/searchKeyword';
import NBanner from '~/components/clp/n-banner';
import VerticalTabPage from '~/components/clp/verticalTabPage';
import PPlp from '~/components/clp/pplp';
import Survey from '~/components/clp/survey';
import imageSlideProduct from '~/components/clp/image-slide-product';
import { getUrlQuery } from '~/utilities/helper/urlHelper';
import { trackClpView, trackClpLabelTabView, getScrollDepth, trackClpButtonClick, setIsSign } from '~/components/clp/track/index';
import { getDistinctIds , getPlatform } from '~/utilities/vendors/sensors';
import couponPanel from '~/components/common/promo-redemption';
import appDownload from '~/components/common/app-download';
import {BasicUtils} from '~/utilities/basic/utils';
import { AppLogin } from "~/utilities/basic/appLogin";
import { TokenUtils } from '~/utilities';

let myAccountTrack;
if (process.client) {
  myAccountTrack = require('~/pages/index/track/index.ts');
}
const getClpLayer = (ctx, contentId, renderSide) => {
  try {
    const url = `${ctx.$config.S3_HOST}/f04/page_conf/COM/zh_CN/${
      renderSide === 'server' ? 'online' : 'stage'
    }/0/${contentId}.json`;
    return ctx.$axios.get(url);
  } catch (e) {
    console.log('getClpLayer error', e);
  }
};

const getPathConfig = (ctx) => {
  try {
    const url = `${ctx.$config.S3_HOST}/com/pathConfig.json`;
    return ctx.$axios.get(url);
  } catch (e) {
    console.log('getPathConfig error', e);
  }
};

export const searchPlp = (options, ctx, renderSide) => {
  const { keyword, categoryCode } = getUrlQuery(options.link);
  const params = {
    page: 1,
    abTest: 'A',
    pageSize: options.pageSize,
    categoryCode,
    keyword: decodeURI(keyword)
  };
  if (!keyword && keyword !== '0') {
    delete params.keyword;
  }
  if (categoryCode === '/search') {
    delete params.categoryCode;
  }
  return new Promise((resolve, reject) => {
    ctx.$axios
      .get(
        `${ctx.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/search`,
        { params: { ...params, preview: renderSide === 'client' ? 0 : null } }
      )
      .then((res) => {
        if (!res.content) res.content = [];
        resolve(res);
      })
      .catch((e) => {
        resolve({});
      });
  });
};


const saveSeoInfo = (data, ctx) => {
  const { seoDescription, seoKeyword, seoNoFollow, seoTitle } = data;
  const seoInfo = { seoDescription, seoKeyword, seoNoFollow, seoTitle };
  (ctx.store || ctx.$store).commit('clp/SET_SEO_INFO', seoInfo);
};

export const getClpData = async (ctx, contentId, renderSide,dataValue) => {
  try {
    // 获取小程序跳转方式
    const pathConfig = await getPathConfig(ctx);

    // 获取组件
    let clpData;
    if(dataValue){
      clpData=dataValue;
    }else{
      clpData=await getClpLayer(ctx, contentId, renderSide);
    }
    // clpData = filterClpDataByTerminal(clpData, ctx);
    // 存储seo信息
    saveSeoInfo(clpData, ctx);
    const {data,componentsData,feedProductList}=await getClpChildData(ctx, contentId, renderSide,false,clpData);
    (ctx.store || ctx.$store).commit('clp/SET_COMPONENTS_DATA', filterCompDataByTerminal(ctx, componentsData));
    (ctx.store || ctx.$store).commit('clp/SET_FEED_PRODUCT', feedProductList);
    (ctx.store || ctx.$store).commit('clp/SET_PATH_CONFIG', pathConfig);
    return {componentsData, data};
  } catch (e) {
    console.log(e);
    return {};
  }
};
// 该函数为getClpData复制版本，只是去掉了set componentsData store，为了解决clp页面缓存问题
export const getClpDataInPage = async (ctx, contentId, renderSide,dataValue) => {
  try {
    // 获取小程序跳转方式
    const pathConfig = await getPathConfig(ctx);

    // 获取组件
    let clpData;
    if(dataValue){
      clpData=dataValue;
    }else{
      clpData=await getClpLayer(ctx, contentId, renderSide);
    }
    // clpData = filterClpDataByTerminal(clpData, ctx);
    // 存储seo信息
    saveSeoInfo(clpData, ctx);
    const {data,componentsData,feedProductList}=await getClpChildData(ctx, contentId, renderSide,false,clpData);
    // (ctx.store || ctx.$store).commit('clp/SET_COMPONENTS_DATA', filterCompDataByTerminal(ctx, componentsData));
    (ctx.store || ctx.$store).commit('clp/SET_FEED_PRODUCT', feedProductList);
    (ctx.store || ctx.$store).commit('clp/SET_PATH_CONFIG', pathConfig);
    return {componentsData, data};
  } catch (e) {
    console.log(e);
    return {};
  }
};

// by 渠道filter组件
const filterCompDataByTerminal = (ctx,componentsData) => {
  const copyComponentsData = deepcopy(componentsData);
  const hasACPath = ctx.app?.router?.history.current.path.toLowerCase().includes('/ac/') || ctx.$route?.path.toLowerCase().includes('/ac/');
  const hasOrPath = ctx.app?.router?.history.current.path.toLowerCase().includes('/or/') || ctx.$route?.path.toLowerCase().includes('/or/');
  const shopCode = ctx.app?.router?.history.current.query.shopCode || ctx.$route?.query.shopCode;
  const ua = ctx.req?.headers['user-agent'].toLowerCase() || window?.navigator.userAgent.toLowerCase();
  const isCom = !ua.includes('miniprogram') && !ua.includes('adidasapp') && !hasOrPath && !shopCode && !hasACPath;
  // eslint-disable-next-line array-callback-return
  const newComponentsData = copyComponentsData.filter((item) => {
    const options = item.options;
    const terminal = options.terminal.toLowerCase().split(',');
    if (terminal.includes('or') && hasOrPath && shopCode) {
      return item;
    }
    if (terminal.includes('adiclub') && hasACPath) {
      return item;
    }
    if (ua.includes('miniprogram') && terminal.includes('wms') && !hasOrPath && !shopCode && !hasACPath) {
      return item;
    }
    if (ua.includes('adidasapp') && terminal.includes('bosapp')) {
      return item;
    }
    if ((terminal.includes('mobile') || terminal.includes('pc')) && isCom)  {
      return item;
    }
  });
  return newComponentsData;
};

export default {
  name: 'CLP',
  components: {
    textLink,
    cta,
    look,
    LookGroup,
    clpSingleImage,
    multiImage,
    clpVideo,
    productList,
    tabPage,
    tabAnchor,
    searchKeyword,
    NBanner,
    PPlp,
    Survey,
    couponPanel,
    VerticalTabPage,
    imageSlideProduct,
    appDownload
  },
  props:{
    clpData:{
      type: Object
      /**
       * data: clp数据经过getClpData 处理
       * contentId 活动id
       * preview url中 preview参数
       * appType url中 appType参数
       * hasState 是否有缓存
       */
    },
    franchise: {
      type: String
    },
    refreshSensorsSurveyResultView: {
      type: Boolean
    },
    showHeader:{
      type: Boolean,
      default: false
    }
  },
  data() {
    const dataPara={
        pageScrollTop: 0,
        preview:this.clpData.preview,
        contentId:this.clpData.contentId,
        // 领券半窗展示
        show: false,
        // 是否仍有未领取的优惠券
        stillContainCoupon: false,
        // 是否关闭半窗flag
        closeLoginPanel: false,
        // 是否显示app下载半窗
        isShowAppDownload: false,
        tabPageSelected: '',
        name: '',
        appDownloadType: ''
    };
    if(this.clpData.hasState){
        return dataPara;
      }
    try {
      // 有缓存时不请求

      let data ;
      if (this.clpData.preview !== '1') {
        data = this.clpData.data;
      } else {
        return dataPara;
      }
      let appId="";
      if(this.clpData.appType=== '1'){
        appId = '1413676670';
      }else if( this.appType=== '2'){
        appId='1525190009';
      }
      return {
        ...dataPara,
        seoData: {
          keyword: data.seoKeyword,
          description: data.seoDescription,
          title: data.seoTitle,
          appId
        },
        scroll:null,
        show: false,
        stillContainCoupon: false,
        closeLoginPanel: false,
        isShowAppDownload: false,
        name: '',
        appDownloadType: ''
      };
    } catch (e) {
      console.log("data catch",e);
      (this.store || this.$store).commit('clp/SET_CACHE_PAGE_ID', '');
    }
  },
  computed: {
    ...mapState('clp', [
      'componentsData',
      'feedProductList',
      'hasPreviewPermission',
      'seoInfo'
    ]),
    ...mapState('cart', [
      'couponData'
    ]),
    ...mapState('myAccount', ['isLogin', 'isEmployeeLogin']),
    isNonmemberLogin() {
      return !!this.$store.state.myAccount.isNonmemberLogin;
    },
    categoryCode() {
      return function(options) {
        if(options.maintainType === 'PLP') {
          const {categoryCode = ''} = getUrlQuery(options.link);
          return categoryCode;
        }
        return '';
      };
    },
    isShowPanel() {
      return this.show || this.stillContainCoupon || this.closeLoginPanel;
    }
  },
  watch: {
    isLogin: {
      handler(nVal, oVal) {
        if (nVal) {
          // 登陆之后重新获取last reply result
          this.$emit('refreshLastResult', true);
        }
      }
    },
    isShowPanel(newValue) {
      if (newValue) {
        this.trackCouponView();
      }
    },
    refreshSensorsSurveyResultView: {
      handler(nVal) {
        if (nVal) {
          try {
            console.log('重新sensors survey result view');
            // 重新sensors survey result view
            if(!this.scroll){
              this.scroll=new Scroll(this.$refs.clpContainer,this.contentId);
            }
            this.scroll.scroll();
          } catch (error) {
          }
        }
      }
    }
  },
  async mounted() {
    let data = {};
    this.$nextTick(async () => {
      const platform = this.getPlatform();
      // eslint-disable-next-line camelcase
      const { code_prod, position_prod } = this.getProdInfo();
       // eslint-disable-next-line camelcase
      await new Promise(resolve => {setTimeout(() => {
        resolve();
      }, 500);});
      // eslint-disable-next-line camelcase
      const { component_code_list, position_list, tag_list, sub_campaign_name, Nbanner_type_list,properties } = this.getScrollVisibleComponent();
      data = {
        campaign_name: this.contentId,
        component_code_list,
        position_list,
        scroll_depth: 1,
        tag_list,
        sub_campaign_name,
        Nbanner_type_list,
        properties,
        is_has_header:this.showHeader
      };
      // 这里是需要PIPL渠道列表，即以下平台需要先验证隐私政策
      const noSubmitPlatform = ["WMS", "BOS APP", "ADICLUB", "OR"];
      // 神策部分：先去查询渠道是否有PIPL限制，有的话需要先查隐私政策签订状态
      if (noSubmitPlatform.includes(platform)) {
        const isSignPrivacyTc = await this.whetherSignPrivacyAsync();
        // clp主页通用部分
        setIsSign(isSignPrivacyTc);
        // clp 中survey组件独有神策部分
        surveySetIsSign(isSignPrivacyTc);
        if(!this.scroll){
          this.scroll=new Scroll(this.$refs.clpContainer,this.contentId);
        }
        if (isSignPrivacyTc) {
          trackClpView(data);
          this.handleProductListTrackView(code_prod, position_prod);
          // 这里延迟3s待子组件渲染完调用clp中survey组件的初始化CLP_Survey_View事件，下同
          setTimeout(()=>{
            this.scroll.scroll();
          },3000);
        }
      } else {
        setIsSign(true);
        surveySetIsSign(true);
        trackClpView(data);
        if(!this.scroll){
          this.scroll=new Scroll(this.$refs.clpContainer,this.contentId);
        }
        setTimeout(()=>{
          this.scroll.scroll();
        },3000);
        this.handleProductListTrackView(code_prod, position_prod);
      }
    });
    const currentContentId = this.contentId;
    // contentId变化后应该将缓存的clp页面滚动条位置重制
    setTimeout(() => {
      if (
        this.$router?._scrollTopCache?.clp
      ) {
        this.$nextTick(() => {
          document.querySelector('#layout-wrapper').scrollTop = this.$router._scrollTopCache.clp;
        });
      }
    }, 500);
    const onScroll = async () => {
      const depth = this.getScreenDepth();
      await new Promise(resolve => {setTimeout(() => {
        resolve();
      }, 500);});
      // eslint-disable-next-line camelcase
      const {component_code_list, position_list, tag_list, sub_campaign_name, Nbanner_type_list,properties} = this.getScrollVisibleComponent();
       // eslint-disable-next-line camelcase
      const {code_prod, position_prod} = this.getProdInfo();
      this.handleProductListTrackView(code_prod, position_prod);
       // eslint-disable-next-line camelcase
      data.component_code_list = component_code_list;
       // eslint-disable-next-line camelcase
      data.position_list = position_list;
       // eslint-disable-next-line camelcase
      data.tag_list = tag_list;
       // eslint-disable-next-line camelcase
      data.sub_campaign_name = sub_campaign_name;
        // eslint-disable-next-line camelcase
      data.Nbanner_type_list = Nbanner_type_list;
      data.properties=properties;
      // eslint-disable-next-line camelcase
      data.is_has_header = this.showHeader;
      // 如果当前视口横跨两屏，则上报两次view
      if(depth.length === 1) {
        data.scroll_depth = depth[0];
        trackClpView(data);
      } else {
        const data1 = {...data, scroll_depth: depth[0]};
        const data2 = {...data, scroll_depth: depth[1]};
        trackClpView(data1);
        trackClpView(data2);
      }
    };

    this.$on(
      'hook:beforeDestroy',
      this.$utils.device.onPageScroll(debounce(onScroll, 300))
    );

    // 数据缓存五分钟
    setTimeout(() => {
      this.$store.commit('clp/SET_CACHE_PAGE_ID', '');
    }, 300000);

    if (this.preview === '1') {
      this.initClp();
    }

    // 判断是否为小程序或者app打开
    // this.handleJump();

    // 有缓存的clp contentId
    this.$store.commit('clp/SET_CACHE_PAGE_ID', currentContentId);
    const isContainSurvey = this.componentsData.find((item) => {return item.code === 'Survey';});
    if (isContainSurvey) {
      await this.getSurveyMemberOnlyConfig();
    }
  },
  methods: {
    handleLooKGroupSwiperChange(position) {
      const pos = position.replace(/[^\d]/g, " ");
      this.handleProductListTrackView(['LookGroup'], [parseInt(pos)]);
    },
    detailList(options){
      return [options];
    },
    async getSurveyMemberOnlyConfig() {
      const defaultConfig = [
          {
              "bannerPath": "https://static-page-sit.adidas.com.cn/assets/clp/survey/banner.png",
              "title": "会员专属内容",
              "subtitle": "加入会员即可解锁趣味玩法/专属活动等精彩内容",
              "ctaText": "立即解锁",
              "startTime": ""
          }
        ];
      try {
        const url = `${this.$config.S3_COM_HOST}/static/clp/memberOnlySurveyConfig.json`;
        const config = await this.$axios.get(url);
        this.$store.commit('clp/SET_SURVEY_MEMBER_ONLY_CONFIG', config);
      } catch (e) {
        this.$store.commit('clp/SET_SURVEY_MEMBER_ONLY_CONFIG', defaultConfig);
        console.log('获取survey memberonly 配置失败', e);
      }
    },
    getPlatform(){
      return getPlatform();
    },
    /**
     * 查询是否签订隐私条款
     * @returns null: 没有签订 true签订了 false 拒绝了
     */
    async whetherSignPrivacyAsync(){
      console.log("whetherSignPrivacyAsync");
      const utils=new BasicUtils(this);
      const isLogin = !!TokenUtils.getToken();
      if (utils.IsAPP && window.AdidasApp) {
        const appLogin = new AppLogin(window.AdidasApp);
        const data = await appLogin.getloginInfoAsync();
        !data ? utils.config.headers['x-client-device-id'] = await getDistinctIds() : '';
      } else if((utils.IsWMS || utils.IsOR || utils.IsAdiclub) && !isLogin) {
        utils.config.headers['x-open-id'] = TokenUtils.getQueryValue('openId');
      }

      const req = await this.$axios.get(`${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/tc/sign-info?tcCode=TAC_PRIVACY`,{
        ...utils.config
      });
      if (!req||req.signFlag === 2) {
        return null;
      } else {
        return req.signFlag === 1;
      }
    },
    initClp() {
      // getClpData(this, this.$route.query.contentId, 'client');
      if (this.isLogin) {
        this.checkPermission();
      } else {
        this.$login({
          show: true,
          on: {
            login: () => {
              if (this.isNonmemberLogin) {
                this.$store.dispatch('myAccount/afterNonmemberLogin', {
                  ctx: this
                });
              } else {
                this.$store.dispatch('myAccount/afterLogin', { ctx: this });
              }
              this.checkPermission();
            }
          }
        });
      }
    },
    async checkPermission() {
      this.$aloading.show({ type: 'indicator' });
      const hasPermission = await this.$axios.get(
        `${this.$config.ECP_API_PRIVATE_HOST}/o2cms/v1/cms/preview/permission-check`
      );
      if (hasPermission.prePermissionFlag) {
        this.$store.commit('clp/SET_PREVIEW_PERMISSION', true);
        const data = await getClpData(this, this.contentId, 'client');
        const contentCode = data.data.contentCode;
        await this.getPreData(contentCode);
      } else {
        this.$router.push('error');
      }

      this.$aloading.hide();
    },
    // 获取clp配置的组件信息 用于sensors事件上报
    getClpComponentInfo() {
      const isMobile = window.innerWidth < 720;
      const positionList = [];
      const componentCodeList = [];

      this.componentsData?.map(item => {
        if(isMobile && item.viewport?.includes('mobile') || !isMobile && item.viewport?.includes('pc')) {
          positionList.push(item.position.slice(1));
          componentCodeList.push(item.code);
        };

        return false;
      });

      return {
        component_code_list: componentCodeList.join('|'),
        position_list: positionList.join('|')
      };
    },

    // 计算用户当前视口为第几屏 返回list
    getScreenDepth() {
      const { scrollTop } = this.$utils.device.getScrollTop();
      const viewHeight = window.innerHeight;
      // 向下取整
      const preView = Math.floor(scrollTop/viewHeight) + 1;
      // 向上取整
      const nextView = Math.ceil(scrollTop/viewHeight) + 1;

      return preView === nextView ? [preView] : [preView,nextView];
    },

    trackEvent(tabInfo) {
      const {code, options = {}, maintainType, position = ''} = this.getComponentByCode(tabInfo.activeAnchor);
      const articleIdList = options.products && options.products.length > 0
      && options.products.map(item => {
        return item.articleId;
      });
      const {categoryCode, keyword} = getUrlQuery(options.link);
      const currentElement = document.querySelector(`[tab-anchor="${position}"]`);
      const recommendId = options.recommendId || '';
      if(code === 'ProductList') {
        const data = {
          campaign_name: this.contentId,
          text: tabInfo.activeTabName,
          category_code: maintainType === 'PLP' ? categoryCode || keyword : '',
          component_code: code,
          position_code: Number(position.slice(1)),
          tab_name: tabInfo.activeTabName,
          recommend_id: recommendId,
          scroll_depth: getScrollDepth(currentElement.offsetTop),
          article_id_list: articleIdList.length ? articleIdList.join('|') : ''
        };

        trackClpLabelTabView(data);
      }
    },

    // 根据positionCode 返回当前组件的componentCode
    getComponentByCode(positionCode) {
      const { componentsData } = this;

      const component = componentsData.filter(item => {
        return item.position === positionCode;
      });

      return component[0] || {};
    },
    // 获取视口范围内的组件
    getScrollVisibleComponent() {
      const windowHeight = window.innerHeight - 120;
      const compContainer = document.getElementsByClassName(
        `clp-components-container`
      );
      const codeArr = [];
      const positionArr = [];
      const labelInfoArr = [];
      const nbannerTypeArr = [];
      //  这个是自定义属性, 后续会调用显示区域内的组件的getClpView方法,传入这个参数并被修改,最终会被提交到sensors
      const properties={
        is_has_countdown:false
      };
      const children = compContainer[0]?.children || [];
      for (const element of children) {
        const curTop = element.getBoundingClientRect().top;
        const curBottom = curTop + element.offsetHeight;
        const componentCur = this.componentsData.find((comp) => {
          return comp.position === element.dataset.position;
        });
        if (element.dataset.code === 'TabAnchor') {
          const tabAnchorChildren =element.children;

          // eslint-disable-next-line camelcase
          const tabAnchorChildren_len = tabAnchorChildren.length;
          // eslint-disable-next-line camelcase
          tabAnchorChildren[tabAnchorChildren_len - 1].style.transform === 'translateY(0px)' ? codeArr.push(element.dataset.code) : '' ;
          // eslint-disable-next-line camelcase
          tabAnchorChildren[tabAnchorChildren_len - 1].style.transform === 'translateY(0px)' ? positionArr.push(+(element.dataset.position.replace(/[^0-9]/ig, ''))) : '' ;
          // eslint-disable-next-line camelcase
          tabAnchorChildren[tabAnchorChildren_len - 1].style.transform === 'translateY(0px)' ? labelInfoArr.push([componentCur.options.labelInfoList.toString()]) : '' ;
        }
        if ((curTop > 0 && curTop < windowHeight) || (curBottom < windowHeight && curBottom > 0) || (curTop < 0 && curBottom > 0)) {
          codeArr.push(element.dataset.code);
          positionArr.push(+(element.dataset.position.replace(/[^0-9]/ig, '')));
          labelInfoArr.push(`[${componentCur.options.labelInfoList}]`);
          const components=(this.$refs.components?.length)?this.$refs.components:(this.$refs.components ? [this.$refs.components] : []);
          for(const ele of components){
            if(ele.position=== element.dataset.position ){
              if(ele?.getClpView){
                try{
                  ele.getClpView(properties);
                }catch(e){
                  console.error('getClpView',e);
                }
              }
            }

          }
          element.dataset.code === 'NBanner' ? nbannerTypeArr.push(element.dataset.nbannertype) : '';
        }
      }
      const tabPageIdx = codeArr.findIndex((item) => {
        return item === 'TabPage';
      });
      let subCampaignName = '';
      if (tabPageIdx !== -1) {
        const tabPagePos = positionArr[tabPageIdx];
        const tabPageComp = this.componentsData.find((comp) => {
          return comp.position === `a${tabPagePos}`;
        });
        const contentIdSelected = this.tabPageSelected || tabPageComp?.options.itemList[0]?.clpData?.contentId || '';
        subCampaignName = tabPageComp?.options.content === 'clp' ? contentIdSelected : '';
        this.tabPageSelected = subCampaignName;
      } else {
        subCampaignName = '';
        this.tabPageSelected = '';
      }
      return {properties, component_code_list: codeArr.join('|'), position_list: positionArr.join('|'), tag_list: labelInfoArr.join(','), sub_campaign_name: subCampaignName, Nbanner_type_list: nbannerTypeArr.join('|')};
    },
    // 切换tabPage track clp
    handleTabPageTrack(text, position) {
      this.tabPageSelected = text;
      this.handleTabPageTrackView(text);
      this.handleTabPageTrackButtonClick(text, position);
    },
    // 切换 tabPage track clp view
    handleTabPageTrackView(text) {
      const depth = this.getScreenDepth();
      // eslint-disable-next-line camelcase
      const {component_code_list, position_list, tag_list, sub_campaign_name, Nbanner_type_list,properties} = this.getScrollVisibleComponent();
      const data = {
        campaign_name: this.contentId,
        component_code_list,
        position_list,
        scroll_depth: 1,
        tag_list,
        properties,
        // eslint-disable-next-line camelcase
        sub_campaign_name: text || sub_campaign_name,
        Nbanner_type_list,
        is_has_header: this.showHeader
      };

      // 如果当前视口横跨两屏，则上报两次view
      if(depth.length === 1) {
        data.scroll_depth = depth[0];
        trackClpView(data);
      } else {
        const data2 = {...data, scroll_depth: depth[1]};
        trackClpView(data2);
      }
    },
    // 切换 tabPage track clp button click
    handleTabPageTrackButtonClick(text, position) {
      const depth = this.getScreenDepth();
      const item =  this.componentsData.find(comp => {
        return comp.position === position;
      });
      console.log('item>>>>', item, position);
      const data = {
        campaign_name: this.contentId,
        sub_campaign_name: item.options.content === 'clp' ? text: '',
        text: '',
        component_code: 'TabPage',
        position_code: +(position.replace(/[^0-9]/ig, '')),
        scroll_depth: depth.length ? depth.length > 1 ? depth[1] : depth[0] : 1,
        to_url: '',
        button_name: text,
        tab_name: text,
        tag: item.options.labelInfoList.join(',')
      };
      trackClpButtonClick(data);
    },
    // clp productList view
    handleProductListTrackView(codeList, positionList, currentIndex = 0) {
      const depth = this.getScreenDepth();
      // eslint-disable-next-line camelcase
      const comp_list = [];
      // eslint-disable-next-line camelcase
      const product_list = [];
      let isTabPageContainClp = false;
      positionList.forEach(position => {
        const item =  this.componentsData.find(comp => {
          return comp.position === `a${position}`;
        });
        comp_list.push(item);
      });
      const productlistDate=[];
      comp_list.forEach(it => {
        if (it.code === 'TabPage' && it.options.content === 'clp') {
          isTabPageContainClp = true;
          const productList = it.options.itemList[currentIndex].clpData.componentsData.filter(childComp => {
            return childComp.code === 'ProductList';
          });
          productList.forEach((prod) => {
            prod.tab_name = it.options.itemList[currentIndex].tabText;
          });
          // product_list = productList;
          productList.forEach((product) => {
            product_list.push(product);
          });
          if(this.$refs?.TabPage){
            const tabPages=(this.$refs?.TabPage?.length)?[...this.$refs?.TabPage]:[this.$refs?.TabPage];
            for(const tabPage of tabPages){
              if(tabPage.position===it.position){
                const productList=tabPage.getProductList();
                productList&&productlistDate.push(...productList);
              }
            }
          }

        } else if (it.code === 'ProductList') {
          isTabPageContainClp = false;
          product_list.push(it);
        } else if (it.code === 'TabPage' && it.options.content === 'product_list') {
          isTabPageContainClp = false;
          const options = it.options.itemList[currentIndex];
          const products = options.products;
          const position = it.position;
          const tabText = options.tabText;
          const code = it.code;
          const link = options.link;
          const articleList = options.articleList;
          let categoryCode = '';
          if (link) {
            categoryCode = parseURL(link).path.substr(4);
          }
          product_list.push({
            code: code,
            position: position,
            options: {
              categoryCode,
              articleList,
              products
            },
            tab_name: tabText
          });
        }
        if(this.$refs.components){
          const components=(this.$refs.components?.length)?this.$refs.components:[this.$refs.components];
          for(const ele of components){
            if(ele.position===it.position){
              if(ele.getProduct){
                try{
                  const product=ele.getProduct();
                  if(product && product instanceof Array){
                    productlistDate.push(...product);
                  }else if(product){
                    productlistDate.push(product);
                  }
                }catch{}
              }
            }
          }
        }
      });
      product_list.forEach((product) => {
        const articleIdList = product?.options?.products?.map((item) => { return item.articleId; }) || [];
        const data = {
          campaign_name: this.contentId,
          sub_campaign_name: isTabPageContainClp ? this.tabPageSelected : '',
          category_code: product.options.fixCategoryCode || product.options.preCategoryCode || product.options.categoryCode || '',
          component_code: product.code,
          position_code: +(product.position.replace(/[^0-9]/ig, '')),
          tab_name: product.tab_name || '',
          recommend_id: product.options.recommendId || '',
          scroll_depth: depth.length ? depth.length > 1 ? depth[1] : depth[0] : 1,
          article_id_list: articleIdList.join('|'),
          tag_list: `[${product.options.labelInfoList}]`
        };
        // console.log('>>>>data<<<<', data);
        trackClpLabelTabView(data);
      });
      for(const productData of productlistDate){
        console.log("trackClpLabelTabView", productData);
        trackClpLabelTabView(productData);
      }
    },
    getProdInfo() {
      const windowHeight = window.innerHeight;
      const compContainer = document.getElementsByClassName(
        `clp-components-container`
      );
      const codeArr = [];
      const positionArr = [];
      const children = compContainer[0]?.children || [];
      for (let i = 0; i < children.length; i++) {
        const curTop = children[i].getBoundingClientRect().top;
        const curBottom = curTop + children[i].offsetHeight;
        const codeList=['ProductList','TabPage','VerticalTabPage',"ImageSlideProduct", "LookGroup"];
        if ((curTop > 0 && curTop < windowHeight && (codeList.includes( children[i].dataset.code ))) || (curBottom < windowHeight && curBottom > 0 && (children[i].dataset.code === 'ProductList' || children[i].dataset.code === 'TabPage'))) {
          codeArr.push(children[i].dataset.code);
          positionArr.push(+(children[i].dataset.position.replace(/[^0-9]/ig, '')));
        }
      }
      // console.log('code&position', codeArr, positionArr);
      return { code_prod: codeArr, position_prod: positionArr };
    },
    getMorePplpList(params) {
      this.$emit('getMoreList', { page: params.page });
    },
    initSurvey(data){
      if(!this.scroll){
        this.scroll=new Scroll(this.$refs.clpContainer,this.contentId);
      }
      this.scroll.addData(data.detail);
      if (data.lastResult) {
        this.scroll.addLastResult(data.lastResult);
      }
    },
    handleLoginMember() {
      this.$login({
        show: true,
        on: {
          login: async () => {
            await this.$store.dispatch('cart/getCoupon', {ctx: this, ids: []});
            const isContainToBeReceived = this.couponData.find((item) => {
              return item.statusCode === 'TO_BE_RECEIVED';
            });
            this.stillContainCoupon = !!isContainToBeReceived;
            if (!isContainToBeReceived) {
              this.$Toast({ message: '您已领取所有有效优惠券' });
            }
            this.$store.commit('myAccount/SET_SKELETON_STATUS', true);
            // 更新会员状态
            this.$store.dispatch('myAccount/updateLoginStatus');
            // 更新员工状态
            this.$store.dispatch('myAccount/updateEmployeeLoginStatus');
            // 更新非会员状态
            this.$store.dispatch('myAccount/updateNonmemberLoginStatus');

            await this.$store.dispatch('myAccount/afterLogin', { ctx: this });
            this.$store.commit('myAccount/SET_SKELETON_STATUS', false);
          },
          close: () => {
          }
        },
        joinAdiclubProp: true
      });
    },
    async refreshCouponData() {
      await this.$store.dispatch('cart/getCoupon', {ctx: this, ids: []});
      if (!this.couponData.length) {
        this.show = false;
        this.stillContainCoupon = false;
        this.closeLoginPanel = false;
      }
    },
    async handleClosePanel() {
      this.show = false;
      await this.$store.dispatch('cart/getCoupon', {ctx: this, ids: []});
      this.stillContainCoupon = false;
      this.closeLoginPanel = false;
    },
    handleCloseAppDownload() {
      this.isShowAppDownload = false;
    },
    async handleOpenCouponPanel() {
      const hasOrPath = this.$route?.path.toLowerCase().includes('/or/');
      const shopCode = this.$route?.query.shopCode;
      const ua = window?.navigator.userAgent.toLowerCase();
      const isCom = !ua.includes('miniprogram') && !ua.includes('adidasapp') && !hasOrPath && !shopCode;
      if (isCom && !this.isEmployeeLogin) {
        await this.$store.dispatch('cart/getCoupon', {ctx: this, ids: []});
        if(this.couponData.length) {
          this.show = true;
        } else {
          this.$Toast({ message: '您已领取所有有效优惠券' });
        }
      } else if (this.isEmployeeLogin) {
        this.$Toast({ message: '暂无有效优惠券可领取' });
      }
      // console.log("领券埋点");
      // this.$emit('clickGetCoupon','领券');
    },
    handleSpecialLinkClick(link){

      if (link === '领券') {
        this.handleOpenCouponPanel();
      } else if (link === '会员登录') {
        this.handleMemberLogin();
      } else if (link === 'action://TOBOS') {
        this.handleRedirectToApp('bos');
      } else if (link === 'action://TOCFM') {
        this.handleRedirectToApp('cfm');
      }
    },
    handleRedirectToApp(type) {
      if (type === 'bos') {
        this.name = "adidas APP";
      } else {
        this.name = "Confirmed APP";
      }
      this.appDownloadType = type;
      this.isShowAppDownload = true;
    },
    handleMemberLogin(){
      const utils = new BasicUtils(this);
      if (utils.isMemberLogin()) {
        this.$Toast({message: "您已登录为会员"});
      } else if (utils.getEmployeeId()) {
        this.$Toast({message: "员工账号，请退出重新登录"});
      } else {
         utils.loginByEnvAsync({
           redirectUrl: location.href,
           memberOnly: true,
           loginClp: true
        });
      }
    },
    trackCouponView() {
      const couponAmount = [];
      const couponId = [];
      const couponName = [];
      const cuponList = this.couponData;
      const countDownList = [];
      let canGetInFourtyEightHours = false;
      const today = DayJs(new Date());
      cuponList.forEach((item) => {
        couponAmount.push(item.discount || item.reduction);
        couponId.push(item.promoCodeId);
        couponName.push(item.promoCodeName);
        const { days } = LibFormatter.calcInterval(DayJs(item.endTime).toDate().getTime(), today.toDate().getTime());
        const itemCanGetInFourtyEightHours = days<2 && DayJs(item.startTime) < today;
        canGetInFourtyEightHours = itemCanGetInFourtyEightHours || canGetInFourtyEightHours;
        if (item.statusCode === "TO_BE_RECEIVED" && itemCanGetInFourtyEightHours) {
          const ItemCountDown = LibFormatter.countdown(item.endTime);
          countDownList.push(ItemCountDown);
        }
      });
      console.log("countDownList",countDownList);
      const obj = {
        couponamount: `[${couponAmount.toString()}]`,
        couponid: `[${couponId.toString()}]`,
        couponname: `[${couponName.toString()}]`,
        coupontype: '优惠码',
        pageSource: 'CLP',
        isHasCountdownCoupon: canGetInFourtyEightHours,
        countdownTimeList: `[${countDownList.toString()}]`
      };
      try {
        myAccountTrack.CouponView(obj);
      } catch (e) {
        console.warn('trackCouponView', e);
      }
    },
    async getPreData(contentCode) {
      // 商品列表pre
      const recommendProdList = {};
      // tabPage pre
      const tabPagePreList = [];
      // pplp 数据
      let pplpListData = [];
      const componentsData = deepcopy(this.componentsData);
      // 是否有pplp
      const isContainPplp = !!componentsData.find((item) => { return item.code === 'PplpCom'; });
      if (isContainPplp) {
        pplpListData = await this.getPplpList({page: 1, pageSize: 24, type: 'CLP'});
      }
      componentsData?.forEach((el, index) => {
        const _key = el.code;
        const _position = +(el.position.replace(/[^0-9]/ig, ''));
        const _value = el.options;
        if (['ProductList'].includes(_key) && _value.preCategoryCode) {
          recommendProdList[_position] = {..._value, code: _key, _position, tabContentCode: el.tabContentCode};
        }
        if(['TabPage'].includes(_key) && _value.content === 'clp') {
          _value.itemList[0].clpData.componentsData?.forEach((c) => {
            c.parentPosition = _position;
            c.options.tabContentCode = _value.itemList[0].tabContentCode;
            if (c.code === 'ProductList' && c.tabContentCode) {
              tabPagePreList.push(c);
            }
          });
        }
        if (_key === 'PplpCom') {
          _value.listData = pplpListData;
        }
      });
      // 商品列表 请求集合
      const prePromises = [];
      // tabPage 子clp 请求集合
      const tabPagePromises = [];
      // tabPage 子clp 位置信息
      const tabPagePos = [];
      for (const el in tabPagePreList) {
        tabPagePos.push({parentPosition: tabPagePreList[el].parentPosition, position: tabPagePreList[el].position});
        tabPagePromises.push(this.getRecommendedPlp(tabPagePreList[el].options, contentCode));
      }
      for (const el of Object.values(recommendProdList)) {
        prePromises.push(this.getRecommendedPlp(el, contentCode));
      }
      // 商品列表 pre返回数据
      const allPreProductData = await Promise.all(prePromises);
      // tabPage 子clp pre返回数据
      const allTabPageProductData = await Promise.all(tabPagePromises);
      // 整合数据 便于找到对应的tabPage
      const allTabPageProductDataObjArr = [];
      // 商品列表 位置信息
      const posData = [];
      // 整合数据 便于找到对应的商品列表
      const allPreProductDataObj = {};
      for (const el of Object.keys(recommendProdList)) {
        posData.push(el);
      }
      for (let i = 0; i < allPreProductData.length; i++) {
        allPreProductDataObj[posData[i]] = allPreProductData[i];
      }
      for(let i = 0; i < allTabPageProductData.length; i++) {
        allTabPageProductDataObjArr.push({...tabPagePos[i], data: allTabPageProductData[i]});
      }
      this.$store.commit('clp/SET_TABPAGE_PRODUCTLIST', allTabPageProductDataObjArr);
      this.$store.commit('clp/SET_PRE_PRODUCTDATA', allPreProductDataObj);
      componentsData?.forEach((el, index) => {
        const _position = +(el.position.replace(/[^0-9]/ig, ''));
        if (el.code === 'ProductList' && el.options.preCategoryCode) {
          el.options.products = allPreProductDataObj[_position].list;
          el.options.flag = allPreProductDataObj[_position].flag;
          el.options.recommendId = allPreProductDataObj[_position].id;
        } else if (el.code === 'TabPage' && el.options.content === 'clp') {
          allTabPageProductDataObjArr.forEach((tabPagePreProd) => {
            if (tabPagePreProd.parentPosition === _position) {
              const comp = el.options.itemList[0].clpData.componentsData.find((data) => {
                return data.position === tabPagePreProd.position;
              });
              comp.options.products = tabPagePreProd.data.list;
              comp.options.recommendId = tabPagePreProd.data.id;
              comp.options.flag = tabPagePreProd.data.flag;
            }
          });
        }
      });
      this.$store.commit('clp/SET_COMPONENTS_DATA', this.filterCompDataByTerminal(componentsData));
      if (contentCode) {
        this.$store.commit('clp/SET_CONTENT_CODE', contentCode);
      }
    },
    async getRecommendedPlp (options, contentCode) {
      const utils=new BasicUtils(this);
      if (utils.IsAPP && window.AdidasApp) {
        const appLogin = new AppLogin(window.AdidasApp);
        const data = await appLogin.getloginInfoAsync();
        utils.config.headers['x-client-device-id'] = data ? jwt.decode(JSON.parse(data).token).crm_member_id : await getDistinctIds();
      } else {
        utils.config.headers['x-client-device-id'] = await getDistinctIds();
      }
      const params = {
        page: 1,
        abTest: 'A',
        pageSize: options.pageSize,
        recommendUrl: 'recommendation/getPersonalizedCampaignListPage',
        categoryCode: options.preCategoryCode,
        clpContentCode: contentCode || this.contentCode,
        tabContentCode: options.tabContentCode
      };
      options.articleList.join(',') ? params.articleList = options.articleList.join(',') : '';
      options.fixCategoryCode ? params.fixCategoryCode = options.fixCategoryCode : '';
      const str = this.getQueryParams(params);
      return new Promise((resolve, reject) => {
        this.$axios
          .get(
            `${this.$config.ECP_API_PRIVATE_HOST}/o2pcm/v1/pub/platform-products/recommend-products?${str}`,
            {
              // params: { ...params },
              ...utils.config
            }
          )
          .then(async (res) => {
            let normalPlpList;
            if (!res.recommendList || !res.recommendList.length) res.recommendList = [];
            if (!res.recommendList.length) {
              const preRes = await this.searchPlpByPre(options);
              normalPlpList = preRes.content || [];
            }
            resolve(res.recommendList.length ? {list: res.recommendList, id: res.recommendId, flag: true} : {list: normalPlpList, id: res.recommendId, flag: false});
          })
          .catch(async(e) => {
            console.log('获取推荐商品列表报错，去调search接口', e);
            const preRes = await this.searchPlpByPre(options);
            const normalPlpList = preRes.content || [];
            resolve(normalPlpList);
          });
      });
    },
    getQueryParams(params) {
      let str = '';
      for(const key in params) {
        str += `${key}=${params[key]}&`;
      }
      str = str.substring(0, str.length-1);
      return str;
    },
    async searchPlpByPre (options) {
      try {
        const utils=new BasicUtils(this);
        const params = {
          page: 1,
          abTest: 'A',
          pageSize: options.pageSize,
          categoryCode: options.preCategoryCode
        };

        const data = await this.$axios.get(
          `${this.$config.ECP_API_PRIVATE_HOST}/o2srh/v1/pub/platform-products/search`,
          { params: { ...params, preview: 0 }, ...utils.config }
        );
        console.log('推荐商品列表为空，使用preCategoryCode调用search接口进行兜底', data, params);
        if (!data.content) data.content = [];
        return data;
      } catch (e) {
        return {content: [], totalPages: 0, totalElements: 0};
      }
    },
    // 获取pplp数据
    async getPplpList(queryParams) {
      const utils=new BasicUtils(this);
      if (utils.IsAPP && window.AdidasApp) {
        const appLogin = new AppLogin(window.AdidasApp);
        const data = await appLogin.getloginInfoAsync();
        utils.config.headers['x-client-device-id'] = data ? jwt.decode(JSON.parse(data).token).crm_member_id : await getDistinctIds();
      } else {
        const isLogin = !!TokenUtils.getToken();
        console.log('是否为wms', utils.IsWMS, isLogin, TokenUtils.getQueryValue('openId'));
        utils.config.headers['x-client-device-id'] = await getDistinctIds();
        if(utils.IsWMS && !isLogin) {
          utils.config.headers['x-open-id'] = TokenUtils.getQueryValue('openId');
        }
      }
      try {
        const res = await this.$axios
          .get(
            `${this.$config.ECP_API_HOST}/o2pcm/v1/pub/personalized-product-list`,
            {
              params: { ...queryParams },
              ...utils.config
            }
          );
        // const res = this.mockData(queryParams.page);
        const listData = [];
        for(let i = 0; i < res.content.length; i++) {
          const item = res.content[i];
          const labelList = item.article?.pplpInfo?.labelList || [];
          const otherProps = {...item.article};
          delete otherProps.pplpInfo;
          const listItem = {
            labelList,
            type: item.type,
            recommendId: res.recommendId,
            ...otherProps
          };
          listData.push(listItem);
        }
        this.$store.commit('clp/SET_PPLP_DATA', {page: queryParams.page, data: listData});
        return listData;
      } catch (error) {
        this.$store.commit('clp/SET_PPLP_DATA', {page: queryParams.page, data: []});
        return [];
      }
    },
    // pplp 下拉获取下一页数据
    async getMoreList(params) {
      // pplp 数据
      let pplpListData = [];
      const componentsData = deepcopy(this.componentsData);
      // 是否有pplp
      const isContainPplp = !!componentsData.find((item) => { return item.code === 'PplpCom'; });
      if (isContainPplp) {
        // pplpListData = await this.getPplpList({page: params.page, pageSize: 24, type: 'CLP'});
        pplpListData = await this.getPplpList({page: this.pplpPage, pageSize: 24, type: 'CLP'});
      }
      componentsData?.forEach((el) => {
        const _key = el.code;
        const _value = el.options;
        if (_key === 'PplpCom') {
          _value.listData = _value.listData.concat(pplpListData);
          console.log('_value.listData', _value.listData);
        }
      });
      this.$store.commit('clp/SET_COMPONENTS_DATA', this.filterCompDataByTerminal(componentsData));
    },
    // by 渠道filter组件
    filterCompDataByTerminal(componentsData) {
      const copyComponentsData = deepcopy(componentsData);
      if(process.server){
        return copyComponentsData;
      }
      const hasOrPath = this.$route?.path.toLowerCase().includes('/or/');
      const hasACPath = this.$route?.path.toLowerCase().includes('/ac/');
      const shopCode = this.$route?.query.shopCode;
      const ua = window?.navigator.userAgent.toLowerCase();
      const isCom = !ua.includes('miniprogram') && !ua.includes('adidasapp') && !hasOrPath && !shopCode && !hasACPath;
      // eslint-disable-next-line array-callback-return
      const newComponentsData = copyComponentsData.filter((item) => {
        const options = item.options;
        const terminal = options.terminal.toLowerCase().split(',');
        if (terminal.includes('or') && hasOrPath && shopCode) {
          return item;
        }
        if (terminal.includes('adiclub') && hasACPath) {
          return item;
        }
        if (ua.includes('miniprogram') && terminal.includes('wms') && !hasOrPath && !shopCode && !hasACPath) {
          return item;
        }
        if (ua.includes('adidasapp') && terminal.includes('bosapp')) {
          return item;
        }
        if ((terminal.includes('mobile') || terminal.includes('pc')) && isCom)  {
          return item;
        }
      });
      return newComponentsData;
    }
  }
};
