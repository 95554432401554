/*
 * @Author: dezhao.zhao@hand-china.com
 * @Date: 2021-10-21 16:54:49
 * @Description: my-account 神策埋点
 */

import { track } from '~/utilities/vendors/sensors';

interface ViewMyAccount {
  // 是否领取成功
  isValid: any;
  // splp且是搜索词打开的搜索关键词
  searchKeyword: string
  // splp中分类编码
  categoryCode: string;
  // 优惠券倒计时列表
  countdownTimeList: string;
  // 是否有48小时即将过期优惠码
  isHasCountdownCoupon: boolean;
  // 会员等级 未登录传递空字符串
  membertier: string;
  // 专属权益
  exclusiveprivilege: number;
  // 可用优惠
  couponnumber: number;
  // 差多少分升级
  pointsupgrade: number;
  // 会员积分值
  pointsnumber: number;
  // 事件发生页面
  pagetitle: 'My Account';
  // 商品总数
  producttotalnumber: number;
  // 商品id拼接字符串
  productlist: string;
  // 货号商品id
  articlenumber: string;
  // 商品名称
  productname: string;
  // 产品展示位置
  productposition: string;
  // 优惠券金额
  couponamount: string;
  // 优惠券id
  couponid: string;
  // 优惠券名称
  couponname: string;
  // 页面显示类型  优惠券 || 优惠码
  // 来源页面： Profile个人中心进入的优惠券界面 || Checkout：checkout点击选择优惠券时进入的界面 || CartList：cart里的领券半窗 || PDP:PDP里领券半窗 || SPLP
  coupontype: string;
  pageSource: string;
  couponTab:string;
}
interface ViewMembershipPoint {
  // 会员等级 未登录传递空字符串
  membertier: string;
  // 来源页面
  pageTitle: string;
  // 会员积分值
  pointsNumber: number;
}
// 浏览我的账户 触发
export function MyAccountView(obj: ViewMyAccount): void {
  track('View_MyAccountview', {
    member_tier: obj.membertier,
    exclusive_privilege: obj.exclusiveprivilege,
    coupon_number: obj.couponnumber,
    points_upgrade: obj.pointsupgrade,
    points_number: obj.pointsnumber
  });
}
// 浏览最近足迹 触发 adidas-com-front/packages/nuxt/components/my-account/member/foot-print
export function RecentView(obj: ViewMyAccount): void {
  track('Recent_View', {
    page_title: obj.pagetitle,
    product_total_number: obj.producttotalnumber,
    product_list: obj.productlist
  });
}
// 点击最近足迹中商品 触发 adidas-com-front/packages/nuxt/components/my-account/member/foot-print
export function RecentClick(obj: ViewMyAccount): void {
  track('Recent_Click', {
    page_title: obj.pagetitle,
    article_number: obj.articlenumber,
    product_name: obj.productname,
    product_position: obj.productposition
  });
}
// 点击优惠券列表里的立即使用 触发 adidas-com-front/packages/nuxt/components/my-account/coupon/Card.vue
export function CouponUse(obj: ViewMyAccount): void {
  track('Coupon_Use', {
    coupon_amount: obj.couponamount,
    coupon_id: obj.couponid,
    coupon_name: obj.couponname
  });
}
// 浏览优惠券/码列表时 触发 adidas-com-front/packages/nuxt/pages/index/my-account/coupon.vue
export function CouponView(obj: ViewMyAccount): void {
  const payload:{[key:string]:string|boolean|number} = {
    coupon_amount: obj.couponamount,
    coupon_id: obj.couponid,
    coupon_name: obj.couponname,
    coupon_type: obj.coupontype,
    page_source: obj.pageSource
  };
  if(obj.couponTab){
    payload.coupon_Tab=obj.couponTab;
  }
  // @ts-ignore
  obj.articleNumber && (payload.article_number = obj.articleNumber);
  // 以下模块需要is_has_countdown_coupon字段
  const includeList = ["CartList","PDP","SPLP","CLP","HomepageModule","HompagePopup"];
    // @ts-ignore
  includeList.includes(obj.pageSource) && (payload.is_has_countdown_coupon = obj.isHasCountdownCoupon);
  if (obj.isHasCountdownCoupon) {
    // @ts-ignore
    payload.countdown_time_list = obj.countdownTimeList;
  }
  if (obj.categoryCode) {
    // @ts-ignore
    payload.category_code = obj.categoryCode;
  }
  // @ts-ignore
  obj.searchKeyword && (payload.search_keyword = obj.searchKeyword);
  track('Coupon_View', payload);
}

// 浏览优惠券/码列表时 触发 adidas-com-front/packages/nuxt/pages/index/my-account/coupon.vue
export function CouponRecieveClick(obj: ViewMyAccount): void {
  console.log();
  const payload = {
    coupon_amount: obj.couponamount,
    coupon_id: obj.couponid,
    coupon_name: obj.couponname,
    coupon_type: obj.coupontype,
    page_source: obj.pageSource,
    is_valid: obj.isValid
  };
  // @ts-ignore
  obj.articleNumber && (payload.article_number = obj.articleNumber);
  track('coupon_recieve_click', payload);
}

// my-account中积分相关埋点
export async function MembershipPointView(ctx: any, obj: ViewMembershipPoint, sensorName: string): Promise<void> {
  const { state } = ctx.$store;
  if (Object.keys(state.myAccount.accountInfo).length === 0) {
    await ctx.$store.dispatch('myAccount/getAccountInfo', {
      ctx: ctx
    }).then(() => {
    });
  }
  const payload = {
    points_number: obj.pointsNumber,
    member_tier: ctx.$store.state.myAccount.accountInfo.memberTier
  };
  if (obj.pageTitle) {
    // @ts-ignore
    payload.page_title = obj.pageTitle;
  }
  track(sensorName, payload);
}
export function ExclusivePrivilegeClick(): void {
  track('Exclusive_Privilege_Click');
}
export function ExclusivePrivilegeHover(): void {
  track('Exclusive_Privilege_Hover');
}
interface iManualCouponClick{
  couponid:string;
  clickResult:"使用成功"|"使用失败";
}
export function manualCouponClick(obj: iManualCouponClick): void{
  const payload = {
    coupon_id:obj.couponid,
    click_result:obj.clickResult
  };
  track('manual_coupon_click', payload);
}
interface iUseCouponSubmit{
  couponIdList:string[];
  usedCouponNumber:number;
  isUsedManualPromocode:boolean;
}
export function useCouponSubmit(obj: iUseCouponSubmit): void{
  const payload = {
    coupon_id_list:obj.couponIdList,
    used_coupon_number:obj.usedCouponNumber,
    is_used_manual_promocode:obj.isUsedManualPromocode
  };
  track('use_coupon_submit', payload);
}