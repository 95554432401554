
import LibMask from '@adidas-com-front/ui-library/src/components/atoms/LibMask/LibMask.vue';
import throttle from 'lodash.throttle';
import { trackCatalogView } from '../track/index';
import SkeletonPc from '../catalog-skeleton/SkeletonPc.vue';
import Sudoku from './Sudoku';
import Operate from './Operate';
import NbannerOther from './Nbanner-other';
import Title from './Title';
import { detectOS } from '~/utilities';

export default {
  name: 'PcCatalog',
  components: {
    Sudoku,
    Operate,
    LibMask,
    SkeletonPc,
    Title,
    NbannerOther
  },
  props: {
    // 控制组件显隐
    visible: {
      default: false,
      type: Boolean
    },
    // 分类一级标题(navigation)数据
    content: {
      type: [Array, Object]
    }
  },
  data() {
    return {
      // 一级导航栏传过来的id
      contentId: '',
      // 二级导航栏数据
      tagline: [],
      // 九宫格组件数据
      sudokuData: [],
      // 二级标题下标
      taglineIndex: 0,
      // 组件类型 九宫格组件 || plp组件
      typeFlag: '',
      // 当前标题
      nowNaItem: null,
      // 是否禁止九宫格组件滚动
      stopScroll: true
    };
  },
  computed: {
    componentData() {
      return this.$store.$store.state.homePage.sortInfo.all;
    },
    // 控制组件显隐
    showComponent() {
      return function (index, data) {
        return index === this.content[0]?.index;
      };
    },

    // 二级标题hover上去样式切换
    taglineClass() {
      return function (index) {
        return index === this.taglineIndex
          ? ['label-3', 'active']
          : ['label-4'];
      };
    },

    showCatalog() {
      return this.$store.state.homePage.sortInfo?.all?.length || 0;
    }
  },

  watch: {
    taglineIndex(newVal) {
      if (newVal) {
        this.trackDataScroll(this.nowNaItem);
      }
    },
    // 监听 navigation，在不同的类别下展示不同数据
    content(val) {
      if (val[0].contentId !== this.contentId) {
        this.contentId = val[0].contentId;
        this.getCatalogData(val[0].contentId, val[0]);
        this.$nextTick(() => {
          this.trackData(val[0]);
        });
      }
    },
    // 处理将要展示的数据
    showCatalog(nVal, oVal) {
      if (!this.content[0]) return;
      this.getCatalogData(this.content[0].contentId, this.content[0]);
    }
  },
  methods: {
    init() {
      this.taglineIndex = 0;
      setTimeout(() => {
        document.getElementsByClassName('sudoku-bar')[
          this.content[0].index
        ].scrollTop = 0;
      });
      this.sudokuData = [];
      this.tagline = [];
    },
    // 二级导航栏hover事件
    taglineHover(index) {
      const container =
        document.getElementsByClassName('sudoku-bar')[this.content[0].index];
      container.scrollTop = this.getScrollTop(index);
      this.taglineIndex = index;
      this.$nextTick(() => {
        setTimeout(() => {
          if (index) {
            this.trackDataScroll(this.nowNaItem);
          } else {
            this.trackData(this.nowNaItem);
          }
        }, 300);
      });
    },

    // 获取九宫格的scorll值
    getScrollTop(index) {
      const container =
          document.getElementsByClassName('sudoku-bar')[this.content[0].index];
      let scrollHeight = 0;
      if(index === 0) {
        return scrollHeight;
      }
      container.childNodes.forEach((item, idx) => {
        if(idx === index) {
          scrollHeight = item.offsetTop;
        }
      });

      return scrollHeight;
    },

    // 请求数据
    getCatalogData(paramsId, val) {
      const info = this.$store.state.homePage.sortInfo.all[val.index];
      this.handleData(info);
      // 添加catalog_view埋点
      this.nowNaItem = val;
    },

    trackDataScroll(nowNaItem) {
      const {idx, typeArray, positionCode} = this.getScrollVisibleComponent();
      const data = {
        catalog_title: nowNaItem.contentName,
        catalog_section: this.tagline[this.taglineIndex]?.options.headDescription || '',
        component_number: idx,
        component_code_list: typeArray.join('|'),
        position_code_list: positionCode.join('|')
      };
      console.log('trackData 切换', data);
      trackCatalogView(data);
    },
    getScrollVisibleComponent() {
      // const container =
      //     document.getElementsByClassName('sudoku-bar')[this.content[0].index];
      let navH;
      const msgH = 40;
      const viewW = window.innerWidth;
      if (viewW > 720 && viewW < 800) {
        navH = 60;
      } else if (viewW >=800 && viewW < 960) {
        navH = 74;
      } else {
        navH = 84;
      }
      const topCompH = navH + msgH;
      const patchBox = document.getElementsByClassName(
        `patch-box${this.content[0].index}`
      );
      let typeArray = [];
      let positionCode = [];
      let idx = 0;
      for (let i = 0; i < patchBox.length; i++) {
        const children = patchBox[i].children;
        for (let j = 0; j < children.length; j++) {
          const curTop = children[j].getBoundingClientRect().top - topCompH;
          // console.log(`${i}` + `${j}`, children, children[j].getBoundingClientRect(), children[j].getBoundingClientRect().top - topCompH);
          if ( curTop > 0 && curTop < 500 ) {
            // console.log(`${i}` + `${j}`, children[j], children[j].dataset.type, children[j].dataset.position);
            if (children[j].dataset.type === 'NBanner') {
              const { nIdx, nPosList, nTypeList } = this.getNbannerTrackData(children[j].children[0]);
              idx += nIdx;
              typeArray = typeArray.concat(nTypeList);
              positionCode = positionCode.concat(nPosList);
            } else {
              idx++;
              typeArray.push( children[j].dataset.type);
              positionCode.push(children[j].dataset.position);
            }
          }
        }
      }
      return { idx, typeArray, positionCode };
      // console.log('scrollHeight', container.scrollHeight, container.scrollTop, patchBox);
    },
    // 埋点数据格式化
    trackData(nowNaItem) {
      const {idx, typeArray, positionCode} = this.getVisibleComponent();
      const data = {
        catalog_title: nowNaItem.contentName,
        catalog_section: this.tagline[this.taglineIndex]?.options.headDescription || '',
        component_number: idx,
        component_code_list: typeArray.join('|'),
        position_code_list: positionCode.join('|')
      };
      console.log('trackData', data);
      trackCatalogView(data);
    },
    getVisibleComponent() {
      const patchH = document.getElementsByClassName(
        `patch-box${this.content[0].index}`
      );
      let idx = 0;
      let heightCount = 0;
      let typeArray = [];
      let positionCode = [];
      for (let i = 0; i < patchH.length; i++) {
        const childNodes = patchH[i].childNodes;
        for (let j = 0; j < childNodes.length; j++) {
          if (childNodes[j].offsetHeight) {
            heightCount = heightCount + childNodes[j].offsetHeight;
            idx++;
            if (childNodes[j].dataset.type === 'NBanner') {
              const { nIdx, nPosList, nTypeList } = this.getNbannerTrackData(childNodes[j].children[0]);
              idx += nIdx;
              typeArray = typeArray.concat(nTypeList);
              positionCode = positionCode.concat(nPosList);
            } else {
              typeArray.push(childNodes[j].dataset.type);
              positionCode.push(childNodes[j].dataset.position);
            }
          }
          // console.log('patchH'+ i + j,  typeArray);
          if ( heightCount > 450) {
            return { idx, typeArray, positionCode };
          }
        }
      }
      return { idx, typeArray, positionCode };
    },
    getNbannerTrackData(nodes) {
      const nbannerList = nodes.children;
      const posArr = [];
      const typeArr = [];
      for (let i = 0; i < nbannerList.length; i++) {
        posArr.push(nbannerList[i].dataset.position);
        typeArr.push('NBanner');
      }
      return {
        nIdx: nbannerList.length,
        nPosList: posArr,
        nTypeList: typeArr
      };
    },
    // 处理数据
    handleData(data) {
      if (!data) return;
      this.init();
      console.log('taglineArr', data.taglineArr);
      this.tagline = data.taglineArr;
      this.initTaglineIndex();
    },

    // 获取第一个pc端的taglineIndex
    initTaglineIndex() {
      const index = this.tagline.findIndex((item) => {
        return item.viewport.includes('pc');
      }) || 0;
      this.taglineIndex = index;
    },

    // 锚点逻辑
    patchBarScroll: throttle(function (e) {
      if (this.stopScroll) {
        const scrollTop = e.target?.scrollTop;
        const patchH = document.getElementsByClassName(
          `patch-box${this.content[0].index}`
        );
        let scrollFlag = 0;
        if(patchH&&patchH?.length){
          for(let idx=0;idx<patchH.length;idx++){
            const item=patchH[idx];
            if(!item.className.includes('mobile-only')) {
              if(scrollTop > item.offsetTop -36) {
                scrollFlag = idx;
              }
            }
          }
          this.taglineIndex = scrollFlag;
        }
        // this.$nextTick(() => {
        //   this.trackDataScroll(this.nowNaItem);
        // });
      }
    }, 100),

    // 在鼠标移出tagline区域时允许九宫格滚动事件执行
    handleMouseUp() {
      setTimeout(() => {
        this.stopScroll = true;
      }, 500);
    },

    // 遮罩点击事件
    clickMask() {
      this.$emit('clickMask');
    },

    // 处理跳转事件
    handleJump() {
      this.$emit('jump');
    },

    // 鼠标移出overlay关闭overlay
    leaveBar(e) {
      if (['Tablet', 'Mobile'].includes(detectOS())) return;
      this.$emit('close', e);
    }
  }
};
