
import { LibButton } from '@adidas-com-front/ui-library';
import { trackClpPdpClick, trackClpPlpClick, trackClpButtonClick, getScrollDepth } from '../../../track';
import LookVideo from '../common/video.vue';
import LookImg from '../common/image.vue';
import productList from './list.vue';
import { handleClickLink, lookHandleHref, getCurrentLink } from '~/components/clp/util';
import { parseURL } from '~/utilities/helper/urlHelper';

export default {
  name: 'FeedProduct',
  components: {
    LibButton,
    productList,
    LookVideo,
    LookImg
  },
  props: {
    clpShow: {
      type: Boolean
    },
    sensorInfo: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => {}
    },
    franchise: {
      type: String
    },
    isMobile: {
      type: Boolean
    },
    index:{
      type: Number
    },
    position: {
      type: String,
      default: ''
    },
    tabName: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    activeIndex: {
      type: Number
    }
  },
  data() {
    return {
      isLoad: false
    };
  },
  computed: {
    ratio() {
      return this.item.mobileActualW / this.item.mobileActualH;
    },
    itemStyle() {
      return {
        width: this.item.mobileActualW && this.item.mobileActualH ? this.ratio * 334 + 'px' : '191px'
      };
    },
    imgUrl(){
      return (item)=>{
        return this.isMobile?item.mobImgUrl :item.pcImgUrl;
      };
    },
    videoUrl(){
      return (item)=>{
        return this.isMobile?item.mobVideoUrl:item.pcVideoUrl;
      };
    },
    computedSwiperWidth() {
      return {
        width: this.item.mobileActualW && this.item.mobileActualH ? (this.ratio * 334 + 86) + 'px' : '279px'
      };
    },
    optLink() {
      return function (item, type) {
        if (type === 'Image') {
          return item.photoLink || item.imgRedirectLink;
        } else if (type === 'Button') {
          return item.buttonLink || item.imgRedirectLink;
        } else if (type === 'Video') {
          return item.imgRedirectLink;
        } else {
          return '';
        }
      };
    },

    handleUrl() {
      return function (link) {
        if (!link) {
          return 'javascript:void(0);';
        }
        const url=lookHandleHref(link);
        if(!url){
          return 'javascript:void(0);';
        }
        return url;
      };
    }
  },
  methods: {
    sensorClickTrack(type, data) {
      console.log('sensorClickTrack======', type, data);
      const curTerminalLink = data.allLink ? getCurrentLink(data.allLink) : data.link || '';
      if (!curTerminalLink) {
        return;
      }

      if (
        curTerminalLink.includes('/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/list') ||
        curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
        curTerminalLink.includes('/pages/product/rich-list') ||
        curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
        curTerminalLink.includes('adidas://category') ||
        parseURL(curTerminalLink).path.startsWith('/plp') ||
        parseURL(curTerminalLink).path.startsWith('/splp'))
      {
        this.trackData('plp', data, curTerminalLink);
      } else if ( curTerminalLink.includes('/pages/product/details') ||
        curTerminalLink.includes('adidas://product') ||
        curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
        parseURL(curTerminalLink).path === '/pdp') {
        this.trackData('pdp', data, curTerminalLink);
      } else {
        this.trackData('button', data, curTerminalLink);
      }

    },

    trackData(val, data, link) {
      const text = [data.title?.text, data.subtitle?.text].filter(item => {
        return !!item;
      });
      let param;
      const labelInfoList = [];
      if (data.labelInfo) {
        data.labelInfo.forEach((labelItem) => {
          labelInfoList.push(`${labelItem.labelGroup}:${labelItem.label.join('|')}`);
        });
      }
      switch (val) {
        case 'plp':
          param = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'LookGroup',
            position_code: Number(this.position.slice(1)),
            card_position: 1,
            scroll_depth: getScrollDepth(this.$refs.lookGroupRef.offsetTop),
            plp_url: link || '',
            plp_name: '',
            tab_name: this.tabName,
            tag: labelInfoList.join(',')
          };
          trackClpPlpClick(param);
          break;
        case 'pdp':
          param = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'LookGroup',
            position_code: Number(this.position.slice(1)),
            article_position: 1,
            tab_name: this.tabName,
            recommend_id: '',
            scroll_depth: getScrollDepth(this.$refs.lookGroupRef.offsetTop),
            category_code: this.categoryCode,
            article_number: parseURL(link).query.articleId,
            brand: '',
            category: '',
            gender: '',
            tag: labelInfoList.join(',')
          };

          trackClpPdpClick(param);
          break;
        case 'button':
          param = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: text.join('|'),
            component_code: 'LookGroup',
            position_code: Number(this.position.slice(1)),
            scroll_depth: getScrollDepth(this.$refs.lookGroupRef.offsetTop),
            to_url: link || '',
            button_name: '',
            tab_name: this.tabName,
            tag: labelInfoList.join(',')
          };
          trackClpButtonClick(param);
          break;
      }
    },

    linkRoute(item, type) {
      if(item.redirectAllLink) {
        const _item = { allLink: item.redirectAllLink};
        try {
          this.sensorClickTrack(type, _item);
        } catch (error) {

        }
        this.franchise ? (_item.franchise = this.franchise) : '';
        handleClickLink.call(this, _item);
      } else {
        if (!this.optLink(item, type)) {
          return;
        }
        const url=lookHandleHref(item.imgRedirectLink);
        if(!url){
          return;
        }
        const _item = { anchor: item.anchor, link: this.optLink(item, type) };
        try {
          this.sensorClickTrack(type, _item);
        } catch (error) {

        }
        this.franchise ? (_item.franchise = this.franchise) : '';
        handleClickLink.call(this, _item);
      }
    },
    transmit() {
      return this.$parent.$options.name;
    },
    handleLoadEnd(loadEnd) {
      this.isLoad = loadEnd;
    }
  }
};
