
import LibMask from '@adidas-com-front/ui-library/src/components/atoms/LibMask/LibMask.vue';
import { detectOS } from '../../../utilities/device/is-mobile.ts';

export default {
  name: 'LibPopover',
  components: {
    LibMask,
  },

  props: {
    classList:{
      type:Array,
      default:()=>[]
    },
    // 去除淡入淡出动画
    noFade: {
      type: Boolean,
      default: false,
    },
    // 半窗的样式
    popoverClass: { type: String },
    // 隐藏头部
    hideHeader: {
      type: Boolean,
      default: false,
    },
    // 去掉最大高度限制
    noHeightLimit: {
      type: Boolean,
      default: false,
    },
    // 去掉popBody的padding
    noPadding: {
      type: Boolean,
      default: false,
    },
    // 白色背景的mask
    whiteMask: {
      type: Boolean,
      default: false,
    },
    /* 是否打开组件 */
    show: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: Array,
      default: ()=>[],
    },
    /* 拉出方向
     * 可选值：top, bottom, left, right
     * 默认值：right
     */
    dir: {
      type: String,
      default: 'right',
    },
    /* 拉出大小
     * 可选值类型：数字或字符串
     * 值为数字或字符串类型的数字时，会设置相关值为 `size`px
     * 值为其他字符串时，相关值则直接设为 `size`,例如 '100%'
     * 默认值：424
     */
    size: {
      type: [Number, String],
      default: 424,
    },
    /*
     * 外边距自定义
     * 可选值类型：数字或字符串
     * 值为数字或字符串类型的数字时，会设置margin-top值为 `margin`px
     * 值为其他字符串时，则设置tailwind类名，例如 'sm:m-0 m-40'
     * 默认值：0
     */
    margin: {
      type: [Number, String],
      default: 0,
    },
    /*
     * 层级自定义
     * 默认值：999
     */
    zIndex: {
      type: [Number, String],
      default: 999,
    },
    /*
     * 是否响应浏览器路由返回时间
     * 默认值：false (不响应)
     */
    backToClose: {
      type: Boolean,
      default: false,
    },
    /*
     * 是否启用内容滚动
     * 开启时，组件内可滚动，下层页面不可滚动（ios端键盘呼出会导致下层页面恢复滚动）
     * 关闭时，全局滚动禁用，组件内以及页面都不可滚动（ios端键盘呼出，全局仍不可滚动）
     * 默认值：true （组件内需要滚动）
     */
    scroll: {
      type: Boolean,
      default: true,
    },
    /*
     * 内置标题位置
     * 可选值：left, middle, right
     * 默认值：left
     */
    titleType: {
      type: String,
      default: 'left',
    },
    /*
     * 标题字体tailWind类名
     * 默认值：heading-3
     */
    titleFont: {
      type: String,
      default: 'heading-3',
    },
    /*
     * 内置图标位置
     * 可选值：left, right
     * 默认值: right
     */
    iconType: {
      type: String,
      default: 'right',
    },
    /* 标题文本 */
    title: {
      type: String,
    },
    /* 标题数字文本 */
    titleNum: {
      type: String,
      default: '',
    },
    /* 标题数字文本样式 */
    titleNumClass: {
      type: String,
      default: ''
    },
    /*
     * 矢量图标
     * 值格式 icon-xxx
     */
    icon: {
      type: String,
      default: 'icon-cross_32',
    },
    /*
     * 是否需要关闭图标
     * 值格式 icon-xxx
     */
    noCloseIcon: {
      type: Boolean,
      default: false,
    },
    /*
     * 是否需要添加浏览器后退监听
     */
    popListener: {
      type: Boolean,
      // 默认为false,不然会影响其他弹窗使用
      default: false,
    },
    /*
      是否显示遮罩
     */
    maskShow: {
      type: Boolean,
      default: true,
    },
    paddingBottom: {
      type: Number,
      default: 8,
    },
    desc: {
      type: String,
      default: '',
    },
    subTitleFont: {
      type: String,
      default: 'label-6',
    },
    bg: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: ''
    },
    customizeClass : {
      type: String,
      default: ''
    },
    // 配置该参数可以自定义popover从左右划入的size是高度，传入参数为height,仅限于移动端左右划入设置半窗高度，pc端禁止使用
    sizeDir: {
      type: String,
      default: ''
    },
    maskOpacity: {
      type: Number,
      default: null
    },
    popoverBg: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // 是否移动端触发返回手势
      goBack: true,
      // 真正的开关标识
      ifshow: this.show,
      // 真正的组件大小
      trueSize: this.size,
      // 动画类标记
      popAniName: null,
      overlayAniName: null,
      // 是否正在打开/关闭组件
      aniActive: false,
      // 内容是否正在滚动
      bodyScrolling: false,
      topValue: 0, // 上次滚动条到顶部的距离
      interval: null,
      // 组件定位样式
      style: {},
      pop: '',
    };
  },
  computed: {
    /* 根据不同的props，展示顶部样式 */
    textStyle: {
      get() {
        return [`pop-title-text-${this.titleType}`, `pop-title-text`];
      },
    },
    iconStyle: {
      get() {
        return [`pop-title-icon`];
      },
    },
    computedPopoverStyle() {
      return {
        bottom: this.size - 1 + 'px',
        zIndex: this.zIndex,
        position: 'absolute',
        width: '100%'
      };
    }
  },
  watch: {
    size(val) {
      this.trueSize = val;
      this.style = {
        top: {
          top: 0,
          right: 0,
          left: 0,
          height: `${
            Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
          }`,
          backgroundImage: `url(${this.bg})`,
          backgroundColor: this.bgColor
        },
        bottom: {
          bottom: 0,
          left: 0,
          right: 0,
          height: `${
            Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
          }`,
          backgroundImage: `url(${this.bg})`,
          backgroundColor: this.bgColor
        },
        left: {
          top: 0,
          bottom: 0,
          left: 0,
          width: `${
            Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
          }`,
          backgroundImage: `url(${this.bg})`,
          backgroundColor: this.bgColor
        },
        right: {
          top: 0,
          bottom: 0,
          right: 0,
          width: `${
            Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
          }`,
          backgroundImage: `url(${this.bg})`,
          backgroundColor: this.bgColor
        },
      };
      // 此处增加的逻辑是为了让左右划出的半窗可以自定义高度，注意入参是区分好移动端还是pc端，pc端禁止入参sizeDir
      if (this.sizeDir === 'height') {
        this.style.right = {
          bottom: 0,
          left: 0,
          right: 0,
          height: `${
            Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
          }`,
          backgroundImage: `url(${this.bg})`,
          backgroundColor: this.bgColor
        };
        this.style.left = {
          top: 0,
          bottom: 0,
          left: 0,
          height: `${
            Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
          }`,
          backgroundImage: `url(${this.bg})`,
          backgroundColor: this.bgColor
        };
      }
    },
    // 动画逻辑
    show(val) {
      if (val) {
        if (document.body.clientWidth < 720 && this.backToClose) {
          window.history.pushState(null, null, '#');
        }
        if (this.popListener) {
          window.addEventListener('popstate', this.handleGoBack);
        }
        document.body.appendChild(this.$refs.pop);
        this.aniActive = true;
        // this.popAniName = `adi-slide-in-${this.dir}-enter-active`;
        // 是否去除淡入淡出动画
        if (this.noFade) {
          this.popAniName = `adi-slide-in-${this.dir}-no-enter-active`;
        } else {
          this.popAniName = `adi-slide-in-${this.dir}-enter-active`;
        }

        this.overlayAniName = `adi-fade-enter-active`;
        this.ifshow = val;
        setTimeout(() => {
          this.popAniName = null;
          this.overlayAniName = null;
          this.aniActive = false;
        }, 250);
      } else {
        if (document.body.clientWidth < 720 && this.backToClose) {
          if (this.goBack) {
            window.history.back();
            this.goBack = false;
          } else {
            this.$emit('goBack');
          }
        }
        if (this.popListener) {
          window.removeEventListener('popstate', this.handleGoBack);
        }
        this.aniActive = true;
        // this.popAniName = `adi-slide-in-${this.dir}-leave-active`;
        // 是否去除淡入淡出动画
        if (this.noFade) {
          this.popAniName = `adi-slide-in-${this.dir}-no-leave-active`;
        } else {
          this.popAniName = `adi-slide-in-${this.dir}-leave-active`;
        }
        this.overlayAniName = `adi-fade-leave-active`;
        setTimeout(() => {
          this.ifshow = val;
        }, 220);
        setTimeout(() => {
          this.popAniName = null;
          this.overlayAniName = null;
          this.aniActive = false;
          // document.body.removeChild(this.$refs.pop);
        }, 260);
      }
    },
  },
  mounted() {
    this.pop = detectOS() === 'Mobile' ? 'popauto' : 'pophidden';
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    // console.log('to destroy');
    // console.log(this.$refs.pop, this.$refs.pop.parentNode.localName);
    if (this.$refs.pop?.parentNode?.localName === 'body')
      document.body.removeChild(this.$refs.pop);
  },
  beforeMount() {
    this.style = {
      top: {
        top: 0,
        right: 0,
        left: 0,
        height: `${
          Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
        }`,
        backgroundImage: `url(${this.bg})`,
        backgroundColor: this.bgColor
      },
      bottom: {
        bottom: 0,
        left: 0,
        right: 0,
        height: `${
          Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
        }`,
        backgroundImage: `url(${this.bg})`,
        backgroundColor: this.bgColor
      },
      left: {
        top: 0,
        bottom: 0,
        left: 0,
        width: `${
          Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
        }`,
        backgroundImage: `url(${this.bg})`,
        backgroundColor: this.bgColor
      },
      right: {
        top: 0,
        bottom: 0,
        right: 0,
        width: `${
          Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
        }`,
        backgroundImage: `url(${this.bg})`,
        backgroundColor: this.bgColor
      },
    };
    // 此处增加的逻辑是为了让左右划出的半窗可以自定义高度，注意入参是区分好移动端还是pc端，pc端禁止入参sizeDir
    if (this.sizeDir === 'height') {
      this.style.right = {
        bottom: 0,
        left: 0,
        right: 0,
        height: `${
          Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
        }`,
        backgroundImage: `url(${this.bg})`,
        backgroundColor: this.bgColor
      };
      this.style.left = {
        top: 0,
        bottom: 0,
        left: 0,
        height: `${
          Number(this.trueSize) ? this.trueSize + 'px' : this.trueSize
        }`,
        backgroundImage: `url(${this.bg})`,
        backgroundColor: this.bgColor
      };
    }
  },
  methods: {
    handleResize() {
      const el = document.querySelector('.pop-container');
      el.style.height = '100vh';
      this.pop = detectOS() === 'Mobile' ? 'popauto' : 'pophidden';
    },
    clickIcon() {
      if (!this.aniActive) {
        this.goBack = true;
        this.$emit('updateShow');
      }
    },
    bodyScroll(e) {
      const ele = e.target;
      if (this.interval === null) {
        this.topValue = ele.scrollTop;
        this.bodyScrolling = true;
        // 未发起时，启动定时器，1秒1执行
        this.interval = setInterval(() => {
          // console.log("warn: Interval p19");
          // 判断此刻到顶部的距离是否和1秒前的距离相等
          if (ele.scrollTop === this.topValue) {
            clearInterval(this.interval);
            this.interval = null;
            this.bodyScrolling = false;
          }
          this.topValue = ele.scrollTop;
        }, 500);
      }
      const scrollBottom = ele.scrollHeight - ele.scrollTop - ele.clientHeight;
      if (scrollBottom <= 20) {
        this.$emit('reachBottom');
      }
    },
    closePop(ifGoback) {
      if (!this.aniActive) {
        this.goBack = ifGoback;
        if (this.$listeners.popGoBack) {
          this.$emit('popGoBack');
        } else {
          this.$emit('updateShow');
        }
      }
    },
    handleGoBack() {
      this.closePop(false);
      setTimeout(() => {
        if (
          this.$refs.pop?.parentNode?.localName === 'body' &&
          !this.backToClose
        )
          document.body.removeChild(this.$refs.pop);
      }, 260);
    },
  },
};
