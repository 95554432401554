export const DemoEntry = () => import('../../components/DemoEntry.vue' /* webpackChunkName: "components/demo-entry" */).then(c => wrapFunctional(c.default || c))
export const FooterPlaceholder = () => import('../../components/FooterPlaceholder.vue' /* webpackChunkName: "components/footer-placeholder" */).then(c => wrapFunctional(c.default || c))
export const HeaderPlaceholder = () => import('../../components/HeaderPlaceholder.vue' /* webpackChunkName: "components/header-placeholder" */).then(c => wrapFunctional(c.default || c))
export const LayoutWrapper = () => import('../../components/LayoutWrapper.vue' /* webpackChunkName: "components/layout-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ErrorError = () => import('../../components/404-error/404Error.vue' /* webpackChunkName: "components/error-error" */).then(c => wrapFunctional(c.default || c))
export const ErrorSearch = () => import('../../components/404-error/Search.vue' /* webpackChunkName: "components/error-search" */).then(c => wrapFunctional(c.default || c))
export const BraFinderProcess = () => import('../../components/braFinder/process.vue' /* webpackChunkName: "components/bra-finder-process" */).then(c => wrapFunctional(c.default || c))
export const BraFinderSwiperSelector = () => import('../../components/braFinder/swiperSelector.vue' /* webpackChunkName: "components/bra-finder-swiper-selector" */).then(c => wrapFunctional(c.default || c))
export const BraFinderUtils = () => import('../../components/braFinder/utils.js' /* webpackChunkName: "components/bra-finder-utils" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../components/cart/index.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const CatalogUtils = () => import('../../components/catalog/utils.js' /* webpackChunkName: "components/catalog-utils" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSsrAsyncData = () => import('../../components/checkout/ssr.async.data.js' /* webpackChunkName: "components/checkout-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const CheckoutUtils = () => import('../../components/checkout/utils.ts' /* webpackChunkName: "components/checkout-utils" */).then(c => wrapFunctional(c.default || c))
export const ClpChild = () => import('../../components/clp/child.vue' /* webpackChunkName: "components/clp-child" */).then(c => wrapFunctional(c.default || c))
export const ClpDirectives = () => import('../../components/clp/directives.js' /* webpackChunkName: "components/clp-directives" */).then(c => wrapFunctional(c.default || c))
export const Clp = () => import('../../components/clp/index.vue' /* webpackChunkName: "components/clp" */).then(c => wrapFunctional(c.default || c))
export const ClpUtil = () => import('../../components/clp/util.js' /* webpackChunkName: "components/clp-util" */).then(c => wrapFunctional(c.default || c))
export const Compatibility = () => import('../../components/compatibility/index.vue' /* webpackChunkName: "components/compatibility" */).then(c => wrapFunctional(c.default || c))
export const CompatibilityUtils = () => import('../../components/compatibility/utils.ts' /* webpackChunkName: "components/compatibility-utils" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInBlack = () => import('../../components/dailyCheckIn/black.vue' /* webpackChunkName: "components/daily-check-in-black" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInContinuously = () => import('../../components/dailyCheckIn/continuously.vue' /* webpackChunkName: "components/daily-check-in-continuously" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInCumilative = () => import('../../components/dailyCheckIn/cumilative.vue' /* webpackChunkName: "components/daily-check-in-cumilative" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInIntroduction = () => import('../../components/dailyCheckIn/introduction.vue' /* webpackChunkName: "components/daily-check-in-introduction" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInRecurNextWeek = () => import('../../components/dailyCheckIn/recur-next-week.vue' /* webpackChunkName: "components/daily-check-in-recur-next-week" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInReminder = () => import('../../components/dailyCheckIn/reminder.vue' /* webpackChunkName: "components/daily-check-in-reminder" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInUtilsTest = () => import('../../components/dailyCheckIn/utils-test.ts' /* webpackChunkName: "components/daily-check-in-utils-test" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInUtils = () => import('../../components/dailyCheckIn/utils.ts' /* webpackChunkName: "components/daily-check-in-utils" */).then(c => wrapFunctional(c.default || c))
export const DownloadConfirmed = () => import('../../components/download/confirmed.vue' /* webpackChunkName: "components/download-confirmed" */).then(c => wrapFunctional(c.default || c))
export const EmployeeLogin = () => import('../../components/employee-login/Login.vue' /* webpackChunkName: "components/employee-login" */).then(c => wrapFunctional(c.default || c))
export const EmployeeLogout = () => import('../../components/employee-logout/Logout.vue' /* webpackChunkName: "components/employee-logout" */).then(c => wrapFunctional(c.default || c))
export const ErrorPage = () => import('../../components/error-page/ErrorPage.vue' /* webpackChunkName: "components/error-page" */).then(c => wrapFunctional(c.default || c))
export const ErrorPageNormalDouble = () => import('../../components/error-page/ErrorPageNormalDouble.vue' /* webpackChunkName: "components/error-page-normal-double" */).then(c => wrapFunctional(c.default || c))
export const ErrorPageNormalDoublePc = () => import('../../components/error-page/ErrorPageNormalDoublePc.vue' /* webpackChunkName: "components/error-page-normal-double-pc" */).then(c => wrapFunctional(c.default || c))
export const ErrorPageNormalSingle = () => import('../../components/error-page/ErrorPageNormalSingle.vue' /* webpackChunkName: "components/error-page-normal-single" */).then(c => wrapFunctional(c.default || c))
export const FooterDyPopper = () => import('../../components/footer/dy-popper.vue' /* webpackChunkName: "components/footer-dy-popper" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterTrack = () => import('../../components/footer/track.ts' /* webpackChunkName: "components/footer-track" */).then(c => wrapFunctional(c.default || c))
export const FooterWbPopper = () => import('../../components/footer/wb-popper.vue' /* webpackChunkName: "components/footer-wb-popper" */).then(c => wrapFunctional(c.default || c))
export const FooterWxPopper = () => import('../../components/footer/wx-popper.vue' /* webpackChunkName: "components/footer-wx-popper" */).then(c => wrapFunctional(c.default || c))
export const HomepageTrack = () => import('../../components/homepage/track.ts' /* webpackChunkName: "components/homepage-track" */).then(c => wrapFunctional(c.default || c))
export const HomepageUtils = () => import('../../components/homepage/utils.ts' /* webpackChunkName: "components/homepage-utils" */).then(c => wrapFunctional(c.default || c))
export const Identity = () => import('../../components/identity/index.vue' /* webpackChunkName: "components/identity" */).then(c => wrapFunctional(c.default || c))
export const IdentityUtils = () => import('../../components/identity/utils.ts' /* webpackChunkName: "components/identity-utils" */).then(c => wrapFunctional(c.default || c))
export const LocationSelectorContent = () => import('../../components/location-selector/content.vue' /* webpackChunkName: "components/location-selector-content" */).then(c => wrapFunctional(c.default || c))
export const LocationSelectorHeader = () => import('../../components/location-selector/header.vue' /* webpackChunkName: "components/location-selector-header" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterLibLogin = () => import('../../components/login-register/LibLogin.vue' /* webpackChunkName: "components/login-register-lib-login" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterLibLoginInPage = () => import('../../components/login-register/LibLoginInPage.vue' /* webpackChunkName: "components/login-register-lib-login-in-page" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterNonmemberLogin = () => import('../../components/login-register/NonmemberLogin.vue' /* webpackChunkName: "components/login-register-nonmember-login" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterForgetPassword = () => import('../../components/login-register/forget-password.vue' /* webpackChunkName: "components/login-register-forget-password" */).then(c => wrapFunctional(c.default || c))
export const LoginPopover = () => import('../../components/login-register/loginPopover.vue' /* webpackChunkName: "components/login-popover" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawUtils = () => import('../../components/luckyDraw/utils.ts' /* webpackChunkName: "components/lucky-draw-utils" */).then(c => wrapFunctional(c.default || c))
export const MyAccount = () => import('../../components/my-account/index.vue' /* webpackChunkName: "components/my-account" */).then(c => wrapFunctional(c.default || c))
export const NavigationBar = () => import('../../components/navigation/NavigationBar.vue' /* webpackChunkName: "components/navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/navigation/index.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationSkeleton = () => import('../../components/navigation/skeleton.vue' /* webpackChunkName: "components/navigation-skeleton" */).then(c => wrapFunctional(c.default || c))
export const NpsCes = () => import('../../components/nps-ces/ces.vue' /* webpackChunkName: "components/nps-ces" */).then(c => wrapFunctional(c.default || c))
export const Nps = () => import('../../components/nps-ces/nps.vue' /* webpackChunkName: "components/nps" */).then(c => wrapFunctional(c.default || c))
export const NpsCesQuestionnaire = () => import('../../components/nps-ces/questionnaire.vue' /* webpackChunkName: "components/nps-ces-questionnaire" */).then(c => wrapFunctional(c.default || c))
export const NpsCesShowScore = () => import('../../components/nps-ces/showScore.vue' /* webpackChunkName: "components/nps-ces-show-score" */).then(c => wrapFunctional(c.default || c))
export const NpsCesUtils = () => import('../../components/nps-ces/utils.ts' /* webpackChunkName: "components/nps-ces-utils" */).then(c => wrapFunctional(c.default || c))
export const Partnership = () => import('../../components/partnership/index.vue' /* webpackChunkName: "components/partnership" */).then(c => wrapFunctional(c.default || c))
export const PartnershipUtils = () => import('../../components/partnership/utils.ts' /* webpackChunkName: "components/partnership-utils" */).then(c => wrapFunctional(c.default || c))
export const PartnershipVerify = () => import('../../components/partnership/verify.vue' /* webpackChunkName: "components/partnership-verify" */).then(c => wrapFunctional(c.default || c))
export const PdpUtils = () => import('../../components/pdp/utils.ts' /* webpackChunkName: "components/pdp-utils" */).then(c => wrapFunctional(c.default || c))
export const PdpVirtusize = () => import('../../components/pdp/virtusize.ts' /* webpackChunkName: "components/pdp-virtusize" */).then(c => wrapFunctional(c.default || c))
export const PointsAnimation = () => import('../../components/pointsAnimation/index.vue' /* webpackChunkName: "components/points-animation" */).then(c => wrapFunctional(c.default || c))
export const PointsAnimationUtils = () => import('../../components/pointsAnimation/utils.ts' /* webpackChunkName: "components/points-animation-utils" */).then(c => wrapFunctional(c.default || c))
export const Right2copyApplyExportMixins = () => import('../../components/right2copy/applyExport.mixins.ts' /* webpackChunkName: "components/right2copy-apply-export-mixins" */).then(c => wrapFunctional(c.default || c))
export const Right2copyApplyExport = () => import('../../components/right2copy/applyExport.vue' /* webpackChunkName: "components/right2copy-apply-export" */).then(c => wrapFunctional(c.default || c))
export const Right2copyExample = () => import('../../components/right2copy/example.vue' /* webpackChunkName: "components/right2copy-example" */).then(c => wrapFunctional(c.default || c))
export const Right2copyExportPopover = () => import('../../components/right2copy/exportPopover.vue' /* webpackChunkName: "components/right2copy-export-popover" */).then(c => wrapFunctional(c.default || c))
export const Right2copyNonmemberApplyExport = () => import('../../components/right2copy/nonmember-applyExport.vue' /* webpackChunkName: "components/right2copy-nonmember-apply-export" */).then(c => wrapFunctional(c.default || c))
export const Right2copyTrack = () => import('../../components/right2copy/track.ts' /* webpackChunkName: "components/right2copy-track" */).then(c => wrapFunctional(c.default || c))
export const Right2copyUtils = () => import('../../components/right2copy/utils.ts' /* webpackChunkName: "components/right2copy-utils" */).then(c => wrapFunctional(c.default || c))
export const SfPickupUtils = () => import('../../components/sf-pickup/utils.ts' /* webpackChunkName: "components/sf-pickup-utils" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideAnchor = () => import('../../components/size-guide/anchor.vue' /* webpackChunkName: "components/size-guide-anchor" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideAnchorItem = () => import('../../components/size-guide/anchorItem.vue' /* webpackChunkName: "components/size-guide-anchor-item" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideTrack = () => import('../../components/size-guide/track.ts' /* webpackChunkName: "components/size-guide-track" */).then(c => wrapFunctional(c.default || c))
export const SplpMethod = () => import('../../components/splp/method.js' /* webpackChunkName: "components/splp-method" */).then(c => wrapFunctional(c.default || c))
export const SplpUtils = () => import('../../components/splp/utils.ts' /* webpackChunkName: "components/splp-utils" */).then(c => wrapFunctional(c.default || c))
export const TC = () => import('../../components/t-c/index.vue' /* webpackChunkName: "components/t-c" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../components/toast/index.js' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const AbtestTipsBar = () => import('../../components/abtest/tips-bar/index.vue' /* webpackChunkName: "components/abtest-tips-bar" */).then(c => wrapFunctional(c.default || c))
export const AbtestTipsBarPdpBanner = () => import('../../components/abtest/tips-bar/pdpBanner.vue' /* webpackChunkName: "components/abtest-tips-bar-pdp-banner" */).then(c => wrapFunctional(c.default || c))
export const BraFinderCheckboxSelector = () => import('../../components/braFinder/checkboxSelector/index.vue' /* webpackChunkName: "components/bra-finder-checkbox-selector" */).then(c => wrapFunctional(c.default || c))
export const CartCartList = () => import('../../components/cart/cart-list/index.vue' /* webpackChunkName: "components/cart-cart-list" */).then(c => wrapFunctional(c.default || c))
export const CartCommodityPriceDetails = () => import('../../components/cart/commodityPriceDetails/CommodityPriceDetails.vue' /* webpackChunkName: "components/cart-commodity-price-details" */).then(c => wrapFunctional(c.default || c))
export const CartCoupon = () => import('../../components/cart/coupon/index.vue' /* webpackChunkName: "components/cart-coupon" */).then(c => wrapFunctional(c.default || c))
export const CartEmptyCart = () => import('../../components/cart/empty-cart/EmptyCart.vue' /* webpackChunkName: "components/cart-empty-cart" */).then(c => wrapFunctional(c.default || c))
export const CartEmptyCartNotLogin = () => import('../../components/cart/empty-cart/EmptyCartNotLogin.vue' /* webpackChunkName: "components/cart-empty-cart-not-login" */).then(c => wrapFunctional(c.default || c))
export const CartEmptyCartTop = () => import('../../components/cart/empty-cart/EmptyCartTop.vue' /* webpackChunkName: "components/cart-empty-cart-top" */).then(c => wrapFunctional(c.default || c))
export const CartGiftShow = () => import('../../components/cart/gift-show/GiftShow.vue' /* webpackChunkName: "components/cart-gift-show" */).then(c => wrapFunctional(c.default || c))
export const CartMonthFeatured = () => import('../../components/cart/month-featured/MonthFeatured.vue' /* webpackChunkName: "components/cart-month-featured" */).then(c => wrapFunctional(c.default || c))
export const CartMonthFeaturedSsrAsyncData = () => import('../../components/cart/month-featured/ssr.async.data.js' /* webpackChunkName: "components/cart-month-featured-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const CartOutOfStockProductCard = () => import('../../components/cart/out-of-stock-product-card/outOfStockProductCard.vue' /* webpackChunkName: "components/cart-out-of-stock-product-card" */).then(c => wrapFunctional(c.default || c))
export const CartPointsBar = () => import('../../components/cart/points-bar/index.vue' /* webpackChunkName: "components/cart-points-bar" */).then(c => wrapFunctional(c.default || c))
export const CartShoppingBag = () => import('../../components/cart/shopping-bag/ShoppingBag.vue' /* webpackChunkName: "components/cart-shopping-bag" */).then(c => wrapFunctional(c.default || c))
export const CartSizeChoice = () => import('../../components/cart/size-choice/index.vue' /* webpackChunkName: "components/cart-size-choice" */).then(c => wrapFunctional(c.default || c))
export const CartSkeletonMobile = () => import('../../components/cart/skeleton/SkeletonMobile.vue' /* webpackChunkName: "components/cart-skeleton-mobile" */).then(c => wrapFunctional(c.default || c))
export const CartSkeletonPc = () => import('../../components/cart/skeleton/SkeletonPc.vue' /* webpackChunkName: "components/cart-skeleton-pc" */).then(c => wrapFunctional(c.default || c))
export const CartTips = () => import('../../components/cart/tips/Tips.vue' /* webpackChunkName: "components/cart-tips" */).then(c => wrapFunctional(c.default || c))
export const CartTrack = () => import('../../components/cart/track/index.ts' /* webpackChunkName: "components/cart-track" */).then(c => wrapFunctional(c.default || c))
export const CartUtil = () => import('../../components/cart/util/index.js' /* webpackChunkName: "components/cart-util" */).then(c => wrapFunctional(c.default || c))
export const CartUtilLocalCartService = () => import('../../components/cart/util/localCartService.ts' /* webpackChunkName: "components/cart-util-local-cart-service" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatalogPc = () => import('../../components/catalog/catalog-pc/index.vue' /* webpackChunkName: "components/catalog-catalog-pc" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatalogSkeletonMobile = () => import('../../components/catalog/catalog-skeleton/SkeletonMobile.vue' /* webpackChunkName: "components/catalog-catalog-skeleton-mobile" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatalogSkeletonPc = () => import('../../components/catalog/catalog-skeleton/SkeletonPc.vue' /* webpackChunkName: "components/catalog-catalog-skeleton-pc" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatelogMobile = () => import('../../components/catalog/catelog-mobile/index.vue' /* webpackChunkName: "components/catalog-catelog-mobile" */).then(c => wrapFunctional(c.default || c))
export const CatalogSkeletonMobileSideBar = () => import('../../components/catalog/skeleton/mobile-sideBar.vue' /* webpackChunkName: "components/catalog-skeleton-mobile-side-bar" */).then(c => wrapFunctional(c.default || c))
export const CatalogSkeletonMobile = () => import('../../components/catalog/skeleton/mobile.vue' /* webpackChunkName: "components/catalog-skeleton-mobile" */).then(c => wrapFunctional(c.default || c))
export const CatalogTrack = () => import('../../components/catalog/track/index.ts' /* webpackChunkName: "components/catalog-track" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDiscountCard = () => import('../../components/checkout/discount/Card.vue' /* webpackChunkName: "components/checkout-discount-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDiscount = () => import('../../components/checkout/discount/Discount.vue' /* webpackChunkName: "components/checkout-discount" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDiscountInputCode = () => import('../../components/checkout/discount/InputCode.vue' /* webpackChunkName: "components/checkout-discount-input-code" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPayFailed = () => import('../../components/checkout/pay-failed/PayFailed.vue' /* webpackChunkName: "components/checkout-pay-failed" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPayLoading = () => import('../../components/checkout/pay-loading/PayLoading.vue' /* webpackChunkName: "components/checkout-pay-loading" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaySkeleton = () => import('../../components/checkout/pay-skeleton/PaySkeleton.vue' /* webpackChunkName: "components/checkout-pay-skeleton" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentPayMsg = () => import('../../components/checkout/payment/PayMsg.vue' /* webpackChunkName: "components/checkout-payment-pay-msg" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaysuccessPaySuccess = () => import('../../components/checkout/paysuccess/PaySuccess.vue' /* webpackChunkName: "components/checkout-paysuccess-pay-success" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaysuccessMemberShipPointsPopup = () => import('../../components/checkout/paysuccess/memberShipPointsPopup.vue' /* webpackChunkName: "components/checkout-paysuccess-member-ship-points-popup" */).then(c => wrapFunctional(c.default || c))
export const ClpClpSingleImage = () => import('../../components/clp/clpSingleImage/index.vue' /* webpackChunkName: "components/clp-clp-single-image" */).then(c => wrapFunctional(c.default || c))
export const ClpCommonCountdown = () => import('../../components/clp/common/countdown.vue' /* webpackChunkName: "components/clp-common-countdown" */).then(c => wrapFunctional(c.default || c))
export const ClpCommonHotzone = () => import('../../components/clp/common/hotzone.vue' /* webpackChunkName: "components/clp-common-hotzone" */).then(c => wrapFunctional(c.default || c))
export const ClpCommonProductCard = () => import('../../components/clp/common/productCard.vue' /* webpackChunkName: "components/clp-common-product-card" */).then(c => wrapFunctional(c.default || c))
export const ClpCommonProductUtil = () => import('../../components/clp/common/productUtil.ts' /* webpackChunkName: "components/clp-common-product-util" */).then(c => wrapFunctional(c.default || c))
export const ClpCommonSpecialLinkMixins = () => import('../../components/clp/common/specialLink.mixins.js' /* webpackChunkName: "components/clp-common-special-link-mixins" */).then(c => wrapFunctional(c.default || c))
export const ClpCta = () => import('../../components/clp/cta/Cta.vue' /* webpackChunkName: "components/clp-cta" */).then(c => wrapFunctional(c.default || c))
export const ClpCtaTest = () => import('../../components/clp/cta-test/index.vue' /* webpackChunkName: "components/clp-cta-test" */).then(c => wrapFunctional(c.default || c))
export const ClpHeader = () => import('../../components/clp/header/index.vue' /* webpackChunkName: "components/clp-header" */).then(c => wrapFunctional(c.default || c))
export const ClpImageSlideProduct = () => import('../../components/clp/image-slide-product/index.vue' /* webpackChunkName: "components/clp-image-slide-product" */).then(c => wrapFunctional(c.default || c))
export const ClpLook = () => import('../../components/clp/look/index.vue' /* webpackChunkName: "components/clp-look" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroup = () => import('../../components/clp/look-group/index.vue' /* webpackChunkName: "components/clp-look-group" */).then(c => wrapFunctional(c.default || c))
export const ClpMultiImage = () => import('../../components/clp/multi-image/index.vue' /* webpackChunkName: "components/clp-multi-image" */).then(c => wrapFunctional(c.default || c))
export const ClpNBanner = () => import('../../components/clp/n-banner/index.vue' /* webpackChunkName: "components/clp-n-banner" */).then(c => wrapFunctional(c.default || c))
export const ClpNBannerMinibanner = () => import('../../components/clp/n-banner/minibanner.vue' /* webpackChunkName: "components/clp-n-banner-minibanner" */).then(c => wrapFunctional(c.default || c))
export const ClpNBannerMinibannerV2 = () => import('../../components/clp/n-banner/minibannerV2.vue' /* webpackChunkName: "components/clp-n-banner-minibanner-v2" */).then(c => wrapFunctional(c.default || c))
export const ClpNBannerSideslip = () => import('../../components/clp/n-banner/sideslip.vue' /* webpackChunkName: "components/clp-n-banner-sideslip" */).then(c => wrapFunctional(c.default || c))
export const ClpNBannerTiled = () => import('../../components/clp/n-banner/tiled.vue' /* webpackChunkName: "components/clp-n-banner-tiled" */).then(c => wrapFunctional(c.default || c))
export const ClpPplp = () => import('../../components/clp/pplp/index.vue' /* webpackChunkName: "components/clp-pplp" */).then(c => wrapFunctional(c.default || c))
export const ClpProductCard = () => import('../../components/clp/product-list/ProductCard.vue' /* webpackChunkName: "components/clp-product-card" */).then(c => wrapFunctional(c.default || c))
export const ClpProductList = () => import('../../components/clp/product-list/index.vue' /* webpackChunkName: "components/clp-product-list" */).then(c => wrapFunctional(c.default || c))
export const ClpProductListUtils = () => import('../../components/clp/product-list/utils.ts' /* webpackChunkName: "components/clp-product-list-utils" */).then(c => wrapFunctional(c.default || c))
export const ClpSearchKeyword = () => import('../../components/clp/searchKeyword/index.vue' /* webpackChunkName: "components/clp-search-keyword" */).then(c => wrapFunctional(c.default || c))
export const ClpSearchKeywordUtil = () => import('../../components/clp/searchKeyword/util.ts' /* webpackChunkName: "components/clp-search-keyword-util" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyAccordion = () => import('../../components/clp/survey/accordion.vue' /* webpackChunkName: "components/clp-survey-accordion" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyBrick = () => import('../../components/clp/survey/brick.vue' /* webpackChunkName: "components/clp-survey-brick" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyChoice = () => import('../../components/clp/survey/choice.vue' /* webpackChunkName: "components/clp-survey-choice" */).then(c => wrapFunctional(c.default || c))
export const ClpSurvey = () => import('../../components/clp/survey/index.vue' /* webpackChunkName: "components/clp-survey" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyMemberPopover = () => import('../../components/clp/survey/memberPopover.vue' /* webpackChunkName: "components/clp-survey-member-popover" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyPk = () => import('../../components/clp/survey/pk.vue' /* webpackChunkName: "components/clp-survey-pk" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyQuestionMixins = () => import('../../components/clp/survey/question.mixins.js' /* webpackChunkName: "components/clp-survey-question-mixins" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyShuffleItem = () => import('../../components/clp/survey/shuffle-item.vue' /* webpackChunkName: "components/clp-survey-shuffle-item" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyShuffle = () => import('../../components/clp/survey/shuffle.vue' /* webpackChunkName: "components/clp-survey-shuffle" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyTextInput = () => import('../../components/clp/survey/text-input.vue' /* webpackChunkName: "components/clp-survey-text-input" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyUtils = () => import('../../components/clp/survey/utils.ts' /* webpackChunkName: "components/clp-survey-utils" */).then(c => wrapFunctional(c.default || c))
export const ClpTabAnchorItem = () => import('../../components/clp/tab-anchor/TabAnchorItem.vue' /* webpackChunkName: "components/clp-tab-anchor-item" */).then(c => wrapFunctional(c.default || c))
export const ClpTabAnchor = () => import('../../components/clp/tab-anchor/index.vue' /* webpackChunkName: "components/clp-tab-anchor" */).then(c => wrapFunctional(c.default || c))
export const ClpTabPage = () => import('../../components/clp/tab-page/index.vue' /* webpackChunkName: "components/clp-tab-page" */).then(c => wrapFunctional(c.default || c))
export const ClpTextLink = () => import('../../components/clp/text-link/TextLink.vue' /* webpackChunkName: "components/clp-text-link" */).then(c => wrapFunctional(c.default || c))
export const ClpTrack = () => import('../../components/clp/track/index.ts' /* webpackChunkName: "components/clp-track" */).then(c => wrapFunctional(c.default || c))
export const ClpVerticalTabPage = () => import('../../components/clp/verticalTabPage/index.vue' /* webpackChunkName: "components/clp-vertical-tab-page" */).then(c => wrapFunctional(c.default || c))
export const ClpVideo = () => import('../../components/clp/video/index.vue' /* webpackChunkName: "components/clp-video" */).then(c => wrapFunctional(c.default || c))
export const CommonAddrPopoverAddress = () => import('../../components/common/addr-popover/Address.vue' /* webpackChunkName: "components/common-addr-popover-address" */).then(c => wrapFunctional(c.default || c))
export const CommonAddrPopoverAddressEditor = () => import('../../components/common/addr-popover/AddressEditor.vue' /* webpackChunkName: "components/common-addr-popover-address-editor" */).then(c => wrapFunctional(c.default || c))
export const CommonAddrPopoverAddressList = () => import('../../components/common/addr-popover/AddressList.vue' /* webpackChunkName: "components/common-addr-popover-address-list" */).then(c => wrapFunctional(c.default || c))
export const CommonAddrPopoverAddressSelector = () => import('../../components/common/addr-popover/AddressSelector.vue' /* webpackChunkName: "components/common-addr-popover-address-selector" */).then(c => wrapFunctional(c.default || c))
export const CommonAddrPopoverEventsBus = () => import('../../components/common/addr-popover/EventsBus.js' /* webpackChunkName: "components/common-addr-popover-events-bus" */).then(c => wrapFunctional(c.default || c))
export const CommonAppDownload = () => import('../../components/common/app-download/index.vue' /* webpackChunkName: "components/common-app-download" */).then(c => wrapFunctional(c.default || c))
export const CommonCountdown = () => import('../../components/common/countdown/Countdown.vue' /* webpackChunkName: "components/common-countdown" */).then(c => wrapFunctional(c.default || c))
export const CommonDialogComDialog = () => import('../../components/common/dialog/ComDialog.vue' /* webpackChunkName: "components/common-dialog-com-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonDialogIndicator = () => import('../../components/common/dialog/indicator.vue' /* webpackChunkName: "components/common-dialog-indicator" */).then(c => wrapFunctional(c.default || c))
export const CommonHeader = () => import('../../components/common/header/index.vue' /* webpackChunkName: "components/common-header" */).then(c => wrapFunctional(c.default || c))
export const CommonHorizontalSteps = () => import('../../components/common/horizontal-steps/index.vue' /* webpackChunkName: "components/common-horizontal-steps" */).then(c => wrapFunctional(c.default || c))
export const CommonNewAddr = () => import('../../components/common/new-addr/NewAddr.vue' /* webpackChunkName: "components/common-new-addr" */).then(c => wrapFunctional(c.default || c))
export const CommonPromoRedemptionCouponItem = () => import('../../components/common/promo-redemption/couponItem.vue' /* webpackChunkName: "components/common-promo-redemption-coupon-item" */).then(c => wrapFunctional(c.default || c))
export const CommonPromoRedemption = () => import('../../components/common/promo-redemption/index.vue' /* webpackChunkName: "components/common-promo-redemption" */).then(c => wrapFunctional(c.default || c))
export const CommonPromoRedemptionSsrAsyncData = () => import('../../components/common/promo-redemption/ssr.async.data.js' /* webpackChunkName: "components/common-promo-redemption-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const CommonTabItem = () => import('../../components/common/tab-list/tabItem.vue' /* webpackChunkName: "components/common-tab-item" */).then(c => wrapFunctional(c.default || c))
export const CommonTabList = () => import('../../components/common/tab-list/tabList.vue' /* webpackChunkName: "components/common-tab-list" */).then(c => wrapFunctional(c.default || c))
export const CommonTextareaComTextarea = () => import('../../components/common/textarea/ComTextarea.vue' /* webpackChunkName: "components/common-textarea-com-textarea" */).then(c => wrapFunctional(c.default || c))
export const CommonTextareaCalcTextareaHeight = () => import('../../components/common/textarea/calcTextareaHeight.js' /* webpackChunkName: "components/common-textarea-calc-textarea-height" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInErrorMobile = () => import('../../components/dailyCheckIn/500-error/500-error-mobile.vue' /* webpackChunkName: "components/daily-check-in-error-mobile" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckIn500Errorerror = () => import('../../components/dailyCheckIn/500-error/500error.vue' /* webpackChunkName: "components/daily-check-in500-errorerror" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckIn500ErrorHeader = () => import('../../components/dailyCheckIn/500-error/header.vue' /* webpackChunkName: "components/daily-check-in500-error-header" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckIn500ErrorUtills = () => import('../../components/dailyCheckIn/500-error/utills.ts' /* webpackChunkName: "components/daily-check-in500-error-utills" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInCheckInCalendar = () => import('../../components/dailyCheckIn/CheckInCalendar/index.vue' /* webpackChunkName: "components/daily-check-in-check-in-calendar" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInCheckInCalendarUtils = () => import('../../components/dailyCheckIn/CheckInCalendar/utils.ts' /* webpackChunkName: "components/daily-check-in-check-in-calendar-utils" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInCheckInCalendarPopup = () => import('../../components/dailyCheckIn/CheckInCalendarPopup/index.vue' /* webpackChunkName: "components/daily-check-in-check-in-calendar-popup" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInActivitywarnActivityEnd = () => import('../../components/dailyCheckIn/activitywarn/activityEnd.vue' /* webpackChunkName: "components/daily-check-in-activitywarn-activity-end" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInActivitywarnActivityStart = () => import('../../components/dailyCheckIn/activitywarn/activityStart.vue' /* webpackChunkName: "components/daily-check-in-activitywarn-activity-start" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInAddress = () => import('../../components/dailyCheckIn/address/Address.vue' /* webpackChunkName: "components/daily-check-in-address" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInAddressRule = () => import('../../components/dailyCheckIn/address/AddressRule.vue' /* webpackChunkName: "components/daily-check-in-address-rule" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInAddressSelector = () => import('../../components/dailyCheckIn/address/AddressSelector.vue' /* webpackChunkName: "components/daily-check-in-address-selector" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInCheckInCalendarNewGrid = () => import('../../components/dailyCheckIn/checkInCalendar-new/grid.vue' /* webpackChunkName: "components/daily-check-in-check-in-calendar-new-grid" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInCheckInCalendarNew = () => import('../../components/dailyCheckIn/checkInCalendar-new/index.vue' /* webpackChunkName: "components/daily-check-in-check-in-calendar-new" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInCheckInCalendarNewUtils = () => import('../../components/dailyCheckIn/checkInCalendar-new/utils.ts' /* webpackChunkName: "components/daily-check-in-check-in-calendar-new-utils" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInContinuouslyPrize = () => import('../../components/dailyCheckIn/continuouslyPrize/index.vue' /* webpackChunkName: "components/daily-check-in-continuously-prize" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInContinuouslyPrizePreview = () => import('../../components/dailyCheckIn/continuouslyPrize/preview.vue' /* webpackChunkName: "components/daily-check-in-continuously-prize-preview" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInMyPrize = () => import('../../components/dailyCheckIn/my-prize/Prize.vue' /* webpackChunkName: "components/daily-check-in-my-prize" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupConfetti = () => import('../../components/dailyCheckIn/popup/confetti.vue' /* webpackChunkName: "components/daily-check-in-popup-confetti" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupContinuous = () => import('../../components/dailyCheckIn/popup/continuous.vue' /* webpackChunkName: "components/daily-check-in-popup-continuous" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupContinuousCalendar = () => import('../../components/dailyCheckIn/popup/continuousCalendar.vue' /* webpackChunkName: "components/daily-check-in-popup-continuous-calendar" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopup = () => import('../../components/dailyCheckIn/popup/index.vue' /* webpackChunkName: "components/daily-check-in-popup" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupLightBackground = () => import('../../components/dailyCheckIn/popup/lightBackground.vue' /* webpackChunkName: "components/daily-check-in-popup-light-background" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupMixins = () => import('../../components/dailyCheckIn/popup/popup.mixins.js' /* webpackChunkName: "components/daily-check-in-popup-mixins" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupPrizeInfoList = () => import('../../components/dailyCheckIn/popup/prizeInfoList.vue' /* webpackChunkName: "components/daily-check-in-popup-prize-info-list" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupRemedyAnimationBase64 = () => import('../../components/dailyCheckIn/popup/remedy-animation-base64.js' /* webpackChunkName: "components/daily-check-in-popup-remedy-animation-base64" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupRemedy = () => import('../../components/dailyCheckIn/popup/remedy.vue' /* webpackChunkName: "components/daily-check-in-popup-remedy" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPrize = () => import('../../components/dailyCheckIn/prize/index.vue' /* webpackChunkName: "components/daily-check-in-prize" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPrizePreview = () => import('../../components/dailyCheckIn/prize/preview.vue' /* webpackChunkName: "components/daily-check-in-prize-preview" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInTaskPanel = () => import('../../components/dailyCheckIn/taskPanel/index.vue' /* webpackChunkName: "components/daily-check-in-task-panel" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInTrack = () => import('../../components/dailyCheckIn/track/index.ts' /* webpackChunkName: "components/daily-check-in-track" */).then(c => wrapFunctional(c.default || c))
export const HomepageBannerCarouselCard = () => import('../../components/homepage/banner/carousel-card.vue' /* webpackChunkName: "components/homepage-banner-carousel-card" */).then(c => wrapFunctional(c.default || c))
export const HomepageBannerCarousel = () => import('../../components/homepage/banner/carousel.vue' /* webpackChunkName: "components/homepage-banner-carousel" */).then(c => wrapFunctional(c.default || c))
export const HomepageBanner = () => import('../../components/homepage/banner/index.vue' /* webpackChunkName: "components/homepage-banner" */).then(c => wrapFunctional(c.default || c))
export const HomepageClpMultiImage = () => import('../../components/homepage/clp-multi-image/ClpMultiImage.vue' /* webpackChunkName: "components/homepage-clp-multi-image" */).then(c => wrapFunctional(c.default || c))
export const HomepageFeedProduct = () => import('../../components/homepage/feed-product/index.vue' /* webpackChunkName: "components/homepage-feed-product" */).then(c => wrapFunctional(c.default || c))
export const HomepageFeedCard = () => import('../../components/homepage/feedCard/index.vue' /* webpackChunkName: "components/homepage-feed-card" */).then(c => wrapFunctional(c.default || c))
export const HomepageFootPrint = () => import('../../components/homepage/foot-print/index.vue' /* webpackChunkName: "components/homepage-foot-print" */).then(c => wrapFunctional(c.default || c))
export const HomepageNBanner = () => import('../../components/homepage/n-banner/index.vue' /* webpackChunkName: "components/homepage-n-banner" */).then(c => wrapFunctional(c.default || c))
export const HomepagePromoCardCountdown = () => import('../../components/homepage/promo-card/countdown.vue' /* webpackChunkName: "components/homepage-promo-card-countdown" */).then(c => wrapFunctional(c.default || c))
export const HomepagePromoCard = () => import('../../components/homepage/promo-card/index.vue' /* webpackChunkName: "components/homepage-promo-card" */).then(c => wrapFunctional(c.default || c))
export const HomepageSkeletonBannerSkeleton = () => import('../../components/homepage/skeleton/BannerSkeleton.vue' /* webpackChunkName: "components/homepage-skeleton-banner-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomepageSkeletonBuyForOtherSkeleton = () => import('../../components/homepage/skeleton/BuyForOtherSkeleton.vue' /* webpackChunkName: "components/homepage-skeleton-buy-for-other-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomepageSkeletonDownloadSkeleton = () => import('../../components/homepage/skeleton/DownloadSkeleton.vue' /* webpackChunkName: "components/homepage-skeleton-download-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomepageSkeletonFooterPrintSkeleton = () => import('../../components/homepage/skeleton/FooterPrintSkeleton.vue' /* webpackChunkName: "components/homepage-skeleton-footer-print-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomepageSkeletonHotClassificationSkeleton = () => import('../../components/homepage/skeleton/HotClassificationSkeleton.vue' /* webpackChunkName: "components/homepage-skeleton-hot-classification-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomepageSkeletonMoreStoriesSkeleton = () => import('../../components/homepage/skeleton/MoreStoriesSkeleton.vue' /* webpackChunkName: "components/homepage-skeleton-more-stories-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomepageSkeletonMoreWonderSkeleton = () => import('../../components/homepage/skeleton/MoreWonderSkeleton.vue' /* webpackChunkName: "components/homepage-skeleton-more-wonder-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomepageSkeletonTagLineSkeleton = () => import('../../components/homepage/skeleton/TagLineSkeleton.vue' /* webpackChunkName: "components/homepage-skeleton-tag-line-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomepageSkeleton = () => import('../../components/homepage/skeleton/index.vue' /* webpackChunkName: "components/homepage-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HomepageTagLine = () => import('../../components/homepage/tag-line/TagLine.vue' /* webpackChunkName: "components/homepage-tag-line" */).then(c => wrapFunctional(c.default || c))
export const IdentityCertificationCampus = () => import('../../components/identity/certification/Campus.vue' /* webpackChunkName: "components/identity-certification-campus" */).then(c => wrapFunctional(c.default || c))
export const IdentityCertificationMedical = () => import('../../components/identity/certification/Medical.vue' /* webpackChunkName: "components/identity-certification-medical" */).then(c => wrapFunctional(c.default || c))
export const IdentityCertification = () => import('../../components/identity/certification/index.vue' /* webpackChunkName: "components/identity-certification" */).then(c => wrapFunctional(c.default || c))
export const IdentityClpCampusClp = () => import('../../components/identity/clp/CampusClp.vue' /* webpackChunkName: "components/identity-clp-campus-clp" */).then(c => wrapFunctional(c.default || c))
export const IdentityClpMedicaClp = () => import('../../components/identity/clp/MedicaClp.vue' /* webpackChunkName: "components/identity-clp-medica-clp" */).then(c => wrapFunctional(c.default || c))
export const IdentityClpVolunteerClp = () => import('../../components/identity/clp/VolunteerClp.vue' /* webpackChunkName: "components/identity-clp-volunteer-clp" */).then(c => wrapFunctional(c.default || c))
export const IdentityHeader = () => import('../../components/identity/header/Header.vue' /* webpackChunkName: "components/identity-header" */).then(c => wrapFunctional(c.default || c))
export const IdentityHomeCampusHome = () => import('../../components/identity/home/CampusHome.vue' /* webpackChunkName: "components/identity-home-campus-home" */).then(c => wrapFunctional(c.default || c))
export const IdentityHomeMedicalHome = () => import('../../components/identity/home/MedicalHome.vue' /* webpackChunkName: "components/identity-home-medical-home" */).then(c => wrapFunctional(c.default || c))
export const IdentityHomeVolunteerHome = () => import('../../components/identity/home/VolunteerHome.vue' /* webpackChunkName: "components/identity-home-volunteer-home" */).then(c => wrapFunctional(c.default || c))
export const IdentityPosterImg = () => import('../../components/identity/poster-img/index.vue' /* webpackChunkName: "components/identity-poster-img" */).then(c => wrapFunctional(c.default || c))
export const IdentityShare = () => import('../../components/identity/share/index.vue' /* webpackChunkName: "components/identity-share" */).then(c => wrapFunctional(c.default || c))
export const IdentityTrackCode = () => import('../../components/identity/track/code.js' /* webpackChunkName: "components/identity-track-code" */).then(c => wrapFunctional(c.default || c))
export const IdentityTrack = () => import('../../components/identity/track/index.ts' /* webpackChunkName: "components/identity-track" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterComponentsClp = () => import('../../components/login-register/components/clp.vue' /* webpackChunkName: "components/login-register-components-clp" */).then(c => wrapFunctional(c.default || c))
export const LoginMethod = () => import('../../components/login-register/components/loginMethod.vue' /* webpackChunkName: "components/login-method" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterComponentsToClp = () => import('../../components/login-register/components/toClp.vue' /* webpackChunkName: "components/login-register-components-to-clp" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterTrack = () => import('../../components/login-register/track/index.ts' /* webpackChunkName: "components/login-register-track" */).then(c => wrapFunctional(c.default || c))
export const LuckyDraw500Errorerror = () => import('../../components/luckyDraw/500-error/500error.vue' /* webpackChunkName: "components/lucky-draw500-errorerror" */).then(c => wrapFunctional(c.default || c))
export const LuckyDraw500ErrorHeader = () => import('../../components/luckyDraw/500-error/header.vue' /* webpackChunkName: "components/lucky-draw500-error-header" */).then(c => wrapFunctional(c.default || c))
export const LuckyAddress = () => import('../../components/luckyDraw/address/LuckyAddress.vue' /* webpackChunkName: "components/lucky-address" */).then(c => wrapFunctional(c.default || c))
export const LuckyAddressRule = () => import('../../components/luckyDraw/address/LuckyAddressRule.vue' /* webpackChunkName: "components/lucky-address-rule" */).then(c => wrapFunctional(c.default || c))
export const LuckyAddressSelector = () => import('../../components/luckyDraw/address/LuckyAddressSelector.vue' /* webpackChunkName: "components/lucky-address-selector" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawLotteryHead = () => import('../../components/luckyDraw/lottery/Head.vue' /* webpackChunkName: "components/lucky-draw-lottery-head" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawLotteryAnimation = () => import('../../components/luckyDraw/lottery/animation.ts' /* webpackChunkName: "components/lucky-draw-lottery-animation" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawLotteryBlindbox = () => import('../../components/luckyDraw/lottery/blindbox.vue' /* webpackChunkName: "components/lucky-draw-lottery-blindbox" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawLotteryFlipcard = () => import('../../components/luckyDraw/lottery/flipcard.vue' /* webpackChunkName: "components/lucky-draw-lottery-flipcard" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawLottery = () => import('../../components/luckyDraw/lottery/index.vue' /* webpackChunkName: "components/lucky-draw-lottery" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawLotteryPointsAnimation = () => import('../../components/luckyDraw/lottery/pointsAnimation.vue' /* webpackChunkName: "components/lucky-draw-lottery-points-animation" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawLotteryUtils = () => import('../../components/luckyDraw/lottery/utils.ts' /* webpackChunkName: "components/lucky-draw-lottery-utils" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawMyPrizeCard = () => import('../../components/luckyDraw/my-prize/Card.vue' /* webpackChunkName: "components/lucky-draw-my-prize-card" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawMyPrize = () => import('../../components/luckyDraw/my-prize/Prize.vue' /* webpackChunkName: "components/lucky-draw-my-prize" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawPopupBox = () => import('../../components/luckyDraw/popup/box.vue' /* webpackChunkName: "components/lucky-draw-popup-box" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawPopupHit = () => import('../../components/luckyDraw/popup/hit.vue' /* webpackChunkName: "components/lucky-draw-popup-hit" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawPopup = () => import('../../components/luckyDraw/popup/index.vue' /* webpackChunkName: "components/lucky-draw-popup" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawPopupLost = () => import('../../components/luckyDraw/popup/lost.vue' /* webpackChunkName: "components/lucky-draw-popup-lost" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawPrizePool = () => import('../../components/luckyDraw/prize-pool/index.vue' /* webpackChunkName: "components/lucky-draw-prize-pool" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawPrizePoolPreview = () => import('../../components/luckyDraw/prize-pool/preview.vue' /* webpackChunkName: "components/lucky-draw-prize-pool-preview" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawShareGuid = () => import('../../components/luckyDraw/share-guid/index.vue' /* webpackChunkName: "components/lucky-draw-share-guid" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawTrack = () => import('../../components/luckyDraw/track/index.ts' /* webpackChunkName: "components/lucky-draw-track" */).then(c => wrapFunctional(c.default || c))
export const LuckyDrawWinner = () => import('../../components/luckyDraw/winner/index.vue' /* webpackChunkName: "components/lucky-draw-winner" */).then(c => wrapFunctional(c.default || c))
export const MemberShipTrack = () => import('../../components/memberShip/track/index.ts' /* webpackChunkName: "components/member-ship-track" */).then(c => wrapFunctional(c.default || c))
export const MyAccountAccountConfirmation = () => import('../../components/my-account/account/confirmation.vue' /* webpackChunkName: "components/my-account-account-confirmation" */).then(c => wrapFunctional(c.default || c))
export const MyAccountAccountDeregistratonPopover = () => import('../../components/my-account/account/deregistratonPopover.vue' /* webpackChunkName: "components/my-account-account-deregistraton-popover" */).then(c => wrapFunctional(c.default || c))
export const MyAccountAccountUtils = () => import('../../components/my-account/account/utils.ts' /* webpackChunkName: "components/my-account-account-utils" */).then(c => wrapFunctional(c.default || c))
export const MyAccountAddressEditAddress = () => import('../../components/my-account/address/edit-address.vue' /* webpackChunkName: "components/my-account-address-edit-address" */).then(c => wrapFunctional(c.default || c))
export const MyAccountAddressEmpty = () => import('../../components/my-account/address/empty.vue' /* webpackChunkName: "components/my-account-address-empty" */).then(c => wrapFunctional(c.default || c))
export const MyAccountCouponCard = () => import('../../components/my-account/coupon/Card.vue' /* webpackChunkName: "components/my-account-coupon-card" */).then(c => wrapFunctional(c.default || c))
export const MyAccountCouponNoCoupon = () => import('../../components/my-account/coupon/NoCoupon.vue' /* webpackChunkName: "components/my-account-coupon-no-coupon" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMyAccountSkeletonMobile = () => import('../../components/my-account/my-account-skeleton/SkeletonMobile.vue' /* webpackChunkName: "components/my-account-my-account-skeleton-mobile" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMyAccountSkeletonPc = () => import('../../components/my-account/my-account-skeleton/SkeletonPc.vue' /* webpackChunkName: "components/my-account-my-account-skeleton-pc" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnSideBar = () => import('../../components/my-account/return/sideBar.vue' /* webpackChunkName: "components/my-account-return-side-bar" */).then(c => wrapFunctional(c.default || c))
export const MyAccountWxAppDownload = () => import('../../components/my-account/wx-app-download/index.js' /* webpackChunkName: "components/my-account-wx-app-download" */).then(c => wrapFunctional(c.default || c))
export const NavigationComponentsDropdown = () => import('../../components/navigation/Components/dropdown.vue' /* webpackChunkName: "components/navigation-components-dropdown" */).then(c => wrapFunctional(c.default || c))
export const NpsCesQuestionDifficulty = () => import('../../components/nps-ces/question/difficulty.vue' /* webpackChunkName: "components/nps-ces-question-difficulty" */).then(c => wrapFunctional(c.default || c))
export const NpsCesQuestionOptions = () => import('../../components/nps-ces/question/options.vue' /* webpackChunkName: "components/nps-ces-question-options" */).then(c => wrapFunctional(c.default || c))
export const NpsCesQuestionOther = () => import('../../components/nps-ces/question/other.vue' /* webpackChunkName: "components/nps-ces-question-other" */).then(c => wrapFunctional(c.default || c))
export const NpsCesQuestionScores = () => import('../../components/nps-ces/question/scores.vue' /* webpackChunkName: "components/nps-ces-question-scores" */).then(c => wrapFunctional(c.default || c))
export const NpsCesTrack = () => import('../../components/nps-ces/track/index.ts' /* webpackChunkName: "components/nps-ces-track" */).then(c => wrapFunctional(c.default || c))
export const PartnershipTrack = () => import('../../components/partnership/track/index.ts' /* webpackChunkName: "components/partnership-track" */).then(c => wrapFunctional(c.default || c))
export const PdpAcCodeSsrAsyncData = () => import('../../components/pdp/ac-code/ssr.async.data.js' /* webpackChunkName: "components/pdp-ac-code-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PdpBottomTool = () => import('../../components/pdp/bottom-tool/BottomTool.vue' /* webpackChunkName: "components/pdp-bottom-tool" */).then(c => wrapFunctional(c.default || c))
export const PdpComments = () => import('../../components/pdp/comments/Comments.vue' /* webpackChunkName: "components/pdp-comments" */).then(c => wrapFunctional(c.default || c))
export const PdpCommentsMoreComBus = () => import('../../components/pdp/comments/moreComBus.js' /* webpackChunkName: "components/pdp-comments-more-com-bus" */).then(c => wrapFunctional(c.default || c))
export const PdpCommentsSsrAsyncData = () => import('../../components/pdp/comments/ssr.async.data.js' /* webpackChunkName: "components/pdp-comments-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PdpCouponItem = () => import('../../components/pdp/coupon/couponItem.vue' /* webpackChunkName: "components/pdp-coupon-item" */).then(c => wrapFunctional(c.default || c))
export const PdpCoupon = () => import('../../components/pdp/coupon/index.vue' /* webpackChunkName: "components/pdp-coupon" */).then(c => wrapFunctional(c.default || c))
export const PdpCouponPanel = () => import('../../components/pdp/coupon/panel.vue' /* webpackChunkName: "components/pdp-coupon-panel" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailCollectionSpot = () => import('../../components/pdp/detail-collection-spot/index.vue' /* webpackChunkName: "components/pdp-detail-collection-spot" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailCollectionSpotSsrAsyncData = () => import('../../components/pdp/detail-collection-spot/ssr.async.data.js' /* webpackChunkName: "components/pdp-detail-collection-spot-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PdpHighlights = () => import('../../components/pdp/highlights/highlights.vue' /* webpackChunkName: "components/pdp-highlights" */).then(c => wrapFunctional(c.default || c))
export const PdpHighlightsList = () => import('../../components/pdp/highlights/list.vue' /* webpackChunkName: "components/pdp-highlights-list" */).then(c => wrapFunctional(c.default || c))
export const PdpHighlightsUtils = () => import('../../components/pdp/highlights/utils.ts' /* webpackChunkName: "components/pdp-highlights-utils" */).then(c => wrapFunctional(c.default || c))
export const PdpImageItem = () => import('../../components/pdp/image-list/ImageItem.vue' /* webpackChunkName: "components/pdp-image-item" */).then(c => wrapFunctional(c.default || c))
export const PdpImageList = () => import('../../components/pdp/image-list/ImageList.vue' /* webpackChunkName: "components/pdp-image-list" */).then(c => wrapFunctional(c.default || c))
export const PdpImageListPreview = () => import('../../components/pdp/image-list/ImageListPreview.vue' /* webpackChunkName: "components/pdp-image-list-preview" */).then(c => wrapFunctional(c.default || c))
export const PdpImagePreview = () => import('../../components/pdp/image-list/ImagePreview.vue' /* webpackChunkName: "components/pdp-image-preview" */).then(c => wrapFunctional(c.default || c))
export const PdpImageProduct = () => import('../../components/pdp/image-list/ImageProduct.vue' /* webpackChunkName: "components/pdp-image-product" */).then(c => wrapFunctional(c.default || c))
export const PdpImageScaleItem = () => import('../../components/pdp/image-list/ImageScaleItem.vue' /* webpackChunkName: "components/pdp-image-scale-item" */).then(c => wrapFunctional(c.default || c))
export const PdpImageTouch = () => import('../../components/pdp/image-list/ImageTouch.js' /* webpackChunkName: "components/pdp-image-touch" */).then(c => wrapFunctional(c.default || c))
export const PdpImageListShowImage = () => import('../../components/pdp/image-list/ShowImage.vue' /* webpackChunkName: "components/pdp-image-list-show-image" */).then(c => wrapFunctional(c.default || c))
export const PdpImageListTouchCls = () => import('../../components/pdp/image-list/TouchCls.js' /* webpackChunkName: "components/pdp-image-list-touch-cls" */).then(c => wrapFunctional(c.default || c))
export const PdpImageListEventBus = () => import('../../components/pdp/image-list/eventBus.js' /* webpackChunkName: "components/pdp-image-list-event-bus" */).then(c => wrapFunctional(c.default || c))
export const PdpProductSsrAsyncData = () => import('../../components/pdp/product/ssr.async.data.js' /* webpackChunkName: "components/pdp-product-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PdpSelector = () => import('../../components/pdp/selector/Selector.vue' /* webpackChunkName: "components/pdp-selector" */).then(c => wrapFunctional(c.default || c))
export const PdpSelectorCountdown = () => import('../../components/pdp/selector/countdown.vue' /* webpackChunkName: "components/pdp-selector-countdown" */).then(c => wrapFunctional(c.default || c))
export const PdpSelectorLabelBehindTitle = () => import('../../components/pdp/selector/labelBehindTitle.vue' /* webpackChunkName: "components/pdp-selector-label-behind-title" */).then(c => wrapFunctional(c.default || c))
export const PdpSelectorPromoMsg = () => import('../../components/pdp/selector/promoMsg.vue' /* webpackChunkName: "components/pdp-selector-promo-msg" */).then(c => wrapFunctional(c.default || c))
export const PdpSizeTable = () => import('../../components/pdp/size-table/index.vue' /* webpackChunkName: "components/pdp-size-table" */).then(c => wrapFunctional(c.default || c))
export const PdpSwiperSlide = () => import('../../components/pdp/swiper/Slide.vue' /* webpackChunkName: "components/pdp-swiper-slide" */).then(c => wrapFunctional(c.default || c))
export const PdpSwiper = () => import('../../components/pdp/swiper/Swiper.vue' /* webpackChunkName: "components/pdp-swiper" */).then(c => wrapFunctional(c.default || c))
export const PdpUrlSchemeSsrAsyncData = () => import('../../components/pdp/url-scheme/ssr.async.data.js' /* webpackChunkName: "components/pdp-url-scheme-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PlpBanner = () => import('../../components/plp/banner/index.vue' /* webpackChunkName: "components/plp-banner" */).then(c => wrapFunctional(c.default || c))
export const PlpBannerMobile = () => import('../../components/plp/banner/mobile.vue' /* webpackChunkName: "components/plp-banner-mobile" */).then(c => wrapFunctional(c.default || c))
export const PlpFilterBar = () => import('../../components/plp/filter/filterBar.vue' /* webpackChunkName: "components/plp-filter-bar" */).then(c => wrapFunctional(c.default || c))
export const PlpFilterSsrAsyncData = () => import('../../components/plp/filter/ssr.async.data.js' /* webpackChunkName: "components/plp-filter-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PlpPlp = () => import('../../components/plp/plp/index.vue' /* webpackChunkName: "components/plp-plp" */).then(c => wrapFunctional(c.default || c))
export const PlpPlpSplpIndex = () => import('../../components/plp/plp/splp-index.vue' /* webpackChunkName: "components/plp-plp-splp-index" */).then(c => wrapFunctional(c.default || c))
export const PlpPlpFilterQuickFilter = () => import('../../components/plp/plp-filter/quickFilter.vue' /* webpackChunkName: "components/plp-plp-filter-quick-filter" */).then(c => wrapFunctional(c.default || c))
export const PlpPlpFilterSortFilter = () => import('../../components/plp/plp-filter/sortFilter.vue' /* webpackChunkName: "components/plp-plp-filter-sort-filter" */).then(c => wrapFunctional(c.default || c))
export const PlpPlpFilterTitle = () => import('../../components/plp/plp-filter/title.vue' /* webpackChunkName: "components/plp-plp-filter-title" */).then(c => wrapFunctional(c.default || c))
export const PlpNoResult = () => import('../../components/plp/plp-no-result/plpNoResult.vue' /* webpackChunkName: "components/plp-no-result" */).then(c => wrapFunctional(c.default || c))
export const PlpPlpNoResultSsrAsyncData = () => import('../../components/plp/plp-no-result/ssr.async.data.js' /* webpackChunkName: "components/plp-plp-no-result-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PlpPlpPcSearchResult = () => import('../../components/plp/plp-pc-search-result/index.vue' /* webpackChunkName: "components/plp-plp-pc-search-result" */).then(c => wrapFunctional(c.default || c))
export const PlpTitle = () => import('../../components/plp/plp-title-sort/plpTitle.vue' /* webpackChunkName: "components/plp-title" */).then(c => wrapFunctional(c.default || c))
export const PlpProductCardLoadMoreSkeleton = () => import('../../components/plp/product-card/LoadMoreSkeleton.vue' /* webpackChunkName: "components/plp-product-card-load-more-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PlpProductCardSkeleton = () => import('../../components/plp/product-card/ProductCardSkeleton.vue' /* webpackChunkName: "components/plp-product-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PlpProductCardDeleteSsrAsyncData = () => import('../../components/plp/product-card/delete_ssr.async.data.js' /* webpackChunkName: "components/plp-product-card-delete-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PlpProductCard = () => import('../../components/plp/product-card/productCard.vue' /* webpackChunkName: "components/plp-product-card" */).then(c => wrapFunctional(c.default || c))
export const PlpProductCardSsrAsyncData = () => import('../../components/plp/product-card/ssr.async.data.js' /* webpackChunkName: "components/plp-product-card-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PlpPromotionFilter = () => import('../../components/plp/promotion-filter/index.vue' /* webpackChunkName: "components/plp-promotion-filter" */).then(c => wrapFunctional(c.default || c))
export const PlpSplpFilter = () => import('../../components/plp/splp-filter/index.vue' /* webpackChunkName: "components/plp-splp-filter" */).then(c => wrapFunctional(c.default || c))
export const PlpSplpFilterTabPageFilter = () => import('../../components/plp/splp-filter/tabPageFilter.vue' /* webpackChunkName: "components/plp-splp-filter-tab-page-filter" */).then(c => wrapFunctional(c.default || c))
export const PlpTabFilter = () => import('../../components/plp/tab-filter/index.vue' /* webpackChunkName: "components/plp-tab-filter" */).then(c => wrapFunctional(c.default || c))
export const PlpTrack = () => import('../../components/plp/track/index.ts' /* webpackChunkName: "components/plp-track" */).then(c => wrapFunctional(c.default || c))
export const SearchMobileSearch = () => import('../../components/search/mobile-search/index.vue' /* webpackChunkName: "components/search-mobile-search" */).then(c => wrapFunctional(c.default || c))
export const SearchMobileSearchComponent = () => import('../../components/search/mobile-search-component/index.vue' /* webpackChunkName: "components/search-mobile-search-component" */).then(c => wrapFunctional(c.default || c))
export const SearchMobileSearchComponentObservable = () => import('../../components/search/mobile-search-component/observable.js' /* webpackChunkName: "components/search-mobile-search-component-observable" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/search/search-bar/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchBarRest = () => import('../../components/search/search-bar/SearchBarRest.vue' /* webpackChunkName: "components/search-bar-rest" */).then(c => wrapFunctional(c.default || c))
export const SearchMobile = () => import('../../components/search/search-joint/SearchMobile.vue' /* webpackChunkName: "components/search-mobile" */).then(c => wrapFunctional(c.default || c))
export const SearchPc = () => import('../../components/search/search-joint/SearchPc.vue' /* webpackChunkName: "components/search-pc" */).then(c => wrapFunctional(c.default || c))
export const SearchNoResult = () => import('../../components/search/search-no-result/SearchNoResult.vue' /* webpackChunkName: "components/search-no-result" */).then(c => wrapFunctional(c.default || c))
export const SearchSearchOverlay = () => import('../../components/search/search-overlay/index.vue' /* webpackChunkName: "components/search-search-overlay" */).then(c => wrapFunctional(c.default || c))
export const SearchPcSkeleton = () => import('../../components/search/search-pc-skeleton/SearchPcSkeleton.vue' /* webpackChunkName: "components/search-pc-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SfPickupInformation = () => import('../../components/sf-pickup/Information/index.vue' /* webpackChunkName: "components/sf-pickup-information" */).then(c => wrapFunctional(c.default || c))
export const SfPickupInformationPc = () => import('../../components/sf-pickup/Information/pc.vue' /* webpackChunkName: "components/sf-pickup-information-pc" */).then(c => wrapFunctional(c.default || c))
export const SfPickupInformationSelectTime = () => import('../../components/sf-pickup/Information/selectTime.vue' /* webpackChunkName: "components/sf-pickup-information-select-time" */).then(c => wrapFunctional(c.default || c))
export const SfPickupCombine = () => import('../../components/sf-pickup/combine/index.vue' /* webpackChunkName: "components/sf-pickup-combine" */).then(c => wrapFunctional(c.default || c))
export const SfPickupCombineItem = () => import('../../components/sf-pickup/combine/item.vue' /* webpackChunkName: "components/sf-pickup-combine-item" */).then(c => wrapFunctional(c.default || c))
export const SmartBannerTrack = () => import('../../components/smart-banner/track/index.ts' /* webpackChunkName: "components/smart-banner-track" */).then(c => wrapFunctional(c.default || c))
export const SplpPromoCardCountdown = () => import('../../components/splp/promo-card/countdown.vue' /* webpackChunkName: "components/splp-promo-card-countdown" */).then(c => wrapFunctional(c.default || c))
export const SplpPromoCard = () => import('../../components/splp/promo-card/index.vue' /* webpackChunkName: "components/splp-promo-card" */).then(c => wrapFunctional(c.default || c))
export const SplpSingleImage = () => import('../../components/splp/single-image/index.vue' /* webpackChunkName: "components/splp-single-image" */).then(c => wrapFunctional(c.default || c))
export const SplpTextLink = () => import('../../components/splp/text-link/TextLink.vue' /* webpackChunkName: "components/splp-text-link" */).then(c => wrapFunctional(c.default || c))
export const SplpVideo = () => import('../../components/splp/video/index.vue' /* webpackChunkName: "components/splp-video" */).then(c => wrapFunctional(c.default || c))
export const BraFinderCheckboxSelectorComponentsItem = () => import('../../components/braFinder/checkboxSelector/components/item.vue' /* webpackChunkName: "components/bra-finder-checkbox-selector-components-item" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatalogPcNbanner = () => import('../../components/catalog/catalog-pc/Nbanner/index.vue' /* webpackChunkName: "components/catalog-catalog-pc-nbanner" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatalogPcNbannerOther = () => import('../../components/catalog/catalog-pc/Nbanner-other/index.vue' /* webpackChunkName: "components/catalog-catalog-pc-nbanner-other" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatalogPcOperate = () => import('../../components/catalog/catalog-pc/Operate/index.vue' /* webpackChunkName: "components/catalog-catalog-pc-operate" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatalogPcSudoku = () => import('../../components/catalog/catalog-pc/Sudoku/index.vue' /* webpackChunkName: "components/catalog-catalog-pc-sudoku" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatalogPcTitle = () => import('../../components/catalog/catalog-pc/Title/index.vue' /* webpackChunkName: "components/catalog-catalog-pc-title" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatelogMobileExclusive = () => import('../../components/catalog/catelog-mobile/exclusive/exclusive.vue' /* webpackChunkName: "components/catalog-catelog-mobile-exclusive" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatelogMobileCatalogBar = () => import('../../components/catalog/catelog-mobile/mobileCatalogBar/mobileCatalogBar.vue' /* webpackChunkName: "components/catalog-catelog-mobile-catalog-bar" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatelogMobileNavigation = () => import('../../components/catalog/catelog-mobile/navigation/navigation.vue' /* webpackChunkName: "components/catalog-catelog-mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatelogMobileNbanner = () => import('../../components/catalog/catelog-mobile/nbanner/index.vue' /* webpackChunkName: "components/catalog-catelog-mobile-nbanner" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatelogMobileNine = () => import('../../components/catalog/catelog-mobile/nine/nine.vue' /* webpackChunkName: "components/catalog-catelog-mobile-nine" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatelogMobileTitle = () => import('../../components/catalog/catelog-mobile/title/index.vue' /* webpackChunkName: "components/catalog-catelog-mobile-title" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentAmount = () => import('../../components/checkout/payment/amount/Amount.vue' /* webpackChunkName: "components/checkout-payment-amount" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentChecklist = () => import('../../components/checkout/payment/checklist/Checklist.vue' /* webpackChunkName: "components/checkout-payment-checklist" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentChecklistGoodsCard = () => import('../../components/checkout/payment/checklist/GoodsCard.vue' /* webpackChunkName: "components/checkout-payment-checklist-goods-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentDeliveryInfo = () => import('../../components/checkout/payment/delivery-info/DeliveryInfo.vue' /* webpackChunkName: "components/checkout-payment-delivery-info" */).then(c => wrapFunctional(c.default || c))
export const ClpImageSlideProductCard = () => import('../../components/clp/image-slide-product/components/ProductCard.vue' /* webpackChunkName: "components/clp-image-slide-product-card" */).then(c => wrapFunctional(c.default || c))
export const ClpImageSlideProductComponentsMobileList = () => import('../../components/clp/image-slide-product/components/mobile-list.vue' /* webpackChunkName: "components/clp-image-slide-product-components-mobile-list" */).then(c => wrapFunctional(c.default || c))
export const ClpImageSlideProductComponentsPcList = () => import('../../components/clp/image-slide-product/components/pc-list.vue' /* webpackChunkName: "components/clp-image-slide-product-components-pc-list" */).then(c => wrapFunctional(c.default || c))
export const ClpLookComponentsList = () => import('../../components/clp/look/components/list.vue' /* webpackChunkName: "components/clp-look-components-list" */).then(c => wrapFunctional(c.default || c))
export const ClpLookComponentsScroll = () => import('../../components/clp/look/components/scroll.ts' /* webpackChunkName: "components/clp-look-components-scroll" */).then(c => wrapFunctional(c.default || c))
export const ClpLookComponentsVideo = () => import('../../components/clp/look/components/video.vue' /* webpackChunkName: "components/clp-look-components-video" */).then(c => wrapFunctional(c.default || c))
export const ClpPplpComponentsIndicator = () => import('../../components/clp/pplp/components/indicator.vue' /* webpackChunkName: "components/clp-pplp-components-indicator" */).then(c => wrapFunctional(c.default || c))
export const ClpPplpComponentsList = () => import('../../components/clp/pplp/components/list.vue' /* webpackChunkName: "components/clp-pplp-components-list" */).then(c => wrapFunctional(c.default || c))
export const ClpPplpComponentsLoading = () => import('../../components/clp/pplp/components/loading.vue' /* webpackChunkName: "components/clp-pplp-components-loading" */).then(c => wrapFunctional(c.default || c))
export const ClpPplpComponentsNoMoreList = () => import('../../components/clp/pplp/components/noMoreList.vue' /* webpackChunkName: "components/clp-pplp-components-no-more-list" */).then(c => wrapFunctional(c.default || c))
export const ClpPplpComponentsSkeleton = () => import('../../components/clp/pplp/components/skeleton.vue' /* webpackChunkName: "components/clp-pplp-components-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyTrack = () => import('../../components/clp/survey/track/index.ts' /* webpackChunkName: "components/clp-survey-track" */).then(c => wrapFunctional(c.default || c))
export const ClpVerticalTabPageComponentsMobile = () => import('../../components/clp/verticalTabPage/components/mobile.vue' /* webpackChunkName: "components/clp-vertical-tab-page-components-mobile" */).then(c => wrapFunctional(c.default || c))
export const ClpVerticalTabPageComponentsPc = () => import('../../components/clp/verticalTabPage/components/pc.vue' /* webpackChunkName: "components/clp-vertical-tab-page-components-pc" */).then(c => wrapFunctional(c.default || c))
export const ClpVerticalTabPageComponentsProductCard = () => import('../../components/clp/verticalTabPage/components/productCard.vue' /* webpackChunkName: "components/clp-vertical-tab-page-components-product-card" */).then(c => wrapFunctional(c.default || c))
export const CommonTabListDemo = () => import('../../components/common/tab-list/demo/index.vue' /* webpackChunkName: "components/common-tab-list-demo" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupPanelBonus = () => import('../../components/dailyCheckIn/popup/panel/bonus.vue' /* webpackChunkName: "components/daily-check-in-popup-panel-bonus" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupPanelError = () => import('../../components/dailyCheckIn/popup/panel/error.vue' /* webpackChunkName: "components/daily-check-in-popup-panel-error" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupPanelNormal = () => import('../../components/dailyCheckIn/popup/panel/normal.vue' /* webpackChunkName: "components/daily-check-in-popup-panel-normal" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupPanelPreNext = () => import('../../components/dailyCheckIn/popup/panel/pre-next.vue' /* webpackChunkName: "components/daily-check-in-popup-panel-pre-next" */).then(c => wrapFunctional(c.default || c))
export const DailyCheckInPopupPanelRemedy = () => import('../../components/dailyCheckIn/popup/panel/remedy.vue' /* webpackChunkName: "components/daily-check-in-popup-panel-remedy" */).then(c => wrapFunctional(c.default || c))
export const HomepageFeedProductComponentsMobileProductList = () => import('../../components/homepage/feed-product/components/mobileProductList.vue' /* webpackChunkName: "components/homepage-feed-product-components-mobile-product-list" */).then(c => wrapFunctional(c.default || c))
export const HomepageFeedProductList = () => import('../../components/homepage/feed-product/components/productList.vue' /* webpackChunkName: "components/homepage-feed-product-list" */).then(c => wrapFunctional(c.default || c))
export const MyAccountAddressAddressList = () => import('../../components/my-account/address/address-list/index.vue' /* webpackChunkName: "components/my-account-address-address-list" */).then(c => wrapFunctional(c.default || c))
export const MyAccountExchangeDetailCancelPopover = () => import('../../components/my-account/exchange/detail/CancelPopover.vue' /* webpackChunkName: "components/my-account-exchange-detail-cancel-popover" */).then(c => wrapFunctional(c.default || c))
export const MyAccountExchangeDetailStep = () => import('../../components/my-account/exchange/detail/Step.vue' /* webpackChunkName: "components/my-account-exchange-detail-step" */).then(c => wrapFunctional(c.default || c))
export const MyAccountExchangeDetail = () => import('../../components/my-account/exchange/detail/index.vue' /* webpackChunkName: "components/my-account-exchange-detail" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberFootPrint = () => import('../../components/my-account/member/foot-print/index.vue' /* webpackChunkName: "components/my-account-member-foot-print" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberHeadEmployeeCard = () => import('../../components/my-account/member/head/EmployeeCard.vue' /* webpackChunkName: "components/my-account-member-head-employee-card" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberCard = () => import('../../components/my-account/member/head/MemberCard.vue' /* webpackChunkName: "components/my-account-member-card" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberHeadOtherCard = () => import('../../components/my-account/member/head/OtherCard.vue' /* webpackChunkName: "components/my-account-member-head-other-card" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberHeadPartnerCard = () => import('../../components/my-account/member/head/PartnerCard.vue' /* webpackChunkName: "components/my-account-member-head-partner-card" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberHeadPointShop = () => import('../../components/my-account/member/head/PointShop.vue' /* webpackChunkName: "components/my-account-member-head-point-shop" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberHead = () => import('../../components/my-account/member/head/index.vue' /* webpackChunkName: "components/my-account-member-head" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberMemberBenefit = () => import('../../components/my-account/member/member-benefit/index.vue' /* webpackChunkName: "components/my-account-member-member-benefit" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberMyOrder = () => import('../../components/my-account/member/my-order/index.vue' /* webpackChunkName: "components/my-account-member-my-order" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberOther = () => import('../../components/my-account/member/other/index.vue' /* webpackChunkName: "components/my-account-member-other" */).then(c => wrapFunctional(c.default || c))
export const MyAccountMemberRecommend = () => import('../../components/my-account/member/recommend/index.vue' /* webpackChunkName: "components/my-account-member-recommend" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderDetail = () => import('../../components/my-account/order/detail/OrderDetail.vue' /* webpackChunkName: "components/my-account-order-detail" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderDetailProd = () => import('../../components/my-account/order/detail/Prod.vue' /* webpackChunkName: "components/my-account-order-detail-prod" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderDetailRefund = () => import('../../components/my-account/order/detail/Refund.vue' /* webpackChunkName: "components/my-account-order-detail-refund" */).then(c => wrapFunctional(c.default || c))
export const MyAccountRefundOnlyDetail = () => import('../../components/my-account/refund-only/detail/index.vue' /* webpackChunkName: "components/my-account-refund-only-detail" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnLogisticsInf = () => import('../../components/my-account/return/logistics-inf/LogisticsInf.vue' /* webpackChunkName: "components/my-account-return-logistics-inf" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnOrderDetailMobile = () => import('../../components/my-account/return/order-detail/DetailMobile.vue' /* webpackChunkName: "components/my-account-return-order-detail-mobile" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnOrderDetailPc = () => import('../../components/my-account/return/order-detail/DetailPc.vue' /* webpackChunkName: "components/my-account-return-order-detail-pc" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnOrderDetailStep = () => import('../../components/my-account/return/order-detail/Step.vue' /* webpackChunkName: "components/my-account-return-order-detail-step" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnOrderDetail = () => import('../../components/my-account/return/order-detail/index.vue' /* webpackChunkName: "components/my-account-return-order-detail" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnOrderList = () => import('../../components/my-account/return/order-list/index.vue' /* webpackChunkName: "components/my-account-return-order-list" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnOrderItem = () => import('../../components/my-account/return/order-list/orderItem.vue' /* webpackChunkName: "components/my-account-return-order-item" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnReturnApply = () => import('../../components/my-account/return/return-apply/index.js' /* webpackChunkName: "components/my-account-return-return-apply" */).then(c => wrapFunctional(c.default || c))
export const PdpCommentsComponentsOverallRating = () => import('../../components/pdp/comments/components/OverallRating.vue' /* webpackChunkName: "components/pdp-comments-components-overall-rating" */).then(c => wrapFunctional(c.default || c))
export const PdpCommentsComponentsReviewCard = () => import('../../components/pdp/comments/components/ReviewCard.vue' /* webpackChunkName: "components/pdp-comments-components-review-card" */).then(c => wrapFunctional(c.default || c))
export const PdpCommentsComponentsReviewList = () => import('../../components/pdp/comments/components/ReviewList.vue' /* webpackChunkName: "components/pdp-comments-components-review-list" */).then(c => wrapFunctional(c.default || c))
export const PdpCommentsComponentsStatusBar = () => import('../../components/pdp/comments/components/StatusBar.vue' /* webpackChunkName: "components/pdp-comments-components-status-bar" */).then(c => wrapFunctional(c.default || c))
export const PdpCommentsComponentsUnlessPrompt = () => import('../../components/pdp/comments/components/UnlessPrompt.vue' /* webpackChunkName: "components/pdp-comments-components-unless-prompt" */).then(c => wrapFunctional(c.default || c))
export const PdpCommentsComponentsUnlessReviewBar = () => import('../../components/pdp/comments/components/UnlessReviewBar.vue' /* webpackChunkName: "components/pdp-comments-components-unless-review-bar" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailCollocation = () => import('../../components/pdp/detail-collection-spot/detail-collocation/DetailCollocation.vue' /* webpackChunkName: "components/pdp-detail-collocation" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailCollectionSpotDetailCollocationSsrAsyncData = () => import('../../components/pdp/detail-collection-spot/detail-collocation/ssr.async.data.js' /* webpackChunkName: "components/pdp-detail-collection-spot-detail-collocation-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailHiglights = () => import('../../components/pdp/detail-collection-spot/detail-higlights/detail-higlights.vue' /* webpackChunkName: "components/pdp-detail-higlights" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailLights = () => import('../../components/pdp/detail-collection-spot/detail-lightspot/DetailLights.vue' /* webpackChunkName: "components/pdp-detail-lights" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailCollectionSpotDetailLightspotSwiperPc = () => import('../../components/pdp/detail-collection-spot/detail-lightspot/SwiperPc.vue' /* webpackChunkName: "components/pdp-detail-collection-spot-detail-lightspot-swiper-pc" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailCollectionSpotDetailLightspotHightLightPc = () => import('../../components/pdp/detail-collection-spot/detail-lightspot/hightLightPc.vue' /* webpackChunkName: "components/pdp-detail-collection-spot-detail-lightspot-hight-light-pc" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailCollectionSpotDetailLightspotSsrAsyncData = () => import('../../components/pdp/detail-collection-spot/detail-lightspot/ssr.async.data.js' /* webpackChunkName: "components/pdp-detail-collection-spot-detail-lightspot-ssr-async-data" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailCollectionSpotProductDetails = () => import('../../components/pdp/detail-collection-spot/product-details/ProductDetails.vue' /* webpackChunkName: "components/pdp-detail-collection-spot-product-details" */).then(c => wrapFunctional(c.default || c))
export const PdpDetailCollectionSpotProductDetailsVideoMobile = () => import('../../components/pdp/detail-collection-spot/product-details/videoMobile.vue' /* webpackChunkName: "components/pdp-detail-collection-spot-product-details-video-mobile" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonPageIndicatorLibPageIndicator = () => import('../../components/plp/common/pageIndicator/LibPageIndicator.vue' /* webpackChunkName: "components/plp-common-page-indicator-lib-page-indicator" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonProductCard = () => import('../../components/plp/common/productCard/index.vue' /* webpackChunkName: "components/plp-common-product-card" */).then(c => wrapFunctional(c.default || c))
export const PlpFilterSkeleton = () => import('../../components/plp/filter/filter-skeleton/filterSkeleton.vue' /* webpackChunkName: "components/plp-filter-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PlpProductNoMore = () => import('../../components/plp/product-card/product-nomore/productNoMore.vue' /* webpackChunkName: "components/plp-product-no-more" */).then(c => wrapFunctional(c.default || c))
export const PlpSplpFilterMobileSort = () => import('../../components/plp/splp-filter/mobile/sort.vue' /* webpackChunkName: "components/plp-splp-filter-mobile-sort" */).then(c => wrapFunctional(c.default || c))
export const SearchMobileSearchSearchInput = () => import('../../components/search/mobile-search/search-input/index.vue' /* webpackChunkName: "components/search-mobile-search-search-input" */).then(c => wrapFunctional(c.default || c))
export const SearchSearchOverlayTrack = () => import('../../components/search/search-overlay/track/index.ts' /* webpackChunkName: "components/search-search-overlay-track" */).then(c => wrapFunctional(c.default || c))
export const SplpPromoCardTrack = () => import('../../components/splp/promo-card/track/index.ts' /* webpackChunkName: "components/splp-promo-card-track" */).then(c => wrapFunctional(c.default || c))
export const CatalogCatelogMobileNineTitle = () => import('../../components/catalog/catelog-mobile/nine/nine-title/nineTitle.vue' /* webpackChunkName: "components/catalog-catelog-mobile-nine-title" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsCommonImage = () => import('../../components/clp/look-group/components/common/image.vue' /* webpackChunkName: "components/clp-look-group-components-common-image" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsCommonProductCardPc = () => import('../../components/clp/look-group/components/common/product-card-pc.vue' /* webpackChunkName: "components/clp-look-group-components-common-product-card-pc" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsCommonProductCard = () => import('../../components/clp/look-group/components/common/product-card.vue' /* webpackChunkName: "components/clp-look-group-components-common-product-card" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsCommonScroll = () => import('../../components/clp/look-group/components/common/scroll.ts' /* webpackChunkName: "components/clp-look-group-components-common-scroll" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsCommonUtils = () => import('../../components/clp/look-group/components/common/utils.ts' /* webpackChunkName: "components/clp-look-group-components-common-utils" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsCommonVideo = () => import('../../components/clp/look-group/components/common/video.vue' /* webpackChunkName: "components/clp-look-group-components-common-video" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsLeftRight = () => import('../../components/clp/look-group/components/leftRight/index.vue' /* webpackChunkName: "components/clp-look-group-components-left-right" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsLeftRightList = () => import('../../components/clp/look-group/components/leftRight/list.vue' /* webpackChunkName: "components/clp-look-group-components-left-right-list" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsUpDown = () => import('../../components/clp/look-group/components/up_down/index.vue' /* webpackChunkName: "components/clp-look-group-components-up-down" */).then(c => wrapFunctional(c.default || c))
export const ClpLookGroupComponentsUpDownList = () => import('../../components/clp/look-group/components/up_down/list.vue' /* webpackChunkName: "components/clp-look-group-components-up-down-list" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyComponentsTextareaComTextarea = () => import('../../components/clp/survey/components/textarea/ComTextarea.vue' /* webpackChunkName: "components/clp-survey-components-textarea-com-textarea" */).then(c => wrapFunctional(c.default || c))
export const ClpSurveyComponentsTextareaCalcTextareaHeight = () => import('../../components/clp/survey/components/textarea/calcTextareaHeight.js' /* webpackChunkName: "components/clp-survey-components-textarea-calc-textarea-height" */).then(c => wrapFunctional(c.default || c))
export const MyAccountAddressAddressListComponentsCard = () => import('../../components/my-account/address/address-list/components/card.vue' /* webpackChunkName: "components/my-account-address-address-list-components-card" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderDetailApply = () => import('../../components/my-account/order/detail/apply/Apply.vue' /* webpackChunkName: "components/my-account-order-detail-apply" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderDetailAssess = () => import('../../components/my-account/order/detail/assess/Assess.vue' /* webpackChunkName: "components/my-account-order-detail-assess" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderDetailAssessSuccessAssess = () => import('../../components/my-account/order/detail/assess/SuccessAssess.vue' /* webpackChunkName: "components/my-account-order-detail-assess-success-assess" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderDetailExchange = () => import('../../components/my-account/order/detail/exchange/Exchange.vue' /* webpackChunkName: "components/my-account-order-detail-exchange" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderDetailLogistics = () => import('../../components/my-account/order/detail/logistics/Logistics.vue' /* webpackChunkName: "components/my-account-order-detail-logistics" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderListTabAllPage = () => import('../../components/my-account/order/list/tab/allPage.vue' /* webpackChunkName: "components/my-account-order-list-tab-all-page" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderListTabCommentPage = () => import('../../components/my-account/order/list/tab/commentPage.vue' /* webpackChunkName: "components/my-account-order-list-tab-comment-page" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderListTabDeliveryPage = () => import('../../components/my-account/order/list/tab/deliveryPage.vue' /* webpackChunkName: "components/my-account-order-list-tab-delivery-page" */).then(c => wrapFunctional(c.default || c))
export const MyAccountOrderListTabPayPage = () => import('../../components/my-account/order/list/tab/payPage.vue' /* webpackChunkName: "components/my-account-order-list-tab-pay-page" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnOrderListTabExchangePage = () => import('../../components/my-account/return/order-list/tab/exchangePage.vue' /* webpackChunkName: "components/my-account-return-order-list-tab-exchange-page" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnOrderListTabOnlyReturnPage = () => import('../../components/my-account/return/order-list/tab/onlyReturnPage.vue' /* webpackChunkName: "components/my-account-return-order-list-tab-only-return-page" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnPage = () => import('../../components/my-account/return/order-list/tab/returnPage.vue' /* webpackChunkName: "components/my-account-return-page" */).then(c => wrapFunctional(c.default || c))
export const MyAccountReturnOrderListTrack = () => import('../../components/my-account/return/order-list/track/index.ts' /* webpackChunkName: "components/my-account-return-order-list-track" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterSkeleton = () => import('../../components/plp/common/filter/filter-skeleton/filterSkeleton.vue' /* webpackChunkName: "components/plp-common-filter-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterPULibFilterItem = () => import('../../components/plp/common/filter/filterPU/LibFilterItem.vue' /* webpackChunkName: "components/plp-common-filter-filter-p-u-lib-filter-item" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterPULibFilterPU = () => import('../../components/plp/common/filter/filterPU/LibFilterPU.vue' /* webpackChunkName: "components/plp-common-filter-filter-p-u-lib-filter-p-u" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterPULibFilterPopover = () => import('../../components/plp/common/filter/filterPU/LibFilterPopover.vue' /* webpackChunkName: "components/plp-common-filter-filter-p-u-lib-filter-popover" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterPULibFilterSizeItem = () => import('../../components/plp/common/filter/filterPU/LibFilterSizeItem.vue' /* webpackChunkName: "components/plp-common-filter-filter-p-u-lib-filter-size-item" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterPromo = () => import('../../components/plp/common/filter/filterPromo/index.vue' /* webpackChunkName: "components/plp-common-filter-filter-promo" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterQuickLibDropdown = () => import('../../components/plp/common/filter/filterQuick/LibDropdown.vue' /* webpackChunkName: "components/plp-common-filter-filter-quick-lib-dropdown" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterQuickLibFilterbar = () => import('../../components/plp/common/filter/filterQuick/LibFilterbar.vue' /* webpackChunkName: "components/plp-common-filter-filter-quick-lib-filterbar" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterQuickFilter = () => import('../../components/plp/common/filter/filterQuick/quickFilter.vue' /* webpackChunkName: "components/plp-common-filter-filter-quick-filter" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterSortLibPlpSort = () => import('../../components/plp/common/filter/filterSort/LibPlpSort.vue' /* webpackChunkName: "components/plp-common-filter-filter-sort-lib-plp-sort" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterSortFilter = () => import('../../components/plp/common/filter/filterSort/sortFilter.vue' /* webpackChunkName: "components/plp-common-filter-filter-sort-filter" */).then(c => wrapFunctional(c.default || c))
export const PlpCommonFilterFilterTab = () => import('../../components/plp/common/filter/filterTab/index.vue' /* webpackChunkName: "components/plp-common-filter-filter-tab" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
