import {
  appToWebPage as appToWebPage2,
  TokenUtils as tokenUtils
} from '~/utilities/helper/SystemHelper';
let length: number = 1;
export class AppLogin {
  protected appInitPromise: Promise<void>;
  protected isDefineProperty: boolean = false;
  protected appToWebPages: ((action: string, data: string) => void)[] = [];
  protected event: {
    [key: string]: ((data: string) => void)[];
  } = {};

  id: number = 0;
  constructor(protected AdidasApp: {
    webPageToApp(action: string, data: string): void;
  }) {
    length += 1;
    this.id = length;
    if (window.appToWebPage) {
      this.appToWebPages.push(window.appToWebPage);
    }
    const appToWebPage = (type: string, data: string) => {
      if (type === "status_web_resume") {
        type = 'status_resume';
      }
      console.log("appToWebPage", type, data);
      if (type in this.event) {
        for (const fun of this.event[type]) {
          try {
            fun(data);
          } catch { }
        }
        delete this.event[type];
      } else {
        for (const appToWebPage of this.appToWebPages) {
          appToWebPage.call(window, type, data);
        }
      }
    };
    try {
      Object.defineProperty(window, 'appToWebPage', {
        set: (appToWebPage) => {
          if (typeof (appToWebPage) === 'function') {
            const appToWebPages = this.appToWebPages;
            if (!appToWebPages.includes(appToWebPage)) {
              appToWebPages.push(appToWebPage);
              if (appToWebPages.length > 3) {
                appToWebPages.splice(0, appToWebPages.length - 3);
              }
            }
          } else {
            const appToWebPages = this.appToWebPages;
            appToWebPages.splice(appToWebPages.length - 1, 1);
          }
        },
        get: () => {
          return appToWebPage;
        }
      });
      this.isDefineProperty = true;
    } catch (error) {
      const appToWebPages = this.appToWebPages;
      appToWebPages.splice(0, appToWebPages.length);
      window.appToWebPage = appToWebPage;
    }
    this.appInitPromise = this.appInitAsync();
  }

  /** 等待特定返回  */
  protected appToWebPageAsync(action: string): Promise<string | undefined> {
    return new Promise<string>((resolve, reject) => {
      let events: ((data: string) => void)[];
      if (action in this.event) {
        events = this.event[action];
      } else {
        events = this.event[action] = [];
      }
      events.push((data) => {
        resolve(data);
      });
    });
  }

  /**
   * 握手协议 告诉app我准备好了
   */
  protected appInitAsync(): Promise<void> {
    if (this.appInitPromise) {
      return this.appInitPromise;
    } else {
      return (async () => {
        console.log('发送 status_web_loaded');
        // 等待 app 告诉我 app准备好了
        const initPromise = this.appToWebPageAsync('status_resume'); 
        // 告诉App web已经准备好了
        this.AdidasApp.webPageToApp("status_web_loaded", ""); 
        console.log('App 准备完成');
        await initPromise;
      })();
    }
  }

  /** 获取登录信息 */
  async getloginInfoAsync(): Promise<string | undefined> {
    await this.appInitAsync();
    // 等待 app 告诉我 app准备好了
    const loginInfoPromise = this.appToWebPageAsync('resp_login_info'); 
    console.log('发送 req_login_info');
    this.AdidasApp.webPageToApp('req_login_info', '');
    const data = await loginInfoPromise;
    console.log('收到 resp_login_info', data);
    if (data) {
      appToWebPage2("resp_login_info", data);
    }
    return data;
  }

  protected statusResumePromise: Promise<string | undefined> | null = null;

  /** 跳出登录窗口 */
  async getloginAsync(): Promise<boolean> {
    await this.appInitAsync();
    if (this.statusResumePromise) {
      window.appToWebPage&&window.appToWebPage("status_resume", "false");
    }
    const loginPromise = this.statusResumePromise = this.appToWebPageAsync("status_resume");
    console.log('发送 action_login');
    this.AdidasApp.webPageToApp('action_login', '');
    const isLogin = await loginPromise;
    this.statusResumePromise = null;
    if (isLogin === "false") {
      return false;
    }
    console.log("action_login 登录结束");
    return true;
    
  }

  async appLoginAsync(): Promise<boolean> {
    console.log('先获取 登录信息');
    let data = await this.getloginInfoAsync();
    if (!data) {
      console.log('没有登录 尝试登录!');
      const isLogin= await this.getloginAsync();
      if(!isLogin){
        return isLogin;
      }
      for (let i = 0; i < 10; i++) {
        console.log(i+' 登录结束 获取下登录信息', data);
        data = await this.getloginInfoAsync();
        if(data){
          break;
        }else{
          await new Promise((resolve=>{
            setTimeout(resolve, 500);
          }));
        }
      }
    }
    if (data) {
      return !!tokenUtils.getAppToken();
    }
    return false;
  }

  async forceAppLoginAsync(): Promise<boolean> {
    console.log('先获取 登录信息');
    let data:string|undefined;
    console.log('没有登录 尝试登录!');
    const isLogin= await this.getloginAsync();
    if(!isLogin){
      return isLogin;
    }
    for (let i = 0; i < 10; i++) {
      console.log(i+' 登录结束 获取下登录信息', data);
      data = await this.getloginInfoAsync();
      if(data){
        break;
      }else{
        await new Promise((resolve=>{
          setTimeout(resolve, 500);
        }));
      }
    }
    
    if (data) {
      return !!tokenUtils.getAppToken();
    }
    return false;
  }
}