
import { getCurrentLink } from '../util';
import specialLinkMixin from '../common/specialLink.mixins';
import { parseURL } from '../../../utilities/helper/urlHelper';
import {
  trackClpPlpClick,
  trackClpButtonClick,
  trackClpPdpClick,
  getScrollDepth
} from '../track/index';
export default {
  mixins: [specialLinkMixin],
  props: {
    ctaObj: {
      type: Object
    },
    code: {
      type: String,
      default: ''
    },
    franchise: {
      type: String
    },
    isChild: {
      type: Boolean,
      default: false
    },
    position: {
      type: String
    },
    tabName: {
      type: String,
      default: ''
    },
    categoryCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isMobile: true
      // ctaObj: {
      //   button: {
      //     text: '按钮',
      //     textColor: '#fff',
      //     size: '12px',
      //     bold: true,
      //     color: '#000',
      //   },
      //   link: 'xxxx',
      //   anchor: '01',
      // },
    };
  },
  computed: {
    handleStyle() {
      const { textColor, size, bold, color } = this.ctaObj?.button || {};
      return {
        color: textColor || '#000',
        fontSize: size || '14px',
        fontWeight: bold ? '700' : '600',
        backgroundColor: color || '#fff'
      };
    },
    boxStyle() {
      const { backgroundColor, mobBackgroundImage, pcBackgroundImage } = this.ctaObj || {};
      const style={
        backgroundColor:backgroundColor || "transparent",
        maxWidth:"1920px",
        margin: "0 auto"
      };

      if(this.isMobile&&mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${mobBackgroundImage})`;
      }else if(!this.isMobile&&pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${pcBackgroundImage})`;
      }
      return style;
    },
    backgroundColor() {
      const { color } = this.ctaObj?.button || {};
      return color || '#fff';
    },
    handleText() {
      const { text } = this.ctaObj?.button || {};
      return text;
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 720;
    },
    handleClick() {
      const _ctaObj = this.ctaObj;
      this.franchise ? _ctaObj.franchise = this.franchise : '';
      const curTerminalLink = _ctaObj.allLink ? getCurrentLink(_ctaObj.allLink) : _ctaObj.link || '';
      try {
        if (curTerminalLink) {
          if (
            curTerminalLink.includes('/pages/plp/index') ||
            curTerminalLink.includes('/pages/product/list') ||
            curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
            curTerminalLink.includes('/pages/product/rich-list') ||
            curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
            curTerminalLink.includes('adidas://category') ||
            parseURL(curTerminalLink).path.startsWith('/plp') ||
            parseURL(curTerminalLink).path.startsWith('/splp')
          ) {
            this.trackData('plp', curTerminalLink);
          } else if (
            curTerminalLink.includes('/pages/product/details') ||
            curTerminalLink.includes('adidas://product') ||
            curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
            parseURL(curTerminalLink).path === '/pdp'
          ) {
            this.trackData('pdp', curTerminalLink);
          } else {
            this.trackData('button', curTerminalLink);
          }
        } else {
          // 没有link直接是button类型埋点
          this.trackData('button', curTerminalLink);
        }
      } catch (error) {
        console.log('sensors track报错', error);
      }
      this.handleSpecialLinkClick(curTerminalLink, _ctaObj, this.isMobile);
    },
    trackData(val, link) {
      let data;
      const directAppProject = link.includes('BOS') && 'FS' || link.includes('CFM') && 'CFD' || '';
      switch (val) {
        case 'plp':
          data = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: '',
            component_code: 'CTA',
            position_code: Number(this.position.slice(1)),
            card_position: 1,
            scroll_depth: getScrollDepth(this.$refs.ctaRef.offsetTop),
            plp_url: link || '',
            plp_name: '',
            tab_name: this.tabName,
            tag: this.ctaObj.labelInfoList.join(',')
          };
          trackClpPlpClick(data);
          break;
        case 'pdp':
          data = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: '',
            component_code: 'CTA',
            position_code: Number(this.position.slice(1)),
            article_position: 1,
            tab_name: this.tabName,
            recommend_id: '',
            scroll_depth: getScrollDepth(this.$refs.ctaRef.offsetTop),
            category_code: this.categoryCode,
            article_number: parseURL(link).query.articleId,
            brand: '',
            category: '',
            gender: '',
            tag: this.ctaObj.labelInfoList.join(',')
          };

          trackClpPdpClick(data);
          break;
        case 'button':
          data = {
            campaign_name: this.$route.query.contentId || '',
            sub_campaign_name: this.code,
            text: '',
            component_code: 'CTA',
            position_code: Number(this.position.slice(1)),
            scroll_depth: getScrollDepth(this.$refs.ctaRef.offsetTop),
            to_url: link || '',
            tab_name: this.tabName,
            button_name: link === '领券' ? '领券' : this.ctaObj.button.text || '',
            tag: this.ctaObj.labelInfoList.join(',')
          };
          directAppProject ? data.direct_app_project = directAppProject : '';
          trackClpButtonClick(data);
          break;
      }
    }
  }
};
