let tracker = {} as any;
if (process.client) {
  tracker = require('~/utilities/vendors/sensors').default;
}
export function trackIntroView(): void {
  try {
    tracker.track('Company_intro_View', {});
  } catch (error) {
    console.warn('sensor:[Company_intro_View]', error);
  }
}

export function trackIntroClick(): void {
  try {
    tracker.track('Company_intro_Click', {});
  } catch (error) {
    console.warn('sensor:[Company_intro_Click]', error);
  }
}
