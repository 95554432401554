
export default {
  name: 'Dropdown',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    align: {
      type: String
      // default: 'right',
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    width: {
      type: String,
      default: '128px'
    }
  },
  data() {
    return {
      visible: false
    };
  },
  mounted() {
    window.addEventListener('click', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('click', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.visible = false;
    },
    handleHover() {
      this.visible = true;
    },
    changeVisible() {
      this.visible = !this.visible;
    }
  }
};
