/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2023-04-10 11:05:06
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2023-04-10 12:07:17
 * @FilePath: /adidas-com-front/packages/nuxt/components/smart-banner/track/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { track,getPlatform } from '~/utilities/vendors/sensors';

/**
 * @description: 当SmartBanner入屏显示后触发
 */
export function trackSmartBannerView(
  // eslint-disable-next-line camelcase
  url_path: string
): void {
  console.log('SmartBanner_View', {
    url_path,
    app_name: getPlatform()
  });
  track('SmartBanner_View', {
    url_path,
    app_name: getPlatform()
  });
}

/**
 * @description: 点击Smart Banner后触发
 */
export function trackSmartBannerClick(
  // eslint-disable-next-line camelcase
  url_path: string,
  // eslint-disable-next-line camelcase
  button_to_url: string
): void {
  track('SmartBanner_Click', {
    url_path,
    app_name: getPlatform(),
    button_to_url
  });
}
