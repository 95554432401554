/*
 * @Author: wang,shuang
 * @Date: 2021-09-16 15:40:57
 * @Description: live800弹出装口
 */

/**
 * @description: 客服窗口配置
 * @Author wang,shuang
 * @Date 2021-09-16
 */

// import md5 from 'md5';
import { BasicUtils, iCtx } from '../basic/utils';
import tracker from '~/utilities/vendors/sensors';
import { getDistinctIds } from '~/utilities/vendors/sensors';

interface iKeyData {
  cipherText: string;
}
interface iCipherText {
  text: string;
  userID: string;
  time: Date;
}

export function formatNumberWithDecimal(value:string) {
  const numValue=parseFloat(value.toString());
  return Number.isInteger(numValue) ? numValue.toString() : numValue.toFixed(2);
}
export function formatDateTime(value:string) {
  function padZero(num:number) {
    return num.toString().padStart(2, "0");
  }
  const time=new Date(value);
  const year = time.getFullYear();
  const month = padZero(time.getMonth() + 1);
  const day = padZero(time.getDate());
  const hours = padZero(time.getHours());
  const minutes = padZero(time.getMinutes());
  const seconds = padZero(time.getSeconds());
  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}

interface iCustomItem{
  customCardName:string,
  customCardThumbnail:string,
  customCardAmountName:string,
  customCardAmountSymbol:string,
  customCardAmount:string,
  customCardOriginalAmount:string
}
interface iCustomField{
  key:string,
  value: string
}
interface iObjValue{cardType:2,cardLink:string,customItems:iCustomItem[],customFields:iCustomField[]}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    adidaslive?: void | number;
  }
}
class CS {
  protected utils: BasicUtils;
  protected S3Data: undefined | { startTime: string, newCsFlag: boolean }[]
  protected isOpenNewCS: boolean=false;
  // eslint-disable-next-line no-useless-constructor
  constructor(protected ctx: iCtx) {
    this.utils = new BasicUtils(this.ctx);
  };

  // 清除
  async updateCopher(): Promise<void> {
    const store=(this.ctx.store || this.ctx.$store);
    const cipherText = store.state.cs.cipher as iCipherText | null;

    if(!cipherText||cipherText.userID!==await this.getUserID(this.utils)){
      // 数据清空
      store.commit('cs/SET_CIPHER', null);
      // 更新密钥
      await this.getNewcsCipherText();
    }
  }

  // 初始化
  async init() {
    if (!process.client) {
      return;
    }
    if (window.adidaslive) {
      clearInterval(window.adidaslive);
    }
    const utils = this.utils;
    if(utils.channel!=="COM"){
      return;
    }
    // 开始检查实时更新 cipherText 
    window.adidaslive = window.setInterval(async () => {
      // this.isOpenNewCS = await this.isOpenCS();
      // if (this.isOpenNewCS) {
        const userID = await this.getUserID(utils);
        const now = await utils.getServerTimeAsync();
        const cipherText = (this.ctx.store || this.ctx.$store).state.cs.cipher as iCipherText | null;
        if (!cipherText || cipherText.userID !== userID || now.getTime() - cipherText.time.getTime() > 12 * 60 * 60 * 1000) {
          this.getNewcsCipherText();
        }
      // }
    }, 60 * 1000);
    // this.isOpenNewCS = await this.isOpenCS();
    // if (this.isOpenNewCS) {
     await this.getNewcsCipherText();
    // }
  }

  // 是否打开新CS
  // protected async isOpenCS(): Promise<boolean> {
  //   if (!this.S3Data) {
  //     const datas = await this.ctx.$axios.get(`${this.ctx.$config.S3_COM_HOST}/static/customer-service/config.json`) as { startTime: string, newCsFlag: boolean }[];
  //     datas.sort((a, b) => {
  //       return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
  //     });
  //     this.S3Data = datas;
  //   }
  //   const now = await this.utils.getServerTimeAsync();
  //   console.log("now", now);
  //   let newCsFlag = false;
  //   for (const item of this.S3Data) {
  //     const startTime = new Date(item.startTime);
  //     if (startTime.getTime() <= now.getTime()) {
  //       newCsFlag = !!item.newCsFlag;
  //     }
  //   }
  //   return newCsFlag;
  // }

  /**
   * 获取秘闻总控制
   */
  protected async getNewcsCipherText(): Promise<void | string>{
    const store=(this.ctx.store || this.ctx.$store);
    let updatePromise=store.state.cs.waitUpdate;
    if(updatePromise){
      await updatePromise;
      return;
    }
    updatePromise=this.getNewcsCipher();
    store.commit('cs/SET_WAIT_UPDATE',updatePromise);
    await updatePromise;
    store.commit('cs/SET_WAIT_UPDATE',null);
  }

  /**
 * 获取新秘文
 * @returns 
 */
  protected async getNewcsCipher(): Promise<void | string> {
    let headers= {
      ...this.utils.config.headers
    };
    if(!this.utils.getUserID()){
      const distinctId= await getDistinctIds();
      if(distinctId){
        headers={
          ...headers,
          "x-client-device-id":distinctId
        };
      }
    }
    const keyData = await this.ctx.$axios.post<iKeyData, iKeyData>(`${this.ctx.$config.ECP_API_HOST}/o2vip/v1/pub/user/cs/gen-ciphertext`, null, {
      ...this.utils.config,
      headers:headers
    });
    if (keyData?.cipherText) {
      const userID = await this.getUserID(this.utils);
      const cipherText: iCipherText = {
        userID: userID,
        text: keyData?.cipherText,
        time: await this.utils.getServerTimeAsync()
      };
      if(['sit','dev'].includes(this.ctx.$config.RUNTIME_ENV)){
        console.log("getNewcsCipherText", cipherText);
      }
      (this.ctx.store || this.ctx.$store).commit('cs/SET_CIPHER', cipherText);
      return keyData?.cipherText;
    }
  }

  /**
 * 打开CS客服
 * @param xentry 
 * @param objValue 
 * @returns 
 */
  protected async openCS(xentry: number, objValue?: iObjValue) {
    // console.log('openCS',xentry,objValue);
    const cipherText = (this.ctx.store || this.ctx.$store).state.cs.cipher as iCipherText | null;
    const userID = await this.getUserID(this.utils);
    if (!cipherText || cipherText.userID !== userID) {
      this.ctx.$Toast({ message: "网络响应慢，请重新点击" });
      await this.getNewcsCipherText();
      return;
    }
    if (cipherText) {
      const csUrlParam = new URL(this.ctx.$config.CS_Link);
      csUrlParam.searchParams.append("ciphertext", cipherText.text.toString());
      csUrlParam.searchParams.append("x-entry", xentry.toString());
      // csUrlParam.searchParams.append("obiect_type",obiectType.toString());
      let url = csUrlParam.toString();
      if (objValue) {
        url = `${url}&object_value=${encodeURIComponent(JSON.stringify(objValue))}`;
      }
      this.openLink(url);
    }
  }

  /**
   * 打开live800
   * @param ctx 
   * @param utils 
   */
  // eslint-disable-next-line no-unused-vars
//  protected Live800(): void {
//     let live800Url: string = this.ctx.$config.LIVE_800 ||
//       'https://adidas.live800.com/live800/chatClient/chatbox.jsp?companyID=9088&configID=3&codeType=custom';
//     const live800Para = new URL(live800Url);
//     if (this.utils.getToken()) {
//       const userID = this.utils.getUserID() as string;
//       if (userID) {
//         const timestamp = Date.now().toString();
//         const hashCode = md5(userID + 'a' + 'a' + timestamp + 'live800Key').toUpperCase();
//         live800Para.searchParams.append("info", `userId=${userID}&loginname=a&grade=&name=a&memo=&hashCode=${hashCode}&timestamp=${timestamp}`);
//         live800Url = live800Para.toString();
//       }
//     }
//     this.openLink(live800Url);
//   }

  /**
   * 打开客服
   * @param xentry 
   * @param objValue 
   */
  open( xentry: number = 100000, objValue?: iObjValue){
    if(this.utils.channel!=="COM"){
      return;
    }
    this.openCS(xentry, objValue);
    // if (this.isOpenNewCS) {
    //     this.openCS(xentry, objValue);
    // } else {
    //   try {
    //     this.Live800();
    //   } catch (error) {
    //     console.log("Live800 error", error);
    //   }
    // }
  };
  
  // 打开链接
  protected openLink(url: string): void {
    const name: string = '_blank';
    if (this.isWeiXin()) {
      window.location.href = url;
    } else {
      // 这里由于某些浏览器会拦截新开页面，所以只能通过此方法打开新页面
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", name);
      document.body.appendChild(a);
      a.click();
      // const winref: any = window.open('', name, parameters);
      // winref.location = live800Url;
    }
  }
  
  // 是否微信
  protected isWeiXin(): boolean {
    const ua: string = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i)) {
      const webkit: any = ua.match(/MicroMessenger/i);
      return webkit[0] === 'micromessenger';
    } else {
      return false;
    }
  }

  // 获取userid
  protected async getUserID(utils: BasicUtils):Promise<string> {
    let userID = utils.getUserID();
    if (userID) {
      return userID;
    }else{
      userID= await getDistinctIds();
      if(userID){
        return userID;
      }else{
        return "";
      }
    }
  }
}



let cs:CS|undefined;
export async function initCS(ctx: iCtx) {
  cs=new CS(ctx);
  await cs.init();
}
// 打开客服
export const openLive800 = (ctx: iCtx, xentry: number = 100000, objValue?: iObjValue) => {
   if(cs){
    cs.open(xentry,objValue);
   }
};


// let updateCopherProm:Promise<void>|undefined;
export function clearCopher() {
  if(cs){
    cs.updateCopher();
   }
};
 




// eslint-disable-next-line camelcase
export function trackerClick(data: { page_title: string, order_list_tab?: string, order_status?: string }): void {
  try {
    tracker.track('Service_Online_Click', data as Object);
  } catch { }
}
