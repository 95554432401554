
import { parseURL } from '../../utilities/helper/urlHelper';
export default {
  name: 'Search',
  props: {
    // placeholder 参数接收默认提示
    placeholder: {
      type: String,
      default: '尝试搜索其他内容'
    }
  },
  methods: {
    noop() {},
    /*
     * @description: 触发父元素的getValue方法，传递输入值给父元素
     */
    async show(e) {
      try {
        const keyword = e.target.value;
        this.$emit('getValue', keyword);
        const name = 'plp-category';
        const query = {
          keyword: encodeURI(keyword),
          page: 1
        };
        const res = await this.$axios.get(
          this.$config.ECP_API_HOST + '/o2srh/v1/pub/platform-products/search',
          {
            params: {
              page: 1,
              pageSize: 24,
              abTest: 'A',
              keyword
            }
          }
        );

        if (res.plpType === 'deepLink') {
          if (res.deepLink.startsWith('http')) {
            window.location.href = res.deepLink;
          } else {
            const routerObj = parseURL(res.deepLink);
            const temp = routerObj.path.split('/');
            if (
              temp[temp.length - 1] === decodeURI(this.$route.query.keyword) ||
              temp[temp.length - 1] === decodeURI(this.$route.params.category)
            ) {
              this.$emit('closeOverlay');
              this.showAssociate = false;
              this.inputValue = '';
              this.recommendation = [];
            }
            if (routerObj.path.startsWith('/plp')) {
              routerObj.query = {
                page: 1,
                type: 'deepLink'
              };
            }
            this.$router.push(routerObj);
          }
          return;
        }
        const params =
          keyword === '医护'
            ? { flag: 'search', isSearch: true }
            : { category: 'search' };
        if (res.totalElements === 0 && res.plpType !== 'deepLink') {
          query.type = 'searchNoResult';
        }
        this.$router.push({
          name,
          query,
          params
        });
      } catch (error) {}
    }
  }
};
