/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2023-11-16 13:32:36
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2023-11-27 18:04:34
 * @FilePath: /new-com/adidas-com-front/packages/nuxt/utilities/basic/ua-parser/enums/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const browserRegexList = {
  /** start other browser **/
  'uc': /ucbrowser/i,
  'oppo': /heytapbrowser/i,
  'samsung': /samsung/i,
  'sogou': /sogou|metasr/i,
  '2345': /mb2345browser/i,
  'vivo': /vivobrowser/i,
  'quark': /quark/i,
  '360': /360/i,
  'opera': /opera|opr|opt/i,
  'firefox': /firefox|fxios/i,
  'miui': /miuibrowser/i,
  /** end other browser **/
  'qq': /qqbrowser/i,
  'baidu': /baidu|bidubrowser|baidubrowser|baiduboxapp|baidud/i,
  'huawei': /huaweibrowser|huawei|honor|hbpc|harmony/i,
  'wechat': /micromessenger/i,
  'edge': /edg|edga|edgios/i,
  'safari': /version\/[\d\.]+ ((mobile\/\w+) )?safari\/[\d\.]+$/i,
  'chrome': /chrome|crios/i
};
export class UAParser {
  public currentBrowserUA: string = window.navigator.userAgent.toLowerCase();

  getUA(): string {
    let browserName = '';
    for (const i in browserRegexList) {
      if (browserRegexList[i as keyof typeof browserRegexList].exec(this.currentBrowserUA)) {
        browserName = i;
        break;
      }
    }
    return browserName;
  }
}
