
// import mixins from './mixins';
import ProductCard from './productCard.vue';
export default {
  name: 'VerticalTabPagePc',
  components: {
    ProductCard
  },
  // mixins: [mixins],
  props: {
    options: {
      type: Object
    },
    backgroundStyle: {
      type: Object,
      default: () => {
        return {};
      }
    },
    viewWidth: {
      type: Number,
      default: 0
    },
    active:{
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      rightUrl:
        this.$config.S3_COM_HOST + '/assets/clp/mincatalog/right-icon.png',
      allLoad: false
    };
  },
  computed: {
    boxStyle(){
      const boxStyle={...this.backgroundStyle};
      delete boxStyle.maxWidth;
      return boxStyle;
    },
    sellected(){
      if(this.options?.itemList?.length&&this.active< this.options.itemList.length){
        return this.options.itemList[this.active];
      }else{
        return null;
      }
    },
    emptyImg() {
      return this.$config.S3_COM_HOST + '/assets/clp/mincatalog/empty-pc.png';
    },
    List() {
      return this.options.itemList || [];
    },
    activeItemList() {
      if (this.sellected) {
        return this.sellected.articleDetailList?.slice(0, 4) || [];
      } else {
        return [];
      }
    },
    productCardStyle() {
      return {
        width: this.productCardWidth + 'px',
        height: (this.productCardWidth + 46 + 20 + 4).toString() + 'px'
      };
    },
    productBoxStyle() {
      return {
        height: (this.productCardWidth + 46 + 20 + 4).toString() + 'px'
      };
    },
    productEmptyBoxStyle() {
      return {
        height: (this.productCardWidth + 46 + 20 + 4 + 59).toString() + 'px'
      };
    },
    productCardWidth() {
      // if(this.allLoad&&this.$refs.right&&this.viewWidth>=720){
      //   const width=this.$refs.right.offsetWidth;
      //   return (width - 3 - 20) / 4;
      // }
      // return 0;
      if (this.viewWidth > 1440) {
        return (1360 - 20 - 150 - 1*3)/4;
      } else if (this.viewWidth > 719 && this.viewWidth < 1441) {
        return (this.viewWidth - 40*2 - 150 - 20 - 1*3)/4;
      } else {
        return 0;
      }
    },
    activeRedirectText() {
      return (this.sellected)?this.sellected.tabRedirectText:null;
    },
    isTransparent(){
      return this.List[this.active].isTransparent;
    },
    emptyCLass(){
      return {
        'bg-transparent': this.isTransparent
      };
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.allLoad=true;
    });

  },
  methods: {
    handlePdpClick(banner,index){
      this.$emit('pdpClick',this.sellected,banner,index);
    },
    handleClickTab(idx) {
      // this.sellected = item;
      if(this.options?.itemList?.length&&idx< this.options.itemList.length){
        this.$emit('switchTab', idx,this.options.itemList[idx]);
      }
    },
    handleRedirect() {
      this.sellected&&this.$emit('redirect', this.sellected.tabRedirectLink,this.sellected, this.sellected.tabRedirectAllLink);
    },
    tabStyle(item) {
      if (item === this.sellected) {
        return {
          color: item.tabTitleSelectedColor
        };
      } else {
        return {};
      }
    },
    arrowStyle(item) {
      if (item === this.sellected) {
        return {
          backgroundColor: item.tabTitleSelectedColor
        };
      } else {
        return {};
      }
    }
  }
};
