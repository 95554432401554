/*
 * @Author: wang,shuang
 * @Date: 2021-10-20 15:16:58
 * @Description: vconsole 配置
 */

import VConsole from 'vconsole';

// eslint-disable-next-line import/no-mutable-exports
let vConsole = "";
// 生产环境不显示

if (typeof window !== 'undefined' && window.self === window.top) {
  try {
    // if(process.env.RUNTIME_ENV!=="prod"){
      vConsole=new VConsole();
    // }
  } catch {}
}


export default vConsole;
