/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2023-11-15 10:06:00
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2024-01-08 16:42:59
 * @FilePath: /new-com/adidas-com-front/packages/nuxt/components/compatibility/utils.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { UAParser } from "~/utilities/basic/ua-parser";
interface iWhitePage {
  path: string,
  param: string[]
}
export function getMimeType(value: string) {
  const mimeTypes = self?.navigator?.mimeTypes as MimeTypeArray;
  for (const mt in mimeTypes) {
    const mimeType: MimeType = mimeTypes[mt];
    if (mimeType.type === value) {
      return mimeType;
    }
  }
  return false;
};
export function isBaiduBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('baidu') || ua.includes('bidubrowser') || ua.includes('baidubrowser') || ua.includes('baiduboxapp') || ua.includes('baidud');
}

export function isChrome() {
  const ua = window.navigator.userAgent.toLowerCase();
  return !isOpera() && !is360Browser() && !isUCBrowser() && !isQuarkBrowser() && !isVivoBrowser() && !is2345Browser() && !isSogouBrowser() && !isSamsungBrowser() && !isHuawei() && !isOppoBrowser() && !isMiBrowser() && (ua.includes('chrome') || ua.includes('crios'));
}

export function isChromium() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('chromium');
}

export function isEdge() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('edge') || ua.includes('edg/') || ua.includes('edga') || ua.includes('edgios');
}

export function isFirefox() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('firefox') || ua.includes('fxios');
}

export function isHuawei() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('huaweibrowser') || ua.includes('huawei') || ua.includes('honor') || ua.includes('hbpc/') || ua.includes('harmony');
}

export function isOpera() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('opera') || ua.includes('opr') || ua.includes('opt');
}

export function isQQBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('qqbrowser') && !ua.includes('sogou');
}

export function isWechat() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('micromessenger');
}

export function is360Browser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('360');
}

export function isUCBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('ucbrowser');
}

export function isQuarkBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('quark');
}

export function isVivoBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('vivobrowser');
}

export function is2345Browser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('mb2345browser');
}

export function isSogouBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('sogou');
}

export function isSamsungBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('samsung');
}

export function isOppoBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('heytapbrowser');
}

export function isSafari() {
  const ua = window.navigator.userAgent.toLowerCase();
  return (
    ua.includes('applewebkit') &&
    ua.includes('safari') &&
    !ua.includes('android') &&
    !ua.includes('linux') &&
    !ua.includes('crios') &&
    !ua.includes('chrome') &&
    !ua.includes('browser') &&
    !ua.includes('ios') &&
    !isOpera() &&
    !is360Browser()
  );
}

export function isMiBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('miuibrowser');
}



export class BrowserUtils extends UAParser {
  public isChrome: boolean = false;
  public isWechat: boolean = false;
  public isSafari: boolean = false;
  public isBaidu: boolean = false;
  public isQQBrowser: boolean = false;
  public isEdge: boolean = false;
  public isHuawei: boolean = false;
  public activeBrowserList: Array<string> = [];
  constructor(protected list: Array<string>, protected whitePageList: iWhitePage[]) {
    super();
    this.activeBrowserList = list;
  }

  checkIsShowCompatibilityTips(): boolean {
    const browser = this.getUA();
    console.log('当前浏览器是', browser, '是否在兼容列表中', this.activeBrowserList.includes(browser), '是否显示兼容提示', !this.activeBrowserList.includes(browser));
    const checkIsShowCompatibilityTips = !this.activeBrowserList.includes(browser);
    return checkIsShowCompatibilityTips;
    // if (isWechat() && this.activeBrowserList.includes('wechat')) {
    //   return false;
    // } else if (isBaiduBrowser() && this.activeBrowserList.includes('baidu')) {
    //   return false;
    // } else if (isHuawei() && this.activeBrowserList.includes('huawei')) {
    //   return false;
    // } else if (isEdge() && this.activeBrowserList.includes('edge')) {
    //   return false;
    // } else if (isQQBrowser() && this.activeBrowserList.includes('qq')) {
    //   return false;
    // } else if (isSafari() && this.activeBrowserList.includes('safari')) {
    //   return false;
    // } else if (isChrome() && this.activeBrowserList.includes('chrome')) {
    //   return false;
    // } else { return true; }
  }

  inWhitePage(): boolean {
    const urlPara = new URL(location.href);
    let pathname = urlPara.pathname;
    if (pathname.slice(-1) === "/") {
      pathname = pathname.slice(0, -1);
    }
    const locationPathList = pathname.split("/");
    for (const whitePage of this.whitePageList) {
      if (whitePage.path === "") {
        whitePage.path = '/';
      }
      const whitePagePath = whitePage.path.split("/");
      let isPathMatch = false;
      let isParamMatch = false;
      if (locationPathList[0] === whitePagePath[0] && whitePagePath[0] === '') {
        for (let i = 1; i < locationPathList.length; i++) {
          if (locationPathList[i] === whitePagePath[i] || whitePagePath[i] === "*") {
            if (i === (whitePagePath.length - 1) && whitePagePath.length === locationPathList.length) {
              isPathMatch = true;
              break;
            } else {
              continue;
            }
          } else if (whitePagePath[i] === "**") {
            isPathMatch = true;
            break;
          } else {
            break;
          }
        }
        // homepage识别
        if (whitePagePath[1] === whitePagePath[0] && whitePagePath[0] === '' && locationPathList.length === 1 && locationPathList[0] === '') {
          isPathMatch = true;
        }
        if (!isPathMatch) {
          continue;
        }
        if (whitePage.param.length === 0) {
          isParamMatch = true;
        } else {
          for (const param of whitePage.param) {
            const [name, value] = param.split("=");
            if (urlPara.searchParams.get(name) === value) {
              isParamMatch = true;
              break;
            } else {
              isParamMatch = false;
            }
          }
        }
        if (isPathMatch && isParamMatch) {
          return true;
        }
      }
    }
    return false;
  }
}
