/*
 * @Author: wang,shuang
 * @Date: 2021-11-30 16:14:08
 * @Description: url解析,非sort字段均已转码
 */

/**
 * @param url:/plp?categoryCode=shoes&filter=[gender]女子&filter=[identifyColor]brown||purple&sort=new
 * @return push对象
 */
export function parseURL(url: string): object {
  if (!url) return {};
  let lineQueryUrl: string = '';
  let sortUrl: string = '';
  let queryObj: object = {};
  let params: object = {
    path: url.split('?')[0]
  };
  const queryArray = url.split('?')[1]?.split('&');
  if (!queryArray) return { ...params, query: {} };
  queryArray.forEach((item) => {
    if (
      item.startsWith('categoryCode') ||
      item.startsWith('keyword') ||
      item.startsWith('articleId')
    ) {
      item.startsWith('keyword') &&
        (params = {
          path: url.split('?')[0] + '/search'
        });

      if (item.startsWith('categoryCode')) {
        // 兼容categoryCode 带/和不带/配置
        let categoryCode = item.split('=')[1];
        categoryCode = categoryCode.startsWith('/') ? categoryCode.slice(1) : categoryCode;

        params = {
          path: url.split('?')[0] + '/' + categoryCode
        };
      }
      !item.startsWith('categoryCode') &&
        (queryObj = {
          ...queryObj,
          [item.split('=')[0]]: encodeURI(item.split('=')[1])
        });
    } else if (item.startsWith('filter')) {
      lineQueryUrl += item + '&';
    } else if (item.startsWith('sort')) {
      sortUrl += item;
    } else if (item.startsWith('activityCode') || item.startsWith('contentId')) {
      queryObj = {
        ...queryObj,
        [item.split('=')[0]]: encodeURI(item.split('=')[1])
      };
    }
  });

  sortUrl && (queryObj = { ...queryObj, sortUrl });
  lineQueryUrl &&
    (queryObj = {
      ...queryObj,
      lineQueryUrl: encodeURI(lineQueryUrl.slice(0, -1))
    });

  return {
    ...params,
    query: queryObj
  };
}

export function getUrlQuery(url: string): any {
  if (!url) return {};
  const _paramsArr: string[] = url.split('?')[1]?.split('&');
  if (!_paramsArr || !_paramsArr.length) return {};
  const _paramsObj: object = {};
  _paramsArr.forEach((el) => {
    const _map: string[] = el.split('=');
    Object.assign(_paramsObj, { [_map[0]]: _map[1] });
  });
  return _paramsObj;
}

// aws图片url添加尺寸后缀
export function getImgUrl(url: string, size: number): string {
  let _url = url;
  if (!url || url.length === 0) {
    return '';
  }
  if (url.includes('im=Resize')) {
    return _url;
  }
  if (url.includes('?')) {
    _url += `&im=Resize=(${size},${size})`;
  } else {
    _url += `?im=Resize=(${size},${size})`;
  }
  return _url;
}
// 获取绝对路径中的二级路由
export function getUrlRelativePath(url: string): string {
  const arrUrl = url.split('//');
  const start = arrUrl[1].indexOf('/');
  let relUrl = arrUrl[1].substring(start);
  if (relUrl?.indexOf('?') !== -1) {
    relUrl = relUrl.split('?')[0];
  }
  return relUrl;
}

export function isContainInnerPath(url: string): boolean {
  const innerLink = ['/clp', '/pdp', '/plp', 'splp'];
  for (let i = 0; i < innerLink.length; i++) {
    if (url.includes(innerLink[i])) {
      return true;
    }
  }
  return false;
}

// 判断是否为绝对路径
export function isAbsoluteURL(url: string): boolean {
  // 正则表达式匹配绝对URL
  const pattern = /^[a-z]+:\/\/.+/i;
  return pattern.test(url);
}
