
import { getCurrentLink } from '../util';
import specialLinkMixin from '../common/specialLink.mixins';
import { parseURL } from '~/utilities/helper/urlHelper';

export default {
  mixins: [specialLinkMixin],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isMobile:{
      type: Boolean
    },
    position:{
      type:String
    }
  },
  data(){
    return {
      itemStyle:{
        height:"0px"
      },
      offsetWidth:0,
      conf:{
        '16:9':{
            // 允许的layoutType
           child:[1,2],
           scale:0.5625
        },
        "1:1":{
           child:[1,2,3],
           scale:1
        },
        "3:4":{
           child:[1,2,3,4],
           scale:1.33333
        },
        "pc":{
           child:[1,2,3,4],
           scale:null
        },
        "null":{
           child:[1,2,3,4],
           scale:null
        }
      }
    };
  },
  computed:{
    backgroundStyle() {
      const style={
        backgroundColor:(this.data?.backgroundColor)?this.data.backgroundColor:"transparent",
        maxWidth:this.imgHeightAuto&&!this.isMobile?"1440px": "1920px",
        padding:this.imgHeightAuto&&!this.isMobile?"0 40px":'0px',
        margin: "0 auto"
      };

      if(this.isMobile&&this.data?.mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.data.mobBackgroundImage})`;
      }else if(!this.isMobile&&this.data?.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.data.pcBackgroundImage})`;
      }
      return style;
    },
    layoutType(){
      if(this.data){
        const {layoutType}=this.data;
        return layoutType;
      }
      return 0;
    },
    uniqueItem(){
      return this.layoutType===1;
    },
    ratio(){
      const {ratio}=this.data;
      if(ratio){
        if(this?.data?.itemList?.length === 5){
          return 'null';
        };
        return ratio;
      }else{
        return 'null';
      }
    },
    imgHeightAuto(){
      return this.ratio==='null';
    },
    widthClass(){
      let ratio=this.ratio;
      const classList=[];
      if(this.uniqueItem){
        classList.push('visibility-hidden');
      }
      if(!this.isMobile){
        ratio='pc';
      }
      if(ratio in this.conf){
        const child=this.conf[ratio];
        const className=this.imgHeightAuto?"child-auto-":'child-';
        if(child.child.includes(this.layoutType)){
          if(this.layoutType===1){
            classList.push(className+child.child[child.child.length-1].toString());
          }else{
            classList.push(className+this.layoutType.toString());
          }
          return classList;
        }
      }
      return [];
    },
    itemList(){
      // 非法
      if(this.widthClass.length===0){
        return [];
      }
      if(this?.data?.itemList?.length){
        if(this.uniqueItem){
          return [this.data.itemList[0]];
        }else{
          const ratio=this.ratio;
          if(ratio in this.conf){
            if(this.conf[ratio].child.includes(this.layoutType)){
              return this.data.itemList;
            }
          }
        }
      }
      return [];
    },
    uniqueItems(){
      if(this.widthClass.length===0){
        return [];
      }
      if(this.uniqueItem && this?.data?.itemList?.length){
        const ratio=this.ratio;
        if(ratio in this.conf){
          if(this.conf[ratio].child.includes(this.layoutType)){
            return this.data.itemList;
          }
        }
      }
      return [];
    }
  },
  mounted() {
    // console.log("layoutType",this.position,this.layoutType,this?.data?.itemList?.length,this.ratio);

    window.addEventListener('resize', this.onResize);
    this.$nextTick(()=>{
      this.onResize();
    });
  },
    beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods:{
    lastItem(index){
      const classList=[];
      const no=index+1;

      if (no%this.layoutType===0){
        classList.push('mr-0');
      }
      const length=(this.uniqueItem)?this.uniqueItems.length:this.itemList.length;
      const maxLine=Math.ceil( length/this.layoutType);
      if(Math.ceil( no/this.layoutType)===maxLine){
        classList.push('mb-0');
      }
      return classList;
    },
    imgUrl(item){
      if(this.isMobile&&item.mobImgUrl){
        return item.mobImgUrl;
      }else if(!this.isMobile&&item.pcImgUrl){
        return item.pcImgUrl;
      }else{
        return '';
      }
    },
    onResize(){
      const ratio=this.ratio;
      if(!ratio||this.imgHeightAuto){
        this.itemStyle={};
        return;
      }
      if(!this.isMobile){
        let width=1440;
        if(this.$refs.box&&this.$refs.box.offsetWidth<width){
          width=this.$refs.box.offsetWidth;
        }
        this.itemStyle={
          height:((width-40*2-2*3)/4).toString() +'px'
          // ued老师提供,具体看CRFC-2909 平铺逻辑和figma
        };
        return;
      }

      if(this.$refs?.item?.length){
        this.offsetWidth= this.$refs.item[0].offsetWidth;
      }else{
        this.offsetWidth=0;
      }

      if(ratio in this.conf){
        const child=this.conf[ratio];
        this.itemStyle= {
          height: (this.offsetWidth*child.scale)+'px'
        };
      }else{
        this.itemStyle= {
          height: '0px'
        };
      }
      // console.log("this.itemStyle",this.itemStyle.height+'px');
    },
    handleBannerClick(item) {
      const _options = item;
      const curTerminalLink = item.allLink ? getCurrentLink(item.allLink) : item.link || '';
      try {
        if (curTerminalLink) {
          if (
            curTerminalLink.includes('/pages/product/list') ||
            curTerminalLink.includes('/pages/plp/index') ||
            curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
            curTerminalLink.includes('/pages/product/rich-list') ||
            curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
            curTerminalLink.includes('adidas://category') ||
            parseURL(curTerminalLink).path.startsWith('/plp') ||
            parseURL(curTerminalLink).path.startsWith('/splp')
          ) {
            this.$emit('trackClick', {type: 'plp', item: item});
          } else if (
            curTerminalLink.includes('/pages/product/details') ||
            curTerminalLink.includes('adidas://product') ||
            curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
            parseURL(curTerminalLink).path === '/pdp') {
            this.$emit('trackClick', {type: 'pdp', item: item});
          } else {
            this.$emit('trackClick', {type: 'button', item: item});
          }
        }
      } catch (error) {
      }
      this.handleSpecialLinkClick(curTerminalLink, _options);
    }

  }
};
