
  import deepcopy from 'deepcopy';
  import { LibButton } from '@adidas-com-front/ui-library';
  import Choice from './choice.vue';
  import Brick from './brick.vue';
  import Pk from './pk.vue';
  import PK from './pk.vue';
  import Accordion from './accordion.vue';
  import Shuffle from './shuffle.vue';
  import TextInput from './text-input';
  import { trackClpSurveyResultView, trackClpSurveySubmit } from './track/index';
  import {BasicUtils} from '~/utilities/basic/utils';
  import { TokenUtils } from '~/utilities';
  import { AppLogin } from "~/utilities/basic/appLogin";
  import { getDistinctIds } from '~/utilities/vendors/sensors';
  import MemberGuide from '~/components/clp/survey/memberPopover.vue';

  export default {
    components: {
      Choice,
      Shuffle,
      Brick,
      PK,
      Pk,
      Accordion,
      TextInput,
      LibButton,
      MemberGuide
    },
    props: {
      surveyDetail: {
        type: Object,
        default: () => {
          return {};
        }
      },
      surveyOptions: {
        type: Object,
        default: () => {
          return {};
        }
      },
      surveyResult: {
        type: Object,
        default: () => {
          return null;
        }
      }
    },
    data(){
      return {
        serverTime:null,
        wholeSurveySubmitData: {},
        surveyMapAnswer: {},
        wholeSurveySubmited: false,
        btnLoading: false,
        refreshWholeSurvey: false,
        answerInfoSubmited: [],
        showMemberGuide: false
      };
    },
    computed: {
      isBlock() {
        if (window.innerWidth < 720) {
          return true;
        } else {
          return false;
        }
      },
      btnStyle() {
        if (!this.wholeSurveySubmited) {
          return {
            backgroundColor: this.mainButtonColor
          };
        } else {
          return {};
        }

      },
      mainButtonText() {
        return this.surveyDetail.mainButtonText;
      },
      mainButtonColor() {
        return this.surveyDetail.mainButtonColor;
      },
      btnIcon() {
        return this.wholeSurveySubmited ? 'none' : '';
      },
      ctaStatus() {
        return this.wholeSurveySubmited;
      },
      ctaName() {
        return this.wholeSurveySubmited ? '已提交' : '提交问卷' || this.mainButtonText;
      },
      submitAnswerFlag() {
        return this.surveyDetail.submitAnswerFlag;
      },
      questionList() {
        let questionList = deepcopy(this.surveyDetail.questionList);
        questionList = questionList?.map(item=>{
          let type = item.questionType;
          // 这里将后端的文本问题类型转化下
          if (item.questionType === "Text_essay") {
            type = "TextInput";
          }
          return{
            ...item,
            questionType: type
          };
        });
        return questionList.sort((a, b) => { return a.questionPosition - b.questionPosition; });
      },
      repeatFillingFlag() {
        return this.surveyDetail.repeatFillingFlag;
      },
      isShowSurvey() {
        const publishDate = new Date(this.surveyDetail.publishDate).getTime();
        const serverTime=(this.serverTime)?this.serverTime:new Date();
        const currentTime =serverTime.getTime();
        return currentTime > publishDate;
      }
    },
    watch: {
      surveyResult(newValue, oldValue) {
        if (newValue !== oldValue || newValue.uniqueId !== oldValue.uniqueId) {
          this.wholeSurveySubmited = true;
        }
      }
    },
    async mounted () {
      this.utils = new BasicUtils(this);
      this.serverTime=await this.utils.getServerTimeAsync();
      this.$emit('initSurvey');
      this.initSurveyWholeSubmitData();
      this.checkSurveySubmited();
    },
    methods: {
      handleGuideChange(val) {
        this.showMemberGuide = val;
      },
      checkSurveySubmited() {
        if (this.surveyResult) {
          this.wholeSurveySubmited = true;
        } else {
          this.wholeSurveySubmited = false;
        }
      },
      initSurveyWholeSubmitData() {
        this.wholeSurveySubmitData = {
          surveyCode: this.surveyDetail.surveyCode,
          version: this.surveyDetail.version,
          questionAnswerInfo: []
        };
        const questionList = this.surveyDetail.questionList;
        questionList.forEach((question) => {
          this.wholeSurveySubmitData.questionAnswerInfo.push(
            {
              questionCode: question.questionCode,
              answerList: []
            }
          );
        });
      },
      decorateWholeSubmitData(item) {
        const list = [];
        if (item.selectedItemList.length) {
          item.selectedItemList.forEach((element) => {
            const pushObj = {optionCode: element.optionCode};
            if(item.questionType === 'Shuffle') {
              pushObj.submitType = element.submitType || element.liked? 'second':'first';
            }
            list.push(pushObj);
          });
        } else if (item.selectedItem.optionCode) {
          list.push({optionCode: item.selectedItem.optionCode});
        } else {
          list.push({answerValue: item.answerValue});
        }
        const obj = {};
        obj.answerList = list;
        obj.showWholeReply = item.showWholeReply;
        this.surveyMapAnswer[item.questionCode] = obj;
        console.log('surveyMapAnswer', this.surveyMapAnswer);
      },
      handleCheckLogin(flag) {
        if(this.surveyDetail.replyMemberFlag) {
        this.utils.isMemberLogin() ? (flag === 'submit' ? this.handleWholeSubmit() : this.handleWholeRefresh()) : this.handleMemberLogin();
        } else {
          (flag === 'submit' ? this.handleWholeSubmit() : this.handleWholeRefresh());
        }
      },
      handleWholeSubmit() {
        this.wholeSurveySubmitData.questionAnswerInfo.forEach((question) => {
          const questionCode = question.questionCode;
          if (this.surveyMapAnswer[questionCode]) {
            question.answerList = this.surveyMapAnswer[questionCode].answerList;
          }
        });
        try {
          this.wholeSurveySubmitData.questionAnswerInfo.forEach((question) => {
            const answerList = question.answerList;
            const questionCode = question.questionCode;
            const showWholeReply = this.surveyMapAnswer[questionCode].showWholeReply;
            const currentQuestion = this.surveyDetail.questionList.find((item) => item.questionCode === questionCode);
            const questionPosition = currentQuestion?.questionPosition;
            const questionType = currentQuestion?.questionType;
            const currentQuestionOptionList = currentQuestion?.questionDescriptionDetail.optionList || [];
            const submitDetail = [];
            const articleIdList = [];
            const categoryCodeList = [];
            currentQuestionOptionList.forEach((option) => {
              const optionCode = option.optionCode;
              const optionAnswer = answerList.find((item) => item.optionCode === optionCode);
              if (optionAnswer) {
                submitDetail.push(option.optionDescribe || '');
                articleIdList.push(option?.optionArticleList[0] || '');
                categoryCodeList.push(option.optionCategoryCode || '');
              }
            });
            trackClpSurveySubmit({
              campaignName: this.$route.query.contentId,
              componentCode: 'Survey',
              positionCode: questionPosition,
              questionType: questionType,
              surveyId: this.surveyDetail.surveyCode,
              questionId: question.questionCode,
              submitDetail: submitDetail.join('|'),
              articleIdList: articleIdList.join('|'),
              categoryCodeList: categoryCodeList.join('|'),
              is_memer_only: !!this.surveyDetail.replyMemberFlag
            });
            trackClpSurveyResultView({
              campaignName: this.$route.query.contentId,
              componentCode: 'Survey',
              positionCode: questionPosition,
              questionType: questionType,
              surveyId: this.surveyDetail.surveyCode,
              questionId: question.questionCode,
              submitDetail: submitDetail.join('|'),
              articleIdList: articleIdList.join('|'),
              categoryCodeList: categoryCodeList.join('|'),
              resultType: this.surveyDetail.dynamicReplyFlag ? 'dynamic': (showWholeReply ? 'for all' : 'by choice'),
              pageTitle: 'submit',
              is_memer_only: !!this.surveyDetail.replyMemberFlag
            });
          });
        } catch (error) {
          console.log('trackClpSurveySubmit error', error);
        }
        this.submitResult();
      },
      async submitResult() {
        this.btnLoading = true;
        this.wholeSurveySubmited = false;
        this.refreshWholeSurvey = false;
        const utils=new BasicUtils(this);
        const isLogin = !!TokenUtils.getToken();
        const unionId = TokenUtils.getQueryValue('unionId');
        if (utils.IsAPP && window.AdidasApp) {
          const appLogin = new AppLogin(window.AdidasApp);
          const data = await appLogin.getloginInfoAsync();
          !data ? utils.config.headers['x-client-device-id'] = await getDistinctIds() : '';
        } else if((utils.IsWMS || utils.IsOR || utils.IsAdiclub) && !isLogin) {
          utils.config.headers['x-open-id'] = TokenUtils.getQueryValue('openId');
        } else {
          !isLogin && (utils.config.headers['x-client-device-id'] = await getDistinctIds());
        }

        if ((utils.IsWMS || utils.IsOR || utils.IsAdiclub) && unionId) {
          utils.config.headers['x-union-id'] = unionId;
        }

        try {
          const res = await this.$axios.post(
            `${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/surveys/answer-submit`,
            this.wholeSurveySubmitData,
            utils.config
          );
          this.answerInfoSubmited = res.questionAnswerInfo;
          this.wholeSurveySubmited = true;
          this.btnLoading = false;
          this.$Toast({message: '提交成功'});
          console.log('survey submit user result', res);
        } catch (error) {
          // if (this.dynamicReplyFlag && this.question.questionType !== 'Shuffle') {
          //   this.resetSurvey();
          // }
          const errorData = error.data;
          if (errorData) {
            errorData.code === '70191' && this.$Toast({message: '还有题目未按规范答题 请返回上方进行答题'});
            errorData.code === '70188' && this.$Toast({message: '还有题目未完成 请返回上方进行答题'});
          }
          this.btnLoading = false;
          console.log('survey submit error', error);
        }
      },
      handleWholeRefresh() {
        this.refreshWholeSurvey = true;
        this.wholeSurveySubmited = false;
        this.surveyMapAnswer = {};
        this.initSurveyWholeSubmitData();
      },
      async handleMemberLogin(){
        const utils = new BasicUtils(this);
        if (utils.isMemberLogin()) {
          // this.$Toast({message: "您已登录为会员"});
          // this.handleData(item);
        } else if (utils.getEmployeeId()) {
          this.$Toast({message: "抱歉，员工无法参与"});
        } else {
          const utils=new BasicUtils(this);
          if ((utils.IsAPP && window.AdidasApp) || utils.IsWMS || utils.IsOR || utils.IsAdiclub) {
            this.showMemberGuide = true;
          } else {
            const firstLogin = await utils.loginByEnvAsync({
              redirectUrl: location.href,
              memberOnly: true,
              loginClp: true,
              title: window.innerWidth < 720 ? '会员专属内容' : '登录即可解锁互动玩法',
              desc: window.innerWidth < 720 ? '加入会员即可解锁趣味玩法/专属活动等精彩内容' : ''
            });
            if (firstLogin) {
              this.$emit('refreshLastResult');
            }
          }
        }
      }
    }
  };
