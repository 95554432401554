
import { trackCatalogClick } from '../../track/index';
import { parseURL, getImgUrl } from '../../../../utilities/helper/urlHelper';
import { setBreadCrumbs, setPdpBreadCrumbs } from '../../utils';
export default {
  name: 'Operate',
  props: {
    // plp组件数据源
    operateData: {
      type: Object
    },
    // plp组件二级标题数据
    tagline: {
      type: Object
    },
    nowNaItem: {
      type: Object,
      default: () => {}
    },
    // 当前nbanner组件对应一级标题数据
    content: {
      type: Array
    }
  },
  data() {
    return {
      // 是否是第一次进入当前分类页
      firstIn: true,
      productList: []
    };
  },
  computed: {
    compData() {
      return this.$store.state.homePage.sortInfo.compData;
    },
    previewPermission() {
      return this.$store.state.homePage.hasPreviewPermission;
    },
    getResizeImgUrl() {
      return function (url) {
        return getImgUrl(url, 200);
      };
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      const link = this.operateData.options.link;
      try {
        const res = await this.$axios.get(
          this.$config.ECP_API_HOST +
            '/o2srh/v1/pub/platform-products/search?' +
            'page=1&pageSize=6&abTest=A&' +
            link.split('?')[1] +`${this.previewPermission ? '&preview=0' : ''}`
        );
        this.productList = res.content;
      } catch (error) {
        console.log('catalog获取plp数据有误', error);
      }

    },
    // plp组件中每张图片点击事件逻辑
    handleClick(item) {
      const breadStr = this.tagline.options.headDescription + ':';
      setPdpBreadCrumbs(breadStr, item.articleId);
      this.$router.push(`/pdp?articleId=${item.articleId}`);
      const query = this.operateData.options.link.split('?')[1];
      this.trackData(item);
      this.$emit('jump', item);
    },

    // 埋点数据格式化
    trackData(item) {
      const data = {
        catalog_title: this.nowNaItem.contentName,
        catalog_section: this.tagline.options.headDescription,
        position_code: this.operateData.layoutPositionCode,
        component_code: this.operateData.componentCode,
        button_name: item.articleId,
        to_page_path: `/pdp`,
        to_page_query: item.articleId,
        to_page_title: item.articleName
      };
      trackCatalogClick(data);
    },
    trackDataPLP(query) {
      const arr = [];
        for (const key in query) {
          arr.push(`${key}=${query[key]}`);
        }
      const data = {
        catalog_title: this.nowNaItem.contentName,
        catalog_section: this.tagline.options.headDescription,
        position_code: this.operateData.layoutPositionCode,
        component_code: this.operateData.componentCode,
        button_name: '更多',
        to_page_path: `/plp`,
        to_page_query: encodeURIComponent(arr.join('&')),
        to_page_title: this.tagline.options.headDescription
      };
      console.log('pc plpcom plp track click data', data);
      trackCatalogClick(data);
    },
    // 查看更多跳转逻辑
    watchMore() {
      const noNavComp = this.compData[this.content[0].index];
      const idx = noNavComp.findIndex((it) => {return it.component[0].componentCode === this.operateData.componentCode && it.component[0].layoutPositionCode === this.operateData.layoutPositionCode; });
      let currentTagLine;
      for(let i = idx; i >= 0; i--) {
        if(noNavComp[i].component[0].componentCode === 'TAGLINE') {
          currentTagLine = noNavComp[i].component[0];
          break;
        }
      }
      console.log('currentTagLine', currentTagLine);
      const path = this.operateData.options.link;
      const breadStr = currentTagLine.options.headDescription + ':';
      const queryObj = parseURL(path);
      const key = queryObj.query.keyword
        ? decodeURI(queryObj.query.keyword)
        : queryObj.path.split('/')[queryObj.path.split('/').length - 1];

      setBreadCrumbs(key, breadStr);
      queryObj.query = {
        ...queryObj.query,
        page: 1
        // breadStr,
      };
      this.$router.push(queryObj);
      this.trackDataPLP(queryObj.query);
      this.$emit('watchMore');
    },

    // 图片加载完成事件
    onLoad() {
      this.firstIn = false;
    }
  }
};
