interface SwiperHTMLElement extends HTMLElement {
  swiper: any;
}
export class Scroll {
  protected timout: any = null;
  protected isVisible: boolean = false;
  // eslint-disable-next-line no-useless-constructor
  constructor(protected containerEle: SwiperHTMLElement) {
    this.init();
  };

  init() {
    const layout = document.querySelector("#layout-wrapper");
    if (layout) {
      layout.addEventListener('scroll', (e: Event) => {
        if (this.timout) {
          clearTimeout(this.timout);
        }
        this.timout = setTimeout(() => {
          this.scroll();
        }, 500);
      });
    }

  }


  protected scroll(): void {
    this.checkAction();
  }

  checkAction(): void {
    this.isVisible = this.scrollVisible(this.containerEle);
    if (this.isVisible) {
      this.play();
    } else {
      this.pause();
    }
  }

  play(): void {
    console.log('play >>> ');
    const swiper = this.containerEle.swiper;
    swiper.autoplay.start();
  }

  pause(): void {
    console.log('pause >>>');
    const swiper = this.containerEle.swiper;
    swiper.autoplay.stop();
  }



  scrollVisible(ele: HTMLElement): boolean {
    const curTop = ele.getBoundingClientRect().top;
    const curBottom = curTop + ele.offsetHeight;
    const windowHeight = window.innerHeight;
    if ((curTop > 0 && curTop < windowHeight) || (curBottom < windowHeight && curBottom > 0)) {
      return true;
    } else {
      return false;
    }
  }
}
