
import deepcopy from 'deepcopy';
import { mapState } from 'vuex';
import { trackClpPdpClick, getScrollDepth } from '../track/index';
import ProductCard from './ProductCard';
import { Scroll } from './utils';
import {filterProducts} from "~/components/clp/common/productUtil";
export default {
  name: 'ProductList',
  components: {
    ProductCard
  },
  props: {
    flag: {
      type: Boolean,
      default: false
    },
    preCode: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    layoutType: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String
    },
    bgOptions: {
      type: Object,
      default: ()=>{}
    },
    code: {
      type: String,
      default: ''
    },
    isChild: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: false
    },
    tabName: {
      type: String,
      default: ''
    },
    categoryCode: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    recommendId: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      isMobile: false,
      activeBanner: 0,
      swiperProgress: 0,
      swiperOptionPC: {
        watchOverflow: true,
        simulateTouch: false,
        freeMode: false,
        spaceBetween: 2,
        slidesPerView: 4,
        slidesPerGroup: 4,
        navigation: {
          nextEl: '.navigation-right',
          prevEl: '.navigation-left'
        },
        on: {
          slideChange: () => {
            this.swiperProgress = this.swiperPC.progress;
            this.activeBanner = this.swiperPC.activeIndex;
          }
        }
      },
      displayWidth:720
    };
  },
  computed: {
    ...mapState('clp', [
      'plpListMap',
      'articleList'
    ]),
    isTransparent(){
      return this?.options?.transparentFlag?.toString()==="1";
    },
    autoHideSalePrice(){

      if(this.isMobile){
        return (this.displayWidth<400&&this?.swiperOption?.slidesPerView===3)? 7 : 8;
      }else{
        return 0;
      }
    },
    swiperOption(){
      let slidesPerView=4;
      if (this.layoutType === '3-products'|| (this.layoutType === 'multiple-products-gallery'&&this.isMobile) ) {
        slidesPerView=3;
      } else if (
        this.layoutType === '2-products'
      ) {
        slidesPerView=2;
      } else {
        slidesPerView=4;
      }
      const autoplayOption = {
        enabled: false,
        delay: 0,
        disableOnInteraction: false
      };
      this.$nextTick(()=>{
        this.isShow&& this.swiper && this.swiper.update();
      });
      const option = {
        spaceBetween: 2,
        slidesPerView: slidesPerView,
        watchOverflow: true,
        simulateTouch: false,
        loop: this.autoPlay,
        autoplay: autoplayOption,
        speed: this.autoPlay ? 4800 : 300,
        observer: true,
        observeParents: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        freeMode: true,
        on: {
          slideChange: () => {
          },
          // 回调函数，swiper从一个slide过渡到另一个slide结束时执行。
          slideChangeTransitionEnd () {
            // 在Loop模式下Swiper切换到指定slide。切换到的是slide索引是realIndex
            if (this.autoPlay) {
              this.slideToLoop(this.realIndex, 0, false);
            }
          }
        }
      };
      return this.autoPlay ? {...option, loopAdditionalSlides: 2} : option;
    },
    backgroundStyle() {
      const style={
        backgroundColor:(this.bgOptions?.backgroundColor)?this.bgOptions.backgroundColor:"transparent",
        maxWidth: "1920px",
        margin: "0 auto !important",
        backgroundSize: "cover"
      };

      if(this.isMobile&&this.bgOptions?.mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.bgOptions.mobBackgroundImage})`;
      }else if(!this.isMobile&&this.bgOptions?.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.bgOptions.pcBackgroundImage})`;
      }
      return style;
    },
    galleryMobile() {
      return (
        this.isMobile &&
        this.layoutType === 'multiple-products-gallery'
      );
    },
    swiper() {
      if(this.$refs.mySwiper){
        return this.$refs.mySwiper.swiper;
      }
      return null;
    },
    swiperPC() {
      if(this.$refs.mySwiperPC){
        return this.$refs.mySwiperPC.swiper;
      }
      return null;
    },
    lessThen4() {
      return this.list.length < 4 ? 'lessThen4' : '';
    },
    ProductList2() {
      return this.layoutType === '2-products';
    },
    ProductList3() {
      return this.layoutType === '3-products';
    },
    ProductListType3() {
      return (this.layoutType === '3-products');
    },
    galleryPC() {
      return (
        this.layoutType === 'multiple-products-gallery' && !this.isMobile
      );
    },
    mismatch() {
      return (
        this.layoutType === 'multiple-products-mismatch'
      );
    },
    simple() {
      return (
        this.layoutType === 'multiple-products-simple'
      );
    },
    sm3() {
      return this.layoutType === '3-products' ? 'sm:3' : 'sm:2';
    },
    noPadding() {
      return this.layoutType === 'multiple-products-simple' ||
        this.layoutType === 'multiple-products-mismatch'
        ? 'no-padding'
        : '';
    },
    // 交错布局
    staggeredLayout() {
      return this.layoutType === 'multiple-products-mismatch'
        ? 'staggered-layout'
        : '';
    },
    // 筛选后的数据
    filteredList() {
      return (
        this.list.map((item) => {
          // 不显示的标签
          const _product = deepcopy(item);
          delete _product.labelList;
          delete _product.recommendedPercentage;
          delete _product.subTitle;
          return _product;
        }) || []
      );
    },
    filteredPlpList() {
      const link = this.options.link;
      const selectedPlpListObj = this.plpListMap.find((plp) => {
        return plp.link === link;
      });
      const list = selectedPlpListObj?.content || [];
      return (
        list.map((item) => {
          // 不显示的标签
          const _product = deepcopy(item);
          delete _product.labelList;
          delete _product.recommendedPercentage;
          delete _product.subTitle;
          return _product;
        }) || []
      );
    },
    filteredArticleList() {
      return (
        this.articleList.map((item) => {
          // 不显示的标签
          const _product = deepcopy(item);
          delete _product.labelList;
          delete _product.recommendedPercentage;
          delete _product.subTitle;
          return _product;
        }) || []
      );
    },
    // clp refactor之后的商品数据
    productList() {
      if (this.preCode) {
        const filteredList =filterProducts(this.isTransparent,this.filteredList,this.options);
        return filteredList.slice(0, this.options.pageSize || 12);
      } else if (this.options.maintainType === 'PLP') {
        const filteredPlpList =filterProducts(this.isTransparent,this.filteredPlpList,this.options);
        return filteredPlpList.slice(0, this.options.pageSize || 12);
      } else if (this.options.maintainType === 'article') {
        const list = [];
        this.options.articleList.forEach((item) => {
          const findItem = this.filteredArticleList.find((article) => {
            return article.articleId === item;
          });
          findItem && list.push(findItem);
        });
        const prodcuts= this.options.pageSize ? list.slice(0, this.options.pageSize) : list;
        return filterProducts(this.isTransparent,prodcuts,this.options);
      } else {
        return [];
      }
    },
    autoRollFlag() {
      return this.options.autoRollFlag || false;
    },
    autoPlay() {
      return this.layoutType === 'multiple-products-gallery' && this.isMobile && this.autoRollFlag && this.productList.length > 3;
    }
  },
  watch: {
    isShow(v) {
      this.$nextTick(() => {
        this.isShow&& this.swiper && this.swiper.update();
      });
    }
  },
  mounted() {
    this.swiperOptionPC.slidesPerView = 4;
    this.$nextTick(() => {
      if (this.swiper && this.autoPlay) {
        setTimeout(() => {
          this.scroll = new Scroll(this.$refs.mySwiper);
          this.scroll.scroll();
        }, 1000);
      }
    });
    this.handleWidth();
    window.addEventListener('resize', this.handleWidth);
    this.$on('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.handleWidth);
    });
  },
  methods: {
    handleWidth() {
      this.isMobile = window.innerWidth < 720;
      this.displayWidth = window.innerWidth;
    },
    click(item, index) {
      const data = {
        sub_campaign_name: this.code,
        campaign_name: this.$route.query.contentId || '',
        text: '',
        component_code: 'product_list',
        position_code: Number(this.position.slice(1)),
        article_position: index + 1,
        tab_name: this.tabName,
        recommend_id: this.recommendId || '',
        scroll_depth: getScrollDepth(this.$refs.productListRef.offsetTop),
        category_code: this.categoryCode,
        article_number: item.articleId,
        brand: item.brandName,
        category: item.plpType || '',
        gender: item.genderShow,
        tag: this.options?.labelInfoList?.join(',') || ''
      };

      trackClpPdpClick(data);
    }
  }
};
