
  import { parseURL } from '../../../../utilities/helper/urlHelper';
  import { setBreadCrumbs } from '../../utils';
  import { trackCatalogClick } from '../../track/index';
  export default {
    props: {
      titleData: {
        type: Object
      },
      nowNaItem: {
        type: Object,
        default: () => {}
      },
      // 当前九宫格组件对应二级标题数据
      tagline: {
        type: Array
      },
      // 二级标题index
      taglineIndex: {
        type: Number,
        default: 0
      },
    },
    methods: {
      handleRedirect() {
        const path = this.titleData.options.link;
        if (path) {
          const breadStr = this.titleData.options.headDescription + ':';
          const queryObj = parseURL(path);
          const key = queryObj.query?.keyword
            ? decodeURI(queryObj.query.keyword)
            : queryObj.path.split('/')[queryObj.path.split('/').length - 1];

          setBreadCrumbs(key, breadStr);
          queryObj.query = {
            ...queryObj.query,
            page: 1
          };
          this.trackData('/plp', queryObj.query)
          this.$router.push(queryObj);
          this.$emit('watchMore');
        }
      },
      // 埋点数据格式化
      trackData(linkTo, query) {
        const arr = [];
        for (let key in query) {
          arr.push(`${key}=${query[key]}`);
        }
        const data = {
          catalog_title: this.nowNaItem.contentName,
          catalog_section: this.tagline[this.taglineIndex]?.options.headDescription,
          position_code: this.titleData.layoutPositionCode,
          component_code: this.titleData.componentCode,
          button_name: this.titleData.options.linkDescription || '查看全部',
          to_page_path: linkTo,
          to_page_query: encodeURIComponent(arr.join('&')),
          to_page_title: this.tagline[this.taglineIndex]?.options.headDescription
        };
        console.log('PC端title track click data', data);
        trackCatalogClick(data);
      }
    }
  };
