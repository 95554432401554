
export default {
  name: 'Toast',
  data() {
    return {
      htmlMsg: '',
      // 传入的message信息,默认message,可传入信息均可不填
      message: '',
      // 显示时间
      delay: 2000,
      // 显示位置,可不传
      position: '',
       // left距离
      left: null,
       // top距离
      top: null,
      // 传入元素ref 这样可以控制显示的位置
      ref: null,
      // 计算显示元素的宽度
      msgWidth: null,
      // 计算显示元素的高度
      msgHeight: null,
      width: 'auto',
      // 给了几个直接可控位置
      composition: '',
      timer: null,
      resolveList: []
    };
  },
  computed: {
    positonStyle() {
      return {
        left: this.left,
        top: this.top,
        width: this.width
      };
    }
  },
  mounted() {
    this.left = null;
    this.$nextTick(() => {
      // 使用nextTick为了保证dom元素都已经渲染完毕
      this.msgWidth = this.$refs.container.clientWidth / 2;
      this.msgHeight = this.$refs.container.clientHeight / 2;
      if (this.ref !== null) {
        this.getInfo(this.ref);
        if (this.composition === 'center') {
          this.getInfoY(this.ref);
        } else {
          this.position = 'alcenter';
        }
      } else {
        this.position = 'center';
      }
    });
    this.composition = '';
  },
  methods: {
    show() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.resolveList.length &&
          this.resolveList.map((resolveItem) => {
            resolveItem();
            return false;
          });
        this.resolveList = [];
        this.$el.remove();
      }, this.delay);
      return new Promise((resolve) => {
        this.resolveList.push(resolve);
      });
    },
    // ↓↓↓↓↓↓计算位置
    getInfo(element) {
      this.left =
        element.getBoundingClientRect().left +
        element.getBoundingClientRect().width / 2 -
        this.msgWidth +
        'px';
    },
    getInfoY(element) {
      this.top =
        element.getBoundingClientRect().top +
        element.getBoundingClientRect().height / 2 -
        this.msgHeight +
        'px';
    }
    // ↑↑↑↑↑↑计算位置
  }
};
