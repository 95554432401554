
import {
  LibInput,
  // LibTab,
  LibButton,
  LibCheckbox,
  LibLoading
} from '@adidas-com-front/ui-library';
import { mapState } from 'vuex';
import { TokenUtils } from '../../utilities';
import loginMethod from "./components/loginMethod";
import toClp from "./components/toClp";
import ForgetPassword from './forget-password';
import { trackLogin, trackVerificode,newuserSubscribeNotificationView,newuserSubscribeNotificationClick, trackSpecialLogin } from './track/index';
import NonmemberLogin from './NonmemberLogin';
import clp from './components/clp';
import { trackPartnerLoginOrLogout } from '~/components/partnership/track/index';
const nonmemberTitle="注册会员享受更多专属礼遇";
export default {
  name: 'Login',
  components: {
    LibInput,
    // LibTab,
    LibButton,
    LibCheckbox,
    LibLoading,
    ForgetPassword,
    NonmemberLogin,
    loginMethod,
    toClp,
    clp
  },
  props: {
    show: {
      required: true,
      type: Boolean,
      default: false
    },
    // 展示会员登录界面
    firstStepProp: {
      type: Boolean,
      default: false
    },
    // 点击加入阿迪俱乐部
    joinAdiclubProp: {
      type: Boolean,
      default: false
    },
    loginClp:{
      type: Boolean,
      default: true
    },
    scrollShowClp:{
      type: Boolean,
      default: false
    },
    isJointMember:{
      type: Boolean,
      default: true
    },
    joinType:{
      type: String,
      default: ''
    },
    titleImport: {
      type: String,
      default: ''
    },
    descImport: {
      type: String,
      default: ''
    }
  },
  data() {
    let rememberPwd=false;
    try{
      rememberPwd=this.$store.state.myAccount.isKeep;
    }catch{}
    return {
      showDebugger:process.env.RUNTIME_ENV === "dev",
      loading: false,
      // 是否忘记密码
      forgetPassword: false,
      canCloseForgetPassword: true,
      isMobile: true,
      apiErrMessage: null,
      rememberPwd,
      clauseSubscription: false,
      clausePrivate: false,
      loginData: [
        { phoneNumber: null, verifyCode: null },
        { account: null, password: null }
      ],
      currentLoginMethod: 0,
      hasErr: true,
      // 接口请求button的loading态
      btnLoading: false,
      stepMap: [
        [
          {
            text:()=> '手机号登录/注册',
            confirmMethod:async () => {
              this.showClp=false;
              const isPrivate=  await this.agreeToPrivate();
              if(!isPrivate){
                return;
              }
              this.showClp=false;
              this.firstStep = false;
              this.setLoginMethod(0);
              this.goLoginStep(1);
              this.onFocus('tel');
              this.fromFirst = false;
            }
          },
          {
            text: ()=>this.isReturnMember||this.isMobile?'获取验证码':"手机号登录/注册",
            confirmMethod: async() => {
              // 没有填任务文字进入下一步 手机号填写
              if(this.hasErr&&!this.loginData[this.currentLoginMethod].phoneNumber){
                const isPrivate=  await this.agreeToPrivate();
                if(!isPrivate){
                  return;
                }
                this.onFocus('tel');
                this.isOnlyMember=true;
                return;
              }else if(this.hasErr){
                // 有内容有错误停止
                return;
              }
              const isPrivate=  await this.agreeToPrivate();
              if(!isPrivate){
                return;
              }
              this.sendVerifyCode();
            }
          },
          { text: ()=>"登录", confirmMethod: this.phoneNumberLoginRegister }
        ],
        [{ text: ()=>"登录", confirmMethod: this.accountLogin }]
      ],
      currentStep: [0, 0],
      screenHeight: null,
      isFlag: true,
      titleFont: 'heading-2',
      // 是否为非会员
      isNonmember: false,
      nonmemberIcon:null,
      // 是否为第一步
      firstStep: true,
      // 标题
      title: undefined,
      titleIcon:undefined,
      // 是否确认为会员登录
      isMemberLogin: '',
      nonmemberStep: 1,
      fromFirst: false,
      disabled: false,
      showCheckClauseNotice: false,
      // 移动端 登陆保持模态窗口（值与 showErrorMsgTab一致 但是设置false时showErrorMsgTab会比他晚500MS）
      showDialog: false,
      // pc端 登陆错误信息TAB页
      showErrorMsgTab: false,
      showClp:false,
      loginHeight:0,

      // 是否只显示 输入手机号信息
      // 同意协议
      clauseWin:false,
      isOnlyMember:false,
      closeClauseWin:()=>{},
      subscriptionWin:false,
      closesubscriptionWin:()=>{},
      // 是否新注册
      isNewMember:false,
      showMemberGuide:false,
      nonmemberTitle:'',
      hasReset: false,
      nowTime:(new Date()).getTime()
    };
  },
  computed: {
    ...mapState('login', [
      'configObj'
    ]),
    pcMockupImg(){
      const now=this.nowTime;
      if(this.configObj){
        for(const param of this.configObj){
          const startTime=new Date(param.startTime);
          if(now>startTime.getTime()){
            return param.pcRegistrationImage;
          }
        }
      }
      return '';
    },
    mockupImg(){
      const now=this.nowTime;
      if(this.configObj){
        for(const param of this.configObj){
          const startTime=new Date(param.startTime);
          if(now>startTime.getTime()){
            return param.mobRegistrationImage;
          }
        }
      }
      return '';
    },
    proUserIconImg() {
      return this.$config.S3_COM_HOST + '/assets/login/pro_user_icon.png';
    },
    requiredPhone(){
      if(this.isPCFirst&&!this.loginData[this.currentLoginMethod].phoneNumber){
        return false;
      }else{
        return true;
      }
    },
    isPCFirst(){
      return !this.isMobile&&this.currentLoginMethod===0&&this.currentStep[this.currentLoginMethod]===1&&!this.isOnlyMember;
    },
    btnText(){
      const text=this.stepMap[this.currentLoginMethod][this.currentStep[this.currentLoginMethod]].text();
      if(text==='登录'&&this.isNewMember){
          return "注册并登录";
      }
      return text;
    },
    disabledButton(){
      if(this.isMobile &&
        this.currentLoginMethod === 0 &&
        this.currentStep[0] === 0){
        return false;
      }
      if(!this.isMobile &&
        this.currentLoginMethod === 0 &&
        this.currentStep[0] === 1){
        return false;
      }
      if(this.hasErr){
        return this.hasErr;
      }
      return this.disabled;
    },
    loadingLabel(){
      let text=null;
      try{
        text=this.stepMap[this.currentLoginMethod][this.currentStep[this.currentLoginMethod]].text();
      }catch{
        return text;
      }
      if(text==='登录'){
          return (this.isNewMember)?"注册并登录中":"登录中";
      }else if(this.currentLoginMethod===0&&[0,1].includes(this.currentStep[this.currentLoginMethod])){
        return '获取验证码';
      }else {
        return this.btnText;
      }
    },
    keepLogin(){
      if(!this.isMobile){
        if(this.currentLoginMethod===0){
          return  this.currentStep[0]===2||(this.currentStep[0]===1&&this.isReturnMember);
        }else{
          return  this.currentStep[1]===0;
        }
      }else{
        return false;
      }
    },
    showClpPC(){
     return this.showClp&&!this.isMobile;
    },
    titleContent() {
      let titleContent='';
      let descContent = '';
      if(this.subscriptionWin){
        titleContent=this.isMobile?"订阅通知":'';
      }else if(this.clauseWin){
        titleContent=this.isMobile?"请您阅读并同意相关条款":'';
      }else if(this.showMemberGuide && !this.isMemberLogin){
        titleContent= this.isMobile?"注册会员享受更多专属礼遇":'';
      // eslint-disable-next-line no-extra-boolean-cast
      }else if(this.showMemberGuide &&!!this.isMemberLogin){
        titleContent= '';
      }else if(this.showClpPC){
        titleContent= '';
      }else if (!this.showLoginContent) {
        titleContent = '';
      }else if (this.title) {
        titleContent = this.title;
      }else if(this.isNonmember){
        titleContent = this.nonmemberTitle;
      }else {
        const title= this.titles[this.currentLoginMethod][
          this.currentStep[this.currentLoginMethod]
        ].title;
        descContent = this.titles[this.currentLoginMethod][
          this.currentStep[this.currentLoginMethod]
        ].desc;
        if(typeof(title)==='string'){
          titleContent=title;
        }else{
          titleContent=title();
        }
      }
      this.$nextTick(()=>{
        this.updatedTitleFont();
        this.$emit('setTitle',{titleFont:this.titleFont,titleContent:titleContent, descContent: descContent});
      });
      return titleContent;
    },
    titles() {
      if (this.isMobile) {
        return [
          [
            {
              title: this.titleImport || '欢迎你来adiClub',
              desc: this.descImport,
              backLocation: 'right',
              goback: () => {
                this.switchPopover();
              }
            },
            {
              title: !this.isReturnMember&&!this.isMobile
                ? '登录/加入 adiClub 会员'
                : '输入手机号',
              backLocation: 'left',
              goback: () => {
                this.goLoginStep(-1);
                if (!this.isReturnMember) {
                  this.firstStep = true;
                } else {
                  this.fromFirst = true;
                }
              }
            },
            {
              title:()=>{
                if(this.isMobile){
                  return '验证码已发送至 '+this.loginData[0].phoneNumber;
                }else{
                  return '输入验证码';
                }
              },
              backLocation: 'left',
              goback: () => {
                const phoneNumber=this.loginData[0].phoneNumber;
                this.goLoginStep(-1);
                this.loginData[0].phoneNumber=phoneNumber;
                this.hasErr=false;
              }
            }
          ],
          [
            {
              title: '输入账号密码',
              backLocation: 'left',
              goback: () => {
                if (!this.fromFirst) {
                  this.setLoginMethod(0);
                  // this.goLoginStep(0);
                  // this.onFocus('tel');
                } else {
                  this.setLoginMethod(0);
                  this.goLoginStep(-1);
                  // this.setLoginMethod(-1);
                  this.fromFirst = false;
                }
              }
            },
            {
              title: '重置密码',
              backLocation: 'left',
              goback: () => {
                if (this.canCloseForgetPassword) {
                  this.goLoginStep(-1);
                } else {
                  this.canCloseForgetPassword = !this.canCloseForgetPassword;
                }
              }
            }
          ]
        ];
      } else {
        return [
          [
            // pc端登录title文案不会变化, 则在pc端为了照顾移动端, 三步的显示文案一样
            {
              title: this.titleImport || '欢迎你来adiClub',
              desc: this.descImport,
              backLocation: 'right',
              goback: () => {
                this.switchPopover();
              }
            },
            {
              title:()=>{
                return this.isReturnMember? '输入手机号': this.titleImport || "欢迎你来adiClub";
              },
              backLocation:()=>{ return this.isReturnMember?"left":'right';},
              goback: () => {
                if(this.isReturnMember){
                  this.switchPopover(-1);
                }else{
                  this.switchPopover();
                }
              }
            },
            {
              title: '验证码已发送至 '+this.loginData[0].phoneNumber,
              backLocation: 'left',
              goback: () => {
                const phoneNumber=this.loginData[0].phoneNumber;
                this.goLoginStep(-1);
                this.loginData[0].phoneNumber=phoneNumber;
                this.hasErr=false;
              }
            }
          ],
          [
            {
              title: '输入账号密码',
              backLocation: 'left',
              goback: () => {
                this.setLoginMethod(0);
              }
            },
            {
              title: '重置密码',
              backLocation: 'left',
              goback: () => {
                if (this.canCloseForgetPassword) {
                  this.goLoginStep(-1);
                } else {
                  this.canCloseForgetPassword = !this.canCloseForgetPassword;
                }
              }
            }
          ]
        ];
      }
    },
    clauseChecked() {
      if (
        (this.currentLoginMethod === 0 && this.currentStep[0] === 2) ||
        this.currentLoginMethod === 1
      ) {
        return this.clausePrivate;
      } else {
        return true;
      }
    },
    isDoctorOrNurse() {
      return this.$store.state.myAccount.isDoctorOrNurse;
    },
    isCampus() {
      return this.$store.state.myAccount.isCampus;
    },
    isPartnerShip() {
      return this.$store.state.myAccount.isPartnerShip;
    },
    isVolunteer() {
      return this.$store.state.myAccount.isVolunteer;
    },
    showLoginContent() {
      return !(!this.isMobile && this.showErrorMsgTab);
    },
    isReturnMember(){
     return this.currentLoginMethod === 0 && this.currentStep[0] === 1&&this.isOnlyMember;
    },
    iconType(){
      if(this.clauseWin||this.subscriptionWin){
        if(this.isMobile){
          this.$emit('setIcon',{iconType:'right',icon:'icon-cross_32'});
        }else{
          this.$emit('setIcon',{iconType:'left',icon:'icon-chevron_left_32 font-20'});
        }
        return 'right';
      }else if(this.showClpPC){
        this.$emit('setIcon',{iconType:'left',icon:'icon-chevron_left_32 font-20'});
        return 'left';
      }else if(this.showMemberGuide && !this.isMemberLogin){
        this.$emit('setIcon',{iconType:'left',icon:'icon-chevron_left_32'});
        return "right";
      // eslint-disable-next-line no-extra-boolean-cast
      }
      else if(this.isNonmember){
        this.$emit('setIcon',this.titleIcon?this.titleIcon:{iconType:'left',icon:'icon-chevron_left_32'});
        return 'left';
      }else if(this.isReturnMember){
        this.$emit('setIcon',{iconType:'left',icon:'icon-chevron_left_32'});
        return 'left';
      }else{
        let backLocation='';
        const backLocationValue= this.titles[this.currentLoginMethod][this.currentStep[this.currentLoginMethod]].backLocation;
        if(typeof(backLocationValue)==='string'){
          backLocation=backLocationValue;
        }else{
          backLocation=backLocationValue();
        }
        const iconType='' || backLocation;
        const icon=(backLocation === 'left'||this.isNonmember)?'icon-chevron_left_32':'icon-cross_32';
        this.$emit('setIcon',{iconType:iconType,icon});
        return iconType;
      }
    },
    showMobileErrMsgDialog() {
      this.$emit('showErrMsgDialog',this.isMobile && this.showDialog);
      return this.isMobile && this.showDialog;
    },
    showClause(){
      if(this.isMobile&&this.currentStep[0]===0&&this.currentLoginMethod===0){
        console.log('this.isMobile&&this.currentStep[0]',true);
        return true;
      }else if(!this.isMobile&&this.currentStep[0]===1&&this.currentLoginMethod===0&&!this.isOnlyMember){
        console.log('this.isMobile&&this.currentStep[0]==1',true);
        return true;
      }
      console.log('showClause',true);
      return false;
    },
    showLoginMethod(){
      // 只显示手机登录会员
      if(this.currentLoginMethod===1){
        return false;
      }
      if(!this.isMobile&&this.isOnlyMember){
        return false;
      }
      if(this.isMobile&&this.currentStep[0]===0){
        return true;
      }else if(!this.isMobile&&(this.currentStep[0]===0||this.currentStep[0]===1)&&!this.isOnlyMember){
        return true;
      }
      return false;
    }
    // showChangeMargin(){
    //   if(currentLoginMethod===1 &&){

    //   }
    // }
  },
  watch: {
    scrollShowClp(val){
      if (val) {
        this.showClp=val;
      }
    },
    show(val) {
      this.clearData('init');
      this.isNonmember = false;
      this.firstStep = true;
      this.btnLoading = false;
      if (this.joinAdiclubProp) {
        setTimeout(() => {
          this.firstStep = false;
          this.setLoginMethod(0);
          this.goLoginStep(1);
          this.currentStep = [this.isMobile ? 0 : 1, 0];

        }, 100);
      }
      if (val && this.isMobile) {
        this.fromFirst = true;
      }
      if (!val) {
        this.rememberPwd = true;
        setTimeout(() => {
          this.showErrorMsgTab = false;
        }, 100);
      }else{
        setTimeout(() => {
          this.onFocus('tel');
        }, 100);
      }
    },
    rememberPwd(val){
      this.$store.commit('myAccount/SET_KEEP_STATUS', val);
      this.rememberPwd=this.$store.state.myAccount.isKeep;
    },
    // pc端的入口界面没有移动端的必须先选择登录方式, 故如果是pc端则直接进到第二步
    // currentLoginMethod() {
    //   this.clausePrivate=false;
    //   this.clauseSubscription=false;
    //   // for (const i of Object.keys(this.clause)) {
    //   //   this.clause[i] = false;
    //   // }
    // },
    isMobile(val) {
      if (
        (this.currentStep[0] === 0 || (this.firstStep && this.isMobile)) &&
        val
      ) {
        this.$set(this.currentStep, 0, 0);
      } else if (this.currentStep[0] === 0 && !val) {
        this.$set(this.currentStep, 0, 1);
      }
    },
    currentStep() {
      this.btnLoading = false;
      this.updatedTitleFont();
      this.$emit('setTitle',{titleFont:this.titleFont});
    },
    clauseChecked(val, preVal) {
      if (!preVal && val) {
        this.showCheckClauseNotice = false;
      }
    }
  },
  mounted() {
    this.nowTime=(new Date()).getTime();
    // console.log("nowTime",this.nowTime);
    this.onResize();
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.onFocus('tel');
    }, 100);
  },
  updated() {
    // 医护顾客登录需要直接调起会员登录
    if (this.firstStepProp) {
      setTimeout(() => {
        this.firstStep = false;
      }, 100);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    setNonmemberTitle(value){
      this.nonmemberTitle=value;
    },
    updatedTitleFont(){
      if(this.clauseWin||this.subscriptionWin){
        this.titleFont = 'heading-3';
      }else if(this.showMemberGuide && !this.isMemberLogin){
        if(this.isMobile){
          this.titleFont = 'heading-3';
        }else{
          this.titleFont ='';
        }

      }else if(this.showMemberGuide && !!this.isMemberLogin){
        if(this.isMobile){
          this.titleFont = 'heading-3';
        }else{
          this.titleFont ='heading-2';
        }
      }else if(this.showClpPC){
        this.titleFont = '';
      }else if (this.isMobile) {
        this.titleFont = 'heading-3';
      } else if (!this.isMobile && this.currentStep[1] === 0) {
        this.titleFont = 'heading-2';
      } else {
        this.titleFont = 'heading-2';
      }
    },
    showClpBox(val){
      console.log('showClpBox');
      this.$emit('hideClpBox', val);
      if(this.isMobile){
        if(val){
          this.showClp=true;
          if(this.$refs?.login&&this.$refs?.clp?.$el){
            this.loginHeight=this.$refs.login.offsetHeight-this.$refs.clp.$el.offsetHeight;
          }
          this.$nextTick(()=>{
            this.$emit('scroll', this.loginHeight);
          });
        }else{
          this.showClp=false;
          this.$nextTick(()=>{
            this.$emit('scroll', 0);
          });
        }
      }else{
        this.showClp=val;
      }
    },
    checkPrivateStatus() {
      if (this.clauseChecked) {
        return true;
      } else {
        this.showCheckClauseNotice = true;
        return false;
      }
    },
    rememberPwdCheckErr(data) {
      this.checkErr();
    },
    switchPopover() {
      this.$emit('close');
      // this.show = false;
      setTimeout(() => {
        this.forgetPassword = false;
        // this.currentStep= [0, 0];
        this.currentStep = [this.isMobile ? 0 : 1, 0];
        this.currentLoginMethod = 0;
        this.firstStep = true;
      }, 500);
      this.clearData();
    },
    clearData(val) {
      this.loginData = [
        {
          ...this.loginData[0],
          verifyCode: null,
          phoneNumber:
            this.currentStep[0] < 2 ? null : this.loginData[0].phoneNumber
        },
        { account: null, password: null }
      ];
      this.apiErrMessage = null;
      if (val === 't&c') {
        // this.clause = {
        //   private: false
        // };
      } else if(val==='init') {
        // debugger;
        this.clauseSubscription=this.clausePrivate=false;
        // this.clause = {
        //   subscription: false,
        //   private: false
        // };
      }else{
        this.clauseSubscription=false;
      }
      if (val === 'pcL/Rpage2') {
        return;
      }
      setTimeout(() => {
        // this.isNonmember=false;
        this.title = undefined;
        this.isMemberLogin = '';
        this.nonmemberStep = 1;
      }, 200);
    },
    onResize() {
      this.isMobile = window.innerWidth < 720;
      this.screenHeight = document.documentElement.clientHeight;
    },
    async toPassword(){
      const isPrivate=await this.agreeToPrivate();
      this.showClp=false;
      if(isPrivate){
        this.setLoginMethod(1);
        this.onFocus('account');
      }
    },
    setLoginMethod(currentActiveKey) {
      this.currentLoginMethod = currentActiveKey;
      if (!this.isMobile) {
        this.currentStep = [1, 0];
      } else {
        this.currentStep = [0, 0];
      }
      this.clearData();
    },
    checkErr(hasErr) {
      this.disabled = false;
      if (hasErr || this.currentLoginMethod === 0) {
        this.hasErr = hasErr;
      } else if (this.currentLoginMethod === 1) {
        this.hasErr = !(
          this.loginData[1].account &&
          this.loginData[1].password &&
          /^1\d{10}$/.test(this.loginData[1].account) &&
          /^.{1,20}/.test(this.loginData[1].password)
        );
      }
      return this.hasErr;
    },
    // 手机号码登录时调整步骤
    goLoginStep(step, val) {
      if (this.currentStep[this.currentLoginMethod] + step < 0) return;
      if (this.forgetPassword && step === -1) {
        this.forgetPassword = false;
        this.title=undefined;
      }
      if (this.currentStep[this.currentLoginMethod] === 2 && val === 'reset') {
        this.$set(
          this.currentStep,
          this.currentLoginMethod,
          this.currentStep[this.currentLoginMethod]
        );
        this.clearData('t&C');
      } else {
        this.$set(
          this.currentStep,
          this.currentLoginMethod,
          this.currentStep[this.currentLoginMethod] + step
        );
        this.clearData('pcL/Rpage2');
      }
      this.hasErr = true;
    },

    // 扩大PC端勾选热区范围
    handlePcClick(value) {
      if (!this.isMobile) {
        if (value === 'rememberPwd') {
          this.rememberPwd=!this.rememberPwd;
          this.$set(this.clause, 'rememberPwd', !this.clause.rememberPwd);
        } else if(value==='subscription'){
          this.clauseSubscription=!this.subscription;
        }else{
          this.clausePrivate=!this.clausePrivate;
        }
      }
    },
    showSubscription(){
      return new Promise((resolve)=>{
        if(!this.subscription){
          newuserSubscribeNotificationView('ACCOUNT|LOGIN',this.isJointMember,this.joinType);
          this.subscriptionWin=true;
          this.closesubscriptionWin=(value)=>{
            if(typeof(value)==="boolean"){
              this.clauseSubscription=value;
              newuserSubscribeNotificationClick('ACCOUNT|LOGIN',this.isJointMember,this.joinType,value?"Agree":"Close");
              this.$nextTick(()=>resolve(()=>{
                this.subscriptionWin=false;
              }));
            }else{
              this.subscriptionWin=false;
              resolve();
            }
            // true 表示继续下一步

          };
        }else{
          // false 返回上一步
          resolve(()=>{});
        }
      });
    },
    agreeToPrivate(){
      return new Promise((resolve)=>{
        if(!this.clausePrivate){
        this.clauseWin=true;
        this.closeClauseWin=(value)=>{
            this.closeClauseWin=()=>{};
            this.clauseWin=false;
            this.clausePrivate=value;
            this.$nextTick(()=>resolve(value));
          };
        }else{
          resolve(true);
        }
      });
    },
    async getNewMember(){
      const req=await this.$axios.get(`${this.$config.ANA_API_HOST}/v1/users/exist/${this.loginData[0].phoneNumber}`,{
            params: {
              type: 'phone'
            }
          });
      if(req?.data?.existed){
        return false;
      }else{
        return true;
      }
    },
    async sendVerifyCode() {
      trackVerificode('ACCOUNT|LOGIN', 'ACCOUNT',this.isJointMember,this.joinType);
      this.btnLoading = true;
      try {
        const isNewMemberPromise=this.getNewMember();
        const data = await this.$axios.get(
          `${this.$config.ANA_API_HOST}/v1/sms/otp/send`,
          {
            params: {
              phone: this.loginData[0].phoneNumber,
              type: '10002'
            }
          }
        );
        this.isNewMember=await isNewMemberPromise;
        if (data && data.data && this.btnLoading) {
          this.loading = false;
          this.goLoginStep(1, 'reset');
          setTimeout(() => {
            this.$refs.verifyCode.startCountDown();
          }, 0);
          setTimeout(() => {
            this.onFocus('verifyCode');
          }, 100);
        }
      } catch (e) {
        this.loading = false;
        this.handleNetworkErr(e);
        if (e.data?.errcode) {
          if (e.data.errcode === 4000024) {
            this.goLoginStep(1, 'reset');
            setTimeout(() => {
              this.$refs.verifyCode.startCountDown();
            }, 0);
            setTimeout(() => {
              this.onFocus('verifyCode');
            }, 100);
            this.apiErrMessage = '验证码发送过于频繁, 请稍后尝试';
          } else if (e.data.errcode === 4000026) {
            this.$Toast({
              message: '24小时内验证码发送次数过多，请稍后尝试',
              ref: this.isMobile ? null : this.$refs.boxg
            });
            this.apiErrMessage = '24小时内验证码发送次数过多，请稍后尝试';
          } else if (e.data.errcode === 4000021) {
            this.$Toast({
              message: 'Type传入值不在枚举值中',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          } else if (e.data.errcode === 4000025) {
            this.$Toast({
              message: '短信验证码生成失败',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          } else if (e.data.errcode === 4000029) {
            this.$Toast({
              message: '短信发送内部错误',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          } else if (e.data.errcode === 4000022) {
            this.$Toast({
              message: '手机号为空',
              ref: this.isMobile ? null : this.$refs.boxg
            });
            this.apiErrMessage = '手机号为空';
          } else if (e.data.errcode === 4000023) {
            this.$Toast({
              message: '手机号格式错误',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          } else {
            if (e.data.errcode === 400399) {
              this.disabled = true;
            }
            this.$Toast({
              message: e.data.errmsg,
              ref: this.isMobile ? null : this.$refs.boxg
            });
          }
        } else if (e.data?.errmsg) {
          this.$Toast({
            message: e.data.errmsg,
            ref: this.isMobile ? null : this.$refs.boxg
          });
        }
      }
      this.btnLoading = false;
    },
    async phoneNumberLoginRegister() {
      let callBack=null;
      if(this.isNewMember){
        callBack=await this.showSubscription();
        if(!callBack){
          return;
        }else{
          callBack();
        }
      }
      this.$aloading.show();
      this.$emit('hide');
      this.btnLoading = true;
      try {
        this.setPhoneNumber(this.loginData[0].phoneNumber);
        const data = await this.$axios.post(
          `${this.$config.ANA_API_HOST}/v2/users/login/sms`,
          this.$qs.stringify({
            phone: this.loginData[0].phoneNumber,
            smsOtp: this.loginData[0].verifyCode,
            subscribeFlag: +this.clauseSubscription
          }),
          {
            'content-type': 'application/x-www-form-urlencoded'
          }
        );
        if (data && data.data && this.btnLoading) {
          const actionType = data.data.actionType;
          const tel = this.loginData[0].phoneNumber;
          const paramsData = {
            ...data.data,
            isKeep: this.isMobile || this.rememberPwd
          };
          this.setToken(paramsData);
          this.signRecord();
          // this.switchPopover();
          this.$emit('login',data.data);
          this.clearData();
          // 神策埋点-手机号登录
          const sensorData = [
            actionType === '4' || actionType === '5' ? 'Register' : 'Login',
            'SMS',
            'ACCOUNT|LOGIN',
            'ACCOUNT',
            !!this.clauseSubscription,
            this.isJointMember,
            this.joinType
          ];
          trackLogin(...sensorData);
          if (this.isDoctorOrNurse) {
            const sensorSpecialData = ['Doctor', tel];
            trackSpecialLogin(...sensorSpecialData);
          } else if (this.isCampus) {
            const sensorSpecialData = ['Student', tel];
            trackSpecialLogin(...sensorSpecialData);
          } else if (this.isVolunteer) {
            const sensorSpecialData = ['Volunteer', tel];
            trackSpecialLogin(...sensorSpecialData);
          } else if (this.isPartnerShip) {
            const sensorSpecialData = ['Partner', tel];
            trackSpecialLogin(...sensorSpecialData);
            trackPartnerLoginOrLogout('Login');
          }
        }
      } catch (e) {
        this.$emit('show');
        this.handleNetworkErr(e);
        if (e.data?.errcode === 4000036) {
          this.disabled = true;
          this.apiErrMessage = '验证码不正确, 请检查并重新输入';
        } else if (e.data.errcode === 4000066) {
          this.isMobile ? this.popGoBack() : '';
          this.showDialog = true;
          this.showErrorMsgTab = true;
        } else {
          const isSystem =
            e.data?.errcode === 4000035 ||
            e.data?.errcode === 4000047 ||
            e.data?.errcode === 4000068;
          this.$Toast({
            message: isSystem ? '系统繁忙，请稍后再试' : e.data?.errmsg,
            ref: this.isMobile ? null : this.$refs.boxg
          });
        }
      }
      this.$aloading.hide();
      // callBack&&callBack();
      this.btnLoading = false;
    },
    async accountLogin() {
      this.btnLoading = true;
      const subscription= this.subscription;
      this.isNewMember&&await this.agreeToSubscription();
      try {
        this.setPhoneNumber(this.loginData[1].account);

        const data = await this.$axios.post(
          `${this.$config.ANA_API_HOST}/v1/users/login/phonepwd`,
          this.$qs.stringify({
            phone: this.loginData[1].account,
            password: this.loginData[1].password
          }),
          {
            'content-type': 'application/x-www-form-urlencoded'
          }
        );
        if (data && data.data && this.btnLoading) {
          const privateV=this.clausePrivate;
          const tel = this.loginData[1].account;
          const paramsData = {
            ...data.data,
            isKeep: this.isMobile || this.rememberPwd
          };
          this.setToken(paramsData);
          this.signRecord();
          // this.switchPopover();
          this.clausePrivate=privateV;
          this.$emit('login');
          // 神策埋点-账号登录
          const sensorData = [
            'Login',
            'Account',
            'ACCOUNT|LOGIN',
            'ACCOUNT',
            !!this.clauseSubscription,
            this.isJointMember,
            this.joinType
          ];
          trackLogin(...sensorData);
          if (this.isDoctorOrNurse) {
            const sensorSpecialData = ['Doctor', tel];
            trackSpecialLogin(...sensorSpecialData);
          } else if (this.isCampus) {
            const sensorSpecialData = ['Student', tel];
            trackSpecialLogin(...sensorSpecialData);
          } else if (this.isVolunteer) {
            const sensorSpecialData = ['Volunteer', tel];
            trackSpecialLogin(...sensorSpecialData);
          } else if (this.isPartnerShip) {
            const sensorSpecialData = ['Partner', tel];
            trackSpecialLogin(...sensorSpecialData);
            trackPartnerLoginOrLogout('Login');
          }
          return new Promise((resolve, reject) => {
            setTimeout(()=>{
              resolve('deng lu cheng gong');
              console.log('deng lu cheng gong');
            },500);
          });
        }
      } catch (e) {
        this.subscription=subscription;
        console.log('error',e);
        this.handleNetworkErr(e);
        if (e && e.data?.errcode) {
          if (e.data?.errcode === 4000066) {
            this.isMobile ? this.popGoBack() : '';
            this.showDialog = true;
            this.showErrorMsgTab = true;
          } else {
            if (e.data?.errcode === 4000064) {
              this.disabled = true;
            }
            this.$Toast({
              message:
                e.data?.errcode === 4000065
                  ? '密码输入错误频繁，请于明日再试'
                  : '账号或密码不正确,请重新输入',
              ref: this.isMobile ? null : this.$refs.boxg
            });
          }
        }
      }
      this.btnLoading = false;
    },
    setNonmemberToken(data, phone, nonmember) {
      this.setPhoneNumber(phone, nonmember);
      this.setToken(data);
      if(!nonmember){
        this.signRecord();
      }
    },
    setToken(data) {
      TokenUtils.setToken({
        ...data,
        isNew:this.isNewMember
      }, this.$axios);
      this.$store.commit('myAccount/LOGIN_COUNT');
      // 更新会员状态
      this.$store.dispatch('myAccount/updateLoginStatus');
      // 更新员工状态
      this.$store.dispatch('myAccount/updateEmployeeLoginStatus');
      // 更新非会员状态
      this.$store.dispatch('myAccount/updateNonmemberLoginStatus');
      // 更新是否新用户
      this.$store.dispatch('myAccount/upadteIsNew');


      this.$trackLogin(this.isNonmember);
    },
    // 记录用户是否同意TC
    signRecord() {
      this.$axios.post(`${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/tc/sign-record`,{
        signFlag: this.clausePrivate ? 1 : 0,
        tcCode: 'TAC_PRIVACY'
      });
      this.$axios.post(`${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/tc/sign-record`,{
        signFlag: this.clausePrivate ? 1 : 0,
        tcCode: 'TAC_MEMBER'
      });
      this.$axios.post(`${this.$config.ECP_API_HOST}/o2vip/v1/pub/0/tc/sign-record`,{
        signFlag: this.clausePrivate ? 1 : 0,
        tcCode: 'TAC_4IN1'
      });
    },
    setPhoneNumber(phone, nonmember = false) {
      if (nonmember) {
        this.$store.dispatch('myAccount/updateNonmember', phone);
      }
    },
    changeForgetPasswordPage() {
      this.hasReset = true;
      this.goLoginStep(-1);
      this.canCloseForgetPassword = true;
      this.currentStep=[...this.currentStep];
      this.$Toast({
        message: '您的密码已重置成功，请重新登录',
        ref: this.isMobile ? null : this.$refs.boxg
      });
    },
    linkto(val) {
      const privacy =
        'https://static1.adidas.com.cn/ecp-cms/cdn-file/tac/content/WMS/0/TAC_PRIVACY.html';
      const use =
        'https://static1.adidas.com.cn/ecp-cms/cdn-file/tac/content/WMS/0/TAC_4IN1.html';
      const rule =
        'https://static1.adidas.com.cn/ecp-cms/cdn-file/tac/content/WMS/0/TAC_MEMBER.html';
      if (val === 'privacy') {
        window.open(privacy);
      }
      if (val === 'use') {
        window.open(use);
      }
      if (val === 'rule') {
        window.open(rule);
      }
    },
    onFocus(val) {
      if (val === 'tel') {
        this.$nextTick(() => {
          this.$refs.tel&&this.$refs.tel.focusInput();
        });
      }
      if (!this.show||this.isMobile) return;
      if(val==="account"){
        this.$nextTick(() => {
          this.$refs.account.focusInput();
        });
      }
      if (val === 'verifyCode') {
        setTimeout(() => {
          this.$refs.verifyCode.focusInput();
        });
      }
    },
    popGoBack() {
      if(this.subscriptionWin){
        if(this.isMobile){
          this.closesubscriptionWin(false);
        }else{
          this.closesubscriptionWin();
        }
        return;
      }
      if(this.isReturnMember){
        this.isOnlyMember=false;
        return;
      }
      if (
        this.joinAdiclubProp &&
        ((this.currentLoginMethod === 0 && !this.isMobile) ||
          (this.currentLoginMethod === -1 && this.isMobile))
      ) {
        this.$emit('close');
        return;
      }
      if (this.isMobile) {
        this.goLoginStep(-1);
        this.currentLoginMethod = 0;
        this.currentStep = [0, 0];
      } else {
        this.goLoginStep(-1);
        this.currentLoginMethod = 0;
        this.currentStep = [1, 0];
      }
      this.canCloseForgetPassword = true;
      this.$emit('close');
    },
    handleTitle(title,type) {
      this.title = title;
      if(type){
        console.log("this.titleIcon", this.titleIcon);
        this.titleIcon=type;
      }else{
        this.titleIcon=null;
      }
    },
    async returnMember() {
      const jumpMember= await this.agreeToPrivate();
      if(!jumpMember){
        return;
      }
      this.showMemberGuide=false;
      this.title = undefined;
      this.isNonmember = false;
     if(!this.isMobile){
        this.isOnlyMember=true;
      };
      const currentStep=[...this.currentStep];
      currentStep[0]=1;
      this.currentStep=currentStep;
      this.onFocus('tel');
      // this.stepMap[0][0].confirmMethod();
    },
    handleIsNonmermber() {
      this.showMemberGuide=this.isNonmember = true;
      this.showClp=false;
      this.isMemberLogin='';
      if (this.isMobile) {
        this.title = nonmemberTitle;
      }
    },
    timeoutToast() {
      this.$Toast({
        message: '登录失败',
        delay: 1000,
        ref: this.isMobile ? null : this.$refs.boxg
      });
    },
    emitLogin() {
      this.$emit('login');
    },
    handleNonmember(step) {
      this.nonmemberStep = step;
    },
    handleMermberLogin() {
      this.isMemberLogin = 'N';
      this.showMemberGuide=false;
      setTimeout(() => {
        this.isMemberLogin = 'Y';
      });
    },
    updateShow(){
      // console.log("updateShow");
      if(this.subscriptionWin){
        if(this.isMobile){
          this.closesubscriptionWin(false);
        }else{
          this.closesubscriptionWin();
        }
        return;
      }
      if(this.clauseWin){
        this.closeClauseWin(false);
        return;
      }
      if(this.isNonmember){
        const iswClause= this.$refs?.nonmember&&this.$refs.nonmember.closeClause();
        if(iswClause){
          return;
        }
        if(this.nonmemberStep===3){
          this.nonmemberStep=2;
        }else if(this.nonmemberStep>1){
          this.nonmemberStep=1;
          // console.log(" this.nonmemberStep", this.nonmemberStep);
        }else{
          // console.log(" this.isNonmember", this.isNonmember);
          this.isNonmember=false;
          this.title=undefined;
          this.showMemberGuide=false;
        }
        return;
      }
      if(this.showClpPC){
        this.showClp=false;
        return;
      }
      if(this.isReturnMember){
        this.isOnlyMember=false;
        return;
      }
      if (
        (this.joinAdiclubProp &&
          this.currentLoginMethod === 0 && this.currentStep[this.currentLoginMethod]===1&&
          !this.isMobile) ||
        (this.currentLoginMethod === -1 && this.isMobile)
      ) {
        this.$emit('close');
        return;
      }
      if (this.isNonmember) {
        const clauseWin=  this.$refs?.nonmember&&this.$refs.nonmember.closeClause();
        if(clauseWin){
          return;
        }
        if (!this.isMemberLogin && this.isMobile) {
          this.isNonmember = false;
          this.nonmemberStep = 1;
          this.title = undefined;
        } else {
          if (this.nonmemberStep === 1 && this.isMobile) {
            this.isMemberLogin = '';
            this.title = nonmemberTitle;
          } else if (!this.isMobile && this.nonmemberStep === 1) {
            this.isNonmember = false;
          }
          if (this.nonmemberStep !== 1) {
            this.nonmemberStep--;
          }
        }
      } else {
        this.titles[this.currentLoginMethod][
          this.currentStep[this.currentLoginMethod]
        ].goback();
      }
    },
    handleConfirmPopover() {
      this.popGoBack();
      this.handleConfirm();
    },
    handleConfirm() {
      this.showDialog = false;
      setTimeout(() => {
        this.showErrorMsgTab = false;
        this.currentLoginMethod = 0;
      }, 500);
    },
    handleCancel() {
      this.showDialog = false;
    },
    handleUnRegisterStatus() {
      this.isMobile ? this.popGoBack() : '';
      this.showDialog = true;
      this.showErrorMsgTab = true;
    },
    handleNetworkErr(e) {
      if (this.$nuxt.isOffline) {
        window.location.reload();
      } else if (!e.data) {
        this.$Toast({
          message: '系统繁忙，请稍后再试',
          ref: this.isMobile ? null : this.$refs.boxg
        });
      }
    }
  }
};
