
  import Error404 from "../pages/error";
  import Error500 from "../components/500-error/500Error";
  import luckyDrawError500 from "../components/luckyDraw/500-error/500error.vue";
  import dailyCheckIn500 from "../components/dailyCheckIn/500-error/500error.vue";
  import dailyCheckIn500Mobile from "../components/dailyCheckIn/500-error/500-error-mobile.vue";
  export default {
    name: "Error",
    components: {Error404, Error500,luckyDrawError500,dailyCheckIn500,dailyCheckIn500Mobile},
    props: ['error'],
    data() {
      let miniprogramCheckin = false;
      let userAgent='';
      if(this?.$ssrContext?.req&&this.$ssrContext.req.headers['user-agent']){
        userAgent=this.$ssrContext.req.headers['user-agent'].toLowerCase();
      }else{
        try{
          userAgent=navigator.userAgent.toLowerCase();
        }catch{};
      }
      if(userAgent.includes('miniprogram')){
        miniprogramCheckin=true;
      }
      return {
        miniprogramCheckin
      };
    },
    computed:{
      is404(){
        return this.error.statusCode === 404;
      },
      is500(){
        return this.error.statusCode !== 404;
      },
      isLuckyDraw(){
        return this.$route.path.toLowerCase().includes('/lucky-draw');
      },
      isDailyCheckIn(){
        return this.$route.path.toLowerCase().includes('/daily-checkin');
      }
    }
  };
