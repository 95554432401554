
import { getCurrentLink } from '../util';
import specialLinkMixin from '../common/specialLink.mixins';
import { parseURL } from '~/utilities/helper/urlHelper';

export default {
  mixins: [specialLinkMixin],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      isMobile: false,
      activeBanner: 0,
      swiperProgress: 0,
      swiperNavigationTop: '0px',
      swiperOptionLoop: {
        // simulateTouch: false,
        // slidesPerView: 4,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction : false,
        // },
        spaceBetween: 2,
        loopAdditionalSlides: 2,
        loop: true,
        speed: 500,
        on: {
          slideChange: () => {}
        }
      },
      swiperOption: {
        spaceBetween: 2,
        autoplay: {
          enabled: true,
          delay: 3500,
          disableOnInteraction: false
        },
        speed: 500,
        on: {
          slideChange: () => {}
        }
      },
      swiperOptionPC: {
        watchOverflow: true,
        simulateTouch: false,
        freeMode: false,
        spaceBetween: 2,
        slidesPerView: 4,
        slidesPerGroup: 4,
        breakpoints: {
          719: {
            freeMode: true,
            spaceBetween: 2,
            slidesPerView: 3,
            slidesPerGroup: 1
          }
        },
        navigation: {
          nextEl: '.navigation-right',
          prevEl: '.navigation-left'
        },
        on: {
          slideChange: () => {
            if (this.swiperPC) {
              this.swiperProgress = this.swiperPC.progress;
              this.activeBanner = this.swiperPC.activeIndex;
            }
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.nBannerSwiper?.swiper;
    },
    swiperPC() {
      return this.$refs.nBannerSwiperPC?.swiper;
    },
    backgroundStyle() {
      const style={
        backgroundColor:(this.data?.backgroundColor)?this.data.backgroundColor:"transparent",
        maxWidth: "1920px",
        margin: "0 auto"
      };

      if(this.isMobile&&this.data?.mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.data.mobBackgroundImage})`;
      }else if(!this.isMobile&&this.data?.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.data.pcBackgroundImage})`;
      }
      return style;
    },
    itemList() {
      let listDouble = [];
      let list = [];
      if (this.data.itemList.length > 2) {
        listDouble = this.data.itemList.concat(this.data.itemList);
        list = this.ArrayTransfer(listDouble, 2);
      } else {
        listDouble = this.data.itemList;
        list = this.ArrayTransfer(listDouble, 2);
      }
      return list;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleWidth);
    this.handleWidth();
    this.$nextTick(() => {
      if (this.data.itemList.length > 2 && this.swiper) {
        this.swiper.params.autoplay.delay = 3000;
        this.swiper.params.autoplay.enabled = true;
        this.swiper.params.autoplay.disableOnInteraction = false;
        this.swiper.autoplay.start();
      }
    });
  },
  beforeDestroy() {
    this.swiper?.destroy(false, false);
    this.swiperPC?.destroy(false, false);
  },
  methods: {
    ArrayTransfer(baseArray, n) {
      const len = baseArray.length;
      const lineNum = len % n === 0 ? len / n : Math.floor( (len / n) + 1 );
      const res = [];
      for (let i = 0; i < lineNum; i++) {
        // slice() 方法返回一个从开始到结束（不包括结束）选择的数组的一部分浅拷贝到一个新数组对象。且原始数组不会被修改。
        const temp = baseArray.slice(i*n, i*n+n);
        res.push(temp);
      }
      return res;
    },
    handleWidth() {
      this.isMobile = window.innerWidth < 720;
    },
    // mini banner点击事件
    handleBannerClick(item) {
      const _options = item;
      const curTerminalLink = item.allLink ? getCurrentLink(item.allLink) : item.link || '';
      try {
        if (curTerminalLink) {
          if (
            curTerminalLink.includes('/pages/product/list') ||
            curTerminalLink.includes('/pages/plp/index') ||
            curTerminalLink.includes('/pages/subPackage/pages/plp/index') ||
            curTerminalLink.includes('/pages/product/rich-list') ||
            curTerminalLink.includes('/pages/subPackage/pages/splp/index') ||
            curTerminalLink.includes('adidas://category') ||
            parseURL(curTerminalLink).path.startsWith('/plp') ||
            parseURL(curTerminalLink).path.startsWith('/splp')
          ) {
            this.$emit('trackClick', {type: 'plp', item: item});
          } else if (
            curTerminalLink.includes('/pages/product/details') ||
            curTerminalLink.includes('adidas://product') ||
            curTerminalLink.includes('/orwshop/pages/productDetail/index') ||
            parseURL(curTerminalLink).path === '/pdp') {
            this.$emit('trackClick', {type: 'pdp', item: item});
          } else {
            this.$emit('trackClick', {type: 'button', item: item});
          }
        }
      } catch (error) {
      }
      this.handleSpecialLinkClick(curTerminalLink, _options);
    }
  }
};
