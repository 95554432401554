/*
 * @Description:
 * @Autor: Wang,YunQi
 * @Date: 2021-11-09 16:24:28
 */
let tracker = {} as any;
if (process.client) {
  tracker = require('~/utilities/vendors/sensors').default;
}
interface catalogViewData {
  catalog_title: string;
  catalog_section: string;
  component_number: number;
  component_code_list: string;
  position_code_list: string;
}
interface catalogClickData {
  catalog_title: string;
  catalog_section: string;
  position_code: string;
  component_code: string;
  button_name: string;
  to_page_path: string;
  to_page_query: string;
  to_page_title: string;
}
export function trackCatalogView(data: catalogViewData): void {
  try {
    tracker.track('Catalog_View', data);
  } catch (error) {
    console.warn('sensor:[Catalog_View]', error);
  }
}

export function trackCatalogClick(
  data: catalogClickData
): void {
  try {
    tracker.track('Catalog_Click', data);
  } catch (error) {
    console.warn('sensor:[Catalog_Click]', error);
  }
}
