
import { LibProductCard } from '@adidas-com-front/ui-library';
import deepcopy from 'deepcopy';
import { trackClpPdpClick, getScrollDepth } from '../../../clp/track/index';
import { filterProducts } from "~/components/clp/common/productUtil";
import { handleClickLink, getCurrentLink } from '~/components/clp/util';
import { getImgUrl } from '~/utilities/helper/urlHelper';

export default {
  name: 'ProductList',
  components: { LibProductCard },
  props: {
    franchise: {
      type: String
    },
    info: {
      type: Object
    },
    options: {
      type: Object
    },
    products: {
      type: Array,
      default: () => []
    },
    articles: {
      type: Array,
      default: () => []
    },
    type: {
      type: String
    },
    tabName: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    categorycode:{
      type: String,
      default: ''
    },
    labelInfoList:{
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isMobile: true,
      isLoad: false,
      activeBanner: 0,
      swiperProgress: 0,
      slidePerView: 0,
      swiperOption: {
        watchOverflow: true,
        simulateTouch: false,
        freeMode: false,
        spaceBetween: 2,
        slidesPerView: "auto",
        slidesPerGroup: 4,
        navigation: {
          nextEl: '.navigation-right',
          prevEl: '.navigation-left'
        },
        on: {
          slideChange: () => {
            this.swiperProgress = this.swiper.progress;
            this.activeBanner = this.swiper.activeIndex;
          }
        }
      },
      device: 'pc',
      benefitDescriptionHeight: '',
      swiperNavigationTop: '0px',
      productListData: [],
      articleList: [],
      allLoad: false
    };
  },
  computed: {
    lessThanFour(){
      return (this.list.length + (this.imgInfo(this.info).img? 1 : 0)) < 4;
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    list() {
      return this.type === 'PLP'
        ? this.products.slice(0, this.info.articleNum)
        :  this.articles.slice(0, this.info.articleNum);
    },
    filteredList() {
      const list=
        this.list.map((item) => {
          // 不显示的标签
          const _product = deepcopy(item);
          delete _product.labelList;
          delete _product.recommendedPercentage;
          delete _product.subTitle;
          return _product;
        }) || [];
      return filterProducts(this.isTransparent,list,this.info);
    },
    isTransparent(){
      return this?.info?.transparentFlag?.toString()==="1";
    },
    viewMoreClass(){
      const classList=[];
      if(this.isTransparent){
        classList.push( 'bg-transparent');
      }
      return classList;
    }
  },
  mounted() {
    this.loadMultiImg();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    loadMultiImg() {
      const imgList = this.list.map((item) => {return item.imageUrl;});
      const moduleImgList = [this.imgInfo(this.info).img, ...imgList];
      const promiseAll = [];
      const img = [];
      const imgTotal = moduleImgList.length;
      for(let i = 0; i <  imgTotal; i++) {
        promiseAll[i] = new Promise((resolve, reject) => {
          img[i] = new Image();
          img[i].src = this.moduleImgList[i];
          if(img[i].complete){
            // 图片走缓存逻辑
            resolve(img[i]);
          }else{
            img[i].onload = () => {
              resolve(img[i]);
            };
          }
        });
      }
      Promise.all(promiseAll).then((img) => {
        // 全部加载完成
        this.allLoad = true;
      }).catch((err) => {
        this.allLoad = true;
        console.log('图片加载失败', err);
      });
    },
    handleUrl(link) {
      return 'javascript:void(0);';
    },
    optLink(item,type) {
          return type !== "More"? item.imageRedirectLink : item.slideRedirectLink;
    },
    optAllLink(item, type) {
      return type !== "More"? item.imageRedirectAllLink : item.slideRedirectAllLink;
    },
    onLoad() {
      this.isLoad = true;
    },
    imgInfo(item) {
        return this.isMobile
          ? {
            img: getImgUrl(item.mainImageMobile, 1000),
            tag: item.mobSeoTag
          }
          : {
            img: getImgUrl(item.mainImagePc, 500),
            tag: item.pcSeoTag
          };
    },
    linkRoute(item, type) {
      const _item = { ...item, link: this.optLink(item, type), allLink: this.optAllLink(item, type) };
      const curTerminalLink = _item.allLink ? getCurrentLink(_item.allLink) : _item.link || '';
      if (!curTerminalLink) {
        return;
      }
      const itemLabelInfoList = [];
      this.info.labelInfo?.forEach((labelItem) => {
        itemLabelInfoList.push(`${labelItem.labelGroup}:${labelItem.label.join('|')}`);
      });
      this.franchise ? _item.franchise = this.franchise : '';
      handleClickLink.call(this, _item);
      this.$emit('more',{link:_item.link, itemLabelInfoList, allLink: this.optAllLink(item, type)});
    },

    sensorClickTrack(item,index) {
      const itemLabelInfoList = [];
      this.info.labelInfo?.forEach((labelItem) => {
        itemLabelInfoList.push(`${labelItem.labelGroup}:${labelItem.label.join('|')}`);
      });
      // 判断当前父组件做埋点
      const parent = this.$parent.transmit();
      if (parent === 'CLP') {
        const data = {
          campaign_name: this.$route.query.contentId || '',
          sub_campaign_name: this.code,
          text: '',
          component_code: 'ImageSlideProduct',
          position_code: Number(this.position.slice(1)),
          article_position: index + 1 + 1,
          tab_name: this.tabName,
          recommend_id: '',
          scroll_depth: getScrollDepth(this.$refs.lookListRef.offsetTop),
          category_code: this.categoryCode || '',
          article_number: item.articleId,
          brand: item.brandName,
          category: item.plpType || '',
          gender: item.genderShow,
          tag: this.labelInfoList?.join(',')
        };
        trackClpPdpClick(data);
      } else {
        const link = `/pdp?articleId=${item.articleId}`;
        this.$emit('sensorTrack', 'Image', { ...item, link: link, labelInfoList: this.options.labelInfoList, itemLabelInfoList });
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth < 720;
      let slidePerView;
      this.slidePerView = slidePerView;

      if (!document.getElementsByClassName('product-list')[0]) return;
      // 设置完轮播图属性 动态获取的图片高度确定导航的绝对位置
      setTimeout(() => {
        const imgDom = document.getElementsByClassName('product-list')[0];
        if (!imgDom) return;
        this.swiperNavigationTop =
          ((imgDom.clientWidth - 4) / 4 + 72) / 2 - 16 + 'px';
      }, 0);
    }
  }
};
