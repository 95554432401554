import { render, staticRenderFns } from "./index.vue?vue&type=template&id=036c6395&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=036c6395&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036c6395",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorPageNormalSingle: require('/usr/src/app/packages/nuxt/components/error-page/ErrorPageNormalSingle.vue').default})
