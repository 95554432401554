/*
 * @Author: your name
 * @Date: 2021-09-08 19:52:32
 * @LastEditTime: 2021-09-08 20:16:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \adProject\adidas-com-front\packages\nuxt\plugins\vue-toast.js
 */
import Vue from 'vue';
import VueToast from '@/components/toast/index';
console.log('plugins VueToast');
Vue.use(VueToast);
