
import { LibPopover } from '@adidas-com-front/ui-library';
import MobileSearch from '../../../components/search/mobile-search/index.vue';
import { trackSearchViewWeb, searchClickTerm } from '../../../pages/search/track/index';
import ErrorPageNormalSingle from '../../../components/error-page/ErrorPageNormalSingle.vue';
import { parseURL } from '../../../utilities/helper/urlHelper';
import { searchVisiblestate, showSearchFlag } from './observable';
export default {
  name: 'SearchPopover',
  components: { LibPopover, MobileSearch, ErrorPageNormalSingle },
  data() {
    return {
      showErrorPage: false,
      // 关键词
      keyWord: '',
      // 跳转类型
      jumpFlag: '',
      // 唤起/隐藏的标识
      click: false,
      // 输入框输入的值
      inputVal: ''
    };
  },
  computed: {
    // 这里将popover的search挂载到default里面,显示和隐藏不是通过传入
    // 的值改变,而是通过当前的路由是否包含一个显示的特殊标识符号来唤起或者
    // 消失,不直接写成这样的原因是在页面内安卓表现良好但是ios不兼容主动自动
    // 唤起键盘的事件,所以需要写成一个组件来实现预站位,然后需要显示的时候
    // 唤起键盘即可
    show() {
      return searchVisiblestate.render;
    }
  },
  watch: {
    // 判断当前的路由标识,由于是挂载到default下面的,理论上在任何地方都可唤起
    $route(to, from) {
      if (to.query.VisibleFlag) {
        this.showSearch();
        this.click = true;
      } else {
        this.hideSearch();
        this.click = false;
      }
    }
  },
  mounted() {
    // 这里保证了刷新的时候也能正常运转
    if (this.$route.query.VisibleFlag) {
      this.showSearch();
      this.click = true;
    } else {
      this.hideSearch();
      this.click = false;
    }
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    this.click = false;
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 720;
    },
    reSearch() {
      this.jumpWhere(this.keyWord, this.jumpFlag);
    },
    clearData() {
      this.click = true;
    },
    searchJump(item, type, jumped, val, relationList) {
      this.relationList = relationList || [];
       let _item = item;
       this.inputVal = val;
      if (type === 'recommendation') {
        _item = item.word.split('>')[1];
      }
      this.$store.commit('searchHistory/setWords', _item);
      this.jumpWhere(_item, type, jumped, item);
    },

    async jumpWhere(keyword, type, jumped, item) {
      try {
        if (this.$router._scrollTopCache && this.$router._scrollTopCache.plp) {
          // 搜索后的plp页需在顶部
          this.$router._scrollTopCache.plp = 0;
        }
        const name = 'plp-category';
        const query = {
          keyword: encodeURI(keyword),
          page: 1
        };
        const res = await this.$axios.get(
          this.$config.ECP_API_HOST + '/o2srh/v1/pub/platform-products/search',
          {
            params: {
              page: 1,
              pageSize: 24,
              abTest: 'A',
              keyword
            }
          }
        );
        const fromName = this.$store.state.searchHistory.fromName;
        const pageName =
          fromName === 'plp' ? 'PLP' : fromName === 'pdp' ? 'PDP' : 'Homepage';
        // search 埋点
        trackSearchViewWeb(
          // 根据点击客服的页面取值 "ACCOUNT|LOGIN"/" SHOPPING CART|EMPTY CART"
          pageName,
          // 根据点击客服的页面取值 "ACCOUNT”/“SHOPPING CART”
          pageName,
          // 从哪个页面进行的搜素 PDP/Homepage/PLP….
          pageName,
          // 搜索关键词
          keyword,
          // 搜素类型 KEYWORDS RECOMMENDATION HISTORY
          type === 'hot' ? 'recommendation' : type,
          // 结果产品数量
          res.totalElements,
          // 是否使用默认关键词进行搜索
          type !== 'keywords',
          // 搜索结果类型 CLP活动页，PLP，No Result，H5目前没有
          res.totalElements > 0 ? 'PLP' : 'No Result',
          // 是否为搜索关键词自动补全时的搜索结果
          type === 'recommendation',
          this.inputVal,
          JSON.stringify(item),
          this.relationList.map((item)=> { return item.word; })
        );
        const searchClickTermData={
          // eslint-disable-next-line camelcase
          search_type: type,
          // eslint-disable-next-line camelcase
          search_term: keyword,
          // eslint-disable-next-line camelcase
          search_result_type: '',
          // eslint-disable-next-line camelcase
          search_result_detail: '',
          // eslint-disable-next-line camelcase
          search_results:res.totalElements
        };
        if (res.plpType === 'deepLink') {
          if (res.deepLink.startsWith('http')) {
            searchClickTermData.search_result_detail=res.deepLink;
            searchClickTerm(searchClickTermData);
            window.location.href = res.deepLink;
          } else {
            const routerObj = parseURL(res.deepLink);
            if (routerObj.path.startsWith('/plp')) {
              routerObj.query = {
                page: 1,
                type: 'deepLink'
              };
            }
            const urlPara=this.$router.resolve(routerObj);
            searchClickTermData.search_result_detail=urlPara.href;
            searchClickTerm(searchClickTermData);
            this.$router.push(routerObj);
          }
          return;
        }
        if (res.totalElements === 0 && res.plpType !== 'deepLink') {
          query.type = 'searchNoResult';
          searchClickTermData.search_result_type="No result";
        }
        const params = { category: 'search' };
        // 先收起search的popover,再进行跳转
        this.hideSearch();

        // 仅有一个商品直接跳转pdp
        if (res.totalElements === 1) {
          const urlPara={
            path: '/pdp',
            query: { articleId: res.content[0].articleId },
            params: { flag: 'home' }
          };
          searchClickTermData.search_result_detail=this.$router.resolve(urlPara).href;
          searchClickTerm(searchClickTermData);
          this.$router.push(urlPara);
          return false;
        }

        // 携带路由参数为params  必须通过name进行跳转
        const urlPara={
          name,
          query,
          params
        };
        searchClickTermData.search_result_detail=this.$router.resolve(urlPara).href;
        searchClickTerm(searchClickTermData);
        // 清楚筛选条件
        this.$router.push(urlPara);
      } catch (e) {
        this.showErrorPage = true;
        this.keyWord = keyword;
        this.jumpFlag = jumped;
        if (e.data && (e.data.code === '1000' || e.data.code === '20000')) {
          this.$dialog({
            title: '当前页面访问人数过多',
            confirmButton: true,
            confirmButtonText: '重试',
            on: {
              confirm: ({ close }) => {
                window.location.reload();
                close();
              }
            }
          });
        } else if (e.data && e.data.code === '1002') {
          this.$dialog({
            title: e.data.message,
            confirmButton: true,
            confirmButtonText: '我知道了',
            on: {
              confirm: ({ close }) => {
                window.location.reload();
                close();
              }
            }
          });
        }
      }
    },
    // 组件点击回退
    componentBack() {
      this.$router.back();
    },
    clickState(state) {
      this.click = false;
    },
    // 唤起or隐藏
    showSearch: showSearchFlag.renderSearch,
    hideSearch: showSearchFlag.hidSearch
  }
};
