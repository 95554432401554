/*
 * @Description:
 * @Autor: Wang,YunQi
 * @Date: 2021-11-15 11:24:19
 */
import Vue from 'vue';

export const searchVisiblestate = Vue.observable({
  render: false
});
export const showSearchFlag = {
  // ↓↓↓↓↓↓observe控制popover的显示隐藏
  renderSearch () {
    searchVisiblestate.render = true;
  },
  hidSearch () {
    searchVisiblestate.render = false;
  }
  // ↑↑↑↑↑↑
};
