/**
 * 复制内容到剪切板
 * @author  韦胜健
 * @date    2019/4/4 15:51
 */
export const copyToClipboard = (text, success, error) => {
  const textArea = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.opacity = '0';
  textArea.style.zIndex = '-1000';
  // textArea.style.display = 'none';
  textArea.readOnly = true;
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) !!success && success();
    else !!error && error('复制到剪切板失败！');
  } catch (err) {
    console.error(err);
    !!error && error(err);
  }

  document.body.removeChild(textArea);
};
