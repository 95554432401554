import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _60191131 = () => interopDefault(import('../pages/app-download/index.vue' /* webpackChunkName: "pages/app-download/index" */))
const _57953ed0 = () => interopDefault(import('../pages/braFinder/index.vue' /* webpackChunkName: "pages/braFinder/index" */))
const _26b6aa43 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _1d1338e0 = () => interopDefault(import('../pages/clp/index.vue' /* webpackChunkName: "pages/clp/index" */))
const _73050432 = () => interopDefault(import('../pages/daily-checkin/index.vue' /* webpackChunkName: "pages/daily-checkin/index" */))
const _f9b408c0 = () => interopDefault(import('../pages/demo/index.vue' /* webpackChunkName: "pages/demo/index" */))
const _58d875fe = () => interopDefault(import('../pages/error/index.vue' /* webpackChunkName: "pages/error/index" */))
const _50a39b9e = () => interopDefault(import('../pages/error-page/index.vue' /* webpackChunkName: "pages/error-page/index" */))
const _566ac0cf = () => interopDefault(import('../pages/goods/index.vue' /* webpackChunkName: "pages/goods/index" */))
const _3e2533a5 = () => interopDefault(import('../pages/healthcheck/index.vue' /* webpackChunkName: "pages/healthcheck/index" */))
const _24712f94 = () => interopDefault(import('../pages/joint-membership/index.vue' /* webpackChunkName: "pages/joint-membership/index" */))
const _cbc60376 = () => interopDefault(import('../pages/live800/index.vue' /* webpackChunkName: "pages/live800/index" */))
const _5940aba0 = () => interopDefault(import('../pages/location-selector/index.vue' /* webpackChunkName: "pages/location-selector/index" */))
const _f10af1be = () => interopDefault(import('../pages/loop/index.vue' /* webpackChunkName: "pages/loop/index" */))
const _f7c78c74 = () => interopDefault(import('../pages/lucky-draw/index.vue' /* webpackChunkName: "pages/lucky-draw/index" */))
const _0c9707a4 = () => interopDefault(import('../pages/nonmember-right2copy/index.vue' /* webpackChunkName: "pages/nonmember-right2copy/index" */))
const _66163ea0 = () => interopDefault(import('../pages/operation/index.vue' /* webpackChunkName: "pages/operation/index" */))
const _07a53b16 = () => interopDefault(import('../pages/pdp/index.vue' /* webpackChunkName: "pages/pdp/index" */))
const _9120df70 = () => interopDefault(import('../pages/right2copy/index.vue' /* webpackChunkName: "pages/right2copy/index" */))
const _5df81bce = () => interopDefault(import('../pages/siteMap/index.vue' /* webpackChunkName: "pages/siteMap/index" */))
const _d19bb898 = () => interopDefault(import('../pages/sizeGuide/index.vue' /* webpackChunkName: "pages/sizeGuide/index" */))
const _e80dbe44 = () => interopDefault(import('../pages/splp/index.vue' /* webpackChunkName: "pages/splp/index" */))
const _c767c81a = () => interopDefault(import('../pages/adiclub/register/index.vue' /* webpackChunkName: "pages/adiclub/register/index" */))
const _79c61a10 = () => interopDefault(import('../pages/app-download/track/index.ts' /* webpackChunkName: "pages/app-download/track/index" */))
const _12396fa5 = () => interopDefault(import('../pages/braFinder/searchProduct.vue' /* webpackChunkName: "pages/braFinder/searchProduct" */))
const _0e7679ee = () => interopDefault(import('../pages/braFinder/select.vue' /* webpackChunkName: "pages/braFinder/select" */))
const _36615cfc = () => interopDefault(import('../pages/campaign/running_loop/index.vue' /* webpackChunkName: "pages/campaign/running_loop/index" */))
const _2c2018ee = () => interopDefault(import('../pages/catalog/catalog-mobile/index.vue' /* webpackChunkName: "pages/catalog/catalog-mobile/index" */))
const _f75ac318 = () => interopDefault(import('../pages/catalog/catalog-skeleton/index.vue' /* webpackChunkName: "pages/catalog/catalog-skeleton/index" */))
const _f255de60 = () => interopDefault(import('../pages/checkout/paid-off/index.vue' /* webpackChunkName: "pages/checkout/paid-off/index" */))
const _53051214 = () => interopDefault(import('../pages/checkout/pay-page/index.vue' /* webpackChunkName: "pages/checkout/pay-page/index" */))
const _186e5fd2 = () => interopDefault(import('../pages/checkout/pay-success/index.vue' /* webpackChunkName: "pages/checkout/pay-success/index" */))
const _6a6395b4 = () => interopDefault(import('../pages/checkout/track/index.ts' /* webpackChunkName: "pages/checkout/track/index" */))
const _328a4812 = () => interopDefault(import('../pages/clp/login.vue' /* webpackChunkName: "pages/clp/login" */))
const _24da8852 = () => interopDefault(import('../pages/daily-checkin/address/index.vue' /* webpackChunkName: "pages/daily-checkin/address/index" */))
const _97a5fc78 = () => interopDefault(import('../pages/daily-checkin/my-prize/index.vue' /* webpackChunkName: "pages/daily-checkin/my-prize/index" */))
const _fac21216 = () => interopDefault(import('../pages/demo/clp.vue' /* webpackChunkName: "pages/demo/clp" */))
const _295a51b1 = () => interopDefault(import('../pages/demo/color.vue' /* webpackChunkName: "pages/demo/color" */))
const _1312bffc = () => interopDefault(import('../pages/demo/demo-scale.vue' /* webpackChunkName: "pages/demo/demo-scale" */))
const _b033b11e = () => interopDefault(import('../pages/demo/font.vue' /* webpackChunkName: "pages/demo/font" */))
const _55b72414 = () => interopDefault(import('../pages/demo/margin-padding.vue' /* webpackChunkName: "pages/demo/margin-padding" */))
const _324ff942 = () => interopDefault(import('../pages/demo/plp.vue' /* webpackChunkName: "pages/demo/plp" */))
const _2dc7979c = () => interopDefault(import('../pages/demo/square-list.vue' /* webpackChunkName: "pages/demo/square-list" */))
const _f3aa5936 = () => interopDefault(import('../pages/demo/testlogin.vue' /* webpackChunkName: "pages/demo/testlogin" */))
const _5648c14f = () => interopDefault(import('../pages/demo/text.vue' /* webpackChunkName: "pages/demo/text" */))
const _31b07cdc = () => interopDefault(import('../pages/download/confirmed.vue' /* webpackChunkName: "pages/download/confirmed" */))
const _5b9cffd0 = () => interopDefault(import('../pages/error-page/error-normal-net/index.vue' /* webpackChunkName: "pages/error-page/error-normal-net/index" */))
const _d65208c4 = () => interopDefault(import('../pages/error-page/error-normal-pc-double/index.vue' /* webpackChunkName: "pages/error-page/error-normal-pc-double/index" */))
const _a2b018ea = () => interopDefault(import('../pages/error-page/error-normal-tablet-double/index.vue' /* webpackChunkName: "pages/error-page/error-normal-tablet-double/index" */))
const _2f295370 = () => interopDefault(import('../pages/error-page/error-normal-visitors/index.vue' /* webpackChunkName: "pages/error-page/error-normal-visitors/index" */))
const _04a28e04 = () => interopDefault(import('../pages/error-page/error-pop-net/index.vue' /* webpackChunkName: "pages/error-page/error-pop-net/index" */))
const _2ff7ac4c = () => interopDefault(import('../pages/error-page/error-pop-visitors/index.vue' /* webpackChunkName: "pages/error-page/error-pop-visitors/index" */))
const _10553713 = () => interopDefault(import('../pages/joint-membership/track/index.ts' /* webpackChunkName: "pages/joint-membership/track/index" */))
const _908482c2 = () => interopDefault(import('../pages/loop/LoopAddress.vue' /* webpackChunkName: "pages/loop/LoopAddress" */))
const _16b7081e = () => interopDefault(import('../pages/loop/LoopAddressSelector.vue' /* webpackChunkName: "pages/loop/LoopAddressSelector" */))
const _7f08660d = () => interopDefault(import('../pages/loop/thanks/index.vue' /* webpackChunkName: "pages/loop/thanks/index" */))
const _4b95c52a = () => interopDefault(import('../pages/lucky-draw/address/index.vue' /* webpackChunkName: "pages/lucky-draw/address/index" */))
const _21b2adeb = () => interopDefault(import('../pages/lucky-draw/auto.vue' /* webpackChunkName: "pages/lucky-draw/auto" */))
const _5bd6d2b0 = () => interopDefault(import('../pages/lucky-draw/my-prize/index.vue' /* webpackChunkName: "pages/lucky-draw/my-prize/index" */))
const _82bfd58a = () => interopDefault(import('../pages/lucky-draw/rule/index.vue' /* webpackChunkName: "pages/lucky-draw/rule/index" */))
const _4cce1074 = () => interopDefault(import('../pages/nonmember-right2copy/example.vue' /* webpackChunkName: "pages/nonmember-right2copy/example" */))
const _656ef91f = () => interopDefault(import('../pages/operation/bosdownload/index.vue' /* webpackChunkName: "pages/operation/bosdownload/index" */))
const _6dffc870 = () => interopDefault(import('../pages/operation/dljumper/index.vue' /* webpackChunkName: "pages/operation/dljumper/index" */))
const _401ccc34 = () => interopDefault(import('../pages/pdp/track/index.ts' /* webpackChunkName: "pages/pdp/track/index" */))
const _75288788 = () => interopDefault(import('../pages/plp/track/index.ts' /* webpackChunkName: "pages/plp/track/index" */))
const _14df6d60 = () => interopDefault(import('../pages/right2copy/example.vue' /* webpackChunkName: "pages/right2copy/example" */))
const _757b6c04 = () => interopDefault(import('../pages/search/track/index.ts' /* webpackChunkName: "pages/search/track/index" */))
const _276461ba = () => interopDefault(import('../pages/siteMap/ssr.async.data.js' /* webpackChunkName: "pages/siteMap/ssr.async.data" */))
const _3a40ce52 = () => interopDefault(import('../pages/sizeGuide/utils.ts' /* webpackChunkName: "pages/sizeGuide/utils" */))
const _37214c0a = () => interopDefault(import('../pages/campaign/running_loop/utils.ts' /* webpackChunkName: "pages/campaign/running_loop/utils" */))
const _7d350957 = () => interopDefault(import('../pages/checkout/track/track.mixins.js' /* webpackChunkName: "pages/checkout/track/track.mixins" */))
const _af7c1f60 = () => interopDefault(import('../pages/demo/components/demo-lib-anchor.vue' /* webpackChunkName: "pages/demo/components/demo-lib-anchor" */))
const _d0e118e8 = () => interopDefault(import('../pages/demo/components/demo-lib-bottom-bar.vue' /* webpackChunkName: "pages/demo/components/demo-lib-bottom-bar" */))
const _cd7ae5c4 = () => interopDefault(import('../pages/demo/components/demo-lib-breadcrumb.vue' /* webpackChunkName: "pages/demo/components/demo-lib-breadcrumb" */))
const _0af0ce4d = () => interopDefault(import('../pages/demo/components/demo-lib-button.vue' /* webpackChunkName: "pages/demo/components/demo-lib-button" */))
const _21f01d3d = () => interopDefault(import('../pages/demo/components/demo-lib-cell.vue' /* webpackChunkName: "pages/demo/components/demo-lib-cell" */))
const _b9f8ad3a = () => interopDefault(import('../pages/demo/components/demo-lib-dialog.vue' /* webpackChunkName: "pages/demo/components/demo-lib-dialog" */))
const _08deb492 = () => interopDefault(import('../pages/demo/components/demo-lib-format.vue' /* webpackChunkName: "pages/demo/components/demo-lib-format" */))
const _2346fb7f = () => interopDefault(import('../pages/demo/components/demo-lib-input.vue' /* webpackChunkName: "pages/demo/components/demo-lib-input" */))
const _9e552e9e = () => interopDefault(import('../pages/demo/components/demo-lib-loading.vue' /* webpackChunkName: "pages/demo/components/demo-lib-loading" */))
const _439cc238 = () => interopDefault(import('../pages/demo/components/demo-lib-number.vue' /* webpackChunkName: "pages/demo/components/demo-lib-number" */))
const _67efeccc = () => interopDefault(import('../pages/demo/components/demo-lib-popover.vue' /* webpackChunkName: "pages/demo/components/demo-lib-popover" */))
const _6964ab47 = () => interopDefault(import('../pages/demo/components/demo-lib-popper.vue' /* webpackChunkName: "pages/demo/components/demo-lib-popper" */))
const _2e5a6809 = () => interopDefault(import('../pages/demo/components/demo-lib-product-card.vue' /* webpackChunkName: "pages/demo/components/demo-lib-product-card" */))
const _7f0d2697 = () => interopDefault(import('../pages/demo/components/demo-lib-product-list.vue' /* webpackChunkName: "pages/demo/components/demo-lib-product-list" */))
const _f89791a0 = () => interopDefault(import('../pages/demo/components/demo-lib-radio.vue' /* webpackChunkName: "pages/demo/components/demo-lib-radio" */))
const _548ae04e = () => interopDefault(import('../pages/demo/components/demo-lib-step-group.vue' /* webpackChunkName: "pages/demo/components/demo-lib-step-group" */))
const _5b801a6e = () => interopDefault(import('../pages/demo/components/demo-lib-uploader.vue' /* webpackChunkName: "pages/demo/components/demo-lib-uploader" */))
const _75dd735a = () => interopDefault(import('../pages/demo/components/demo-login-register.vue' /* webpackChunkName: "pages/demo/components/demo-login-register" */))
const _793ea667 = () => interopDefault(import('../pages/demo/page-components/demo-lib-discount-card.vue' /* webpackChunkName: "pages/demo/page-components/demo-lib-discount-card" */))
const _1eef2528 = () => interopDefault(import('../pages/demo/page-components/demo-lib-goods-card.vue' /* webpackChunkName: "pages/demo/page-components/demo-lib-goods-card" */))
const _10de69ba = () => interopDefault(import('../pages/demo/page-components/demo-steps.vue' /* webpackChunkName: "pages/demo/page-components/demo-steps" */))
const _4e7172ca = () => interopDefault(import('../pages/joint-membership/track/track.mixins.js' /* webpackChunkName: "pages/joint-membership/track/track.mixins" */))
const _828dfe62 = () => interopDefault(import('../pages/loop/component/select.vue' /* webpackChunkName: "pages/loop/component/select" */))
const _9e225244 = () => interopDefault(import('../pages/loop/product/product.vue' /* webpackChunkName: "pages/loop/product/product" */))
const _e4fb43c2 = () => interopDefault(import('../pages/lucky-draw/my-prize/statusBar.vue' /* webpackChunkName: "pages/lucky-draw/my-prize/statusBar" */))
const _d821f2ee = () => interopDefault(import('../pages/pdp/track/track.mixins.js' /* webpackChunkName: "pages/pdp/track/track.mixins" */))
const _15878101 = () => interopDefault(import('../pages/plp/no-result/SearchNoResult.vue' /* webpackChunkName: "pages/plp/no-result/SearchNoResult" */))
const _900d5270 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4e68b16a = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _4aef78b7 = () => interopDefault(import('../pages/index/cart.vue' /* webpackChunkName: "pages/index/cart" */))
const _77fff7d6 = () => interopDefault(import('../pages/index/category.vue' /* webpackChunkName: "pages/index/category" */))
const _1a8cbb63 = () => interopDefault(import('../pages/index/my-account.vue' /* webpackChunkName: "pages/index/my-account" */))
const _90ac28b4 = () => interopDefault(import('../pages/index/my-account/index.vue' /* webpackChunkName: "pages/index/my-account/index" */))
const _d1426efe = () => interopDefault(import('../pages/index/my-account/account.vue' /* webpackChunkName: "pages/index/my-account/account" */))
const _11a69ec8 = () => interopDefault(import('../pages/index/my-account/address.vue' /* webpackChunkName: "pages/index/my-account/address" */))
const _6f80a072 = () => interopDefault(import('../pages/index/my-account/algorithm-description.vue' /* webpackChunkName: "pages/index/my-account/algorithm-description" */))
const _baf77e72 = () => interopDefault(import('../pages/index/my-account/contact-us.vue' /* webpackChunkName: "pages/index/my-account/contact-us" */))
const _38016602 = () => interopDefault(import('../pages/index/my-account/coupon.vue' /* webpackChunkName: "pages/index/my-account/coupon" */))
const _33871fba = () => interopDefault(import('../pages/index/my-account/delivery-rules.vue' /* webpackChunkName: "pages/index/my-account/delivery-rules" */))
const _436a6858 = () => interopDefault(import('../pages/index/my-account/employeeInfo.vue' /* webpackChunkName: "pages/index/my-account/employeeInfo" */))
const _1f8a9f86 = () => interopDefault(import('../pages/index/my-account/log-off/index.vue' /* webpackChunkName: "pages/index/my-account/log-off/index" */))
const _6e2819fa = () => interopDefault(import('../pages/index/my-account/memberCode.vue' /* webpackChunkName: "pages/index/my-account/memberCode" */))
const _47b4ac80 = () => interopDefault(import('../pages/index/my-account/my-account-skeleton-mobile/index.vue' /* webpackChunkName: "pages/index/my-account/my-account-skeleton-mobile/index" */))
const _1daa9211 = () => interopDefault(import('../pages/index/my-account/my-account-skeleton-pc/index.vue' /* webpackChunkName: "pages/index/my-account/my-account-skeleton-pc/index" */))
const _4609f5ea = () => interopDefault(import('../pages/index/my-account/personalInfo.vue' /* webpackChunkName: "pages/index/my-account/personalInfo" */))
const _9e0115aa = () => interopDefault(import('../pages/index/my-account/print-rules.vue' /* webpackChunkName: "pages/index/my-account/print-rules" */))
const _746f223e = () => interopDefault(import('../pages/index/my-account/privacy-statement.vue' /* webpackChunkName: "pages/index/my-account/privacy-statement" */))
const _5a623929 = () => interopDefault(import('../pages/index/my-account/purchase-guid.vue' /* webpackChunkName: "pages/index/my-account/purchase-guid" */))
const _188a3476 = () => interopDefault(import('../pages/index/my-account/recommand-rules.vue' /* webpackChunkName: "pages/index/my-account/recommand-rules" */))
const _0b1ea6f6 = () => interopDefault(import('../pages/index/my-account/return-rules.vue' /* webpackChunkName: "pages/index/my-account/return-rules" */))
const _66b12166 = () => interopDefault(import('../pages/index/my-account/score.vue' /* webpackChunkName: "pages/index/my-account/score" */))
const _5c758324 = () => interopDefault(import('../pages/index/my-account/setting.vue' /* webpackChunkName: "pages/index/my-account/setting" */))
const _23bf6b9a = () => interopDefault(import('../pages/index/my-account/terms-conditions.vue' /* webpackChunkName: "pages/index/my-account/terms-conditions" */))
const _4d3b5715 = () => interopDefault(import('../pages/index/my-account/use-terms.vue' /* webpackChunkName: "pages/index/my-account/use-terms" */))
const _5f1f5d9b = () => interopDefault(import('../pages/index/my-account/vip-rules.vue' /* webpackChunkName: "pages/index/my-account/vip-rules" */))
const _d6237e22 = () => interopDefault(import('../pages/index/my-account/log-off/logoff.mixins.js' /* webpackChunkName: "pages/index/my-account/log-off/logoff.mixins" */))
const _40fa70c9 = () => interopDefault(import('../pages/index/my-account/log-off/Utils.ts' /* webpackChunkName: "pages/index/my-account/log-off/Utils" */))
const _656df85e = () => interopDefault(import('../pages/index/my-account/order/list/index.vue' /* webpackChunkName: "pages/index/my-account/order/list/index" */))
const _53b60f98 = () => interopDefault(import('../pages/index/my-account/return/list/index.vue' /* webpackChunkName: "pages/index/my-account/return/list/index" */))
const _3974c01a = () => interopDefault(import('../pages/index/redirect.vue' /* webpackChunkName: "pages/index/redirect" */))
const _0cf61d1e = () => interopDefault(import('../pages/index/track/index.ts' /* webpackChunkName: "pages/index/track/index" */))
const _87abe10e = () => interopDefault(import('../pages/index/exchange/detail/index.vue' /* webpackChunkName: "pages/index/exchange/detail/index" */))
const _c77ba428 = () => interopDefault(import('../pages/index/exchange/track/index.ts' /* webpackChunkName: "pages/index/exchange/track/index" */))
const _94b080e8 = () => interopDefault(import('../pages/index/order/detail/index.vue' /* webpackChunkName: "pages/index/order/detail/index" */))
const _6382c6bf = () => interopDefault(import('../pages/index/order/track/index.ts' /* webpackChunkName: "pages/index/order/track/index" */))
const _0083a719 = () => interopDefault(import('../pages/index/refund-only/detail/index.vue' /* webpackChunkName: "pages/index/refund-only/detail/index" */))
const _064f004c = () => interopDefault(import('../pages/index/return/detail/index.vue' /* webpackChunkName: "pages/index/return/detail/index" */))
const _339f03e0 = () => interopDefault(import('../pages/index/return/sf-pickup-info/index.vue' /* webpackChunkName: "pages/index/return/sf-pickup-info/index" */))
const _7283377f = () => interopDefault(import('../pages/index/return/track/index.ts' /* webpackChunkName: "pages/index/return/track/index" */))
const _ba628b42 = () => interopDefault(import('../pages/index/track/track.mixins.js' /* webpackChunkName: "pages/index/track/track.mixins" */))
const _3002c5d1 = () => interopDefault(import('../pages/index/exchange/track/track.mixins.js' /* webpackChunkName: "pages/index/exchange/track/track.mixins" */))
const _11ff299e = () => interopDefault(import('../pages/index/order/track/track.mixins.js' /* webpackChunkName: "pages/index/order/track/track.mixins" */))
const _e0fb3e44 = () => interopDefault(import('../pages/index/return/track/track.mixins.js' /* webpackChunkName: "pages/index/return/track/track.mixins" */))
const _1bc24cf5 = () => interopDefault(import('../pages/demo/pdp/_id.vue' /* webpackChunkName: "pages/demo/pdp/_id" */))
const _3ad92c1a = () => interopDefault(import('../pages/identity/clp/_type.vue' /* webpackChunkName: "pages/identity/clp/_type" */))
const _c12d4e9c = () => interopDefault(import('../pages/identity/discount/_type.vue' /* webpackChunkName: "pages/identity/discount/_type" */))
const _a5d863a0 = () => interopDefault(import('../pages/partnership/discount/_type.vue' /* webpackChunkName: "pages/partnership/discount/_type" */))
const _b8c208fc = () => interopDefault(import('../pages/identity/_type.vue' /* webpackChunkName: "pages/identity/_type" */))
const _3058c044 = () => interopDefault(import('../pages/partnership/_type.vue' /* webpackChunkName: "pages/partnership/_type" */))
const _4d46087b = () => interopDefault(import('../pages/plp/_category/index.vue' /* webpackChunkName: "pages/plp/_category/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app-download",
    component: _60191131,
    name: "app-download"
  }, {
    path: "/braFinder",
    component: _57953ed0,
    name: "braFinder"
  }, {
    path: "/checkout",
    component: _26b6aa43,
    name: "checkout"
  }, {
    path: "/clp",
    component: _1d1338e0,
    name: "clp"
  }, {
    path: "/daily-checkin",
    component: _73050432,
    name: "daily-checkin"
  }, {
    path: "/demo",
    component: _f9b408c0,
    name: "demo"
  }, {
    path: "/error",
    component: _58d875fe,
    name: "error"
  }, {
    path: "/error-page",
    component: _50a39b9e,
    name: "error-page"
  }, {
    path: "/goods",
    component: _566ac0cf,
    name: "goods"
  }, {
    path: "/healthcheck",
    component: _3e2533a5,
    name: "healthcheck"
  }, {
    path: "/joint-membership",
    component: _24712f94,
    name: "joint-membership"
  }, {
    path: "/live800",
    component: _cbc60376,
    name: "live800"
  }, {
    path: "/location-selector",
    component: _5940aba0,
    name: "location-selector"
  }, {
    path: "/loop",
    component: _f10af1be,
    name: "loop"
  }, {
    path: "/lucky-draw",
    component: _f7c78c74,
    name: "lucky-draw"
  }, {
    path: "/nonmember-right2copy",
    component: _0c9707a4,
    name: "nonmember-right2copy"
  }, {
    path: "/operation",
    component: _66163ea0,
    name: "operation"
  }, {
    path: "/pdp",
    component: _07a53b16,
    name: "pdp"
  }, {
    path: "/right2copy",
    component: _9120df70,
    name: "right2copy"
  }, {
    path: "/siteMap",
    component: _5df81bce,
    name: "siteMap"
  }, {
    path: "/sizeGuide",
    component: _d19bb898,
    name: "sizeGuide"
  }, {
    path: "/splp",
    component: _e80dbe44,
    name: "splp"
  }, {
    path: "/adiclub/register",
    component: _c767c81a,
    name: "adiclub-register"
  }, {
    path: "/app-download/track",
    component: _79c61a10,
    name: "app-download-track"
  }, {
    path: "/braFinder/searchProduct",
    component: _12396fa5,
    name: "braFinder-searchProduct"
  }, {
    path: "/braFinder/select",
    component: _0e7679ee,
    name: "braFinder-select"
  }, {
    path: "/campaign/running_loop",
    component: _36615cfc,
    name: "campaign-running_loop"
  }, {
    path: "/catalog/catalog-mobile",
    component: _2c2018ee,
    name: "catalog-catalog-mobile"
  }, {
    path: "/catalog/catalog-skeleton",
    component: _f75ac318,
    name: "catalog-catalog-skeleton"
  }, {
    path: "/checkout/paid-off",
    component: _f255de60,
    name: "checkout-paid-off"
  }, {
    path: "/checkout/pay-page",
    component: _53051214,
    name: "checkout-pay-page"
  }, {
    path: "/checkout/pay-success",
    component: _186e5fd2,
    name: "checkout-pay-success"
  }, {
    path: "/checkout/track",
    component: _6a6395b4,
    name: "checkout-track"
  }, {
    path: "/clp/login",
    component: _328a4812,
    name: "clp-login"
  }, {
    path: "/daily-checkin/address",
    component: _24da8852,
    name: "daily-checkin-address"
  }, {
    path: "/daily-checkin/my-prize",
    component: _97a5fc78,
    name: "daily-checkin-my-prize"
  }, {
    path: "/demo/clp",
    component: _fac21216,
    name: "demo-clp"
  }, {
    path: "/demo/color",
    component: _295a51b1,
    name: "demo-color"
  }, {
    path: "/demo/demo-scale",
    component: _1312bffc,
    name: "demo-demo-scale"
  }, {
    path: "/demo/font",
    component: _b033b11e,
    name: "demo-font"
  }, {
    path: "/demo/margin-padding",
    component: _55b72414,
    name: "demo-margin-padding"
  }, {
    path: "/demo/plp",
    component: _324ff942,
    name: "demo-plp"
  }, {
    path: "/demo/square-list",
    component: _2dc7979c,
    name: "demo-square-list"
  }, {
    path: "/demo/testlogin",
    component: _f3aa5936,
    name: "demo-testlogin"
  }, {
    path: "/demo/text",
    component: _5648c14f,
    name: "demo-text"
  }, {
    path: "/download/confirmed",
    component: _31b07cdc,
    name: "download-confirmed"
  }, {
    path: "/error-page/error-normal-net",
    component: _5b9cffd0,
    name: "error-page-error-normal-net"
  }, {
    path: "/error-page/error-normal-pc-double",
    component: _d65208c4,
    name: "error-page-error-normal-pc-double"
  }, {
    path: "/error-page/error-normal-tablet-double",
    component: _a2b018ea,
    name: "error-page-error-normal-tablet-double"
  }, {
    path: "/error-page/error-normal-visitors",
    component: _2f295370,
    name: "error-page-error-normal-visitors"
  }, {
    path: "/error-page/error-pop-net",
    component: _04a28e04,
    name: "error-page-error-pop-net"
  }, {
    path: "/error-page/error-pop-visitors",
    component: _2ff7ac4c,
    name: "error-page-error-pop-visitors"
  }, {
    path: "/joint-membership/track",
    component: _10553713,
    name: "joint-membership-track"
  }, {
    path: "/loop/LoopAddress",
    component: _908482c2,
    name: "loop-LoopAddress"
  }, {
    path: "/loop/LoopAddressSelector",
    component: _16b7081e,
    name: "loop-LoopAddressSelector"
  }, {
    path: "/loop/thanks",
    component: _7f08660d,
    name: "loop-thanks"
  }, {
    path: "/lucky-draw/address",
    component: _4b95c52a,
    name: "lucky-draw-address"
  }, {
    path: "/lucky-draw/auto",
    component: _21b2adeb,
    name: "lucky-draw-auto"
  }, {
    path: "/lucky-draw/my-prize",
    component: _5bd6d2b0,
    name: "lucky-draw-my-prize"
  }, {
    path: "/lucky-draw/rule",
    component: _82bfd58a,
    name: "lucky-draw-rule"
  }, {
    path: "/nonmember-right2copy/example",
    component: _4cce1074,
    name: "nonmember-right2copy-example"
  }, {
    path: "/operation/bosdownload",
    component: _656ef91f,
    name: "operation-bosdownload"
  }, {
    path: "/operation/dljumper",
    component: _6dffc870,
    name: "operation-dljumper"
  }, {
    path: "/pdp/track",
    component: _401ccc34,
    name: "pdp-track"
  }, {
    path: "/plp/track",
    component: _75288788,
    name: "plp-track"
  }, {
    path: "/right2copy/example",
    component: _14df6d60,
    name: "right2copy-example"
  }, {
    path: "/search/track",
    component: _757b6c04,
    name: "search-track"
  }, {
    path: "/siteMap/ssr.async.data",
    component: _276461ba,
    name: "siteMap-ssr.async.data"
  }, {
    path: "/sizeGuide/utils",
    component: _3a40ce52,
    name: "sizeGuide-utils"
  }, {
    path: "/campaign/running_loop/utils",
    component: _37214c0a,
    name: "campaign-running_loop-utils"
  }, {
    path: "/checkout/track/track.mixins",
    component: _7d350957,
    name: "checkout-track-track.mixins"
  }, {
    path: "/demo/components/demo-lib-anchor",
    component: _af7c1f60,
    name: "demo-components-demo-lib-anchor"
  }, {
    path: "/demo/components/demo-lib-bottom-bar",
    component: _d0e118e8,
    name: "demo-components-demo-lib-bottom-bar"
  }, {
    path: "/demo/components/demo-lib-breadcrumb",
    component: _cd7ae5c4,
    name: "demo-components-demo-lib-breadcrumb"
  }, {
    path: "/demo/components/demo-lib-button",
    component: _0af0ce4d,
    name: "demo-components-demo-lib-button"
  }, {
    path: "/demo/components/demo-lib-cell",
    component: _21f01d3d,
    name: "demo-components-demo-lib-cell"
  }, {
    path: "/demo/components/demo-lib-dialog",
    component: _b9f8ad3a,
    name: "demo-components-demo-lib-dialog"
  }, {
    path: "/demo/components/demo-lib-format",
    component: _08deb492,
    name: "demo-components-demo-lib-format"
  }, {
    path: "/demo/components/demo-lib-input",
    component: _2346fb7f,
    name: "demo-components-demo-lib-input"
  }, {
    path: "/demo/components/demo-lib-loading",
    component: _9e552e9e,
    name: "demo-components-demo-lib-loading"
  }, {
    path: "/demo/components/demo-lib-number",
    component: _439cc238,
    name: "demo-components-demo-lib-number"
  }, {
    path: "/demo/components/demo-lib-popover",
    component: _67efeccc,
    name: "demo-components-demo-lib-popover"
  }, {
    path: "/demo/components/demo-lib-popper",
    component: _6964ab47,
    name: "demo-components-demo-lib-popper"
  }, {
    path: "/demo/components/demo-lib-product-card",
    component: _2e5a6809,
    name: "demo-components-demo-lib-product-card"
  }, {
    path: "/demo/components/demo-lib-product-list",
    component: _7f0d2697,
    name: "demo-components-demo-lib-product-list"
  }, {
    path: "/demo/components/demo-lib-radio",
    component: _f89791a0,
    name: "demo-components-demo-lib-radio"
  }, {
    path: "/demo/components/demo-lib-step-group",
    component: _548ae04e,
    name: "demo-components-demo-lib-step-group"
  }, {
    path: "/demo/components/demo-lib-uploader",
    component: _5b801a6e,
    name: "demo-components-demo-lib-uploader"
  }, {
    path: "/demo/components/demo-login-register",
    component: _75dd735a,
    name: "demo-components-demo-login-register"
  }, {
    path: "/demo/page-components/demo-lib-discount-card",
    component: _793ea667,
    name: "demo-page-components-demo-lib-discount-card"
  }, {
    path: "/demo/page-components/demo-lib-goods-card",
    component: _1eef2528,
    name: "demo-page-components-demo-lib-goods-card"
  }, {
    path: "/demo/page-components/demo-steps",
    component: _10de69ba,
    name: "demo-page-components-demo-steps"
  }, {
    path: "/joint-membership/track/track.mixins",
    component: _4e7172ca,
    name: "joint-membership-track-track.mixins"
  }, {
    path: "/loop/component/select",
    component: _828dfe62,
    name: "loop-component-select"
  }, {
    path: "/loop/product/product",
    component: _9e225244,
    name: "loop-product-product"
  }, {
    path: "/lucky-draw/my-prize/statusBar",
    component: _e4fb43c2,
    name: "lucky-draw-my-prize-statusBar"
  }, {
    path: "/pdp/track/track.mixins",
    component: _d821f2ee,
    name: "pdp-track-track.mixins"
  }, {
    path: "/plp/no-result/SearchNoResult",
    component: _15878101,
    name: "plp-no-result-SearchNoResult"
  }, {
    path: "/",
    component: _900d5270,
    children: [{
      path: "",
      component: _4e68b16a,
      name: "index"
    }, {
      path: "cart",
      component: _4aef78b7,
      name: "index-cart"
    }, {
      path: "category",
      component: _77fff7d6,
      name: "index-category"
    }, {
      path: "my-account",
      component: _1a8cbb63,
      children: [{
        path: "",
        component: _90ac28b4,
        name: "index-my-account"
      }, {
        path: "account",
        component: _d1426efe,
        name: "index-my-account-account"
      }, {
        path: "address",
        component: _11a69ec8,
        name: "index-my-account-address"
      }, {
        path: "algorithm-description",
        component: _6f80a072,
        name: "index-my-account-algorithm-description"
      }, {
        path: "contact-us",
        component: _baf77e72,
        name: "index-my-account-contact-us"
      }, {
        path: "coupon",
        component: _38016602,
        name: "index-my-account-coupon"
      }, {
        path: "delivery-rules",
        component: _33871fba,
        name: "index-my-account-delivery-rules"
      }, {
        path: "employeeInfo",
        component: _436a6858,
        name: "index-my-account-employeeInfo"
      }, {
        path: "log-off",
        component: _1f8a9f86,
        name: "index-my-account-log-off"
      }, {
        path: "memberCode",
        component: _6e2819fa,
        name: "index-my-account-memberCode"
      }, {
        path: "my-account-skeleton-mobile",
        component: _47b4ac80,
        name: "index-my-account-my-account-skeleton-mobile"
      }, {
        path: "my-account-skeleton-pc",
        component: _1daa9211,
        name: "index-my-account-my-account-skeleton-pc"
      }, {
        path: "personalInfo",
        component: _4609f5ea,
        name: "index-my-account-personalInfo"
      }, {
        path: "print-rules",
        component: _9e0115aa,
        name: "index-my-account-print-rules"
      }, {
        path: "privacy-statement",
        component: _746f223e,
        name: "index-my-account-privacy-statement"
      }, {
        path: "purchase-guid",
        component: _5a623929,
        name: "index-my-account-purchase-guid"
      }, {
        path: "recommand-rules",
        component: _188a3476,
        name: "index-my-account-recommand-rules"
      }, {
        path: "return-rules",
        component: _0b1ea6f6,
        name: "index-my-account-return-rules"
      }, {
        path: "score",
        component: _66b12166,
        name: "index-my-account-score"
      }, {
        path: "setting",
        component: _5c758324,
        name: "index-my-account-setting"
      }, {
        path: "terms-conditions",
        component: _23bf6b9a,
        name: "index-my-account-terms-conditions"
      }, {
        path: "use-terms",
        component: _4d3b5715,
        name: "index-my-account-use-terms"
      }, {
        path: "vip-rules",
        component: _5f1f5d9b,
        name: "index-my-account-vip-rules"
      }, {
        path: "log-off/logoff.mixins",
        component: _d6237e22,
        name: "index-my-account-log-off-logoff.mixins"
      }, {
        path: "log-off/Utils",
        component: _40fa70c9,
        name: "index-my-account-log-off-Utils"
      }, {
        path: "order/list",
        component: _656df85e,
        name: "index-my-account-order-list"
      }, {
        path: "return/list",
        component: _53b60f98,
        name: "index-my-account-return-list"
      }]
    }, {
      path: "redirect",
      component: _3974c01a,
      name: "index-redirect"
    }, {
      path: "track",
      component: _0cf61d1e,
      name: "index-track"
    }, {
      path: "exchange/detail",
      component: _87abe10e,
      name: "index-exchange-detail"
    }, {
      path: "exchange/track",
      component: _c77ba428,
      name: "index-exchange-track"
    }, {
      path: "order/detail",
      component: _94b080e8,
      name: "index-order-detail"
    }, {
      path: "order/track",
      component: _6382c6bf,
      name: "index-order-track"
    }, {
      path: "refund-only/detail",
      component: _0083a719,
      name: "index-refund-only-detail"
    }, {
      path: "return/detail",
      component: _064f004c,
      name: "index-return-detail"
    }, {
      path: "return/sf-pickup-info",
      component: _339f03e0,
      name: "index-return-sf-pickup-info"
    }, {
      path: "return/track",
      component: _7283377f,
      name: "index-return-track"
    }, {
      path: "track/track.mixins",
      component: _ba628b42,
      name: "index-track-track.mixins"
    }, {
      path: "exchange/track/track.mixins",
      component: _3002c5d1,
      name: "index-exchange-track-track.mixins"
    }, {
      path: "order/track/track.mixins",
      component: _11ff299e,
      name: "index-order-track-track.mixins"
    }, {
      path: "return/track/track.mixins",
      component: _e0fb3e44,
      name: "index-return-track-track.mixins"
    }]
  }, {
    path: "/demo/pdp/:id?",
    component: _1bc24cf5,
    name: "demo-pdp-id"
  }, {
    path: "/identity/clp/:type?",
    component: _3ad92c1a,
    name: "identity-clp-type"
  }, {
    path: "/identity/discount/:type?",
    component: _c12d4e9c,
    name: "identity-discount-type"
  }, {
    path: "/partnership/discount/:type?",
    component: _a5d863a0,
    name: "partnership-discount-type"
  }, {
    path: "/identity/:type?",
    component: _b8c208fc,
    name: "identity-type"
  }, {
    path: "/partnership/:type?",
    component: _3058c044,
    name: "partnership-type"
  }, {
    path: "/plp/:category",
    component: _4d46087b,
    name: "plp-category"
  }, {
    path: "/OR/app-download",
    component: _60191131,
    name: "OR-app-download"
  }, {
    path: "/OR/braFinder",
    component: _57953ed0,
    name: "OR-braFinder"
  }, {
    path: "/OR/checkout",
    component: _26b6aa43,
    name: "OR-checkout"
  }, {
    path: "/OR/clp",
    component: _1d1338e0,
    name: "OR-clp"
  }, {
    path: "/OR/daily-checkin",
    component: _73050432,
    name: "OR-daily-checkin"
  }, {
    path: "/OR/demo",
    component: _f9b408c0,
    name: "OR-demo"
  }, {
    path: "/OR/error",
    component: _58d875fe,
    name: "OR-error"
  }, {
    path: "/OR/error-page",
    component: _50a39b9e,
    name: "OR-error-page"
  }, {
    path: "/OR/goods",
    component: _566ac0cf,
    name: "OR-goods"
  }, {
    path: "/OR/healthcheck",
    component: _3e2533a5,
    name: "OR-healthcheck"
  }, {
    path: "/OR/joint-membership",
    component: _24712f94,
    name: "OR-joint-membership"
  }, {
    path: "/OR/live800",
    component: _cbc60376,
    name: "OR-live800"
  }, {
    path: "/OR/location-selector",
    component: _5940aba0,
    name: "OR-location-selector"
  }, {
    path: "/OR/loop",
    component: _f10af1be,
    name: "OR-loop"
  }, {
    path: "/OR/lucky-draw",
    component: _f7c78c74,
    name: "OR-lucky-draw"
  }, {
    path: "/OR/my-account/nonmember-right2copy",
    component: _0c9707a4,
    name: "OR-my-nonmember-right2copy"
  }, {
    path: "/OR/operation",
    component: _66163ea0,
    name: "OR-operation"
  }, {
    path: "/OR/pdp",
    component: _07a53b16,
    name: "OR-pdp"
  }, {
    path: "/OR/my-account/right2copy",
    component: _9120df70,
    name: "OR-my-right2copy"
  }, {
    path: "/OR/siteMap",
    component: _5df81bce,
    name: "OR-siteMap"
  }, {
    path: "/OR/sizeGuide",
    component: _d19bb898,
    name: "OR-sizeGuide"
  }, {
    path: "/OR/splp",
    component: _e80dbe44,
    name: "OR-splp"
  }, {
    path: "/OR/adiclub/register",
    component: _c767c81a,
    name: "OR-adiclub-register"
  }, {
    path: "/OR/app-download/track",
    component: _79c61a10,
    name: "OR-app-download-track"
  }, {
    path: "/OR/braFinder/searchProduct",
    component: _12396fa5,
    name: "OR-braFinder-searchProduct"
  }, {
    path: "/OR/braFinder/select",
    component: _0e7679ee,
    name: "OR-braFinder-select"
  }, {
    path: "/OR/campaign/running_loop",
    component: _36615cfc,
    name: "OR-campaign-running_loop"
  }, {
    path: "/OR/catalog/catalog-mobile",
    component: _2c2018ee,
    name: "OR-catalog-catalog-mobile"
  }, {
    path: "/OR/catalog/catalog-skeleton",
    component: _f75ac318,
    name: "OR-catalog-catalog-skeleton"
  }, {
    path: "/OR/checkout/paid-off",
    component: _f255de60,
    name: "OR-checkout-paid-off"
  }, {
    path: "/OR/checkout/pay-page",
    component: _53051214,
    name: "OR-checkout-pay-page"
  }, {
    path: "/OR/checkout/pay-success",
    component: _186e5fd2,
    name: "OR-checkout-pay-success"
  }, {
    path: "/OR/checkout/track",
    component: _6a6395b4,
    name: "OR-checkout-track"
  }, {
    path: "/OR/clp/login",
    component: _328a4812,
    name: "OR-clp-login"
  }, {
    path: "/OR/daily-checkin/address",
    component: _24da8852,
    name: "OR-daily-checkin-address"
  }, {
    path: "/OR/daily-checkin/my-prize",
    component: _97a5fc78,
    name: "OR-daily-checkin-my-prize"
  }, {
    path: "/OR/demo/clp",
    component: _fac21216,
    name: "OR-demo-clp"
  }, {
    path: "/OR/demo/color",
    component: _295a51b1,
    name: "OR-demo-color"
  }, {
    path: "/OR/demo/demo-scale",
    component: _1312bffc,
    name: "OR-demo-demo-scale"
  }, {
    path: "/OR/demo/font",
    component: _b033b11e,
    name: "OR-demo-font"
  }, {
    path: "/OR/demo/margin-padding",
    component: _55b72414,
    name: "OR-demo-margin-padding"
  }, {
    path: "/OR/demo/plp",
    component: _324ff942,
    name: "OR-demo-plp"
  }, {
    path: "/OR/demo/square-list",
    component: _2dc7979c,
    name: "OR-demo-square-list"
  }, {
    path: "/OR/demo/testlogin",
    component: _f3aa5936,
    name: "OR-demo-testlogin"
  }, {
    path: "/OR/demo/text",
    component: _5648c14f,
    name: "OR-demo-text"
  }, {
    path: "/OR/download/confirmed",
    component: _31b07cdc,
    name: "OR-download-confirmed"
  }, {
    path: "/OR/error-page/error-normal-net",
    component: _5b9cffd0,
    name: "OR-error-page-error-normal-net"
  }, {
    path: "/OR/error-page/error-normal-pc-double",
    component: _d65208c4,
    name: "OR-error-page-error-normal-pc-double"
  }, {
    path: "/OR/error-page/error-normal-tablet-double",
    component: _a2b018ea,
    name: "OR-error-page-error-normal-tablet-double"
  }, {
    path: "/OR/error-page/error-normal-visitors",
    component: _2f295370,
    name: "OR-error-page-error-normal-visitors"
  }, {
    path: "/OR/error-page/error-pop-net",
    component: _04a28e04,
    name: "OR-error-page-error-pop-net"
  }, {
    path: "/OR/error-page/error-pop-visitors",
    component: _2ff7ac4c,
    name: "OR-error-page-error-pop-visitors"
  }, {
    path: "/OR/joint-membership/track",
    component: _10553713,
    name: "OR-joint-membership-track"
  }, {
    path: "/OR/loop/LoopAddress",
    component: _908482c2,
    name: "OR-loop-LoopAddress"
  }, {
    path: "/OR/loop/LoopAddressSelector",
    component: _16b7081e,
    name: "OR-loop-LoopAddressSelector"
  }, {
    path: "/OR/loop/thanks",
    component: _7f08660d,
    name: "OR-loop-thanks"
  }, {
    path: "/OR/lucky-draw/address",
    component: _4b95c52a,
    name: "OR-lucky-draw-address"
  }, {
    path: "/OR/lucky-draw/auto",
    component: _21b2adeb,
    name: "OR-lucky-draw-auto"
  }, {
    path: "/OR/lucky-draw/my-prize",
    component: _5bd6d2b0,
    name: "OR-lucky-draw-my-prize"
  }, {
    path: "/OR/lucky-draw/rule",
    component: _82bfd58a,
    name: "OR-lucky-draw-rule"
  }, {
    path: "/OR/my-account/nonmember-right2copy/example",
    component: _4cce1074,
    name: "OR-my-nonmember-right2copy-example"
  }, {
    path: "/OR/operation/bosdownload",
    component: _656ef91f,
    name: "OR-operation-bosdownload"
  }, {
    path: "/OR/operation/dljumper",
    component: _6dffc870,
    name: "OR-operation-dljumper"
  }, {
    path: "/OR/pdp/track",
    component: _401ccc34,
    name: "OR-pdp-track"
  }, {
    path: "/OR/plp/track",
    component: _75288788,
    name: "OR-plp-track"
  }, {
    path: "/OR/my-account/right2copy/example",
    component: _14df6d60,
    name: "OR-my-right2copy-example"
  }, {
    path: "/OR/search/track",
    component: _757b6c04,
    name: "OR-search-track"
  }, {
    path: "/OR/siteMap/ssr.async.data",
    component: _276461ba,
    name: "OR-siteMap-ssr.async.data"
  }, {
    path: "/OR/sizeGuide/utils",
    component: _3a40ce52,
    name: "OR-sizeGuide-utils"
  }, {
    path: "/OR/campaign/running_loop/utils",
    component: _37214c0a,
    name: "OR-campaign-running_loop-utils"
  }, {
    path: "/OR/checkout/track/track.mixins",
    component: _7d350957,
    name: "OR-checkout-track-track.mixins"
  }, {
    path: "/OR/demo/components/demo-lib-anchor",
    component: _af7c1f60,
    name: "OR-demo-components-demo-lib-anchor"
  }, {
    path: "/OR/demo/components/demo-lib-bottom-bar",
    component: _d0e118e8,
    name: "OR-demo-components-demo-lib-bottom-bar"
  }, {
    path: "/OR/demo/components/demo-lib-breadcrumb",
    component: _cd7ae5c4,
    name: "OR-demo-components-demo-lib-breadcrumb"
  }, {
    path: "/OR/demo/components/demo-lib-button",
    component: _0af0ce4d,
    name: "OR-demo-components-demo-lib-button"
  }, {
    path: "/OR/demo/components/demo-lib-cell",
    component: _21f01d3d,
    name: "OR-demo-components-demo-lib-cell"
  }, {
    path: "/OR/demo/components/demo-lib-dialog",
    component: _b9f8ad3a,
    name: "OR-demo-components-demo-lib-dialog"
  }, {
    path: "/OR/demo/components/demo-lib-format",
    component: _08deb492,
    name: "OR-demo-components-demo-lib-format"
  }, {
    path: "/OR/demo/components/demo-lib-input",
    component: _2346fb7f,
    name: "OR-demo-components-demo-lib-input"
  }, {
    path: "/OR/demo/components/demo-lib-loading",
    component: _9e552e9e,
    name: "OR-demo-components-demo-lib-loading"
  }, {
    path: "/OR/demo/components/demo-lib-number",
    component: _439cc238,
    name: "OR-demo-components-demo-lib-number"
  }, {
    path: "/OR/demo/components/demo-lib-popover",
    component: _67efeccc,
    name: "OR-demo-components-demo-lib-popover"
  }, {
    path: "/OR/demo/components/demo-lib-popper",
    component: _6964ab47,
    name: "OR-demo-components-demo-lib-popper"
  }, {
    path: "/OR/demo/components/demo-lib-product-card",
    component: _2e5a6809,
    name: "OR-demo-components-demo-lib-product-card"
  }, {
    path: "/OR/demo/components/demo-lib-product-list",
    component: _7f0d2697,
    name: "OR-demo-components-demo-lib-product-list"
  }, {
    path: "/OR/demo/components/demo-lib-radio",
    component: _f89791a0,
    name: "OR-demo-components-demo-lib-radio"
  }, {
    path: "/OR/demo/components/demo-lib-step-group",
    component: _548ae04e,
    name: "OR-demo-components-demo-lib-step-group"
  }, {
    path: "/OR/demo/components/demo-lib-uploader",
    component: _5b801a6e,
    name: "OR-demo-components-demo-lib-uploader"
  }, {
    path: "/OR/demo/components/demo-login-register",
    component: _75dd735a,
    name: "OR-demo-components-demo-login-register"
  }, {
    path: "/OR/demo/page-components/demo-lib-discount-card",
    component: _793ea667,
    name: "OR-demo-page-components-demo-lib-discount-card"
  }, {
    path: "/OR/demo/page-components/demo-lib-goods-card",
    component: _1eef2528,
    name: "OR-demo-page-components-demo-lib-goods-card"
  }, {
    path: "/OR/demo/page-components/demo-steps",
    component: _10de69ba,
    name: "OR-demo-page-components-demo-steps"
  }, {
    path: "/OR/joint-membership/track/track.mixins",
    component: _4e7172ca,
    name: "OR-joint-membership-track-track.mixins"
  }, {
    path: "/OR/loop/component/select",
    component: _828dfe62,
    name: "OR-loop-component-select"
  }, {
    path: "/OR/loop/product/product",
    component: _9e225244,
    name: "OR-loop-product-product"
  }, {
    path: "/OR/lucky-draw/my-prize/statusBar",
    component: _e4fb43c2,
    name: "OR-lucky-draw-my-prize-statusBar"
  }, {
    path: "/OR/pdp/track/track.mixins",
    component: _d821f2ee,
    name: "OR-pdp-track-track.mixins"
  }, {
    path: "/OR/plp/no-result/SearchNoResult",
    component: _15878101,
    name: "OR-plp-no-result-SearchNoResult"
  }, {
    path: "/OR/",
    component: _900d5270,
    name: "OR-undefined"
  }, {
    path: "/OR/demo/pdp/:id?",
    component: _1bc24cf5,
    name: "OR-demo-pdp-id"
  }, {
    path: "/OR/identity/clp/:type?",
    component: _3ad92c1a,
    name: "OR-identity-clp-type"
  }, {
    path: "/OR/identity/discount/:type?",
    component: _c12d4e9c,
    name: "OR-identity-discount-type"
  }, {
    path: "/OR/partnership/discount/:type?",
    component: _a5d863a0,
    name: "OR-partnership-discount-type"
  }, {
    path: "/OR/identity/:type?",
    component: _b8c208fc,
    name: "OR-identity-type"
  }, {
    path: "/OR/partnership/:type?",
    component: _3058c044,
    name: "OR-partnership-type"
  }, {
    path: "/OR/plp/:category",
    component: _4d46087b,
    name: "OR-plp-category"
  }, {
    path: "/WMS/app-download",
    component: _60191131,
    name: "WMS-app-download"
  }, {
    path: "/WMS/braFinder",
    component: _57953ed0,
    name: "WMS-braFinder"
  }, {
    path: "/WMS/checkout",
    component: _26b6aa43,
    name: "WMS-checkout"
  }, {
    path: "/WMS/clp",
    component: _1d1338e0,
    name: "WMS-clp"
  }, {
    path: "/WMS/daily-checkin",
    component: _73050432,
    name: "WMS-daily-checkin"
  }, {
    path: "/WMS/demo",
    component: _f9b408c0,
    name: "WMS-demo"
  }, {
    path: "/WMS/error",
    component: _58d875fe,
    name: "WMS-error"
  }, {
    path: "/WMS/error-page",
    component: _50a39b9e,
    name: "WMS-error-page"
  }, {
    path: "/WMS/goods",
    component: _566ac0cf,
    name: "WMS-goods"
  }, {
    path: "/WMS/healthcheck",
    component: _3e2533a5,
    name: "WMS-healthcheck"
  }, {
    path: "/WMS/joint-membership",
    component: _24712f94,
    name: "WMS-joint-membership"
  }, {
    path: "/WMS/live800",
    component: _cbc60376,
    name: "WMS-live800"
  }, {
    path: "/WMS/location-selector",
    component: _5940aba0,
    name: "WMS-location-selector"
  }, {
    path: "/WMS/loop",
    component: _f10af1be,
    name: "WMS-loop"
  }, {
    path: "/WMS/lucky-draw",
    component: _f7c78c74,
    name: "WMS-lucky-draw"
  }, {
    path: "/WMS/my-account/nonmember-right2copy",
    component: _0c9707a4,
    name: "WMS-my-nonmember-right2copy"
  }, {
    path: "/my-account/nonmember-right2copy",
    component: _0c9707a4,
    name: "COM-my-nonmember-right2copy"
  }, {
    path: "/WMS/operation",
    component: _66163ea0,
    name: "WMS-operation"
  }, {
    path: "/WMS/pdp",
    component: _07a53b16,
    name: "WMS-pdp"
  }, {
    path: "/WMS/my-account/right2copy",
    component: _9120df70,
    name: "WMS-my-right2copy"
  }, {
    path: "/my-account/right2copy",
    component: _9120df70,
    name: "COM-my-right2copy"
  }, {
    path: "/WMS/siteMap",
    component: _5df81bce,
    name: "WMS-siteMap"
  }, {
    path: "/WMS/sizeGuide",
    component: _d19bb898,
    name: "WMS-sizeGuide"
  }, {
    path: "/WMS/splp",
    component: _e80dbe44,
    name: "WMS-splp"
  }, {
    path: "/WMS/adiclub/register",
    component: _c767c81a,
    name: "WMS-adiclub-register"
  }, {
    path: "/WMS/app-download/track",
    component: _79c61a10,
    name: "WMS-app-download-track"
  }, {
    path: "/WMS/braFinder/searchProduct",
    component: _12396fa5,
    name: "WMS-braFinder-searchProduct"
  }, {
    path: "/WMS/braFinder/select",
    component: _0e7679ee,
    name: "WMS-braFinder-select"
  }, {
    path: "/WMS/campaign/running_loop",
    component: _36615cfc,
    name: "WMS-campaign-running_loop"
  }, {
    path: "/WMS/catalog/catalog-mobile",
    component: _2c2018ee,
    name: "WMS-catalog-catalog-mobile"
  }, {
    path: "/WMS/catalog/catalog-skeleton",
    component: _f75ac318,
    name: "WMS-catalog-catalog-skeleton"
  }, {
    path: "/WMS/checkout/paid-off",
    component: _f255de60,
    name: "WMS-checkout-paid-off"
  }, {
    path: "/WMS/checkout/pay-page",
    component: _53051214,
    name: "WMS-checkout-pay-page"
  }, {
    path: "/WMS/checkout/pay-success",
    component: _186e5fd2,
    name: "WMS-checkout-pay-success"
  }, {
    path: "/WMS/checkout/track",
    component: _6a6395b4,
    name: "WMS-checkout-track"
  }, {
    path: "/WMS/clp/login",
    component: _328a4812,
    name: "WMS-clp-login"
  }, {
    path: "/WMS/daily-checkin/address",
    component: _24da8852,
    name: "WMS-daily-checkin-address"
  }, {
    path: "/WMS/daily-checkin/my-prize",
    component: _97a5fc78,
    name: "WMS-daily-checkin-my-prize"
  }, {
    path: "/WMS/demo/clp",
    component: _fac21216,
    name: "WMS-demo-clp"
  }, {
    path: "/WMS/demo/color",
    component: _295a51b1,
    name: "WMS-demo-color"
  }, {
    path: "/WMS/demo/demo-scale",
    component: _1312bffc,
    name: "WMS-demo-demo-scale"
  }, {
    path: "/WMS/demo/font",
    component: _b033b11e,
    name: "WMS-demo-font"
  }, {
    path: "/WMS/demo/margin-padding",
    component: _55b72414,
    name: "WMS-demo-margin-padding"
  }, {
    path: "/WMS/demo/plp",
    component: _324ff942,
    name: "WMS-demo-plp"
  }, {
    path: "/WMS/demo/square-list",
    component: _2dc7979c,
    name: "WMS-demo-square-list"
  }, {
    path: "/WMS/demo/testlogin",
    component: _f3aa5936,
    name: "WMS-demo-testlogin"
  }, {
    path: "/WMS/demo/text",
    component: _5648c14f,
    name: "WMS-demo-text"
  }, {
    path: "/WMS/download/confirmed",
    component: _31b07cdc,
    name: "WMS-download-confirmed"
  }, {
    path: "/WMS/error-page/error-normal-net",
    component: _5b9cffd0,
    name: "WMS-error-page-error-normal-net"
  }, {
    path: "/WMS/error-page/error-normal-pc-double",
    component: _d65208c4,
    name: "WMS-error-page-error-normal-pc-double"
  }, {
    path: "/WMS/error-page/error-normal-tablet-double",
    component: _a2b018ea,
    name: "WMS-error-page-error-normal-tablet-double"
  }, {
    path: "/WMS/error-page/error-normal-visitors",
    component: _2f295370,
    name: "WMS-error-page-error-normal-visitors"
  }, {
    path: "/WMS/error-page/error-pop-net",
    component: _04a28e04,
    name: "WMS-error-page-error-pop-net"
  }, {
    path: "/WMS/error-page/error-pop-visitors",
    component: _2ff7ac4c,
    name: "WMS-error-page-error-pop-visitors"
  }, {
    path: "/WMS/joint-membership/track",
    component: _10553713,
    name: "WMS-joint-membership-track"
  }, {
    path: "/WMS/loop/LoopAddress",
    component: _908482c2,
    name: "WMS-loop-LoopAddress"
  }, {
    path: "/WMS/loop/LoopAddressSelector",
    component: _16b7081e,
    name: "WMS-loop-LoopAddressSelector"
  }, {
    path: "/WMS/loop/thanks",
    component: _7f08660d,
    name: "WMS-loop-thanks"
  }, {
    path: "/WMS/lucky-draw/address",
    component: _4b95c52a,
    name: "WMS-lucky-draw-address"
  }, {
    path: "/WMS/lucky-draw/auto",
    component: _21b2adeb,
    name: "WMS-lucky-draw-auto"
  }, {
    path: "/WMS/lucky-draw/my-prize",
    component: _5bd6d2b0,
    name: "WMS-lucky-draw-my-prize"
  }, {
    path: "/WMS/lucky-draw/rule",
    component: _82bfd58a,
    name: "WMS-lucky-draw-rule"
  }, {
    path: "/WMS/my-account/nonmember-right2copy/example",
    component: _4cce1074,
    name: "WMS-my-nonmember-right2copy-example"
  }, {
    path: "/my-account/nonmember-right2copy/example",
    component: _4cce1074,
    name: "COM-my-nonmember-right2copy-example"
  }, {
    path: "/WMS/operation/bosdownload",
    component: _656ef91f,
    name: "WMS-operation-bosdownload"
  }, {
    path: "/WMS/operation/dljumper",
    component: _6dffc870,
    name: "WMS-operation-dljumper"
  }, {
    path: "/WMS/pdp/track",
    component: _401ccc34,
    name: "WMS-pdp-track"
  }, {
    path: "/WMS/plp/track",
    component: _75288788,
    name: "WMS-plp-track"
  }, {
    path: "/WMS/my-account/right2copy/example",
    component: _14df6d60,
    name: "WMS-my-right2copy-example"
  }, {
    path: "/my-account/right2copy/example",
    component: _14df6d60,
    name: "COM-my-right2copy-example"
  }, {
    path: "/WMS/search/track",
    component: _757b6c04,
    name: "WMS-search-track"
  }, {
    path: "/WMS/siteMap/ssr.async.data",
    component: _276461ba,
    name: "WMS-siteMap-ssr.async.data"
  }, {
    path: "/WMS/sizeGuide/utils",
    component: _3a40ce52,
    name: "WMS-sizeGuide-utils"
  }, {
    path: "/WMS/campaign/running_loop/utils",
    component: _37214c0a,
    name: "WMS-campaign-running_loop-utils"
  }, {
    path: "/WMS/checkout/track/track.mixins",
    component: _7d350957,
    name: "WMS-checkout-track-track.mixins"
  }, {
    path: "/WMS/demo/components/demo-lib-anchor",
    component: _af7c1f60,
    name: "WMS-demo-components-demo-lib-anchor"
  }, {
    path: "/WMS/demo/components/demo-lib-bottom-bar",
    component: _d0e118e8,
    name: "WMS-demo-components-demo-lib-bottom-bar"
  }, {
    path: "/WMS/demo/components/demo-lib-breadcrumb",
    component: _cd7ae5c4,
    name: "WMS-demo-components-demo-lib-breadcrumb"
  }, {
    path: "/WMS/demo/components/demo-lib-button",
    component: _0af0ce4d,
    name: "WMS-demo-components-demo-lib-button"
  }, {
    path: "/WMS/demo/components/demo-lib-cell",
    component: _21f01d3d,
    name: "WMS-demo-components-demo-lib-cell"
  }, {
    path: "/WMS/demo/components/demo-lib-dialog",
    component: _b9f8ad3a,
    name: "WMS-demo-components-demo-lib-dialog"
  }, {
    path: "/WMS/demo/components/demo-lib-format",
    component: _08deb492,
    name: "WMS-demo-components-demo-lib-format"
  }, {
    path: "/WMS/demo/components/demo-lib-input",
    component: _2346fb7f,
    name: "WMS-demo-components-demo-lib-input"
  }, {
    path: "/WMS/demo/components/demo-lib-loading",
    component: _9e552e9e,
    name: "WMS-demo-components-demo-lib-loading"
  }, {
    path: "/WMS/demo/components/demo-lib-number",
    component: _439cc238,
    name: "WMS-demo-components-demo-lib-number"
  }, {
    path: "/WMS/demo/components/demo-lib-popover",
    component: _67efeccc,
    name: "WMS-demo-components-demo-lib-popover"
  }, {
    path: "/WMS/demo/components/demo-lib-popper",
    component: _6964ab47,
    name: "WMS-demo-components-demo-lib-popper"
  }, {
    path: "/WMS/demo/components/demo-lib-product-card",
    component: _2e5a6809,
    name: "WMS-demo-components-demo-lib-product-card"
  }, {
    path: "/WMS/demo/components/demo-lib-product-list",
    component: _7f0d2697,
    name: "WMS-demo-components-demo-lib-product-list"
  }, {
    path: "/WMS/demo/components/demo-lib-radio",
    component: _f89791a0,
    name: "WMS-demo-components-demo-lib-radio"
  }, {
    path: "/WMS/demo/components/demo-lib-step-group",
    component: _548ae04e,
    name: "WMS-demo-components-demo-lib-step-group"
  }, {
    path: "/WMS/demo/components/demo-lib-uploader",
    component: _5b801a6e,
    name: "WMS-demo-components-demo-lib-uploader"
  }, {
    path: "/WMS/demo/components/demo-login-register",
    component: _75dd735a,
    name: "WMS-demo-components-demo-login-register"
  }, {
    path: "/WMS/demo/page-components/demo-lib-discount-card",
    component: _793ea667,
    name: "WMS-demo-page-components-demo-lib-discount-card"
  }, {
    path: "/WMS/demo/page-components/demo-lib-goods-card",
    component: _1eef2528,
    name: "WMS-demo-page-components-demo-lib-goods-card"
  }, {
    path: "/WMS/demo/page-components/demo-steps",
    component: _10de69ba,
    name: "WMS-demo-page-components-demo-steps"
  }, {
    path: "/WMS/joint-membership/track/track.mixins",
    component: _4e7172ca,
    name: "WMS-joint-membership-track-track.mixins"
  }, {
    path: "/WMS/loop/component/select",
    component: _828dfe62,
    name: "WMS-loop-component-select"
  }, {
    path: "/WMS/loop/product/product",
    component: _9e225244,
    name: "WMS-loop-product-product"
  }, {
    path: "/WMS/lucky-draw/my-prize/statusBar",
    component: _e4fb43c2,
    name: "WMS-lucky-draw-my-prize-statusBar"
  }, {
    path: "/WMS/pdp/track/track.mixins",
    component: _d821f2ee,
    name: "WMS-pdp-track-track.mixins"
  }, {
    path: "/WMS/plp/no-result/SearchNoResult",
    component: _15878101,
    name: "WMS-plp-no-result-SearchNoResult"
  }, {
    path: "/WMS/",
    component: _900d5270,
    name: "WMS-undefined"
  }, {
    path: "/WMS/demo/pdp/:id?",
    component: _1bc24cf5,
    name: "WMS-demo-pdp-id"
  }, {
    path: "/WMS/identity/clp/:type?",
    component: _3ad92c1a,
    name: "WMS-identity-clp-type"
  }, {
    path: "/WMS/identity/discount/:type?",
    component: _c12d4e9c,
    name: "WMS-identity-discount-type"
  }, {
    path: "/WMS/partnership/discount/:type?",
    component: _a5d863a0,
    name: "WMS-partnership-discount-type"
  }, {
    path: "/WMS/identity/:type?",
    component: _b8c208fc,
    name: "WMS-identity-type"
  }, {
    path: "/WMS/partnership/:type?",
    component: _3058c044,
    name: "WMS-partnership-type"
  }, {
    path: "/WMS/plp/:category",
    component: _4d46087b,
    name: "WMS-plp-category"
  }, {
    path: "/AC/app-download",
    component: _60191131,
    name: "AC-app-download"
  }, {
    path: "/AC/braFinder",
    component: _57953ed0,
    name: "AC-braFinder"
  }, {
    path: "/AC/checkout",
    component: _26b6aa43,
    name: "AC-checkout"
  }, {
    path: "/AC/clp",
    component: _1d1338e0,
    name: "AC-clp"
  }, {
    path: "/AC/daily-checkin",
    component: _73050432,
    name: "AC-daily-checkin"
  }, {
    path: "/AC/demo",
    component: _f9b408c0,
    name: "AC-demo"
  }, {
    path: "/AC/error",
    component: _58d875fe,
    name: "AC-error"
  }, {
    path: "/AC/error-page",
    component: _50a39b9e,
    name: "AC-error-page"
  }, {
    path: "/AC/goods",
    component: _566ac0cf,
    name: "AC-goods"
  }, {
    path: "/AC/healthcheck",
    component: _3e2533a5,
    name: "AC-healthcheck"
  }, {
    path: "/AC/joint-membership",
    component: _24712f94,
    name: "AC-joint-membership"
  }, {
    path: "/AC/live800",
    component: _cbc60376,
    name: "AC-live800"
  }, {
    path: "/AC/location-selector",
    component: _5940aba0,
    name: "AC-location-selector"
  }, {
    path: "/AC/loop",
    component: _f10af1be,
    name: "AC-loop"
  }, {
    path: "/AC/lucky-draw",
    component: _f7c78c74,
    name: "AC-lucky-draw"
  }, {
    path: "/AC/my-account/nonmember-right2copy",
    component: _0c9707a4,
    name: "AC-my-nonmember-right2copy"
  }, {
    path: "/AC/operation",
    component: _66163ea0,
    name: "AC-operation"
  }, {
    path: "/AC/pdp",
    component: _07a53b16,
    name: "AC-pdp"
  }, {
    path: "/AC/my-account/right2copy",
    component: _9120df70,
    name: "AC-my-right2copy"
  }, {
    path: "/AC/siteMap",
    component: _5df81bce,
    name: "AC-siteMap"
  }, {
    path: "/AC/sizeGuide",
    component: _d19bb898,
    name: "AC-sizeGuide"
  }, {
    path: "/AC/splp",
    component: _e80dbe44,
    name: "AC-splp"
  }, {
    path: "/AC/adiclub/register",
    component: _c767c81a,
    name: "AC-adiclub-register"
  }, {
    path: "/AC/app-download/track",
    component: _79c61a10,
    name: "AC-app-download-track"
  }, {
    path: "/AC/braFinder/searchProduct",
    component: _12396fa5,
    name: "AC-braFinder-searchProduct"
  }, {
    path: "/AC/braFinder/select",
    component: _0e7679ee,
    name: "AC-braFinder-select"
  }, {
    path: "/AC/campaign/running_loop",
    component: _36615cfc,
    name: "AC-campaign-running_loop"
  }, {
    path: "/AC/catalog/catalog-mobile",
    component: _2c2018ee,
    name: "AC-catalog-catalog-mobile"
  }, {
    path: "/AC/catalog/catalog-skeleton",
    component: _f75ac318,
    name: "AC-catalog-catalog-skeleton"
  }, {
    path: "/AC/checkout/paid-off",
    component: _f255de60,
    name: "AC-checkout-paid-off"
  }, {
    path: "/AC/checkout/pay-page",
    component: _53051214,
    name: "AC-checkout-pay-page"
  }, {
    path: "/AC/checkout/pay-success",
    component: _186e5fd2,
    name: "AC-checkout-pay-success"
  }, {
    path: "/AC/checkout/track",
    component: _6a6395b4,
    name: "AC-checkout-track"
  }, {
    path: "/AC/clp/login",
    component: _328a4812,
    name: "AC-clp-login"
  }, {
    path: "/AC/daily-checkin/address",
    component: _24da8852,
    name: "AC-daily-checkin-address"
  }, {
    path: "/AC/daily-checkin/my-prize",
    component: _97a5fc78,
    name: "AC-daily-checkin-my-prize"
  }, {
    path: "/AC/demo/clp",
    component: _fac21216,
    name: "AC-demo-clp"
  }, {
    path: "/AC/demo/color",
    component: _295a51b1,
    name: "AC-demo-color"
  }, {
    path: "/AC/demo/demo-scale",
    component: _1312bffc,
    name: "AC-demo-demo-scale"
  }, {
    path: "/AC/demo/font",
    component: _b033b11e,
    name: "AC-demo-font"
  }, {
    path: "/AC/demo/margin-padding",
    component: _55b72414,
    name: "AC-demo-margin-padding"
  }, {
    path: "/AC/demo/plp",
    component: _324ff942,
    name: "AC-demo-plp"
  }, {
    path: "/AC/demo/square-list",
    component: _2dc7979c,
    name: "AC-demo-square-list"
  }, {
    path: "/AC/demo/testlogin",
    component: _f3aa5936,
    name: "AC-demo-testlogin"
  }, {
    path: "/AC/demo/text",
    component: _5648c14f,
    name: "AC-demo-text"
  }, {
    path: "/AC/download/confirmed",
    component: _31b07cdc,
    name: "AC-download-confirmed"
  }, {
    path: "/AC/error-page/error-normal-net",
    component: _5b9cffd0,
    name: "AC-error-page-error-normal-net"
  }, {
    path: "/AC/error-page/error-normal-pc-double",
    component: _d65208c4,
    name: "AC-error-page-error-normal-pc-double"
  }, {
    path: "/AC/error-page/error-normal-tablet-double",
    component: _a2b018ea,
    name: "AC-error-page-error-normal-tablet-double"
  }, {
    path: "/AC/error-page/error-normal-visitors",
    component: _2f295370,
    name: "AC-error-page-error-normal-visitors"
  }, {
    path: "/AC/error-page/error-pop-net",
    component: _04a28e04,
    name: "AC-error-page-error-pop-net"
  }, {
    path: "/AC/error-page/error-pop-visitors",
    component: _2ff7ac4c,
    name: "AC-error-page-error-pop-visitors"
  }, {
    path: "/AC/joint-membership/track",
    component: _10553713,
    name: "AC-joint-membership-track"
  }, {
    path: "/AC/loop/LoopAddress",
    component: _908482c2,
    name: "AC-loop-LoopAddress"
  }, {
    path: "/AC/loop/LoopAddressSelector",
    component: _16b7081e,
    name: "AC-loop-LoopAddressSelector"
  }, {
    path: "/AC/loop/thanks",
    component: _7f08660d,
    name: "AC-loop-thanks"
  }, {
    path: "/AC/lucky-draw/address",
    component: _4b95c52a,
    name: "AC-lucky-draw-address"
  }, {
    path: "/AC/lucky-draw/auto",
    component: _21b2adeb,
    name: "AC-lucky-draw-auto"
  }, {
    path: "/AC/lucky-draw/my-prize",
    component: _5bd6d2b0,
    name: "AC-lucky-draw-my-prize"
  }, {
    path: "/AC/lucky-draw/rule",
    component: _82bfd58a,
    name: "AC-lucky-draw-rule"
  }, {
    path: "/AC/my-account/nonmember-right2copy/example",
    component: _4cce1074,
    name: "AC-my-nonmember-right2copy-example"
  }, {
    path: "/AC/operation/bosdownload",
    component: _656ef91f,
    name: "AC-operation-bosdownload"
  }, {
    path: "/AC/operation/dljumper",
    component: _6dffc870,
    name: "AC-operation-dljumper"
  }, {
    path: "/AC/pdp/track",
    component: _401ccc34,
    name: "AC-pdp-track"
  }, {
    path: "/AC/plp/track",
    component: _75288788,
    name: "AC-plp-track"
  }, {
    path: "/AC/my-account/right2copy/example",
    component: _14df6d60,
    name: "AC-my-right2copy-example"
  }, {
    path: "/AC/search/track",
    component: _757b6c04,
    name: "AC-search-track"
  }, {
    path: "/AC/siteMap/ssr.async.data",
    component: _276461ba,
    name: "AC-siteMap-ssr.async.data"
  }, {
    path: "/AC/sizeGuide/utils",
    component: _3a40ce52,
    name: "AC-sizeGuide-utils"
  }, {
    path: "/AC/campaign/running_loop/utils",
    component: _37214c0a,
    name: "AC-campaign-running_loop-utils"
  }, {
    path: "/AC/checkout/track/track.mixins",
    component: _7d350957,
    name: "AC-checkout-track-track.mixins"
  }, {
    path: "/AC/demo/components/demo-lib-anchor",
    component: _af7c1f60,
    name: "AC-demo-components-demo-lib-anchor"
  }, {
    path: "/AC/demo/components/demo-lib-bottom-bar",
    component: _d0e118e8,
    name: "AC-demo-components-demo-lib-bottom-bar"
  }, {
    path: "/AC/demo/components/demo-lib-breadcrumb",
    component: _cd7ae5c4,
    name: "AC-demo-components-demo-lib-breadcrumb"
  }, {
    path: "/AC/demo/components/demo-lib-button",
    component: _0af0ce4d,
    name: "AC-demo-components-demo-lib-button"
  }, {
    path: "/AC/demo/components/demo-lib-cell",
    component: _21f01d3d,
    name: "AC-demo-components-demo-lib-cell"
  }, {
    path: "/AC/demo/components/demo-lib-dialog",
    component: _b9f8ad3a,
    name: "AC-demo-components-demo-lib-dialog"
  }, {
    path: "/AC/demo/components/demo-lib-format",
    component: _08deb492,
    name: "AC-demo-components-demo-lib-format"
  }, {
    path: "/AC/demo/components/demo-lib-input",
    component: _2346fb7f,
    name: "AC-demo-components-demo-lib-input"
  }, {
    path: "/AC/demo/components/demo-lib-loading",
    component: _9e552e9e,
    name: "AC-demo-components-demo-lib-loading"
  }, {
    path: "/AC/demo/components/demo-lib-number",
    component: _439cc238,
    name: "AC-demo-components-demo-lib-number"
  }, {
    path: "/AC/demo/components/demo-lib-popover",
    component: _67efeccc,
    name: "AC-demo-components-demo-lib-popover"
  }, {
    path: "/AC/demo/components/demo-lib-popper",
    component: _6964ab47,
    name: "AC-demo-components-demo-lib-popper"
  }, {
    path: "/AC/demo/components/demo-lib-product-card",
    component: _2e5a6809,
    name: "AC-demo-components-demo-lib-product-card"
  }, {
    path: "/AC/demo/components/demo-lib-product-list",
    component: _7f0d2697,
    name: "AC-demo-components-demo-lib-product-list"
  }, {
    path: "/AC/demo/components/demo-lib-radio",
    component: _f89791a0,
    name: "AC-demo-components-demo-lib-radio"
  }, {
    path: "/AC/demo/components/demo-lib-step-group",
    component: _548ae04e,
    name: "AC-demo-components-demo-lib-step-group"
  }, {
    path: "/AC/demo/components/demo-lib-uploader",
    component: _5b801a6e,
    name: "AC-demo-components-demo-lib-uploader"
  }, {
    path: "/AC/demo/components/demo-login-register",
    component: _75dd735a,
    name: "AC-demo-components-demo-login-register"
  }, {
    path: "/AC/demo/page-components/demo-lib-discount-card",
    component: _793ea667,
    name: "AC-demo-page-components-demo-lib-discount-card"
  }, {
    path: "/AC/demo/page-components/demo-lib-goods-card",
    component: _1eef2528,
    name: "AC-demo-page-components-demo-lib-goods-card"
  }, {
    path: "/AC/demo/page-components/demo-steps",
    component: _10de69ba,
    name: "AC-demo-page-components-demo-steps"
  }, {
    path: "/AC/joint-membership/track/track.mixins",
    component: _4e7172ca,
    name: "AC-joint-membership-track-track.mixins"
  }, {
    path: "/AC/loop/component/select",
    component: _828dfe62,
    name: "AC-loop-component-select"
  }, {
    path: "/AC/loop/product/product",
    component: _9e225244,
    name: "AC-loop-product-product"
  }, {
    path: "/AC/lucky-draw/my-prize/statusBar",
    component: _e4fb43c2,
    name: "AC-lucky-draw-my-prize-statusBar"
  }, {
    path: "/AC/pdp/track/track.mixins",
    component: _d821f2ee,
    name: "AC-pdp-track-track.mixins"
  }, {
    path: "/AC/plp/no-result/SearchNoResult",
    component: _15878101,
    name: "AC-plp-no-result-SearchNoResult"
  }, {
    path: "/AC/",
    component: _900d5270,
    name: "AC-undefined"
  }, {
    path: "/AC/demo/pdp/:id?",
    component: _1bc24cf5,
    name: "AC-demo-pdp-id"
  }, {
    path: "/AC/identity/clp/:type?",
    component: _3ad92c1a,
    name: "AC-identity-clp-type"
  }, {
    path: "/AC/identity/discount/:type?",
    component: _c12d4e9c,
    name: "AC-identity-discount-type"
  }, {
    path: "/AC/partnership/discount/:type?",
    component: _a5d863a0,
    name: "AC-partnership-discount-type"
  }, {
    path: "/AC/identity/:type?",
    component: _b8c208fc,
    name: "AC-identity-type"
  }, {
    path: "/AC/partnership/:type?",
    component: _3058c044,
    name: "AC-partnership-type"
  }, {
    path: "/AC/plp/:category",
    component: _4d46087b,
    name: "AC-plp-category"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
