/*
 * @Description: 神策集成
 * @Autor: Duan,JingLiang
 * @Date: 2021-09-13 18:01:34
 */
import { sensorsLogin, sensorsLogout,getDistinctIds } from '~/utilities/vendors/sensors';
import { isLogin, TokenUtils, isBos, isWechatMini } from '~/utilities/index';

const sensors = require('sa-sdk-javascript');

function isWxMiniLogin() {
  const anaToken = TokenUtils.getQueryValue('anaToken');
  return !!anaToken;
}

function isBOSOperation() {
  return location.pathname.includes('/operation') && TokenUtils.getQueryValue('appType') === 'bos';
}

function isCFMOperation() {
  return location.pathname.includes('/operation') && TokenUtils.getQueryValue('appType') === 'confirmed';
}

export default ({ app, $config }, inject) => {
  // Set the function directly on the context.app object
  app.sa = sensors;
  inject('sa', sensors);
  inject('trackLogin', sensorsLogin);
  inject('trackLogout', sensorsLogout);
  let serverUrlByEnv = $config.SENSORS_URL;
  let serverWebUrlByEnv= $config.SENSORS_WEB_URL;
  const wxOpenId = TokenUtils.getWxOpenid();
  // 兼容operation 落地页的sensor唤起功能，使用app的serverUrl
  if(isBos() || isBOSOperation()) {
    serverUrlByEnv = $config.SENSORS_URL_APP;
    serverWebUrlByEnv= $config.SENSORS_WEB_URL_APP;
  } else if (isCFMOperation()) {
    serverUrlByEnv = $config.SENSORS_URL_CFM;
    serverWebUrlByEnv= $config.SENSORS_WEB_URL_CFM;
  } else if (isWechatMini()) {
    // 非OR则都归入WMS
    if(location.pathname.includes('/AC/')){
      serverUrlByEnv = $config.SENSORS_URL_AC;
      serverWebUrlByEnv=$config.SENSORS_WEB_URL_AC;
    }else if(location.pathname.includes('/OR')) {
      serverUrlByEnv = $config.SENSORS_URL_OR;
      serverWebUrlByEnv=$config.SENSORS_WEB_URL_OR;
    } else if(wxOpenId) {
      serverUrlByEnv = $config.SENSORS_URL_WMS;
      serverWebUrlByEnv=$config.SENSORS_WEB_URL_WMS;
    }
  }

  !(function () {
    // console.log("process.env.RUNTIME_ENV",process.env.RUNTIME_ENV);
    // console.log("serverUrlByEnv",serverUrlByEnv,serverWebUrlByEnv);
    sensors.init({
      // 神策系统配置
      server_url: serverUrlByEnv,
      // 打开日志
      show_log: process.env.RUNTIME_ENV !== 'prod',
      is_track_single_page: true,
      use_client_time: true,
      app_js_bridge: isBos(),
      send_type: 'beacon',
      web_url:serverWebUrlByEnv,
      heatmap: {
        clickmap: 'default',
        scroll_notice_map: 'default'
      }
    });
    sensors.registerPage({
      run_environment:(process.env.RUNTIME_ENV === 'prod')?'prod':'test'
    });
    if (!isBos()) {
      if (isWechatMini()) {
        if (isWxMiniLogin()) {
          sensorsLogin();
        } else if (wxOpenId) {
          sensors.identify(wxOpenId, true);
        }
      } else {
        isLogin() ? sensorsLogin() : sensorsLogout();
      }
    }

    sensors.quick('autoTrack');
    getDistinctIds().then((id)=>{
      if(id){
        if( window.TINGYUN){
          window.TINGYUN?.setUid(id );
        }else{
          try{
            localStorage.setItem('TY_USER_ID',id);
          }catch{}
        }
      }
    });    
  })();
};
