/**
 * homePage、my-account页面 缓存scrollTop位置并在返回时应用
 * https://tools.adidas-group.com/jira/browse/CRFC-1215
 * https://tools.adidas-group.com/jira/browse/CRFC-1341
 * @author  满悦
 * @date    2021/12/6 6:06
 */

import VueRouter, { Route } from 'vue-router';
// import Vue from 'vue';
import { DeviceService } from '@adidas-com-front/ui-library/src/utilities/device';
interface iMetaScrollCache {
    globalScrollCache?: {
        keepAlive: boolean; //标记是否缓冲了ScrollCtop
        scrollTop: number;//scrollTop的缓存值
        components: string[]
    }
}
enum triggerType {
    // next = 'next', //beforeEach afterEach的next方法调用
    mounted = "mounted",// vue的mounted 触发
    activated = "activated",//vue的activated触发（keep-alive）
}
export class GlobalScrollCache {
    constructor() {
        // Vue.directive('scroll-el',{ //使用指令注册操控scroll的元素
        //     inserted:(el:HTMLElement)=>{
        //         this.scrollEl=el
        //     }
        // })
    }
    public triggerType = triggerType //触发枚举，给外部选择应用ScrollCache调用 来自哪里
    protected isPopstate = false //是否是浏览器的前进后退行为
    protected scrollTopCache: { //用于缓存scrollTop
        [name: string]: {
            scrollTop: number,
            components: string[]
        }
    } = {};

    protected _scrollEl_: HTMLElement | undefined //用来操控scroll的元素
    protected set scrollEl(el: HTMLElement) {//用来操控scroll的元素
        this._scrollEl_ = el
    }
    protected get scrollEl(): HTMLElement {//用来操控scroll的元素
        if (!this._scrollEl_) {
            this._scrollEl_ = DeviceService.getScrollTop().el
        }
        return this._scrollEl_
    }


    protected _toRoute_: Route | undefined; //缓存的toRoute
    protected set toRoute(route: Route | undefined) {
        this._toRoute_ = route
    }
    protected get toRoute(): Route | undefined { //缓存的toRoute 加防御 防止串联
        if (this._toRoute_ && document.location.pathname == this._toRoute_.path) {
            return this._toRoute_
        } else {
            return undefined
        }
    }
    protected scrollCacheNamePatterns: {
        reg: RegExp,
        components: string[]
    }[] = [
            {
                reg: /^\/$/,
                components: ["HomeIndex",'Home']
            }, {
                reg: /^\/my-account$/,
                components: ["Member"]
            }
        ]
    //要缓存scroll的path

    /**
    * 初始化 ScrollCache 功能
    * @param vueRoute
    */
    init(vueRoute: VueRouter, a: Vue) {
        /**
         *  在浏览器前进、后退 时，先执行popstate事件，在执行beforeEach···
         *  非浏览器前进、后退 时，只会执行beforeEach
         *  isPopstate标记这次beforeEach是否为在浏览器前进、后退 引发的
         *  详情搜索 onpopstate
         */
        window.addEventListener('popstate', () => {
            this.isPopstate = true
        });
        vueRoute.beforeEach((to, from, next) => {
            this.beforeEach(to, from, this.isPopstate)
            next()
        });
        vueRoute.afterEach((to, from) => {
            this.toRoute = to //缓存下toRoute
            this.scrollEl = DeviceService.getScrollTop().el;//默认操控ScrollTop的元素
            // setTimeout(() => {
            //     this.useScrollCache && this.useScrollCache(this.triggerType.next)
            // }, 0);
        })
    };


    /**
     * 尝试使用ScrollCache 控制页面Scrolltop位置
     * @param triggerType 是什么触发这个方法的
     * @returns  true 表示控制了Scrolltop位置，false 表示没有控制
     */
    useScrollCache: undefined | ((triggerType: triggerType, name: string) => boolean) = undefined;


    /**
    * 尝试使用ScrollCache 控制页面Scrolltop位置
    * * @param triggerType 是什么触发这个方法的
    * * @param name  是什么控件触发这个方法的 只有符合出控件才可以控制
    * @returns  true 表示控制了Scrolltop位置，false 表示没有控制
    */
    protected _useScrollCache_(type: triggerType, name: string): boolean { //性能优化使用方法调用
        if (!this.toRoute || !this.toRoute.meta) {
            return false
        }
        let meta: iMetaScrollCache = this.toRoute.meta as iMetaScrollCache
        if (!meta.globalScrollCache) {
            return false
        }
        if (!meta.globalScrollCache.keepAlive || typeof (meta.globalScrollCache.scrollTop) != "number") {
            return false
        }
        if (type != triggerType.mounted && type != triggerType.activated) {
            return false
        }
        let components = meta.globalScrollCache.components;
        // if (name == "Member") {
        //     debugger
        // }
        if (!name || components.indexOf(name) == -1) {

            return false
        }
        let scrollTopValue = meta.globalScrollCache.scrollTop;
        setTimeout(() => {
            this.scrollEl.scrollTop = scrollTopValue
        }, 0);

        this.useScrollCache = () => {
            return true
        }
        return true
    }

    /**
     * 用于截取全局 beforeEach scrolltop位置
     * @param to 前往 Route
     * @param from 来自 Route
     * @param isPopstate 是否是浏览器的前进后退行为
     */
    protected beforeEach(to: Route, from: Route, isPopstate: boolean): void {
        if (from.name && from.meta) { //查看来自哪里 并存储ScrollTop
            const fromFullPath = from.fullPath;
            for (let { reg, components } of this.scrollCacheNamePatterns) {
                if (reg.test(fromFullPath)) {

                    this.scrollTopCache[fromFullPath] = {
                        scrollTop: this.scrollEl.scrollTop,
                        components: [...components]
                    };
                    break
                }
            }
        }
        if (to.name && to.meta) {//在浏览器前进后退时 查看去哪里并决定是否要使用
            const toFullPath = to.fullPath;
            if (toFullPath in this.scrollTopCache) {
                let meta: iMetaScrollCache = to.meta as iMetaScrollCache
                meta.globalScrollCache = {
                    keepAlive: true,
                    scrollTop: (isPopstate) ? this.scrollTopCache[toFullPath].scrollTop : 0,
                    components: this.scrollTopCache[toFullPath].components
                }
                // if (0 == scrollTop) {
                //     this.scrollEl.scrollTop=0
                // }
                this.useScrollCache = this._useScrollCache_//性能优化使用方法调用
                return

            }
            this.useScrollCache = undefined
        }
    }
    /**
     * 设置 ScrollTop 防止与ScrollTopCache冲突
     * @param value
     */
    setScrollTop(value: number) {
        if (!this.useScrollCache) {
            this.scrollEl.scrollTop = value
        }
    }
}
// const useScrollCache = new UseScrollCache
export default GlobalScrollCache;
