
import deepcopy from 'deepcopy';
import questionMixin from './question.mixins';
import { colorHexToRgb } from '~/utilities/helper/colorHelper';
import MemberGuide from '~/components/clp/survey/memberPopover.vue';

export default {
  components: {MemberGuide},
  mixins: [questionMixin],
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    },
    repeatFillingFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionList: [],
      submited: false,
      selectedItemList: [],
      selectedItem: null,
      wholeResultClassName: '',
      isIos: false
    };
  },
  computed: {
    // 这里需要判断数据有效性，只有当选项长度是2且里面都有配选项文字才可以显示
    showQuestion() {
      return (
        this.optionList.length === 2 &&
        this.optionList.filter((item) => item.optionText).length === 2
      );
    },
    // 题干图片和反馈图片显示逻辑：先默认显示题干图片，当 选择后，若选项配置replyImageUrl，则优先显示次，当没有配置选项图则判断题干反馈图是否配置，配置则显示体感反馈图
    imgUrl() {
      let url = this.questionDescDetail.questionImage;
      if (this.selectedItem && url && this.selectedItem.replyImageUrl) {
        url = this.selectedItem.replyImageUrl;
      } else if (
        this.selectedItem &&
        this.questionDescDetail.replyImage &&
        url
      ) {
        url = this.questionDescDetail.replyImage;
      }
      return url;
    },
    showWholeReply() {
      return (
        this.selectedItem &&
        !this.selectedItem.replyText &&
        !this.selectedItem.replyImageUrl &&
        (this.questionDescDetail.replyImage ||
          this.questionDescDetail.replyText)
      );
    },
    showDynamicReply() {
      return (
        this.selectedItem && this.dynamicReplyFlag && this.computeCompleted
      );
    },
    showReply() {
      return this.selectedItem && !this.dynamicReplyFlag;
    },
    optionItems() {
      const optionItems = [];
      let index = 0;
      for (const element of this.optionList) {
        let className = [];
        let selected = false;
        let percentStr = '0%';
        if (index > 0) {
          className = ['flex-row-reverse'];
        } else {
          className = ['flex-row'];
        }
        if (this.showDynamicReply) {
          className.push(`dynamic-option label-1`);
          if (index > 0) {
            className.push('dynamic-option-right  justify-between');
          } else {
            className.push('dynamic-option-left justify-between');
          }
          percentStr = element.percentStr;
          // if (index) {
          //   percentStr = "20%";
          // } else {
          //   percentStr = "80%";
          // }
          // debugger;
          if (
            this.selectedItem &&
            element.optionCode === this.selectedItem.optionCode
          ) {
            selected = true;
          }
        } else {
          className.push('label-3 justify-between');
          if (this.showReply && this.selectedItem) {
            if (element.optionCode === this.selectedItem.optionCode) {
              selected = true;
              if (index > 0) {
                className.push('selected selected-right flex-row-reverse');
              } else {
                className.push('selected selected-left flex-row');
              }
            } else {
              className.push('unSelected');
            }
          }
        }

        index += 1;
        // percentage * 100 + '%';
        let percentageStyle = { width: percentStr , flex:`0 0 ${percentStr}`};
        if (percentStr === '0%') {
          percentageStyle = {
            display: 'none'
          };
        } else if (percentStr === '100%') {
          percentageStyle = {
            width: `calc( 100% + 14px )`,
            flex:`0 0 calc( 100% + 14px )`,
            'margin-left': '-7px',
            'margin-right': '-7px'
          };
        }

        optionItems.push({
          ...element,
          selected,
          className: className.join(' '),
          percentStr,
          percentageStyle
        });
      }
      return optionItems;
    }
  },
  watch: {
    showWholeReply(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.wholeResultClassName = 'transaction';
        }, 100);
      } else {
        this.wholeResultClassName = '';
      }
    },
    surveyDetail(newVal) {
      if (newVal) {
        this.getLastResult();
      }
    }
  },
  mounted() {
    const u = navigator.userAgent.toLowerCase();
    this.isIos = !!u.match(/macintosh|mac os x/i);
    this.isVivo = !!u.match(/vivobrowser/i);
    this.getOptionList();
    this.getLastResult();
  },
  methods: {
    getOptionList() {
      const optionList = deepcopy(
        this.question.questionDescriptionDetail.optionList
      );
      optionList.sort((a, b) => {
        return a.optionPosition - b.optionPosition;
      });
      this.optionList = deepcopy(optionList);
    },
    computedStyle(item) {

      let backgroundColor = '';
      if (item.optionTextColor) {
        backgroundColor = colorHexToRgb(item.optionTextColor,1);
      } else if (item.optionPosition === 1) {
        backgroundColor = '#0080FE';
      } else {
        backgroundColor = '#C53622';
      }
      console.log("backgroundColor",backgroundColor);
      return {
        backgroundColor: backgroundColor,
        '--option-background-color': backgroundColor
      };
    },
    computedTextStyle(item) {
      let color = '';
      if (this.showDynamicReply) {
        if (item.optionTextColor) {
          color = colorHexToRgb(item.optionTextColor,1);
        } else if (item.optionPosition === 1) {
          color = '#0080FE';
        } else {
          color = '#C53622';
        }
      }
      return {
        color: color
      };
    },
    handlePkData(item) {
      if (this.submited) {
        return;
      }
      this.selectedItem = item;
      this.submited = true;
      this.handleSingleTrack(item);
      this.submitResult();
    },
    handleClick(item) {
      if(this.surveyDetail.replyMemberFlag) {
        this.utils.isMemberLogin() ? this.handleSubmitData(item) : this.handleMemberLogin();
      } else {
        this.handleSubmitData(item);
      }
    },
    handleSubmitData(item) {
      if (!this.submitAnswerFlag) {
        // 非整卷提交
        this.handlePkData(item);
      } else {
        // 整卷提交
        if (this.wholeSurveySubmited)
          return;
        this.beforeWholeSurveySubmit(item);
        console.log('整卷提交', this.selectedItemList, this.selectedItem);
        const noneReplyItem = this.selectedItemList.find((item) => {return !item.replyText && !item.replyImageUrl && (!item.replyArticleList || !item.replyArticleList?.length) && !item.replyCategoryCode;});
          let flag;
          if (this.isMultiple || (!this.isMultiple && this.questionDescDetail.submitButtonFlag)) {
            flag = !!(noneReplyItem && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
          } else {
            flag = !!(this.selectedItem && !this.selectedItem.replyText && !this.selectedItem.replyImageUrl && (!this.selectedItem.replyArticleList || !this.selectedItem.replyArticleList?.length) && !this.selectedItem.replyCategoryCode && (this.questionDescDetail.replyImage || this.questionDescDetail.replyText));
          }
        this.$emit('wholeSubmit', {
          selectedItemList: this.selectedItemList,
          selectedItem: this.selectedItem,
          optionCode: item.optionCode,
          questionCode: this.question.questionCode,
          surveyCode: this.surveyCode,
          questionType: this.question.questionType,
          showWholeReply: flag
        });
      }
    }
  }
};
