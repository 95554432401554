
import { LibButton } from '@adidas-com-front/ui-library';
import questionMixin from './question.mixins';
import ComTextarea from './components/textarea/ComTextarea.vue';
import MemberGuide from '~/components/clp/survey/memberPopover.vue';
import {BasicUtils} from '~/utilities/basic/utils';

export default {
  components: { ComTextarea, LibButton, MemberGuide },
  mixins: [questionMixin],
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    },
    repeatFillingFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionList: [],
      selectedItemList: [],
      selectedItem: null,
      showRepeatSelect: false,
      textContent: "",
      utils: null,
      isMemberLogin: false
    };
  },
  computed: {
    submitAnswerFlag() {
      return this.surveyDetail.submitAnswerFlag;
    },
    surveyMemberOnly() {
      return !!this.surveyDetail.replyMemberFlag;
    },
    wholeReplyTextStyle() {
      return {
        color: this.questionDescDetail.replyTextColor,
        ...this.wholeReplyStyle
      };
    },
    isShowSubmitBtn(){
      return !this.submitAnswerFlag && this.questionDescDetail.submitButtonFlag && !this.submited;
    },
    submitButtonText(){
      return this.questionDescDetail.submitButtonText || "提交";
    },
    isBlock() {
      return window.innerWidth < 720;
    },
    defaultText(){
      return this.question.noticeText || "请输入您的回复";
    },
    // 题干图片
    imgUrl() {
      return this.questionDescDetail.questionImage;
    },
    showWholeReply() {
      return (!this.submitAnswerFlag && this.submited) || (this.submitAnswerFlag && this.wholeSurveySubmited);
    },
    showReply() {
      return this.selectedItem && this.repeatFillingFlag;
    },
    buttonWidth(){
      let width = null;
      if (window.innerWidth > 719) {
        width = 250;
      }
      return width;
    },
    buttonBgStyle() {
      const bgColor = this.questionDescDetail.submitButtonColor;
      if (bgColor && this.textContent) {
        return {
          backgroundColor: bgColor
        };
      } else {
        return {};
      }
    }
  },
  watch: {
    surveyDetail(newValue, oldValue) {
      if(newValue) {
        this.$nextTick(async () => {
          this.wholeReplyImgHeight = await this.loadReplyImg();
          this.computeHeight();
          if (this.showWholeReply) {
            this.animateWholeReply();
          }
          this.getLastResult();
          this.isMemberLogin = this.utils.isMemberLogin();
        });
      }
    }
  },
  mounted() {
    this.utils = new BasicUtils(this);
    this.isMemberLogin = this.utils.isMemberLogin();
    const u = navigator.userAgent.toLowerCase();
    this.isIos = !!u.match(/macintosh|mac os x/i);
    this.isVivo = !!u.match(/vivobrowser/i);
    this.textContent = this.selectedItem?.text;
    this.$nextTick(async () => {
      this.wholeReplyImgHeight = await this.loadReplyImg();
     this.computeHeight();
      if (this.showWholeReply) {
        this.animateWholeReply();
      }
      this.getLastResult();
    });
  },
  methods: {
    computeHeight(){
      this.optionsHeight = this.$refs.options?.clientHeight || 0;
      this.wholeReplyStyle = {
        height: this.optionsHeight? (this.optionsHeight + (this.questionDescDetail.replyImage? 'px':'px !important')):"100%",
        minHeight: this.optionsHeight? (this.optionsHeight + (this.questionDescDetail.replyImage? 'px':'px !important')):"100%"
      };
    },
    // 这里当超过规定字数后，则截取前1000个字符
    validateTextNum(){
      // 整卷提交
      if (this.submitAnswerFlag) {
        this.$emit('updateTextAnswer', {showWholeReply: true, answerValue: this.textContent, questionType: this.question.questionType, questionCode: this.question.questionCode, selectedItemList: [], selectedItem: {}});
      }
      if (this.textContent.length >= 1000) {
        this.textContent = this.textContent.substr(0,1000);
      }
    },
    resetTextSurvey(){
      this.textContent = "";
      this.resetSurvey();
    },
    handleClick() {
      if (this.submited) {
        return;
      }
      const item = {text:this.textContent};
      this.selectedItem = item;
      this.submited = true;
      this.handleSingleTrack(item);
      this.computeHeight();
      this.submitResult();
    },
    handleFocusOrNativeClick() {
      if(this.surveyDetail.replyMemberFlag) {
        this.utils.isMemberLogin() ? '' : this.handleMemberLogin();
      }
    }
  }
};
