/*
 * @Author: hong.sheng
 * @Date: 2021-11-05 13:47:50
 * @Description: 员工登录插件入口文件
 */
import Login from './Login.vue';

export default {
  install(Vue) {
    const LoginConstructor = Vue.extend(Login);
    const login = new LoginConstructor();
    document.body.appendChild(login.$mount().$el);
    Vue.prototype.$EmployeeLogin = login;
  }
};
