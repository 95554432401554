import Vue from 'vue';
import { delay } from '../LibTooltip/LibTooltip.trigger';
import LibDialog from './LibDialog.vue';

interface DialogServiceOption {
  on: Record<string, (...args: any[]) => any[]>,
  render: (data: { close: () => void }) => any,
}

const getIns = (() => {
  const cacheInsList: any[] = [];

  const newIns = (context: any) => {
    const dialogEl = document.createElement('div');
    document.body.appendChild(dialogEl);
    const ins = new Vue({
      parent: context,
      data () {
        return {
          // eslint-disable-next-line
          options: {} as DialogServiceOption,
          showFlag: false
        };
      },
      methods: {
        async service (options: DialogServiceOption) {
          this.options = options;
          await delay();
          this.showFlag = true;
        },
        hide () {
          this.showFlag = false;
        }
      },
      render () {
        const {
          render,
          on,
          ...props
        } = this.options;
        return (
          // @ts-ignore
          <LibDialog v-model={this.showFlag} {...{
            props,
            on
          }}>
            {!!render && render({ close: () => (ins as any).hide() })}
          </LibDialog>
        );
      }
    });
    ins.$mount(dialogEl);
    cacheInsList.push(ins);
    cacheInsList.push(ins);
    return ins;
  };

  return (context: any) => {
    let ins = cacheInsList.find(i => !i.showFlag);
    if (!ins) {
      ins = newIns(context);
    }
    return ins;
  };
})();

function createDialogService (context: any) {
  return (options: DialogServiceOption) => getIns(context).service(options);
}

export function installDialogService (Vue: any) {
  console.log('plugins mixin installDialogService');
  Vue.mixin({
    computed: {
      $dialog () {
        return createDialogService(this);
      }
    }
  });
}
