
import { throttle } from 'throttle-debounce';
import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import TabAnthorItem from '~/components/clp/tab-anchor/TabAnchorItem.vue';

export default {
  name: 'TabAnchor',
  components: {
    TabAnthorItem
  },
  provide() {
    return {
      tabAnchorGroup: {
        addItem: (refFunc) => {
          this.anchorRefList.push(refFunc);
        },
        removeItem: (refFunc) => {
          this.anchorRefList.splice(this.anchorRefList.indexOf(refFunc), 1);
        },
        activeAnchorRef: () => this.activeAnchor,
        // 点击锚点触发平滑滚动到被点击的锚点
        onClick: (anchor) => {
          const activeItem = this.elRefList
            .map((i) => i())
            .find((i) => i.anchor === anchor);
          // 获取元素样式，为了取到margin-top的值，否则offsetTop是不包含margin的值，导致锚点跳转位置不正确
          function getStyle(ele) {
            let style = null;
            if (window.getComputedStyle) {
              style = window.getComputedStyle(ele, null);
            } else {
              style = ele.currentStyle;
            }
            return style;
          }
          let marginTop = getStyle(activeItem.el).marginTop;
          if (marginTop !== '0px') {
            marginTop = marginTop.slice(0, -2);
          } else {
            marginTop = 0;
          }
          // 以下为实现平滑滚动效果
          // 目标锚点y值 取实际滚动target + 1 的y值，防止小数点出现 this.height会吞小数点导致计算不对
          const _target =
            activeItem.el.offsetTop -
            (Number(marginTop) + Number(this.top) + Number(this.height)) + 1;
          // 当前滚动条位置
          const _current = this.$utils.device.getScrollTop().scrollTop;
          // 目标锚点距离当前滚动条距离
          const _distance = _target - _current;
          // 重绘次数
          const renderTimes = Math.abs(_distance) > 500 ? 40 : 20;
          // 频率
          const frequency = _distance / renderTimes;
          let times = 1;
          const myInterval = setInterval((smoothScroll) => {
            // console.log("warn: Interval p3");
            this.$utils.device.setScrollTop(_current + frequency * times);
            if (times++ < renderTimes) {
              smoothScroll;
            } else {
              clearInterval(myInterval);
            }
          }, 8);
          const activeTab = this.data.itemList.filter(item => {
            return Number(item.anchor) === Number(activeItem.anchor.slice(1));
          })[0];

          // 上报事件
          this.$emit('trackEvent', {activeAnchor: activeTab.anchor, activeTabName: activeTab.activeTabName});
        }
      }
    };
  },
  props: {
    // 锚点区域高度
    height: { type: [Number, String], default: 56 },
    // 锚点是否显示
    show: { type: Boolean, default: true },
    // 锚点距离顶部大小，一般为顶部导航栏的高度（如果导航栏吸顶的话）
    top: { type: [Number, String] },
    // 是否为小屏锚点
    smallScreen: { type: Boolean, default: false },
    // 数据源
    data: {}
  },
  data() {
    return {
      isMobile: false,
      // 当前页面滚动高度
      pageScrollTop: 0,
      // 锚点ref对象列表
      anchorRefList: [],
      // 封装好的锚点对象列表
      elRefList: [],
      // 当前选中锚点
      activeAnchor: null,
      activeTabName: '',
      // tab对应的组件是否超出了视口
      tabOverView: true,
      // 当前锚点所在元素距离顶部的初始值
      originalOffsetTop: 0,
      // 当前布局模式最外层div的宽度（用于解决兼容windows和mac中滚动条表现不同而产生的bug）
      bodyWidth: 0,
      // 下划线标记距离左边的距离
      indOffsetLeft: 0,
      tabAnchorRef: 'tabAnchor',
      // 当前锚点是否吸顶
      anchorFixed: false,
      resetActiveAnchor: throttle(
        50,
        true,
        (initFlag) => {
          const tmpActiveAnchor = (() => {
            if (!this.elRefList || this.elRefList.length === 0) {
              return null;
            }
            let { pageScrollTop } = this;
            pageScrollTop =
              Number(pageScrollTop) + Number(this.top) + Number(this.height);
            let tmpAnchor = '';
            let tmpAnchorArray = [];
            let viewAnchorArray = [];
            let allAnchorArray = this.elRefList.map((item) => {
              return item().anchor.substr(1);
            });
            allAnchorArray = [...new Set(allAnchorArray)];
            // 取绝对值最小的，也就是离得最近的，否则会出现第一个anchor对应的内容位置在最后一个的anchor对应内容位置的下面
            // 把他们塞到一个数组里，取最小值
            for (const i of this.elRefList) {
              const { anchor, el } = i();
              if (!el) {
                continue;
              }
              const { offsetTop, offsetHeight } = el;
              const curTop = el.getBoundingClientRect().top + 100;
              const windowHeight = window.innerHeight;
              const curBottom = curTop + offsetHeight;
              // 当初始化的时候需要包含等于的场景，非初始化则不包含等于
              if (initFlag && pageScrollTop <= offsetTop + offsetHeight || !initFlag && pageScrollTop < offsetTop + offsetHeight) {
                tmpAnchorArray.push(anchor.substr(1));
              }
              if ((curTop > 0 && curTop < windowHeight) || (curBottom < windowHeight && curBottom > 0) || (curTop < 0 && curBottom > 0)) {
                viewAnchorArray.push(anchor.substr(1));
              }
            }
            viewAnchorArray = [...new Set(viewAnchorArray)];
            tmpAnchorArray = [...new Set(tmpAnchorArray)];
            if (tmpAnchorArray.length > 0) {
              const notInArrary = this.diff(allAnchorArray, tmpAnchorArray);
              if (notInArrary?.length) {
                if (viewAnchorArray.length) {
                  const otherArray = viewAnchorArray.filter(v => notInArrary.includes(v));
                  console.log('otherArray', otherArray);
                  tmpAnchor = otherArray.length ? Math.max(...notInArrary): Math.min(...tmpAnchorArray);
                } else {
                  tmpAnchor = Math.max(...notInArrary);
                }
              } else {
                tmpAnchor = Math.min(...tmpAnchorArray);
              }
              const activeTab = this.data.itemList.filter(item => {
                return Number(item.anchor) === tmpAnchor;
              })[0];

              this.activeTabName = activeTab.tabText;
              this.tabOverView = false;
              console.log("tmpAnchor",tmpAnchor, tmpAnchorArray, allAnchorArray,notInArrary, viewAnchorArray);
              return 'a' + tmpAnchor;
            }

            this.tabOverView = true;
            this.activeTabName = '';

            return this.elRefList[this.elRefList.length - 1]().anchor;
          })();
          this.activeAnchor = tmpActiveAnchor;
          // 遍历取到当前应该活跃的锚点
          const active = this.elRefList
            .map((i) => i())
            .find((i) => i.anchor === tmpActiveAnchor);
          this.indOffsetLeft = active.anchorEl.children[0]?.offsetLeft;
        },
        100
      )
    };
  },
  computed: {
    ...mapState('clp', [
      'componentsData'
    ]),
    // 锚点最外层container样式计算
    styles() {
      return {
        top: '0px',
        backgroundColor: 'white',
        position: 'relative'
        // width: this.bodyWidth + 'px'
      };
    },
    // 占位div样式：无展示作用，只是占位
    stylesFixed() {
      return {
        top: '0px',
        backgroundColor: 'white',
        position: 'fixed',
        width: this.bodyWidth + 'px',
        transform: `translateY(${this.anchorFixed ? 0 : '-100%'})`
      };
    },
    // 外层wrapper样式计算
    wrapperStyle() {
      const style={
        backgroundColor:(this.data.backgroundColor)?this.data.backgroundColor:"white",
        maxWidth: "1920px",
        margin: "0 auto"
      };

      if(this.isMobile&&this.data.mobBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.data.mobBackgroundImage})`;
      }else if(!this.isMobile&&this.data.pcBackgroundImage){
        style.backgroundSize='cover';
        style.backgroundRepeat='no-repeat';
        style.backgroundPosition="top center";
        style.backgroundImage=`url(${this.data.pcBackgroundImage})`;
      }
      return style;
    },

    // 根据传入属性计算容器高度
    containerStyles() {
      return {
        height: this.height + 'px'
      };
    },

    // 锚点活跃状态时下划线的样式计算
    indicatorStyles() {
      const active = this.elRefList
        .map((i) => i())
        .find((i) => i.anchor === this.activeAnchor);
      if (!active) {
        return {
          transform: `translateX(0)`,
          width: 0
        };
      }
      let activeIndex = 0;
      this.data.itemList.forEach((item, index) => {
        if (item.anchor === this.activeAnchor || this.activeAnchor.includes(item.anchor)) {
          activeIndex = index;
        }
      });
      const { offsetWidth } = active.anchorEl.children[0];
      return {
        transform: `translateX(${this.indOffsetLeft + 'px'})`,
        width: this.show ? offsetWidth + 'px' : 0,
        // 背景色根据当前对应活跃的锚点元素的文字元素取值
        backgroundColor: this.data.itemList
          ? this.data.itemList[activeIndex].textColor
          : 'black'
      };
    }
  },
  watch: {
    // 监听页面滑动到顶部距离的值，从而判断是否吸顶展示以及判断当前活跃的锚点
    pageScrollTop() {
      const anchorList = [...this.elRefList];
      let tmpAnchor = '';
      const tmpAnchorArray = [];
      // 把他们塞到一个数组里，取最大值即最后一个锚点对应内容的元素
      for (const i of this.elRefList) {
        const { anchor, el } = i();
        if (!el) {
          continue;
        }
        tmpAnchorArray.push(anchor.substr(1));
      }
      if (tmpAnchorArray.length > 0) {
        tmpAnchor = 'a' + Math.max(...tmpAnchorArray);
      }
      const maxPostionAnchor = anchorList
        .map((i) => i())
        .find((i) => i.anchor === tmpAnchor);
      if(maxPostionAnchor?.el){
        const { offsetTop, offsetHeight } = maxPostionAnchor.el;
        // 在配置的锚点组件位置开始到最大位置数中间都吸顶显示
        this.originalOffsetTop = this.$refs.tabAnchor.offsetTop;
        this.anchorFixed =
          this.pageScrollTop > this.originalOffsetTop + Number(this.top) &&
          this.pageScrollTop < offsetHeight + offsetTop;
        this.resetActiveAnchor();
      }
    }
  },
  mounted() {
    const onScroll = () => {
      // 记录页面滚动高度，监听锚点变化
      const { scrollTop } = this.$utils.device.getScrollTop();
      this.pageScrollTop = scrollTop;
      this.computeStyle();

      this.trackTabEvent();
    };
    onScroll();
    const eject = this.$utils.device.onPageScroll(onScroll);
    this.$on('hook:beforeDestroy', eject);

    this.elRefList = this.anchorRefList.map((anchorIns) => {
      const { anchor, $el } = anchorIns();
      const el = document.querySelector(`[tab-anchor=${anchor}]`);
      return () => ({ anchor, el, anchorEl: $el, height: el?.offsetHeight });
    });
    this.resetActiveAnchor(true);
    window.addEventListener('resize', this.screenResize);
    this.isMobile = window.innerWidth < 720;
    // this.computeStyle();
  },
  methods: {
    diff(arr1, arr2) {
      return arr1.reduce((previous, i) => {
        const found = arr2.findIndex((j) => {
          return j === i || (isNaN(i) && isNaN(j));
        });
        return (found < 0 && previous.push(i), previous);
      }, []);
    },
    // 计算一些元素样式
    computeStyle() {
      this.$nextTick(() => {
        this.bodyWidth = document.querySelector('.cutStyles')?.offsetWidth;
        setTimeout(() => {
          this.originalOffsetTop = this.$refs.tabAnchor.offsetTop;
        }, 1000);
        const active = this.elRefList
          .map((i) => i())
          .find((i) => i.anchor === this.activeAnchor);
        this.indOffsetLeft = active.anchorEl.children[0]?.offsetLeft;
      });
    },

    // 屏幕尺寸变化时触发样式重计算
    screenResize() {
      this.isMobile = window.innerWidth < 720;
      this.activeAnchor = null;
      this.$nextTick(() => {
        this.bodyWidth = document.querySelector('.cutStyles').offsetWidth;
        this.originalOffsetTop = this.$refs.tabAnchor.offsetTop;
        const { scrollTop } = this.$utils.device.getScrollTop();
        this.pageScrollTop = scrollTop;
        this.resetActiveAnchor(true);
        // this.computeStyle();
      });
    },

    // 滚动及点击tab触发事件上报
    trackTabEvent: debounce(function() {
      // console.log('trackTabEvent========', this.activeAnchor, this.data.itemList);

      // 如果tab对应的组件在视口内,判断当前active的组件是否为productList
      if (!this.tabOverView) {
        this.$emit('trackEvent', {activeAnchor: this.activeAnchor, activeTabName: this.activeTabName});
      }

    }, 500)
  }
};
