
export default {
  name: 'LibCheckbox',
  props: {
    // 绑定勾选的值
    value: {
      type: Boolean,
      default: false,
    },
    // 是否可使用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 判断样式位置
    iconp: {
      type: String,
      default: 'center',
    },
    // 设置图标的大小
    iconSize: {
      type: String,
      default: 'middle',
    },
    // 图标距离的边距
    iconRight: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      ischecked: false,
    };
  },
  computed: {
    // 字体大小
    _iconfont() {
      if (this.iconSize === 'large') {
        return this.ischecked ? 'icon-checkbox_full_32' : 'icon-checkbox_32';
      } else {
        return this.ischecked ? 'icon-checkbox_full_24' : 'icon-checkbox_24';
      }
    },
  },
  watch: {
    value(val) {
      this.ischecked = val;
    },
  },
  mounted() {
    this.ischecked = this.value;
  },
  methods: {
    // 点击勾选
    changeFlag() {
      this.ischecked = !this.ischecked;
      this.$emit('input', this.ischecked);
    },
  },
};
