/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2022-05-24 15:47:38
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2022-11-28 21:49:32
 * @FilePath: /adidas-com-front/packages/nuxt/utilities/helper/catalogHelper.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export function o2mArr(array: Array<any>, divider: number): Array<any> {
  const newArr: any[] = [];
  for (let i = 0; i < Math.ceil(array.length / divider); i++) {
    newArr[i] = [];
    newArr[i].push(array[i * divider]);
    for (let j = 1; j < divider; j++) {
      if (array[i * divider + j] === undefined) {
        return newArr;
      } else {
        newArr[i].push(array[i * divider + j]);
      }
    }
  }
  return newArr;
}

export function scrollElementTo(scrollWithin: HTMLElement, direction: number, pixelsPerSecond: number, targetDistance: number): void {
  scrollWithin.scrollLeft = scrollWithin.scrollLeft || 0;
  let timerId;
  const pixelsPerTick = pixelsPerSecond / 100,
    doTick = function () {
      scrollWithin.scrollLeft += pixelsPerTick * direction;
      if (direction > 0 && scrollWithin.scrollLeft < targetDistance || direction < 0 && scrollWithin.scrollLeft > targetDistance) {
        // console.log('>>scrollWithin<<', scrollWithin.scrollLeft, targetDistance);
        timerId = setTimeout(doTick, 10);
      }
    };
  clearTimeout(timerId);
  doTick();
}
