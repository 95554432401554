/*
 * @Author: Kevin.Wang Kevin.Wang2@adidas.com
 * @Date: 2022-03-21 08:54:13
 * @LastEditors: Kevin.Wang Kevin.Wang2@adidas.com
 * @LastEditTime: 2022-06-28 14:03:12
 * @FilePath: /adidas-com-front/packages/nuxt/plugins/axios.loginhook.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// 路径映射关系，key 为登录前路径，value 为登录后自动跳转的路径
const pathMap = {
  '/checkout': '/',
  '/order/detail': '/my-account/order/list',
  '/return/detail': '/my-account/return/list',
  '/exchange/detail': '/my-account/return/list?tab=exchange'
};

/**
 * 特定页面登录后需要自动跳转到另外指定的页面
 * @created 姚润发
 * @param app vue 实例，主要取router对象进行跳转
 * @returns {boolean} 返回ture 表示要进行跳转，不执行其他操作了
 */
export function routerMap(app) {
  if (!app.$isServer && pathMap[location.pathname]) {
    app.router.push(pathMap[location.pathname]);
    // alert(pathMap[location.pathname]);
    return true;
  }
  return false;
}
