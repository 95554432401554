/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2023-12-13 16:26:45
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2024-09-03 11:42:51
 * @FilePath: /new-com/adidas-com-front/packages/nuxt/components/clp/common/productUtil.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { BasicUtils, iCtx } from '~/utilities/basic/utils';
import { getUrlQuery } from '~/utilities/helper/urlHelper';
import deepcopy from 'deepcopy';

enum ComponentCode {
  'ClpSingleImage',
  'ClpMultiImage',
  'CTA',
  'TextLink',
  'Video',
  'NBanner',
  'ProductList',
  'TabAnchor',
  'TabPage',
  'Look',
  'SearchKeyword',
  'LookGroup',
  'PplpCom',
  'Survey',
  'ImageSlideProduct',
  'VerticalTabPage'
}
// plpList Map
interface iLink2Plp {
  content: Array<iArticleData>;
  link: string
}
// search接口获取plpList的response结构
interface iPlpRes {
  content: Array<iArticleData>;
  totalPages: number;
  totalElements: number;
}
// 商品数据信息
interface iArticleData {
  articleId: string
}
// miniCatalog 数据结构
interface iVerticalData {
  articleNum: number;
  categoryCode: string;
  keyword: string;
  maintainType: string;
  searchTerm: string;
  tabRedirectLink?: string;
  imageRedirectLink?: string;
  articleList?: Array<any>;
}
// tabPage 数据结构
interface itabPageItem {
  articleList: Array<string>
  layoutType: string
  tabText: string
  textColor: string,
  link: string,
  verticalItemList: Array<iVerticalData>
  imageSlideItemList: Array<iVerticalData>
  pageSize: number
}
// 组件 options 数据结构
interface iComponentOptions {
  link: string,
  maintainType: 'article' | 'PLP',
  articleList: Array<string>
  pageSize?: number
  detail?: Array<any>
  content?: string,
  itemList?: Array<itabPageItem>
}
// 组件结构
interface iComponetData {
  code: string,
  class: string,
  viewport: string[],
  position: string,
  options: iComponentOptions
}
// 获取plpList request Data MAP
interface iPlpParamsData {
  link: string,
  pageSize: number,
  params: iPlpParams,
}
// 调用search接口的入参数据结构
interface iPlpParams {
  keyword?: string,
  categoryCode?: string,
  pageSize?: number,
  page: number,
  abTest: string
}
const needGetProductComponentCodeList = [
  'ProductList',
  'Look'
];
const needExtractComponentCodeList = [
  {
    code: 'LookGroup',
    key: 'detail'
  },
  {
    code: 'ImageSlideProduct',
    key: 'itemList'
  },
  {
    code: 'VerticalTabPage',
    key: 'itemList'
  }
];
// tabpage需要获取商品的contentType
const tabPageContentTypeList = [
  'product_list',
  'VerticallyPage',
  'ImagesAndSliding'
];
const componentParamMap = {
  'ProductList': 'link',
  'Look': 'link'
};
interface iArticleInfo{
  articleId:string,articleSpName?:string
}

export function filterProducts(isTransparent:boolean,items:{transFrontImageUrl?:string,transColorImageUrl?:string,articleInfo?:iArticleInfo,articleId:string}[],options:{articleInfo?:iArticleInfo[]}){
  let list=[]
  if(isTransparent){
    list= items.filter(item=>{
      if(item&&item.transFrontImageUrl||item.transColorImageUrl) {
        return true;
      }else{
        return false;
      }
    });
  }else{
    list= items;
  }
  for(let i=0;i<list.length;i++){
    list[i]={...list[i]};
  }
  if(options?.articleInfo?.length){
    for(const item of list){
      const articleInfo= options?.articleInfo.find((articleInfo)=>{return articleInfo.articleId===item.articleId});
      if(articleInfo){
        // if(articleInfo.articleId==='IB7796'){
        //   debugger
        // }
        item.articleInfo=articleInfo;
      }
    }
  }
  return list;
}
const extractData = (componentData: Array<iComponetData>) => {
  let list: any[] = [];
  componentData.forEach((component) => {
    const code = component.code;
    const obj = needExtractComponentCodeList.find((item) => {
      return item.code === component.code;
    });
    const key = obj?.key;
    if (key) {
      const options = component.options;
      const detail = options[key as keyof typeof options] as Array<any> || [];
      detail.forEach((item) => {
        item.code = code;
      });
      list.push(...detail);
    }
  });
  return list;
}
export class CLPUtils extends BasicUtils {
  public articleIdList: string[] = [];
  public plpParamList: Array<iPlpParamsData> = [];
  public componentData: Array<iComponetData> = [];
  public filterComponentList: Array<iComponetData> = [];
  public needExtractComponentList: Array<iComponetData> = [];
  public tabPageComponentList: Array<iComponetData> = [];
  public articleList: iArticleData[] = [];
  public link2PlpList: iLink2Plp[] = [];
  constructor(ctx: iCtx, components: Array<iComponetData>) {
    super(ctx);
    this.componentData = components;
    this.getFilterComponentList();
    this.getExtractedComponentList();
    this.getTabpageList();
    this.unique();
    console.log('filterComponentList', this.articleIdList, this.plpParamList);
  }

  /**
   * 去重articleIdList及plpParamList
   */
  unique() {
    this.articleIdList = Array.from(new Set(this.articleIdList));
    this.plpParamList.sort((a, b) => {
      return b.pageSize - a.pageSize;
    });
    this.plpParamList = this.plpParamList.reduce((acc: Array<iPlpParamsData>, curr: iPlpParamsData) => {
      const index = acc.findIndex((item) => item.link === curr.link);
      if (index < 0) {
        acc.push(curr)
      }
      return acc
    }, []);
  }

  /**
   * 获取所有的plpList
   */
  async getAllPlpList(ctx: iCtx) {
    // const lastPlpListMap = deepcopy((ctx.store || ctx.$store).state.clp.plpListMap) || [];
    // (ctx.store || ctx.$store).commit('clp/SET_PLP_LIST', []);
    const plpPromises: Array<Promise<iLink2Plp>> = [];
    this.plpParamList.forEach((item) => {
      plpPromises.push(this.searchPlp(item.params, item.link));
    });
    const plpList = await Promise.all(plpPromises);
    this.link2PlpList.push(...plpList);
    let arr: iLink2Plp[] = [];
    this.link2PlpList.forEach((item: iLink2Plp) => {
      const findIndex = arr.findIndex((li: iLink2Plp) => {
        return li.link === item.link;
      });
      if (arr[findIndex]) {
        arr[findIndex].content = arr[findIndex].content.length > item.content.length ? arr[findIndex].content : item.content;
      } else {
        arr.push(item);
      }
    });
    (ctx.store || ctx.$store).commit('clp/SET_PLP_LIST', arr);
  }

  /**
   * 获取所有的articleDetailList
   */
  async getAllArticles(ctx: iCtx) {
    try {
      let articleListCopy: iArticleData[] = [];
      const articleIdList = this.articleIdList.join(',');
      if (articleIdList) {
        const articleDetailList = await this.axios.get<any, Array<iArticleData>>(`${this.intranetHost}/o2srh/v1/pub/platform-products/batch-search`, {
          ...this.config,
          params: {
            articleIdList,
            preview: null
          }
        });
        articleDetailList.forEach((it) => {
          articleListCopy.push(it);
        });
        (ctx.store || ctx.$store).commit('clp/SET_ARTICLE_LIST', articleListCopy);
      }
    } catch (error) {
      console.log('通过articleIdList批量获取articleList失败', error);
      (ctx.store || ctx.$store).commit('clp/SET_ARTICLE_LIST', []);
    }
  }

  /**
   * 调用search接口回去plplist
   */
  async searchPlp(params: iPlpParams, link: string) {
    try {
      const plpRes = await this.axios.get<any, iPlpRes>(`${this.intranetHost}/o2srh/v1/pub/platform-products/search`, {
        ...this.config,
        params: {
          ...params,
          preview: 0
        }
      });
      const content = plpRes.content || [];
      return { content: content, link: link };
    } catch (error) {
      console.log('调用search接口获取plpList失败', error);
      return { content: [], link };
    }
  }

  /*
  *获取需要获取商品信息的组件
  */
  getFilterComponentList() {
    this.filterComponentList = this.componentData.filter((component) => {
      if (needGetProductComponentCodeList.includes(component.code)) {
        return component;
      }
    });
    this.filterComponentList.forEach((component) => {
      const code = component.code;
      const maintainType = component.options.maintainType;
      const articleList: Array<string> = component.options.articleList || [];
      const link = component.options.link;
      const pageSize = component.options.pageSize || 12;
      this.getParamsOfProductList(articleList, maintainType, component, link, pageSize);
    });
  }
  /* 获取需要提取数据的组件 */
  getExtractedComponentList() {
    this.needExtractComponentList = this.componentData.filter((component) => {
      const isContain = !!needExtractComponentCodeList.find((item: { code: string, key: string }) => {
        return item.code === component.code;
      });
      if (isContain) {
        return component;
      }
    });
    const extractList = extractData(this.needExtractComponentList);
    extractList.forEach((component) => {
      // const code = component.code;
      const maintainType = component.maintainType;
      const articleList: Array<string> = component.articleList || [];
      if (component.code === 'ImageSlideProduct' || component.code === 'VerticalTabPage') {
        const pageSize = component.articleNum || 12;
        const link = component.searchTerm === 'categoryCode' ? `/plp?categoryCode=${component.categoryCode}` : `/plp?keyword=${component.keyword}`;
        this.getParamsOfProductList(articleList, maintainType, component, link, pageSize);
      } else {
        const link = component.link;
        const pageSize = component.pageSize || 12;
        this.getParamsOfProductList(articleList, maintainType, component, link, pageSize);
      }
    });
  }
  /**
   * tabPage非子clp相关类型获取商品
   */
  getTabpageList() {
    this.tabPageComponentList = this.componentData.filter((component) => {
      const code = component.code;
      const contentType = component.options.content || '';
      if (code === 'TabPage' && tabPageContentTypeList.includes(contentType)) {
        return component;
      }
    });
    const articleList: Array<string> = [];
    const linkList: Array<string> = [];
    this.tabPageComponentList.forEach((component) => {
      const contentType = component.options.content || '';
      const itemList = component.options.itemList || [];
      switch (contentType) {
        case 'product_list':
          const maintainType = component.options.maintainType;
          if (maintainType === 'PLP') {
            itemList.forEach((item) => {
              const { keyword, categoryCode } = getUrlQuery(item.link);
              const params: iPlpParams = {
                page: 1,
                abTest: 'A',
                pageSize: item.pageSize || 12,
                categoryCode,
                keyword: decodeURI(keyword)
              };
              if (!keyword && keyword !== '0') {
                delete params.keyword;
              }
              if (categoryCode === '/search' || !categoryCode) {
                delete params.categoryCode;
              }
              this.plpParamList.push({
                params,
                link: item.link,
                pageSize: item.pageSize || 12
              })
            });
          } else {
            const list = itemList.map((item) => { return item.articleList; }).flat();
            this.articleIdList.push(...list);
          }
          break;
        case 'VerticallyPage':
          const verticalItemList = itemList.map((item) => { return item.verticalItemList; }).flat();
          const verticalTabPageArticleList = verticalItemList.filter((item) => {
            return item.maintainType === 'article';
          });
          const verticalTabPageArticleIdList = Array.from(new Set(verticalTabPageArticleList.map((item) => {
            return item.articleList;
          }).flat(1)));
          const verticalTabPagePlpList = verticalItemList.filter((item) => {
            return item.maintainType === 'PLP';
          });
          this.articleIdList.push(...verticalTabPageArticleIdList);
          verticalTabPagePlpList.forEach((item) => {
            const params: iPlpParams = {
              page: 1,
              abTest: 'A',
              pageSize: item.articleNum || 12
            };
            item.searchTerm === 'categoryCode' ? params.categoryCode = item.categoryCode : params.keyword = item.keyword;
            const dLink = params.categoryCode ? `/plp?categoryCode=${item.categoryCode}` : `/plp?keyword=${item.keyword}`;
            this.plpParamList.push({
              link: dLink,
              pageSize: item.articleNum || 12,
              params
            })
          });
          break;
        case 'ImagesAndSliding':
          const imageSlideItemList = itemList.map((item) => { return item.imageSlideItemList; }).flat();
          const imageSlideArticleList = imageSlideItemList.filter((item) => {
            return item.maintainType === 'article';
          });
          const imageSlideArticleIdList = Array.from(new Set(imageSlideArticleList.map((item) => {
            return item.articleList;
          }).flat(1)));
          const imageSlidePlpList = imageSlideItemList.filter((item) => {
            return item.maintainType === 'PLP';
          });
          this.articleIdList.push(...imageSlideArticleIdList);
          imageSlidePlpList.forEach((item) => {
            const params: iPlpParams = {
              page: 1,
              abTest: 'A',
              pageSize: item.articleNum || 12
            };
            item.searchTerm === 'categoryCode' ? params.categoryCode = item.categoryCode : params.keyword = item.keyword;
            const dLink = params.categoryCode ? `/plp?categoryCode=${item.categoryCode}` : `/plp?keyword=${item.keyword}`;
            this.plpParamList.push({
              link: dLink,
              pageSize: item.articleNum || 12,
              params
            })
          });
          break;
        default:
          break;
      }
    });
  }

  /**
   * 获取所有的articleId和plpParams
   */
  getParamsOfProductList(
    articleList: Array<string>,
    maintainType: string,
    component: iComponetData,
    link: string, pageSize: number) {
    if (maintainType === 'article') {
      this.articleIdList.push(...articleList);
    } else {
      const { keyword, categoryCode } = getUrlQuery(link);
      const params: iPlpParams = {
        page: 1,
        abTest: 'A',
        pageSize,
        categoryCode,
        keyword: decodeURI(keyword)
      };
      if (!keyword && keyword !== '0') {
        delete params.keyword;
      }
      if (categoryCode === '/search' || !categoryCode) {
        delete params.categoryCode;
      }
      const obj: iPlpParamsData = {
        link,
        pageSize,
        params
      };
      this.plpParamList.push({
        link,
        pageSize,
        params
      })
    }
  }
}

