/*
 * @Author: your name
 * @Date: 2021-09-08 20:16:28
 * @LastEditTime: 2021-09-08 20:16:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /adidas-com-front/packages/nuxt/utilities/helper/delay.ts
 */

export const delay = (timer = 0)=> new Promise((resolve)=>{setTimeout(resolve,timer);});
