/* eslint-disable camelcase */
/*
 * @Description: 神策集成
 * @Autor: Duan,JingLiang
 * @Date: 2021-09-13 16:02:17
 */

import {  TokenUtils } from '~/utilities/index';
let sensors = {} as any;
const jwt = require('jsonwebtoken');
let distinctId:null|string=null;
if (process.client) {
  sensors = require('sa-sdk-javascript');
}
export function getLocatDistinctIds(){
  return distinctId;
}
export function getDistinctIds():Promise<string|null> {
  return new Promise((resolve, reject) => {
    sensors.quick('isReady', () => {
      distinctId=(sensors.getPresetProperties() &&
      sensors.getPresetProperties()._distinct_id) ||
      '';
      return resolve(
        distinctId
      );
    });
  });
}
export function getAnonymousID() {
  return new Promise((resolve, reject) => {
    sensors.quick('isReady', () => {
      return resolve(
        sensors.quick('getAnonymousID')
      );
    });
  });
}

/**
 * 设置公共属性
 */
export function sensorsSetPublicProps(param: Object = {}): void {
  try {
    sensors.registerPage(param);
  } catch (error) {
    console.error('[sensorsSetPublicProps] error', error);
  }
}

/**
 * 神策 用户关联
 */
export async function sensorsLogin() {
  try {
    const memberInfo = jwt.decode(TokenUtils.getOriginToken() || '');
    // 未登录，由sdk生成匿名id, 当做cookieid
    const anonymous_id = await getDistinctIds();
    let userId = memberInfo.member_id;

    if (memberInfo.member_group === '31') {
      // employee
      userId = memberInfo.user_id;
    }

    if (memberInfo.member_group === '91') {
      // 非会员
      userId = anonymous_id;
    }
    sensors.login(userId);
  } catch (error) {
    console.error('[sensors login] error', error);
  }
}

/**
 * 神策 用户退出
 */
export function sensorsLogout() {
  try {
    sensors.logout();
  } catch (error) {
    console.error('[sensors login] error', error);
  }
}

/**
 *  神策上报埋点
 * @param eventName 事件名
 * @param payload 参数对象
 */
export function track(eventName: string, payload?: Object): void {
  try {
    sensors.track(eventName, payload);
  } catch (error) {
    console.error('[sensors track] error', error);
  }
}

/**
 * 获取当前环境
 * @returns 
 */
export function getPlatform(): string {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.includes('miniprogram')) {
    if (location.pathname.includes('/OR')) {
      return 'OR';
    }else if(location.pathname.includes('/AC')){
      return 'ADICLUB';
    } else {
      return 'WMS';
    }
  } else if (ua.includes('adidasapp')) {
    return 'BOS APP';
  } else {
    return '.COM';
  }
};

export default { track };
