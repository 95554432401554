import qs from 'qs';
import Cookies from 'js-cookie';
// import Dayjs from '../../ui-library/src/components/atoms/LibFormat/dayjs.min';
// import { isClient, TokenUtils } from "~/utilities/index";
import { routerMap } from "~/plugins/axios.loginhook";
// import Dayjs from '@adidas-com-front/ui-library/src/components/atoms/LibFormat/dayjs.min';

const isClient = (() =>
  typeof window !== 'undefined' || typeof document !== 'undefined')();
let Dayjs,TokenUtils,getEmployeeId;
if(isClient){
  // 减少不必要的 服务器内存消耗
  Dayjs=require('../../ui-library/src/components/atoms/LibFormat/dayjs.min');
  const utilities=require('~/utilities/index');
  TokenUtils=utilities.TokenUtils;
  getEmployeeId=utilities.getEmployeeId;
}
const md5 = require('md5');
try{
  if(global&&global.consola){
    // Consola会替换console 造成内存泄漏
    global.consola.restoreConsole();
    console.log('warn:plugins axios restoreConsole');
  }
}catch{}
/**
 * 处理get请求参数转码问题
 * @author 姚润发
 * @date 2021-09-02 17:57:00
 * @param config
 * @return {{params}|*}
 */

function dealGetParam(config) {
  let url = config.url;
  if (config.method === 'get' && config.params) {
    url += '?';
    // const keys = Object.keys(config.params);
    // for (const key of keys) {
    //   url += `${key}=${encodeURIComponent(config.params[key])}&`;
    // }
    // url = url.substring(0, url.length - 1);
    url += qs.stringify(config.params);
    config.url = url;
    config.params = {};
  }
  return config;
}

async function getServerTime(ctx) {
  const result = await ctx.get('/system/time');
  if (result) {
    return result;
  }
}

/**
 * refreshToken也失效，则判断是否存在clickId，如果存在则获取服务器时间
 * @returns {Promise<void>}
 */
async function setServerTime($axios) {
  const clickId = window && window.localStorage.getItem("adTrackClickId");
  if (clickId) {
    let serverTime = 0;
    // 如果未登录获取服务器时间
    await getServerTime($axios).then(resp => {
      serverTime = resp;
    });
    // 这里转化为北京时间，时区取东八区，
    const timeZone = 8;
    // 计算偏移UTC的时间
    const offsetTime = new Date().getTimezoneOffset() * 60000;
    serverTime = serverTime && Dayjs(new Date(serverTime + 3600000 * timeZone + offsetTime)).format();
    // 这里手动调整末尾时区为东八区
    serverTime = serverTime.substr(0, serverTime.lastIndexOf('+')).concat('+08:00');
    // 设置点击广告链接进入的时间
    serverTime && window.localStorage.setItem('adTrackDate', serverTime);
  }
}

/**
 * 是否员工登录
 */
function employeeIdLogin() {
  const employeeId=getEmployeeId();
  return !!employeeId;
};
/* eslint-disable-next-line no-console */
// @ts-ignore
export default function (
  { $axios, redirect, route, app, error, $config },
  inject
) {
  
  try{
    if(global&&global.consola){
      global.consola.restoreConsole();
      console.log('warn:plugins axios restoreConsole');
    }
    console.log('warn:plugins axios');
    console.log('warn:plugins axios app.$qs'+!!app.$qs);
    console.log('warn:plugins axios '+$axios.interceptors.request.handlers.length);
  }catch{}
  // qs注入context.app, 以及vue实例
  if(!process.server){
    app.$qs = qs;
    inject('qs', qs);
  }
  const target = {
    anaProxyPrefix: '/ana/api',
    ana: $config.ANA_API_HOST,
    oauth: 'users/refreshToken',
    crm: $config.CRM_API_HOST,
    crmProxyPrefix: '/crm/api',
    s3: $config.S3_HOST,
    nav: 'o2cms/v2/pub/navigation',
    hotWord: '/o2srh/v1/pub/hot-word'
  };
  /**
   * 设置token
   */
  $axios.onRequest((config) => {
    // click-id广告ID处理,具体请看 CRFC-3402
    if(config.url==="/o2vip/v1/customer/click-id" || config.url==='/system/time'){
      if(process.server){
        config.url=$config.ECP_API_PRIVATE_HOST+config.url;
      }else{
        config.url=$config.ECP_API_HOST+config.url;
      }
    }
    if (process.env.NODE_ENV === 'development') {
      // 处理本地开发时的跨域问题
      config.url = config.url.replace($config.ECP_API_HOST, '/ecp/api');
      config.url = config.url.replace($config.ECP_API_PRIVATE_HOST, '/ecp/private/api');
      config.url = config.url.replace($config.ANA_API_HOST, '/ana/api');
      config.url = config.url.replace($config.CRM_API_HOST, '/membership/adidas/cn');
    }
    if (['prod', 'uat', 'sit'].includes($config.RUNTIME_ENV)) {
      if (process.server && config.url.startsWith($config.ECP_API_HOST)) {
        config.url = config.url.replace($config.ECP_API_HOST, $config.ECP_API_PRIVATE_HOST);
      }

      if (!process.server && config.url.startsWith($config.ECP_API_PRIVATE_HOST)) {
        config.url = config.url.replace($config.ECP_API_PRIVATE_HOST, $config.ECP_API_HOST);
      }
    }

    // 处理开发环境客户端渲染接口调用带public host
    if (isClient && process.env.NODE_ENV === 'development' && config.url.includes('/ecp/private/api/o2pcm')) {
      config.url = config.url.replace('/ecp/private/api', '/ecp/api');
    }
    // 处理生产环境客户端渲染接口调用带public host
    if (isClient && config.url.includes('ecp.api.adidas.com.cn/o2pcm')) {
      config.url = config.url.replace($config.ECP_API_PRIVATE_HOST, $config.ECP_API_HOST);
    }

    config.timeout = 15000;
    if (config.url.includes(target.ana) || config.url.includes(target.oauth) || config.url.includes(target.anaProxyPrefix)) {
      if (employeeIdLogin()) {
        config.headers.source = 'A001';
      } else {
        config.headers.source = 'W001';
      }
      config.headers['api-key'] = $config.ANA_API_KEY;
      if (config.url.includes(target.oauth)) {
        config.headers['content-type'] = 'application/x-www-form-urlencoded';
      }
    } else if (config.url.includes(target.crm)) {
      // config.url = config.url.replace(target.crm, target.crmProxyPrefix); // crm接口 不再走代理
      const tagUrl = config.url.slice(8).split('?')[0] || '';
      const crypto = md5(`${$config.CRM_API_KEY}${tagUrl}`);
      config.headers['x-api-key'] = crypto;
    } else if (!config.url.includes(target.s3)) {
      // 静态资源不添加自定义请求头，防止出现预请求
      if (config.sourceExclusion) {
        // debugger;
      } else {
        config.headers['x-source'] = 'COM';
      }
    }
    config.withCredentials = false;

    // ANA CRM ECP请求
    if (config.url.includes($config.ECP_API_HOST) || config.url.includes($config.ECP_API_PRIVATE_HOST) || config.url.includes($config.CRM_API_HOST) || config.url.includes($config.ANA_API_HOST)) {
      config.withCredentials = true;
    }
    if(isClient&&!config?.sourceToken){
      let token = TokenUtils.getToken(false, config.url);
      // 没有使用临时隔离期token
      if(!token){
        token=TokenUtils.getIsolatedToken();
      }
      // 静态资源、导航栏、热词搜索接口不需要token
      if (token && !config.url.includes(target.s3)  && !config.url.includes(target.hotWord) || (config.url.includes(target.nav) && String(config.params.preview) === '0')) {
        if(config.url.includes(target.nav)){
          if ("x-preview-flag" in config.headers && config.headers["x-preview-flag"]===1 ){
            config.headers.Authorization = token;
          }
        }else{
          config.headers.Authorization = token;
        }
      }
    }
    dealGetParam(config);
    const arr = config.url.split("?");
    // 修复出现出现二个“?”的情况 www.a.com?a=b&b=c?
    if (arr.length === 3 && arr[2] === '') {
      arr.splice(2, 1);
      config.url = arr.join("?");
    }
    console.log(`Ready to access: ${config?.url}`);
    // if (process.server) {
    //   config.startTime = Date.now();
    // }
    return config;
  });

  /**
   * 请求响应返回数据
   */
  $axios.onResponse((response) => {
    // 记录响应日志，$alogger 只存在于服务端
    console.log(`API response code ${response.status}: ${response?.config?.url}`);
    if (process.server) {
      // const interval = Date.now() - response.config.startTime;
      console.log(`Page 【${route?.fullPath}】 accessed【${response?.config?.url}】 used time: ~ms status:${response.status}`);
      // if (interval > 5000) {
      //   console.log(`【${response?.config?.url}】 used time: ${interval}ms status:${response.status}`);
      // }
    }
    let res ;
    if(isClient){
      res = response.data;
    }else{
      res = JSON.parse(JSON.stringify(response.data));
    }
    const status = parseInt(response.status) || 200;
    const message = res.msg;
    if (status !== 200 && status !== 204) {
      // eslint-disable-next-line no-console
      console.error('Response status Error: ' + response.config.url);
      return Promise.reject(new Error(`网络请求异常${status}: ${message}`));
    } else if (res.failed) {
      // eslint-disable-next-line no-console
      console.error('Response Success Error: ' + response.config.url);
      return Promise.reject(new Error(res.msg));
    }
    return res;
  });
  $axios.onError(async (e) => {
    // refreshToken后接口自身报错标识
    let requestSelfError = false;
    if (console) {
      // 请求错误日志
      let codeMessage;
      if(e.response?.data?.code){
        codeMessage=`error code-->${e.response?.data?.code}`;
      }else if(e.response?.data?.message){
        codeMessage=`error message-->${e.response?.data?.message}`;
      }else{
        codeMessage=`error ${JSON.stringify(e.response?.data)}`;
      }
      console.log(`【${e.response?.config?.url}】 status:${e.response?.status} ## ${codeMessage}`);
    } else {
      console.error('no logger');
    }
    const code = parseInt(e.response && e.response.status);
    if (code) {
      // eslint-disable-next-line no-console
      console.error(
        'Making request error ' + code + ': ' + e.response.config.url
      );
      // eslint-disable-next-line no-console
      if (code === 500) {
        error({ statusCode: code, error: e });
      } else if (code === 401) {
        // 未登录时打开登录弹框
        if (isClient) {
          // const url=new URL(location.href);
          // if(url.pathname.includes('/my-account/right2copy')&&navigator.userAgent.toLowerCase().includes('adidasapp')){
          //   return;
          // }
          const refreshToken = TokenUtils.getRefreshToken();
          const sensors = require('~/utilities/vendors/sensors');
          if (refreshToken) {
            return await $axios.post(`${$config.ANA_API_HOST}/v1/users/refreshToken`, qs.stringify({ refreshToken })).then(async function (response) {
              // 如果用refreshToken去获取新的token验证通过，则直接重设token,重新请求报401的请求,否则打开登录窗口后登录后重新请求
              if (response.data) {
                const isKeep = !!Number(Cookies.get('isKeep') || '0');
                TokenUtils.setToken({
                  access_token: response.data.access_token,
                  refresh_token: response.data.refresh_token,
                  isKeep
                }, $axios, true);
                return await $axios(e.config).catch((exception) => {
                  requestSelfError = true;
                  return Promise.reject(exception);
                });
              } else {
                // 清除登录信息
                TokenUtils.logOut();
                sensors.sensorsLogout();
                app.store.commit('myAccount/SET_LOGOUT');
                await setServerTime($axios);
                await app.router.app.$aloading.hide();
                if (!e.config.noForceLogin) {
                  app.store.commit('myAccount/SET_LOGIN_POPOVER', true);
                  await app.router.app.$login({
                    show: true,
                    on: {
                      close: () => {
                        app.store.commit('myAccount/SET_LOGIN_POPOVER', false);
                      }
                    }
                  });
                  if (!routerMap(app)) {
                    // 不需要进行页面跳转，则继续执行先前请求
                    return $axios(e.config).catch((e) => {
                      // 这里捕获异常时置刷新token后重新请求接口本身报错标识为true,防止在外层捕获异常直接清除登录信息造成bug
                      requestSelfError = true;
                      return Promise.reject(e.response || e);
                    });
                  }
                }
              }
            }).catch(async function (ex1) {
              if (!requestSelfError) {
                // 清除登录信息
                TokenUtils.logOut();
                sensors.sensorsLogout();
                app.store.commit('myAccount/SET_LOGOUT');
                // 如果是refreshToken也失效，则判断是否存在clickId，如果存在则获取服务器时间
                await setServerTime($axios);
                // 当refreshToken也失效时，则强制登录
                if (!e.config.noForceLogin) {
                  await app.router.app.$aloading.hide();
                  app.store.commit('myAccount/SET_LOGIN_POPOVER', true);
                  await app.router.app.$login({
                    show: true,
                    on: {
                      close: () => {
                        app.store.commit('myAccount/SET_LOGIN_POPOVER', false);
                      }
                    }
                  });
                  if (!routerMap(app)) {
                    // 不需要进行页面跳转，则继续执行先前请求
                    return $axios(e.config);
                  }
                  // return
                } else {
                  return Promise.reject(e.response);
                }
              } else {
                return Promise.reject(ex1.response || ex1);
              }
            }).finally(function () {
            });
          } else {


            // 清除登录信息
            TokenUtils.logOut();
            sensors.sensorsLogout();
            app.store.commit('myAccount/SET_LOGOUT');
            await app.router.app.$aloading.hide();
            if (!e.config.noForceLogin) {
              app.store.commit('myAccount/SET_LOGIN_POPOVER', true);
              const isChecked = (Number(window.localStorage.getItem('isMemberShipChecked')) || 0) && e.response?.config?.url?.includes('carts/batch');
              await app.router.app.$login({
                show: true,
                on: {
                  close: () => {
                    app.store.commit('myAccount/SET_LOGIN_POPOVER', false);
                  }
                },
                joinAdiclubProp: !!isChecked
              });
              if (!routerMap(app)) {
                // 不需要进行页面跳转，则继续执行先前请求
                return $axios(e.config);
              }
            }
          }
        }
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('Making request error: ' + e);
    }
    return Promise.reject(e.response || e);
  });
}
