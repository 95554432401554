
import { getImgUrl } from '~/utilities/helper/urlHelper';

  export default {
    props: {
      item: {
        type: Object,
        default: () => {
          return {};
        }
      },
      isMobile: {
        type: Boolean
      },
      itemStyle: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        isLoad: false
      };
    },
    computed: {
      imgInfo() {
        return function (item) {
          return this.isMobile
            ? {
                img: getImgUrl(item.mobImgUrl, 1000),
                tag: item.mobSeoTag
              }
            : {
                img: getImgUrl(item.pcImgUrl, 500),
                tag: item.pcSeoTag
              };
        };
      },
      optLink() {
        return function (item, type) {
          if (type === 'Image') {
            return item.photoLink || item.link;
          } else if (type === 'Button') {
            return item.buttonLink || item.link;
          } else if (type === 'Video') {
            return item.link;
          } else {
            return '';
          }
        };
      }
    },
    methods: {
      onLoad() {
        this.isLoad = true;
        this.$emit('loadEnd', this.isLoad);
      }
    }
  };
