/*
 * @Author: wang,shuang
 * @Date: 2022-03-23 14:06:52
 * @Description: 用户未登录无法访问mtbr页面
 */

const isMobile = (ua) => {
  return !!ua.match(/AppleWebKit.*Mobile.*/);
};

export default function ({req, store, redirect }) {
  if (store) {
    const userAgent = req ? req.headers['user-agent'] : navigator.userAgent || '';
    const { isLogin, isEmployeeLogin, isNonmemberLogin } =
      store.state.myAccount;
    if (!isMobile(userAgent) && !isLogin && !isEmployeeLogin && !isNonmemberLogin) {
      redirect({ path: '/campaign/running_loop' });
    }
  }
}
