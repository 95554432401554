
import deepcopy from 'deepcopy';
import LibProductCard from '~/components/plp/common/productCard/index.vue';

export default {
  name: 'ProductCard',
  components: {
    LibProductCard
  },
  props: {
    // 商品数据
    ssrObj: {
      type: [Object, Array],
      default: () => {
        return [];
      }
    },
    // 面包屑数据
    urlParam: {
      type: String
    }
  },
  computed: {
    productList() {
      return deepcopy(this.ssrObj);
    }
  },
  methods: {
    // 商品卡片点击事件
    cardClick(item) {
      this.$emit('click', item);
    }
  }
};
