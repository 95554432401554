
export default {
  data() {
    return {
      adidasQR: `${this.$config.S3_HOST}/p05/right/wechatQR_adidas.png`,
      adidasLogo: `${this.$config.S3_HOST}/p05/right/adidasLogo.png`,
      adidasOriginalsQR: `${this.$config.S3_COM_HOST}/assets/logo/wechatQR_adidasOriginals-black.png`,
      adidasOriginalsLogo: `${this.$config.S3_COM_HOST}/assets/logo/footer-wechat-logo-black.png`
    };
  }
};
