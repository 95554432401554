const state = () => ({
  configObj:null,
  waitConfig:null
});

const mutations = {
  SET_CONFIG_OBJ(state, status) {
    state.configObj = status;
  },
  SET_WAIT_CONFIG(state, status) {
    state.waitConfig = status;
  }
};
async function getLoginImg(commit ,state, ctx){
  if(!state?.configObj){
    const data = await ctx.$axios.get(
      `${ctx.$config.S3_COM_HOST}/static/loginConfig.json`
    );
    const configObj=data.backgroundImgCofing;
    configObj.sort((a, b) => {
      return new Date(b.startTime).getTime() - new Date(a.startTime).getTime();
    });
    commit('SET_CONFIG_OBJ', configObj);
  }
}
const actions = {
  async getLoginImg({ commit ,state}, { ctx }){
    if(!state.waitConfig){
      const wait=getLoginImg(commit ,state, ctx);
      commit("SET_WAIT_CONFIG",wait);
    }
    await state.waitConfig;
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};