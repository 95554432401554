/*
 * @Author: zhouwed wendy.zhou@adidas.com
 * @Date: 2023-07-20 11:17:20
 * @LastEditors: zhouwed wendy.zhou@adidas.com
 * @LastEditTime: 2023-07-20 11:30:56
 * @FilePath: /new-com/adidas-com-front/packages/nuxt/utilities/helper/colorHelper.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

export function colorHexToRgb(hexStr: string, opacity?: number) {
  // rgb颜色值的正则表达式
  const reg = /^(rgba|rgb|RGBA|RGB)\([\s]*[0-9]+[\s]*,[\s]*[0-9]+[\s]*,[\s]*[0-9]+[\s]*(,[\s]*[0-9.]+[\s]*)*\)$/;
  if (reg.test(hexStr)) {
    return hexStr;
  } else {
    if (hexStr.length === 4) {
      let colorNew = "#";
      for (let i = 1; i < 4; i += 1) {
        const str = hexStr.slice(i, i + 1);
        colorNew += str + str;
      }
      hexStr = colorNew;
    }
    const rgbArray = [];
    for (let i = 1; i < hexStr.length; i += 2) {
      if (i < 7) {
        rgbArray.push(parseInt("0x" + hexStr.slice(i, i + 2)));
      }
      if (i >= 7) {
        const str = hexStr.slice(i, i + 2);
        rgbArray.push(/^[a-f0-9]{2}$/.test(str) ? parseInt(`0x${str}`) / 255 : (Number(str) / 100).toString());
      }
    }
    if (opacity) {
      return "rgba(" + rgbArray.join(",") + "," + opacity + ")";
    } else {
      return ("rgb(" + rgbArray.join(",") + ")");
    }
  }
};
